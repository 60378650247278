/* eslint jsx-a11y/label-has-associated-control: "off" */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input, Header } from 'semantic-ui-react';
import isCycleExpired from 'helpers/isCycleExpired';
import Message from 'components/common/Message';
import updateGroup from 'redux/actions/organizations/updateGroup';

// test
const FineDetails = () => {
  const [form, setForm] = useState();
  const dispatch = useDispatch();
  const { group } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup?.item,
  );

  const { loading, error } = useSelector(
    ({ organizations: { updateGroup } }) => updateGroup,
  );

  const { isSupportAdmin } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const handleChange = (_, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    updateGroup(group?.id, form)(dispatch);
  };

  const isGroupSupervisedOrGraduated = () =>
    ['supervised', 'graduated'].includes(group?.type);

  const isDisabled = () =>
    !isSupportAdmin &&
    (group?.status === 'active' ||
      group?.cycles[group?.cycles?.length - 1]?.status === 'active' ||
      isCycleExpired(group?.cycle_end_date));

  return (
    <Form onSubmit={handleSubmit}>
      {isCycleExpired(group?.cycle_end_date) && (
        <Header as="h5" color="red">
          Update Cycle details First.
        </Header>
      )}

      {!loading && error && (
        <Message message={error.message} color="red" />
      )}

      {isGroupSupervisedOrGraduated() && (
        <Header as="h5">1. Contribution Fines Details</Header>
      )}
      <Form.Field>
        <label className={`${isDisabled() ? 'disabled-label' : ''}`}>
          Saving Default Fine
        </label>
        <Input
          label={{ basic: true, content: 'Rwf' }}
          placeholder="Saving Default Fine"
          labelPosition="left"
          name="saving_delay_fine"
          disabled={isDisabled()}
          defaultValue={group?.saving_delay_fine}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label className={`${isDisabled() ? 'disabled-label' : ''}`}>
          Social Fund contribution
        </label>
        <Input
          label={{ basic: true, content: 'Rwf' }}
          placeholder="Social Fund contribution"
          labelPosition="left"
          name="social_fund_delay_fine"
          disabled={isDisabled()}
          defaultValue={group?.social_fund_delay_fine}
          onChange={handleChange}
        />
      </Form.Field>
      {isGroupSupervisedOrGraduated() && (
        <>
          <Header as="h5">2. Meeting Fines Details</Header>
          <Form.Field>
            <label
              className={`${isDisabled() ? 'disabled-label' : ''}`}
            >
              Lateness
            </label>
            <Input
              label={{ basic: true, content: 'Rwf' }}
              placeholder="Lateness"
              labelPosition="left"
              name="attendance_delay_fine"
              disabled={isDisabled()}
              defaultValue={group?.attendance_delay_fine}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label
              className={`${isDisabled() ? 'disabled-label' : ''}`}
            >
              Absence
            </label>
            <Input
              label={{ basic: true, content: 'Rwf' }}
              placeholder="Absence"
              labelPosition="left"
              name="meeting_absence_fine"
              disabled={isDisabled()}
              defaultValue={group?.meeting_absence_fine}
              onChange={handleChange}
            />
          </Form.Field>
        </>
      )}

      <div className="action">
        <Button type="submit" primary loading={loading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FineDetails;
