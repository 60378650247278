import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Transactions from 'components/Wallet/Transactions';
import Empty from 'components/common/Empty';
import {
  isFinanceRwandaAdmin,
  isSuperAdmin,
  isSupportAdmin,
  isOrganizationAdmin,
} from 'helpers/checkRoles';
import getTransactions from 'redux/actions/wallets/getTransactions';
import { getOrgTransactions } from 'redux/actions/wallets/getOrgRequests';
import PageLoader from 'components/common/PageLoader';
import getOrgWithWallet from 'redux/actions/organizations/getOrgWithWallet';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';

const AllTransactions = () => {
  const isCurrentOrgParent = isParentOrganization();

  const {
    loading: loadingTransactions,
    data: transactions,
    error: transactionError,
    meta,
  } = useSelector(({ wallets: { transactions } }) => transactions);

  const {
    loading: loadingRequests,
    items: requests,
    error: errorRequests,
  } = useSelector(
    ({ wallets: { topupOrgRequests } }) => topupOrgRequests,
  );

  const { items = [] } = useSelector(
    ({ organizations: { hasWallet } }) => hasWallet,
  );

  const { push } = useHistory();

  const {
    currentOrgId,
    currentOrgName,
    orgUsers,
    roles,
  } = getUserRoles();

  const hasAccess = () =>
    roles &&
    (isSuperAdmin(roles) ||
      isFinanceRwandaAdmin(roles) ||
      isSupportAdmin(roles) ||
      isOrganizationAdmin({ orgUsers, currentOrgId }));

  useEffect(() => {
    if (!hasAccess()) {
      push('/');
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    getOrgWithWallet({ has_wallet: 'true' })(dispatch);
    if (isCurrentOrgParent) {
      getTransactions()(dispatch);
    } else {
      getOrgTransactions({ organization_id: currentOrgId })(dispatch);
    }
  }, [dispatch]);

  const onQuery = queries => {
    getTransactions(queries)(dispatch);
  };

  return (
    <>
      <Toolbar
        header={{
          title: 'Wallet: Transactions',
          navigation: true,
          rightComponent: (
            <ToolbarFooter
              onSearch={onQuery}
              onPaginate={onQuery}
              paginationMeta={meta}
              exportCSVData={transactions}
              exportCSVFileName="Transactions"
              filterTransactions
              organizations={items}
              onFilterTransactions={onQuery}
            />
          ),
        }}
      />
      {transactionError && (
        <Message negative>{transactionError.message}</Message>
      )}
      {loadingTransactions && <PageLoader />}
      {isCurrentOrgParent
        ? !loadingTransactions &&
          !transactionError &&
          transactions?.length === 0 && (
            <Empty message="No transactions found" />
          )
        : !loadingRequests &&
          !errorRequests &&
          requests?.length === 0 && (
            <Empty message="No Requests found" />
          )}
      {isCurrentOrgParent
        ? !loadingTransactions &&
          !transactionError &&
          transactions?.length > 0 && (
            <Transactions
              currentOrgName={currentOrgName}
              items={transactions}
              viewAll
            />
          )
        : !loadingRequests &&
          !errorRequests &&
          requests?.length > 0 && (
            <Transactions
              currentOrgName={currentOrgName}
              items={requests}
              viewAll
            />
          )}
    </>
  );
};

export default AllTransactions;
