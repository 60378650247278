import {
  TOGGLE_ROLES_IN_BULK_FAILURE,
  TOGGLE_ROLES_IN_BULK_START,
  TOGGLE_ROLES_IN_BULK_SUCCESS,
} from 'constants/action-types/user/toggleRolesInBulk';

export default (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_ROLES_IN_BULK_START:
      return {
        ...state,
        toggleRolesInBulk: {
          loading: true,
          error: null,
          message: null,
        },
      };
    case TOGGLE_ROLES_IN_BULK_FAILURE:
      return {
        ...state,
        toggleRolesInBulk: {
          ...state.toggleRolesInBulk,
          loading: false,
          error: payload,
        },
      };
    case TOGGLE_ROLES_IN_BULK_SUCCESS:
      return {
        ...state,
        toggleRolesInBulk: {
          ...state.toggleRolesInBulk,
          loading: false,
          message: payload,
        },
      };
    default:
      return null;
  }
};
