export default {
  max_monthly_savings: {
    status: false,
    message: `Minimum monthly savings shouldn't be greater than or equal to maximum savings`,
  },
  max_monthly_loans: {
    status: false,
    message: `Minimum monthly loans shouldn't be greater than or equal to maximum loans`,
  },
  min_savings_range_exists: {
    status: false,
    message: `Minimum monthly saving range already exists`,
  },
  max_savings_range_exists: {
    status: false,
    message: `Maximum monthly saving range already exists`,
  },
  min_loans_range_exists: {
    status: false,
    message: `Minimum monthly loan range already exists`,
  },
  max_loans_range_exists: {
    status: false,
    message: `Maximum monthly loan range already exists`,
  },
};
