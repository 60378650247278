import React from 'react';
import { Form, Message, Button } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';

const ConfirmActionUI = ({
  onSubmit,
  isDeactivating,
  setPassword,
  error,
}) => (
  <Form
    onSubmit={onSubmit}
    loading={isDeactivating}
    data-cy="passwordConfirmationButton"
  >
    <Form.Input
      size="mini"
      label="Enter Pin"
      type="password"
      data-cy="passwordInput"
      onChange={e => {
        setPassword(e.target.value);
      }}
    />

    <Message
      error
      data-cy="errorOutput"
      visible={error.message !== undefined}
      content={error.message ? error.message : null}
    />

    <Button type="submit" color="blue" fluid size="tiny">
      Confirm
    </Button>
  </Form>
);

ConfirmActionUI.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isDeactivating: PropTypes.bool.isRequired,
  setPassword: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};
export default ConfirmActionUI;
