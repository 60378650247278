import { capitalize, startCase } from 'lodash';
import airtel from 'assets/images/airtel-wallet.svg';
import mtn from 'assets/images/mtn-wallet.svg';
import save from 'assets/images/save-wallet.svg';
import inkomoko from 'assets/images/wallet-inkomoko.svg';
import accessBank from 'assets/images/access-bank-wallet.svg';
import keys from 'constants/walletKeys';

const getWalletLogo = name => {
  if (name?.toLowerCase()?.includes('mtn')) return mtn;
  if (name?.toLowerCase()?.includes('airtel')) return airtel;
  if (name?.toLowerCase()?.includes('access')) return accessBank;
  if (name?.toLowerCase()?.includes('save')) return save;
  if (name?.toLowerCase()?.includes('inkomoko')) return inkomoko;
};

const sorter = ['org_name', 'access', 'mtn', 'airtel'];

const onlyKeysAccepted = {
  save_country: {
    access_bank: [
      keys.saving_group,
      keys.fees,
      keys.float_totals,
      keys.interest_on_deposit,
      keys.due_commissions,
      keys.subscription_and_transaction_fees,
      keys.groups_subscription,
      keys.groups_transaction_charges,
      keys.users_subscription,
      keys.users_transactions_charge,
    ],
    mtn_rwanda: [
      keys.saving_group,
      keys.float_totals,
      keys.due_commissions,
    ],
    airtel_rwanda: [
      keys.saving_group,
      keys.float_totals,
      keys.due_commissions,
    ],
    save: [
      keys.saving_group,
      keys.fees,
      keys.float_totals,
      keys.interest_on_deposit,
      keys.due_commissions,
      keys.subscription_and_transaction_fees,
      keys.groups_subscription,
      keys.groups_transaction_charges,
      keys.users_subscription,
      keys.users_transactions_charge,
    ],
  },
  ngos: [keys.saving_group, keys.org_float],
};

const getWalletData = (data = [], orgType) =>
  data?.map(wallet => ({
    label: capitalize(startCase(wallet?.provider_name)),
    logo: getWalletLogo(wallet?.provider_name),
    balance: wallet?.balance || wallet?.total_balance,
    data: [
      {
        label: keys.saving_group,
        value: wallet?.groups_balance,
      },
      {
        label: keys.fees,
        value: wallet?.fees,
      },
      {
        label: keys.org_float,
        value: wallet?.float_balance,
      },
      {
        label: keys.interest_on_deposit,
        value: wallet?.groups_deposit_interests,
        hasSub: true,
        subs: [
          {
            label: keys.deposit_sg,
            value: wallet?.groups_deposit_interests,
          },
          {
            label: keys.deposit_save,
            value: wallet?.save_deposit_interests,
          },
        ],
      },
      {
        label: keys.subscription_and_transaction_fees,
        value:
          (wallet?.groups_subscription || 0) +
          (wallet?.groups_transactions_charge || 0) +
          (wallet?.users_subscription || 0) +
          (wallet?.users_transactions_charge || 0),
        hasSub: true,
        subs: [
          {
            label: keys.groups_subscription,
            value: wallet?.groups_subscription,
          },
          {
            label: keys.groups_transaction_charges,
            value: wallet?.groups_transactions_charge,
          },
          {
            label: keys.users_subscription,
            value: wallet?.users_subscription,
          },
          {
            label: keys.users_transactions_charge,
            value: wallet?.users_transactions_charge,
          },
        ],
      },
      {
        label: keys.float_totals,
        value: wallet?.float_balance,
        hasSub: true,
        subs: [
          {
            label: keys.save_float,
            value: wallet?.float_balance,
          },
          !!wallet?.micro_loan_insurance && {
            label: keys.micro_loan_insurance,
            value: wallet?.micro_loan_insurance,
          },
        ],
      },
      {
        label: keys.due_commissions,
        value: wallet?.due_commissions,
      },
    ].filter(detailedData => {
      const organizationKeys = onlyKeysAccepted[orgType];
      const acceptedKeys = Array.isArray(organizationKeys)
        ? organizationKeys
        : organizationKeys?.[wallet?.provider_name?.toLowerCase()];

      return acceptedKeys
        ? acceptedKeys.includes(detailedData.label)
        : true;
    }),
  }));

export default (data, orgType) => {
  const walletData = getWalletData(data, orgType);
  return sorter.map(item =>
    walletData.find(data => {
      const label = data?.label?.toLowerCase();
      return item === 'org_name' ? true : label?.includes(item);
    }),
  );
};
