/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Popup } from 'semantic-ui-react';
import { savePDF } from '@progress/kendo-react-pdf';
import Thumbnail from 'components/common/Thumbnail';
import capitalize from 'utils/capitalize';
import SimpleModal from 'components/common/modals/SimpleModal';
import Receipt from 'components/Wallet/Receipt';
import Request from 'components/Wallet/Request';
import './index.scss';
import formatNumber from 'helpers/formatNumber';
import { isFinanceAdmin } from 'helpers/checkRoles';
import Withdrawal from '../Withdrawal';
import TransactionPopupContent from './PopupContent';

const statusColors = {
  approved: '#48ff5b',
  declined: '#ff4869',
  pending: '#ffab48',
  success: '#48ff5b',
  failed: '#ff4869',
  rejected: '#ff4869',
};

const Transactions = ({
  items = [],
  roles,
  viewAll,
  currentOrgName,
}) => {
  const [title, setTitle] = useState('Transaction');
  const [showReceipt, setShowReceipt] = useState(false);
  const [receipt, setReceipt] = useState({});
  const [downloadReceipt, setDownloadReceipt] = useState();

  const [orgRequestPending, setOrgRequestPending] = useState(
    'transaction',
  );

  const handleSetReceipt = data => {
    setReceipt(data);
  };

  const handleDownloadReceipt = data => {
    setReceipt(data);
    setDownloadReceipt(true);
  };

  useEffect(() => {
    if (downloadReceipt) {
      const receiptElement = document.getElementById('receipt');
      savePDF(
        receiptElement,
        {
          paperSize: 'auto',
          margin: 40,
          fileName: 'Receipt',
        },
        () =>
          setTimeout(() => {
            setShowReceipt(false);
          }, 1000),
      );
    }

    setDownloadReceipt(false);
  }, [downloadReceipt]);

  const popupContentItems = {
    default: {
      items: [
        {
          label: ' View transaction receipt',
          onClick: currentReceipt => {
            setShowReceipt(true);
            handleSetReceipt(currentReceipt);
            setTitle('Transaction');
          },
        },
        {
          label: ' Download receipt PDF',
          onClick: currentReceipt => {
            setShowReceipt(true);
            handleDownloadReceipt(currentReceipt);
            setTitle('Transaction');
          },
        },
      ],
    },
    org_wallet_topup: {
      items: [
        {
          label: 'View Request Details',
          onClick: currentReceipt => {
            setShowReceipt(true);
            handleSetReceipt(currentReceipt);
            setOrgRequestPending('topup');
            setTitle('Transaction');
          },
        },
      ],
    },
    org_wallet_view_withdrawal: {
      items: [
        {
          label: 'View transaction',
          onClick: currentReceipt => {
            setShowReceipt(true);
            handleSetReceipt(currentReceipt);
            setOrgRequestPending('withdrawal');
            setTitle('Withdrawal Transaction');
          },
        },
      ],
    },
    org_wallet_process_withdrawal: {
      items: [
        {
          label: 'Process transaction',
          onClick: currentReceipt => {
            setShowReceipt(true);
            handleSetReceipt(currentReceipt);
            setOrgRequestPending('withdrawal');
            setTitle('Process Withdrawal');
          },
        },
      ],
    },
  };

  const getContentItem = ({ status, type, transactionPurpose }) => {
    if (status === 'pending') {
      if (
        type === 'org_wallet_topup' ||
        transactionPurpose === 'org_wallet_topup'
      )
        return popupContentItems.org_wallet_topup;

      if (
        transactionPurpose === 'org_wallet_withdrawal' &&
        isFinanceAdmin(roles)
      )
        return popupContentItems.org_wallet_process_withdrawal;
    }

    if (transactionPurpose === 'org_wallet_withdrawal')
      return popupContentItems.org_wallet_view_withdrawal;

    return popupContentItems.default;
  };

  console.log(
    'purpose :>> ',
    items
      .map(({ purpose, narration, type }) => {
        return { purpose, narration, type };
      })
      .filter(({ type }) => type === 'credit'),
  );

  return (
    <>
      <div className="wallet__transactions">
        <Table basic="very" size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="1">ID</Table.HeaderCell>
              <Table.HeaderCell>Originator/Creditor</Table.HeaderCell>
              <Table.HeaderCell>Recipient</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount (RWF)</Table.HeaderCell>
              <Table.HeaderCell>Channel</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width="1" />
            </Table.Row>
          </Table.Header>

          <Table.Body
            className={`wallet__transactions--table-body ${viewAll &&
              'lg-tbody'}`}
          >
            {items.map(
              ({
                id,
                type,
                phone_number: phoneNumber,
                created_at: date,
                amount,
                comment,
                provider_name: channel,
                purpose: transactionPurpose,
                status,
                description,
                group,
                org_wallet_id,
                attachment,
                user: initiator,
                group_id: groupId,
                recipient_bank: recipientBank,
                recipient_bank_account: recipientBankAccount,
                organization,
              }) => {
                const getOriginator = (type, transactionPurpose) => {
                  if (
                    transactionPurpose === 'org_loan' &&
                    type === 'credit'
                  ) {
                    return currentOrgName || phoneNumber || '-';
                  }
                  if (
                    type === 'credit' &&
                    transactionPurpose === 'org_wallet_withdrawal'
                  )
                    return organization?.name;
                  if (
                    transactionPurpose === 'org_loan' &&
                    type === 'debit'
                  ) {
                    return group?.name || phoneNumber || '-';
                  }
                  if (
                    type === 'debit' ||
                    transactionPurpose === 'org_wallet_topup' ||
                    type === 'org_wallet_topup'
                  ) {
                    return 'Save';
                  }

                  if (transactionPurpose === 'group_cash') {
                    return group?.name || '-';
                  }
                  return phoneNumber;
                };

                const originator = getOriginator(
                  type,
                  transactionPurpose,
                );

                const getRecipient = (type, transactionPurpose) => {
                  if (
                    type === 'debit' &&
                    transactionPurpose === 'group_cash'
                  ) {
                    return { name: group?.name, id: groupId } || '-';
                  }
                  if (
                    type === 'debit' &&
                    transactionPurpose === 'org_loan'
                  ) {
                    return group?.organization || '-';
                  }
                  if (
                    type === 'org_wallet_topup' ||
                    transactionPurpose === 'org_wallet_topup'
                  ) {
                    return organization?.name || phoneNumber || '-';
                  }
                  if (
                    type === 'credit' &&
                    transactionPurpose === 'org_loan'
                  ) {
                    return group || '-';
                  }
                  if (
                    type === 'credit' &&
                    transactionPurpose === 'org_wallet_withdrawal'
                  )
                    return recipientBank;
                  if (
                    type === 'debit' &&
                    transactionPurpose === 'org_loan'
                  ) {
                    return group?.organization || '-';
                  }
                  if (type === 'loan') {
                    return { name: group?.name, id: groupId };
                  }
                  if (
                    type === 'debit' &&
                    transactionPurpose === 'group_micro_loan'
                  ) {
                    return { name: group?.name, id: groupId };
                  }

                  if (type === 'debit') {
                    return {
                      id: phoneNumber,
                      name: `${initiator?.first_name} ${initiator?.last_name}`,
                    };
                  }
                  return 'Save';
                };

                const recipient = getRecipient(
                  type,
                  transactionPurpose,
                );
                const formatedRecipient =
                  type === 'debit' &&
                  transactionPurpose === 'group_micro_loan'
                    ? recipient.name
                    : (type === 'debit' &&
                        transactionPurpose !== 'group_cash') ||
                      (type === 'credit' &&
                        transactionPurpose === 'org_loan')
                    ? recipient.name
                    : transactionPurpose === 'org_wallet_withdrawal'
                    ? capitalize(recipient)
                    : recipient.id || recipient || '-';

                if (id === 816176) {
                  console.log(
                    'object :>> ',
                    (type === 'debit' &&
                      transactionPurpose !== 'group_cash') ||
                      (type === 'credit' &&
                        transactionPurpose === 'org_loan'),
                  );
                  console.log('recipient :>> ', recipient);
                  console.log(
                    'formatedRecipient :>> ',
                    formatedRecipient,
                  );
                }

                const currentReceipt = {
                  originator,
                  recipient,
                  transactionId: id,
                  date,
                  transactionPurpose,
                  channel,
                  amount,
                  comment,
                  organizationId: organization?.id,
                  org_wallet_id,
                  roles,
                  attachment,
                  initiator,
                  status,
                  description,
                  type,
                  recipientBankAccount,
                };

                return (
                  <Table.Row key={id}>
                    <Table.Cell width="1">{id}</Table.Cell>
                    <Table.Cell className="wallet__transactions--originator">
                      <Thumbnail name={originator} />
                      <span>{originator}</span>
                    </Table.Cell>
                    <Table.Cell>{formatedRecipient}</Table.Cell>
                    <Table.Cell>
                      {new Date(date).toDateString()}
                    </Table.Cell>
                    <Table.Cell>{formatNumber(amount)}</Table.Cell>
                    <Table.Cell>
                      {String(channel).replace(/_/g, ' ')}
                    </Table.Cell>
                    <Table.Cell className="wallet__transactions--status">
                      <div
                        className="wallet__transactions--status--buble"
                        style={{
                          backgroundColor:
                            statusColors[status.trim()],
                        }}
                      />
                      <div>{capitalize(status)}</div>
                    </Table.Cell>
                    <Table.Cell width="1">
                      <Popup
                        key={id}
                        basic
                        hoverable
                        position="left center"
                        on="click"
                        pinned
                        content={
                          <TransactionPopupContent
                            items={getContentItem({
                              status,
                              type,
                              transactionPurpose,
                            })}
                            data={currentReceipt}
                          />
                        }
                        trigger={
                          <div className="status-icon">
                            <span>i</span>
                          </div>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              },
            )}
          </Table.Body>
        </Table>
      </div>

      <SimpleModal
        title={title}
        open={showReceipt}
        onClose={() => {
          setShowReceipt(false);
          setOrgRequestPending(false);
        }}
        contentStyle={{ padding: '0px' }}
      >
        {orgRequestPending === 'topup' ? (
          <Request {...receipt} setShowReceipt={setShowReceipt} />
        ) : orgRequestPending === 'withdrawal' ? (
          <Withdrawal {...receipt} setShowReceipt={setShowReceipt} />
        ) : (
          <Receipt {...receipt} />
        )}
      </SimpleModal>
    </>
  );
};

Transactions.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  viewAll: PropTypes.bool,
  roles: PropTypes.instanceOf(Array),
  currentOrgName: PropTypes.string,
};

export default Transactions;
