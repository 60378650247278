import axiosHelper from 'helpers/axiosHelper';
import { API_REQUEST } from 'constants/apiActions';
import handleAxiosError from 'helpers/handleAxiosError';

export default ({ dispatch }) => next => async ({
  type = '',
  payload = {},
}) => {
  if (type !== API_REQUEST) {
    return next({
      type,
      payload,
    });
  }
  try {
    if (payload.onStart) {
      await payload.onStart();
    } else {
      dispatch({
        type: payload.API_REQUEST_START,
        payload: {
          loading: true,
        },
      });
    }

    const { data, status } = await axiosHelper(
      payload.httpOptions,
    ).request({
      method: payload.method.toLowerCase(),
      url: payload.url,
      data: payload.data,
      params: payload.queries,
      timeout: payload.timeout,
    });

    if (payload.onSuccess) {
      await payload.onSuccess(data, status);
    } else {
      dispatch({
        type: payload.API_REQUEST_SUCCESS,
        payload: data,
      });
      if (payload.callbackOnSuccess)
        await payload.callbackOnSuccess(data, status);
    }
  } catch (e) {
    const error = handleAxiosError(e);
    if (payload.onFailure) {
      await payload.onFailure(error);
    } else {
      dispatch({
        type: payload.API_REQUEST_FAILURE,
        payload: error,
      });
      if (payload.callbackOnFailure)
        await payload.callbackOnFailure(error);
    }
  }
  if (payload.onEnd) {
    await payload.onEnd();
  } else {
    dispatch({
      type: payload.API_REQUEST_END,
      payload: {
        loading: false,
      },
    });
  }

  return true;
};
