import {
  TOP_UP_WALLET_ERROR,
  TOP_UP_WALLET_START,
  TOP_UP_WALLET_SUCCESS,
} from 'constants/action-types/wallets/topUpWallet';
import { ERROR } from 'constants/notificationStatus';

import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';

export default ({
  organizationId,
  walletId,
  data,
  isSaveRwanda,
  callBack,
}) => dispatch => {
  const url = isSaveRwanda
    ? '/wallets/load'
    : `/organizations/${organizationId}/wallets/${walletId}/requests`;

  dispatch(
    apiAction({
      url,
      method: 'POST',
      data,
      API_REQUEST_START: TOP_UP_WALLET_START,
      API_REQUEST_SUCCESS: TOP_UP_WALLET_SUCCESS,
      API_REQUEST_FAILURE: TOP_UP_WALLET_ERROR,
      onSuccess: payload => {
        dispatch({
          type: TOP_UP_WALLET_SUCCESS,
          payload,
        });
        if (callBack) callBack(payload);
      },
      onFailure: error => {
        dispatch({
          type: TOP_UP_WALLET_ERROR,
          error,
        });
        if (error) {
          if (!error.errors) {
            notificationHelper({
              message: error.message,
              status: ERROR,
            });
          }
          if (error.errors) {
            notificationHelper({
              message: error.errors[0].message,
              status: ERROR,
            });
          }
        }
      },
      onEnd: () => false,
    }),
  );
};
