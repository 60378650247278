import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SingleOrganization from 'components/Organizations/SingleOrganization/Settings';
import getSingleOrganization from 'redux/actions/organizations/getSingleOrganization';
import updateSingleOrganization, {
  clearUpdateOrganization,
} from 'redux/actions/organizations/updateSingleOrganization';
import './index.scss';
import notificationHelper from 'helpers/notificationHelper';
import { SUCCESS } from 'constants/notificationStatus';

const OrganizationProfile = () => {
  const { organizations = [] } = useSelector(
    ({
      user: {
        currentUser: { details = {} },
      },
    }) => details,
  );

  const { id: organizationId } = organizations[0] || {};

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const {
    currentOrganization = {},
    getSingleOrganization: {
      loading: getOrganizationLoading,
      error: getOrganizationError,
    },
    updateSingleOrganization: {
      loading: updateOrganizationLoading,
      error: updateOrganizationError,
      message: updateOrganizationMessage,
    },
  } = useSelector(({ organizations }) => organizations);

  const updateOrganization = () => {
    updateSingleOrganization(organizationId, formData)(dispatch);
  };

  useEffect(() => {
    if (organizationId) {
      getSingleOrganization(organizationId)(dispatch);
    }
  }, [organizationId, dispatch]);

  const handleChange = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  useEffect(() => {
    if (currentOrganization.organization) {
      setFormData(currentOrganization.organization);
    }
  }, [currentOrganization]);

  const setTypeCategories = args => {
    setFormData({ ...formData, ...args });
  };

  useEffect(() => {
    if (updateOrganizationMessage) {
      notificationHelper({
        message: updateOrganizationMessage,
        status: SUCCESS,
      });
      clearUpdateOrganization()(dispatch);
    }
  }, [updateOrganizationMessage]);

  return (
    <>
      <SingleOrganization
        formData={formData}
        getOrganizationLoading={getOrganizationLoading}
        getOrganizationError={getOrganizationError}
        updateOrganizationLoading={updateOrganizationLoading}
        updateOrganizationError={updateOrganizationError}
        handleSubmit={updateOrganization}
        handleChange={handleChange}
        typeError={null}
        categoryError={null}
        setTypeCategories={setTypeCategories}
      />
    </>
  );
};

OrganizationProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default OrganizationProfile;
