/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Loader, Message } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Transactions from 'components/Wallet/Transactions';
import getWallet from 'redux/actions/wallets/getWallets';
import Toolbar from 'components/common/Toolbar';
import TopUpSidebar from 'components/common/SidebarFilter/TopUpSidebar';
import CreditSidebar from 'components/common/SidebarFilter/CreditSidebar';
import getTransactions from 'redux/actions/wallets/getTransactions';
import { getOrgTransactions } from 'redux/actions/wallets/getOrgRequests';
import { canAccess, canPerformAction } from 'helpers/permissions';
import Empty from 'components/common/Empty';
import getOrgWithWallet from 'redux/actions/organizations/getOrgWithWallet';
import isParentOrganization from 'helpers/isParentOrganization';
import getUserRoles from 'helpers/getUserRoles';
import WithdrawSidebar from 'components/common/SidebarFilter/WithdrawSidebar';
import { organizationsDropDownOptions } from 'utils/dropdownOptions';
import Balance from './Balance';

const Wallet = () => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState();
  const [form, setForm] = useState({});
  const { push } = useHistory();
  const isCurrentOrgParent = isParentOrganization();

  const { loading, items, error } = useSelector(
    ({ wallets: { wallets } }) => wallets,
  );

  const { items: organizations = [] } = useSelector(
    ({ organizations: { hasWallet } }) => hasWallet,
  );

  const {
    loading: loadingRequests,
    items: requests,
    error: errorRequests,
  } = useSelector(
    ({ wallets: { topupOrgRequests } }) => topupOrgRequests,
  );

  const {
    currentOrgId,
    currentOrgName,
    saveType,
    orgUsers,
    roles,
  } = getUserRoles();

  const [currentOrganization, setCurrentOrganization] = useState({
    name: currentOrgName,
    id: currentOrgId,
    type: saveType || 'ngos',
  });

  const { has_wallet } = useSelector(
    ({
      dashboard: {
        currentOrganization: { details = { user: {} } },
      },
    }) => ({ ...details }),
  );

  const {
    loading: loadingTransactions,
    data: transactions,
    error: transactionError,
  } = useSelector(({ wallets: { transactions } }) => transactions);

  useEffect(() => {
    if (
      !isEmpty(roles) &&
      currentOrgId &&
      !canAccess('wallet', roles, {
        currentOrgId,
        orgUsers,
        additionalCondition: has_wallet,
      })
    ) {
      push('/');
    }
  }, [orgUsers, currentOrgId]);

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (isCurrentOrgParent) {
      getWallet()(dispatch);
      getOrgWithWallet()(dispatch);
      getTransactions()(dispatch);
    } else {
      getWallet(currentOrgId)(dispatch);
      getOrgTransactions({ organization_id: currentOrgId })(dispatch);
    }
  }, [isCurrentOrgParent]);

  const handleActionClicked = type => {
    setActionType(type);
  };

  const onQuery = queries => {
    getTransactions(queries)(dispatch);
  };

  const dropdownDefaultValue = organizations =>
    organizationsDropDownOptions(organizations).find(
      ({ value }) => value === currentOrgId,
    );

  const onDropdownChange = (e, { value }) => {
    const currentOrg = organizations?.find(org => org?.id === value);

    setCurrentOrganization({
      id: currentOrg?.id,
      name: currentOrg?.name,
      type: currentOrg?.save_type || 'ngos',
    });

    if (
      ['save_country', 'save_global'].includes(
        organizations.find(org => org.id === value).save_type,
      )
    ) {
      getWallet()(dispatch);
      return getTransactions()(dispatch);
    }
    getWallet(value)(dispatch);
    onQuery({ organization_id: value });
  };

  const headerContent = {
    rightComponent: canPerformAction('wallet', roles, {
      currentOrgId,
      orgUsers,
      additionalCondition: has_wallet,
    }) && (
      <>
        <Button
          primary
          className="wallet__top-up"
          onClick={() => handleActionClicked('top-up')}
        >
          Top-up
        </Button>

        <Button
          primary
          onClick={() =>
            handleActionClicked(
              isCurrentOrgParent ? 'credit' : 'withdraw',
            )
          }
        >
          {isCurrentOrgParent ? 'Credit' : 'Withdraw'}
        </Button>

        <TopUpSidebar
          {...{
            filterOn: actionType === 'top-up',
            form,
            setForm,
            handleChange,
            types: [],
            setActionType,
            roles,
            currentOrgId,
            wallets: { items },
            orgUsers,
          }}
        />

        {isCurrentOrgParent ? (
          <CreditSidebar
            {...{
              filterOn: actionType === 'credit',
              form,
              setForm,
              handleChange,
              setActionType,
              roles,
            }}
          />
        ) : (
          <WithdrawSidebar
            {...{
              filterOn: actionType === 'withdraw',
              form,
              setForm,
              handleChange,
              setActionType,
              roles,
              currentOrgId,
            }}
          />
        )}
      </>
    ),
  };

  return (
    <div className="wallet">
      <Toolbar
        header={
          isCurrentOrgParent
            ? {
                walletSelection: {
                  title: 'Wallet:',
                  options: organizationsDropDownOptions(
                    organizations,
                  ),
                  defaultValue: dropdownDefaultValue(organizations),
                  onChange: onDropdownChange,
                },
                ...headerContent,
              }
            : {
                title: `Wallet: ${currentOrgName || ''}`,
                ...headerContent,
              }
        }
        body={
          <>
            {error && <Message>{error.message}</Message>}
            {!loading && items && items.length > 0 && !error && (
              <Balance
                data={items}
                currentOrg={currentOrganization}
              />
            )}
          </>
        }
        footer={
          <>
            {!loading && (
              <div className="wallet__footer-wrapper">
                <Toolbar
                  header={{
                    title: 'Transactions',
                    rightComponent: (
                      <Button onClick={() => push('/transactions')}>
                        View all
                      </Button>
                    ),
                  }}
                />
              </div>
            )}
          </>
        }
      />
      {(!!transactionError || !!errorRequests) && (
        <Message negative>
          {transactionError?.message || errorRequests?.message}
        </Message>
      )}

      {(loadingTransactions || loadingRequests) && (
        <Loader active inline="centered" />
      )}

      {isCurrentOrgParent
        ? !loadingTransactions &&
          !transactionError &&
          transactions?.length === 0 && (
            <Empty message="No transactions found" />
          )
        : !loadingRequests &&
          !errorRequests &&
          requests?.length === 0 && (
            <Empty message="No Requests found" />
          )}

      {isCurrentOrgParent
        ? !loadingTransactions &&
          !transactionError &&
          transactions?.length > 0 && (
            <Transactions
              currentOrgName={currentOrgName}
              items={transactions}
              roles={roles}
            />
          )
        : !loadingRequests &&
          !errorRequests &&
          requests?.length > 0 && (
            <Transactions
              currentOrgName={currentOrgName}
              items={requests}
              roles={roles}
            />
          )}
    </div>
  );
};

export default Wallet;
