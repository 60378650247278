import PropTypes from 'prop-types';
import React from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import './Pagination.scss';

const Pagination = ({ onPaginate, paginationMeta, updateUrl }) => {
  let urlQueries = '';
  const { push } = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);

  const handlePagination = activePage => {
    queries.page = activePage;
    if (activePage > 0) {
      onPaginate(queries);
    }

    if (updateUrl) {
      Object.keys(queries || {}).forEach(key => {
        if (key && queries[key]) {
          urlQueries += `${urlQueries && '&'}${key}=${queries[key]}`;
        }
      });
      push(`?${urlQueries}`);
    }
  };

  return (
    <SemanticPagination
      boundaryRange={0}
      activePage={paginationMeta.page || queries.page || 1}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      siblingRange={1}
      totalPages={paginationMeta.pages ? paginationMeta.pages : 0}
      disabled={paginationMeta.pages < 2}
      className="toolbar-pagination"
      onPageChange={(e, { activePage }) =>
        handlePagination(activePage)
      }
    />
  );
};

Pagination.defaultProps = {
  onPaginate: value => value,
  paginationMeta: {},
  updateUrl: true,
};

Pagination.propTypes = {
  onPaginate: PropTypes.func,
  paginationMeta: PropTypes.instanceOf(Object),
  updateUrl: PropTypes.bool,
};

export default Pagination;
