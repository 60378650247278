import initialState from 'redux/initial-states/user';
import login from './login';
import logout from './logout';
import usersList from './usersList';
import addUser from './addUser';
import getSingleUser from './getSingleUser';
import deactivateUser from './deactivateUser';
import currentUser from './currentUser';
import updateUser from './updateUser';
import resetPassword from './resetPassword';
import changePin from './changePin';
import resetUserPassword from './resetUserPassword';
import searchUser from './searchUser';
import searchAgent from './searchAgent';
import deleteUser from './deleteUser';
import updateUserPhone from './updateUserPhone';
import getUserRoles from './getUserRoles';
import toggleRolesInBulk from './toggleRolesInBulk';
import toggleUserRoles from './toggleUserRoles';
import unblockUser from './unblockUser';
import transferGroups from './transferGroups';
import createReferralAlias from './createReferralAlias';
import getUserTransactions from './getUserTransactions';

export default (state = initialState, action = {}) => ({
  ...state,
  ...login(state, action),
  ...logout(state, action),
  ...addUser(state, action),
  ...getSingleUser(state, action),
  ...toggleRolesInBulk(state, action),
  ...toggleUserRoles(state, action),
  ...deactivateUser(state, action),
  ...currentUser(state, action),
  ...usersList(state, action),
  ...updateUser(state, action),
  ...updateUserPhone(state, action),
  ...resetPassword(state, action),
  ...changePin(state, action),
  ...resetUserPassword(state, action),
  ...searchUser(state, action),
  ...searchAgent(state, action),
  ...deleteUser(state, action),
  ...getUserRoles(state, action),
  ...unblockUser(state, action),
  ...transferGroups(state, action),
  ...createReferralAlias(state, action),
  ...getUserTransactions(state, action),
});
