import {
  ADD_TO_COMMITTEE_ERROR,
  ADD_TO_COMMITTEE_START,
  ADD_TO_COMMITTEE_SUCCESS,
} from 'constants/action-types/organizations/addToCommittee';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_TO_COMMITTEE_START:
      return {
        ...state,
        addToCommittee: {
          ...state.addToCommittee,
          loading: true,
          error: null,
          data: [],
        },
      };
    case ADD_TO_COMMITTEE_SUCCESS:
      return {
        ...state,
        addToCommittee: {
          ...state.addToCommittee,
          loading: false,
          error: null,
          data: payload,
        },
      };
    case ADD_TO_COMMITTEE_ERROR:
      return {
        ...state,
        addToCommittee: {
          ...state.addToCommittee,
          loading: false,
          error: payload,
        },
      };
    default:
      return null;
  }
};
