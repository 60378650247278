import AddUser from 'containers/Users/AddUser';

export default {
  exact: true,
  name: 'Create User',
  protected: true,
  path: '/create-user',
  component: AddUser,
  permissions: ['all'],
};
