import {
  GET_ORGANIZATION_FSP_OVERVIEW_ERROR,
  GET_ORGANIZATION_FSP_OVERVIEW_START,
  GET_ORGANIZATION_FSP_OVERVIEW_SUCCESS,
} from 'constants/action-types/organizations/fspOrganizationOverview';
import apiAction from 'helpers/apiAction';

export default (organizationId, queries) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/fsp/overview`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_FSP_OVERVIEW_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_FSP_OVERVIEW_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_FSP_OVERVIEW_ERROR,
      onEnd: () => false,
    }),
  );
};
