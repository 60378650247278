import {
  UPDATE_BILL_ERROR,
  UPDATE_BILL_START,
  UPDATE_BILL_SUCCESS,
} from 'constants/action-types/settings/updateBill';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default (id, data) => (dispatch, push, callback) => {
  dispatch(
    apiAction({
      url: `/admin/billing/transactions_charges_settings/${id}`,
      method: 'PUT',
      data,
      API_REQUEST_START: UPDATE_BILL_START,
      API_REQUEST_FAILURE: UPDATE_BILL_ERROR,
      API_REQUEST_SUCCESS: UPDATE_BILL_SUCCESS,
      callbackOnSuccess: ({ message }) => {
        callback();
        push('/settings/billing');
        notification({ message });
      },
      callbackOnFailure: ({ message }) => {
        if (message !== 'Wrong PIN.') {
          callback();
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
};
