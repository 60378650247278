import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'semantic-ui-react';
import Loader from 'components/common/PageLoader';
import Empty from 'components/common/Empty';
import Toolbar from 'components/common/Toolbar';
import BillingList from 'components/Settings/Billing/BillingList';
import getAllBills from 'redux/actions/settings/getAllBills';

const BillRequests = () => {
  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(
    ({ settings: { allBills } }) => allBills,
  );

  useEffect(() => {
    getAllBills()(dispatch);
  }, []);

  const bills = data?.filter(bill => bill?.status === 'inactive');

  return (
    <>
      <Toolbar
        header={{
          title: 'New Bill Requests',
          navigation: true,
        }}
        style={{
          marginLeft: '0px',
        }}
      />
      <div className="billings">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : bills.length === 0 ? (
          <Empty message="No billings found" />
        ) : (
          <BillingList bills={bills} />
        )}
      </div>
    </>
  );
};

export default BillRequests;
