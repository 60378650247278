import AllOrganizations from 'containers/Organizations/AllNGOs';

export default {
  exact: true,
  name: 'NGOs',
  protected: true,
  path: '/ngos',
  component: AllOrganizations,
  permissions: ['all'],
};
