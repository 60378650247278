import {
  CREATE_ORGANIZATION_ERROR,
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_SUCCESS,
} from 'constants/action-types/organizations/createOrganization';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_ORGANIZATION_START:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
        },
      };
    case CREATE_ORGANIZATION_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          error: payload,
          loading: false,
        },
      };
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
        },
      };
    default:
      return null;
  }
};
