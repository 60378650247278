import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import {
  SEND_BULK_PUSH_NOTIFICATION_ERROR,
  SEND_BULK_PUSH_NOTIFICATION_START,
  SEND_BULK_PUSH_NOTIFICATION_SUCCESS,
} from 'constants/action-types/organizations/pushNotifications';
import { HTTP_NO_CONTENT } from 'constants/httpStatusCodes';
import getAllPushNotifications from './getAllPushNotifications';

export default data => dispatch =>
  dispatch(
    apiAction({
      url: '/users/notifications/push_notifications',
      method: 'POST',
      data,
      API_REQUEST_START: SEND_BULK_PUSH_NOTIFICATION_START,
      API_REQUEST_SUCCESS: SEND_BULK_PUSH_NOTIFICATION_SUCCESS,
      API_REQUEST_FAILURE: SEND_BULK_PUSH_NOTIFICATION_ERROR,
      onStart: () =>
        dispatch({ type: SEND_BULK_PUSH_NOTIFICATION_START }),
      callbackOnSuccess: ({ message, status }) => {
        if (status === HTTP_NO_CONTENT) {
          return notification({
            message: 'No user found.',
            status: 'warn',
          });
        }
        notification({ message });
        return getAllPushNotifications()(dispatch);
      },
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
