/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import formatNumber from 'helpers/formatNumber';
import getAllGroups from 'redux/actions/groups/getAllGroups';
import GroupList from 'components/Groups/GroupsList';
import './index.scss';
import capitalize from 'utils/capitalize';
import SidebarFilter from 'components/common/SidebarFilter';
import { formatSearchParameter } from 'helpers/formatUrlParameter';

const AllGroups = ({ type, filter = '' }) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const [filterForm, setFilterForm] = useState({});
  const [filterOn, setFilterOn] = useState(false);
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);
  const dispatch = useDispatch();

  const {
    items: groups = [],
    meta = {},
    loading,
    error,
  } = useSelector(({ groups: { allGroups } }) => allGroups);

  const orderOption = filter.split('-')[1];
  const orderBy = filter.split('-')[0];

  const filterGroups = () => {
    const { search, status, access_bank, type } = filterForm;
    getAllGroups({
      ...params,
      search,
      status,
      access_bank,
      type,
      group_type: type,
      order_by: filter ? orderBy : undefined,
      order_option: filter ? orderOption : undefined,
    })(dispatch);
  };

  useEffect(() => {
    if (!isEmpty(filterForm)) return filterGroups();
    if (search) return setFilterForm(params);
    return getAllGroups({
      group_type: type,
      order_by: filter ? orderBy : undefined,
      order_option: filter ? orderOption : undefined,
    })(dispatch);
  }, [type, filter, filterForm]);

  const defaultValue = {
    search: filterForm?.search,
    status: filterForm?.status,
    access_bank: filterForm?.access_bank,
    type: filterForm?.type,
  };

  const handleChange = (e, { value, name }) => {
    if (name === 'type')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'type'),
          [name]: value,
        }),
      );
    if (name === 'status')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'status'),
          [name]: value,
        }),
      );
    if (name === 'access_bank')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'access_bank'),
          [name]: value,
        }),
      );
    setFilterForm({ ...filterForm, [name]: value });
  };

  const handleGetGroups = page => {
    if (page) {
      getAllGroups({
        page,
        group_type: type,
        order_by: orderBy,
        order_option: orderOption || 'DESC',
      })(dispatch);
      return;
    }
    getAllGroups({
      group_type: type,
      order_by: orderBy,
      order_option: orderOption,
    })(dispatch);
  };

  const onQuery = queries => {
    getAllGroups({
      ...queries,
      group_type: type,
      order_by: orderBy,
      order_option: orderOption,
    })(dispatch);
  };

  return (
    <>
      <Toolbar
        header={{
          title: `${
            type ? capitalize(type) : 'All Saving Groups'
          } (${formatNumber(meta.total || '0')})`,
          rightComponent: (
            <ToolbarFooter
              onSearch={onQuery}
              onPaginate={onQuery}
              paginationMeta={meta}
              exportCSVData={groups}
              exportCSVFileName="Groups"
              filterClicked={() => setFilterOn(true)}
              filter
            />
          ),
        }}
      />
      <GroupList
        {...{
          handleGetGroups,
          loading,
          error,
          groups,
        }}
      />
      <SidebarFilter
        {...{
          filterOn,
          setFilterOn,
          handleChange,
          form: filterForm,
          setForm: setFilterForm,
          activity: 'allSavingGroups',
        }}
      />
    </>
  );
};

AllGroups.defaultProps = {
  filter: undefined,
  type: undefined,
};

AllGroups.propTypes = {
  filter: PropTypes.string,
  type: PropTypes.string,
};

export default AllGroups;
