import {
  GET_EXPORT_DATA_START,
  GET_EXPORT_DATA_SUCCESS,
  GET_EXPORT_DATA_ERROR,
} from 'constants/action-types/export/exportData';

import apiAction from 'helpers/apiAction';

export default (url, queries) => dispatch => {
  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_EXPORT_DATA_START,
      API_REQUEST_SUCCESS: GET_EXPORT_DATA_SUCCESS,
      API_REQUEST_FAILURE: GET_EXPORT_DATA_ERROR,
      onSuccess: (data, status) => {
        dispatch({
          type: GET_EXPORT_DATA_SUCCESS,
          payload: {
            data: {
              ...data,
              message:
                status === 204 ? 'No data found in that range' : null,
            },
          },
        });
      },
      onEnd: () => false,
    }),
  );
};
