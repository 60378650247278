const requiredFields = {
  amend: [
    'comment',
    'repayment_days',
    'repayment_installments',
    'provider_name',
    'password',
    'amount',
  ],
  accept: ['password'],
  reject: ['password', 'comment'],
};

export const validateAmount = (amount, walletBalance) => {
  if (amount > walletBalance)
    return `Selected Wallet doesn't have ${amount} RWF`;
};

const isOrgRequestFormValid = (action, form) => {
  return requiredFields[action].every(field => form[field]);
};

export default isOrgRequestFormValid;
