import React, { useState } from 'react';
import './settingModals.scss';
import Proptype from 'prop-types';
import { Modal, Menu } from 'semantic-ui-react';
import labelFormatter from 'helpers/labelFormatter';

const SettingModals = ({
  panes,
  data,
  states,
  showBulkEditModal,
  size,
}) => {
  const [activeItem, setActiveItem] = useState('Basic Details');
  const handleItemClick = (e, { name }) => setActiveItem(name);

  return (
    <Modal
      onClose={() => showBulkEditModal(false)}
      open={states.isOpened}
      className="settingModals"
      size={size}
      closeIcon
    >
      <Modal.Header>{`Edit ${labelFormatter(
        data?.type,
      )}`}</Modal.Header>
      <Menu pointing secondary text={false}>
        {panes?.map(({ menuItem }) => (
          <Menu.Item
            key={menuItem}
            name={`${menuItem}`}
            active={activeItem === menuItem}
            onClick={handleItemClick}
          />
        ))}
      </Menu>

      <div className="settingModals__content">
        {panes
          ?.find(item => item.menuItem === activeItem)
          ?.render(data)}
      </div>
    </Modal>
  );
};

SettingModals.propTypes = {
  panes: Proptype.array.isRequired,
  states: Proptype.shape({
    loading: Proptype.bool,
    isOpened: Proptype.bool,
    errors: Proptype.string,
  }).isRequired,
  data: Proptype.object.isRequired,
  showBulkEditModal: Proptype.func.isRequired,
  size: Proptype.oneOf(['tiny', 'mini', 'small']).isRequired,
};

export default SettingModals;
