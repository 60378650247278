import {
  RECOMPUTE_GROUP_BALANCE_START,
  RECOMPUTE_GROUP_BALANCE_SUCCESS,
  RECOMPUTE_GROUP_BALANCE_FAILED,
} from 'constants/action-types/groups/recomputeGroupBalance';
import notification from 'helpers/notificationHelper';
import { ERROR } from 'constants/notificationStatus';
import apiAction from 'helpers/apiAction';
import { getSingleGroup } from 'redux/actions/organizations/organizationGroups';

export default groupId => dispatch => {
  dispatch(
    apiAction({
      url: `/groups/${groupId}/refresh_balance`,
      method: 'PUT',
      API_REQUEST_START: RECOMPUTE_GROUP_BALANCE_START,
      API_REQUEST_SUCCESS: RECOMPUTE_GROUP_BALANCE_SUCCESS,
      API_REQUEST_FAILURE: RECOMPUTE_GROUP_BALANCE_FAILED,
      callbackOnSuccess: ({ message }) => {
        notification({ message });
        getSingleGroup(groupId)(dispatch);
      },
      callbackOnFailure: ({ message }) => {
        dispatch({
          type: RECOMPUTE_GROUP_BALANCE_FAILED,
          payload: message,
        });
        notification({ message, status: ERROR });
      },
      onEnd: () => false,
    }),
  );
};
