import {
  CREATE_ORGANIZATION_ERROR,
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_SUCCESS,
} from 'constants/action-types/organizations/createOrganization';
import apiAction from 'helpers/apiAction';
import notify from 'helpers/notificationHelper';
import getOrganizations from 'redux/actions/organizations/listOrganizations';

export default data => (dispatch, push) => {
  return dispatch(
    apiAction({
      url: '/organizations',
      method: 'POST',
      data,
      API_REQUEST_START: CREATE_ORGANIZATION_START,
      API_REQUEST_SUCCESS: CREATE_ORGANIZATION_SUCCESS,
      API_REQUEST_FAILURE: CREATE_ORGANIZATION_ERROR,
      onSuccess: ({ message }) => {
        notify({
          message,
        });

        dispatch({
          type: CREATE_ORGANIZATION_SUCCESS,
        });

        getOrganizations()(dispatch);

        push('/');
      },
      onEnd: () => false,
    }),
  );
};
