import {
  GET_SINGLE_ORGANIZATION_START,
  GET_SINGLE_ORGANIZATION_SUCCESS,
  GET_SINGLE_ORGANIZATION_FAILURE,
  GET_SINGLE_ORGANIZATION_END,
} from 'constants/action-types/organizations/getSingleOrganization';
import {
  GET_ORGANIZATION_SEARCH_ERROR,
  GET_ORGANIZATION_SEARCH_START,
  GET_ORGANIZATION_SEARCH_SUCCESS,
} from 'constants/action-types/organizations/organizationSearch';
import apiAction from 'helpers/apiAction';
import { HTTP_NOT_FOUND } from 'constants/httpStatusCodes';
import { UNEXPECTED_ERROR } from 'constants/response-messages/httpRequests';

export const getOrganizationByName = organizationName => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/list?search=${organizationName}`,
      method: 'GET',
      API_REQUEST_START: GET_ORGANIZATION_SEARCH_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_SEARCH_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_SEARCH_ERROR,
      onSuccess: ({ data }) => {
        return dispatch({
          type: GET_ORGANIZATION_SEARCH_SUCCESS,
          payload: data,
        });
      },
      onEnd: () => false,
    }),
  );
};

export default organizationId => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}`,
      API_REQUEST_START: GET_SINGLE_ORGANIZATION_START,
      API_REQUEST_END: GET_SINGLE_ORGANIZATION_END,
      onSuccess: ({ data }) => {
        return dispatch({
          type: GET_SINGLE_ORGANIZATION_SUCCESS,
          payload: data,
        });
      },
      onFailure: error => {
        if (
          error.status === HTTP_NOT_FOUND ||
          Number.isNaN(Number(organizationId))
        ) {
          return dispatch({
            type: GET_SINGLE_ORGANIZATION_FAILURE,
            payload: `Organization with id "${organizationId}" does not exist`,
          });
        }
        return dispatch({
          type: GET_SINGLE_ORGANIZATION_FAILURE,
          payload: UNEXPECTED_ERROR,
        });
      },
    }),
  );
