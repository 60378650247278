import { agentOverview, agentProjectOverview } from './overview';
import agents from './agents';
import invite from './invite';
import groups from './groups';
import activities from './activities';

export default [
  agentOverview,
  agentProjectOverview,
  agents,
  invite,
  groups,
  activities,
];
