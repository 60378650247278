import {
  RECOMPUTE_GROUP_BALANCE_START,
  RECOMPUTE_GROUP_BALANCE_SUCCESS,
  RECOMPUTE_GROUP_BALANCE_FAILED,
} from 'constants/action-types/groups/recomputeGroupBalance';

export default (state, { type, payload }) => {
  switch (type) {
    case RECOMPUTE_GROUP_BALANCE_START:
      return {
        ...state,
        recomputeGroupBalance: {
          ...state.recomputeGroupBalance,
          loading: true,
          error: null,
          message: null,
        },
      };
    case RECOMPUTE_GROUP_BALANCE_SUCCESS:
      return {
        ...state,
        recomputeGroupBalance: {
          ...state.recomputeGroupBalance,
          message: payload.message,

          error: null,
          loading: false,
        },
      };
    case RECOMPUTE_GROUP_BALANCE_FAILED:
      return {
        ...state,
        recomputeGroupBalance: {
          ...state.recomputeGroupBalance,
          loading: false,
          error: payload,
          message: null,
        },
      };
    default:
      return null;
  }
};
