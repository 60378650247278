import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Thumbnail from 'components/common/Thumbnail';
import currencyFormat from 'utils/currencyFormat';
import './index.scss';

const GroupDetails = ({
  data: {
    group: {
      name,
      cycle,
      saving_frequency: savingFrequency,
      saving_time: savingTime,
      share_value_min: shareMinValue,
      share_value_max: shareMaxValue,
      social_fund: socialFund,
      province,
      district,
      type,
      created_at: createdAt,
      interest_rate: interestRate,
      saving_delay_fine: savingDelayFine,
      social_fund_delay_fine: socialFundDelayFine,
      attendance_delay_fine: attendaceDelayFine,
      meeting_absence_fine: meetingAbsenceFine,
      status,
      access_bank,
      access_bank_wallet_number,
    } = {},
  },
}) => {
  return (
    <div className="group-info">
      <div className="info-wrapper">
        <div className="header">
          <Thumbnail
            name={name}
            style={{ width: '50px', height: '50px' }}
          />
          <div className="name">{name}</div>
        </div>

        <div className="group-details">
          <div className="details">
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Cycle</Table.Cell>
                  <Table.Cell className="right-align">
                    {cycle || ''}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Saving Frequency</Table.Cell>
                  <Table.Cell className="right-align">
                    {savingFrequency}, {savingTime}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Minimum Share</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(shareMinValue)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Maximum Share</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(shareMaxValue)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Social fund contribution</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(socialFund)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Interest rate</Table.Cell>
                  <Table.Cell className="right-align">
                    {interestRate} %
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Saving delay fine</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(savingDelayFine)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Social fund delay fine</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(socialFundDelayFine)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Attendance delay fine</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(attendaceDelayFine)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Meeting absence fine</Table.Cell>
                  <Table.Cell className="right-align">
                    {currencyFormat(meetingAbsenceFine)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Location</Table.Cell>
                  <Table.Cell className="right-align">
                    <div className="locations">
                      <div className="location">
                        {/* {district}, {province} */}
                        Kigali, Central
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Wallet</Table.Cell>
                  <Table.Cell className="right-align">
                    {access_bank ? 'Access Bank' : 'Telco'}
                  </Table.Cell>
                </Table.Row>

                {access_bank && (
                  <Table.Row>
                    <Table.Cell>Account No</Table.Cell>
                    <Table.Cell className="right-align">
                      {access_bank_wallet_number}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="label_container">
          <div className="label">{type}</div>
          <div className={`label ${String(status).toLowerCase()}`}>
            {status}
          </div>
        </div>
        <div className="content">
          Joined on {new Date(createdAt).toDateString()}
        </div>
      </div>
    </div>
  );
};

GroupDetails.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default GroupDetails;
