import {
  COUNT_PROFILE_GROUPS_FAILED,
  COUNT_PROFILE_GROUPS_START,
  COUNT_PROFILE_GROUPS_SUCCESS,
} from 'constants/action-types/profiles/countProfileGroups';

export default (state, { type, payload }) => {
  switch (type) {
    case COUNT_PROFILE_GROUPS_START:
      return {
        ...state,
        countProfileGroups: {
          ...state.countProfileGroups,
          loading: true,
          error: null,
        },
      };
    case COUNT_PROFILE_GROUPS_FAILED:
      return {
        ...state,
        countProfileGroups: {
          ...state.countProfileGroups,
          error: payload,
          loading: false,
        },
      };
    case COUNT_PROFILE_GROUPS_SUCCESS:
      return {
        ...state,
        countProfileGroups: {
          ...state.countProfileGroups,
          data: payload.data,
          loading: false,
        },
      };
    default:
      return null;
  }
};
