import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';
import ProfileInfo from 'components/Profile/ProfileForm/ProfileInfo';
import ProfileInterest from 'components/Profile/ProfileForm/ProfileInterest';
import countProfileGroups from 'redux/actions/profiles/countProfileGroups';
import getCurrentOrg from 'helpers/getCurrentOrg';
import editProfile from 'redux/actions/profiles/editProfile';
import getProfileOverview from 'redux/actions/organizations/profiles/profileOverview';
import validateProfileForm from 'helpers/validators/validateProfileForm';
import profileFormErrors from 'constants/response-messages/profiles/profileFormErrors';

const EditProfile = ({ backToProfileOverview }) => {
  const [activePage, setActivePage] = useState(0);
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  const params = useParams();
  const {
    organizationId,
    profileId: theProfileId,
    tabOption,
  } = params;
  const profileId = theProfileId || tabOption;

  const [disableForm, setDisableForm] = useState(
    !(
      !!form?.name &&
      !!form?.cycles_count &&
      !!form?.min_monthly_savings &&
      !!form?.min_monthly_loans
    ),
  );
  const [formError, setFormError] = useState(profileFormErrors);
  const unRequiredData = [
    'created_at',
    'id',
    'groups_count',
    'groups_members_count',
    'organization',
    'organization_id',
    'updated_at',
    'user_id',
    'month',
    'provinces',
    'year',
  ];

  const dispatch = useDispatch();
  const { push } = useHistory();
  const currentOrganization = getCurrentOrg();

  const {
    createProfile: { loading },
    profileList: { items: profiles },
  } = useSelector(({ profiles: { createProfile, profileList } }) => ({
    createProfile,
    profileList,
  }));

  const { data: profile, loading: loadingProfile } = useSelector(
    ({ profiles: { profileOverview } }) => profileOverview,
  );

  useEffect(() => {
    getProfileOverview(
      organizationId || currentOrganization?.id,
      profileId,
    )(dispatch);
  }, [currentOrganization, profileId]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      setDisableForm(
        !profile?.profile?.name &&
          !profile?.profile?.cycles_count &&
          !profile?.profile?.min_monthly_savings &&
          !profile?.profile?.min_monthly_loans,
      );

      setData({
        ...omit(profile?.profile, unRequiredData),
        districts: [],
        sectors: [],
      });
    }
  }, [profile]);

  const validateForm = ({ name, value, type }) => {
    if (type === 'number') value = Number(value);

    validateProfileForm({
      name,
      value,
      form,
      formError,
      profiles: profiles.filter(
        prof => prof.id !== Number(profileId),
      ),
      setFormError,
      setDisableBtn,
    });

    setForm({ ...form, [name]: value });
  };

  const onFieldChange = ({ name, value }) => {
    const fieldData = {
      ...data,
      ...form,
      profile_type: undefined,
      [name]: value,
    };

    countProfileGroups({
      id: organizationId || currentOrganization?.id,
      data: fieldData,
    })(dispatch, setActivePage);

    setForm(fieldData);
  };

  const handlechange = (e, { name, value, type }) => {
    if (type === 'number') value = Number(value);
    setForm({
      ...data,
      ...form,
      [name]: value,
    });
  };

  const handleNext = () => {
    const data = {
      ...omit(profile?.profile, unRequiredData),
      provinces: [],
      districts: [],
      sectors: [],
      ...form,
      profile_type: undefined,
    };
    countProfileGroups({
      id: organizationId || currentOrganization?.id,
      data,
    })(dispatch, setActivePage);
  };

  const handleSubmit = () => {
    const data = {
      ...omit(profile?.profile, unRequiredData),
      provinces: [],
      districts: [],
      sectors: [],
      ...form,
      profile_type: undefined,
    };
    editProfile({
      orgId: organizationId || currentOrganization?.id,
      profileId,
      data,
    })(dispatch, push, setForm);
  };

  return (
    <>
      {activePage === 0 && (
        <ProfileInfo
          form={form}
          defaultValues={profile?.profile}
          loadingProfile={loadingProfile}
          profiles={profiles}
          handlechange={handlechange}
          validateForm={validateForm}
          handleNext={handleNext}
          disableBtn={disableBtn || disableForm}
          formError={formError}
          backToProfileOverview={backToProfileOverview}
          customizedTitle
        />
      )}
      {activePage === 1 && (
        <ProfileInterest
          form={form}
          profile={profile?.profile}
          loading={loading}
          handlechange={handlechange}
          disableBtn={disableBtn || disableForm}
          setPage={setActivePage}
          handleSubmit={handleSubmit}
          submitBtnText="Save"
          onFieldChange={onFieldChange}
          onIconChange={() => setActivePage(0)}
          backToProfileOverview={backToProfileOverview}
          customizedTitle
        />
      )}
    </>
  );
};

EditProfile.propTypes = {
  backToProfileOverview: PropTypes.func.isRequired,
};

export default EditProfile;
