/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon } from 'semantic-ui-react';
import './index.scss';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import LineChartContainer from 'components/common/charts/LineChart/Container';
import todaysDate from 'utils/todaysDate';
import currencyFormat from 'utils/currencyFormat';

const InternalLoanOverview = ({ open, setOpen, request }) => {
  return (
    <Modal
      size="small"
      open={open}
      className="internal-loan-overview-modal"
      onClose={setOpen}
    >
      <Modal.Header className="simple-modal__header">
        <span>Internal Loans overview</span>
        <Icon
          name="close"
          fitted
          className="pointer"
          onClick={setOpen}
        />
      </Modal.Header>

      <Modal.Content>
        <div className="internal-loan-overview-modal__container">
          <LineChartContainer
            title="CUMULATIVE LOANS"
            date={todaysDate()}
            amount={currencyFormat(
              request?.group?.loans_total_with_interest,
            )}
            background={greenColor}
            noLineCharts
          />
          <LineChartContainer
            title="OUTSTANDING LOAN"
            date={todaysDate()}
            amount={currencyFormat(request?.group?.loans_balance)}
            background={redColor}
            noLineCharts
          />
          <LineChartContainer
            title="LOAN WRITE OFFS"
            date={todaysDate()}
            amount={currencyFormat(
              request?.group?.loan_write_off_total,
            )}
            background={blueColor}
            noLineCharts
          />
          <LineChartContainer
            title="OVERDUE LOAN"
            date={todaysDate()}
            amount={currencyFormat(
              request?.group?.overdue_loans_total,
            )}
            background={orangeColor}
            noLineCharts
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

InternalLoanOverview.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  request: PropTypes.object.isRequired,
};

export default InternalLoanOverview;
