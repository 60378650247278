import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Divider, Popup } from 'semantic-ui-react';
import moment from 'moment';
import smsDeliveredIcon from 'assets/images/delivered.png';
import smsFailedIcon from 'assets/images/Failed.png';
import formatNumber from 'helpers/formatNumber';
import toPlural from 'helpers/toPlural';
import './ActivityItem.scss';
import capitalize from '../../../utils/capitalize';
import ActivityPopupDetails from './ActivityPopupDetails';

const ActivityItem = ({
  data: {
    subject,
    organization,
    sent_count: sentCount = 0,
    success_count: successCount,
    failed_count: failedCount,
    organization_id: organizationId,
    sender = null,
    project = null,
    user_type: userType,
    group = null,
    created_at: date,
    content,
  },
  viewAll = false,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <>
      <div className="sms-item">
        <div className="sms-item-body">
          <div className="sms-item-body__left">
            <span className="sms-subject">{capitalize(subject)}</span>
            <span className="highlighter">
              {`${sentCount} ${toPlural('message', sentCount)} sent`}
            </span>
            <br />
            <span className="sms-description">
              sent to{' '}
              {organization === null ? (
                'All'
              ) : (
                <Link to={`/organizations/${organizationId}`}>
                  {capitalize(organization.name)}
                </Link>
              )}
              ,{' '}
              {moment(date).isValid() &&
                moment(date).format('MM/DD/YYYY LT')}
            </span>
          </div>
        </div>
        <div className="sms-item-details">
          {viewAll && (
            <>
              {sentCount !== null && (
                <div className="sms-sent">
                  <img
                    src={smsDeliveredIcon}
                    className="sms-sent__icon"
                    alt="email delivered"
                  />
                  <span className="sms-sent__label green">
                    Delivered {formatNumber(sentCount)}
                  </span>
                </div>
              )}

              {failedCount !== null && (
                <div className="sms-sent">
                  <img
                    src={smsFailedIcon}
                    className="sms-sent__icon"
                    alt="email failed"
                  />
                  <span className="sms-sent__label red">
                    Failed {formatNumber(failedCount)}
                  </span>
                </div>
              )}
            </>
          )}
          <Popup
            trigger={
              <div className="status-icon">
                <span>i</span>
              </div>
            }
            on="click"
            open={openPopup}
            onOpen={() => setOpenPopup(true)}
            onClose={() => setOpenPopup(false)}
            content={
              <ActivityPopupDetails
                organization={organization}
                project={project}
                group={group}
                content={content}
                userType={userType}
                sender={sender}
                sentCount={sentCount}
                successCount={successCount}
                failedCount={failedCount}
              />
            }
            position="left center"
          />
        </div>
      </div>
      <Divider />
    </>
  );
};

ActivityItem.defaultProps = {
  viewAll: false,
};

ActivityItem.propTypes = {
  data: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    organization: PropTypes.objectOf(Object),
    project: PropTypes.objectOf(Object),
    user_type: PropTypes.string.isRequired,
    sent_count: PropTypes.number.isRequired,
    failed_count: PropTypes.number,
    success_count: PropTypes.number,
    group: PropTypes.objectOf(Object),
    sender: PropTypes.objectOf(Object),
    created_at: PropTypes.string.isRequired,
    organization_id: PropTypes.number,
    content: PropTypes.string.isRequired,
  }).isRequired,
  viewAll: PropTypes.bool,
};

export default ActivityItem;
