import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Form,
  Segment,
  Message,
} from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import PageLoader from 'components/common/PageLoader';
import './index.scss';
import TypesCategory from 'components/Organizations/SingleOrganization/Settings/TypeCategory';
import PhoneInput from 'components/common/PhoneInput';
import { isOrganizationAdmin } from 'helpers/checkRoles';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';

const SingleOrganization = ({
  noToolbar,
  formData,
  getOrganizationLoading,
  getOrganizationError,
  updateOrganizationLoading,
  updateOrganizationError,
  handleSubmit,
  handleChange,
  categoryError,
  typeError,
  setTypeCategories,
}) => {
  const { currentOrgId, orgUsers } = getUserRoles();

  const isCurrentOrgParent = isParentOrganization();
  const disabledForm = !isOrganizationAdmin({
    orgUsers,
    currentOrgId,
  });

  const getOrganizationErrorMessage = getOrganizationError && (
    <div className="text-center">
      <Segment vertical>
        <Message size="huge" compact warning attached="bottom">
          <Icon name="warning" />
          {getOrganizationError}
        </Message>
      </Segment>
    </div>
  );

  const { pathname = '' } = useLocation();

  const isSettings = /settings/g.test(pathname);

  const isAdminSettings = pathname === '/settings';

  return (
    <div className="org-setting scroll-wrapper">
      {noToolbar && !isAdminSettings && (
        <Toolbar
          header={{
            title: 'Organization Profiles',
            navigation: true,
          }}
        />
      )}
      {!noToolbar && !isCurrentOrgParent && (
        <Toolbar
          header={{
            title: `Organizations: ${
              !getOrganizationLoading && !getOrganizationError
                ? formData.name
                : ''
            }`,
          }}
        />
      )}
      {getOrganizationLoading ? (
        <PageLoader loaderSize="small" />
      ) : null}
      {getOrganizationErrorMessage}

      {getOrganizationLoading || getOrganizationError ? null : (
        <>
          <h3>Organization Details</h3>
          <div>
            <Form
              onSubmit={handleSubmit}
              loading={updateOrganizationLoading}
            >
              <Form.Field>
                <Form.Input
                  label="Name"
                  name="name"
                  placeholder="Name"
                  defaultValue={formData.name}
                  onChange={handleChange}
                  disabled={disabledForm}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  defaultValue={formData.email}
                  onChange={handleChange}
                  disabled={disabledForm}
                />
              </Form.Field>
              <PhoneInput
                name="phone"
                value={formData.phone_number}
                organization
                onChange={handleChange}
                disabled={disabledForm}
              />
              <Form.Field>
                <Form.Input
                  label="Address"
                  name="address"
                  type="text"
                  placeholder="Address"
                  defaultValue={formData.address || ''}
                  onChange={handleChange}
                  disabled={disabledForm}
                />
              </Form.Field>

              {!isSettings && (
                <TypesCategory
                  handleChange={handleChange}
                  categoryError={categoryError}
                  typeError={typeError}
                  form={formData}
                  required={false}
                  setTypeCategories={setTypeCategories}
                  disabled={disabledForm}
                />
              )}

              {updateOrganizationError && (
                <Message negative>
                  <span className="error-message">
                    {updateOrganizationError}
                  </span>
                </Message>
              )}
              <Button.Group floated="right">
                <Button
                  icon
                  labelPosition="right"
                  color="blue"
                  name="submit-btn"
                  disabled={disabledForm}
                >
                  Save
                  <Icon name="check" />
                </Button>
              </Button.Group>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

SingleOrganization.defaultProps = {
  formData: {},
  getOrganizationLoading: false,
  getOrganizationError: '',
  updateOrganizationLoading: false,
  updateOrganizationError: '',
  noToolbar: false,
};

SingleOrganization.propTypes = {
  formData: PropTypes.instanceOf(Object),
  getOrganizationLoading: PropTypes.bool,
  getOrganizationError: PropTypes.string,
  updateOrganizationLoading: PropTypes.bool,
  updateOrganizationError: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  noToolbar: PropTypes.func,
  categoryError: PropTypes.string.isRequired,
  typeError: PropTypes.string.isRequired,
  setTypeCategories: PropTypes.func.isRequired,
};
export default SingleOrganization;
