import {
  GET_SINGLE_ADVERTISEMENT_ERROR,
  GET_SINGLE_ADVERTISEMENT_START,
  GET_SINGLE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/getSingleAdvertisement';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_SINGLE_ADVERTISEMENT_START:
      return {
        ...state,
        singleAdvertisement: {
          ...state?.singleAdvertisement,
          loading: true,
          error: null,
          data: null,
        },
      };
    case GET_SINGLE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        singleAdvertisement: {
          ...state?.singleAdvertisement,
          loading: false,
          error: payload,
        },
      };
    case GET_SINGLE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        singleAdvertisement: {
          ...state?.singleAdvertisement,
          loading: false,
          data: payload?.data,
        },
      };
    default:
      return null;
  }
};
