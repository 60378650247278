import {
  ADD_USER_LOADING,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  CLEAR_ADD_USER,
} from 'constants/action-types/user/addUser';
import apiAction from 'helpers/apiAction';

export const createAdminUser = (user, organizationId) => dispatch => {
  const url = organizationId
    ? `/organizations/${organizationId}/users`
    : `/users`;

  const data = {};
  Object.keys(user).filter(key => {
    if (user[key] !== null) {
      data[key] = user[key];
    }
    return null;
  });

  return dispatch(
    apiAction({
      url,
      method: 'POST',
      data,
      API_REQUEST_START: ADD_USER_LOADING,
      API_REQUEST_SUCCESS: ADD_USER_SUCCESS,
      API_REQUEST_FAILURE: ADD_USER_FAILED,
      onEnd: () => false,
    }),
  );
};
export const clearUserAdded = () => dispatch => {
  dispatch({
    type: CLEAR_ADD_USER,
  });
};
