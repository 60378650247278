import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import Proptype from 'prop-types';
import Message from 'components/common/Message';
import { toDropDownOptions } from 'utils/dropdownOptions';
import getMicroloansSettingData from 'redux/actions/settings/getMicroloansSettingData';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import labelFormatter from 'helpers/labelFormatter';

const EligibilityCheck = ({ type }) => {
  const [form, setForm] = useState({});
  const [openPinModal, setOpenPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');
  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettingsData } }) =>
      microloanSettingsData,
  );
  const { loading: updateLoading, error: updateError } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  const handleChange = (e, { name, value }) => {
    if (name === 'national_id') {
      return setForm({ ...form, [name]: value === 'yes' });
    }
    if (name === 'independent.minimum_members') {
      return setForm({
        ...form,
        independent: {
          minimum_members: value,
        },
      });
    }
    if (name === 'graduated.minimum_members') {
      return setForm({
        ...form,
        graduated: {
          minimum_members: value,
        },
      });
    }
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    getMicroloansSettingData({
      section: 'Eligibility',
      type,
    })(dispatch);
  }, []);

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: userPin,
      section: 'Eligibility',
      type,
      data: {
        ...data,
        ...form,
      },
    })(dispatch, () => setOpenPinModal(false));
  };

  return (
    <>
      <ConfirmPinModal
        content={`Are you sure you want to update the settings for ${labelFormatter(
          type,
        )}`}
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        userPin={userPin}
        setUserPin={setUserPin}
        error={{ message: updateError }}
        loading={updateLoading}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
      />
      <Form
        onSubmit={() => setOpenPinModal(!openPinModal)}
        loading={loading}
      >
        {!loading && error && (
          <Message message={error?.message} color="red" />
        )}
        {data && (
          <>
            <Form.Group widths="equal">
              <Form.Dropdown
                fluid
                selection
                label="Is National ID required?"
                placeholder="-- Select --"
                name="national_id"
                options={toDropDownOptions(['yes', 'no'])}
                onChange={handleChange}
                defaultValue={data.national_id ? 'yes' : 'no'}
              />

              <Form.Field
                control={Input}
                type="number"
                label={`Minimum age${
                  type === 'group_micro_loan'
                    ? ' for group members'
                    : ''
                }`}
                placeholder="19"
                onChange={handleChange}
                name="age"
                defaultValue={data.age}
              />
            </Form.Group>
            <Form.Group widths="equal">
              {type === 'individual_micro_loan' ? (
                <Form.Field
                  control={Input}
                  type="number"
                  label="The period a user should be active (Months)"
                  placeholder="3"
                  onChange={handleChange}
                  name="active_period"
                  defaultValue={data.active_period}
                />
              ) : (
                <Form.Field
                  control={Input}
                  type="number"
                  label="The period the group should be active (Months)"
                  placeholder="3"
                  onChange={handleChange}
                  name="active_period"
                  defaultValue={data.active_period}
                />
              )}

              {type === 'individual_micro_loan' ? (
                <Form.Field
                  control={Input}
                  type="number"
                  label="Loans the user should have cleared"
                  placeholder="3"
                  onChange={handleChange}
                  name="loans_cleared"
                  defaultValue={data.loans_cleared}
                />
              ) : (
                <Form.Field
                  control={Input}
                  type="number"
                  label="Minimum internal loans disbursed"
                  placeholder="3"
                  onChange={handleChange}
                  name="loans_disbursed"
                  defaultValue={data.loans_disbursed}
                />
              )}
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="Minimum Members (Independent groups)"
                placeholder="3"
                onChange={handleChange}
                name="independent.minimum_members"
                defaultValue={data?.independent?.minimum_members}
              />

              <Form.Field
                control={Input}
                type="number"
                label="Minimum Members (Graduated groups)"
                placeholder="7"
                onChange={handleChange}
                name="graduated.minimum_members"
                defaultValue={data?.graduated?.minimum_members}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="Group default rate (Minimum%)"
                placeholder="3"
                onChange={handleChange}
                name="group_default_rate"
                defaultValue={data.group_default_rate}
              />

              {type === 'individual_micro_loan' && (
                <Form.Field
                  control={Input}
                  type="number"
                  label="Individual default rate (Minimum%)"
                  placeholder="3"
                  onChange={handleChange}
                  name="member_default_rate"
                  defaultValue={data.member_default_rate}
                />
              )}
            </Form.Group>
          </>
        )}
        <div className="action">
          <Button
            type="submit"
            primary
            loading={updateLoading}
            disabled={updateLoading || Object.keys(form).length === 0}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
EligibilityCheck.propTypes = {
  type: Proptype.string.isRequired,
};
export default EligibilityCheck;
