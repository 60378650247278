/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Message } from 'semantic-ui-react';
import './index.scss';

const PINInput = ({ onInputChange, name, error, ...props }) => {
  const [showPIN, setShowPIN] = useState();

  const togglePIN = () => {
    setShowPIN(!showPIN);
  };

  return (
    <>
      <Input
        iconPosition="left"
        type={showPIN ? 'text' : 'password'}
        maxLength="5"
        name={name}
        placeholder="PIN"
        onChange={(e, data) => onInputChange(e, data)}
        {...props}
      >
        <Icon name="lock" />

        <input />

        <div
          className="eye-icon"
          role="button"
          tabIndex="-1"
          onClick={togglePIN}
          onKeyDown={() => null}
        >
          {showPIN ? <Icon name="eye" /> : <Icon name="eye slash" />}
        </div>
      </Input>
      {error?.message === 'Wrong PIN.' ||
        (error?.message === 'PIN Number is required' && (
          <p>
            <Message negative>{error.message}</Message>
          </p>
        ))}
    </>
  );
};

PINInput.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
};

PINInput.defaultProps = {
  error: {},
};

export default PINInput;
