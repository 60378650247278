/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from 'semantic-ui-react';

import './LineChart.scss';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import todaysDate from 'utils/todaysDate';

const LineChartWrapper = ({
  width,
  height,
  title,
  className,
  date,
  dataKey1,
  dataKey2,
  data,
  style,
}) => {
  const lineChartContainerRef = useRef();
  const [lineChartWidth, setLineChartWidth] = useState(0);
  const totalSizePaddings = 40;

  useEffect(() => {
    if (lineChartContainerRef.current)
      setLineChartWidth(
        lineChartContainerRef.current.clientWidth - totalSizePaddings,
      );

    window.addEventListener('resize', () => {
      if (lineChartContainerRef.current) {
        setLineChartWidth(
          lineChartContainerRef.current.clientWidth -
            totalSizePaddings,
        );
      }
    });
  }, [lineChartContainerRef]);

  return (
    <Card className={`line-wrapper ${className || ''}`} style={style}>
      <div className="title">{title}</div>
      <div className="date">{date}</div>
      <Divider />
      <div
        style={{
          backgroundColor: 'rgba(72, 78, 115, 0.05)',
          marginTop: -15,
          paddingTop: 30,
        }}
        ref={lineChartContainerRef}
      >
        <LineChart
          width={lineChartWidth || 0}
          height={height}
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={dataKey1} stroke="#8884d8" />
          <Line type="monotone" dataKey={dataKey2} stroke="#82ca9d" />
        </LineChart>
      </div>
    </Card>
  );
};

LineChartWrapper.defaultProps = {
  width: 550,
  height: 300,
  title: '',
  className: '',
  date: todaysDate(),
  dataKey1: 'uv',
  dataKey2: 'pv',
  style: {},
  data: [],
};

LineChartWrapper.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  dataKey1: PropTypes.string,
  dataKey2: PropTypes.string,
  data: PropTypes.array,
  style: PropTypes.instanceOf(Object),
};

export default LineChartWrapper;
