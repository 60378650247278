import React from 'react';
import {
  Icon,
  Loader,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Empty from 'components/common/Empty';
import './index.scss';

const OverviewCardModal = ({
  popup,
  setPopup,
  content,
  items,
  loading,
  error,
}) => {
  return (
    <Modal size="tiny" open={popup} onClose={() => setPopup(false)}>
      <Modal.Header>
        {content.header}
        <Icon
          name="close"
          className="pointer"
          onClick={() => setPopup(false)}
        />
      </Modal.Header>
      <Modal.Content>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error?.message}</Message>
        ) : items && items?.length === 0 ? (
          <Empty message="No content found" />
        ) : (
          <Table basic="very" className={content.action}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>SG Names</Table.HeaderCell>
                <Table.HeaderCell>
                  {content.title} (RWF)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
    </Modal>
  );
};

OverviewCardModal.propTypes = {
  popup: PropTypes.bool.isRequired,
  setPopup: PropTypes.func,
  content: PropTypes.objectOf(Object).isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

OverviewCardModal.defaultProps = {
  popup: false,
  content: {},
};

export default OverviewCardModal;
