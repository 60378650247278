/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Divider } from 'semantic-ui-react';

import './LineChartWrapper.scss';

import LineChart from 'components/common/charts/LineChart';
import todaysDate from 'utils/todaysDate';
import formatNumber from 'helpers/formatNumber';
import chevronArrowRight from 'assets/images/chevron-right.svg';

const LineChartWrapper = ({
  dataKey,
  title,
  count,
  currency,
  className,
  date,
  style,
  view,
  viewKey,
  viewMoreRedirect,
  ...restProps
}) => {
  const { push } = useHistory();
  const { organizationId, tabOption, projectId } = useParams();

  const lineChartContainerRef = useRef(null);
  const [lineChartWidth, setLineChartWidth] = useState(0);
  const totalSizePaddings = 40;

  useEffect(() => {
    if (lineChartContainerRef.current)
      setLineChartWidth(
        lineChartContainerRef.current.clientWidth - totalSizePaddings,
      );

    window.addEventListener('resize', () => {
      if (lineChartContainerRef.current) {
        setLineChartWidth(
          lineChartContainerRef.current.clientWidth -
            totalSizePaddings,
        );
      }
    });
  }, [lineChartContainerRef]);

  const viewMore = (url, props) => (
    <span
      className="line-chart-wrapper__icon"
      role="button"
      tabIndex="-1"
      onClick={() =>
        url && props ? push(url, props) : url ? push(url) : null
      }
      onKeyDown={() => null}
    >
      <span className="line-chart-wrapper__icon--view">
        View More
      </span>
      <img
        src={chevronArrowRight}
        alt=""
        className="line-chart-wrapper__icon--arrow"
      />
    </span>
  );

  return (
    <Card
      className={`line-chart-wrapper ${className || ''}`}
      style={style}
    >
      <div className="line-chart-wrapper__heading">
        <div className="title">{title}</div>

        {viewMoreRedirect &&
        ['project', 'agents'].includes(view || viewKey)
          ? viewMore(`/organizations/${organizationId}/${viewKey}`, {
              projectId: tabOption,
            })
          : view === 'ngo'
          ? viewMore(
              `/projects/${projectId}/organizations/${organizationId}/${viewKey}`,
            )
          : viewMore(`/organizations/${organizationId}/groups`)}
      </div>
      <div className="date">{date}</div>
      <Divider />
      <div className="count">
        {formatNumber(count)}
        {currency && <span className="currency">{currency}</span>}
      </div>
      <div
        className="line-chart-container"
        ref={lineChartContainerRef}
      >
        <LineChart
          width={lineChartWidth}
          dataKey={dataKey}
          {...restProps}
        />
      </div>
    </Card>
  );
};

LineChartWrapper.defaultProps = {
  dataKey: 'uv',
  title: '',
  count: 0,
  currency: '',
  className: '',
  date: todaysDate(),
  style: {},
  view: '',
  viewKey: '',
  viewMoreRedirect: '',
};

LineChartWrapper.propTypes = {
  dataKey: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  view: PropTypes.string,
  viewKey: PropTypes.string,
  viewMoreRedirect: PropTypes.string,
};

export default LineChartWrapper;
