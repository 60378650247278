import moment from 'moment';
import currencyFormat from 'utils/currencyFormat';
import capitalize from 'utils/capitalize';
import ellipses from 'helpers/ellipses';

const shapeFormatter = group => {
  const groupData = [
    {
      icon: 'money bill alternate',
      label: 'Current Savings:',
      content: `${currencyFormat(group.savings_balance)}`,
    },
    {
      icon: 'money bill alternate outline',
      label: 'Outstanding Loans:',
      content: `${currencyFormat(group.loans_balance)}`,
    },
    {
      icon: 'money bill alternate',
      label: 'Social Funds Balance:',
      content: `${currencyFormat(group.social_funds_balance)}`,
    },
    {
      icon: 'calendar alternate outline',
      label: 'Saving Frequency:',
      content: capitalize(group.saving_frequency),
    },
    {
      icon: 'cube',
      label: 'Minimum Share Value:',
      content: `${currencyFormat(group.share_value_min)}`,
    },
    {
      icon: 'cubes',
      label: 'Maximum Share Value:',
      content: `${currencyFormat(group.share_value_max)}`,
    },

    {
      icon: 'clock outline',
      label: 'Cycle End Date:',
      content: moment(group.cycle_end_date).format('LL'),
    },
    {
      icon: 'user',
      label: 'Created By:',
      content: group.created_by
        ? ellipses(
            `${group.created_by.first_name} ${group.created_by.last_name}`,
            20,
          )
        : '',
    },
    {
      icon: 'sitemap',
      label: 'Group Type:',
      content: capitalize(group.type),
    },
  ];

  if (group.access_bank)
    groupData.push(
      {
        icon: 'building',
        label: 'Bank:',
        content: 'Access bank (Rwanda) PLC',
      },
      {
        icon: 'hashtag',
        label: 'Account No:',
        content: `${group.access_bank_wallet_number || '-'}`,
      },
    );

  return groupData;
};

export default shapeFormatter;
