export default {
  createProfile: {
    loading: false,
    error: null,
  },
  countProfileGroups: {
    data: null,
    loading: false,
    error: null,
  },
  editProfile: {
    loading: false,
    error: null,
  },
  profileList: {
    items: [],
    loading: false,
    error: null,
  },
  profileGroups: {
    items: [],
    loading: false,
    error: null,
    meta: null,
  },
  profileOverview: {
    data: {},
    loading: false,
    error: null,
  },
  singleProfile: {
    data: null,
    loading: false,
    error: null,
  },
};
