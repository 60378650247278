/* eslint-disable no-restricted-globals */
/**
 * @param {number} value
 * @param {amount} number the minimum number the value shouldn't go below
 * @param {bool} extraConditions extra condition to apply
 * @returns {boolean} True when the value is less than the amount and false otherwise
 */
export default (value, amount, extraConditions) => {
  return (
    !isNaN(value) &&
    Number(value) < Number(amount) &&
    (extraConditions ?? true)
  );
};
