import {
  ADD_TO_COMMITTEE_ERROR,
  ADD_TO_COMMITTEE_START,
  ADD_TO_COMMITTEE_SUCCESS,
} from 'constants/action-types/organizations/addToCommittee';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (groupId, memberIds, userPin) => (
  dispatch,
  onSuccess,
) =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}/members/committee`,
      method: 'PUT',
      data: {
        action: 'add_to_committee',
        password: userPin,
        members: memberIds,
      },
      API_REQUEST_START: ADD_TO_COMMITTEE_START,
      API_REQUEST_SUCCESS: ADD_TO_COMMITTEE_SUCCESS,
      API_REQUEST_FAILURE: ADD_TO_COMMITTEE_ERROR,
      onSuccess: payload => {
        onSuccess();
        dispatch({
          type: ADD_TO_COMMITTEE_SUCCESS,
          payload,
        });
        notification({
          message: 'Member(s) successfully added to committee',
        });
      },
      onFailure: ({ message }) => {
        notification({ message, status: 'error' });
        dispatch({
          type: ADD_TO_COMMITTEE_ERROR,
          payload: message,
        });
      },
      onEnd: () => false,
    }),
  );
