import {
  GET_ORGANIZATIONS_OVERVIEW_ERROR,
  GET_ORGANIZATIONS_OVERVIEW_START,
  GET_ORGANIZATIONS_OVERVIEW_SUCCESS,
} from 'constants/action-types/organizations/organizationsOverview';

import apiAction from 'helpers/apiAction';

export default queries => dispatch => {
  dispatch(
    apiAction({
      url: '/organizations/overview',
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATIONS_OVERVIEW_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATIONS_OVERVIEW_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATIONS_OVERVIEW_ERROR,
      onEnd: () => false,
    }),
  );
};
