import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from 'components/common/Thumbnail';
import './index.scss';

const GroupDetails = ({
  data: {
    projects_count: projectsCount,
    partners_count: partnersCount,
    groups_members_count: groupMembersCount,
  },
  address,
  name,
  type,
  email,
  phoneNumber,
}) => {
  return (
    <div className="group-info">
      <div className="info-wrapper">
        <div className="header">
          <Thumbnail
            name={name}
            style={{ width: '50px', height: '50px' }}
          />
          <div className="name">{name}</div>
        </div>

        <div className="group-details">
          <div className="details">
            <div className="detail">
              <div>Total projects</div> <div>{projectsCount}</div>
            </div>

            <div className="detail">
              <span>Telephone</span> <span>{phoneNumber}</span>
            </div>

            <div className="detail">
              <span>Partners</span> <span>{partnersCount}</span>
            </div>

            <div className="detail">
              <div>Address</div> <div>{address}</div>
            </div>
            <br />
          </div>
        </div>
        <div className="bottom group-details">
          <div>{type && <span className="label">{type}</span>}</div>
          <div>
            <div>{email}</div>
            <div>{phoneNumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
GroupDetails.defaultProps = {
  type: '',
  name: '',
  type: '',
  email: '',
  phoneNumber: null,
  address: null,
};

GroupDetails.defaultProps = {
  data: {},
  name: '',
  type: '',
  email: '',
  phoneNumber: '',
  address: '',
};

GroupDetails.propTypes = {
  data: PropTypes.objectOf(Object),
  name: PropTypes.string,
  type: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.string,
};

export default GroupDetails;
