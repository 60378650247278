/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import todaysDate from 'utils/todaysDate';
import PieChart from 'components/common/charts/PieChart/PieChartWrapper';

const StatCharts = ({
  data: {
    cumulative_savings_vs_cumulative_loans = [],
    current_savings_vs_current_loans = [],
    members_age_range,
    cashed_out_vs_balance_vas_commission = [],
  },
  year,
}) => {
  const cleanSavingsVsLoans =
    cumulative_savings_vs_cumulative_loans &&
    cumulative_savings_vs_cumulative_loans.map(item => {
      return {
        ...item,
        name: item.month.substring(0, 3),
        cumulative: Number(item.savings),
        current: Number(item.loans),
      };
    });
  const new_current_savings_vs_current_loans =
    current_savings_vs_current_loans &&
    current_savings_vs_current_loans.map(item => {
      return {
        ...item,
        name: item.month.substring(0, 3),
        outstanding: item.loans,
      };
    });
  const shortMonthRevenueVsCommision = cashed_out_vs_balance_vas_commission.map(
    item => ({
      ...item,
      name: item.month.substring(0, 3),
      outstanding: item.balance,
      ['cashed-out']: item.cashed_out,
    }),
  );

  const colors = ['#8884d8', '#83a6ed', '#D0ED57'];
  const member_ages =
    members_age_range &&
    members_age_range.map((item, i) => {
      item.fill = colors[i];
      item.name = item.age;
      return item;
    });
  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Cumulative Savings Vs Cumulative Loans',
              date: todaysDate(year),
              data: cleanSavingsVsLoans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Monthly Savings Vs Monthly Loans',
              date: todaysDate(year),
              data: new_current_savings_vs_current_loans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <PieChart
            filled
            title="Members Age Range"
            width={400}
            height={320}
            innerRadius={20}
            outerRadius={140}
            barSize={10}
            dataKey="total"
            nameKey="age"
            data={member_ages}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Cashed-Out Vs Outstanding Commission',
              date: todaysDate(year),
              data: shortMonthRevenueVsCommision,
              dataKey1: 'cashed-out',
              dataKey2: 'outstanding',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
StatCharts.defaultProps = {
  cumulative_savings_vs_cumulative_loans: [],
  current_savings_vs_current_loans: [],
  members_age_range: [],
  revenue_vs_commission: [],
};

StatCharts.propTypes = {
  data: PropTypes.shape({
    cumulative_savings_vs_cumulative_loans: PropTypes.array,
    current_savings_vs_current_loans: PropTypes.array,
    members_age_range: PropTypes.array,
    revenue_vs_commission: PropTypes.array,
  }).isRequired,
};

export default StatCharts;
