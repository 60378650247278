import {
  REMOVE_FROM_COMMITTEE_ERROR,
  REMOVE_FROM_COMMITTEE_START,
  REMOVE_FROM_COMMITTEE_SUCCESS,
} from 'constants/action-types/organizations/removeFromCommittee';

export default (state, { type, payload }) => {
  switch (type) {
    case REMOVE_FROM_COMMITTEE_START:
      return {
        ...state,
        removeFromCommittee: {
          ...state.removeFromCommittee,
          loading: true,
          error: null,
          data: [],
        },
      };
    case REMOVE_FROM_COMMITTEE_SUCCESS:
      return {
        ...state,
        removeFromCommittee: {
          ...state.removeFromCommittee,
          loading: false,
          error: null,
          data: payload,
        },
      };
    case REMOVE_FROM_COMMITTEE_ERROR:
      return {
        ...state,
        removeFromCommittee: {
          ...state.removeFromCommittee,
          loading: false,
          error: payload,
        },
      };
    default:
      return null;
  }
};
