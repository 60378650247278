import React from 'react';
import FeatureSettings from '.';
import AddFeature from './AddFeature';
import EditFeature from './EditFeature';

const FeatureWrapper = props => {
  const {
    match: {
      params: { option1, option2 },
    },
  } = props;
  return (
    <>
      {option1 === 'add' && <AddFeature />}
      {option1 && option2 === 'edit' && <EditFeature {...props} />}
      {!option1 && <FeatureSettings />}
    </>
  );
};

export default FeatureWrapper;
