/**
 * @param {int|string} num
 * @returns {string} the ordinal number
 */
export default num => {
  const numString = num.toString();
  const lastDigit = numString[numString.length - 1];
  const lastTwoDigits = numString.slice(numString.length - 2);
  let ordinal;

  if (
    lastTwoDigits === '11' ||
    lastTwoDigits === '12' ||
    lastTwoDigits === '13'
  ) {
    ordinal = `${num}th`;
  } else if (lastDigit === '1') {
    ordinal = `${num}st`;
  } else if (lastDigit === '2') {
    ordinal = `${num}nd`;
  } else if (lastDigit === '3') {
    ordinal = `${num}rd`;
  } else {
    ordinal = `${num}th`;
  }

  return ordinal;
};
