/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './index.scss';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import abName from 'utils/abName';
import randomColor from 'utils/randomColor';
import { ACTIVITY_PROJECT_LIMIT } from 'constants/pagination';
import Loader from 'components/common/PageLoader';
import getProjects from 'redux/actions/projects/listProjects';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import formatNumber from 'helpers/formatNumber';

const ListProjects = ({
  history: { push },
  items,
  meta,
  loading,
  all,
}) => {
  const { t } = useTranslation();

  const projectItems = !all
    ? [...items].splice(0, ACTIVITY_PROJECT_LIMIT)
    : items;

  const creationDate = date =>
    `${t('Created on')} ${new Date(date).toUTCString()}`;

  const { organizations = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const dispatch = useDispatch();

  const onQuery = queries =>
    organizations[0] &&
    getProjects(organizations[0].id, queries)(dispatch);

  const { pathname = '' } = useLocation();
  const projectsCount = /\/projects/g.test(pathname)
    ? `(${meta ? formatNumber(meta.total || 0) : ''})`
    : '';

  return (
    <>
      <section className={`list-projects ${!all ? 'not-all' : ''}`}>
        <Toolbar
          header={{
            title: `Projects ${projectsCount}`,
            rightComponent: all ? (
              <ToolbarFooter
                onSearch={onQuery}
                onPaginate={onQuery}
                paginationMeta={meta}
                exportCSVData={items}
                exportCSVFileName="Projects"
              />
            ) : (
              <Button onClick={() => push('/projects')}>
                {t('View all')}
              </Button>
            ),
          }}
          ignoreHelmet={!all}
        />
        {loading ? (
          <Loader />
        ) : projectItems.length === 0 ? (
          <Empty message="No projects" />
        ) : (
          <div className="list-projects-items scroll-wrapper">
            {projectItems.map(
              ({
                id,
                name,
                created_at: createdAt,
                organization_id: organizationId,
              }) => (
                <div
                  className="list-projects-item"
                  key={id}
                  tabIndex="-1"
                  onKeyDown={null}
                  role="button"
                  onClick={() =>
                    push(
                      `/projects/${id}/organizations/${organizationId}/overview`,
                    )
                  }
                >
                  <div
                    className="thumbnail"
                    style={{ backgroundColor: randomColor() }}
                  >
                    <span>{abName(name)}</span>
                  </div>

                  <div className="details">
                    <div className="info">
                      <div className="name">{name}</div>
                      <div className="contact">
                        <span>{creationDate(createdAt)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        )}
      </section>
    </>
  );
};

ListProjects.defaultProps = {
  all: false,
  meta: {},
};

ListProjects.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  all: PropTypes.bool,
  meta: PropTypes.objectOf(Object),
};

export default ListProjects;
