import login from './login';
import SelectOrganization from './selectOrganization';
import addUser from './addUser';
import activity from './activity';
import users from './users';
import singleUser from './singleUser';
import createOrganization from './createOrganization';
import singleOrganization from './singleOrganization';
import addOrganizationUser from './addOrganizationUser';
import createProject from './createProject';
import resetPassword from './resetPassword';
import projectDashboard from './projectDashboard';
import accountSettings from './accountSettings';
import allOrganizations from './allOrganizations';
import allNGOs from './allNGOs';
import allProjects from './allProjects';
import organizationsOverview from './organizationsOverview';
import changePin from './changePin';
import organizationSettings from './organizationSettings';
import SetPin from './setPin';
import wallet from './wallet';
import allUsers from './allUsers';
import user from './user';
import allTransactions from './allTransactions';
import support from './support';
import allGroups from './allGroups';
import featureSettings from './featureSettings';
import communications from './communications';
import allProfiles from './allProfiles';
import createProfile from './createProfile';
import profileSavingGroups from './profileSavingGroups';
import microLoanRequests from './microLoanRequests';

export default [
  login,
  allProfiles,
  SelectOrganization,
  users,
  activity,
  createOrganization,
  addUser,
  singleUser,
  createProfile,
  profileSavingGroups,
  microLoanRequests,
  singleOrganization,
  addOrganizationUser,
  createProject,
  resetPassword,
  projectDashboard,
  accountSettings,
  allOrganizations,
  allNGOs,
  allProjects,
  organizationsOverview,
  changePin,
  organizationSettings,
  SetPin,
  communications,
  wallet,
  allUsers,
  user,
  allTransactions,
  support,
  allGroups,
  featureSettings,
];
