import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Divider, Image, Popup } from 'semantic-ui-react';
import currencyFormat from 'utils/currencyFormat';
import capitalize from 'utils/capitalize';
import Info from 'assets/images/info-circle.svg';

const LoanInfoPopup = ({ description }) => {
  return (
    <div className="activity__info-card">
      <Popup
        trigger={
          <div className="activity__info-card__label">
            <Image src={Info} />
            <span>info</span>
          </div>
        }
        position="left center"
        content={
          <div className="activity__info-card__description">
            <div className="activity__info-card__description__header">
              <div className="activity__info-card__description__header__title">
                Loan Info
              </div>
              <div
                className={`activity__info-card__description__header__status--${description?.status}`}
              >
                {capitalize(description?.request?.status)}
              </div>
            </div>
            <Divider />
            <div className="activity__info-card__description__content">
              <div className="activity__info-card__description__content--left">
                <span>Loan Requested:</span>
                <span>Disbursed Date:</span>
                <span>Repayment Days:</span>
                <span>Interest:</span>
                <span>Due Date:</span>
                <span>Payed Amount:</span>
                <br />
                <span>Interest on Overdue Loan:</span>
                <br />
                <span>Outstanding Balance:</span>
              </div>
              <div className="activity__info-card__description__content--right">
                <span>
                  {currencyFormat(description?.request?.amount)}
                </span>
                <span>
                  {moment(description?.created_at).format(
                    'DD MMM YYYY',
                  )}
                </span>
                <span>
                  {description?.request?.repayment_days || '-'}
                </span>
                <span>
                  {currencyFormat(
                    description?.request?.loans_interest,
                  )}
                </span>
                <span>
                  {moment(
                    description?.request?.loans_due_date,
                  ).format('DD MMM YYYY')}
                </span>
                <span>
                  {currencyFormat(description?.request?.loans_payed)}
                </span>
                <br />
                <span>
                  {currencyFormat(
                    description?.request?.overdue_loans_interests,
                  )}
                </span>
                <br />
                <span>
                  {currencyFormat(
                    description?.request?.loans_balance,
                  )}
                </span>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

LoanInfoPopup.propTypes = {
  description: PropTypes.instanceOf(Object).isRequired,
};

export default LoanInfoPopup;
