import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import {
  GET_ALL_EMAILS_ERROR,
  GET_ALL_EMAILS_START,
  GET_ALL_EMAILS_SUCCESS,
} from 'constants/action-types/communication/getAllEmails';

export default () => dispatch =>
  dispatch(
    apiAction({
      url: '/users/notifications/emails',
      method: 'GET',
      API_REQUEST_START: GET_ALL_EMAILS_START,
      API_REQUEST_SUCCESS: GET_ALL_EMAILS_SUCCESS,
      API_REQUEST_FAILURE: GET_ALL_EMAILS_ERROR,
      onSuccess: payload =>
        dispatch({
          type: GET_ALL_EMAILS_SUCCESS,
          payload,
        }),
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
