import AllProfiles from 'containers/Organizations/SingleOrganization/Profiles/AllProfiles';

export default {
  exact: true,
  name: 'Profiles',
  protected: true,
  path: '/profiles',
  component: AllProfiles,
  permissions: ['all'],
};
