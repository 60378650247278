import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Provinces, District, Sector, Cell, Village } from 'rwanda';
import { Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import capitalize from 'utils/capitalize';
import { toDropDownOptions } from 'utils/dropdownOptions';
import translateProvince from 'helpers/translateProvince';

const Location = ({ onChange, defaultValue: defaultLocation }) => {
  const [location, setLocation] = useState({
    provinces: Provinces(),
    districts: District(),
    sectors: Sector(),
    cells: Cell(),
    villages: Village(),
  });

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (defaultLocation) {
      setLocation({
        ...location,
        districts: District(
          translateProvince(defaultLocation?.province),
        ),
        sectors: Sector(
          capitalize(translateProvince(defaultLocation?.province)),
          defaultLocation.district,
        ),
        cells: Cell(
          capitalize(translateProvince(defaultLocation?.province)),
          defaultLocation.district,
          defaultLocation.sector,
        ),
        villages: Village(
          capitalize(translateProvince(defaultLocation?.province)),
          defaultLocation.district,
          defaultLocation.sector,
          defaultLocation.cell,
        ),
      });
      setSelected(defaultLocation);
    }
  }, [defaultLocation]);

  const handleSelection = (_, { name, value }) => {
    switch (name) {
      case 'province':
        setLocation({
          ...location,
          districts: District(value),
        });
        break;
      case 'district':
        setLocation({
          ...location,
          sectors: Sector(capitalize(selected.province), value),
        });
        break;
      case 'sector':
        setLocation({
          ...location,
          cells: Cell(
            capitalize(selected.province),
            selected.district,
            value,
          ),
        });
        break;
      case 'cell':
        setLocation({
          ...location,
          villages: Village(
            capitalize(selected.province),
            selected.district,
            selected.sector,
            value,
          ),
        });
        break;
      default:
        break;
    }
    setSelected({ ...selected, [name]: value });
    onChange(_, { name, value });
  };

  return (
    !isEmpty(defaultLocation) && (
      <div className="location">
        <Form.Select
          fluid
          label="Province"
          options={toDropDownOptions(location.provinces)}
          placeholder="Province"
          onChange={handleSelection}
          name="province"
          defaultValue={translateProvince(defaultLocation?.province)}
        />
        <Form.Select
          fluid
          label="District"
          options={toDropDownOptions(location.districts)}
          placeholder="District"
          onChange={handleSelection}
          name="district"
          defaultValue={defaultLocation?.district?.toLowerCase()}
        />
        <Form.Select
          fluid
          label="Sector"
          options={toDropDownOptions(location.sectors)}
          placeholder="Sector"
          onChange={handleSelection}
          name="sector"
          defaultValue={defaultLocation?.sector?.toLowerCase()}
        />
        <Form.Select
          fluid
          label="Cell"
          options={toDropDownOptions(location.cells)}
          placeholder="Cell"
          onChange={handleSelection}
          name="cell"
          defaultValue={defaultLocation?.cell?.toLowerCase()}
        />
        <Form.Select
          fluid
          label="Village"
          options={toDropDownOptions(location.villages)}
          placeholder="Village"
          onChange={handleSelection}
          name="village"
          defaultValue={defaultLocation?.village?.toLowerCase()}
        />
      </div>
    )
  );
};

Location.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(['province', 'district']),
};

export default Location;
