import {
  UPDATE_COMMISSIONS_ERROR,
  UPDATE_COMMISSIONS_START,
  UPDATE_COMMISSIONS_SUCCESS,
} from 'constants/action-types/settings/updateCommissions';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_COMMISSIONS_START:
      return {
        ...state,
        updateCommission: {
          ...state.updateCommission,
          loading: true,
          message: null,
        },
      };
    case UPDATE_COMMISSIONS_ERROR:
      return {
        ...state,
        updateCommission: {
          ...state.updateCommission,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_COMMISSIONS_SUCCESS:
      return {
        ...state,
        updateCommission: {
          ...state.updateCommission,
          data: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
