import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import './index.scss';
import UsersList from 'components/Users/UsersList';
import getUsers from 'redux/actions/user/getUsers';
import {
  isTechnicalLead,
  isCommunicationsAdmin,
} from 'helpers/checkRoles';
import getCurrentOrg from 'helpers/getCurrentOrg';

const Users = ({ history: { push }, location }) => {
  const currentUserOrganization = getCurrentOrg();
  const [paginationMeta, setPaginationMeta] = useState({});

  const { roles } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const { items = [], meta, loading, error, message } = useSelector(
    ({ user: { usersList } }) => usersList,
  );

  const dispatch = useDispatch();

  const handleGetUsers = (
    queries = queryString.parse(location.search),
  ) => {
    return (
      roles &&
      getUsers(currentUserOrganization, roles, queries)(dispatch)
    );
  };

  useEffect(() => {
    if (meta) {
      setPaginationMeta(meta);
    }
  }, [meta]);

  useEffect(() => handleGetUsers(), [dispatch, roles]);

  const onSearch = queries => {
    handleGetUsers(queries);
  };

  const onPaginate = queries => {
    handleGetUsers(queries);
  };

  const users = () => {
    if (items?.[0]?.user) {
      return items.map(
        ({
          user,
          user_type: userType,
          is_finance_admin: isFinanceRwandaAdmin,
          is_support_admin: isSupportAdmin,
          ...details
        }) => ({
          ...user,
          userType,
          isFinanceRwandaAdmin,
          isSupportAdmin,
          isTechnicalLead: isTechnicalLead(user.roles),
          isCommunicationsAdmin: isCommunicationsAdmin(user.roles),
          details,
        }),
      );
    }
    return items;
  };

  return (
    <UsersList
      roles={roles}
      push={push}
      meta={paginationMeta}
      handleGetUsers={handleGetUsers}
      onSearch={onSearch}
      dispatch={dispatch}
      loading={loading}
      error={error}
      users={users}
      message={message}
      onPaginate={onPaginate}
      allUsers
    />
  );
};

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Users;
