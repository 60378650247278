import {
  UPDATE_ADVERTISEMENT_ERROR,
  UPDATE_ADVERTISEMENT_START,
  UPDATE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/updateAdvertisement';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import getAllAdvertisements from './getAllAdvertisement';

export default (id, data) => (dispatch, callback) => {
  dispatch(
    apiAction({
      url: `/advertisements/${id}`,
      method: 'PUT',
      data,
      API_REQUEST_START: UPDATE_ADVERTISEMENT_START,
      API_REQUEST_FAILURE: UPDATE_ADVERTISEMENT_ERROR,
      API_REQUEST_SUCCESS: UPDATE_ADVERTISEMENT_SUCCESS,
      callbackOnSuccess: () => {
        notificationHelper({
          message: 'Advertisement successfully updated',
        });
        getAllAdvertisements()(dispatch);
        callback?.();
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};
