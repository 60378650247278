/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ProjectPartners from 'components/Projects/Partners/ProjectPartners';
import InvitePartners from 'containers/Projects/Partners/InvitePartners';
import ProjectPartner from 'containers/Projects/Partner';

const Partners = ({ history, match }) => {
  const {
    params: { option },
  } = match;

  return (
    <>
      {!option && <ProjectPartners {...{ history, match }} />}
      {option === 'invite' && (
        <InvitePartners {...{ history, match }} />
      )}
      {option && option !== 'invite' && (
        <ProjectPartner {...{ history, match }} />
      )}
    </>
  );
};

Partners.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  location: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
};

export default Partners;
