import React, { useRef, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './index.scss';

const AttachmentInput = ({
  onAddAttach,
  label,
  name,
  filename,
  onDeleteAttach,
}) => {
  const fileInputRef = useRef(null);

  const [event, setEvent] = useState({});

  const handleDelete = () => {
    onDeleteAttach(event);
  };

  return (
    <>
      <Form.Field>
        <div className="attachment">
          <div className="required-label">{label}</div>
          <div>
            <input
              className="attachment"
              ref={fileInputRef}
              type="file"
              required
              accept="image/*"
              name={name}
              style={{ display: 'none' }}
              onChange={e => {
                e.preventDefault();
                setEvent(e);
                onAddAttach(e);
              }}
            />

            <button
              type="button"
              className="attachment__icon"
              onClick={() => {
                fileInputRef.current.click();
              }}
            />
          </div>
        </div>
      </Form.Field>
      {filename && (
        <div className="attach-item">
          <div>{filename}</div>
          <Button
            onClick={() => handleDelete(event)}
            icon="trash"
            className="attach-item--button"
          />
        </div>
      )}
    </>
  );
};

AttachmentInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  filename: PropTypes.string,
  onAddAttach: PropTypes.func,
  onDeleteAttach: PropTypes.func,
};

AttachmentInput.defaultProps = {
  label: '',
  name: '',
  filename: '',
  onAddAttach: () => null,
  onDeleteAttach: () => null,
};

export default AttachmentInput;
