import SelectOrganization from 'containers/SelectOrganization';

export default {
  exact: true,
  name: 'Select Organization',
  protected: false,
  path: '/select-organization',
  component: SelectOrganization,
  permissions: ['all'],
};
