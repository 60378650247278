import {
  INVITE_PARTNER_ERROR,
  INVITE_PARTNER_START,
  INVITE_PARTNER_SUCCESS,
} from 'constants/action-types/projects/invitePartner';

export default (state, { type, payload }) => {
  switch (type) {
    case INVITE_PARTNER_START:
      return {
        ...state,
        invitePartner: {
          ...state.invitePartner,
          loading: true,
          error: null,
        },
      };
    case INVITE_PARTNER_ERROR:
      return {
        ...state,
        invitePartner: {
          ...state.invitePartner,
          error: payload,
          loading: false,
        },
      };
    case INVITE_PARTNER_SUCCESS:
      return {
        ...state,
        invitePartner: {
          ...state.invitePartner,
          items: payload.data,
          loading: false,
        },
      };
    default:
      return null;
  }
};
