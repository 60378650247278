import {
  GET_SINGLE_ORGANIZATION_START,
  GET_SINGLE_ORGANIZATION_SUCCESS,
  GET_SINGLE_ORGANIZATION_FAILURE,
  GET_SINGLE_ORGANIZATION_END,
} from 'constants/action-types/organizations/getSingleOrganization';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_SINGLE_ORGANIZATION_START:
      return {
        ...state,
        getSingleOrganization: {
          ...state.getSingleOrganization,
          loading: true,
          error: null,
        },
      };
    case GET_SINGLE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        currentOrganization: {
          details: payload,
          ...payload,
        },
        getSingleOrganization: {
          ...state.getSingleOrganization,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case GET_SINGLE_ORGANIZATION_FAILURE:
      return {
        ...state,
        getSingleOrganization: {
          ...state.getSingleOrganization,
          error: payload,
        },
      };
    case GET_SINGLE_ORGANIZATION_END:
      return {
        ...state,
        getSingleOrganization: {
          ...state.getSingleOrganization,
          loading: false,
        },
      };
    default:
      return null;
  }
};
