import React, { useState } from 'react';
import './bulkEditModal.scss';
import Proptype from 'prop-types';
import { Modal, Menu } from 'semantic-ui-react';
import capitalize from 'utils/capitalize';

const BulkEditModal = ({
  title,
  panes,
  states,
  showBulkEditModal,
  size,
}) => {
  const [activeItem, setActiveItem] = useState('Group Details');
  const handleItemClick = (e, { name }) => setActiveItem(name);

  return (
    <Modal
      onClose={() => showBulkEditModal(false)}
      open={states.isOpened}
      className="bulkEditModal"
      size={size}
      closeIcon
    >
      <Modal.Header>{capitalize(title)}</Modal.Header>
      <Menu pointing secondary text={false}>
        {panes?.map(
          ({ menuItem, show }) =>
            show && (
              <Menu.Item
                key={menuItem}
                name={`${menuItem}`}
                active={activeItem === menuItem}
                onClick={handleItemClick}
              />
            ),
        )}
      </Menu>

      <div className="bulkEditModal__content">
        {panes?.find(item => item.menuItem === activeItem)?.render}
      </div>
    </Modal>
  );
};

BulkEditModal.propTypes = {
  title: Proptype.string.isRequired,
  panes: Proptype.array.isRequired,
  states: Proptype.shape({
    loading: Proptype.bool,
    isOpened: Proptype.bool,
    errors: Proptype.string,
  }).isRequired,
  showBulkEditModal: Proptype.func.isRequired,
  size: Proptype.oneOf(['tiny', 'mini', 'small']).isRequired,
};

export default BulkEditModal;
