/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import toCumulativeDates, {
  cleanCumulative,
} from 'helpers/toCumulativeDates';
import todaysDate from 'utils/todaysDate';
import formatNumber from 'helpers/formatNumber';

const LineCharts = ({ data, year }) => {
  const {
    organizations_count: organizationsCount,
    organizations = [],
    agents_count: agentsCount,
    agents = [],
    ngos_count: ngosCount,
    ngos = [],
    groups_count: groupsCount,
    groups = [],
  } = data;
  const organizationId = useSelector(
    ({ user: { currentUser } }) =>
      currentUser?.details?.organizations[0]?.id,
  );

  const saveAdmin = () => {
    if (organizationId === 1 || organizationId === 2) return true;
    else return false;
  };

  const cumulativeAgents = agents.map(item => {
    return item.user ? item.user : item;
  });

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Organizations',
              viewMoreRedirect: saveAdmin(),
              viewKey1: 'organizations',
              view: 'general',
              date: todaysDate(year),
              amount: formatNumber(organizationsCount),
              data: organizations,
              background: greenColor,
              dataKey: 'uv',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Agents',
              viewMoreRedirect: saveAdmin(),
              viewKey1: 'all-users',
              viewKey: 'agents',
              view: 'general',
              date: todaysDate(year),
              amount: formatNumber(agentsCount),
              data: cleanCumulative(cumulativeAgents),
              background: blueColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'NGOs',
              viewMoreRedirect: saveAdmin(),
              viewKey1: 'ngos',
              view: 'general',
              date: todaysDate(year),
              amount: formatNumber(ngosCount),
              data: ngos,
              background: orangeColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Saving groups',
              viewMoreRedirect: saveAdmin(),
              viewKey1: 'all-groups',
              view: 'general',
              date: todaysDate(year),
              amount: formatNumber(groupsCount),
              data: groups,
              background: redColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default LineCharts;
