import {
  DELETE_USER_ERROR,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
} from 'constants/action-types/user/deleteUser';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (userId, data) => (
  dispatch,
  onCloseConfirmModal,
  push,
) =>
  dispatch(
    apiAction({
      url: `/users/${userId}`,
      method: 'DELETE',
      data,
      API_REQUEST_START: DELETE_USER_START,
      API_REQUEST_SUCCESS: DELETE_USER_SUCCESS,
      API_REQUEST_FAILURE: DELETE_USER_ERROR,
      onSuccess: payload => {
        onCloseConfirmModal();
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload,
        });
        push('/all-users');
        notification({ message: 'User successfully deleted' });
      },
      onFailure: ({ message }) => {
        onCloseConfirmModal();
        dispatch({
          type: DELETE_USER_ERROR,
          payload: message,
        });
        notification({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
