import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { round } from 'lodash';
import './index.scss';
import isToday from 'helpers/isToday';
import formatNumber from 'helpers/formatNumber';
import { capitalizeEachWords } from 'utils/capitalize';

const ActivityMessage = ({ item, hyperLink }) => {
  return (
    <div>
      <span>
        {item.purpose === 'org_loan' ? (
          <Link
            to={`/projects/${item?.project_id}/organizations/${item?.organization_id}/${item?.group?.id}`}
          >
            {`${capitalizeEachWords(item.group.name)} `}
          </Link>
        ) : [
            'group_subscription_fees',
            'subscription_fees',
            'transaction_charge',
          ].includes(item.purpose) ? (
          <Link to="#">
            {`${capitalizeEachWords(item.group.name)} `}
          </Link>
        ) : (
          <Link to={`/users/${item?.user?.id}`}>
            {`${item?.user?.first_name} `}
          </Link>
        )}
        {item.actual_action ? (
          <span>
            {item.actual_action}{' '}
            {item.amount &&
            !item.actual_action?.toLowerCase()?.includes('rwf') ? (
              <Link href className={hyperLink ? 'not-link' : ''}>
                {` of ${formatNumber(
                  round(Number(item.amount), 2),
                )} RWF`}
              </Link>
            ) : null}
          </span>
        ) : (
          <>
            {item.action} a {`${item.resource}`} of{' '}
            <Link href>{` of ${formatNumber(
              parseInt(item.amount, 10),
            )} RWF`}</Link>
          </>
        )}
        {/* {/[0-9]+/.test(item.actual_action) && (
          <ResourceLink
            activity={item}
            resource={item[item.resource]}
            resourceName={item.resource}
          />
        )} */}
        {item?.type === 'loan_request' &&
          item?.request?.repayment_days && (
            <span>
              to be paid in {item?.request?.repayment_days} days
            </span>
          )}
        {item.category === 'group_request' && item.request
          ? `, Approvals: ${item.request.members_who_approved} / ${item.request.members_to_approve} (${item.request.status}) `
          : null}
        {`, ${
          isToday(new Date(item.created_at))
            ? moment(item.created_at).fromNow()
            : moment(item.created_at).format('LLLL')
        }`}
      </span>
    </div>
  );
};

ActivityMessage.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  hyperLink: PropTypes.bool,
};

ActivityMessage.defaultProps = {
  hyperLink: false,
};

export default ActivityMessage;
