import {
  DEACTIVATE_FEATURE_ERROR,
  DEACTIVATE_FEATURE_START,
  DEACTIVATE_FEATURE_SUCCESS,
} from 'constants/action-types/settings/deactivateFeature';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';
import getAllFeatures from './getAllFeatures';

export default (id, action, password, platform) => (
  dispatch,
  setPinModal,
) => {
  dispatch(
    apiAction({
      url: `settings/${id}/${action}`,
      method: 'PUT',
      data: { password },
      queries: { platform },
      API_REQUEST_START: DEACTIVATE_FEATURE_START,
      API_REQUEST_SUCCESS: DEACTIVATE_FEATURE_SUCCESS,
      API_REQUEST_FAILURE: DEACTIVATE_FEATURE_ERROR,
      onSuccess: ({ message }) => {
        dispatch({
          type: DEACTIVATE_FEATURE_SUCCESS,
          payload: message,
        });
        getAllFeatures()(dispatch);
        setPinModal(false);
        notification({ message });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: DEACTIVATE_FEATURE_ERROR,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          setPinModal(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
};
