/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import XLSX from 'xlsx';
import {
  Button,
  Icon,
  Modal,
  Checkbox,
  Divider,
  Popup,
  Message,
} from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import _, { isPlainObject } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './ExportCSV.scss';
import Loader from 'components/common/PageLoader';
import capitalize from 'utils/capitalize';
import FetchExportData from 'components/common/FetchExportData';
import clearExportData from 'redux/actions/export/clearExportData';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions/';
import getUserRoles from 'helpers/getUserRoles';

const ExportCSV = ({
  data,
  fileName,
  customHeaders,
  customExport,
  fetchExportData,
  url,
}) => {
  let headers = [];
  const [dataHasBeenFetched, setDataHasBeenFetched] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);
  const [CSVHeaders, setCSVHeaders] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);

  const { pathname } = useLocation();

  const { data: fetchData, loading, error } = useSelector(
    ({ exportData }) => exportData,
  );

  const { roles, currentOrgId, orgUsers } = getUserRoles();

  const dispatch = useDispatch();

  const getLabel = key => capitalize(key.replace(/_/g, ' '));
  const renamedLabels = {
    user_first_name: getLabel('inviter_first_name'),
    user_last_name: getLabel('inviter_last_name'),
    user_middle_name: getLabel('inviter_middle_name'),
  };

  const csvData = () =>
    (Array.isArray(data) ? data : []).map(item => {
      Object.keys(item).forEach(key => {
        const label = renamedLabels[key] || getLabel(key);
        const newHeader = { label, key, children: [] };

        if (
          item[key] &&
          (Array.isArray(item[key]) || typeof item[key] === 'object')
        ) {
          if (Array.isArray(item[key])) {
            if (item[key].length > 0) {
              const subItem = item[key][0];
              Object.keys(subItem).forEach(subKey => {
                newHeader.children = [
                  ...newHeader.children,
                  {
                    label: getLabel(subKey),
                    key: subKey,
                    checked: true,
                    disabled: false,
                  },
                ];
              });
            }
          } else {
            Object.keys(item[key]).forEach(subKey => {
              newHeader.children = [
                ...newHeader.children,
                {
                  label: getLabel(subKey),
                  key: subKey,
                  checked: true,
                  disabled: false,
                },
              ];
            });
          }
        }

        headers = !headers.find(header => header.label === label)
          ? [...headers, newHeader]
          : headers;
      });
      return item;
    });

  if (!fetchExportData) csvData();

  const formatObjectData = item => {
    if (isPlainObject(item))
      return Object.values(item)?.reduce((value, subItem) => {
        if (subItem) {
          value += `, ${
            typeof subItem === 'object' ? '' : `${subItem}`
          }`;
        }
        return value;
      }, '');
    return '';
  };

  const setInitialDataToExport = () => {
    const initialDataToExport = _.cloneDeep(csvData()).map(item => {
      Object.keys(item).forEach(key => {
        const newItem = item[key];
        let value = '';
        if (newItem) {
          value = formatObjectData(newItem);
          if (Array.isArray(newItem)) {
            newItem?.forEach(
              arrayItem => (value += formatObjectData(arrayItem)),
            );
          }
          item[key] = value.replace(',', '') || newItem;
        }
      });
      return item;
    });
    setDataToExport(initialDataToExport);
  };

  const handleSelect = ({ key, label, checked }) => {
    updateChildren(checked, key);
    setSelectedHeaders(
      checked
        ? [...selectedHeaders, { key, label }]
        : selectedHeaders.filter(
            header => String(header.key) !== String(key),
          ),
    );
  };

  const updateChildrenData = (parentKey, selected) => {
    csvData().map((item, index) => {
      let value = '';
      if (item[parentKey] && Array.isArray(item[parentKey])) {
        item[parentKey].forEach(subItem => {
          Object.keys(subItem).forEach((subKey, i) => {
            if (subKey && selected.indexOf(subKey) !== -1) {
              value += `${getLabel(subKey)}: ${subItem[subKey]}${
                i === Object.keys(subItem).length - 1 ? '' : ', '
              }`;
            }
          });
        });
        dataToExport[index][parentKey] = value || item[parentKey];
      } else if (
        item[parentKey] &&
        typeof item[parentKey] === 'object'
      ) {
        Object.keys(item[parentKey]).forEach((subKey, i) => {
          if (subKey && selected.indexOf(subKey) !== -1) {
            value += `${getLabel(subKey)}: ${
              item[parentKey][subKey]
            }${
              i === Object.keys(item[parentKey]).length - 1
                ? ''
                : ', '
            }`;
          }
        });
        dataToExport[index][parentKey] = value || item[parentKey];
      }
      return item;
    });

    setDataToExport(dataToExport);
  };

  const handleChildrenSelect = ({ parentKey, childKey, checked }) => {
    const selected = [];
    const noChildren = [];
    let parentLabel = '';
    setCSVHeaders(
      CSVHeaders.map(item => {
        if (item.key === parentKey) {
          item.children = (item.children || []).map(subItem => {
            parentLabel = subItem.label;
            if (subItem.key === childKey) subItem.checked = checked;
            if (subItem.checked) {
              selected.push(subItem.key);
              noChildren.push(1);
            }

            return subItem;
          });
        }
        return item;
      }),
    );

    // if all children are false, set the parent to false
    if (!noChildren.includes(1)) {
      handleSelect({
        key: parentKey,
        label: parentLabel,
        checked: false,
      });
    }

    updateChildrenData(parentKey, selected);
  };

  const updateChildren = (checked, key) => {
    if (key) {
      CSVHeaders.map(item => {
        if (item.key === key && item.children.length > 0) {
          const selected = [];
          item.children.forEach(subItem => {
            if (checked) {
              selected.push(subItem.key);
            }
            subItem.checked = checked;
            subItem.disabled = !checked;
          });
          updateChildrenData(key, selected);
        }
      });
    } else {
      CSVHeaders.map(item => {
        if (item.children.length > 0) {
          item.children.forEach(subItem => {
            subItem.checked = checked;
            subItem.disabled = !checked;
          });
        }
      });
    }
  };

  const [format, setFormat] = useState();

  const cleanUpAfterClose = () => {
    if (fetchExportData) {
      clearExportData()(dispatch);
      setDataHasBeenFetched(false);
    }
  };

  const handleExportToExcel = () => {
    const filterForExcelExport = [];

    // To Filter out unselected columns
    dataToExport.forEach(item => {
      const filteredColumns = {};
      selectedHeaders.forEach(subItem => {
        filteredColumns[subItem.label] = item[subItem.key];
      });
      filterForExcelExport.push(filteredColumns);
    });
    const binaryWS = XLSX.utils.json_to_sheet(filterForExcelExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, binaryWS, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    setOpenModal(false);
    cleanUpAfterClose();
  };

  const ExportButton = () =>
    customExport ? (
      <Button
        className="toolbar-export-csv-button-custom"
        icon
        size="mini"
        onClick={() => {
          setCSVHeaders(headers);
          setSelectedHeaders(headers);
          setInitialDataToExport();
        }}
      >
        <Icon name="external alternate" />
      </Button>
    ) : (
      <Button
        className="toolbar-export-csv-button"
        onClick={() => {
          if (!fetchExportData) {
            setCSVHeaders(headers);
            setSelectedHeaders(headers);
            setInitialDataToExport();
          }
        }}
      >
        <Icon name="external alternate" />
      </Button>
    );

  const selectOrDeselectedAll = () => {
    if (selectedAll) {
      setSelectedHeaders([]);
      updateChildren(false);

      // This is to update the state of CSVHeaders
      // since its only mutating the value in the previous `updateChildren` method;
      setCSVHeaders(CSVHeaders);

      setSelectedAll(false);
    } else {
      setSelectedHeaders(headers);
      updateChildren(true);
      setCSVHeaders(CSVHeaders);
      setSelectedAll(true);
    }
  };

  const exportCSVReady = () => {
    return (
      <>
        <Modal.Content scrolling>
          {(CSVHeaders || []).map(({ key, label, children }) => (
            <div className="field-to-export" key={key}>
              <Checkbox
                className="checkbox"
                toggle
                value={key}
                label={label}
                checked={
                  !!selectedHeaders.find(header => header.key === key)
                }
                onChange={(e, { checked }) =>
                  handleSelect({ key, label, checked })
                }
              />
              {Array.isArray(children) && children.length ? (
                <div className="radio">
                  {children.map(item => (
                    <div className="child-field-to-export">
                      <Checkbox
                        className="checkbox"
                        toggle
                        label={item.label}
                        value={item.key}
                        checked={item.checked}
                        disabled={item.disabled}
                        onChange={(e, { checked }) =>
                          handleChildrenSelect({
                            parentKey: key,
                            childKey: item.key,
                            checked,
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              <Divider />
            </div>
          ))}
        </Modal.Content>
        <Modal.Actions>
          {format === 'CSV' && (
            <CSVLink
              data={dataToExport}
              filename={fileName}
              headers={customHeaders || selectedHeaders}
              onClick={() => {
                setSelectedAll(true);
              }}
            >
              <Button
                primary
                onClick={() => {
                  setOpenModal(false);
                  cleanUpAfterClose();
                }}
              >
                Export
              </Button>
            </CSVLink>
          )}

          {format === 'Excel' && (
            <Button primary onClick={handleExportToExcel}>
              Export
            </Button>
          )}
        </Modal.Actions>
      </>
    );
  };

  const showSelectOrDeselectButton = () => {
    if (fetchExportData) {
      return dataHasBeenFetched ? 'show' : 'hide';
    }
    return 'show';
  };

  const fetchExportDataResult = () => {
    if (dataHasBeenFetched) {
      return exportCSVReady();
    }

    if (loading)
      return (
        <Modal.Content className="loader_container">
          <Loader />
        </Modal.Content>
      );

    if (error)
      return (
        <Modal.Content>
          <Message negative>Other error..{error?.message}</Message>
        </Modal.Content>
      );

    if (!loading && !!fetchData?.data) {
      data = fetchData.data;
      csvData();
      setCSVHeaders(headers);
      setSelectedHeaders(headers);
      setInitialDataToExport();
      setDataHasBeenFetched(true);
    }
    return <FetchExportData url={url} />;
  };

  return (
    <div className="ExportCSV">
      {canPerformAction(
        permissions.canPerformAction.canExport,
        roles,
        {
          currentOrgId,
          orgUsers,
          additionalCondition: pathname === '/',
        },
      ) && (
        <>
          <Popup
            trigger={
              <div>
                <ExportButton />
              </div>
            }
            on="click"
            pinned
            position="bottom center"
            content={
              <div className="export-format">
                <Button
                  className="export-format-button"
                  onClick={() => {
                    setFormat('CSV');
                    setOpenModal(true);
                  }}
                >
                  CSV Format
                </Button>
                <Button
                  className="export-format-button"
                  onClick={() => {
                    setFormat('Excel');
                    setOpenModal(true);
                  }}
                >
                  Excel Format
                </Button>
              </div>
            }
          />
          <Modal
            size="mini"
            open={openModal}
            onClose={() => {
              setOpenModal(false);
              cleanUpAfterClose();
            }}
            closeIcon
          >
            <Modal.Header>
              Export to {format}
              <div
                className={`whole_selector ${showSelectOrDeselectButton()}`}
                onClick={selectOrDeselectedAll}
              >
                {selectedAll ? 'DESELECT ALL' : 'SELECT ALL'}
              </div>
            </Modal.Header>
            {fetchExportData
              ? fetchExportDataResult()
              : exportCSVReady()}
          </Modal>
        </>
      )}
    </div>
  );
};

ExportCSV.defaultProps = {
  data: [],
  fileName: 'file.csv',
  customHeaders: null,
  customExport: false,
  fetchExportData: false,
  url: '',
};

ExportCSV.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  fileName: PropTypes.string,
  customExport: PropTypes.bool,
  customHeaders: PropTypes.oneOfType([PropTypes.array]),
  fetchExportData: PropTypes.bool,
  url: PropTypes.string,
};

export default ExportCSV;
