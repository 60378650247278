import initialState from 'redux/initial-states/organizations';
import createProject from './createProject';
import listProjects from './listProjects';
import getProject from './getProject';
import updateProject from './updateProject';
import invitePartner from './invitePartner';
import projectPartners from './getPartners';
import projectPartner from './getPartner';
import removeAgent from './removeAgent';

export default (state = initialState, action = {}) => ({
  ...state,
  ...createProject(state, action),
  ...listProjects(state, action),
  ...getProject(state, action),
  ...updateProject(state, action),
  ...invitePartner(state, action),
  ...projectPartners(state, action),
  ...projectPartner(state, action),
  ...removeAgent(state, action),
});
