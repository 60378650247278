/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { read, utils } from 'xlsx';
import './index.scss';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import cancelImage from 'assets/images/cancel.svg';
import successImage from 'assets/images/success.svg';
import errorImage from 'assets/images/error.svg';
import folderImage from 'assets/images/file.svg';
import csvFileExtension from 'assets/images/csvFileExtension.svg';
import xlsFileExtension from 'assets/images/xlsFileExtension.svg';
import xlsxFileExtension from 'assets/images/xlsxFileExtension.svg';
import uploadBulkTransaction from 'redux/actions/groups/uploadBulkTransaction';
import SimpleModal from 'components/common/modals/SimpleModal';
import UploadProgress from 'components/common/UploadProgress';

const UploadSuccess = ({ message }) => (
  <div className="upload-box">
    <div className="top">
      <div>
        <span className="action">Upload complete</span>
        <img src={successImage} alt="" />
      </div>

      <div className="top-success">
        <Icon name="info circle" color="blue" />
        <span className="">{message}</span>
      </div>
    </div>
  </div>
);

const UploadError = ({
  error: { downloadable, errors, message } = {},
  setState,
}) => {
  return (
    <div className="upload-box">
      <div className="top error">
        <div className="top-error">
          <img src={errorImage} alt="" />
          <span>Error</span>
        </div>
        <button type="button" onClick={() => setState()}>
          <img src={cancelImage} className="cancel-image" alt="" />
        </button>
      </div>
      <div className="error-message">
        {message && <span> {message}</span>}
        {errors &&
          errors.map(({ message, path, content, data }) => (
            <>
              <span>{message}</span>
              {path && path[0] ? (
                <span> Field: {path[0]}</span>
              ) : null}
              {content && content.key ? (
                <span>, Key: {content.key}</span>
              ) : null}
              {content && content.value ? (
                <span>, Value: {content.value}</span>
              ) : null}
              {data && (
                <div>
                  <br />
                  Data: {data}
                </div>
              )}
            </>
          ))}
        <br />
        {downloadable && (
          <>
            Download your file <span className="link"> here</span>
          </>
        )}
      </div>
    </div>
  );
};

const BulkUpload = ({ open, setOpen }) => {
  const [error, setError] = useState({});
  const [state, setState] = useState();
  const dispatch = useDispatch();
  const { data, error: apiError, loading } = useSelector(
    ({ groups: { bulkTransactionUpload } }) => bulkTransactionUpload,
  );

  useEffect(() => {
    if (apiError) {
      setError(apiError);
      setState('error');
    } else {
      setError({});
      setState(null);
    }
  }, [apiError]);

  const handleSubmit = data => {
    if (data.length > 1000) {
      setState('error');
      setError({
        errors: [
          {
            message:
              'The payload is too large, the limit is 1000 transactions',
          },
        ],
      });
      return;
    }

    uploadBulkTransaction({ uploadedTransactions: data }, () =>
      setState('success'),
    )(dispatch);
  };

  const onDrop = useCallback(async ([file]) => {
    const supportedExtensions = ['csv', 'xls', 'xlsx'];
    const format = file.path.split('.').pop();

    if (!supportedExtensions.includes(format.toLowerCase())) {
      setError({ message: `Format not supported *.${format}` });
      setState('error');
      return;
    }

    try {
      if (file) {
        setState('progress');
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = event => {
          const data = event.target.result;
          const workbook = read(data, { type: 'binary' });

          workbook.SheetNames.forEach(sheet => {
            const rowObject = utils.sheet_to_json(
              workbook.Sheets[sheet],
            );

            rowObject.forEach(row => {
              const { provider_reference_id, status, ...rest } = row;
              if (
                !provider_reference_id ||
                !status ||
                !isEmpty(rest)
              ) {
                setError({
                  message:
                    'Sheet not allowed, please upload the appropriate file format.',
                });
                setState('error');
                throw new Error(
                  'Sheet not allowed, please upload the appropriate file format.',
                );
              }
            });

            handleSubmit(rowObject);
          });
        };
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(error);
      }
      setState('error');
      const message =
        error.message ||
        'An error occured, please upload the appropriate file format.';
      setError({
        downloadable: false,
        message,
        errors: [{ message }],
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <SimpleModal
      open={open}
      onClose={() => setOpen(false)}
      title="Upload transaction data"
    >
      <section className="bulk-upload-transaction">
        <div className="drag-box" {...getRootProps()}>
          <p>
            Download Data Compilation Sheet
            <span className="link"> here</span>
          </p>
          <img src={folderImage} alt="" className="file" />

          <input {...getInputProps()} />

          {!isDragActive && (
            <p>
              Drag and drop your Data Compilation sheet here or
              <span className="link"> browse</span>
            </p>
          )}

          {isDragActive && <p>Drop the file here..</p>}

          <div className="format">
            <span>Supported format:</span>
            <div className="format__file-extensions">
              <img src={csvFileExtension} alt="" />
              <img src={xlsFileExtension} alt="" />
              <img src={xlsxFileExtension} alt="" />
            </div>
          </div>
        </div>

        {(state === 'progress' || loading) && (
          <UploadProgress setState={setState} />
        )}
        {state === 'success' && (
          <UploadSuccess message={data.message} />
        )}
        {state === 'error' && (
          <UploadError
            error={apiError || error}
            setState={setState}
          />
        )}
      </section>
    </SimpleModal>
  );
};

UploadError.propTypes = {
  error: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

UploadSuccess.propTypes = {
  message: PropTypes.string.isRequired,
};

BulkUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BulkUpload;
