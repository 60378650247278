import initialState from 'redux/initial-states/communication';
import addAdvertisement from './addAdvertisement';
import getAllAdvertisement from './getAllAdvertisements';
import activateAdvertisement from './activateAdvertisement';
import deactivateAdvertisement from './deactivateAdvertisement';
import deleteAdvertisement from './deleteAdvertisement';
import getAllEmails from '../emails/getAllEmails';
import sendEmails from '../emails/sendEmails';
import updateAdvertisement from './updateAdvertisement';
import singleAdvertisement from './singleAdvertisement';

export default (state = initialState, action = {}) => ({
  ...state,
  ...addAdvertisement(state, action),
  ...getAllAdvertisement(state, action),
  ...activateAdvertisement(state, action),
  ...deactivateAdvertisement(state, action),
  ...deleteAdvertisement(state, action),
  ...getAllEmails(state, action),
  ...sendEmails(state, action),
  ...updateAdvertisement(state, action),
  ...singleAdvertisement(state, action),
});
