import {
  GET_SINGLE_BILL_ERROR,
  GET_SINGLE_BILL_START,
  GET_SINGLE_BILL_SUCCESS,
} from 'constants/action-types/settings/getSingleBill';
import apiAction from 'helpers/apiAction';

export default id => dispatch =>
  dispatch(
    apiAction({
      url: `/admin/billing/transactions_charges_settings/${id}`,
      method: 'GET',
      API_REQUEST_START: GET_SINGLE_BILL_START,
      API_REQUEST_SUCCESS: GET_SINGLE_BILL_SUCCESS,
      API_REQUEST_FAILURE: GET_SINGLE_BILL_ERROR,
      onEnd: () => false,
    }),
  );
