export default error => {
  if (
    error?.response?.data?.status === 401 &&
    error?.response?.data?.code === 'AUT_02'
  ) {
    localStorage.setItem('PREVIOUS_URL', window.location.pathname);
    localStorage.removeItem('token');
    return window.location.replace('/login');
  }
  if (error?.response) return error?.response?.data;
  return {
    message: error?.message,
  };
};
