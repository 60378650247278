import CreateOrganisation from 'containers/Organizations/CreateOrganization/CreateOrganization';

export default {
  exact: true,
  name: 'Create Organisation',
  protected: true,
  path: '/create-organization',
  component: CreateOrganisation,
  permissions: ['all'],
};
