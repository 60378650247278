/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Form, Input, Breadcrumb } from 'semantic-ui-react';

import './index.scss';
import Toolbar from 'components/common/Toolbar';
import groupTypeOptions from 'constants/options/groupTypeOptions';
import { toDropDownOptions } from 'utils/dropdownOptions';
import Message from 'components/common/Message';
import formatNumber from 'helpers/formatNumber';

const ProfileInfo = ({
  form,
  defaultValues,
  loadingProfile,
  profiles,
  backToProfileOverview,
  handlechange,
  validateForm,
  handleNext,
  disableBtn,
  formError,
  customizedTitle = false,
}) => {
  const { pathname } = useLocation();
  const { loading } = useSelector(
    ({ profiles: { countProfileGroups } }) => countProfileGroups,
  );

  return (
    <div className="profile-info">
      {!customizedTitle ? (
        <Toolbar header={{ title: 'New profile' }} />
      ) : (
        <Toolbar
          header={{
            title:
              !pathname.includes('/organizations/') &&
              'Profile settings',
            navigation: false,
            leftComponent: pathname.includes('/organizations/') && (
              <Breadcrumb className="customized-title" size="massive">
                <Breadcrumb.Section active className="cursor">
                  Profile
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section
                  active
                  className="cursor"
                  onClick={() => {
                    backToProfileOverview(defaultValues?.id);
                  }}
                >
                  {defaultValues?.name}
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section active>
                  Profile settings
                </Breadcrumb.Section>
              </Breadcrumb>
            ),
          }}
        />
      )}
      <div className="container">
        <div className="wrapper">
          <div className="title">
            <div>Profile information</div>
          </div>

          <Form loading={loadingProfile}>
            <Form.Input
              fluid
              label="Name"
              name="name"
              type="text"
              placeholder="Name"
              defaultValue={form?.name || defaultValues?.name}
              onChange={handlechange}
            />

            <label>Monthly Savings</label>
            <Form.Group widths="equal" className="group-items">
              <Form.Field>
                <Input
                  label={{ basic: true, content: 'Rwf' }}
                  placeholder="Minimum Shares"
                  labelPosition="left"
                  type="number"
                  name="min_monthly_savings"
                  fluid
                  defaultValue={
                    form?.min_monthly_savings ||
                    defaultValues?.min_monthly_savings
                  }
                  onBlur={e =>
                    validateForm({
                      name: e?.target?.name,
                      value: e?.target?.value,
                      type: e?.target?.type,
                    })
                  }
                  error={formError?.min_savings_range_exists?.status}
                />
              </Form.Field>
              <span className="input-separator" />
              <Form.Field>
                <Input
                  placeholder="Maximum Shares"
                  name="max_monthly_savings"
                  type="number"
                  defaultValue={
                    form?.max_monthly_savings ||
                    defaultValues?.max_monthly_savings
                  }
                  onBlur={e =>
                    validateForm({
                      name: e?.target?.name,
                      value: e?.target?.value,
                      type: e?.target?.type,
                    })
                  }
                  error={
                    formError?.max_monthly_savings?.status ||
                    formError?.max_savings_range_exists?.status
                  }
                />
              </Form.Field>
            </Form.Group>
            <div className="group-items__label">
              {profiles?.map(profile => (
                <span className="group-items__label--amount">
                  {`
                  ${formatNumber(profile?.min_monthly_savings)} -
                  ${formatNumber(profile?.max_monthly_savings)}
                `}
                </span>
              ))}
            </div>
            <br />
            {formError?.max_monthly_savings?.status && (
              <Message
                message={formError?.max_monthly_savings?.message}
                color="red"
              />
            )}
            {!formError?.max_monthly_savings?.status &&
              formError?.min_savings_range_exists?.status && (
                <Message
                  message={
                    formError?.min_savings_range_exists?.message
                  }
                  color="red"
                />
              )}
            {!formError?.max_monthly_savings?.status &&
              formError?.max_savings_range_exists?.status && (
                <Message
                  message={
                    formError?.max_savings_range_exists?.message
                  }
                  color="red"
                />
              )}

            <label>Monthly Loans</label>
            <Form.Group widths="equal" className="group-items">
              <Form.Field>
                <Input
                  label={{ basic: true, content: 'Rwf' }}
                  placeholder="Minimum Loans"
                  labelPosition="left"
                  type="number"
                  name="min_monthly_loans"
                  fluid
                  defaultValue={
                    form?.min_monthly_loans ||
                    defaultValues?.min_monthly_loans
                  }
                  onBlur={e =>
                    validateForm({
                      name: e?.target?.name,
                      value: e?.target?.value,
                      type: e?.target?.type,
                    })
                  }
                  error={formError?.min_loans_range_exists?.status}
                />
              </Form.Field>
              <span className="input-separator" />
              <Form.Field>
                <Input
                  placeholder="Maximum Loans"
                  name="max_monthly_loans"
                  type="number"
                  defaultValue={
                    form?.max_monthly_loans ||
                    defaultValues?.max_monthly_loans
                  }
                  onBlur={e =>
                    validateForm({
                      name: e?.target?.name,
                      value: e?.target?.value,
                      type: e?.target?.type,
                    })
                  }
                  error={
                    formError?.max_monthly_loans?.status ||
                    formError?.max_loans_range_exists?.status
                  }
                />
              </Form.Field>
            </Form.Group>
            <div className="group-items__label">
              {profiles?.map(profile => (
                <span className="group-items__label--amount">
                  {`
                  ${formatNumber(profile?.min_monthly_loans)} -
                  ${formatNumber(profile?.max_monthly_loans)}
                `}
                </span>
              ))}
            </div>
            <br />

            {formError?.max_monthly_loans?.status && (
              <Message
                message={formError?.max_monthly_loans?.message}
                color="red"
              />
            )}
            {!formError?.max_monthly_loans?.status &&
              formError?.min_loans_range_exists?.status && (
                <Message
                  message={formError?.min_loans_range_exists?.message}
                  color="red"
                />
              )}
            {!formError?.max_monthly_loans?.status &&
              formError?.max_loans_range_exists?.status && (
                <Message
                  message={formError?.max_loans_range_exists?.message}
                  color="red"
                />
              )}
            {loadingProfile || (
              <Form.Dropdown
                label="Group Type"
                placeholder="Select group type"
                name="groups_types"
                multiple
                search
                fluid
                selection
                options={toDropDownOptions(groupTypeOptions)}
                defaultValue={
                  form?.groups_types || defaultValues?.groups_types
                }
                onChange={handlechange}
              />
            )}

            <Form.Input
              label="Cycle count"
              name="cycles_count"
              type="number"
              placeholder="Enter cycle number"
              fluid
              defaultValue={
                form?.cycles_count || defaultValues?.cycles_count
              }
              onChange={handlechange}
            />
            <Form.Button
              floated="right"
              type="submit"
              disabled={disableBtn}
              loading={loading}
              primary
              onClick={handleNext}
            >
              Next
            </Form.Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

ProfileInfo.defaultProps = {
  form: {},
  defaultValues: {},
  loadingProfile: false,
  profiles: [],
  disableBtn: false,
  formError: {},
  validateForm: () => null,
  customizedTitle: false,
};

ProfileInfo.propTypes = {
  form: PropTypes.object,
  defaultValues: PropTypes.object,
  loadingProfile: PropTypes.bool,
  profiles: PropTypes.array,
  handlechange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  disableBtn: PropTypes.bool,
  formError: PropTypes.object,
  validateForm: PropTypes.func,
  backToProfileOverview: PropTypes.func.isRequired,
  customizedTitle: PropTypes.bool,
};

export default ProfileInfo;
