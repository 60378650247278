/**
 * @param {int|string} num
 * @returns {string} a formatted number with grouped thousands separated by commas
 */
function currencyFormat(num, currency = 'Rwf') {
  let number = Number(num);
  number = Number.isNaN(number) ? 0 : number;
  number = number
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return `${number}${currency ? ` ${currency}` : ''}`;
}

export default currencyFormat;
