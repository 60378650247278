export default {
  translation: {
    Welcome: 'Welcome!',
    Phone: 'Phone',
    Password: 'Password',
    'Sign in': 'Sign in',
    'Forgot password?': 'Forgot password?',
    forgotPin: 'Forgot PIN ?',
    adminSuccessfullyCreated: 'Admin has successfully been created',
    enterEmailAddress: 'Please enter a valid Email address',
    firstNameNoNumbers: 'First name should not contain numbers',
    lastNameNoNumbers: 'LastName should not contain numbers',
    enterValidPhone: 'Please enter a valid phone number',
    Project: 'Project',
    Partner: 'Partner',
    'Saving Groups': 'Saving Groups',
    Budget: 'Budget',
    Duration: 'Duration',
    'No partners': 'No partners',
    'No groups': 'No groups',
    Create: 'Create',
    'View all': 'View all',
    Overview: 'Overview',
    Projects: 'Projects',
    Agent: 'Agent',
    Agents: 'Agents',
    Settings: 'Settings',
    'Created on': 'Created on',
  },
};
