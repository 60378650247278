import AllTransactions from 'containers/Wallet/AllTransactions';

export default {
  exact: true,
  name: 'Transactions',
  protected: true,
  path: '/transactions',
  component: AllTransactions,
  permissions: ['all'],
};
