import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import './FilterButton.scss';

const FilterButton = ({ onClickFilter, filterClicked, primary }) => {
  return (
    <Button
      className="toolbar-filter-button"
      basic
      compact
      primary={primary}
      onClick={() => {
        onClickFilter();
        filterClicked();
      }}
    >
      <Icon name="filter" />
    </Button>
  );
};

FilterButton.defaultProps = {
  onClickFilter: () => true,
  filterClicked: () => {},
  primary: false,
};

FilterButton.propTypes = {
  onClickFilter: PropTypes.func,
  primary: PropTypes.bool,
  filterClicked: PropTypes.func,
};

export default FilterButton;
