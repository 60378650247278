export default (requiredFields, allFields = {}) => {
  const isRequired = requiredFields.every(field => {
    if (
      Object.keys(allFields)?.includes(field) &&
      allFields[field] &&
      Array.isArray(allFields[field])
    ) {
      return !!allFields[field].length;
    }
    return (
      Object.keys(allFields)?.includes(field) && allFields[field]
    );
  });

  return isRequired;
};
