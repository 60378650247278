import {
  ADD_BILL_ERROR,
  ADD_BILL_START,
  ADD_BILL_SUCCESS,
} from 'constants/action-types/settings/addBill';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default data => (dispatch, push) => {
  dispatch(
    apiAction({
      url: '/admin/billing/transactions_charges_settings',
      method: 'POST',
      data,
      API_REQUEST_START: ADD_BILL_START,
      API_REQUEST_FAILURE: ADD_BILL_ERROR,
      API_REQUEST_SUCCESS: ADD_BILL_SUCCESS,
      callbackOnSuccess: ({ message }) => {
        push('/settings/billing');
        notification({ message });
      },
      callbackOnFailure: ({ message }) => {
        notification({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};
