import {
  GET_SINGLE_USER_START,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  GET_SINGLE_USER_END,
} from 'constants/action-types/user/getSingleUser';
import { UPDATE_USER_END } from 'constants/action-types/user/updateUser';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_SINGLE_USER_START:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          loading: true,
          error: null,
        },
      };
    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          userDetails: payload,
          error: null,
        },
      };
    case GET_SINGLE_USER_FAILURE:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          userDetails: {},
          error: payload,
        },
      };
    case GET_SINGLE_USER_END:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          loading: false,
        },
      };
    case UPDATE_USER_END:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          userDetails: payload,
        },
      };
    default:
      return null;
  }
};
