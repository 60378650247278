const convertToPairedArray = urlToPair => {
  let currentUrlPairIndex = 0;
  const newArray = [];

  while (currentUrlPairIndex < urlToPair.length) {
    if (currentUrlPairIndex + 1 === urlToPair.length) break;
    if (Number.isNaN(+String(urlToPair[currentUrlPairIndex + 1]))) {
      currentUrlPairIndex += 1;
    } else {
      newArray.push([
        urlToPair[currentUrlPairIndex],
        urlToPair[currentUrlPairIndex + 1],
      ]);
      currentUrlPairIndex += 2;
    }
  }
  return newArray;
};

const urlIdParser = urlToSplit => {
  const urlArray = urlToSplit.split('/').filter(i => i !== '');
  const pairedArray = convertToPairedArray(urlArray);

  return Object.fromEntries(pairedArray);
};

export default urlIdParser;
