export default {
  currentUser: {
    isAuthenticated: !!localStorage.token,
    details: {},
    loading: false,
  },
  login: {
    error: {},
    loading: false,
  },
  logout: {
    error: {},
    loading: false,
  },
  addUser: {
    user: {},
    isCreating: false,
    error: {},
  },
  usersList: {
    error: null,
    loading: false,
    items: [],
    meta: {},
    message: null,
  },
  getSingleUser: {
    userDetails: {},
    error: null,
    loading: false,
  },
  singleUserRoles: {
    data: {},
    error: null,
    loading: false,
  },
  toggleUserRoles: {
    message: null,
    error: null,
    loading: false,
  },
  toggleRolesInBulk: {
    message: null,
    error: null,
    loading: false,
  },
  deactivateUser: {
    user: {},
    isDeactivating: false,
    error: {},
  },
  updateUser: {
    loading: false,
    error: null,
    message: null,
  },
  updateUserPhone: {
    loading: false,
    error: null,
    message: null,
  },
  resetPassword: {
    loading: false,
    isAccountVerified: false,
    isPasswordReset: false,
    error: null,
    message: null,
  },
  changePin: {
    loading: false,
    error: null,
    message: null,
  },
  resetUserPassword: {
    loading: false,
    error: null,
    data: null,
  },
  searchUser: {
    loading: false,
    error: null,
    items: [],
  },
  searchAgent: {
    loading: false,
    error: null,
    items: [],
  },
  deleteUser: {
    loading: false,
    error: null,
    message: null,
  },
  unblockUser: {
    loading: false,
    error: null,
    message: null,
  },
  transferGroups: {
    message: null,
    error: null,
    loading: false,
  },
  createReferalAlias: {
    loading: false,
    error: null,
    message: null,
  },
  getTransactions: {
    data: [],
    error: null,
    loading: false,
  },
};
