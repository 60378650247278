import {
  GET_EXPORT_DATA_START,
  GET_EXPORT_DATA_SUCCESS,
  GET_EXPORT_DATA_ERROR,
} from 'constants/action-types/export/exportData';
import { CLEAR_EXPORT_DATA } from 'constants/action-types/export/clearExportData';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_EXPORT_DATA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    case GET_EXPORT_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case CLEAR_EXPORT_DATA:
      return { loading: false, error: null, data: null };
    default:
      return null;
  }
};
