/* eslint jsx-a11y/label-has-associated-control: "off" */
import React, { useState } from 'react';
import {
  Form,
  Button,
  Input,
  Header,
  Label,
} from 'semantic-ui-react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toDropDownOptions } from 'utils/dropdownOptions';
import contributionFrequency from 'constants/contributionFrequency';
import days from 'constants/days';
import isCycleExpired from 'helpers/isCycleExpired';
import Message from 'components/common/Message';
import updateGroup from 'redux/actions/organizations/updateGroup';
import validateMinimumValue from 'helpers/validators/validateMinimumValue';

const FinancialDetails = () => {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const { group } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup?.item,
  );

  const { isSupportAdmin } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const minimumShareValueError = validateMinimumValue(
    form?.share_value_min,
    500,
    ['independent', 'graduated'].includes(group.type),
  );

  const socialFundError = validateMinimumValue(
    form?.social_fund,
    500,
    ['independent', 'graduated'].includes(group.type),
  );

  const updateMaximumShareValue = (
    firstFactor,
    secondFactor,
    changedInput,
  ) => {
    const maximumShareValue =
      Number(firstFactor) * Number(secondFactor);
    setForm({
      ...form,
      [changedInput.name]: changedInput.value,
      share_value_max: maximumShareValue,
    });
  };

  const handleChange = (e, data) => {
    if (data.name === 'share_value_min') {
      return updateMaximumShareValue(
        data.value,
        form?.maximum_shares || group.maximum_shares,
        data,
      );
    }

    if (data.name === 'maximum_shares')
      return updateMaximumShareValue(
        data.value,
        form?.share_value_min || group.share_value_min,
        data,
      );

    return setForm({ ...form, [data.name]: data.value });
  };

  const { loading, error } = useSelector(
    ({ organizations: { updateGroup } }) => updateGroup,
  );

  const handleSubmit = () => {
    updateGroup(group?.id, form)(dispatch);
  };

  const dayOfSaving =
    form.saving_frequency === 'weekly'
      ? moment.weekdays()
      : form.saving_frequency === 'monthly'
      ? days
      : [];

  const isDisabled = cycleStatus => {
    const groupCycle = group?.cycles?.reduce((previous, current) => {
      if (current?.id > previous?.id) return current;
      return previous;
    });
    return (
      !isSupportAdmin ||
      cycleStatus === groupCycle?.status ||
      group?.status === 'active' ||
      isCycleExpired(groupCycle?.end_date)
    );
  };

  if (group?.saving_time && !form?.saving_time)
    setForm({
      ...form,
      saving_frequency: group?.saving_frequency,
      saving_time: group?.saving_time,
    });

  return (
    <Form onSubmit={handleSubmit}>
      {!loading && error && (
        <Message message={error.message} color="red" />
      )}
      {isCycleExpired(group?.cycle_end_date) && (
        <Header as="h5" color="red">
          Update Cycle details First.
        </Header>
      )}
      <Form.Group widths="equal">
        <Form.Select
          fluid
          label="Frequency of Contribution"
          options={toDropDownOptions(contributionFrequency)}
          placeholder="Frequency of Contribution"
          name="saving_frequency"
          onChange={handleChange}
          disabled={isDisabled('active')}
          defaultValue={group.saving_frequency}
        />
        {form.saving_frequency === 'daily' ? (
          <Form.Input
            fluid
            label="Saving Date"
            name="saving_time"
            type="time"
            placeholder="Cycle Start Date"
            onChange={handleChange}
            disabled={isDisabled('active')}
            defaultValue={group.saving_time}
          />
        ) : (
          <Form.Select
            fluid
            label="Day of Saving"
            options={toDropDownOptions(dayOfSaving)}
            placeholder="Day of Saving"
            onChange={handleChange}
            disabled={isDisabled('active')}
            name="saving_time"
            defaultValue={group.saving_time}
          />
        )}
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label
            className={`${
              isDisabled('active') ? 'disabled-label' : ''
            }`}
          >
            Minimum Share Value
          </label>
          <Input
            label={{ basic: true, content: 'Rwf' }}
            placeholder="Minimum Share Value"
            labelPosition="left"
            name="share_value_min"
            disabled={isDisabled('active')}
            defaultValue={group.share_value_min}
            onChange={handleChange}
            type="number"
          />
          {minimumShareValueError && (
            <Label basic color="red">
              The minimum share value should not be less than 500
            </Label>
          )}
        </Form.Field>
        <Form.Input
          placeholder="Maximum Shares"
          name="maximum_shares"
          label="Maximum Shares"
          disabled={isDisabled('active')}
          defaultValue={group.maximum_shares}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          label="Monthly Interest Rate (%)"
          placeholder="Monthly Interest Rate (%)"
          control={Input}
          disabled={isDisabled('active')}
          name="interest_rate"
          defaultValue={group.interest_rate}
          onChange={handleChange}
        />
        <Form.Field>
          <label className="disabled-label">
            Maximum Shares Value
          </label>
          <Input
            label={{ basic: true, content: 'Rwf' }}
            placeholder="Maximum Shares Value"
            labelPosition="left"
            name="share_value_max"
            disabled
            value={form?.share_value_max || group?.share_value_max}
            defaultValue={group.interest_rate}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label
          className={`${
            isDisabled('active') ? 'disabled-label' : ''
          }`}
        >
          Social Fund Contribution
        </label>
        <Input
          label={{ basic: true, content: 'Rwf' }}
          placeholder="Social Fund Contribution"
          labelPosition="left"
          name="social_fund"
          disabled={isDisabled('active')}
          defaultValue={group.social_fund}
          onChange={handleChange}
        />
        {socialFundError && (
          <Label basic color="red">
            The Social Fund Contribution should not be less than 500
          </Label>
        )}
      </Form.Field>
      <div className="action">
        <Button
          type="submit"
          primary
          loading={loading}
          disabled={minimumShareValueError || socialFundError}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FinancialDetails;
