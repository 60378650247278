import {
  LIST_BULK_SMS_ERROR,
  LIST_BULK_SMS_START,
  LIST_BULK_SMS_SUCCESS,
} from 'constants/action-types/organizations/bulkSmsList';
import notification from 'helpers/notificationHelper';
import axios from 'helpers/axiosHelper';

export default async dispatch => {
  try {
    dispatch({
      type: LIST_BULK_SMS_START,
    });
    const {
      data: { data: payload },
    } = await axios().request({
      method: 'get',
      url: 'sms',
    });
    return dispatch({
      type: LIST_BULK_SMS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error.response) {
      const { message: payload } = error.response.data;
      notification({
        message: payload,
        status: 'error',
      });
      dispatch({
        type: LIST_BULK_SMS_ERROR,
        payload,
      });
    }
    return notification({
      message: 'Something went wrong',
      status: 'error',
    });
  }
};
