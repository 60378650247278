import React from 'react';
import PropTypes from 'prop-types';
import isLocation from 'helpers/isLocation';
import capitalize from 'utils/capitalize';
import './ActivityItem.scss';

const ActivityPopupDetails = ({
  organization,
  project,
  userType,
  group,
  content,
  sender,
  sentCount,
  successCount,
  failedCount,
}) => {
  return (
    <div className="sms-item-details-popup">
      {!isLocation({ name: 'sms' }) && (
        <>
          <h5 className="header">Message:</h5>
          <p>{content}</p>
          <h5>Sent by</h5>
          <p>
            {sender?.first_name} {sender?.last_name}
          </p>
          <h5>Sent to</h5>
          <p>
            Organization(s):{' '}
            {capitalize(
              organization === null ? 'All' : organization.name,
            ) || 'All'}{' '}
            <br />
            Project(s): {project === null ? 'All' : project.name}{' '}
            <br />
            User Type:{' '}
            {userType === null ? 'All' : capitalize(userType)} <br />
            Group(s): {group === null ? 'All' : group.name}
          </p>
          <h5>Recipients</h5>
          <p>
            Expected recipients: {sentCount} <br />
            Delivered: {successCount ?? 'N/A'} <br />
            Failed: {failedCount ?? 'N/A'} <br />
          </p>
        </>
      )}
      {isLocation({ name: 'sms' }) && (
        <p>
          <b>Message:</b>
          <br /> {content}
        </p>
      )}
    </div>
  );
};

ActivityPopupDetails.defaultProps = {
  organization: null,
  project: null,
  group: null,
  userType: null,
  content: '',
  sender: null,
  sentCount: 0,
  successCount: undefined,
  failedCount: undefined,
};

ActivityPopupDetails.propTypes = {
  organization: null,
  project: PropTypes.object,
  group: PropTypes.object,
  userType: PropTypes.string,
  content: PropTypes.string,
  sender: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  sentCount: PropTypes.number,
  successCount: PropTypes.number,
  failedCount: PropTypes.number,
};

export default ActivityPopupDetails;
