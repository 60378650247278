import ChangePin from 'containers/Users/ChangePin';

export default {
  exact: true,
  path: '/change-pin',
  component: ChangePin,
  protected: true,
  permissions: ['all'],
  name: 'Change Pin',
};
