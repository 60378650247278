import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'semantic-ui-react';
import PhoneInput from 'components/common/PhoneInput';
import InputNumberFormat from 'components/common/InputNumberFormat';
import { toDropDownOptions } from 'utils/dropdownOptions';
import channelTopupOptions from 'constants/options/channelTopupOptions';
import topupOptions from 'constants/options/topupOptions';

const TopupSaveRwandaForm = ({ onChange, form }) => {
  const handleChange = (e, { name, value }) => {
    onChange(name, value);
  };

  return (
    <div>
      <Form.Field
        required
        name="source"
        control={Select}
        fluid
        options={toDropDownOptions(topupOptions)}
        label="Source"
        placeholder="Source"
        onChange={handleChange}
      />
      {form.source === 'phone_number' && (
        <PhoneInput
          required
          name="phone_number"
          label="Phone"
          onChange={handleChange}
        />
      )}
      {form.source === 'save_pay' && (
        <Form.Field
          required
          name="channel"
          control={Select}
          fluid
          options={toDropDownOptions(channelTopupOptions, true)}
          label="Channel"
          placeholder="Channel"
          onChange={handleChange}
        />
      )}
      <InputNumberFormat
        required
        name="amount"
        control={Input}
        label="Amount"
        type="number"
        placeholder="Amount..."
        onChange={handleChange}
      />

      <Form.TextArea
        required
        label="Description"
        autoComplete="off"
        placeholder="Description"
        name="description"
        className="textarea"
        onChange={handleChange}
      />
    </div>
  );
};

TopupSaveRwandaForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default TopupSaveRwandaForm;
