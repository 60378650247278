/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Form, Input, Message, Divider } from 'semantic-ui-react';
import Toolbar from 'components/common/Toolbar';
import { userTypes } from 'utils/dropdownOptions';
import './index.scss';

const AddOrganizationUserUI = ({
  isCreating,
  phone,
  error,
  onSubmit,
  setPhone,
  setUserType,
  phoneError,
}) => {
  return (
    <>
      <Toolbar header={{ title: 'Add Organization User' }} />
      <div className="scroll-wrapper">
        <Form onSubmit={onSubmit} loading={isCreating}>
          <Form.Field required error={phoneError !== ''}>
            <label>Phone</label>
            <Input
              label="250"
              name="phone"
              type="phone"
              placeholder="780000000"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
          </Form.Field>
          <Form.Dropdown
            placeholder="Type"
            fluid
            label="Type"
            selection
            options={userTypes}
            name="type"
            defaultValue="User"
            onChange={(e, option) => setUserType(option.value)}
          />

          <Message
            error
            data-cy="phoneErrorOutput"
            visible={phoneError !== ''}
            content={!phoneError !== '' ? phoneError : null}
          />

          <Message
            error
            name="errorOutput"
            visible={error.errors || error.message !== undefined}
            content={
              error.errors && Array.isArray(error.errors)
                ? error.errors[0].message.replace(/"/g, '')
                : error.message
            }
          />

          <Divider />
          <Form.Button
            primary
            name="addUserButton"
            floated="right"
            content={isCreating ? 'Please wait' : 'Add User'}
            labelPosition="right"
            icon="check"
            disabled={isCreating}
          />
        </Form>
      </div>
    </>
  );
};

AddOrganizationUserUI.defaultProps = {
  phone: '',
};

AddOrganizationUserUI.propTypes = {
  phone: PropTypes.string,
  isCreating: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  phoneError: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  setUserType: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      status: PropTypes.number,
      message: PropTypes.string,
    }),
    PropTypes.shape({
      status: PropTypes.number,
      message: PropTypes.string,
      errors: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
      ]),
    }),
  ]).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
export default AddOrganizationUserUI;
