import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AllProjects from './AllProjects';
import SingleProject from './SingleProject';

const OrganizationProjects = ({ match }) => {
  const checkComponentToRender = match => {
    let component = null;
    const { params } = match;
    const { tab, ...restParams } = params;

    Object.keys(restParams || {}).forEach(
      key => restParams[key] || delete restParams[key],
    );

    const { organizationId, tabOption, option1 } = restParams;
    const paramsLength = Object.keys(restParams).length;

    if (paramsLength === 1) {
      component = <AllProjects organizationId={organizationId} />;
    } else if (
      (paramsLength === 2 || paramsLength === 3) &&
      organizationId &&
      tabOption
    ) {
      component = (
        <SingleProject
          organizationId={organizationId}
          projectId={tabOption}
          option1={option1}
        />
      );
    }

    return component || <Redirect to="/" />;
  };

  return checkComponentToRender(match);
};
OrganizationProjects.defaultProps = {
  params: {},
};

OrganizationProjects.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
  params: PropTypes.instanceOf(Object),
};

export default OrganizationProjects;
