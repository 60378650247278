import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Popup, Dropdown, Image } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import SupportIcon from 'assets/images/support.svg';
import WalletIcon from 'assets/images/wallet_icon.svg';
import DashIcon from 'assets/images/dashboard_icon.svg';
import communicationsIcon from 'assets/images/communication_icon.svg';
import AllUsers from 'assets/images/allUsers.svg';
import AllGroups from 'assets/images/allGroups.svg';
import Settings from 'assets/images/settings.svg';
import './SideBar.scss';
import { canAccess } from 'helpers/permissions';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';
import getCurrentOrg from 'helpers/getCurrentOrg';
import abName from '../../utils/abName';

const AppSideBar = ({ onLogout }) => {
  const { currentOrgId, orgUsers, roles } = getUserRoles();
  const isCurrentOrgParent = isParentOrganization();
  const currentOrganization = getCurrentOrg();

  const {
    first_name: firstName,
    last_name: lastName,
    organizations,
  } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const organizationName = abName(currentOrganization?.name || '');

  return (
    <div id="sidebar">
      <Popup
        position="right center"
        trigger={
          <Link className="abrev" to="/">
            {organizationName}
          </Link>
        }
        className="popup-content"
        content={currentOrganization?.name}
      />

      <Popup
        position="right center"
        className="popup-content"
        trigger={
          <NavLink
            className="abrev menu-item"
            to="/activities"
            exact
            activeClassName="active"
          >
            <Image src={DashIcon} />
          </NavLink>
        }
        content="Activities"
      />

      {canAccess('allUsers', roles, {
        additionalCondition: isCurrentOrgParent,
      }) && (
        <>
          <Popup
            position="right center"
            className="popup-content"
            trigger={
              <NavLink
                className="abrev menu-item"
                to="/all-users"
                exact
                activeClassName="active"
              >
                <Image src={AllUsers} className="large-icon" />
              </NavLink>
            }
            content="All Users"
          />

          <Popup
            position="right center"
            className="popup-content"
            trigger={
              <NavLink
                className="abrev menu-item"
                to="/all-groups"
                exact
                activeClassName="active"
              >
                <Image src={AllGroups} className="large-icon" />
              </NavLink>
            }
            content="All Saving Groups"
          />
        </>
      )}

      {canAccess('wallet', roles, {
        currentOrgId,
        orgUsers,
        additionalCondition: currentOrganization?.has_wallet,
      }) && (
        <Popup
          position="right center"
          className="popup-content"
          trigger={
            <NavLink
              className="abrev menu-item"
              to="/wallets"
              exact
              activeClassName="active"
            >
              <Image src={WalletIcon} />
            </NavLink>
          }
          content="Wallet"
        />
      )}
      {canAccess('communications', roles, {
        currentOrgId,
        orgUsers,
        additionalCondition: isCurrentOrgParent,
      }) && (
        <Popup
          position="right center"
          className="popup-content"
          trigger={
            <NavLink
              className="abrev menu-item"
              to="/communications/bulk-sms"
              exact
              activeClassName="active"
            >
              <Image src={communicationsIcon} />
            </NavLink>
          }
          content="Communications"
        />
      )}

      <Popup
        position="right center"
        className="popup-content"
        trigger={
          <NavLink
            className="abrev menu-item"
            to="/settings"
            exact
            activeClassName="active"
          >
            <Image src={Settings} />
          </NavLink>
        }
        content="Settings"
      />

      <Popup
        position="right center"
        className="popup-content"
        trigger={
          <NavLink
            className="abrev menu-item"
            to="/support"
            exact
            activeClassName="active"
          >
            <Image src={SupportIcon} />
          </NavLink>
        }
        content="Support"
      />

      <div className="flex-1" />
      <Popup
        position="top left"
        className="popup-content"
        trigger={
          <Dropdown
            id="sidebar-dropdown-menu"
            text={abName(firstName, lastName)}
            pointing="left"
            className="abrev"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="user"
                text="Account"
                as={Link}
                to="/account"
              />
              {organizations?.length > 1 && (
                <Dropdown.Item
                  icon="map signs"
                  text="Switch Org"
                  as={Link}
                  to="/select-organization"
                />
              )}
              <Dropdown.Item
                id="logout-btn"
                icon="lock"
                text="Sign Out"
                as={Link}
                onClick={() => onLogout()}
                to="/login"
              />
            </Dropdown.Menu>
          </Dropdown>
        }
        content={`${firstName}, ${lastName}`}
      />
    </div>
  );
};

AppSideBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default AppSideBar;
