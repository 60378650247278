/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Message, Table } from 'semantic-ui-react';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import getPartners from 'redux/actions/projects/getPartners';
import './index.scss';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import formatNumber from 'helpers/formatNumber';

const ProjectPartners = ({
  history: { push },
  match: {
    params: { organizationId, projectId },
  },
}) => {
  const { items = [], loading, error, meta } = useSelector(
    ({ projects: { projectPartners } }) => projectPartners,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getPartners(organizationId, projectId)(dispatch);
  }, [dispatch, organizationId, projectId]);

  const onQuery = queries => {
    getPartners(organizationId, projectId, queries)(dispatch);
  };

  return (
    <div className="partners scroll-wrapper">
      <Toolbar
        header={{
          title: 'Partners',
          rightComponent: (
            <>
              <ToolbarFooter
                onSearch={onQuery}
                onPaginate={onQuery}
                paginationMeta={meta}
                exportCSVData={items}
                exportCSVFileName="Partners"
              />
              <Button
                primary
                onClick={() =>
                  push(
                    `/projects/${projectId}/organizations/${organizationId}/partners/invite`,
                  )
                }
              >
                Invite Partners
              </Button>
            </>
          ),
        }}
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message negative>{error.message}</Message>
      ) : items.length === 0 ? (
        <Empty message="No partners found" />
      ) : (
        <Table basic="very" size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Partner</Table.HeaderCell>
              <Table.HeaderCell>Agent</Table.HeaderCell>
              <Table.HeaderCell>Saving Groups</Table.HeaderCell>
              <Table.HeaderCell>Members</Table.HeaderCell>
              <Table.HeaderCell>
                Active Saving Groups
              </Table.HeaderCell>
              <Table.HeaderCell>
                Inactive Saving Groups
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {items.map(
              ({
                id: partnerOrganizationId,
                project_id: partnerProjectId,
                partner: {
                  name,
                  agents_count: agentsCount,
                  members_count: membersCount,
                  groups_count: groupsCount,
                  active_groups_count: activeGroups,
                  inactive_groups_count: inactiveGroups,
                },
              }) => (
                <Table.Row
                  key={`${partnerProjectId}-${organizationId}-${partnerOrganizationId}`}
                  onKeyDown={null}
                  role="button"
                  tabIndex="-1"
                  // onClick={() =>
                  //   push(
                  //     `/projects/${partnerProjectId}/organizations/${organizationId}/partners/${partnerOrganizationId}`,
                  //   )
                  // }
                >
                  <Table.Cell>
                    <div className="group-name">
                      <Thumbnail avatar={null} name={name} />
                      <span>{name}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{formatNumber(agentsCount)}</Table.Cell>
                  <Table.Cell>{formatNumber(groupsCount)}</Table.Cell>
                  <Table.Cell>
                    {formatNumber(membersCount)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatNumber(activeGroups)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatNumber(inactiveGroups)}
                  </Table.Cell>
                </Table.Row>
              ),
            )}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

ProjectPartners.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
};

export default ProjectPartners;
