export const institutionNames = {
  telco: ['MTN', 'Airtel'],
  bank: [
    'AB Bank',
    'I&M Bank',
    'Bank of Africa',
    'KCB Bank',
    'Ecobank',
    'Urwego Bank',
    'BPR',
    'Equity Bank',
    'Unguka Bank',
    'Development Bank of Rwanda',
    'Bank of Kigali',
    'Zigama CSS',
    'NCBA Bank',
    'Access Bank',
    'Cogebanque',
    'Guaranty Trust Bank',
  ],
  'umurenge sacco': [
    'Inkingi Microfinance',
    'Amasezerano Community Bank',
    'Vision Fund Rwanda',
    'SAGER GANZA Microfinance',
    'COPEDU',
    'DUTERIMBERE',
    'Letshego',
    'Goshen Finance',
    'Reseau Interdiocesain de Microfinance RIM',
    'Financial Safety',
    'AS Microfinance',
    'CLECAM - EJO HEZA',
    'UMUTANGUHA Finance',
    'AXON TUNGA Microfinance',
    'INKUNGA Finance',
    'UMURIMO Finance',
    'JALI S.C',
    'BRAC Rwanda Microfinance',
    'AMIFA Rwanda',
  ],
};

export const getCurrentInstitutionName = institutionType =>
  institutionNames[institutionType];
