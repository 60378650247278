/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Message,
  Grid,
  Button,
  Dropdown,
  Icon,
  Header,
} from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import getAgent from 'redux/actions/organizations/getAgent';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import AgentLineCharts from 'components/Organizations/SingleOrganization/Agent/LineCharts';
import AgentDetails from 'components/Organizations/SingleOrganization/Agent/AgentDetails';
import AgentGenders from 'components/Organizations/SingleOrganization/Agent/AgentGenders';
import StatCharts from 'components/Organizations/SingleOrganization/Agent/StatCharts';
import './index.scss';
import { isSuperAdmin } from 'helpers/checkRoles';
import capitalize from 'utils/capitalize';
import DropdownFilterYear from 'components/common/DropdownFilterYear';
import TransferGroups from 'components/Organizations/SingleOrganization/Agent/TransferGroups';
import RemoveAgent from 'components/Organizations/SingleOrganization/Agent/RemoveAgent';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions';
import getUserRoles from 'helpers/getUserRoles';

const AgentOverview = () => {
  const {
    organizationId,
    projectId,
    option2,
    tabOption,
    option,
  } = useParams();
  const [year, setYear] = useState();

  const [selectedProjectId, setSelectedProjectId] = useState();
  const [openTransferGroupsModal, setTransferGroupsModal] = useState(
    false,
  );
  const [removeAgentModal, setRemoveAgentModal] = useState(false);

  const { data, loading, error } = useSelector(
    ({ organizations: { getAgent } }) => getAgent,
  );

  const dispatch = useDispatch();

  const {
    agent: {
      id: agentId,
      first_name: firstName = '',
      last_name: lastName = '',
    } = {},
  } = data;

  const agentProjectId = projectId || option2;
  const { push } = useHistory();

  const { roles, currentOrgId, orgUsers } = getUserRoles();

  const { projects = [] } = data;
  const projectOptions = projects.map(({ name, id }) => ({
    text: capitalize(name),
    key: id,
    value: id,
  }));

  const handleProjectChange = (e, { value }) => {
    setSelectedProjectId(value);
    getAgent(organizationId, agentProjectId, tabOption || option, {
      year,
    })(dispatch);
  };

  useEffect(() => {
    getAgent(
      organizationId,
      agentProjectId,
      tabOption || option,
      year === 'all' ? {} : { year },
    )(dispatch);
  }, [dispatch, year]);

  const urlPrefix = isSuperAdmin(roles)
    ? `/organizations/${organizationId}`
    : `/projects/${agentProjectId}/organizations/${organizationId}`;

  return (
    <>
      <Toolbar
        header={{
          breadcrumbParent: 'Agent',
          breadcrumbParentLink: `${urlPrefix}/agents`,
          breadcrumbActive: `${firstName} ${lastName}`,
          rightComponent: !loading && !error && (
            <>
              {isSuperAdmin(roles) && (
                <>
                  <span className="project-list-title">
                    <b>Project(s)</b>
                  </span>
                  <Dropdown
                    fluid
                    selection
                    options={projectOptions}
                    defaultValue={
                      selectedProjectId &&
                      projectOptions.find(
                        ({ value }) => value === selectedProjectId,
                      ).value
                    }
                    placeholder="- All -"
                    onChange={handleProjectChange}
                    className="agent-projects-list"
                  />
                </>
              )}

              <DropdownFilterYear
                setFilterYear={setYear}
                requestHandledBySelf
              />

              <Button
                primary
                onClick={() =>
                  push(`${urlPrefix}/agents/${agentId}/activities`)
                }
              >
                Recent activities
              </Button>
              <Dropdown
                className="setting-dropdown"
                icon={false}
                trigger={
                  <Button icon primary labelPosition="right">
                    <span>
                      <Icon name="setting" />
                    </span>
                    Settings
                    <Icon name="triangle down" />
                  </Button>
                }
              >
                <Dropdown.Menu className="right">
                  <Dropdown.Item
                    onClick={() =>
                      push(`${urlPrefix}/agents/${agentId}/groups`)
                    }
                  >
                    <Header as="h5">View Groups</Header>
                  </Dropdown.Item>

                  {canPerformAction(
                    permissions.canPerformAction.canTransferGroups,
                    roles,
                    { currentOrgId, orgUsers },
                  ) && (
                    <Dropdown.Item
                      onClick={() => setTransferGroupsModal(true)}
                    >
                      <Header as="h5">Transfer Saving Groups</Header>
                    </Dropdown.Item>
                  )}

                  {canPerformAction(
                    permissions.canPerformAction.canRemoveAgent,
                    roles,
                    { currentOrgId, orgUsers },
                  ) && (
                    <Dropdown.Item
                      onClick={() => setRemoveAgentModal(true)}
                    >
                      <Header as="h5" color="red">
                        Remove Agent
                      </Header>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              {openTransferGroupsModal && (
                <TransferGroups
                  open={openTransferGroupsModal}
                  onClose={setTransferGroupsModal}
                  organizationId={organizationId}
                  agentId={agentId}
                />
              )}

              <RemoveAgent
                open={removeAgentModal}
                onClose={setRemoveAgentModal}
                organizationId={organizationId}
                agentId={agentId}
              />
            </>
          ),
        }}
      />
      <div className="group-overview scroll-wrapper">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : (
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column width="8">
                <AgentLineCharts data={data} year={year} />
              </Grid.Column>
              <Grid.Column width="5">
                <AgentDetails data={data} year={year} />
              </Grid.Column>
              <Grid.Column width="3">
                <AgentGenders data={data} year={year} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={data} year={year} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </>
  );
};

AgentOverview.propTypes = {};

export default AgentOverview;
