import {
  APPROVE_LOAN_REQUESTS_ERROR,
  APPROVE_LOAN_REQUESTS_START,
  APPROVE_LOAN_REQUESTS_SUCCESS,
} from 'constants/action-types/loanRequests/approveLoanRequests';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (organizationId, requestId, data) => (
  dispatch,
  onSuccess,
) =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/microloans/${requestId}/accept`,
      method: 'POST',
      data,
      API_REQUEST_START: APPROVE_LOAN_REQUESTS_START,
      API_REQUEST_SUCCESS: APPROVE_LOAN_REQUESTS_SUCCESS,
      API_REQUEST_FAILURE: APPROVE_LOAN_REQUESTS_ERROR,
      callbackOnSuccess: ({ message }) => {
        onSuccess();
        notification({ message });
      },
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
