export const UPDATE_SINGLE_ORGANIZATION_START =
  'UPDATE_SINGLE_ORGANIZATION_START';
export const UPDATE_SINGLE_ORGANIZATION_END =
  'UPDATE_SINGLE_ORGANIZATION_END';
export const UPDATE_SINGLE_ORGANIZATION_SUCCESS =
  'UPDATE_SINGLE_ORGANIZATION_SUCCESS';
export const UPDATE_SINGLE_ORGANIZATION_FAILURE =
  'UPDATE_SINGLE_ORGANIZATION_FAILURE';
export const UPDATE_SINGLE_ORGANIZATION_CLEAR =
  'UPDATE_SINGLE_ORGANIZATION_CLEAR';
