export default {
  saving_group: 'Saving Groups Balance',
  save_float: 'Save Rwanda Float',
  micro_loan_insurance: 'Micro loan insurance',
  org_float: 'Float',
  fees: 'Fees',
  float_totals: 'Float totals',
  interest_on_deposit: 'Interest on deposits (6%)',
  subscription_and_transaction_fees:
    'Subscriptions and transaction fees',
  groups_transaction_charges: 'Group transaction charges',
  groups_subscription: 'Group subscriptions',
  users_subscription: 'Users subscriptions',
  users_transactions_charge: 'Users transaction charges',
  deposit_sg: 'Interest for SGs',
  deposit_save: 'Interest for Save',
  due_commissions: 'Due Commissions',
};
