import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SingleUser from 'components/Users/SingleUser';
import getSingleUser from 'redux/actions/user/getSingleUser';

const SingleUserContainer = ({ match, history, account }) => {
  const { userId } = match.params;
  const dispatch = useDispatch();

  const {
    userDetails,
    loading: getUserLoading,
    error: getUserError,
  } = useSelector(({ user: { getSingleUser } }) => getSingleUser);

  const { details } = useSelector(
    ({ user: { currentUser } }) => currentUser,
  );

  const id = account ? details.id : userId;

  useEffect(() => {
    if (id) {
      getSingleUser(id, account)(dispatch);
    }
  }, [dispatch, id]);

  return (
    <SingleUser
      user={account ? details : userDetails}
      getUserLoading={getUserLoading}
      getUserError={getUserError}
      history={history}
      account={account}
    />
  );
};

SingleUserContainer.defaultProps = {
  account: false,
};

SingleUserContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  account: PropTypes.bool,
};

export default SingleUserContainer;
