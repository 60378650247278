import {
  UPDATE_BILL_ERROR,
  UPDATE_BILL_START,
  UPDATE_BILL_SUCCESS,
} from 'constants/action-types/settings/updateBill';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_BILL_START:
      return {
        ...state,
        updateBill: {
          loading: true,
          message: null,
          data: [],
        },
      };
    case UPDATE_BILL_ERROR:
      return {
        ...state,
        updateBill: {
          ...state.updateBill,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_BILL_SUCCESS:
      return {
        ...state,
        updateBill: {
          ...state.updateBill,
          data: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
