/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import GroupTransactionsHistory from 'components/Organizations/SingleOrganization/Requests/GroupTransactionsHistory';
import GroupOrgTransactions from 'components/Organizations/SingleOrganization/Requests/GroupOrgTransactions';

const Requests = props => {
  const {
    match: {
      params: { tab, option, projectId },
    },
  } = props;

  return (
    <>
      {projectId && !option && <GroupOrgTransactions {...props} />}
      {projectId && tab === 'requests' && option && (
        <GroupTransactionsHistory {...props} />
      )}
    </>
  );
};

Requests.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
};

export default Requests;
