import CreateProject from 'containers/Projects/CreateProject';

export default {
  exact: true,
  name: 'Create Project',
  protected: true,
  path: '/create-project',
  component: CreateProject,
  permissions: ['all'],
};
