/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Message,
  Modal,
  Radio,
} from 'semantic-ui-react';
import { omit } from 'lodash';
import moment from 'moment';
import { toDropDownOptions } from 'utils/dropdownOptions';
import Loader from 'components/common/PageLoader';
import PINInput from 'components/common/PINInput';
import './index.scss';
import Toolbar from 'components/common/Toolbar';
import updateFeature from 'redux/actions/settings/updateFeature';
import deactivateFeature from 'redux/actions/settings/deactivateFeature';
import getSingleFeature from 'redux/actions/settings/getSingleFeature';
import activeFrequency from 'constants/activeFrequency';
import platformOptions from 'constants/platformOptions';
import requestMethods from 'constants/requestMethods';
import { monthsPicker, weekDaysPicker } from 'constants/timePicker';
import featureTypeOptions from 'constants/featureTypeOptions';

const EditFeature = props => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({ context: 'feature' });
  const [fieldError, setFieldError] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [hasPayload, setHasPayload] = useState(false);
  const [payload, setPayload] = useState([
    {
      id: 1,
      key: '',
      value: '',
    },
  ]);

  const {
    match: {
      params: { option1: featureId },
    },
  } = props;

  const {
    data: feature,
    loading: loadingFeature,
    error: errorFeature,
  } = useSelector(({ settings: { singleFeature } }) => singleFeature);

  useEffect(() => {
    getSingleFeature(featureId)(dispatch);
  }, []);

  const { loading, error } = useSelector(
    ({ settings: { updateFeature } }) => updateFeature,
  );

  const { loading: loadingStatus, error: errorStatus } = useSelector(
    ({ settings: { deactivateFeature } }) => deactivateFeature,
  );

  const defaultValues = {
    active_frequency:
      form?.active_frequency || feature?.active_frequency,
    active_time: form?.active_time || feature?.active_time,
  };

  const defaultPayload = useMemo(
    () =>
      Object.keys(feature?.payload || {}).map((key, index) => ({
        id: index + 1,
        key,
        value: feature?.payload?.[key],
      })),
    [feature?.payload],
  );

  useEffect(() => {
    setPayload(defaultPayload);
    if (defaultPayload.length > 0) {
      setHasPayload(true);
    }
  }, [defaultPayload]);

  const handleChange = (e, { name, value }) => {
    if (name === 'allowed_date') {
      value = moment(value).format('D');
      return setForm({ ...form, active_time: value, [name]: value });
    }
    if (name === 'allowed_time')
      return setForm({ ...form, active_time: value, [name]: value });
    setForm({
      ...form,
      [name]: value,
      context: 'feature',
    });
  };

  const changeStatus = status => {
    setForm({
      ...form,
      status,
      context: 'status',
    });
    setPinModal(true);
  };

  const openModal = () => {
    setForm({
      ...omit(form, 'status'),
      context: 'feature',
    });
    setPinModal(true);
  };

  const handlePassword = (e, { name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = context => {
    const featureId = feature?.id;
    switch (context) {
      case 'feature': {
        const platforms = form?.platforms
          ? form?.platforms
          : feature?.platform?.map(item => item.platform_name);

        const payloadObject = payload.reduce((acc, curr) => {
          if (curr.key && curr.value) {
            acc[curr.key] = curr.value;
          }
          return acc;
        }, {});

        const data = {
          ...omit(form, 'context'),
          payload: hasPayload ? payloadObject : undefined,
          platforms,
        };

        return updateFeature(featureId, data)(dispatch, setPinModal);
      }
      case 'status': {
        const action = form?.status ? 'activate' : 'deactivate';
        const platform = form?.platforms
          ? form?.platforms
          : feature?.platforms?.map(item => item.platform_name);
        return deactivateFeature(
          featureId,
          action,
          form?.password,
          platform,
        )(dispatch, setPinModal);
      }
      default:
        break;
    }
  };

  const addPayloadItem = () => {
    setPayload([
      ...payload,
      {
        id: payload.length + 1,
        key: '',
        value: '',
      },
    ]);
  };

  const removePayloadItem = id => {
    setPayload(payload.filter(item => item.id !== id));
  };

  return (
    <div className="setting__add-feature">
      <Toolbar
        header={{
          title: 'Edit Feature Setting',
          navigation: true,
        }}
      />
      <Modal
        size="mini"
        open={pinModal}
        onClose={() => setPinModal(false)}
      >
        <Modal.Header>
          Confirm Your Pin
          <Icon
            name="close"
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={() => handleSubmit(form?.context)}
            loading={loadingStatus || loading}
          >
            <PINInput
              className="input"
              fluid
              size="tiny"
              label="Enter Pin"
              name="password"
              onChange={handlePassword}
            />

            {(errorStatus || error) &&
              (errorStatus === 'Wrong PIN.' ||
                error === 'Wrong PIN.') && (
                <p color="red">
                  <Message negative>{errorStatus || error}</Message>
                </p>
              )}

            <Button type="submit" color="blue" fluid size="tiny">
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
      {loadingFeature ? (
        <Loader />
      ) : errorFeature ? (
        <Message negative>{error?.message}</Message>
      ) : (
        <Form>
          <Form.Input
            placeholder="Name"
            label="Feature Name"
            defaultValue={feature?.feature_name}
            fluid
            required
            name="feature_name"
            onChange={handleChange}
          />
          <Form.Dropdown
            placeholder="Select"
            fluid
            label="Accessibility level"
            selection
            defaultValue={feature?.feature_type}
            options={toDropDownOptions(featureTypeOptions)}
            name="feature_type"
            onChange={handleChange}
          />
          <Form.Select
            placeholder="Platform"
            fluid
            required
            multiple
            label="Platform(s)"
            defaultValue={
              feature?.platforms?.length > 0
                ? feature?.platforms?.map(
                    plateform => plateform.platform_name,
                  )
                : null
            }
            options={toDropDownOptions(platformOptions)}
            name="platforms"
            onChange={handleChange}
          />
          <Form.Select
            placeholder="Daily"
            fluid
            required
            label="Frequency"
            defaultValue={feature?.active_frequency}
            options={toDropDownOptions(activeFrequency)}
            name="active_frequency"
            onChange={handleChange}
          />
          {defaultValues?.active_frequency === 'daily' ? (
            <Form.Input
              placeholder="Select time"
              fluid
              label="Time"
              defaultValue={feature?.allowed_time}
              type="time"
              name="allowed_time"
              onChange={handleChange}
            />
          ) : defaultValues?.active_frequency === 'weekly' ? (
            <Form.Select
              placeholder="Select Day"
              fluid
              label="Day of a week"
              defaultValue={defaultValues?.active_time}
              options={toDropDownOptions(weekDaysPicker)}
              name="active_time"
              onChange={handleChange}
            />
          ) : defaultValues?.active_frequency === 'annually' ? (
            <Form.Select
              placeholder="Select Month"
              fluid
              label="Month of a year"
              defaultValue={defaultValues?.active_time}
              options={toDropDownOptions(monthsPicker)}
              name="active_time"
              onChange={handleChange}
            />
          ) : (
            <Form.Input
              placeholder="Select Date"
              fluid
              label="Date of a month"
              defaultValue={moment(feature?.allowed_date).format(
                'YYYY-MM-DD',
              )}
              name="allowed_date"
              type="date"
              onChange={handleChange}
            />
          )}
          <Form.Input
            placeholder="Url"
            fluid
            label="Endpoint Url (optional)"
            defaultValue={feature?.endpoint}
            name="endpoint"
            onChange={handleChange}
          />
          <Form.Select
            placeholder="Method"
            fluid
            label="Endpoint Method (optional)"
            defaultValue={feature?.request_method}
            options={toDropDownOptions(requestMethods, true)}
            name="request_method"
            onChange={handleChange}
          />
          <Form.Field className="flex ">
            <label className="payload-label">Has payload</label>
            <Radio
              toggle
              onChange={() => {
                if (hasPayload) {
                  setPayload([
                    {
                      id: 1,
                      key: '',
                      value: '',
                    },
                  ]);
                }
                setHasPayload(!hasPayload);
              }}
              defaultChecked={!!feature?.payload}
              name="has_payload"
              checked={hasPayload}
            />
          </Form.Field>
          {hasPayload && (
            <>
              {payload.map((item, index) => (
                <Form.Group
                  key={item.id}
                  widths="equal"
                  className="group-items flex-center-vertical"
                >
                  <Form.Input
                    fluid
                    label="Key"
                    name="payload.key"
                    defaultValue={item.key}
                    onChange={(_, { value }) => {
                      setPayload(
                        payload.map(payloadItem => {
                          if (payloadItem.id === item.id) {
                            return {
                              ...payloadItem,
                              key: value,
                            };
                          }
                          return payloadItem;
                        }),
                      );
                    }}
                  />
                  <Form.Input
                    fluid
                    label="Value"
                    name="payload.value"
                    defaultValue={item.value}
                    onChange={(_, { value }) => {
                      setPayload(
                        payload.map(payloadItem => {
                          if (payloadItem.id === item.id) {
                            return {
                              ...payloadItem,
                              value,
                            };
                          }
                          return payloadItem;
                        }),
                      );
                    }}
                  />

                  {index === 0 ? (
                    <Icon
                      name="plus"
                      className="cursor"
                      onClick={() => addPayloadItem()}
                    />
                  ) : (
                    <Icon
                      name="minus"
                      className="cursor"
                      onClick={() => removePayloadItem(item.id)}
                    />
                  )}
                </Form.Group>
              ))}
            </>
          )}
          <Form.Field>
            <label>Status</label>
            <Checkbox
              toggle
              defaultChecked={feature?.status === 'active'}
              onChange={(_, { checked }) => changeStatus(checked)}
            />
          </Form.Field>
          <Form.Button
            primary
            name="updateButton"
            floated="right"
            content="Update"
            onClick={openModal}
          />
        </Form>
      )}
    </div>
  );
};

export default EditFeature;
