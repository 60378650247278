import {
  GET_ORGANIZATION_MEMBERS_ERROR,
  GET_ORGANIZATION_MEMBERS_START,
  GET_ORGANIZATION_MEMBERS_SUCCESS,
} from 'constants/action-types/organizations/getMembers';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ORGANIZATION_MEMBERS_START:
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loading: true,
          error: null,
        },
      };
    case GET_ORGANIZATION_MEMBERS_SUCCESS:
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loading: false,
          items: payload.data,
        },
      };
    case GET_ORGANIZATION_MEMBERS_ERROR:
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loading: false,
          error: payload,
        },
      };
    default:
      return null;
  }
};
