/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import organizationMembers from 'redux/actions/organizations/organizationMembers';

const SearchMember = ({
  name,
  handleChange,
  error,
  disabled,
  groupId,
  label,
  defaultValue,
}) => {
  const { items = [], loading } = useSelector(
    ({ organizations: { organizationMembers } }) =>
      organizationMembers,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId) {
      organizationMembers(groupId)(dispatch);
    }
  }, []);

  const options = items.map(({ user, user_id: userId }) => ({
    text: `${user?.first_name} ${user?.last_name}`,
    value: `${user?.first_name} ${user?.last_name}`,
    key: userId,
  }));

  return (
    <Form.Field
      control={Dropdown}
      search
      selection
      error={error}
      loading={loading}
      onChange={handleChange}
      options={options}
      type="text"
      label={label}
      name={name}
      defaultValue={defaultValue}
      placeholder="-All-"
      disabled={disabled}
    />
  );
};

SearchMember.defaultProps = {
  name: 'member_name',
  disabled: false,
  groupId: null,
  label: 'Member',
};

SearchMember.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  groupId: PropTypes.number,
  label: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
};

export default SearchMember;
