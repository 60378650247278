import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  isOrganizationAdmin,
  isRwandaAdmin,
} from 'helpers/checkRoles';
import './AppNavPanel.scss';
import defaultRoutes from 'constants/defaultRoutes';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';
import getCurrentOrg from 'helpers/getCurrentOrg';

function AppNavPanel({ routes, settings }) {
  const {
    currentOrgId,
    orgUsers,
    roles: currentUserRoles,
  } = getUserRoles();
  const isCurrentOrgParent = isParentOrganization();
  const currentOrganization = getCurrentOrg();

  const getNavigationRoutes = () => {
    if (settings) {
      return routes;
    }
    const checkAdditionalCondition = routePermissions => {
      if (
        routePermissions?.every(
          routePermission =>
            !['org_user', 'org_admin']?.includes(routePermission),
        )
      )
        return isCurrentOrgParent;
      return true;
    };

    return defaultRoutes.filter(defaultRoute => {
      return defaultRoute.permissions.some(permission => {
        if (permission === 'org_admin')
          return (
            isOrganizationAdmin({ currentOrgId, orgUsers }) &&
            checkAdditionalCondition(defaultRoute.permissions)
          );
        if (permission === 'save_rwanda_admin')
          return (
            isRwandaAdmin({
              roles: currentUserRoles,
              currentOrgId,
              orgUsers,
            }) && checkAdditionalCondition(defaultRoute.permissions)
          );
        if (permission === 'wallet_org_admin')
          return (
            currentOrganization?.has_wallet &&
            isOrganizationAdmin({ currentOrgId, orgUsers })
          );

        return currentUserRoles.some(
          userRole =>
            userRole.name === permission &&
            checkAdditionalCondition(defaultRoute.permissions),
        );
      });
    });
  };

  const routesToDisplay = getNavigationRoutes();

  return (
    <div className="sidebar">
      <div className="header">
        <span className="title">Dashboard</span>
      </div>
      {routesToDisplay.map(({ name, path }) => (
        <NavLink
          key={`${name}-${path}`}
          to={path}
          exact
          activeClassName="active"
        >
          {name}
        </NavLink>
      ))}
    </div>
  );
}

AppNavPanel.defaultProps = {
  routes: defaultRoutes,
  settings: false,
};

AppNavPanel.propTypes = {
  routes: PropTypes.arrayOf(Object),
  settings: PropTypes.bool,
};

export default AppNavPanel;
