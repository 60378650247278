export const SSA = 'ssa';
export const SA = 'sa';
export const ORG_ADMIN = 'org_admin';
export const ORG_USER = 'org_user';
export const RWANDA_ADMIN = 'sa_admin';
export const SUPPORT_ADMIN = 'sa_support_admin';
export const FINANCE_ADMIN = 'sa_finance_admin';
export const TECHNICAL_LEAD = 'sa_tech_admin';
export const COMMUNICATIONS_ADMIN = 'sa_communications_admin';

export default ['ssa', 'sa', 'org_admin', 'org_user'];
