import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Location from 'components/common/InterventionArea/Location';
import updateGroup from 'redux/actions/organizations/updateGroup';
import Message from 'components/common/Message';

const GroupDetails = () => {
  const [form, setForm] = useState();
  const [location, setLocation] = useState({});
  const dispatch = useDispatch();
  const { group } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup?.item,
  );

  const { loading, error } = useSelector(
    ({ organizations: { updateGroup } }) => updateGroup,
  );

  const handleChange = (e, data) => {
    if (data.name === 'location') setForm({ ...form, ...data.value });
    setForm({ ...form, [data.name]: data.value });
  };

  useEffect(() => {
    if (!isEmpty(group)) {
      const { province, district, sector, cell, village } = group;
      setLocation({ province, district, sector, cell, village });
    }
  }, []);

  const handleSubmit = () => {
    updateGroup(group?.id, form)(dispatch);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {!loading && error && (
        <Message message={error.message} color="red" />
      )}
      <Form.Field
        control={Input}
        label="Group Name"
        placeholder="Group Name"
        onChange={handleChange}
        name="name"
        defaultValue={group?.name}
      />
      {['graduated', 'supervised'].includes(group?.type) && (
        <>
          <Form.Input
            fluid
            label="Start Date"
            name="started_at"
            type="date"
            placeholder="Cycle Start Date"
            onChange={handleChange}
            defaultValue={moment(group?.started_at).format(
              'YYYY-MM-DD',
            )}
          />
          <Location onChange={handleChange} defaultValue={location} />
        </>
      )}
      <div className="action">
        <Button type="submit" primary loading={loading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default GroupDetails;
