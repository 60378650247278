import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toDropDownOptions } from 'utils/dropdownOptions';
import { getCurrentInstitutionName } from 'constants/finacialInstitution/institutionNames';
import institutionTypes from 'constants/finacialInstitution/institutionTypes';
import updateGroup from 'redux/actions/organizations/updateGroup';
import Message from 'components/common/Message';

const FinancialInstitutionDetails = () => {
  const [form, setForm] = useState();
  const dispatch = useDispatch();

  const { group } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup?.item,
  );

  const { loading, error } = useSelector(
    ({ organizations: { updateGroup } }) => updateGroup,
  );

  const handleSubmit = () => {
    updateGroup(group?.id, form)(dispatch);
  };

  const handleChange = (_, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const accountNoLabel =
    (form?.fi_type || group?.fi_type) === 'telco'
      ? 'Phone Number'
      : 'Account No';

  return (
    <Form onSubmit={handleSubmit}>
      {!loading && error && (
        <Message message={error.message} color="red" />
      )}
      <Form.Select
        fluid
        name="fi_type"
        defaultValue={group?.fi_type}
        label="Type of Financial Institution"
        options={toDropDownOptions(institutionTypes)}
        placeholder="Type of Financial Institution"
        onChange={handleChange}
      />
      {['usacco', 'nusacco'].includes(
        form?.fi_type || group?.fi_type,
      ) ? (
        <Form.Input
          fluid
          label="Name of financial institution"
          name="fi_name"
          placeholder="Name of financial institution"
          defaultValue={group?.fi_name}
          onChange={handleChange}
        />
      ) : (
        <Form.Select
          fluid
          name="fi_name"
          defaultValue={group?.fi_name}
          label="Name of financial institution"
          options={toDropDownOptions(
            getCurrentInstitutionName(
              form?.fi_type || group?.fi_type,
            ),
          )}
          placeholder="Name of financial institution"
          onChange={handleChange}
          search
          clearable
        />
      )}
      <Form.Input
        fluid
        name="fi_account_number"
        defaultValue={group?.fi_account_number}
        label={accountNoLabel}
        placeholder={accountNoLabel}
        onChange={handleChange}
      />
      <div className="action">
        <Button type="submit" primary loading={loading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FinancialInstitutionDetails;
