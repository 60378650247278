/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import './index.scss';
import { Button, Image, Message } from 'semantic-ui-react';
import {
  useLocation,
  useParams,
  NavLink,
  useHistory,
} from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import getProfiles from 'redux/actions/profiles/listProjects';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import formatNumber from 'helpers/formatNumber';
import getCurrentOrg from 'helpers/getCurrentOrg';
import loanRangeIcon from 'assets/images/loan-range.svg';
import peopleProfileIcon from 'assets/images/people-profile.svg';
import Thumbnail from 'components/common/Thumbnail';
import isParentOrganization from 'helpers/isParentOrganization';
import { canAccess } from 'helpers/permissions';

const ListProfiles = ({
  items,
  meta,
  openSingleProfile,
  loading,
}) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const params = useParams();
  const urlParams = new URLSearchParams(search);
  const queryParams = Object.fromEntries(urlParams);
  const [error, setError] = useState('');

  const currentUserOrganization = getCurrentOrg();

  const isCurrentOrgParent = isParentOrganization();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      pathname === '/profiles' &&
      !canAccess('profiles', null, {
        currentOrg: currentUserOrganization,
      })
    ) {
      setError('You are not authorized to view this page');
    } else if (
      pathname ===
        `organizations/${params?.organizationId}/profiles` &&
      !isCurrentOrgParent
    ) {
      setError('You are not authorized to view this page');
    }
  }, [currentUserOrganization, isCurrentOrgParent]);

  useEffect(() => {
    getProfiles(
      params?.organizationId || currentUserOrganization.id,
      queryParams,
    )(dispatch);
  }, [dispatch]);

  const onQuery = queries =>
    currentUserOrganization &&
    getProfiles(currentUserOrganization.id, queries)(dispatch);

  return (
    <>
      <section className="list-profiles not-all">
        <Toolbar
          bac
          header={{
            title: `Profiles`,
            rightComponent: (
              <div className="header_right_component">
                <ToolbarFooter
                  onSearch={onQuery}
                  onPaginate={onQuery}
                  paginationMeta={meta}
                  exportCSVData={items}
                  exportCSVFileName="Loan Profiles"
                />
                <Button
                  as={NavLink}
                  to="/profiles/new"
                  activeClassName="disabled"
                  exact
                  primary
                  content="New Profile"
                  labelPosition="right"
                  icon="add"
                />
              </div>
            ),
          }}
          ignoreHelmet={false}
        />
        <div className="list-profiles__items scroll-wrapper">
          {loading ? (
            <Loader />
          ) : error ? (
            <Message negative>{error}</Message>
          ) : isEmpty(items) ? (
            <Empty message="No profiles" />
          ) : (
            sortBy(items, ['profile_type']).map(item => (
              <div
                className={`list-profiles__items__item ${item.profile_type ===
                  'default' &&
                  'list-profiles__items__default-profile'}`}
                key={item?.id}
                tabIndex="-1"
                onKeyDown={null}
                role="button"
                onClick={() => {
                  if (pathname === '/profiles') {
                    history.push(`/profiles/${item.id}`);
                  } else openSingleProfile(item.id);
                }}
              >
                <Thumbnail name={item?.name} />

                <div className="list-profiles__items__item__details">
                  <div className="list-profiles__items__item__details__info">
                    <div className="list-profiles__items__item__details__info__name">
                      {item?.name}
                    </div>
                    <div className="list-profiles__items__item__details__info__numbers">
                      <div className="number-group">
                        <Image
                          className="number-group__icon"
                          width={20}
                          src={loanRangeIcon}
                        />

                        <span>
                          <span>Monthly saving range: </span>
                          {item.profile_type === 'default'
                            ? 'N/A'
                            : `${formatNumber(
                                item?.min_monthly_savings,
                              )} Rwf - ${formatNumber(
                                item?.max_monthly_savings,
                              )} Rwf`}
                        </span>
                      </div>
                      <div className="number-group">
                        <Image
                          className="number-group__icon"
                          width={20}
                          src={loanRangeIcon}
                        />
                        <span>
                          <span>Monthly loans range: </span>{' '}
                          {item.profile_type === 'default'
                            ? 'N/A'
                            : `${formatNumber(
                                item?.min_monthly_loans,
                              )} Rwf - ${formatNumber(
                                item?.max_monthly_loans,
                              )} Rwf`}
                        </span>
                      </div>
                      <div className="number-group">
                        <Image
                          className="number-group__icon"
                          width={20}
                          src={peopleProfileIcon}
                        />
                        <span>
                          <span>Eligible groups: </span>
                          {formatNumber(item?.groups_count || 0)}
                        </span>
                      </div>
                      <div className="number-group">
                        <Image
                          className="number-group__icon"
                          width={20}
                          src={peopleProfileIcon}
                        />
                        <span>
                          <span>Members: </span>{' '}
                          {formatNumber(
                            item?.groups_members_count || 0,
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
};

ListProfiles.defaultProps = {
  meta: {},
};

ListProfiles.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  openSingleProfile: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.objectOf(Object),
};

export default ListProfiles;
