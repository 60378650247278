import React from 'react';
import PropTypes from 'prop-types';
import './settings.scss';
import { useHistory } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import OrganizationProfile from './OrganizationProfile';
import FeatureSettings from './FeatureSettings';
import Billing from './Billing';
import AddBill from './Billing/AddBill';
import EditBill from './Billing/EditBill';
import MicroLoans from './MicroLoans';
import Commission from './Commission';
import EditCommission from './Commission/EditCommission';

const panes = props => [
  {
    menuItem: 'Organization',
    render: () => <OrganizationProfile props={props} />,
  },
  {
    menuItem: 'Features',
    render: () => <FeatureSettings props={props} />,
  },
  {
    menuItem: 'Billing',
    render: () => <Billing props={props} />,
  },
  {
    menuItem: 'Commission',
    render: () => <Commission props={props} />,
  },
  {
    menuItem: 'Micro Loans',
    render: () => <MicroLoans props={props} />,
  },
];

const Settings = ({ location, history, match }) => {
  const { push } = useHistory();

  const tabs = {
    organization: { index: 0, name: 'organization' },
    features: { index: 1, name: 'features' },
    billing: { index: 2, name: 'billing' },
    commission: { index: 3, name: 'commission' },
    microloans: { index: 4, name: 'microloans' },
  };

  const {
    params: { tab, option, action },
  } = match;

  const activeIndex = !tabs[tab] ? 0 : tabs[tab].index;

  const handleTabChange = (e, { activeIndex }) => {
    const key = Object.keys(tabs).find(
      key => tabs[key].index === activeIndex,
    );
    push(`/settings/${key}`);
  };

  return (
    <>
      {(!option || option === 'request') && (
        <div className="settings-tab-items">
          <Tab
            activeIndex={activeIndex}
            menu={{ secondary: true, pointing: true }}
            panes={panes({ location, history, match })}
            onTabChange={handleTabChange}
          />
        </div>
      )}
      {tab === 'billing' && option === 'add' && <AddBill />}
      {tab === 'billing' && action === 'edit' && (
        <EditBill billId={option} />
      )}
      {tab === 'commission' && option === 'edit' && (
        <EditCommission />
      )}
    </>
  );
};

Settings.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default Settings;
