import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import Proptype from 'prop-types';
import Message from 'components/common/Message';
import { toDropDownOptions } from 'utils/dropdownOptions';
import groupTypeOptions from 'constants/options/groupTypeOptions';
import getMicroloanSettingData from 'redux/actions/settings/getMicroloansSettingData';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import labelFormatter from 'helpers/labelFormatter';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';

const walletTypes = ['group_wallet', 'individual_wallet'];

const BasicDetails = ({ data: basicData }) => {
  const [form, setForm] = useState({});
  const [openPinModal, setOpenPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');
  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettingsData } }) =>
      microloanSettingsData,
  );
  const { loading: updateLoading, error: updateError } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    getMicroloanSettingData({
      section: 'Basic',
      type: basicData.type,
    })(dispatch);
  }, []);

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: userPin,
      type: basicData.type,
      section: 'Basic',
      data: {
        ...data,
        eligible_groups: form.eligible_groups,
      },
    })(dispatch, () => setOpenPinModal(false));
  };

  return (
    <>
      <ConfirmPinModal
        content={`Are you sure you want to update the settings for ${labelFormatter(
          basicData.type,
        )}`}
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        userPin={userPin}
        setUserPin={setUserPin}
        error={{ message: updateError }}
        loading={updateLoading}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
      />
      <Form
        onSubmit={() => setOpenPinModal(!openPinModal)}
        loading={loading}
      >
        {!loading && error && (
          <Message message={error.message} color="red" />
        )}
        <Form.Field
          control={Input}
          disabled
          label="Name"
          placeholder="Name"
          onChange={handleChange}
          name="type"
          value={labelFormatter(basicData.type)}
          defaultValue={labelFormatter(basicData.type)}
        />
        {data && (
          <Form.Group widths="equal">
            <Form.Dropdown
              fluid
              selection
              disabled
              label="Disbursement Wallet Type"
              placeholder="-- Select --"
              name="disbursement"
              options={toDropDownOptions(walletTypes)}
              onChange={handleChange}
              value={basicData?.disbursement}
              defaultValue={basicData?.disbursement}
            />

            <Form.Dropdown
              fluid
              selection
              multiple
              label="Eligible Group Types"
              placeholder="-- Select --"
              name="eligible_groups"
              options={toDropDownOptions(groupTypeOptions)}
              onChange={handleChange}
              defaultValue={data?.eligible_groups}
            />
          </Form.Group>
        )}

        <div className="action">
          <Button
            type="submit"
            primary
            loading={updateLoading}
            disabled={updateLoading || Object.keys(form).length === 0}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

BasicDetails.propTypes = {
  data: Proptype.object.isRequired,
};

export default BasicDetails;
