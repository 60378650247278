/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import formatNumber from 'helpers/formatNumber';

const InputNumberFormat = ({ defaultValue, onChange, ...rest }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (e, data) => {
    if (
      /[a-zA-Z]/.test(data.value) ||
      /[*|":<>[\]{}`\\()';@&$]/.test(data.value)
    ) {
      return;
    }
    const cleanedNumber = Number(data.value.replace(/,/g, ''));

    setValue(cleanedNumber);

    onChange(e, {
      ...data,
      value: cleanedNumber,
    });
  };
  return (
    <Form.Input
      fluid
      className="input-number-format"
      {...rest}
      type="text"
      onChange={handleChange}
      value={
        formatNumber(value) !== '0' ? formatNumber(value) : undefined
      }
    />
  );
};

InputNumberFormat.defaultProps = {
  onChange: () => null,
  defaultValue: '',
  name: '',
};

InputNumberFormat.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
};

export default InputNumberFormat;
