import {
  GET_ORGANIZATION_MEMBERS_ERROR,
  GET_ORGANIZATION_MEMBERS_START,
  GET_ORGANIZATION_MEMBERS_SUCCESS,
} from 'constants/action-types/organizations/getMembers';

import apiAction from 'helpers/apiAction';

export default (id, queries) => dispatch =>
  dispatch(
    apiAction({
      url: `/groups/${id}/members`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_MEMBERS_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_MEMBERS_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_MEMBERS_ERROR,
      onEnd: () => false,
    }),
  );
