/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from 'components/common/Toolbar';

import UsersList from 'components/Users/UsersList';
import { getOrganizationUsers } from 'redux/actions/user/getUsers';

const Users = ({
  push,
  match: {
    params: { organizationId },
  },
}) => {
  const { items = [], meta, loading, error } = useSelector(
    ({ user: { usersList } }) => usersList,
  );

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } }) => details,
  );

  const dispatch = useDispatch();

  const users = () => {
    if (items?.[0]?.user) {
      return items.map(
        ({ user, user_type: userType, ...details }) => ({
          ...user,
          details,
          userType,
        }),
      );
    }
    return items;
  };

  useEffect(() => {
    getOrganizationUsers(organizationId)(dispatch);
  }, [dispatch]);

  const handleGetUsers = page => {
    if (page) {
      getOrganizationUsers(organizationId, page)(dispatch);
      return;
    }
    getOrganizationUsers(organizationId)(dispatch);
  };

  return (
    <div className="organization-users scroll-wrapper no-vertical-padding">
      <Toolbar
        header={{ title: 'Organization Users', navigation: true }}
      />
      <div className="userlist">
        <UsersList
          {...{
            noToolbar: true,
            push,
            meta,
            handleGetUsers,
            dispatch,
            loading,
            error,
            users,
            roles,
          }}
        />
      </div>
    </div>
  );
};

Users.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
  push: PropTypes.func.isRequired,
};

export default Users;
