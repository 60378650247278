import {
  GET_COMMISSIONS_ERROR,
  GET_COMMISSIONS_START,
  GET_COMMISSIONS_SUCCESS,
} from 'constants/action-types/settings/getCommissions';
import apiAction from 'helpers/apiAction';

export default () => dispatch =>
  dispatch(
    apiAction({
      url: `/commissions/settings`,
      method: 'GET',
      API_REQUEST_START: GET_COMMISSIONS_START,
      API_REQUEST_SUCCESS: GET_COMMISSIONS_SUCCESS,
      API_REQUEST_FAILURE: GET_COMMISSIONS_ERROR,
      onEnd: () => false,
    }),
  );
