import {
  DEACTIVATE_USER_LOADING,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAILED,
  CLEAR_DEACTIVATE_USER,
} from 'constants/action-types/user/deactivateUser';
import userState from '../../initial-states/user';

export default (state = userState, { type, payload }) => {
  switch (type) {
    case DEACTIVATE_USER_LOADING:
      return {
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          isDeactivating: true,
          error: {},
        },
      };
    case DEACTIVATE_USER_FAILED:
      return {
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          error: payload,
          isDeactivating: false,
        },
      };

    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          user: payload,
          error: {},
          isDeactivating: false,
        },
        getSingleUser: {
          ...state.getSingleUser,
          userDetails: {
            ...state.getSingleUser.userDetails,
            status: payload.data.status,
          },
          error: null,
        },
      };

    case CLEAR_DEACTIVATE_USER:
      return {
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          user: {},
          error: {},
          isDeactivating: false,
        },
      };
    default:
      return null;
  }
};
