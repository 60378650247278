import {
  GET_TRANSACTIONS_START,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
} from 'constants/action-types/wallets/getTransactions';

import apiAction from 'helpers/apiAction';

export default queries => dispatch =>
  dispatch(
    apiAction({
      url: `/wallets/transactions`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_TRANSACTIONS_START,
      API_REQUEST_SUCCESS: GET_TRANSACTIONS_SUCCESS,
      API_REQUEST_FAILURE: GET_TRANSACTIONS_ERROR,
      onEnd: () => false,
    }),
  );
