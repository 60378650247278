/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Icon,
  Message,
  Modal,
  Radio,
} from 'semantic-ui-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { toDropDownOptions } from 'utils/dropdownOptions';
import PINInput from 'components/common/PINInput';
import './index.scss';
import Toolbar from 'components/common/Toolbar';
import addFeature from 'redux/actions/settings/addFeature';
import activeFrequency from 'constants/activeFrequency';
import platformOptions from 'constants/platformOptions';
import requestMethods from 'constants/requestMethods';
import { monthsPicker, weekDaysPicker } from 'constants/timePicker';
import isFieldRequired from 'helpers/validators/isFieldRequired';
import hasWhiteSpace from 'helpers/validators/hasWhiteSpace';
import featureTypeOptions from 'constants/featureTypeOptions';

const AddFeature = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [form, setForm] = useState({});
  const [status, setStatus] = useState(true);
  const [pinModal, setPinModal] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [hasPayload, setHasPayload] = useState(false);
  const [payload, setPayload] = useState([
    {
      id: 1,
      key: '',
      value: '',
    },
  ]);

  const { loading, error } = useSelector(
    ({ settings: { addFeature } }) => addFeature,
  );

  const handleChange = (e, { name, value }) => {
    if (name === 'allowed_date') {
      value = moment(value).format('D');
      return setForm({ ...form, active_time: value, [name]: value });
    }
    if (name === 'allowed_time')
      return setForm({ ...form, active_time: value, [name]: value });

    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmit = () => {
    const requiredFields = [
      'feature_name',
      'platforms',
      'active_frequency',
      'active_time',
    ];
    const isRequired = requiredFields.every(value =>
      Object.keys(form)?.includes(value),
    );
    if (isRequired && !/\s/g.test(form?.endpoint)) {
      setFieldError(false);
      return setPinModal(true);
    }
    return setFieldError(true);
  };

  const submitAddFeature = () => {
    const platforms = form?.platforms;

    const payloadObject = payload.reduce((acc, curr) => {
      if (curr.key && curr.value) {
        acc[curr.key] = curr.value;
      }
      return acc;
    }, {});

    const data = {
      ...form,
      payload: hasPayload ? payloadObject : undefined,
      status: status ? 'active' : 'inactive',
      platforms,
    };
    addFeature(data)(dispatch, push, setPinModal);
  };

  const addPayloadItem = () => {
    setPayload([
      ...payload,
      {
        id: payload.length + 1,
        key: '',
        value: '',
      },
    ]);
  };

  const removePayloadItem = id => {
    setPayload(payload.filter(item => item.id !== id));
  };

  return (
    <div className="setting__add-feature">
      <Toolbar
        header={{
          title: 'Add Feature Setting',
          navigation: true,
        }}
      />
      <Modal
        size="mini"
        open={pinModal}
        onClose={() => setPinModal(false)}
      >
        <Modal.Header>
          Confirmation
          <Icon
            name="close"
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={submitAddFeature} loading={loading}>
            <PINInput
              className="input"
              fluid
              size="tiny"
              label="Enter Pin"
              name="password"
              onChange={handleChange}
            />

            {error && error === 'Wrong PIN.' && (
              <p color="red">
                <Message negative>{error}</Message>
              </p>
            )}

            <Button type="submit" color="blue" fluid size="tiny">
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
      <Form>
        <Form.Input
          placeholder="Name"
          label="Feature Name"
          fluid
          name="feature_name"
          onChange={handleChange}
          error={isFieldRequired(
            fieldError,
            !form?.feature_name,
            'Feature Name',
          )}
        />
        <Form.Dropdown
          placeholder="Select"
          fluid
          label="Accessibility level"
          selection
          options={toDropDownOptions(featureTypeOptions)}
          name="feature_type"
          onChange={handleChange}
          error={isFieldRequired(
            fieldError,
            !form?.feature_type,
            'Accessibility level',
          )}
        />
        <Form.Dropdown
          placeholder="Platform"
          fluid
          label="Platform(s)"
          selection
          multiple
          options={toDropDownOptions(platformOptions)}
          name="platforms"
          onChange={handleChange}
          error={isFieldRequired(
            fieldError,
            !form?.platforms,
            'Platform',
          )}
        />
        <Form.Dropdown
          placeholder="Daily"
          fluid
          label="Frequency"
          selection
          options={toDropDownOptions(activeFrequency)}
          name="active_frequency"
          onChange={handleChange}
          error={isFieldRequired(
            fieldError,
            !form?.active_frequency,
            'Frequency',
          )}
        />
        {form?.active_frequency === 'daily' ? (
          <Form.Input
            placeholder="Select time"
            fluid
            label="Time"
            type="time"
            name="allowed_time"
            onChange={handleChange}
            error={isFieldRequired(
              fieldError,
              !form?.active_time,
              'Time',
            )}
          />
        ) : form?.active_frequency === 'weekly' ? (
          <Form.Select
            placeholder="Select Day"
            fluid
            label="Day of a week"
            options={toDropDownOptions(weekDaysPicker)}
            name="active_time"
            onChange={handleChange}
            error={isFieldRequired(
              fieldError,
              !form?.active_time,
              'Day of a week',
            )}
          />
        ) : form?.active_frequency === 'annually' ? (
          <Form.Select
            placeholder="Select Month"
            fluid
            label="Month of a year"
            options={toDropDownOptions(monthsPicker)}
            name="active_time"
            onChange={handleChange}
            error={isFieldRequired(
              fieldError,
              !form?.active_time,
              'Month of a year',
            )}
          />
        ) : (
          <Form.Input
            placeholder="Select Date"
            fluid
            label="Date of a month"
            name="allowed_date"
            type="date"
            onChange={handleChange}
            error={isFieldRequired(
              fieldError,
              !form?.active_time,
              'Date of a month',
            )}
          />
        )}
        <Form.Input
          placeholder="Url"
          fluid
          label="Endpoint Url (optional)"
          name="endpoint"
          error={hasWhiteSpace(form?.endpoint, 'Endpoint')}
          onChange={handleChange}
        />
        <Form.Dropdown
          placeholder="Method"
          fluid
          label="Endpoint Method (optional)"
          selection
          options={toDropDownOptions(requestMethods, true)}
          name="request_method"
          onChange={handleChange}
        />
        <Form.Field className="flex ">
          <label className="payload-label">Has payload</label>
          <Radio
            toggle
            onChange={() => {
              if (hasPayload) {
                setPayload([
                  {
                    id: 1,
                    key: '',
                    value: '',
                  },
                ]);
              }
              setHasPayload(!hasPayload);
            }}
            name="has_payload"
            checked={hasPayload}
          />
        </Form.Field>
        {hasPayload && (
          <>
            {payload.map((item, index) => (
              <Form.Group
                key={item.id}
                widths="equal"
                className="group-items flex-center-vertical"
              >
                <Form.Input
                  fluid
                  label="Key"
                  name="payload.key"
                  onChange={(_, { value }) => {
                    setPayload(
                      payload.map(payloadItem => {
                        if (payloadItem.id === item.id) {
                          return {
                            ...payloadItem,
                            key: value,
                          };
                        }
                        return payloadItem;
                      }),
                    );
                  }}
                />
                <Form.Input
                  fluid
                  label="Value"
                  name="payload.value"
                  onChange={(_, { value }) => {
                    setPayload(
                      payload.map(payloadItem => {
                        if (payloadItem.id === item.id) {
                          return {
                            ...payloadItem,
                            value,
                          };
                        }
                        return payloadItem;
                      }),
                    );
                  }}
                />
                {index === 0 ? (
                  <Icon
                    name="plus"
                    className="cursor"
                    onClick={() => addPayloadItem()}
                  />
                ) : (
                  <Icon
                    name="minus"
                    className="cursor"
                    onClick={() => removePayloadItem(item.id)}
                  />
                )}
              </Form.Group>
            ))}
          </>
        )}
        <Form.Field>
          <label>Status</label>
          <Radio
            toggle
            onChange={() => setStatus(!status)}
            name="status"
            checked={status}
          />
        </Form.Field>
        <Form.Button
          primary
          name="addButton"
          floated="right"
          content="Add"
          onClick={onSubmit}
        />
      </Form>
    </div>
  );
};

export default AddFeature;
