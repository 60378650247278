import user from './initial-states/user';
import organizations from './initial-states/organizations';
import projects from './initial-states/projects';
import wallets from './initial-states/wallets';
import dashboard from './initial-states/dashboard';
import groups from './initial-states/groups';
import settings from './initial-states/settings';
import exportData from './initial-states/exportData';
import communication from './initial-states/communication';
import profiles from './initial-states/profiles';
import loanRequests from './initial-states/loanRequests';

export default {
  user,
  organizations,
  projects,
  wallets,
  dashboard,
  groups,
  settings,
  exportData,
  communication,
  profiles,
  loanRequests,
};
