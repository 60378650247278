import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import uploadIcon from 'assets/images/upload_icon.svg';

import './UploadButton.scss';

const UploadButton = ({ onClickUpload, uploadClicked, primary }) => {
  return (
    <Button
      className="toolbar-upload-transaction-button"
      basic
      compact
      primary={primary}
      onClick={() => {
        onClickUpload();
        uploadClicked();
      }}
    >
      <img src={uploadIcon} alt="" />
    </Button>
  );
};

UploadButton.defaultProps = {
  onClickUpload: () => true,
  uploadClicked: () => {},
  primary: false,
};

UploadButton.propTypes = {
  onClickUpload: PropTypes.func,
  primary: PropTypes.bool,
  uploadClicked: PropTypes.func,
};

export default UploadButton;
