import * as Sentry from '@sentry/browser';

const dsn =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_SENTRY_DSN_PRODUCTION
    : process.env.REACT_APP_SENTRY_DSN_STAGING;

Sentry.init({
  dsn,
});
