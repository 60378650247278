import {
  UPDATE_MICRO_LOANS_SETTINGS_DATA_ERROR,
  UPDATE_MICRO_LOANS_SETTINGS_DATA_START,
  UPDATE_MICRO_LOANS_SETTINGS_DATA_SUCCESS,
} from 'constants/action-types/settings/updateMicroLoansSettingData';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default data => (dispatch, onSuccess) =>
  dispatch(
    apiAction({
      url: '/groups/microloans/settings',
      method: 'PUT',
      data,
      API_REQUEST_START: UPDATE_MICRO_LOANS_SETTINGS_DATA_START,
      API_REQUEST_SUCCESS: UPDATE_MICRO_LOANS_SETTINGS_DATA_SUCCESS,
      API_REQUEST_FAILURE: UPDATE_MICRO_LOANS_SETTINGS_DATA_ERROR,
      onSuccess: payload => {
        dispatch({
          type: UPDATE_MICRO_LOANS_SETTINGS_DATA_SUCCESS,
          payload,
        });
        onSuccess?.();
        notification({
          message: 'Microloan setting successfully updated',
        });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: UPDATE_MICRO_LOANS_SETTINGS_DATA_ERROR,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
