/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import LineChartWrapper from 'components/common/charts/LineChart/LineChartWrapper/LineChartWrapper';
import todaysDate from 'utils/todaysDate';
import { isSuperAdmin } from 'helpers/checkRoles';

const StatCharts = ({
  data: {
    actives_vs_inactives_groups: activeVsInactiveGroups = [],
    cumulative_savings_vs_cumulative_loans = [],
    cashed_out_vs_current_commission = [],
  },
  year,
}) => {
  const shortMonthActiveVsInactiveGroups = activeVsInactiveGroups.map(
    item => ({
      ...item,
      name: item.month.substring(0, 3),
    }),
  );

  const cleanSavingsVsLoans = cumulative_savings_vs_cumulative_loans.map(
    item => {
      return {
        name: item.month.substring(0, 3),
        Savings: item.savings,
        Loans: item.loans,
      };
    },
  );

  const cashedOutVsOutstanding = cashed_out_vs_current_commission.map(
    item => ({
      ...item,
      'cashed out': item.cashed_out,
      outstanding: item.current,
    }),
  );

  const { roles = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChartWrapper
            title="Active Vs Inactive Groups"
            dataKey1="actives"
            dataKey2="inactives"
            width={440}
            height={290}
            data={shortMonthActiveVsInactiveGroups}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width="8">
          {!isSuperAdmin(roles) && (
            <BarChart
              {...{
                title: 'Current Savings Vs Cumulative Loans',
                date: todaysDate(year),
                data: cleanSavingsVsLoans,
                dataKey1: 'Savings',
                dataKey2: 'Loans',
              }}
            />
          )}

          {isSuperAdmin(roles) && (
            <BarChart
              {...{
                title: 'Cashed-Out Vs Outstanding Commission',
                date: todaysDate(year),
                data: cashedOutVsOutstanding,
                dataKey1: 'cashed out',
                dataKey2: 'outstanding',
              }}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

StatCharts.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default StatCharts;
