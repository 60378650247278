import ResetPassword from 'containers/ResetPassword';

export default {
  exact: true,
  name: 'Reset PIN',
  protected: false,
  path: '/reset-password',
  component: ResetPassword,
  permissions: ['all'],
};
