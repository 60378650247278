const userRolesTypes = [
  {
    type: 'org_user',
    dependency1: 'organization_id',
    dependency2: 'org_user_type',
  },
  {
    type: 'org_admin',
    dependency1: 'organization_id',
    dependency2: 'admin',
  },
  {
    type: 'wallet_admin',
    dependency1: 'organization_id',
  },
  {
    type: 'support_admin',
    dependency1: 'organization_id',
  },
  {
    type: 'sa_communications_admin',
    dependency1: 'organization_id',
  },
  {
    type: 'tech_admin',
    dependency1: 'organization_id',
  },
  {
    type: 'agent',
    dependency1: 'project_id',
  },
  {
    type: 'group_member',
    dependency1: 'group_id',
  },
];

export default context =>
  userRolesTypes.find(roleType =>
    roleType.type.includes(context?.replace('_roles', '')),
  );
