import React from 'react';
import AgentOverview from 'containers/Organizations/SingleOrganization/Agents/Overview';

export const agentOverview = {
  name: 'overview',
  path: /.*\/organizations\/\d+\/agents\/\d+$/,
  component: <AgentOverview />,
};

export const agentProjectOverview = {
  name: 'overview',
  path: /.*\/organizations\/\d+\/agents\/\d+\/projects\/\d*$/,
  component: <AgentOverview />,
};
