/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import GroupList from './GroupList';
import './index.scss';

const Groups = props => {
  return <GroupList {...props} />;
};

Groups.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
};

export default Groups;
