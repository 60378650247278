import {
  UPDATE_GROUP_START,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_END,
  UPDATE_GROUP_CLEAR,
} from 'constants/action-types/organizations/updateGroup';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_GROUP_START:
      return {
        ...state,
        updateGroup: {
          ...state.updateGroup,
          loading: true,
          error: null,
          message: null,
        },
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        updateGroup: {
          ...state.updateGroup,
          error: null,
          loading: false,
        },
      };
    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        updateGroup: {
          ...state.updateGroup,
          error: payload,
          message: null,
        },
      };
    case UPDATE_GROUP_END:
      return {
        ...state,
        updateGroup: {
          ...state.updateGroup,
          loading: false,
        },
      };
    case UPDATE_GROUP_CLEAR:
      return {
        ...state,
        updateGroup: {
          ...state.updateGroup,
          message: null,
          error: null,
        },
      };
    default:
      return null;
  }
};
