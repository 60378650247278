import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import queryString from 'query-string';
import { omit } from 'lodash';

import './ToolbarFooter.scss';
import FilterTransactions from 'components/common/FilterTransactions';
import Pagination from 'components/common/Pagination';
import SearchInput from 'components/common/SearchInput';
import FilterButton from 'components/common/FilterButton';
import ExportCSV from 'components/common/ExportCSV';
import {
  pagePackagesOptions,
  toDropDownOptions,
} from 'utils/dropdownOptions';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import { transactionSearchByOptions } from 'constants/searchByOptions';
import UploadButton from 'components/common/UploadButton';

const ToolbarFooter = ({
  onPaginate,
  paginationMeta,
  search,
  onSearch,
  filter,
  upload,
  filterTransactions,
  onFilterTransactions,
  organizations,
  exportCSVData,
  exportCSVFileName,
  updateUrl,
  filterClicked,
  uploadClicked,
  fetchExportData,
  hasSearchBy,
  url,
}) => {
  const { push } = useHistory();
  const { search: searchQueries } = useLocation();
  let queries = queryString.parse(searchQueries);

  const [searchBy, setSearchBy] = useState(false);

  const [activeSearch, setActiveSearch] = useState(true);

  useEffect(() => {
    if (hasSearchBy) {
      setActiveSearch(false);
    }
  }, []);

  const handleKeyEnter = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const handlePagePackage = (e, { name, value }) => {
    if (!queries.limit) {
      queries = { [name]: value };
    }
    queries = { ...omit(queries, 'limit'), [name]: value };
    push(formatSearchParameter(queries));
    onPaginate(queries);
  };

  const handleOptionChange = (e, { value }) => {
    setActiveSearch(true);
    setSearchBy(value);
  };

  return (
    <div className="save-dash-toolbar-footer">
      {onPaginate && paginationMeta && paginationMeta.pages > 1 ? (
        <>
          <Dropdown
            className="toolbar-pagination __package"
            name="limit"
            placeholder="100/page"
            options={pagePackagesOptions(paginationMeta.total)}
            defaultValue={queries?.limit}
            onChange={handlePagePackage}
            selection
          />
          <Pagination
            onPaginate={onPaginate}
            paginationMeta={paginationMeta}
            updateUrl={updateUrl}
          />
        </>
      ) : null}
      {hasSearchBy && (
        <Dropdown
          options={toDropDownOptions(transactionSearchByOptions)}
          placeholder="Search by"
          className="toolbar-search-input search-by"
          scrolling
          onChange={handleOptionChange}
        />
      )}
      {(search || onSearch) && activeSearch ? (
        <SearchInput
          onClickFilter
          onSearch={onSearch}
          updateUrl={updateUrl}
          onKeyDown={handleKeyEnter}
          searchBy={searchBy}
        />
      ) : null}
      {filter ? <FilterButton filterClicked={filterClicked} /> : null}
      {upload ? <UploadButton uploadClicked={uploadClicked} /> : null}
      {filterTransactions && organizations.length > 0 ? (
        <FilterTransactions
          organizations={organizations}
          onFilterTransactions={onFilterTransactions}
        />
      ) : null}
      {exportCSVData ? (
        <ExportCSV
          data={exportCSVData}
          fileName={exportCSVFileName}
        />
      ) : null}

      {fetchExportData ? (
        <ExportCSV
          url={url}
          fileName={exportCSVFileName}
          fetchExportData
        />
      ) : null}
    </div>
  );
};

ToolbarFooter.defaultProps = {
  onPaginate: null,
  paginationMeta: {},
  search: null,
  onSearch: false,
  upload: null,
  filter: null,
  filterTransactions: false,
  onFilterTransactions: null,
  organizations: [],
  exportCSVData: null,
  exportCSVFileName: null,
  updateUrl: true,
  filterClicked: () => null,
  uploadClicked: () => null,
  url: '',
  hasSearchBy: false,
  fetchExportData: false,
};

ToolbarFooter.propTypes = {
  onPaginate: PropTypes.func,
  paginationMeta: PropTypes.instanceOf(Object),
  search: PropTypes.func,
  onSearch: PropTypes.bool,
  upload: PropTypes.bool,
  filter: PropTypes.bool,
  filterTransactions: PropTypes.bool,
  onFilterTransactions: PropTypes.func,
  organizations: PropTypes.array,
  exportCSVData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  exportCSVFileName: PropTypes.string,
  updateUrl: PropTypes.bool,
  filterClicked: PropTypes.func,
  uploadClicked: PropTypes.func,
  url: PropTypes.string,
  hasSearchBy: PropTypes.string,
  fetchExportData: PropTypes.bool,
};

export default ToolbarFooter;
