export default ({
  data,
  params,
  context,
  title,
  subitemKey,
  admin,
  isCurrentOrgParent,
  isSaveUserCountryOrganization,
  isSaveAdminCountryOrganization,
}) => {
  const isFinanceRwandaAdmin =
    (isCurrentOrgParent &&
      data.org_admin_roles?.some(item => item?.is_finance_admin)) ||
    (isCurrentOrgParent &&
      data.org_user_roles?.some(item => item?.is_finance_admin));

  const isSupportAdmin =
    (isCurrentOrgParent &&
      data.org_admin_roles?.some(item => item?.is_support_admin)) ||
    (isCurrentOrgParent &&
      data.org_user_roles?.some(item => item?.is_support_admin));

  const isCommunicationsAdmin =
    (isCurrentOrgParent &&
      data.org_admin_roles?.some(
        item => item?.is_communications_admin,
      )) ||
    (isCurrentOrgParent &&
      data.org_user_roles?.some(
        item => item?.is_communications_admin,
      ));

  const isTechAdmin =
    (isCurrentOrgParent &&
      data.org_admin_roles?.some(item => item?.is_tech_admin)) ||
    (isCurrentOrgParent &&
      data.org_user_roles?.some(item => item?.is_tech_admin));

  const financeSubItems = () => {
    if (
      data.org_admin_roles?.some(item =>
        isSaveAdminCountryOrganization(item),
      )
    )
      return data?.org_admin_roles
        ?.filter(item => isSaveAdminCountryOrganization(item))
        .map(role => ({
          name: role[subitemKey]?.name,
          id: role[subitemKey]?.id,
          organization_id: role?.organization_id,
          status: isFinanceRwandaAdmin,
        }));
    return data?.org_user_roles
      ?.filter(item => isSaveUserCountryOrganization(item))
      .map(role => ({
        name: role[subitemKey]?.name,
        id: role[subitemKey]?.id,
        organization_id: role?.organization_id,
        status: isFinanceRwandaAdmin,
      }));
  };

  const supportSubItems = () => {
    if (
      data.org_admin_roles?.some(item =>
        isSaveAdminCountryOrganization(item),
      )
    )
      return data?.org_admin_roles
        ?.filter(item => isSaveAdminCountryOrganization(item))
        .map(role => ({
          name: role[subitemKey]?.name,
          id: role[subitemKey]?.id,
          organization_id: role?.organization_id,
          status: isSupportAdmin,
        }));
    return data?.org_user_roles
      ?.filter(item => isSaveUserCountryOrganization(item))
      .map(role => ({
        name: role[subitemKey]?.name,
        id: role[subitemKey]?.id,
        organization_id: role?.organization_id,
        status: isSupportAdmin,
      }));
  };
  const communicationSubItems = () => {
    if (
      data.org_admin_roles?.some(item =>
        isSaveAdminCountryOrganization(item),
      )
    )
      return data?.org_admin_roles
        ?.filter(item => isSaveAdminCountryOrganization(item))
        .map(role => ({
          name: role[subitemKey]?.name,
          id: role[subitemKey]?.id,
          organization_id: role?.organization_id,
          status: isCommunicationsAdmin,
        }));
    return data?.org_user_roles
      ?.filter(item => isSaveUserCountryOrganization(item))
      .map(role => ({
        name: role[subitemKey]?.name,
        id: role[subitemKey]?.id,
        organization_id: role?.organization_id,
        status: isCommunicationsAdmin,
      }));
  };
  const techSubItems = () => {
    if (
      data.org_admin_roles?.some(item =>
        isSaveAdminCountryOrganization(item),
      )
    )
      return data?.org_admin_roles
        ?.filter(item => isSaveAdminCountryOrganization(item))
        .map(role => ({
          name: role[subitemKey]?.name,
          id: role[subitemKey]?.id,
          organization_id: role?.organization_id,
          status: isTechAdmin,
        }));
    return data?.org_user_roles
      ?.filter(item => isSaveUserCountryOrganization(item))
      .map(role => ({
        name: role[subitemKey]?.name,
        id: role[subitemKey]?.id,
        organization_id: role?.organization_id,
        status: isTechAdmin,
      }));
  };

  if (admin) {
    return {
      user: data?.user?.first_name,
      userId: params?.userId,
      title,
      status:
        !!data[context]?.length &&
        data[context]?.some(item =>
          [true, 'active', 'accepted'].includes(item?.status),
        ) &&
        data[context]?.some(
          item =>
            !!item?.is_finance_admin ||
            !!item?.is_support_admin ||
            !!item?.is_tech_admin,
        ),
      subItems: [
        {
          user: data?.user?.first_name,
          userId: params?.userId,
          title: 'Organization Admin',
          status:
            !!data[context]?.length &&
            data[context]?.some(item =>
              [true, 'active', 'accepted'].includes(item?.status),
            ),
          context: 'org_admin',
          subItems: data[context]?.map(role => ({
            name: role[subitemKey]?.name,
            id: role[subitemKey]?.id,
            organization_id: role?.organization_id,
            status: role?.status,
          })),
        },
        {
          user: data?.user?.first_name,
          userId: params?.userId,
          title: 'Support  Admin ',
          status: isSupportAdmin,
          context: 'support_admin',
          subItems: supportSubItems(),
        },
        {
          user: data?.user?.first_name,
          userId: params?.userId,
          title: 'Wallet Admin',
          status: isFinanceRwandaAdmin,
          context: 'wallet_admin',
          subItems: financeSubItems(),
        },
        {
          user: data?.user?.first_name,
          userId: params?.userId,
          title: 'Communications Admin',
          status: isCommunicationsAdmin,
          context: 'sa_communications_admin',
          subItems: communicationSubItems(),
        },
        {
          user: data?.user?.first_name,
          userId: params?.userId,
          title: 'Tech Admin',
          status: isTechAdmin,
          context: 'tech_admin',
          subItems: techSubItems(),
        },
      ],
    };
  }

  return {
    user: data?.user?.first_name,
    userId: params?.userId,
    title,
    status:
      !!data[context]?.length &&
      data[context]?.some(item =>
        [true, 'active', 'accepted'].includes(item?.status),
      ),
    context,
    subItems: data[context]?.map(role => {
      if (context === 'org_user_roles') {
        return {
          name: role[subitemKey]?.name,
          id: role[subitemKey]?.id,
          organization_id: role?.organization_id,
          status: role?.status,
        };
      }
      return {
        name: role[subitemKey]?.name,
        id: role[subitemKey]?.id,
        organization_id: role[subitemKey]?.organization_id,
        status: role?.status,
      };
    }),
  };
};
