import initialState from 'redux/initial-states/loanRequests';
import pendingLoanRequests from './pendingLoanRequests';
import approveLoanRequest from './approveLoanRequest';
import amendLoanRequest from './amendLoanRequest';
import singleLoanRequest from './singleLoanRequest';

export default (state = initialState, action = {}) => ({
  ...state,
  ...pendingLoanRequests(state, action),
  ...approveLoanRequest(state, action),
  ...amendLoanRequest(state, action),
  ...singleLoanRequest(state, action),
});
