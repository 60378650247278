/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import phoneUtils from '@exuus/rwanda-phone-utils';
import notify from 'helpers/notificationHelper';
import * as notificationStatus from 'constants/notificationStatus';
import {
  createAdminUser,
  clearUserAdded,
} from 'redux/actions/user/createAdminUser';
import AddUserUI from 'components/Users/AddUserUI';
import AddOrganizationUserUI from 'components/Users/AddOrganizationUserUI';

const AddUser = ({ history, match }) => {
  const { organizationId } = match.params;
  const [id, setId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [occupation, setOccupation] = useState('');
  const [workEmail, setWorkEmail] = useState('');
  const [idType, setIdType] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [userType, setUserType] = useState('user');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { error, user, isCreating } = useSelector(
    state => state.user.addUser,
  );

  const { organizations } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  useEffect(() => {}, [workEmail]);

  useEffect(() => {
    clearUserAdded()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (user.id) {
      notify({
        message: 'admin successfully created',
        status: notificationStatus.SUCCESS,
      });
      setPhone('');
      clearUserAdded()(dispatch);
      setTimeout(() => history.push(`/users/${user.id}`), 2000);
    }
  }, [user, history, dispatch, t]);

  const checkUserData = fieldsToValidate => {
    if (
      !validator.isEmail(workEmail || '') &&
      fieldsToValidate.includes('workEmail')
    ) {
      setEmailError(t('enterEmailAddress'));
      return false;
    }
    setEmailError('');

    if (
      /\d/.test(firstName) &&
      fieldsToValidate.includes('firstName')
    ) {
      setFirstNameError(t('firstNameNoNumbers'));
      return false;
    }
    setFirstNameError('');

    if (
      /\d/.test(lastName) &&
      fieldsToValidate.includes('lastName')
    ) {
      setLastNameError(t('lastNameNoNumbers'));
      return false;
    }
    setLastNameError('');
    if (
      fieldsToValidate.includes('phone') &&
      !phoneUtils(phone).isValid
    ) {
      setPhoneError(t('enterValidPhone'));
      return false;
    }
    setPhoneError('');
    return true;
  };

  const onSubmit = ({ userExists, user: submittedUser } = {}) => {
    const submittedFirstName = submittedUser?.first_name?.split(
      ' ',
    )?.[0];
    const submittedLastName =
      submittedUser?.first_name?.split(' ')?.[1] ||
      submittedUser?.first_name?.split(' ')?.[0];

    const user = organizationId
      ? {
          phone_number: phoneUtils(phone).normalized,
          user_type: (userType || 'user').toLowerCase(),
          organization_id: organizations && organizations[0].id,
        }
      : {
          phone_number: phoneUtils(phone).normalized,
          work_email: workEmail,
          first_name: firstName || submittedFirstName,
          last_name: lastName || submittedLastName,
          id_number: id,
          occupation,
          organization_id: organizations && organizations[0].id,
        };
    const fieldsToValidate = organizationId
      ? ['phone']
      : ['phone', 'workEmail', 'firstName', 'lastName', 'occupation'];

    if (userExists) {
      return createAdminUser(user, organizationId)(dispatch);
    }

    if (checkUserData(fieldsToValidate)) {
      createAdminUser(user, organizationId)(dispatch);
    }
  };

  return organizationId ? (
    <AddOrganizationUserUI
      error={error}
      phone={phone}
      user={user}
      onSubmit={onSubmit}
      isCreating={isCreating}
      setPhone={setPhone}
      setUserType={setUserType}
      phoneError={phoneError}
    />
  ) : (
    <AddUserUI
      error={error}
      user={user}
      onSubmit={onSubmit}
      id={id}
      setId={setId}
      setLastName={setLastName}
      setFirstName={setFirstName}
      isCreating={isCreating}
      setOccupation={setOccupation}
      setPhone={setPhone}
      setWorkEmail={setWorkEmail}
      phoneError={phoneError}
      emailError={emailError}
      lastNameError={lastNameError}
      firstNameError={firstNameError}
      workEmail={workEmail}
      occupation={occupation}
      setIdType={setIdType}
      idType={idType}
    />
  );
};

AddUser.defaultProps = {
  match: { params: {} },
};

AddUser.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.oneOfType([PropTypes.object]),
  }),
};

export default AddUser;
