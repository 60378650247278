import Support from 'containers/Support';

export default {
  exact: true,
  name: 'Support',
  protected: true,
  path: '/support',
  component: Support,
  permissions: ['all'],
};
