import {
  TOGGLE_ROLES_IN_BULK_FAILURE,
  TOGGLE_ROLES_IN_BULK_START,
  TOGGLE_ROLES_IN_BULK_SUCCESS,
} from 'constants/action-types/user/toggleRolesInBulk';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';
import getUserRoles from './getUserRoles';

export default (userId, action, data) => (dispatch, setPinModal) =>
  dispatch(
    apiAction({
      url: `/admin/users/${userId}/${action}/bulk`,
      data,
      method: 'PUT',
      API_REQUEST_START: TOGGLE_ROLES_IN_BULK_START,
      API_REQUEST_FAILURE: TOGGLE_ROLES_IN_BULK_FAILURE,
      API_REQUEST_SUCCESS: TOGGLE_ROLES_IN_BULK_SUCCESS,
      onSuccess: ({ message }) => {
        dispatch({
          type: TOGGLE_ROLES_IN_BULK_SUCCESS,
          payload: message,
        });
        getUserRoles(userId)(dispatch);
        setPinModal(false);
        notification({ message });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: TOGGLE_ROLES_IN_BULK_FAILURE,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          setPinModal(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
