import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import searchUser from 'redux/actions/user/searchUser';

const UserSearch = ({ name, handleChange, error }) => {
  const { items, loading } = useSelector(
    ({ user: { searchUser } }) => searchUser,
  );

  const [user, setUser] = useState();

  const [value, setValue] = useState('');
  const [typing, setTyping] = useState(false);
  const [addedOptions, setAdddedOptions] = useState([]);

  const dispatch = useDispatch();

  const handleSearchChange = async (e, { searchQuery }) => {
    setValue(searchQuery);
    setTyping(true);

    await setTimeout(() => setTyping(false), 2000);

    if (!typing) {
      searchUser(searchQuery)(dispatch);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      searchUser(value)(dispatch);
    }
  };

  const itemOptions = (items || []).map(
    ({
      id,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    }) => ({
      text: `${firstName}  ${lastName} ${phoneNumber}`,
      value: phoneNumber,
      key: id,
    }),
  );

  const [selectedOption, setSelectedOption] = useState();

  const options = [...itemOptions, ...addedOptions];

  const handleAddition = (e, { name, value }) => {
    setAdddedOptions([{ text: value, value }]);
    handleChange(e, { name, value, addition: true, user });
  };

  const onChange = (e, { name, value }) => {
    setSelectedOption(value.replace(/[a-zA-Z]/g, ''));

    const user = items.find(
      item => Number(item.phone_number) === Number(value),
    );
    handleChange(e, { name, value, user });
    setUser(user);
  };

  return (
    <Form.Field
      required
      control={Dropdown}
      search
      selection
      error={error}
      loading={loading}
      onSearchChange={handleSearchChange}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onAddItem={handleAddition}
      options={options}
      allowAdditions
      type="text"
      label="Phone"
      name={name}
      value={selectedOption}
      placeholder="780-000-001"
    />
  );
};

UserSearch.defaultProps = {
  name: 'phone_number',
};

UserSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  name: PropTypes.string,
};

export default UserSearch;
