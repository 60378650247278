import AllProjects from 'containers/Projects/AllProjects';

export default {
  exact: true,
  name: 'Projects',
  protected: true,
  path: '/projects',
  component: AllProjects,
  permissions: ['all'],
};
