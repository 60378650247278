/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import currencyFormat from 'utils/currencyFormat';
import todaysDate from 'utils/todaysDate';
import toMonthStat from 'helpers/toMonthStat';

const LineCharts = ({ data, year }) => {
  const {
    current_savings: currentSavings,
    current_savings_total: currentSavingsTotal,
    cumulative_loans_total: CumulativeLoansCount,
    cumulative_loans: cumulativeLoans,
    current_social_funds: socialFunds,
    group: { social_funds_balance: socialFundsBalance } = {},
    current_loans_total: outstandingLoansCount,
    current_loans: outstandingLoans,
  } = data;

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Current savings',
              date: todaysDate(year),
              amount: `${currencyFormat(currentSavingsTotal)}`,
              data: toMonthStat(currentSavings),
              background: greenColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'groups',
              viewKey: 'saving',
              viewKey1: 'transactions',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Social funds balance',
              date: todaysDate(year),
              amount: `${currencyFormat(socialFundsBalance)}`,
              data: toMonthStat(socialFunds),
              background: blueColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'groups',
              viewKey: 'social_fund',
              viewKey1: 'transactions',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Cumulative loans',
              date: todaysDate(year),
              amount: `${currencyFormat(CumulativeLoansCount)}`,
              data: toMonthStat(cumulativeLoans),
              background: orangeColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'groups',
              viewKey: 'loan',
              viewKey1: 'transactions',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Outstanding loans',
              date: todaysDate(year),
              amount: `${currencyFormat(outstandingLoansCount)}`,
              data: toMonthStat(outstandingLoans),
              background: redColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'groups',
              viewKey: 'loan',
              viewKey1: 'transactions',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default LineCharts;
