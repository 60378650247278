import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const BarChart = ({
  data,
  width,
  height,
  color1,
  color2,
  color3,
  dataKey1,
  dataKey2,
  dataKey3,
  dataKeyXaxis,
  stack,
}) => {
  return (
    <Chart
      width={width}
      height={height}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={dataKeyXaxis} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar
        dataKey={dataKey1}
        stackId={stack ? 'a' : null}
        fill={color1}
      />
      <Bar
        dataKey={dataKey2}
        stackId={stack ? 'a' : null}
        fill={color2}
      />
      {dataKey3 && <Bar dataKey={dataKey3} fill={color3} />}
    </Chart>
  );
};

BarChart.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  dataKey1: PropTypes.string.isRequired,
  dataKey2: PropTypes.string.isRequired,
  dataKeyXaxis: PropTypes.string.isRequired,
  color3: PropTypes.string,
  dataKey3: PropTypes.string,
  stack: PropTypes.bool,
};

BarChart.defaultProps = {
  width: 200,
  height: 50,
  color3: '#ffc658',
  dataKey3: '',
  stack: false,
};

export default BarChart;
