/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Message,
  Button,
  Grid,
  Form,
  TextArea,
  Confirm,
  Dropdown,
  Icon,
  Header,
  Loader as Spinner,
} from 'semantic-ui-react';
import './index.scss';
import moment from 'moment';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import {
  getSingleGroup,
  changeGroupStatus,
} from 'redux/actions/organizations/organizationGroups';
import GroupLineCharts from 'components/Organizations/SingleOrganization/Groups/LineCharts';
import GroupGenders from 'components/Organizations/SingleOrganization/Groups/GroupGenders';
import StatCharts from 'containers/Organizations/SingleOrganization/Groups/StatCharts';
import SimpleModal from 'components/common/modals/SimpleModal';
import deleteGroup from 'redux/actions/organizations/deleteGroup';
import resetGroupShares from 'redux/actions/groups/resetGroupShares';
import recomputeGroupBalance from 'redux/actions/groups/recomputeGroupBalance';
import getGroupAccessBankBalance from 'redux/actions/groups/getGroupAccessBankBalance';
import notify from 'helpers/notificationHelper';
import { ERROR } from 'constants/notificationStatus';
import { isSuperAdmin } from 'helpers/checkRoles';
import capitalize from 'utils/capitalize';
import BulkEditModal from 'components/common/modals/BulkEditModal';
import EditGroupDetails from 'components/Organizations/SingleOrganization/Groups/Edit/groupDetails';
import GroupDetails from 'components/Organizations/SingleOrganization/Groups/GroupDetails';
import FinancialDetails from 'components/Organizations/SingleOrganization/Groups/Edit/FinancialDetails';
import CycleDetails from 'components/Organizations/SingleOrganization/Groups/Edit/CycleDetails';
import FineDetails from 'components/Organizations/SingleOrganization/Groups/Edit/FineDetails';
import FinancialInstitutionDetails from 'components/Organizations/SingleOrganization/Groups/Edit/FinacialInstitutionDetails';
import AffiliationDetails from 'components/Organizations/SingleOrganization/Groups/Edit/AffiliationDetails';
import DropdownFilterYear from 'components/common/DropdownFilterYear';
import isCurrentYear from 'helpers/isCurrentYear';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions/';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import access_bank_logo from 'assets/images/access-bank-wallet.svg';
import formatNumber from 'helpers/formatNumber';

const SingleGroup = ({
  match: {
    params: { organizationId, projectId, tabOption, option },
  },
  history: { push },
}) => {
  const [openModal, setOpenModal] = useState(false);
  const currentYear = useRef();
  const [form, setForm] = useState({});
  const [userPin, setUserPin] = useState(null);
  const [showConfirmationModel, setShowConfirmationModel] = useState(
    false,
  );

  const [
    showGroupAccessBankBalance,
    setShowGroupAccessBankBalance,
  ] = useState(false);

  const [isBulkEditModalOpened, showBulkEditModal] = useState(false);
  const [
    confirmGroupStatusModel,
    setConfirmGroupStatusModel,
  ] = useState({ status: '', model: false });
  const { item, loading, error } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup,
  );

  const { roles } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } }) => details,
  );

  const {
    loading: resetGroupLoading,
    error: resetGroupError,
  } = useSelector(
    ({ groups: { resetGroupShares } }) => resetGroupShares,
  );

  const { loading: recomputeGroupBalanceLoading } = useSelector(
    ({ groups: { recomputeGroupBalance } }) => recomputeGroupBalance,
  );

  const {
    loading: getGroupAccessBankBalanceLoading,
    data: accessBankBalance,
  } = useSelector(
    ({ groups: { getGroupAccessBankBalance } }) =>
      getGroupAccessBankBalance,
  );

  const {
    message: deleteMessage,
    loading: loadingDelete,
    error: deleteError,
  } = useSelector(
    ({ organizations: { deleteGroup } }) => deleteGroup,
  );

  const showEditTabMenu = relevance =>
    relevance?.includes(item?.group?.type);

  const panes = [
    {
      menuItem: 'Group Details',
      render: <EditGroupDetails />,
      show: showEditTabMenu([
        'independent',
        'supervised',
        'graduated',
      ]),
    },
    {
      menuItem: 'Affiliation Details',
      render: <AffiliationDetails />,
      show: showEditTabMenu(['supervised', 'graduated']),
    },
    {
      menuItem: 'Financial Details',
      render: <FinancialDetails />,
      show: showEditTabMenu([
        'independent',
        'supervised',
        'graduated',
      ]),
    },
    {
      menuItem: 'Cycle Details',
      render: <CycleDetails />,
      show: showEditTabMenu([
        'independent',
        'supervised',
        'graduated',
      ]),
    },
    {
      menuItem: 'Fines Details',
      render: <FineDetails />,
      show: showEditTabMenu([
        'independent',
        'supervised',
        'graduated',
      ]),
    },
    {
      menuItem: 'Financial Inst',
      render: <FinancialInstitutionDetails />,
      show: showEditTabMenu(['supervised', 'graduated']),
    },
  ];

  useEffect(() => {
    setConfirmGroupStatusModel({
      ...confirmGroupStatusModel,
      status: item?.group?.status,
    });
  }, [item]);

  useEffect(() => {
    if (deleteMessage && !error) {
      notify({ message: deleteMessage });
      setForm({});
    }
  }, [deleteMessage]);

  useEffect(() => {
    if (deleteError) {
      if (!deleteError.errors) {
        notify({ message: deleteError.message, status: ERROR });
      }
      if (deleteError.errors) {
        notify({
          message: deleteError.errors[0].message,
          status: ERROR,
        });
      }
    }
  }, [deleteError]);

  const dispatch = useDispatch();
  const {
    group: {
      name = '',
      id: groupId,
      savings_balance,
      loans_balance,
    } = {},
    groups_members_count: members,
  } = item;

  const getSingleGroupWithYear = (
    { tabOption, option },
    yearQuery,
  ) => {
    currentYear.current = yearQuery.year;
    getSingleGroup(tabOption || option, yearQuery)(dispatch);
  };

  const projectUrl = projectId ? `/projects/${projectId}` : '';

  const handleDeleteGroup = () => {
    deleteGroup(groupId, form)(dispatch)(
      setShowConfirmationModel,
      notify,
      push,
      organizationId,
    );
  };

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const { loading: changeGroupStatusLoading } = useSelector(
    ({ organizations: { changeGroupStatus } }) => changeGroupStatus,
  );

  const groupStatusAction = context => {
    switch (context) {
      case 'color':
        return confirmGroupStatusModel.status === 'active'
          ? 'red'
          : 'black';
      case 'content':
      case 'dispatch':
        return confirmGroupStatusModel.status === 'active'
          ? 'deactivate'
          : 'activate';
      default:
        break;
    }
    return null;
  };

  const canGroupsBeResetted = () => {
    return savings_balance === 0 && loans_balance === 0;
  };

  return (
    <>
      <SimpleModal
        title="Delete group"
        content="Are you sure you want to delete this group ?"
        open={showConfirmationModel}
        onClose={() => setShowConfirmationModel(false)}
      >
        <Form
          className="delete-form"
          onSubmit={handleDeleteGroup}
          loading={loadingDelete}
        >
          <TextArea
            required
            placeholder="Provide a reason for the deletion"
            className="delete-form__reason"
            name="comment"
            onChange={handleChange}
          />
          <Button
            floated="right"
            type="submit"
            content="Delete"
            color="red"
            onClick={() => null}
          />
        </Form>
      </SimpleModal>

      <Confirm
        open={confirmGroupStatusModel.model}
        header={`${capitalize(
          groupStatusAction('content'),
        )} Saving Group`}
        confirmButton={<Button negative>Confirm</Button>}
        onCancel={() =>
          setConfirmGroupStatusModel({
            ...confirmGroupStatusModel,
            model: false,
          })
        }
        onConfirm={() => {
          setConfirmGroupStatusModel({
            ...confirmGroupStatusModel,
            model: false,
          });
          const action = groupStatusAction('dispatch');
          changeGroupStatus(groupId, action)(dispatch);
        }}
        content={`Are you sure you want to ${groupStatusAction(
          'content',
        )} SG ${item.name}?`}
        size="mini"
      />

      <SimpleModal hideHeader open={recomputeGroupBalanceLoading}>
        <div className="recompute_group_balance">
          <Loader paddingTop={0} loaderSize="huge" center />
          <p className="recompute_group_balance__main_title">
            Recomputing Group Balance
          </p>
          <p className="recompute_group_balance__minor_title">
            Please Wait...
          </p>
        </div>
      </SimpleModal>

      <SimpleModal
        hideHeader
        open={showGroupAccessBankBalance}
        onClose={() => setShowGroupAccessBankBalance(false)}
      >
        <div className="group_access_bank_balance_container">
          {accessBankBalance && !getGroupAccessBankBalanceLoading ? (
            <div className="group_access_bank_balance_container__value">
              <img src={access_bank_logo} alt="Access Bank Logo" />
              <div className="group_access_bank_balance_container__value__container">
                <p className="group_access_bank_balance_container__value__container__title">
                  Group Balance
                </p>
                <p className="group_access_bank_balance_container__value__container__balance">
                  {formatNumber(
                    accessBankBalance.access_bank_wallet_balance,
                  )}{' '}
                  RWF
                </p>
                <p className="group_access_bank_balance_container__value__container__date">
                  {moment().format('DD MMMM YYYY')}
                </p>
              </div>
            </div>
          ) : (
            <div className="group_access_bank_balance_container__loader">
              <Loader loaderSize="huge" center />
              <p className="group_access_bank_balance_container__loader__main_title">
                Recomputing Group Balance
              </p>
              <p className="group_access_bank_balance_container__loader__minor_title">
                Please Wait...
              </p>
            </div>
          )}
        </div>
      </SimpleModal>

      <BulkEditModal
        title={`Edit Group: ${name}`}
        panes={panes}
        states={{ isOpened: isBulkEditModalOpened }}
        showBulkEditModal={showBulkEditModal}
        size={item?.group?.type === 'independent' ? 'tiny' : 'small'}
      />

      <Toolbar
        header={{
          breadcrumbParent: 'Saving Group',
          breadcrumbParentLink: `${projectUrl}/organizations/${organizationId}/groups`,
          breadcrumbActive: ` ${name}`,
          rightComponent: (
            <>
              <Spinner
                active={changeGroupStatusLoading}
                inline
                size="small"
              />
              <DropdownFilterYear
                handleChange={getSingleGroupWithYear}
                params={{ tabOption, option }}
              />

              <Button
                primary
                content="Recent activities"
                onClick={() =>
                  push(
                    `${projectUrl}/organizations/${organizationId}/groups/${tabOption ||
                      option}/activities`,
                  )
                }
              />
              <Button
                primary
                label={members ? `${members}` : '-'}
                content="View Members"
                onClick={() =>
                  push(
                    `${projectUrl}/organizations/${organizationId}/groups/${tabOption ||
                      option}/members`,
                  )
                }
              />
              {!loading && isSuperAdmin(roles) && (
                <Dropdown
                  className="setting-dropdown"
                  icon={false}
                  floating
                  labeled
                  trigger={
                    <Button icon primary labelPosition="right">
                      <span>
                        <Icon name="setting" />
                      </span>
                      Settings
                      <Icon name="triangle down" />
                    </Button>
                  }
                >
                  <Dropdown.Menu className="right">
                    <Dropdown.Item
                      onClick={() =>
                        setConfirmGroupStatusModel({
                          ...confirmGroupStatusModel,
                          model: true,
                        })
                      }
                    >
                      <Header
                        as="h4"
                        color={groupStatusAction('color')}
                      >
                        {capitalize(groupStatusAction('content'))}
                      </Header>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => showBulkEditModal(true)}
                    >
                      <Header as="h4">Edit</Header>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() =>
                        recomputeGroupBalance(groupId)(dispatch)
                      }
                    >
                      <Header as="h4">Recompute group balance</Header>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        getGroupAccessBankBalance(groupId)(
                          dispatch,
                          setShowGroupAccessBankBalance,
                        );
                        setShowGroupAccessBankBalance(true);
                      }}
                    >
                      <Header as="h4">
                        Fetch Access Bank balance
                      </Header>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => setShowConfirmationModel(true)}
                    >
                      <Header as="h4" color="red">
                        Delete
                      </Header>
                    </Dropdown.Item>

                    {isCurrentYear(currentYear.current) &&
                      canGroupsBeResetted() &&
                      canPerformAction(
                        permissions.canPerformAction.resetGroupShares,
                        roles,
                      ) && (
                        <Dropdown.Item
                          onClick={() => setOpenModal(true)}
                        >
                          <Header as="h4">Reset Shares</Header>
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          ),
        }}
      />
      <div className="group-overview scroll-wrapper">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : (
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column width="8">
                <GroupLineCharts
                  data={item}
                  year={currentYear.current}
                />
              </Grid.Column>
              <Grid.Column width="5">
                <GroupDetails
                  data={item}
                  year={currentYear.current}
                />
              </Grid.Column>
              <Grid.Column width="3">
                <GroupGenders
                  data={item}
                  year={currentYear.current}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={item} year={currentYear.current} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <ConfirmPinModal
          content={`Are you sure you want to reset shares for ${name} Group.`}
          openModal={openModal}
          setOpenModal={setOpenModal}
          userPin={userPin}
          setUserPin={setUserPin}
          error={resetGroupError?.message}
          loading={resetGroupLoading}
          onSubmitAction={() =>
            resetGroupShares(groupId, { password: userPin })
          }
        />
      </div>
    </>
  );
};

SingleGroup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
};

export default SingleGroup;
