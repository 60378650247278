import {
  UPDATE_USER_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_ACCOUNT_END,
} from 'constants/action-types/user/updateUser';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_START:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: true,
          error: null,
          message: null,
        },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          message: 'Successfully updated',
        },
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case UPDATE_ACCOUNT_END:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
        },
      };
    default:
      return null;
  }
};
