import {
  GET_TRANSACTIONS_START,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
} from 'constants/action-types/wallets/getTransactions';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_TRANSACTIONS_START:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true,
          error: null,
          data: [],
          meta: null,
        },
      };
    case GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          error: payload,
          loading: false,
          data: [],
          meta: null,
        },
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: false,
          error: null,
          data: payload.data,
          meta: payload.meta,
        },
      };
    default:
      return null;
  }
};
