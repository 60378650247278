import {
  GET_ALL_GROUPS_START,
  GET_ALL_GROUPS_ERROR,
  GET_ALL_GROUPS_SUCCESS,
} from 'constants/action-types/groups/getAllGroups';
import apiAction from 'helpers/apiAction';

const onSuccess = ({ data, meta }) => dispatch => {
  dispatch({
    type: GET_ALL_GROUPS_SUCCESS,
    payload: {
      data,
      meta,
    },
  });
};

export default queries => dispatch => {
  dispatch(
    apiAction({
      url: '/groups',
      queries,
      method: 'GET',
      API_REQUEST_START: GET_ALL_GROUPS_START,
      API_REQUEST_SUCCESS: GET_ALL_GROUPS_SUCCESS,
      API_REQUEST_FAILURE: GET_ALL_GROUPS_ERROR,
      onSuccess: data => onSuccess(data)(dispatch),
      onEnd: () => false,
    }),
  );
};
