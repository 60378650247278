/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from 'components/common/Toolbar';
import getGroupMember from 'redux/actions/organizations/getGroupMember';
import './memberTransactions.scss';
import Empty from 'components/common/Empty';
import getMemberTransaction from 'redux/actions/groups/getMemberTransaction';
import { capitalizeEachWords } from 'utils/capitalize';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import SidebarFilter from 'components/common/SidebarFilter';
import TYPE_NAMES from 'constants/typeNames';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import memberActivitiesOptions from 'constants/options/memberActivitiesOptions';
import { toDropDownOptions } from 'utils/dropdownOptions';
import { getSingleGroup } from 'redux/actions/organizations/organizationGroups';
import ActivityItem from '../../Activities/ActivityItem';

const MemberTransactions = ({
  match: {
    params: { organizationId, tabOption, option2: memberId, option },
  },
}) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [filterOn, setFilterOn] = useState(false);
  const [filterForm, setFilterForm] = useState({});
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);

  const { data, loading } = useSelector(
    ({
      groups: {
        singleMember: { MemberTransactions },
      },
    }) => MemberTransactions,
  );

  const { item: singleGroup } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup,
  );

  const groupId = tabOption || option;

  const filteredTransactions = () => {
    const { purpose, cycle_id } = filterForm;
    getMemberTransaction(groupId, memberId, {
      purpose,
      cycle_id,
    })(dispatch);
  };

  useEffect(() => {
    if (isEmpty(singleGroup)) {
      getSingleGroup(groupId)(dispatch);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(filterForm)) filteredTransactions();
  }, [filterForm]);

  const {
    member_info: {
      user: {
        first_name: firstName = '',
        last_name: lastName = '',
      } = {},
    } = {},
    group: { name: groupName = '' } = {},
  } = useSelector(
    ({ organizations: { groupMember } }) => groupMember?.data,
  );

  useEffect(() => {
    if (search) return setFilterForm(params);
    getMemberTransaction(groupId, memberId)(dispatch);

    if (!firstName) getGroupMember(groupId, memberId)(dispatch);
  }, []);

  const handleChange = (e, { name, value }) => {
    push(formatSearchParameter({ [name]: value }));
    setFilterForm({ ...filterForm, [name]: value });
    setFilterOn(false);
  };

  const title = ({ purpose }) =>
    `${capitalizeEachWords(purpose?.replace(/_/g, ' '))} `;

  const actualAction = type =>
    type === 'credit'
      ? `credited an amount`
      : `has been debited an amount`;

  const types = toDropDownOptions(memberActivitiesOptions);

  return (
    <>
      <div className="member__transactions">
        <Toolbar
          header={{
            breadcrumbTitle: 'breadcrumb-title',
            breadcrumbParent: `${groupName ? `${groupName}` : ''}`,
            breadcrumbParentLink: `/organizations/${organizationId}/groups/${groupId}`,
            breadcrumbChildren: `${firstName} ${lastName}`,
            breadcrumbChildrenLink: `/organizations/${organizationId}/groups/${groupId}/members/${memberId}`,
            breadcrumbActive: filterForm?.purpose
              ? `${title(filterForm)}`
              : false,
            tag: true,
            rightComponent: (
              <ToolbarFooter
                filterClicked={() => {
                  setFilterOn(true);
                }}
                filter
              />
            ),
          }}
        />
        {loading ? (
          <Loader active />
        ) : isEmpty(data) ? (
          <Empty message="No transaction found" />
        ) : (
          <div className="scroll-wrapper">
            {data.map(transaction => (
              <ActivityItem
                title={title(transaction)}
                user={transaction.user}
                description={{
                  ...transaction,
                  actual_action: actualAction(transaction.type),
                  hyperLink: false,
                }}
              />
            ))}
          </div>
        )}
      </div>
      <SidebarFilter
        {...{
          filterOn,
          setFilterOn,
          form: filterForm,
          setForm: setFilterForm,
          filterTransactions: filteredTransactions,
          typeName: TYPE_NAMES.TRANSACTION_PURPOSE,
          handleChange,
          types,
          activity: 'member',
          group: singleGroup?.group,
        }}
      />
    </>
  );
};

MemberTransactions.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
};

export default MemberTransactions;
