import {
  APPROVAL_ORG_GROUP_REQUESTS_ERROR,
  APPROVAL_ORG_GROUP_REQUESTS_START,
  APPROVAL_ORG_GROUP_REQUESTS_SUCCESS,
  ORG_GROUP_REQUESTS_FORM_RESET,
} from 'constants/action-types/wallets/approvalOrgRequests';

export default (state, { type, payload }) => {
  switch (type) {
    case ORG_GROUP_REQUESTS_FORM_RESET:
      return {
        ...state,
        approveOrgGroupRequests: {
          ...state.approveOrgGroupRequests,
          message: null,
          loading: false,
          error: null,
        },
      };
    case APPROVAL_ORG_GROUP_REQUESTS_START:
      return {
        ...state,
        approveOrgGroupRequests: {
          ...state.approveOrgGroupRequests,
          message: null,
          loading: true,
          error: null,
        },
      };
    case APPROVAL_ORG_GROUP_REQUESTS_ERROR:
      return {
        ...state,
        approveOrgGroupRequests: {
          ...state.approveOrgGroupRequests,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case APPROVAL_ORG_GROUP_REQUESTS_SUCCESS:
      return {
        ...state,
        approveOrgGroupRequests: {
          ...state.approveOrgGroupRequests,
          error: null,
          message: payload.message,
          loading: false,
        },
      };

    default:
      return null;
  }
};
