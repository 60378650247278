import 'dotenv/config';
import axios from 'axios';

const {
  REACT_APP_API_STAGING_URL,
  REACT_APP_API_PRODUCTION_URL,
  REACT_APP_PLATFORM,
  REACT_APP_NODE_ENV,
  REACT_APP_X_API_KEY,
} = process.env;

export default (httpOptions = {}) => {
  const { token, url, headers } = httpOptions;
  const userToken = token || localStorage.token;

  return axios.create({
    baseURL:
      url || REACT_APP_NODE_ENV === 'production'
        ? REACT_APP_API_PRODUCTION_URL
        : REACT_APP_API_STAGING_URL,
    headers: {
      Authorization: (userToken && `JWT ${userToken}`) || undefined,
      platform: REACT_APP_PLATFORM,
      'x-api-key': REACT_APP_X_API_KEY,
      ...headers,
    },
  });
};
