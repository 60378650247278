import {
  GET_GROUP_ACCESS_BANK_BALANCE_START,
  GET_GROUP_ACCESS_BANK_BALANCE_SUCCESS,
  GET_GROUP_ACCESS_BANK_BALANCE_FAILED,
} from 'constants/action-types/groups/getGroupAccessBankBalance';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_GROUP_ACCESS_BANK_BALANCE_START:
      return {
        ...state,
        getGroupAccessBankBalance: {
          ...state.getGroupAccessBankBalance,
          loading: true,
          error: null,
          data: null,
        },
      };
    case GET_GROUP_ACCESS_BANK_BALANCE_SUCCESS:
      return {
        ...state,
        getGroupAccessBankBalance: {
          ...state.getGroupAccessBankBalance,
          data: payload.data,
          error: null,
          loading: false,
        },
      };
    case GET_GROUP_ACCESS_BANK_BALANCE_FAILED:
      return {
        ...state,
        getGroupAccessBankBalance: {
          ...state.getGroupAccessBankBalance,
          loading: false,
          error: payload,
          data: null,
        },
      };
    default:
      return null;
  }
};
