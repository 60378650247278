export default [
  '6 months',
  '1 year',
  '1 year and 6 months',
  '2 years',
  '2 years and 6 months',
  '3 years',
  '3 years and 6 months',
  '4 years',
  '4 years and 6 months',
  '5 years',
];
