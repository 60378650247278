/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Radio } from 'semantic-ui-react';
import { isEmpty, omit } from 'lodash';
import getSingleBill from 'redux/actions/settings/getSingleBill';
import Toolbar from 'components/common/Toolbar';
import {
  toAreaDropDownOptions,
  toDropDownOptions,
  walletTransactionOptions,
} from 'utils/dropdownOptions';
import groupTypes from 'constants/groupTypes';
import {
  billNameTypes,
  billsRequiredFields,
  chargeAfterDeactive,
  chargeFromWallet,
  chargeOnTransactionTypes,
  chargePerCategory,
  chargeTypes,
  computeFromTransactionField,
  computeOnTransactionTypes,
  frequencyTypes,
  getChargeFrom,
  groupsPurpose,
  providerTypes,
} from 'constants/billingTypes';
import updateBill from 'redux/actions/settings/updateBill';
import PageLoader from 'components/common/PageLoader';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import validateFields from 'helpers/validators/validateFields';
import isFieldRequired from 'helpers/validators/isFieldRequired';
import { getAllCategories } from 'redux/actions/settings/getCategoryFeatures';

const EditBill = ({ billId }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { option: id } = useParams();
  const [form, setForm] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [userPin, setUserPin] = useState(null);
  const [displayFieldError, setFieldError] = useState(false);

  const { loading, data: bill } = useSelector(
    ({ settings: { singleBill } }) => singleBill,
  );

  const { loading: updateBillLoading, error } = useSelector(
    ({ settings: { updateBill } }) => updateBill,
  );

  const {
    loading: categoriesLoading,
    data: categories,
  } = useSelector(
    ({ settings: { categoryFeatures } }) => categoryFeatures,
  );

  useEffect(() => {
    getSingleBill(billId || id)(dispatch);
  }, [billId || id]);

  const [isActive, setActive] = useState(bill?.status);

  const todayDate = moment().format();

  const disableBtn =
    (bill?.status === 'active' || form?.status === 'active') &&
    (bill?.effective_date <= todayDate ||
      form?.effective_date <= todayDate);

  useEffect(() => {
    if (!isEmpty(bill))
      getAllCategories({
        source: bill?.charge_from_wallet,
        type: bill?.compute_on_transaction_type,
      })(dispatch);
  }, [bill]);

  const handleChange = (e, { name, value, type }) => {
    if (type === 'number') value = Number(value);

    if (
      name === billNameTypes.charge_from_wallet &&
      value === 'individual_wallet'
    ) {
      getAllCategories({
        source: value,
        type: form?.compute_on_transaction_type,
      })(dispatch);

      return setForm({
        ...form,
        [name]: value,
        charge_frequency: 'instant',
        wallet_provider: providerTypes[0],
      });
    }

    if (
      name === billNameTypes.charge_per_category &&
      value === 'group_member'
    ) {
      return setForm({
        ...form,
        [name]: value,
        charge_frequency: 'instant',
      });
    }

    if (
      name === billNameTypes.charge_on_transaction_type &&
      value === 'credit'
    ) {
      return setForm({
        ...form,
        [name]: value,
        get_charge_from: 'wallet',
      });
    }

    if (
      name === billNameTypes.charge_frequency &&
      value === 'monthly'
    ) {
      return setForm({
        ...form,
        [name]: value,
        charge_on_transaction_type: undefined,
        get_charge_from: 'wallet',
      });
    }

    if (name === billNameTypes.amount_maximum && !value)
      return setForm({ ...form, [name]: null });

    if (name === billNameTypes.en || name === billNameTypes.rw)
      return setForm({
        ...form,
        notification: { ...form.notification, [name]: value },
      });

    if (name === billNameTypes.charge_from_wallet) {
      const type =
        form?.compute_on_transaction_type ||
        bill?.compute_on_transaction_type;
      getAllCategories({ source: value, type })(dispatch);
    }

    if (name === billNameTypes.compute_on_transaction_type) {
      const source =
        form?.charge_from_wallet || bill?.charge_from_wallet;
      getAllCategories({ source, type: value })(dispatch);
    }

    setForm({ ...form, [name]: value });
  };

  const checkFields = () => {
    let requiredFields = billsRequiredFields;

    if (
      (form?.charge_from_wallet
        ? form?.charge_from_wallet
        : bill.charge_from_wallet) !== 'individual_wallet'
    ) {
      requiredFields = [...requiredFields, 'groups_types'];
    }

    if (form?.groups_types ? form?.groups_types : bill.groups_types) {
      requiredFields = [...requiredFields, 'groups_purposes'];
    }
    if (
      (form?.charge_frequency
        ? form?.charge_frequency
        : bill?.charge_frequency) === 'instant'
    ) {
      requiredFields = [
        ...requiredFields,
        'charge_on_transaction_type',
      ];
    }

    if (
      validateFields(requiredFields, {
        ...bill,
        ...form,
      }) &&
      form?.notification?.en &&
      form?.notification?.rw
    ) {
      setFieldError(false);
      return setOpenModal(true);
    }
    return setFieldError(true);
  };

  const handleSubmit = () => {
    const status = isActive ? 'active' : 'inactive';

    let data = {
      ...form,
      password: userPin,
      status,
    };

    if (form?.charge_frequency) {
      data = {
        ...data,
        get_charge_from:
          form?.get_charge_from || bill?.get_charge_from,
      };
    }
    if (form?.charge_frequency !== 'instant') {
      data = {
        ...data,
        get_charge_from:
          form?.get_charge_from || bill?.get_charge_from,
        charge_on_transaction_type: undefined,
      };
    }

    if (form.charge_from_wallet === 'individual_wallet') {
      data = {
        status: 'inactive',
        ...omit(data, [billNameTypes.charge_per_category]),
      };
    }

    if (form.charge_from_wallet === 'group_wallet') {
      data = {
        ...data,
        groups_types: form.groups_types || bill?.groups_types,
        groups_purposes:
          form.groups_purposes || bill?.groups_purposes,
      };
    }

    if (form.charge_per_category) {
      data = {
        ...data,
        charge_from_wallet:
          form.charge_from_wallet || bill?.charge_from_wallet,
      };
    }

    if (
      form.get_charge_from ||
      form?.charge_frequency === 'instant'
    ) {
      data = {
        ...data,
        charge_frequency:
          form.charge_frequency || bill?.charge_frequency,
        charge_on_transaction_type:
          form.charge_on_transaction_type ||
          bill?.charge_on_transaction_type,
      };
    }

    updateBill(id, data)(dispatch, push, () => {
      setOpenModal(false);
      setUserPin('');
    });
  };

  return loading ? (
    <PageLoader />
  ) : (
    !isEmpty(bill) && (
      <div className="billings__edit">
        <Toolbar
          header={{
            title: 'Edit bill',
            navigation: true,
          }}
        />
        <Form loading={loading}>
          <Form.Input
            placeholder="Name"
            label="Bill Name"
            fluid
            name={billNameTypes.name}
            defaultValue={bill?.name}
            onChange={handleChange}
            error={
              !bill?.name &&
              isFieldRequired(displayFieldError, !form?.name, 'Name')
            }
          />

          <Form.Group widths="equal" className="group-items">
            <Form.Dropdown
              fluid
              selection
              label="Charge from wallet"
              placeholder="-- Select --"
              name={billNameTypes.charge_from_wallet}
              defaultValue={bill?.charge_from_wallet}
              options={toDropDownOptions(chargeFromWallet)}
              onChange={handleChange}
              error={
                !bill?.charge_from_wallet &&
                isFieldRequired(
                  displayFieldError,
                  !form?.charge_from_wallet,
                  'Charge from wallet',
                )
              }
            />

            {bill?.charge_from_wallet === 'individual_wallet' ||
            form?.charge_from_wallet === 'individual_wallet' ||
            bill?.charge_per_category === 'group_member' ||
            form?.charge_per_category === 'group_member' ? (
              <Form.Dropdown
                fluid
                disabled
                selection
                label="Frequency"
                placeholder="-- Select --"
                name={billNameTypes.charge_frequency}
                defaultValue={bill?.charge_frequency}
                onChange={handleChange}
                value={frequencyTypes?.[0]}
                options={toDropDownOptions(['instant'])}
                error={
                  !bill?.charge_frequency &&
                  isFieldRequired(
                    displayFieldError,
                    !form?.charge_frequency,
                    'Frequency',
                  )
                }
              />
            ) : (
              <Form.Dropdown
                fluid
                selection
                label="Frequency"
                placeholder="-- Select --"
                name={billNameTypes.charge_frequency}
                defaultValue={bill?.charge_frequency}
                onChange={handleChange}
                options={toDropDownOptions(frequencyTypes)}
                error={
                  !bill?.charge_frequency &&
                  isFieldRequired(
                    displayFieldError,
                    !form?.charge_frequency,
                    'Frequency',
                  )
                }
              />
            )}
          </Form.Group>

          <Form.Group widths="equal" className="group-items">
            {form?.charge_frequency === 'monthly' ||
            form?.charge_on_transaction_type === 'credit' ? (
              <Form.Dropdown
                fluid
                disabled
                selection
                label="Get charge from"
                placeholder="-- Select --"
                name={billNameTypes.get_charge_from}
                defaultValue={bill?.get_charge_from}
                options={toDropDownOptions(['wallet'])}
                value={getChargeFrom?.[0]}
                onChange={handleChange}
                error={
                  !bill?.get_charge_from &&
                  isFieldRequired(
                    displayFieldError,
                    !form?.get_charge_from,
                    'Get charge from',
                  )
                }
              />
            ) : (
              <Form.Dropdown
                fluid
                selection
                label="Get charge from"
                placeholder="-- Select --"
                name={billNameTypes.get_charge_from}
                defaultValue={bill?.get_charge_from}
                options={toDropDownOptions(getChargeFrom)}
                onChange={handleChange}
                error={
                  !bill?.name &&
                  isFieldRequired(
                    displayFieldError,
                    !form?.get_charge_from,
                    'Get charge from',
                  )
                }
              />
            )}

            <Form.Dropdown
              fluid
              selection
              label="Compute on transaction type"
              placeholder="-- Select --"
              name={billNameTypes.compute_on_transaction_type}
              defaultValue={bill?.compute_on_transaction_type}
              options={toDropDownOptions(computeOnTransactionTypes)}
              onChange={handleChange}
              error={
                !bill?.compute_on_transaction_type &&
                isFieldRequired(
                  displayFieldError,
                  !form?.compute_on_transaction_type,
                  'Compute on transaction type',
                )
              }
            />
          </Form.Group>

          <Form.Group widths="equal" className="group-items">
            <Form.Dropdown
              fluid
              selection
              label="Compute on transaction field"
              placeholder="-- Select --"
              name={billNameTypes.compute_from_transaction_field}
              defaultValue={bill?.compute_from_transaction_field}
              options={toDropDownOptions(computeFromTransactionField)}
              onChange={handleChange}
              error={
                !bill?.compute_from_transaction_field &&
                isFieldRequired(
                  displayFieldError,
                  !form?.compute_from_transaction_field,
                  'Compute on transaction field',
                )
              }
            />

            {(form?.charge_frequency ? (
              form?.charge_frequency
            ) : (
              bill?.charge_frequency === 'instant'
            )) ? (
              <Form.Dropdown
                fluid
                selection
                label={`Charge on transaction type ${
                  form?.charge_frequency === 'instant' ? '' : '(Opt)'
                }`}
                placeholder="-- Select --"
                name={billNameTypes.charge_on_transaction_type}
                defaultValue={bill?.charge_on_transaction_type}
                options={toDropDownOptions(chargeOnTransactionTypes)}
                onChange={handleChange}
                error={
                  !bill?.charge_on_transaction_type &&
                  form?.charge_frequency === 'instant' &&
                  isFieldRequired(
                    displayFieldError,
                    !form?.charge_on_transaction_type,
                    'Charge on transaction type',
                  )
                }
              />
            ) : null}
          </Form.Group>

          {(form?.charge_from_wallet
            ? form?.charge_from_wallet === 'individual_wallet'
            : bill?.charge_from_wallet === 'individual_wallet') || (
            <Form.Dropdown
              fluid
              selection
              label="Charge per category"
              placeholder="-- Select --"
              name={billNameTypes.charge_per_category}
              defaultValue={bill?.charge_per_category}
              options={toDropDownOptions(chargePerCategory)}
              onChange={handleChange}
              error={
                !bill?.charge_per_category &&
                isFieldRequired(
                  displayFieldError,
                  !form?.charge_per_category,
                  'Charge per category',
                )
              }
            />
          )}

          <Form.Dropdown
            fluid
            multiple
            selection
            label={`Group type ${
              (form?.charge_from_wallet
                ? form?.charge_from_wallet
                : bill?.charge_from_wallet) === 'group_wallet'
                ? ''
                : '(Optional)'
            }`}
            placeholder="-- Select --"
            name={billNameTypes.groups_types}
            defaultValue={bill?.groups_types}
            options={toDropDownOptions(groupTypes)}
            onChange={handleChange}
            error={
              (form?.charge_from_wallet
                ? form?.charge_from_wallet
                : bill?.charge_from_wallet) === 'group_wallet' &&
              isFieldRequired(
                displayFieldError,
                form?.groups_types
                  ? !form?.groups_types?.length
                  : !bill?.groups_types?.length,
                'Group type',
              )
            }
          />

          <Form.Dropdown
            fluid
            multiple
            selection
            label={`Group purpose ${
              (form?.groups_types
              ? form?.groups_types?.length
              : bill?.groups_types)
                ? ''
                : '(Optional)'
            }`}
            placeholder="-- Select --"
            name={billNameTypes.groups_purposes}
            defaultValue={bill?.groups_purposes}
            options={toDropDownOptions(groupsPurpose)}
            onChange={handleChange}
            error={
              (form?.groups_types
                ? !!form?.groups_types?.length
                : !!bill?.groups_purposes?.length) &&
              isFieldRequired(
                displayFieldError,
                form?.groups_purposes
                  ? !form?.groups_purposes?.length
                  : !bill?.groups_purposes?.length,
                'Group purpose',
              )
            }
          />

          {(form?.charge_from_wallet ? (
            form?.charge_from_wallet === 'individual_wallet'
          ) : (
            bill?.charge_from_wallet === 'individual_wallet'
          )) ? (
            <Form.Dropdown
              fluid
              selection
              label="Wallet provider"
              placeholder="-- Select --"
              name={billNameTypes.wallet_provider}
              defaultValue={bill?.wallet_provider}
              options={toDropDownOptions(['Access_bank'], true)}
              value={providerTypes?.[0]}
              onChange={handleChange}
              error={
                !bill?.wallet_provider &&
                isFieldRequired(
                  displayFieldError,
                  !form?.wallet_provider,
                  'Wallet provider',
                )
              }
            />
          ) : (
            <Form.Dropdown
              fluid
              selection
              label="Wallet provider"
              placeholder="-- Select --"
              name={billNameTypes.wallet_provider}
              defaultValue={bill?.wallet_provider}
              options={toDropDownOptions(providerTypes, true)}
              onChange={handleChange}
              error={
                !bill?.wallet_provider &&
                isFieldRequired(
                  displayFieldError,
                  !form?.wallet_provider,
                  'Wallet provider',
                )
              }
            />
          )}

          <Form.Dropdown
            fluid
            multiple
            selection
            loading={categoriesLoading}
            label="Transaction categories"
            placeholder="-- Select --"
            name={billNameTypes.transaction_purposes}
            defaultValue={bill?.transaction_purposes}
            options={walletTransactionOptions(categories)}
            onChange={handleChange}
            error={
              !bill?.transaction_purposes &&
              isFieldRequired(
                displayFieldError,
                !form?.transaction_purposes,
                'Transaction categories',
              )
            }
          />

          <label>Range (Rwf)</label>
          <Form.Group widths="equal" className="group-items">
            <span className="label-from">From</span>
            <Form.Input
              placeholder="Min amount"
              fluid
              type="number"
              name={billNameTypes.amount_minimum}
              defaultValue={bill?.amount_minimum}
              onChange={handleChange}
              error={
                !bill?.amount_minimum &&
                isFieldRequired(
                  displayFieldError,
                  !form?.amount_minimum,
                  'Minimum amount',
                )
              }
            />
            <span>To</span>
            <Form.Input
              placeholder="Max amount"
              fluid
              type="number"
              name={billNameTypes.amount_maximum}
              defaultValue={bill?.amount_maximum}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Dropdown
            fluid
            selection
            label="Fees type"
            placeholder="-- Select --"
            name={billNameTypes.charge_type}
            defaultValue={bill?.charge_type}
            options={toDropDownOptions(chargeTypes)}
            onChange={handleChange}
            error={
              !bill?.charge_type &&
              isFieldRequired(
                displayFieldError,
                !form?.charge_type,
                'Fees type',
              )
            }
          />

          <Form.Input
            placeholder={
              bill?.charge_type === 'percentage' ||
              form?.charge_type === 'percentage'
                ? 'Fees Percentage (%)'
                : 'Fees Amount (Rwf)'
            }
            label={
              bill?.charge_type === 'percentage' ||
              form?.charge_type === 'percentage'
                ? 'Fees Percentage (%)'
                : 'Fees Amount (Rwf)'
            }
            fluid
            name={billNameTypes.charge}
            defaultValue={bill?.charge}
            onChange={handleChange}
            error={
              !bill?.charge &&
              isFieldRequired(
                displayFieldError,
                !form?.charge,
                'Fees',
              )
            }
          />

          <Form.TextArea
            fluid
            label="Description"
            placeholder="Description"
            name={billNameTypes.description}
            defaultValue={bill?.description}
            onChange={handleChange}
            error={
              !bill?.description &&
              isFieldRequired(
                displayFieldError,
                !form?.description,
                'Description',
              )
            }
          />

          <label>Message to users</label>
          <Form.Group widths="equal" className="group-items">
            <Form.TextArea
              fluid
              name={billNameTypes.en}
              placeholder="Enter the message in English"
              onChange={handleChange}
              error={
                !form?.notification?.en &&
                isFieldRequired(
                  displayFieldError,
                  !form?.notification?.en,
                  'English message',
                )
              }
            />
            <Form.TextArea
              fluid
              name={billNameTypes.rw}
              placeholder="Enter the message in Kinyarwanda"
              onChange={handleChange}
              error={
                !form?.notification?.rw &&
                isFieldRequired(
                  displayFieldError,
                  !form?.notification?.rw,
                  'Kinyarwanda message',
                )
              }
            />
          </Form.Group>

          <Form.Input
            fluid
            type="date"
            label="When does it start applying?"
            name={billNameTypes.effective_date}
            min={moment()
              .add(1, 'days')
              .format('YYYY-MM-DD')}
            disabled={
              (bill.status === 'active' &&
                bill?.effective_date <= todayDate) ||
              disableBtn
            }
            defaultValue={moment(bill?.effective_date).format(
              'YYYY-MM-DD',
            )}
            onChange={handleChange}
            error={
              !bill?.effective_date &&
              isFieldRequired(
                displayFieldError,
                !form?.effective_date,
                'Effective date',
              )
            }
          />
          <Form.Field>
            <label>Status</label>
            <Radio
              toggle
              name={billNameTypes.status}
              defaultChecked={bill?.status === 'active'}
              checked={
                (form?.charge_from_wallet
                ? form?.charge_from_wallet === 'individual_wallet'
                : bill?.charge_from_wallet === 'individual_wallet')
                  ? false
                  : undefined
              }
              onChange={(e, { checked }) => setActive(checked)}
            />
          </Form.Field>
          <Form.Dropdown
            fluid
            selection
            label="If the end of month has not reached, charge already carried out transactions?"
            name={billNameTypes.charge_after_deactivation}
            defaultValue={
              bill?.charge_after_deactivation ? 'Yes' : 'No'
            }
            options={toAreaDropDownOptions(chargeAfterDeactive)}
            onChange={handleChange}
          />
          <Form.Button
            primary
            name="addButton"
            floated="right"
            content="Save"
            onClick={checkFields}
          />
        </Form>
        <ConfirmPinModal
          content={`Do you want to confirm the edits made to the bill "${bill?.name}"?`}
          openModal={openModal}
          setOpenModal={setOpenModal}
          userPin={userPin}
          setUserPin={setUserPin}
          hasComplexSubmitAction
          handleSubmit={handleSubmit}
          loading={updateBillLoading}
          error={error}
        />
      </div>
    )
  );
};

EditBill.propTypes = {
  billId: PropTypes.string.isRequired,
};

export default EditBill;
