/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import searchAgent from 'redux/actions/user/searchAgent';

const AgentSearch = ({
  name,
  handleChange,
  error,
  disabled,
  form,
}) => {
  const { items = [], loading } = useSelector(
    ({ user: { searchAgent } }) => searchAgent,
  );

  const [value, setValue] = useState('');
  const [typing, setTyping] = useState(false);
  const [addedOptions, setAdddedOptions] = useState([]);

  const dispatch = useDispatch();

  const handleSearchChange = async (e, { searchQuery }) => {
    setValue(searchQuery);
    setTyping(true);

    await setTimeout(() => setTyping(false), 2000);

    if (!typing) {
      searchAgent(form.org_name, searchQuery)(dispatch);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      searchAgent(value)(dispatch);
    }
  };

  const itemOptions = items.map(
    ({
      id,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    }) => ({
      text: `${firstName}  ${lastName}, ${phoneNumber}`,
      value: id,
      key: id,
    }),
  );

  const options = [...itemOptions, ...addedOptions];

  const handleAddition = (e, { name, value }) => {
    setAdddedOptions([{ text: value, value }]);
    handleChange(e, { name, value });
  };

  return (
    <Form.Field
      required
      control={Dropdown}
      search
      selection
      error={error}
      loading={loading}
      onSearchChange={handleSearchChange}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      options={options}
      type="text"
      label="Agent"
      name={name}
      placeholder="Agent"
      disabled={disabled}
    />
  );
};

AgentSearch.defaultProps = {
  name: 'phone_number',
  disabled: false,
};

AgentSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.instanceOf(Object).isRequired,
};

export default AgentSearch;
