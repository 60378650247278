import {
  DELETE_GROUP_MEMBER_ERROR,
  DELETE_GROUP_MEMBER_START,
  DELETE_GROUP_MEMBER_SUCCESS,
  DELETE_MULTIPLE_MEMBER_ERROR,
  DELETE_MULTIPLE_MEMBER_START,
  DELETE_MULTIPLE_MEMBER_SUCCESS,
} from 'constants/action-types/organizations/deleteGroupMember';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import getGroupMembers from 'redux/actions/organizations/organizationMembers';

export default (organizationId, groupId, memberId, data) => (
  dispatch,
  setPinModal,
  push,
  onCloseConfirmModal,
) =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}/members/${memberId}/remove`,
      method: 'DELETE',
      data,
      API_REQUEST_START: DELETE_GROUP_MEMBER_START,
      API_REQUEST_SUCCESS: DELETE_GROUP_MEMBER_SUCCESS,
      API_REQUEST_FAILURE: DELETE_GROUP_MEMBER_ERROR,
      onSuccess: payload => {
        setPinModal(false);
        dispatch({
          type: DELETE_GROUP_MEMBER_SUCCESS,
          payload,
        });
        push(
          `/organizations/${organizationId}/groups/${groupId}/members`,
        );
        notification({
          message: 'Member successfully removed',
        });
      },
      onFailure: ({ message }) => {
        onCloseConfirmModal();
        dispatch({
          type: DELETE_GROUP_MEMBER_ERROR,
          payload: message,
        });
      },
      onEnd: () => false,
    }),
  );

export const deleteRemoveMultipleMembers = (
  groupId,
  memberIds,
  form,
) => async (action, dispatch, onCloseConfirmModal) =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}/members?type=${action}`,
      method: 'DELETE',
      data: {
        ...form,
        members: memberIds,
      },
      API_REQUEST_START: DELETE_MULTIPLE_MEMBER_START,
      API_REQUEST_SUCCESS: DELETE_MULTIPLE_MEMBER_SUCCESS,
      API_REQUEST_FAILURE: DELETE_MULTIPLE_MEMBER_ERROR,
      onSuccess: ({ data }) => {
        onCloseConfirmModal();
        dispatch({
          type: DELETE_MULTIPLE_MEMBER_SUCCESS,
          payload: data,
        });
        if (data.members_deleted.length > 0)
          notification({
            message: `${data.members_deleted.length} member(s) successfully ${action}d `,
          });
        if (data.members_ignored.length > 0)
          notification({
            message: `${data.members_ignored.length} member(s) ignored`,
            status: 'warn',
          });
        getGroupMembers(groupId)(dispatch);
      },
      onFailure: ({ message }) => {
        onCloseConfirmModal();
        dispatch({
          type: DELETE_MULTIPLE_MEMBER_ERROR,
          payload: message,
        });
        notification({
          message,
          status: 'error',
        });
      },
      onEnd: () => false,
    }),
  );
