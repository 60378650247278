export default {
  organizationActivities: {
    items: [],
    loading: false,
    error: null,
    fetched: false,
  },
  groupActivities: {
    getGroupActivities: {
      items: [],
      loading: false,
      error: null,
      meta: null,
    },
    cancelMemberRequest: {
      loading: false,
      error: null,
      message: null,
    },
    reApproveMemberRequest: {
      loading: false,
      error: null,
      message: null,
    },
  },
};
