import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon, Button } from 'semantic-ui-react';
import './index.scss';

const SimpleModal = ({
  hideHeader,
  open,
  onClose,
  title,
  content,
  negativeAction,
  onNegativeAction,
  children,
  className,
  contentStyle,
}) => (
  <Modal
    size="mini"
    open={open}
    className={`simple-modal ${className || ''}`}
    onClose={onClose}
  >
    {!hideHeader && (
      <Modal.Header className="simple-modal__header">
        <span>{title}</span>
        <Icon
          name="close"
          fitted
          className="pointer"
          onClick={onClose}
        />
      </Modal.Header>
    )}

    <Modal.Content style={contentStyle}>
      <div className="simple-modal__content">{content}</div>
      {children}
    </Modal.Content>
    {negativeAction && (
      <Modal.Actions>
        {negativeAction && (
          <Button
            content={negativeAction}
            color="red"
            onClick={onNegativeAction}
          />
        )}
      </Modal.Actions>
    )}
  </Modal>
);

SimpleModal.defaultProps = {
  negativeAction: '',
  onNegativeAction: () => null,
  children: null,
  hideHeader: false,
  className: '',
  contentStyle: {},
  content: '',
};

SimpleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  negativeAction: PropTypes.string,
  onNegativeAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  hideHeader: PropTypes.bool,
  className: PropTypes.string,
  contentStyle: PropTypes.instanceOf(Object),
};

export default SimpleModal;
