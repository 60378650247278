import {
  GET_USER_ROLES_START,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
} from 'constants/action-types/user/getUserRoles';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_USER_ROLES_START:
      return {
        ...state,
        singleUserRoles: {
          ...state.singleUserRoles,
          loading: true,
          error: null,
        },
      };
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        singleUserRoles: {
          ...state.singleUserRoles,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case GET_USER_ROLES_FAILURE:
      return {
        ...state,
        singleUserRoles: {
          ...state.singleUserRoles,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
