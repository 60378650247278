import React from 'react';
import PropTypes from 'prop-types';
import SingleProject from 'components/Projects/SingleProject';

const SingleProjectOverviewContainer = ({ option1 }) => {
  return <SingleProject option1={option1} />;
};

SingleProjectOverviewContainer.defaultProps = {
  option1: 0,
};

SingleProjectOverviewContainer.propTypes = {
  option1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SingleProjectOverviewContainer;
