/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Button, Table, Message, Popup } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useHistory,
  Link,
  useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import Toolbar from 'components/common/Toolbar';
import getOrganizationAgents from 'redux/actions/organizations/organizationAgents';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import './index.scss';
import Empty from 'components/common/Empty';
import formatNumber from 'helpers/formatNumber';
import { isSuperAdmin } from 'helpers/checkRoles';
import { formatSearchParameter } from 'helpers/formatUrlParameter';

const OrganizationAgents = () => {
  const { organizationId, projectId } = useParams();
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { search, state = {} } = useLocation();
  const currentProjectId = projectId || state?.projectId;

  const { items = [], loading, error, meta } = useSelector(
    ({ organizations: { organizationAgents } }) => organizationAgents,
  );

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } = {} }) =>
      details,
  );

  const dispatch = useDispatch();

  const onQuery = queries => {
    getOrganizationAgents(
      organizationId,
      projectId,
      queries,
    )(dispatch);
  };

  useEffect(() => {
    getOrganizationAgents(organizationId, currentProjectId)(dispatch);

    if (search) {
      const queries = queryString.parse(search);
      onQuery(queries);
      push(formatSearchParameter(queries));
    }
  }, [dispatch]);

  const agents = () => {
    if (items[0] && items[0].user) {
      return items.map(({ user }) => user);
    }
    return items;
  };

  const ToolbarAction = (
    <Button
      className="invite-agent-btn"
      primary
      onClick={() =>
        push(
          !projectId
            ? `/organizations/${organizationId}/groups`
            : `/projects/${projectId}/organizations/${organizationId}/agents/invite`,
        )
      }
    >
      {!projectId ? 'View groups' : 'Invite Agent'}
    </Button>
  );

  return (
    <>
      <Toolbar
        header={{
          title: 'Agents',
          navigation: true,
          rightComponent: (
            <>
              <ToolbarFooter
                onPaginate={onQuery}
                paginationMeta={meta}
                onSearch={onQuery}
                exportCSVData={items}
                exportCSVFileName="agents"
              />
              {ToolbarAction}
            </>
          ),
        }}
      />
      <div className="agents-table">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : items.length === 0 ? (
          <Empty message="No agents found" />
        ) : (
          <Table basic="very" size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Agents</Table.HeaderCell>
                {isSuperAdmin(roles) && (
                  <Table.HeaderCell>Projects</Table.HeaderCell>
                )}
                <Table.HeaderCell>Saving Groups</Table.HeaderCell>
                <Table.HeaderCell>Members</Table.HeaderCell>
                {!isSuperAdmin(roles) && (
                  <>
                    <Table.HeaderCell>
                      Active Saving Groups
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Inactive Saving Groups
                    </Table.HeaderCell>
                  </>
                )}
                {isSuperAdmin(roles) && (
                  <>
                    <Table.HeaderCell>
                      Cashed-Out Commission
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Outstanding Commission
                    </Table.HeaderCell>
                  </>
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {agents().map(
                ({
                  id,
                  first_name: firstName,
                  last_name: lastName,
                  groups_count: groups,
                  members_count: members,
                  active_groups_count: activeGroups,
                  inactive_groups_count: inactiveGroups,
                  projects = [],
                  cashed = 0,
                  outstanding,
                }) => (
                  <Table.Row
                    key={firstName}
                    onClick={() => push(`${pathname}/${id}`)}
                  >
                    <Table.Cell>
                      <div className="agent-name">
                        <Thumbnail
                          avatar={null}
                          name={firstName}
                          secondName={lastName}
                        />
                        <span>{`${firstName || ''} ${lastName ||
                          ''}`}</span>
                      </div>
                    </Table.Cell>
                    {isSuperAdmin(roles) && (
                      <Table.Cell>
                        <Popup
                          trigger={
                            <Link>
                              {projects[0] && projects[0].name}
                              {projects.length > 1 && '..'}
                            </Link>
                          }
                          content={projects.map(project => (
                            <span>{project?.name}, </span>
                          ))}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell>{formatNumber(groups)}</Table.Cell>
                    <Table.Cell>{formatNumber(members)}</Table.Cell>
                    {!isSuperAdmin(roles) && (
                      <>
                        <Table.Cell>
                          {formatNumber(activeGroups)}
                        </Table.Cell>
                        <Table.Cell>
                          {formatNumber(inactiveGroups)}
                        </Table.Cell>
                      </>
                    )}

                    {isSuperAdmin(roles) && (
                      <>
                        <Table.Cell>
                          {formatNumber(cashed)}
                        </Table.Cell>
                        <Table.Cell>
                          {formatNumber(outstanding)}
                        </Table.Cell>
                      </>
                    )}
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  );
};

OrganizationAgents.propTypes = {};

export default OrganizationAgents;
