import {
  WITHDRAWAL_WALLET_ERROR,
  WITHDRAWAL_WALLET_START,
  WITHDRAWAL_WALLET_SUCCESS,
} from 'constants/action-types/wallets/withdrawalWallet';
import { ERROR } from 'constants/notificationStatus';

import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import { getOrgTransactions } from './getOrgRequests';

export default ({ organizationId, walletId, data }) => (
  dispatch,
  setActionType,
) =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/wallets/${walletId}/requests/withdraw`,
      method: 'POST',
      data,
      API_REQUEST_START: WITHDRAWAL_WALLET_START,
      API_REQUEST_SUCCESS: WITHDRAWAL_WALLET_SUCCESS,
      API_REQUEST_FAILURE: WITHDRAWAL_WALLET_ERROR,
      callbackOnSuccess: ({ message }) => {
        notificationHelper({ message });
        getOrgTransactions({ organization_id: organizationId })(
          dispatch,
        );
        setActionType(null);
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: ERROR });
      },
      onEnd: () => false,
    }),
  );
