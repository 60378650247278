import FeatureWrapper from 'containers/Settings/FeatureSettings/FeatureWrapper';

export default {
  exact: true,
  name: 'Feature Settings',
  protected: true,
  path: '/features/:option1?/:option2?',
  component: FeatureWrapper,
  permissions: ['all'],
};
