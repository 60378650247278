import {
  GET_SINGLE_USER_START,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  GET_SINGLE_USER_END,
} from 'constants/action-types/user/getSingleUser';
import {
  HTTP_ACCESS_DENIED,
  HTTP_NOT_FOUND,
} from 'constants/httpStatusCodes';
import { VIEW_USER_DENIED } from 'constants/response-messages/user/getSingleUser';
import apiAction from 'helpers/apiAction';
import { UNEXPECTED_ERROR } from 'constants/response-messages/httpRequests';

const getUserDetails = data => data;

export default (userId, account) => dispatch => {
  const url = account ? '/user' : `/users/${userId}`;

  dispatch(
    apiAction({
      url,
      API_REQUEST_START: GET_SINGLE_USER_START,
      API_REQUEST_END: GET_SINGLE_USER_END,
      onSuccess: ({ data }) => {
        return dispatch({
          type: GET_SINGLE_USER_SUCCESS,
          payload: getUserDetails(data),
        });
      },
      onFailure: error => {
        if (
          error.status === HTTP_NOT_FOUND ||
          Number.isNaN(Number(userId))
        ) {
          return dispatch({
            type: GET_SINGLE_USER_FAILURE,
            payload: `User with id "${userId}" does not exist`,
          });
        }
        if (error.status === HTTP_ACCESS_DENIED) {
          return dispatch({
            type: GET_SINGLE_USER_FAILURE,
            payload: VIEW_USER_DENIED,
          });
        }
        return dispatch({
          type: GET_SINGLE_USER_FAILURE,
          payload: UNEXPECTED_ERROR,
        });
      },
    }),
  );
};
