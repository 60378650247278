import {
  ADD_FEATURE_START,
  ADD_FEATURE_ERROR,
  ADD_FEATURE_SUCCESS,
} from 'constants/action-types/settings/addFeature';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default data => (dispatch, push, setPinModal) => {
  dispatch(
    apiAction({
      url: '/settings',
      method: 'POST',
      data,
      API_REQUEST_START: ADD_FEATURE_START,
      API_REQUEST_FAILURE: ADD_FEATURE_ERROR,
      API_REQUEST_SUCCESS: ADD_FEATURE_SUCCESS,
      onSuccess: payload => {
        dispatch({
          type: ADD_FEATURE_SUCCESS,
          payload,
        });
        setPinModal(false);
        push('/features');
        notification({
          message: 'Setting feature successfully created',
        });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: ADD_FEATURE_ERROR,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          setPinModal(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
};
