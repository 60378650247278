import {
  SINGLE_LOAN_REQUEST_ERROR,
  SINGLE_LOAN_REQUEST_START,
  SINGLE_LOAN_REQUEST_SUCCESS,
} from 'constants/action-types/loanRequests/singleLoanRequest';

export default (state, { type, payload }) => {
  switch (type) {
    case SINGLE_LOAN_REQUEST_START:
      return {
        ...state,
        singleLoanRequest: {
          ...state.singleLoanRequest,
          loading: true,
          error: null,
        },
      };
    case SINGLE_LOAN_REQUEST_ERROR:
      return {
        ...state,
        singleLoanRequest: {
          ...state.singleLoanRequest,
          error: payload,
          loading: false,
        },
      };
    case SINGLE_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        singleLoanRequest: {
          ...state.singleLoanRequest,
          data: payload.data,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
