/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import searchGroup from 'redux/actions/organizations/searchGroup';
import { organizationsDropDownOptions } from 'utils/dropdownOptions';

const GroupSearch = ({
  name,
  handleChange,
  error,
  disabled,
  form,
  organizationId,
  label,
}) => {
  const { items = [], loading } = useSelector(
    ({ organizations: { searchGroup } }) => searchGroup,
  );

  const [value, setValue] = useState('');
  const [typing, setTyping] = useState(false);
  const [addedOptions, setAdddedOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId) {
      searchGroup(organizationId, ' ')(dispatch);
    }
  }, [organizationId]);

  const handleSearchChange = async (e, { searchQuery }) => {
    if (organizationId) return;

    setValue(searchQuery);
    setTyping(true);

    await setTimeout(() => setTyping(false), 2000);

    if (!typing) {
      searchGroup(form.org_name, searchQuery)(dispatch);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      searchGroup(organizationId || form.org_name, value)(dispatch);
    }
  };

  const itemOptions = organizationsDropDownOptions(items, true);

  const options = [...itemOptions, ...addedOptions];

  const handleAddition = (e, { name, value }) => {
    setAdddedOptions([{ text: value, value }]);
    handleChange(e, { name, value });
  };

  return (
    <Form.Field
      control={Dropdown}
      className="dropdown-withID"
      search
      selection
      allowAdditions
      handleAddition={handleAddition}
      error={error}
      loading={loading}
      onSearchChange={handleSearchChange}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      options={options}
      type="text"
      label={label}
      name={name}
      placeholder="Group"
      disabled={disabled}
    />
  );
};

GroupSearch.defaultProps = {
  name: 'group_name',
  disabled: false,
  organizationId: null,
  label: 'Group',
};

GroupSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.instanceOf(Object).isRequired,
  organizationId: PropTypes.number,
  label: PropTypes.string,
};

export default GroupSearch;
