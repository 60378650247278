import {
  REMOVE_FROM_COMMITTEE_ERROR,
  REMOVE_FROM_COMMITTEE_START,
  REMOVE_FROM_COMMITTEE_SUCCESS,
} from 'constants/action-types/organizations/removeFromCommittee';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (groupId, memberIds, userPin) => (
  dispatch,
  onSuccess,
) =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}/members/committee`,
      method: 'PUT',
      data: {
        action: 'remove_from_committee',
        password: userPin,
        members: memberIds,
      },
      API_REQUEST_START: REMOVE_FROM_COMMITTEE_START,
      API_REQUEST_SUCCESS: REMOVE_FROM_COMMITTEE_SUCCESS,
      API_REQUEST_FAILURE: REMOVE_FROM_COMMITTEE_ERROR,
      onSuccess: payload => {
        onSuccess();
        dispatch({
          type: REMOVE_FROM_COMMITTEE_SUCCESS,
          payload,
        });
        notification({
          message: 'Member(s) successfully removed to committee',
        });
      },
      onFailure: ({ message }) => {
        notification({ message, status: 'error' });
        dispatch({
          type: REMOVE_FROM_COMMITTEE_ERROR,
          payload: message,
        });
      },
      onEnd: () => false,
    }),
  );
