import React from 'react';
import './statusTag.scss';
import PropTypes from 'prop-types';
import capitalize from 'utils/capitalize';

const StatusTag = ({ status }) => {
  return (
    <div className={`statusTag ${status}`}>{capitalize(status)}</div>
  );
};

StatusTag.propTypes = {
  status: PropTypes.oneOf([
    'accepted',
    'approved',
    'pending',
    'cancelled',
    'rejected',
    'declined',
    'cleared',
  ]).isRequired,
};

export default StatusTag;
