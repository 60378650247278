import {
  GET_ACTIVITIES,
  GET_ACTIVITY_START,
  GET_ACTIVITY_FAILURE,
} from 'constants/action-types/organizations/activities';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ACTIVITIES:
      return {
        ...state,
        organizationActivities: {
          ...state.organizationActivities,
          items: payload.data || [],
          error: null,
          loading: false,
          fetched: true,
          meta: payload.meta,
        },
      };
    case GET_ACTIVITY_START:
      return {
        ...state,
        organizationActivities: {
          ...state.organizationActivities,
          items: [],
          error: null,
          loading: true,
          fetched: false,
        },
      };
    case GET_ACTIVITY_FAILURE:
      return {
        ...state,
        organizationActivities: {
          ...state.organizationActivities,
          items: [],
          loading: false,
          error: payload,
          fetched: true,
        },
      };
    default:
      return state;
  }
};
