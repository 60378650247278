import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonCard = () => (
  <ContentLoader viewBox="0 0 300 90" height={90} width={300}>
    <circle cx="40.2" cy="43.2" r="31.3" />
    <rect x="109.9" y="29.5" width="125.5" height="9" />
    <rect x="109.9" y="64.7" width="296" height="9" />
    <rect x="109.9" y="97.8" width="253.5" height="9" />
    <rect x="109.9" y="132.3" width="212.5" height="9" />
  </ContentLoader>
);

export default SkeletonCard;
