/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import GroupMemberOverview from 'containers/Organizations/SingleOrganization/Groups/Member';
import OrganizationGroups from './GroupList';
import SingleGroup from './SingleGroup';
import GroupMembers from './Members';
import GroupActivities from './Activities';
import MemberTransactions from './Member/Transactions';
import MemberOverdueLoans from './Member/OverdueLoans';

const Groups = props => {
  const {
    match: {
      params: {
        tabOption,
        tab,
        option,
        option1,
        option2,
        option3,
        projectId,
      },
    },
  } = props;

  return (
    <>
      {!tabOption && !option && <OrganizationGroups {...props} />}

      {tabOption && !option1 && <SingleGroup {...props} />}

      {projectId && tab && option && !option1 && (
        <SingleGroup {...props} />
      )}

      {option1 === 'members' && !option2 && (
        <GroupMembers {...props} />
      )}

      {option3 === 'loans' && <MemberOverdueLoans {...props} />}

      {option2 && !option3 && <GroupMemberOverview {...props} />}

      {option3 === 'transactions' && (
        <MemberTransactions {...props} />
      )}

      {!option2 && option1 === 'activities' && (
        <GroupActivities {...props} />
      )}
    </>
  );
};

Groups.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
};

export default Groups;
