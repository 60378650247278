import numeraljs from 'numeral';
import { startCase, snakeCase } from 'lodash';
import cycleLength from 'constants/cycleLength';
import paginationOptions from 'constants/paginationOptions';
import capitalize, { capitalizeEachWords } from './capitalize';

export const toDropDownOptions = (array, caseSensitive) =>
  array &&
  array.map((value, index) => ({
    key: `${value}${index}`,
    text: capitalize(value.replace(/_/g, ' ')),
    value:
      typeof value === 'string' && !caseSensitive
        ? snakeCase(value)
        : value?.replaceAll(' ', '_'),
  }));

export const toAreaDropDownOptions = (array, caseSensitive) =>
  array &&
  array.map(({ value, text }, index) => ({
    key: `${value}${index}`,
    text: text ? capitalize(text.replace(/_/g, ' ')) : value,
    value:
      typeof value === 'string' && !caseSensitive
        ? value.toLowerCase()
        : value,
  }));

export const genderOptions = toDropDownOptions(['Male', 'Female']);

export const idTypeOptions = toDropDownOptions([
  'National ID',
  'Passport',
  'Refugee ID',
  'Foreign Residence ID',
]);

export const occupationOptions = toDropDownOptions([
  'Students',
  'Farmer',
  'Teacher',
]);

export const maritalStatusOptions = toDropDownOptions([
  'Married',
  'Single',
  'Widowed',
  'Divorced',
]);

export const levelOfEducationOptions = toDropDownOptions([
  'Primary',
  'Secondary',
  'University',
]);

export const userTypes = [
  {
    key: 'User',
    text: 'User',
    value: 'User',
  },
  {
    key: 'Agent',
    text: 'Agent',
    value: 'Agent',
  },
];

export const organizationsDropDownOptions = (
  organizations,
  canHaveId = false,
) => {
  return (
    organizations &&
    organizations.map(({ name, id }) => ({
      key: `${name}${id}`,
      description: canHaveId ? id : '',
      text: name,
      value: id,
    }))
  );
};

export const bulkSmsDropdown = (
  organizations = [],
  canHaveId = false,
) => {
  const formattedOrganizations = organizations?.filter(
    organization => !organization?.name?.includes('All'),
  );
  if (formattedOrganizations.length > 1) {
    formattedOrganizations.unshift({ name: 'All', id: '-' });
  }
  return organizationsDropDownOptions(
    formattedOrganizations,
    canHaveId,
  );
};

export const cycleLengthDropDownOptions = cycleLength.map(
  (value, index) => {
    const years = value.includes('months')
      ? value.includes('years') || value.includes('year')
        ? `${value.split(' ')[0]}.5 Years`
        : `0.5 Years`
      : value;

    return {
      key: `${value}${index}`,
      text: value,
      value: capitalizeEachWords(years),
    };
  },
);

export const pagePackagesOptions = total => {
  const relevantPaginationOptions = paginationOptions.slice(
    0,
    Math.ceil(total / 100),
  );
  return relevantPaginationOptions.map(value => ({
    key: value,
    text: `${value}/Page`,
    value,
  }));
};

export const usersDropDownOptions = users => {
  return (
    users &&
    users.map(
      ({ first_name: firstName, last_name: lastName, id }) => ({
        key: `${firstName}${lastName}${id}`,
        text: `${firstName} ${lastName}`,
        value: parseInt(id, 10),
      }),
    )
  );
};

export const walletDropDownOptions = wallets => {
  return wallets.map(wallet => ({
    key: wallet?.provider_name,
    text: `${startCase(wallet?.provider_name)} (${numeraljs(
      wallet?.float_balance,
    ).format('0,0')} Rwf) `,
    value: wallet?.provider_name,
  }));
};

export const walletTransactionOptions = transactions => {
  return (
    transactions &&
    transactions.map(({ purpose, description }) => ({
      key: purpose,
      text: startCase(description),
      value: purpose,
    }))
  );
};
