import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Input, Icon } from 'semantic-ui-react';
import Proptype from 'prop-types';
import { isArray } from 'lodash';
import Message from 'components/common/Message';
import { toDropDownOptions } from 'utils/dropdownOptions';
import './interestDetails.scss';
import getMicroloansSettingData from 'redux/actions/settings/getMicroloansSettingData';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import labelFormatter from 'helpers/labelFormatter';

const interestTypes = ['fixed', 'internal_plus', 'internal'];

const InterestDetails = ({ type }) => {
  const [form, setForm] = useState({
    interest_rate: [],
  });
  const [openPinModal, setOpenPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');

  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettingsData } }) =>
      microloanSettingsData,
  );
  const { loading: updateLoading, error: updateError } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  useEffect(() => {
    getMicroloansSettingData({
      section: 'Interest',
      type,
    })(dispatch);
  }, []);

  useEffect(() => {
    if (data && isArray(data?.interest_rate)) {
      setForm({
        ...form,
        interest_rate: data.interest_rate?.map((item, index) => ({
          id: index + 1,
          ...item,
        })),
      });
    }
  }, [data]);

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: userPin,
      section: 'Interest',
      type,
      data: {
        interest_rate: form.interest_rate.map(range => ({
          ...range,
          id: undefined,
        })),
        interest_sharing_structure: {
          ...data?.interest_sharing_structure,
          ...(form.interest_sharing_structure || {}),
          independent: {
            ...data?.interest_sharing_structure?.independent,
            ...(form.interest_sharing_structure?.independent || {}),
            agent_share: undefined,
          },
          graduated: {
            ...data?.interest_sharing_structure?.graduated,
            ...(form.interest_sharing_structure?.graduated || {}),
          },
        },
      },
    })(dispatch, () => setOpenPinModal(false));
  };

  const addFormItem = () => {
    setForm({
      ...form,
      interest_rate: [
        ...form?.interest_rate,
        {
          id: form.interest_rate.length + 1,
          range_from: '',
          range_to: '',
          type: '',
          interest: '',
        },
      ],
    });
  };

  const removeFormItem = id => {
    setForm({
      ...form,
      interest_rate: form?.interest_rate?.filter(
        item => item.id !== id,
      ),
    });
  };

  return (
    <>
      <ConfirmPinModal
        content={`Are you sure you want to update the settings for ${labelFormatter(
          type,
        )}`}
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        userPin={userPin}
        setUserPin={setUserPin}
        error={{ message: updateError }}
        loading={updateLoading}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
      />
      <Form
        onSubmit={() => setOpenPinModal(!openPinModal)}
        loading={loading}
        className="microloans-interest-details"
      >
        {!loading && error && (
          <Message message={error.message} color="red" />
        )}

        {data?.interest_rate &&
          form?.interest_rate?.map((item, index) => (
            <div
              widths="equal"
              className="microloans-interest-details__item"
              key={item.id}
            >
              <div className="microloans-interest-details__item__left">
                <div className="microloans-interest-details__item__range-label">
                  <span>Internal interest range (%)</span>
                </div>
                <Form.Group
                  widths="equal"
                  className="microloans-interest-details__item__range-input"
                >
                  <Input
                    control={Input}
                    type="number"
                    placeholder="19"
                    name="interest_rate.range_from"
                    onChange={(_, { value }) => {
                      setForm({
                        ...form,
                        interest_rate: form?.interest_rate?.map(
                          formItem => {
                            if (formItem.id === item.id) {
                              return {
                                ...formItem,
                                range_from: value,
                              };
                            }
                            return formItem;
                          },
                        ),
                      });
                    }}
                    defaultValue={item.range_from}
                  />

                  <span className="input-separator" />

                  <Input
                    control={Input}
                    type="number"
                    placeholder="19"
                    onChange={(_, { value }) => {
                      setForm({
                        ...form,
                        interest_rate: form?.interest_rate?.map(
                          formItem => {
                            if (formItem.id === item.id) {
                              return {
                                ...formItem,
                                range_to: value,
                              };
                            }
                            return formItem;
                          },
                        ),
                      });
                    }}
                    name="interest_rate.range_to"
                    defaultValue={item.range_to}
                  />
                </Form.Group>
              </div>

              {item && (
                <div className="microloans-interest-details__item__right">
                  <Form.Group
                    widths="equal"
                    className="microloans-interest-details__item__range-input"
                  >
                    {data?.interest_rate && (
                      <Form.Dropdown
                        fluid
                        selection
                        name="interest_rate.type"
                        label="Interest type"
                        placeholder="-- Select --"
                        options={toDropDownOptions(interestTypes)}
                        onChange={(_, { value }) => {
                          setForm({
                            ...form,
                            interest_rate: form?.interest_rate?.map(
                              formItem => {
                                if (formItem.id === item.id) {
                                  return {
                                    ...formItem,
                                    type: value,
                                    interest:
                                      value === 'internal'
                                        ? undefined
                                        : formItem.interest,
                                  };
                                }
                                return formItem;
                              },
                            ),
                          });
                        }}
                        defaultValue={item.type}
                      />
                    )}
                    {data?.interest_rate && (
                      <Form.Field
                        control={Input}
                        type="number"
                        placeholder=""
                        name="interest_rate.interest"
                        label="Interest (%)"
                        disabled={item.type === 'internal'}
                        value={
                          item.type === 'internal'
                            ? undefined
                            : item.interest
                        }
                        onChange={(_, { value }) => {
                          setForm({
                            ...form,
                            interest_rate: form?.interest_rate?.map(
                              formItem => {
                                if (formItem.id === item.id) {
                                  return {
                                    ...formItem,
                                    interest: value,
                                  };
                                }
                                return formItem;
                              },
                            ),
                          });
                        }}
                        defaultValue={item.interest}
                      />
                    )}
                  </Form.Group>
                </div>
              )}
              <div className="microloans-interest-details__item__icons">
                {index === 0 || (
                  <Icon
                    name="minus"
                    className="cursor"
                    onClick={() => removeFormItem(item.id)}
                  />
                )}
                {index !== 0 || (
                  <Icon
                    name="plus"
                    className="cursor"
                    onClick={() => addFormItem()}
                  />
                )}
              </div>
            </div>
          ))}
        <h5>Interest sharing Structure</h5>
        <h5>Independent groups</h5>
        {data?.interest_sharing_structure?.independent && (
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              label="SAVE Share (%)"
              placeholder="19"
              onChange={(_, { value }) => {
                setForm({
                  ...form,
                  interest_sharing_structure: {
                    ...(form.interest_sharing_structure || {}),
                    independent: {
                      ...(form.interest_sharing_structure
                        ?.independent || {}),
                      save_share: value,
                    },
                  },
                });
              }}
              name="interest_sharing_structure.independent.save_share"
              defaultValue={
                data?.interest_sharing_structure?.independent
                  ?.save_share
              }
            />

            <Form.Field
              control={Input}
              type="number"
              label="Group Share (%)"
              placeholder="19"
              onChange={(_, { value }) => {
                setForm({
                  ...form,
                  interest_sharing_structure: {
                    ...(form.interest_sharing_structure || {}),
                    independent: {
                      ...(form.interest_sharing_structure
                        ?.independent || {}),
                      group_share: value,
                    },
                  },
                });
              }}
              name="interest_sharing_structure.independent.group_share"
              defaultValue={
                data?.interest_sharing_structure?.independent
                  ?.group_share
              }
            />
          </Form.Group>
        )}

        <h5>Graduated groups</h5>
        {data?.interest_sharing_structure?.graduated && (
          <>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="SAVE Share (%)"
                placeholder="19"
                onChange={(_, { value }) => {
                  setForm({
                    ...form,
                    interest_sharing_structure: {
                      ...(form.interest_sharing_structure || {}),
                      graduated: {
                        ...(form.interest_sharing_structure
                          ?.graduated || {}),
                        save_share: value,
                      },
                    },
                  });
                }}
                name="interest_sharing_structure.graduated.save_share"
                defaultValue={
                  data?.interest_sharing_structure?.graduated
                    ?.save_share
                }
              />

              <Form.Field
                control={Input}
                type="number"
                label="Group Share (%)"
                placeholder="19"
                onChange={(_, { value }) => {
                  setForm({
                    ...form,
                    interest_sharing_structure: {
                      ...(form.interest_sharing_structure || {}),
                      graduated: {
                        ...(form.interest_sharing_structure
                          ?.graduated || {}),
                        group_share: value,
                      },
                    },
                  });
                }}
                name="interest_sharing_structure.graduated.group_share"
                defaultValue={
                  data?.interest_sharing_structure?.graduated
                    ?.group_share
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="Agent Share (%)"
                placeholder="19"
                onChange={(_, { value }) => {
                  setForm({
                    ...form,
                    interest_sharing_structure: {
                      ...(form.interest_sharing_structure || {}),
                      graduated: {
                        ...(form.interest_sharing_structure
                          ?.graduated || {}),
                        agent_share: value,
                      },
                    },
                  });
                }}
                name="interest_sharing_structure.graduated.agent_share"
                defaultValue={
                  data?.interest_sharing_structure?.graduated
                    ?.agent_share
                }
              />
            </Form.Group>
          </>
        )}

        <div className="action">
          <Button
            type="submit"
            primary
            loading={updateLoading}
            disabled={updateLoading || Object.keys(form).length === 0}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
InterestDetails.propTypes = {
  type: Proptype.string.isRequired,
};

export default InterestDetails;
