import initialState from 'redux/initial-states/wallets';
import getWallets from './getWallets';
import creditAgent from './creditAgent';
import transactions from './getTransactions';
import topUpWallet from './topUpWallet';
import getOrgTopupRequests from './getOrgTopupRequests';
import approvalOrgRequests from './approveOrgRequests';
import getOrgGroupRequests from './getOrgGroupRequests';
import approveOrgGroupRequests from './approveOrgGroupRequests';
import getOrgGroupLoanHistory from './getOrgGroupLoanHistory';
import withdrawalWallet from './withdrawalWallet';

export default (state = initialState, action = {}) => ({
  ...state,
  ...getWallets(state, action),
  ...creditAgent(state, action),
  ...transactions(state, action),
  ...topUpWallet(state, action),
  ...getOrgTopupRequests(state, action),
  ...approvalOrgRequests(state, action),
  ...getOrgGroupRequests(state, action),
  ...approveOrgGroupRequests(state, action),
  ...getOrgGroupLoanHistory(state, action),
  ...withdrawalWallet(state, action),
});
