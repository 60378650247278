import {
  ADD_ADVERTISEMENT_START,
  ADD_ADVERTISEMENT_ERROR,
  ADD_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/addAdvertisment';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import getAllAdvertisements from './getAllAdvertisement';

export default data => (dispatch, callback) => {
  dispatch(
    apiAction({
      url: '/advertisements',
      method: 'POST',
      data,
      API_REQUEST_START: ADD_ADVERTISEMENT_START,
      API_REQUEST_FAILURE: ADD_ADVERTISEMENT_ERROR,
      API_REQUEST_SUCCESS: ADD_ADVERTISEMENT_SUCCESS,

      callbackOnSuccess: () => {
        notificationHelper({
          message: 'Advertisment successfully created',
        });
        getAllAdvertisements()(dispatch);
        callback?.();
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};
