import { useSelector } from 'react-redux';

export default () => {
  const { details } = useSelector(
    ({ dashboard: { currentOrganization } }) => currentOrganization,
  );
  return ['save_country', 'save_global'].includes(details?.save_type);
};

export const isGlobalOrganization = () => {
  const { details } = useSelector(
    ({ dashboard: { currentOrganization } }) => currentOrganization,
  );
  return details?.save_type === 'save_global';
};

export const isCountryOrganization = () => {
  const { details } = useSelector(
    ({ dashboard: { currentOrganization } }) => currentOrganization,
  );
  return details?.save_type === 'save_country';
};
