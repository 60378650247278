import {
  UPDATE_SINGLE_ORGANIZATION_START,
  UPDATE_SINGLE_ORGANIZATION_SUCCESS,
  UPDATE_SINGLE_ORGANIZATION_FAILURE,
  UPDATE_SINGLE_ORGANIZATION_END,
  UPDATE_SINGLE_ORGANIZATION_CLEAR,
} from 'constants/action-types/organizations/updateSingleOrganization';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_SINGLE_ORGANIZATION_START:
      return {
        ...state,
        updateSingleOrganization: {
          ...state.updateSingleOrganization,
          loading: true,
          error: null,
          message: null,
        },
      };
    case UPDATE_SINGLE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        currentOrganization: payload.organization,
        updateSingleOrganization: {
          ...state.updateSingleOrganization,
          message: payload.message,
          error: null,
        },
      };
    case UPDATE_SINGLE_ORGANIZATION_FAILURE:
      return {
        ...state,
        updateSingleOrganization: {
          ...state.updateSingleOrganization,
          error: payload,
          message: null,
        },
      };
    case UPDATE_SINGLE_ORGANIZATION_END:
      return {
        ...state,
        updateSingleOrganization: {
          ...state.updateSingleOrganization,
          loading: false,
        },
      };
    case UPDATE_SINGLE_ORGANIZATION_CLEAR:
      return {
        ...state,
        updateSingleOrganization: {
          ...state.updateSingleOrganization,
          message: null,
          error: null,
        },
      };
    default:
      return null;
  }
};
