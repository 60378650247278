import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Button, Form } from 'semantic-ui-react';
import { capitalize, keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import currencyFormat from 'utils/currencyFormat';
import { isFinanceRwandaAdmin } from 'helpers/checkRoles';
import notify from 'helpers/notificationHelper';
import { WARN } from 'constants/notificationStatus';
import MyDropzone from 'components/common/DragAndDrop';
import PINInput from 'components/common/PINInput';
import processWithdrawal from 'redux/actions/wallets/processWithdrawal';

const Withdrawal = ({
  originator,
  recipient,
  amount,
  comment,
  roles,
  setShowReceipt,
  recipientBankAccount,
  transactionId: requestId,
  org_wallet_id: walletId,
  organizationId,
  status,
}) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState({});
  const formData = new FormData();
  const dispatch = useDispatch();

  const { loading } = useSelector(
    ({ wallets: { processWithdrawal } }) => processWithdrawal,
  );

  const canProcess =
    isFinanceRwandaAdmin(roles) && status === 'pending';

  const handleChange = (e, { name, value }) =>
    setData({ ...data, [name]: value });

  const handleSubmit = () => {
    if (file) {
      const isLessThan3MBs = file.size / 1024 / 1024 > 3;

      if (isLessThan3MBs)
        return notify({
          message: 'File should not exceed 3 MBs',
          status: WARN,
        });
      keys(data)?.map(key => formData.append(key, data[key]));
      formData.append('withdrawattachment', file);
    }
    processWithdrawal({
      organizationId,
      walletId,
      requestId,
      data: formData,
    })(dispatch, setShowReceipt);
  };

  return (
    <>
      <div className="withdrawal" id="org-withdrawal">
        <div className="withdrawal__item">
          <span className="withdrawal__item--title">
            Originator/Creditor:
          </span>
          {originator}
        </div>
        <div className="withdrawal__item">
          <span className="withdrawal__item--title">
            Recipient bank:
          </span>
          {capitalize(recipient)}
        </div>
        <div className="withdrawal__item">
          <span className="withdrawal__item--title">
            Recipient account:
          </span>
          {recipientBankAccount}
        </div>
        <div className="withdrawal__item">
          <span className="withdrawal__item--title">Amount:</span>
          {currencyFormat(amount)}{' '}
        </div>
        <div className="withdrawal__item">
          <span className="withdrawal__item--title">
            Description:
          </span>
          {comment}
          {canProcess && (
            <div className="withdrawal__item--drop">
              <MyDropzone
                label="New Advertisement"
                setFile={setFile}
                file={file}
                accept="image/*, .pdf"
                showPreview={false}
              />
            </div>
          )}
        </div>
        {canProcess && (
          <div className="withdrawal__item">
            <span className="withdrawal__item--title">
              Provide PIN to confirm
            </span>
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <PINInput
                  name="password"
                  fluid
                  required
                  maxLength={5}
                  onInputChange={handleChange}
                />
              </Form.Field>
              <Button
                disabled={!data.password}
                className="withdrawal__item--button"
                primary
                floated="right"
                size="medium"
                content="Process"
                type="submit"
                loading={loading}
              />
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

Withdrawal.propTypes = {
  originator: PropTypes.string,
  recipient: PropTypes.string,
  amount: PropTypes.number,
  roles: PropTypes.instanceOf(Array),
  setShowReceipt: PropTypes.func.isRequired,
  comment: PropTypes.string,
  recipientBankAccount: PropTypes.number,
  transactionId: PropTypes.number,
  org_wallet_id: PropTypes.number,
  organizationId: PropTypes.number,
  status: PropTypes.string,
};

Withdrawal.defaultProps = {
  originator: '',
  recipient: '',
  amount: '',
  roles: [],
  comment: '',
  recipientBankAccount: '',
  transactionId: 0,
  org_wallet_id: 0,
  organizationId: 0,
  status: '',
};

export default Withdrawal;
