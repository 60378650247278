import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import PINInput from 'components/common/PINInput';
import { approveOrgGroupRequests } from 'redux/actions/wallets/approvalOrgRequests';
import notify from 'helpers/notificationHelper';
import isOrgRequestFormValid from 'helpers/validators/validateOrgRequestForm';
import { compactObject } from 'helpers/removeEmptyProperties';

const DeclineRequestForm = ({
  organizationId,
  loadingGroupRequest,
  action,
  setAction,
  errorOrgGroupRequest,
  request,
  groupId,
  context,
}) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});

  const setError = context => {
    let errorMessage = '';
    switch (context) {
      case 'comment':
        errorMessage =
          'Description must be between 10 and 120 characters';
        break;

      default:
        break;
    }
    setFormError({ ...formError, [context]: errorMessage });
  };

  const resetError = context =>
    setFormError({ ...formError, [context]: null });

  const handleChange = (_, { name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = ({ currentTarget: { name, value } }) => {
    if (name === 'comment') {
      if (value.length < 10 || value.length > 120)
        return setError('comment');
      return resetError('comment');
    }
  };

  const handleApproval = () => {
    approveOrgGroupRequests({
      organizationId,
      requestId: request.id,
      action,
      data: form,
      groupId,
      context,
    })(dispatch)(setAction, notify);
  };

  return (
    <Form onSubmit={handleApproval} loading={loadingGroupRequest}>
      <Form.TextArea
        required
        placeholder="Provide a reason for declining the loan request..."
        className="textarea"
        name="comment"
        value={form.comment}
        onChange={handleChange}
        label="Description"
        min="10"
        max="120"
        error={formError.comment}
        onBlur={handleBlur}
      />

      <PINInput
        label="PIN"
        required
        fluid
        placeholder="PIN"
        name="password"
        className="confirm-transaction-input"
        value={form.password}
        onChange={handleChange}
      />

      {errorOrgGroupRequest && (
        <p color="red">
          <Message negative>{errorOrgGroupRequest?.message}</Message>
        </p>
      )}
      <Button
        type="submit"
        content={
          action === 'amend'
            ? 'Save & notify group committee'
            : 'Submit'
        }
        color="blue"
        fluid
        disabled={
          !isOrgRequestFormValid(action, form) ||
          !isEmpty(compactObject(formError))
        }
      />
    </Form>
  );
};

DeclineRequestForm.propTypes = {
  organizationId: PropTypes.number,
  loadingGroupRequest: PropTypes.bool,
  action: PropTypes.string,
  setAction: PropTypes.func,
  request: PropTypes.object,
  context: PropTypes.string,
  errorOrgGroupRequest: PropTypes.instanceOf(Object),
  groupId: PropTypes.number,
};

DeclineRequestForm.defaultProps = {
  setAction: () => null,
  loadingGroupRequest: false,
  action: '',
  request: {},
  organizationId: '',
  context: '',
  errorOrgGroupRequest: {},
  groupId: 0,
};

export default DeclineRequestForm;
