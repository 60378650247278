import {
  GET_MEMBER_OVERDUE_LOANS_SUCCESS,
  GET_MEMBER_OVERDUE_LOANS_START,
  GET_MEMBER_OVERDUE_LOANS_ERROR,
} from 'constants/action-types/groups/getMemberOverdueLoans';
import apiAction from 'helpers/apiAction';

export default (groupId, memberId, data) => dispatch => {
  dispatch(
    apiAction({
      url: `/groups/${groupId}/members/${memberId}/loans_overdue_interests`,
      method: 'GET',
      data,
      API_REQUEST_START: GET_MEMBER_OVERDUE_LOANS_START,
      API_REQUEST_SUCCESS: GET_MEMBER_OVERDUE_LOANS_SUCCESS,
      API_REQUEST_FAILURE: GET_MEMBER_OVERDUE_LOANS_ERROR,
      onSuccess: ({ data, meta }) => {
        dispatch({
          type: GET_MEMBER_OVERDUE_LOANS_SUCCESS,
          payload: {
            data,
            meta,
          },
        });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: GET_MEMBER_OVERDUE_LOANS_ERROR,
          payload: message,
        });
      },
      onEnd: () => false,
    }),
  );
};
