export default ({ name, regex = '/', isSearch = false }) => {
  let paths = [];
  paths = window.location.pathname.split(regex).filter(Boolean);

  if (isSearch) {
    paths = window.location.search.split(regex).filter(Boolean);
  }

  return [name].every(path => paths.includes(path));
};
