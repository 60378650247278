import getCurrentOrg from './getCurrentOrg';

export const isBusinessOrganization = organization => {
  const currentUserOrganization = getCurrentOrg();

  if (organization) {
    return organization?.type === 'Business ORG';
  }

  return currentUserOrganization
    ? currentUserOrganization?.type === 'Business ORG'
    : undefined;
};

export const isOrgNGO = organization => {
  const currentUserOrganization = getCurrentOrg();

  if (organization) {
    return organization?.type === 'NGO';
  }

  return currentUserOrganization?.type === 'NGO';
};
