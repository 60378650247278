import Activity from 'containers/Activity';

export default {
  exact: true,
  name: 'Activities',
  protected: true,
  path: '/activities',
  component: Activity,
  permissions: ['all'],
};
