import PropTypes from 'prop-types';

export const TabRender = ({ routes, pathname }) => {
  const { component } = routes.find(({ path }) =>
    new RegExp(path).test(pathname),
  );

  return component;
};

TabRender.propTypes = {
  routes: PropTypes.instanceOf(Array).isRequired,
  pathname: PropTypes.string.isRequired,
};
