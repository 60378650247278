export default [
  {
    value: 'NGO',
    text: 'NGO',
  },
  {
    value: 'Business ORG',
    text: 'Business Organization',
  },
];

export const organizationCategory = {
  NGO: [
    {
      text: 'Local',
      value: 'Local',
    },
    {
      text: 'International',
      value: 'International',
    },
  ],
  'Business ORG': [
    {
      text: 'Financial Service Provider (FSP)',
      value: 'FSP',
    },
  ],
};
