import React from 'react';
import { useDispatch } from 'react-redux';
import AppSideBar from 'components/common/SideBar';
import logout from 'redux/actions/user/logout';

const AppSideBarContainer = () => {
  const dispatch = useDispatch();
  const onLogout = () => {
    logout()(dispatch);
  };
  return <AppSideBar onLogout={onLogout} />;
};

export default AppSideBarContainer;
