import {
  GET_ALL_FEATURES_ERROR,
  GET_ALL_FEATURES_START,
  GET_ALL_FEATURES_SUCCESS,
} from 'constants/action-types/settings/getAllFeatures';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ALL_FEATURES_START:
      return {
        ...state,
        allFeatures: {
          loading: true,
          error: null,
          data: [],
          meta: {},
        },
      };
    case GET_ALL_FEATURES_ERROR:
      return {
        ...state,
        allFeatures: {
          ...state.allFeatures,
          error: payload,
          loading: false,
        },
      };
    case GET_ALL_FEATURES_SUCCESS:
      return {
        ...state,
        allFeatures: {
          ...state.allFeatures,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    default:
      return null;
  }
};
