import {
  PENDING_LOAN_REQUESTS_ERROR,
  PENDING_LOAN_REQUESTS_START,
  PENDING_LOAN_REQUESTS_SUCCESS,
} from 'constants/action-types/loanRequests/pendingLoanRequests';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default queries => dispatch =>
  dispatch(
    apiAction({
      url: `/groups/microloans/requests`,
      method: 'GET',
      queries,
      API_REQUEST_START: PENDING_LOAN_REQUESTS_START,
      API_REQUEST_SUCCESS: PENDING_LOAN_REQUESTS_SUCCESS,
      API_REQUEST_FAILURE: PENDING_LOAN_REQUESTS_ERROR,
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
