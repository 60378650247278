import {
  GET_ORGANIZATION_AGENTS_ERROR,
  GET_ORGANIZATION_AGENTS_START,
  GET_ORGANIZATION_AGENTS_SUCCESS,
} from 'constants/action-types/organizations/getAgents';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (organizationId, projectId, queries) => dispatch => {
  const url = projectId
    ? `/organizations/${organizationId}/projects/${projectId}/agents`
    : `/organizations/${organizationId}/agents`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_AGENTS_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_AGENTS_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_AGENTS_ERROR,
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
};
