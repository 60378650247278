/* eslint jsx-a11y/label-has-associated-control: "off" */
import React, { useEffect, useState, useMemo } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { cycleLengthDropDownOptions } from 'utils/dropdownOptions';
import isCycleExpired from 'helpers/isCycleExpired';
import updateGroup from 'redux/actions/organizations/updateGroup';
import Message from 'components/common/Message';
import Empty from 'components/common/Empty';

const CycleDetails = () => {
  const [form, setForm] = useState();
  const dispatch = useDispatch();
  const [cycleEndDate, setCycleEndDate] = useState('');
  const { group } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup?.item,
  );

  const { isSupportAdmin } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const currentCycle = useMemo(
    () => group?.cycles?.find(cycle => cycle.id === group?.cycle_id),
    [group?.cycle_id],
  );

  useEffect(() => {
    setForm({
      ...form,
      cycle_start_date: moment(currentCycle?.start_date).format(
        'YYYY-MM-DD',
      ),
    });
  }, []);

  const handleChange = (_, { name, value }) => {
    if (name === 'cycle')
      setCycleEndDate(
        moment(form?.cycle_start_date || currentCycle?.start_date)
          .add(value.split(' ')[0], 'year')
          .format('YYYY-MM-DD'),
      );
    if (name === 'cycle_start_date')
      setCycleEndDate(
        moment(value)
          .add(
            (form?.cycle || currentCycle?.cycle).split(' ')[0],
            'year',
          )
          .format('YYYY-MM-DD'),
      );
    setForm({ ...form, [name]: value });
  };

  const { loading, error } = useSelector(
    ({ organizations: { updateGroup } }) => updateGroup,
  );

  const handleSubmit = () => {
    updateGroup(group?.id, form)(dispatch);
  };

  const isDisabled = cycleStatus => {
    return (
      (!isSupportAdmin &&
        cycleStatus === currentCycle?.status &&
        !isCycleExpired(currentCycle?.end_date)) ||
      group?.status === 'active'
    );
  };

  useEffect(() => {
    if (currentCycle?.end_date)
      setCycleEndDate(
        moment(currentCycle?.end_date).format('YYYY-MM-DD'),
      );
  }, [currentCycle?.end_date]);

  return (
    <>
      {!currentCycle && <Empty title="No active cycle" smallMargin />}
      {currentCycle && (
        <Form onSubmit={handleSubmit}>
          {!loading && error && <Message message="" color="red" />}
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Cycle Start Date"
              name="cycle_start_date"
              type="date"
              placeholder="Cycle Start Date"
              onChange={handleChange}
              disabled={isDisabled('active')}
              defaultValue={moment(currentCycle?.start_date).format(
                'YYYY-MM-DD',
              )}
            />
            <Form.Select
              fluid
              label="Cycle Length"
              name="cycle"
              options={cycleLengthDropDownOptions}
              placeholder="Cycle Length"
              disabled={isDisabled()}
              onChange={handleChange}
              defaultValue={currentCycle?.cycle}
            />
          </Form.Group>
          <Form.Input
            fluid
            label="Cycle End Date"
            name="cycle_end_date"
            type="date"
            placeholder="Cycle End Date"
            onChange={handleChange}
            max
            disabled
            defaultValue={cycleEndDate}
            value={cycleEndDate}
          />
          <div className="action">
            <Button type="submit" primary loading={loading}>
              Save
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default CycleDetails;
