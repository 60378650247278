import initialState from 'redux/initial-states/activities';
import getActivities from 'redux/reducers/activities/getOrganizationActivities';
import cancelMemberRequest from './cancelMemberRequest';
import getGroupActivities from './getGroupActivities';
import reApproveMemberRequest from './reApproveMemberRequest';

export default (state = initialState, action) => ({
  ...state,
  ...getActivities(state, action),
  ...getGroupActivities(state, action),
  ...cancelMemberRequest(state, action),
  ...reApproveMemberRequest(state, action),
});
