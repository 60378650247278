import {
  DEACTIVATE_FEATURES_IN_BULK_ERROR,
  DEACTIVATE_FEATURES_IN_BULK_START,
  DEACTIVATE_FEATURES_IN_BULK_SUCCESS,
} from 'constants/action-types/settings/deactivateFeatureBulk';

export default (state, { type, payload }) => {
  switch (type) {
    case DEACTIVATE_FEATURES_IN_BULK_START:
      return {
        ...state,
        deactivateFeaturesBulk: {
          ...state.deactivateFeaturesBulk,
          loading: true,
          message: null,
          error: {},
        },
      };
    case DEACTIVATE_FEATURES_IN_BULK_ERROR:
      return {
        ...state,
        deactivateFeaturesBulk: {
          ...state.deactivateFeaturesBulk,
          error: payload,
          loading: false,
        },
      };
    case DEACTIVATE_FEATURES_IN_BULK_SUCCESS:
      return {
        ...state,
        deactivateFeaturesBulk: {
          ...state.deactivateFeaturesBulk,
          message: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
