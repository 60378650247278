/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import phoneUtils from '@exuus/rwanda-phone-utils';
import abName from 'utils/abName';
import randomColor from 'utils/randomColor';
import Toolbar from 'components/common/Toolbar';
import { ACTIVITY_ORGANIZATION_LIMIT } from 'constants/pagination';
import Loader from 'components/common/PageLoader';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import formatNumber from 'helpers/formatNumber';

const ListOrganizations = ({
  history: { push },
  items,
  loading,
  meta,
  all,
  onSearch,
  onPaginate,
}) => {
  const { t } = useTranslation();

  const organizationItems = !all
    ? [...items].splice(0, ACTIVITY_ORGANIZATION_LIMIT)
    : items;

  const { pathname = '' } = useLocation();
  const organizationsCount = /\/organizations/g.test(pathname)
    ? `(${meta ? formatNumber(meta.total || 0) : ''})`
    : '';

  return (
    <section
      className={`list-organizations ${!all ? 'not-all' : ''}`}
    >
      <Toolbar
        header={{
          title: `Organizations ${organizationsCount}`,
          rightComponent: all ? (
            <ToolbarFooter
              onPaginate={onPaginate}
              paginationMeta={meta}
              onSearch={onSearch}
              exportCSVData={organizationItems}
              exportCSVFileName="organizations"
            />
          ) : (
            <Button onClick={() => push('/organizations')}>
              {t('View all')}
            </Button>
          ),
        }}
        ignoreHelmet={!all}
      />

      {loading ? (
        <Loader />
      ) : Array.isArray(items) && items.length === 0 ? (
        <Empty message="No organizations" icon="info circle" />
      ) : (
        <div className="list-organizations-items scroll-wrapper">
          {organizationItems.map(
            ({
              id,
              name,
              type,
              category,
              phone_number: phoneNumber,
              email,
            }) => (
              <div
                className="list-organizations-item"
                key={id}
                tabIndex="-1"
                onKeyDown={null}
                role="button"
                onClick={() => push(`organizations/${id}`)}
              >
                <div
                  className="thumbnail"
                  style={{ backgroundColor: randomColor() }}
                >
                  <span>{abName(name)}</span>
                </div>

                <div className="details">
                  <div className="info">
                    <div className="name">{name}</div>
                    <div className="contact">
                      {!all && (
                        <span>{`${(type || '').replace('_', ' ')} ${(
                          category || ''
                        ).replace('_', ' ')}`}</span>
                      )}

                      {all && (
                        <div>
                          <Icon name="phone" />
                          <span>
                            {phoneUtils(phoneNumber).format('space')}
                          </span>
                        </div>
                      )}

                      {all && (
                        <div>
                          <Icon name="at" />
                          <span>{email}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  {all && (
                    <span>{`${(type || '').replace('_', ' ')} ${(
                      category || ''
                    ).replace('_', ' ')}`}</span>
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      )}
    </section>
  );
};

ListOrganizations.defaultProps = {
  all: false,
  meta: {
    page: 0,
    pages: 0,
    total: 0,
  },
  history: {},
  onSearch: () => {},
  onPaginate: () => {},
};

ListOrganizations.propTypes = {
  history: PropTypes.shape(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  all: PropTypes.bool,
  onSearch: PropTypes.func,
  onPaginate: PropTypes.func,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
    total: PropTypes.number,
  }),
};

export default ListOrganizations;
