import {
  GET_PARTNER_FAILED,
  GET_PARTNER_START,
  GET_PARTNER_SUCCESS,
} from 'constants/action-types/projects/getPartner';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_PARTNER_START:
      return {
        ...state,
        projectPartner: {
          ...state.projectPartner,
          loading: true,
          error: null,
        },
      };
    case GET_PARTNER_FAILED:
      return {
        ...state,
        projectPartner: {
          ...state.projectPartner,
          error: payload,
          loading: false,
        },
      };
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        projectPartner: {
          ...state.projectPartner,
          data: payload.data,
          loading: false,
        },
      };
    default:
      return null;
  }
};
