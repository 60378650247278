/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams } from 'react-router-dom';
import InviteAgent from 'containers/Organizations/SingleOrganization/Agents/Invite';
import ProjectOverview from 'containers/Organizations/SingleOrganization/Projects/SingleProject/Overview';

const SingleProject = props => {
  const { tabOption, option1 } = useParams();

  return (
    <>
      {option1 === 'invite-agent' ? (
        <InviteAgent {...{ ...props, tabOption }} />
      ) : (
        <ProjectOverview {...props} />
      )}
    </>
  );
};

export default SingleProject;
