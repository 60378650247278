import moment from 'moment';
import phoneUtils from '@exuus/rwanda-phone-utils';
import numeral from 'numeral';

export default member => {
  return [
    {
      icon: 'phone',
      label: 'Phone Number:',
      content: phoneUtils(member?.phone_number).format('space'),
    },
    {
      icon: 'users',
      label: 'total groups:',
      content: member?.groups_total,
      visible: member?.groups_total !== undefined,
    },
    {
      icon: 'clock outline',
      label: 'joined:',
      content: moment(member?.created_at).format('DD MMMM YYYY'),
    },
    {
      icon: 'money',
      label: 'current savings:',
      content: `${numeral(member?.savings_balance).format(
        '0,0',
      )} RWF`,
      visible: member?.savings_balance !== undefined,
    },
    {
      icon: 'money',
      label: 'outstanding loans:',
      content: `${numeral(member?.loans_balance).format('0,0')} RWF`,
      visible: member?.loans_balance !== undefined,
    },
    {
      icon: 'money',
      label: 'social fund balance:',
      content: `${numeral(member?.social_funds_balance).format(
        '0,0',
      )} RWF`,
      visible: member?.social_funds_balance !== undefined,
    },
    {
      icon: 'money',
      label: 'cumulative savings:',
      content: `${numeral(member?.savings_total).format('0,0')} RWF`,
      visible: member?.savings_total !== undefined,
    },
    {
      icon: 'money',
      label: 'cumulative loans:',
      content: `${numeral(member?.loans_total).format('0,0')} RWF`,
      visible: member?.loans_total !== undefined,
    },
    {
      icon: 'money',
      label: 'cumulative social fund:',
      content: `${numeral(member?.social_funds_total).format(
        '0,0',
      )} RWF`,
      visible: member?.social_funds_total !== undefined,
    },
  ];
};
