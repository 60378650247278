import {
  CREATE_PROJECT_FAILED,
  CREATE_PROJECT_START,
  CREATE_PROJECT_SUCCESS,
} from 'constants/action-types/projects/createProject';
import getProjects from 'redux/actions/projects/listProjects';

import apiAction from 'helpers/apiAction';
import notify from 'helpers/notificationHelper';

export default (organizationId, body) => dispatch => push => {
  const data = {
    ...body,
    organization: undefined,
  };

  const onSuccess = ({ message, data: { id: projectId } }) => {
    notify({
      message,
    });

    dispatch({
      type: CREATE_PROJECT_SUCCESS,
    });

    getProjects(organizationId)(dispatch);

    push(
      `/projects/${projectId}/organizations/${organizationId}/overview`,
    );
  };

  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects`,
      method: 'POST',
      data,
      API_REQUEST_START: CREATE_PROJECT_START,
      API_REQUEST_SUCCESS: CREATE_PROJECT_SUCCESS,
      API_REQUEST_FAILURE: CREATE_PROJECT_FAILED,
      onSuccess,
      onEnd: () => false,
    }),
  );
};
