import {
  GET_ORG_LOAN_HISTORY_ERROR,
  GET_ORG_LOAN_HISTORY_START,
  GET_ORG_LOAN_HISTORY_SUCCESS,
} from 'constants/action-types/wallets/getOrgLoanHistory';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ORG_LOAN_HISTORY_START:
      return {
        ...state,
        orgGroupLoanHistory: {
          ...state.orgGroupLoanHistory,
          message: null,
          loading: true,
          error: null,
        },
      };
    case GET_ORG_LOAN_HISTORY_ERROR:
      return {
        ...state,
        orgGroupLoanHistory: {
          ...state.orgGroupLoanHistory,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case GET_ORG_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        orgGroupLoanHistory: {
          ...state.orgGroupLoanHistory,
          items: payload.records,
          meta: payload.meta,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
