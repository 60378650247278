import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { capitalizeEachWords } from 'utils/capitalize';
import currencyFormat from 'utils/currencyFormat';

const CommissionPopup = ({ commissions }) => {
  return (
    <Popup
      trigger={<Icon name="info circle" color="blue" />}
      content={
        <div className="commissions-info">
          <div className="commissions-info__details">
            <div className="commissions-info__details--left">
              <h5>Min Trx for daily saving frequency users</h5>
              <span>
                {currencyFormat(commissions.min_trx_daily_savings)}
              </span>

              <h5>Min Trx for monthly saving frequency users</h5>
              <span>1</span>
              <span>
                {currencyFormat(commissions.min_trx_monthly_savings)}
              </span>

              <h5>Future cash-out amount</h5>
              <span>
                {currencyFormat(commissions.future_cashout_amount)}
              </span>
            </div>
            <div className="commissions-info__details--right">
              <h5>Min Trx for weekly saving frequency users</h5>
              <span>
                {currencyFormat(commissions.min_trx_weekly_savings)}
              </span>
              <h5>Initial cash-out amount</h5>

              <span>
                {currencyFormat(commissions.initial_cashout_amount)}
              </span>
            </div>
          </div>

          <h5>Description</h5>
          <span>{commissions.description}</span>
          <h5>Eligible group types</h5>
          <span>
            {commissions.group_type?.map((groupType, idx) => (
              <span>
                {capitalizeEachWords(groupType?.replace(/_/g, ' '))}
                {idx < commissions.group_type.length - 1
                  ? ','
                  : ''}{' '}
              </span>
            ))}
          </span>
          <h5>Eligible group purpose</h5>
          <span>
            {commissions.group_purpose?.map((groupPurpose, idx) => (
              <span>
                {capitalizeEachWords(
                  groupPurpose?.replace(/_/g, ' '),
                )}
                {idx < commissions.group_purpose.length - 1
                  ? ','
                  : ''}{' '}
              </span>
            ))}
          </span>
          <h5>Eligible NGOs</h5>
          <span>
            {commissions.eligible_ngos?.map((ngo, idx) => (
              <span>
                {ngo?.name}
                {idx < commissions.eligible_ngos.length - 1
                  ? ','
                  : ''}{' '}
              </span>
            ))}
          </span>
        </div>
      }
      on="hover"
      hoverable
      position="left center"
    />
  );
};

CommissionPopup.propTypes = {
  commissions: PropTypes.object.isRequired,
};

export default CommissionPopup;
