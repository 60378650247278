import React from 'react';
import PropTypes from 'prop-types';

import './ActivityItem.scss';
import { Divider } from 'semantic-ui-react';
import randomColor from 'utils/randomColor';
import abName from 'utils/abName';
import ActivityMessage from './ActivityMessage';

const ActivityItem = ({ title, description }) => {
  return (
    <>
      <div className="activity__item">
        <div className="activity__item--details">
          <div
            className="activity__thumbnail"
            style={{ backgroundColor: randomColor() }}
          >
            <span>{abName(title)}</span>
          </div>
          <div className="activity__info">
            <span className="activity__item--title">{title}</span>
            <span className="activity__item--description">
              <ActivityMessage item={description} />
            </span>
            <Divider />
          </div>
        </div>
      </div>
    </>
  );
};

ActivityItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.instanceOf(Object).isRequired,
};

export default ActivityItem;
