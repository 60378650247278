/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Message,
  Modal,
  Select,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { without } from 'lodash';
import {
  usersDropDownOptions,
  organizationsDropDownOptions as projectOptions,
} from 'utils/dropdownOptions';
import './index.scss';
import listProjects from 'redux/actions/projects/listProjects';
import organizationAgents from 'redux/actions/organizations/organizationAgents';
import PINInput from 'components/common/PINInput';
import { getAgentGroups } from 'redux/actions/organizations/organizationGroups';
import transferGroups from 'redux/actions/groups/transferGroups';

const TransferGroups = ({
  open,
  onClose,
  organizationId,
  agentId,
}) => {
  const [form, setForm] = useState({});
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const dispatch = useDispatch();

  const { items: projects, loading: projectsLoading } = useSelector(
    ({ projects: { list } }) => list,
  );

  const { items: agents, loading: agentsLoading } = useSelector(
    ({ organizations: { organizationAgents } }) => organizationAgents,
  );

  const { items: groups } = useSelector(
    ({ organizations: { organizationGroups } }) => organizationGroups,
  );

  const { error, loading } = useSelector(
    ({ user: { transferGroups } }) => transferGroups,
  );

  useEffect(() => {
    listProjects(organizationId)(dispatch);
    getAgentGroups(organizationId, agentId)(dispatch);
  }, [agentId]);

  const handleChange = (_, { value, name }) => {
    if (name === 'project_id') {
      organizationAgents(organizationId, value)(dispatch);
    }
    setForm({ ...form, [name]: value });
  };

  const onSearch = (_, { searchQuery }) =>
    organizationAgents(organizationId, form?.project_id, {
      search: searchQuery,
    })(dispatch);

  const handleSelection = (checked, id) => {
    if (checked) {
      return setSelectedGroups([...selectedGroups, id]);
    }
    return setSelectedGroups(without(selectedGroups, id));
  };

  const handleAllGroups = checked => {
    if (checked) {
      const groupIds = groups?.map(group => group.id);
      setSelectedAll(true);
      return setSelectedGroups(groupIds);
    }
    setSelectedAll(false);
    return setSelectedGroups([]);
  };

  const disableForm =
    groups.length === 0 ||
    selectedGroups.length === 0 ||
    !form?.project_id ||
    !form?.agent_user_id ||
    !form?.password;

  const handleSubmit = () => {
    const data = { ...form, groups: selectedGroups };
    transferGroups(data, organizationId, agentId)(dispatch, onClose);
  };
  return (
    <Modal size="mini" open={open} onClose={() => onClose()}>
      <Modal.Header>
        Transfer Saving Groups
        <Icon
          name="close"
          className="pointer"
          onClick={() => onClose(false)}
        />
      </Modal.Header>
      <Modal.Content>
        <Form
          className="transfer-groups"
          onSubmit={handleSubmit}
          loading={false}
        >
          <Form.Field
            name="project_id"
            label="Recepient Project"
            placeholder="Select"
            control={Select}
            options={projectOptions(projects)}
            search
            loading={projectsLoading}
            onChange={handleChange}
          />
          <Form.Field
            name="agent_user_id"
            label="Recepient Agent"
            placeholder="Select"
            control={Select}
            options={usersDropDownOptions(agents)}
            search
            loading={agentsLoading}
            onChange={handleChange}
            onSearchChange={onSearch}
          />
          <Form.Group grouped>
            <label>
              Saving groups to be Transferred (
              {selectedGroups?.length})
            </label>
            {groups?.length > 1 && (
              <Form.Field className="groups-field">
                <Checkbox
                  type="checkbox"
                  onChange={(_, { checked }) =>
                    handleAllGroups(checked)
                  }
                />
                <label>All</label>
              </Form.Field>
            )}

            {groups?.map(({ name, id }) => (
              <Form.Field className="groups-field">
                <Checkbox
                  id={id}
                  className="group-select"
                  name="groups"
                  type="checkbox"
                  checked={selectedGroups.some(
                    groupId => groupId === id,
                  )}
                  disabled={selectedAll}
                  onChange={(_, { checked }) =>
                    handleSelection(checked, id)
                  }
                />
                <label className={selectedAll ? 'disabled' : ''}>
                  {name}
                </label>
              </Form.Field>
            ))}
          </Form.Group>
          <label>Provide your PIN</label>
          <PINInput
            className="input"
            fluid
            size="tiny"
            name="password"
            onInputChange={handleChange}
          />
          {error && error.message === 'Wrong PIN.' && (
            <p>
              <Message negative>{error.message}</Message>
            </p>
          )}
          <Button
            className="transfer__button"
            loading={loading}
            disabled={disableForm}
            type="submit"
            color="blue"
            size="medium"
            content="Transfer"
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

TransferGroups.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  agentId: PropTypes.string.isRequired,
};

export default TransferGroups;
