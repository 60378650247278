import axiosHelper from 'helpers/axiosHelper';

const nidaVerification = async (
  type = 'fetch',
  nationalId,
  bodyData = {},
) => {
  try {
    const { data } = await axiosHelper().request({
      method: 'POST',
      url: `/users/nida?type=${type}&national_id=${nationalId}&serviceName=IndividualInfo4`,
      data: type === fetch ? {} : bodyData,
    });

    return { ...data?.data, fail: false };
  } catch (error) {
    return { ...error?.response?.data, fail: true };
  }
};

export default nidaVerification;
