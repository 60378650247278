import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Message,
  Button,
  Form,
  TextArea,
  Divider,
  Checkbox,
} from 'semantic-ui-react';
import PINInput from 'components/common/PINInput';
import SimpleModal from '../SimpleModal';
import './index.scss';

const ConfirmPinModal = ({
  content,
  openModal,
  setOpenModal,
  userPin,
  setUserPin,
  loading,
  error,
  onSubmitAction,
  hasComment,
  comment,
  setComment,
  hasCheckbox,
  checkbox,
  setCheckbox,
  checkboxContent,
  hasComplexSubmitAction,
  handleSubmit,
  buttonContent,
}) => {
  const dispatch = useDispatch();

  const handleCheckboxChange = (_, { checked }) => {
    setCheckbox(checked);
  };

  const handlePinChange = (e, { value }) => {
    setUserPin(value);
  };

  const handleCommentChange = (e, { value }) => {
    setComment(value);
  };

  const onSubmit = () => {
    if (hasComplexSubmitAction) return handleSubmit();
    onSubmitAction()(dispatch, setOpenModal, setUserPin);
  };

  return (
    <SimpleModal
      title="Confirm PIN"
      content={content}
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <Form className="pin-form">
        {hasCheckbox && (
          <div className="checkbox">
            <Checkbox
              onChange={handleCheckboxChange}
              defaultChecked={checkbox}
            />
            <span className="checkbox-content">
              {checkboxContent}
            </span>
          </div>
        )}
        <Form.Input label="Provide PIN to confirm" required>
          <PINInput
            className="input"
            fluid
            size="tiny"
            name="password"
            onChange={handlePinChange}
            onInputChange={handlePinChange}
            defaltValue={userPin}
          />
        </Form.Input>
        {hasComment && (
          <>
            <TextArea
              required
              placeholder="Provide a comment"
              className="comment"
              name="comment"
              onChange={handleCommentChange}
              defaltValue={comment}
            />
            <Divider />
          </>
        )}
        {error && error.message === 'Wrong PIN.' && (
          <Message negative>{error?.message}</Message>
        )}
        <Button
          color="blue"
          content={buttonContent}
          type="submit"
          loading={loading}
          onClick={onSubmit}
          fluid
        />
      </Form>
    </SimpleModal>
  );
};

ConfirmPinModal.defaultProps = {
  content: '',
  hasComment: false,
  comment: '',
  setComment: () => {},
  hasCheckbox: false,
  checkbox: false,
  setCheckbox: () => null,
  checkboxContent: '',
  hasComplexSubmitAction: false,
  handleSubmit: () => null,
  buttonContent: 'Confirm',
};

ConfirmPinModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onSubmitAction: PropTypes.func.isRequired,
  userPin: PropTypes.number.isRequired,
  setUserPin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  content: PropTypes.string,
  hasComment: PropTypes.bool,
  comment: PropTypes.string,
  setComment: PropTypes.func,
  hasCheckbox: PropTypes.bool,
  checkbox: PropTypes.string,
  setCheckbox: PropTypes.func,
  checkboxContent: PropTypes.string,
  hasComplexSubmitAction: PropTypes.bool,
  handleSubmit: PropTypes.func,
  buttonContent: PropTypes.string,
};

export default ConfirmPinModal;
