import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Image,
  Checkbox,
  Button,
  Message,
  Modal,
  Icon,
  Form,
} from 'semantic-ui-react';
import './RecentAds.scss';
import PropTypes from 'prop-types';
import deleteIcon from 'assets/images/deleteIcon.svg';
import expandIcon from 'assets/images/expandIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import getAllAdvertisements from 'redux/actions/communication/advertisement/getAllAdvertisement';
import activateAdvertisement from 'redux/actions/communication/advertisement/activateAdvertisement';
import deactivateAdvertisement from 'redux/actions/communication/advertisement/deactivateAdvertisement';
import deleteAdvertisement from 'redux/actions/communication/advertisement/deleteAdvertisement';
import Empty from 'components/common/Empty';
import PageLoader from 'components/common/PageLoader';

const RecentAds = ({ setEditingAd }) => {
  const [isDeleteModelOpened, setIsDeleteModelOpened] = useState(
    false,
  );
  const [
    isViewImageModelOpened,
    setIsViewImageModelOpened,
  ] = useState(false);
  const [deleteAd, setDeleteAd] = useState(null);
  const [
    isDisplayingLightImage,
    setIsDisplayingLightImage,
  ] = useState(true);
  const [confirmActionModel, setConfirmActionModel] = useState({
    action: '',
    header: '',
    body: '',
    darkImage: '',
    context: '',
    model: false,
    modelForm: false,
  });
  const dispatch = useDispatch();
  const { data: adsList, loading } = useSelector(
    ({ advertisement: { allAdvertisements } }) => allAdvertisements,
  );

  const { loading: deleteLoading, error: deleteError } = useSelector(
    ({ advertisement: { deleteAdvertisement } }) =>
      deleteAdvertisement,
  );
  const { loading: activateLoading } = useSelector(
    ({ advertisement: { activateAdvertisement } }) =>
      activateAdvertisement,
  );
  const { loading: deactivateLoading } = useSelector(
    ({ advertisement: { deactivateAdvertisement } }) =>
      deactivateAdvertisement,
  );

  const handleActivateAndDeactivate = (id, status) => {
    switch (status) {
      case 'active':
        deactivateAdvertisement(id)(dispatch);
        break;
      case 'inactive':
        activateAdvertisement(id)(dispatch);
        break;
      default:
        break;
    }
  };

  const openDeleteModal = title => {
    setConfirmActionModel({
      header: 'Delete Advertisement ',
      body: `Are you sure you want to delete ${title} ?`,
    });
    setIsDeleteModelOpened(true);
  };

  const openViewImageModal = (title, image, darkImage) => {
    setConfirmActionModel({
      header: title,
      darkImage,
      body: image,
    });
    setIsViewImageModelOpened(true);
  };
  const handleDelete = () => {
    deleteAdvertisement(deleteAd)(dispatch);
    setIsDeleteModelOpened(false);
    setDeleteAd(null);
  };

  const handleDownload = url => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  useEffect(() => {
    getAllAdvertisements()(dispatch);
  }, []);

  return (
    <div className="recent-ads">
      <>
        <div className="delete-modal">
          <Modal
            size="mini"
            open={isDeleteModelOpened}
            onClose={() => setIsDeleteModelOpened(false)}
          >
            <Modal.Header>
              {confirmActionModel.header}
              <Icon
                name="close"
                className="pointer"
                onClick={() => setIsDeleteModelOpened(false)}
              />
            </Modal.Header>
            <Modal.Content>
              {confirmActionModel.body}
              <Form>
                {deleteError && (
                  <p color="red">
                    <Message negative>{deleteError.message}</Message>
                  </p>
                )}
                <Button
                  color="red"
                  content="Confirm"
                  type="submit"
                  loading={deleteLoading}
                  onClick={() => {
                    handleDelete();
                  }}
                  fluid
                />
              </Form>
            </Modal.Content>
          </Modal>
        </div>
        <div className="view-image-modal">
          <Modal
            size="small"
            open={isViewImageModelOpened}
            onClose={() => setIsViewImageModelOpened(false)}
          >
            <Modal.Header>
              {confirmActionModel.header}
              <Icon
                name="close"
                className="pointer"
                onClick={() => setIsViewImageModelOpened(false)}
              />
            </Modal.Header>
            <Modal.Content className="view-image-modal-content">
              <Icon
                name="arrow alternate circle left"
                className="pointer view-image-modal-content__arrow-left"
                size="big"
                onClick={() =>
                  setIsDisplayingLightImage(!isDisplayingLightImage)
                }
              />
              <Image
                size="large"
                src={
                  isDisplayingLightImage
                    ? confirmActionModel.body
                    : confirmActionModel.darkImage ||
                      confirmActionModel.body
                }
                wrapped
              />
              <Icon
                name="arrow alternate circle right"
                className="pointer view-image-modal-content__arrow-right"
                size="big"
                onClick={() =>
                  setIsDisplayingLightImage(!isDisplayingLightImage)
                }
              />
            </Modal.Content>

            <Modal.Actions>
              <Button
                color="blue"
                content="Download"
                type="submit"
                onClick={() => {
                  handleDownload(confirmActionModel.body);
                }}
              />
            </Modal.Actions>
          </Modal>
        </div>

        <div className="recent-ads-header">
          <h3>Recent Ads</h3>
        </div>
        <Divider className="mb-4" />
      </>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="recent-ads-body">
          {adsList.length > 0 ? (
            adsList?.map(ad => (
              <>
                <div className="ad_field" key={ad?.id}>
                  <div className="ad_field__image">
                    <Image
                      src={ad?.image}
                      animation="overlay"
                      verticalAlign="top"
                      className="ad_field__image__picture"
                    />
                    <button
                      className="ad_field__image__button"
                      type="button"
                      disabled={deleteLoading}
                      onClick={() => {
                        openViewImageModal(
                          ad?.title,
                          ad?.image,
                          ad?.dark_image,
                        );
                      }}
                    >
                      <Image
                        src={expandIcon}
                        className="ad_field__image__button__icon"
                      />
                    </button>
                  </div>

                  <div className="advertisement">
                    <div className="advertisement_heading">
                      <span>{ad?.title}</span>
                      {(deactivateLoading || activateLoading) &&
                      ad.id ? (
                        <Checkbox
                          toggle
                          label="publish"
                          checked={ad.status === 'active'}
                          disabled
                        />
                      ) : (
                        <Checkbox
                          toggle
                          label="publish"
                          checked={ad.status === 'active'}
                          onChange={() => {
                            handleActivateAndDeactivate(
                              ad?.id,
                              ad?.status,
                            );
                          }}
                        />
                      )}
                    </div>
                    <div className="advertisement_actions">
                      <Button
                        className="delete"
                        color="blue"
                        basic
                        onClick={() => {
                          setEditingAd(ad?.id);
                        }}
                        disabled={deleteLoading}
                      >
                        <Image
                          src={editIcon}
                          className="deleteIcon"
                        />
                        Edit
                      </Button>
                      <Button
                        className="delete"
                        color="red"
                        basic
                        disabled={deleteLoading}
                        onClick={() => {
                          openDeleteModal(ad?.title);
                          setDeleteAd(ad?.id);
                        }}
                      >
                        <Image
                          src={deleteIcon}
                          className="deleteIcon"
                        />
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>

                <Divider className="mb-4" />
              </>
            ))
          ) : (
            <Empty message="No ads" />
          )}
        </div>
      )}
    </div>
  );
};
RecentAds.defaultProps = {};

RecentAds.propTypes = {
  setEditingAd: PropTypes.func.isRequired,
};
export default RecentAds;
