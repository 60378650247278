/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import currencyFormat from 'utils/currencyFormat';
import todaysDate from 'utils/todaysDate';

const LineCharts = ({ data, year }) => {
  const {
    member_info: memberInfo,
    cumulative_savings: cumulativeSavings,
    cumulative_social_fund: socialFunds,
    cumulative_social_fund_total: socialFundsTotal,
    cumulative_loans: cumulativeLoans,
    cumulative_loans_total: cumulativeLoansCount,
    current_loans: outstandingLoans,
    current_loans_total: outstandingLoansCount,
    current_savings: currentSavings,
    interest_rate: interestRate,
  } = data;

  const { projectId } = useParams();

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Current savings',
              viewMoreRedirect: !projectId,
              viewKey: 'saving',
              view: 'member',
              memberInfo,
              date: todaysDate(year),
              amount: `${currencyFormat(currentSavings)}`,
              data: cumulativeSavings,
              background: greenColor,
              dataKey: 'total',
              color: '#fff',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Cumulative Social funds',
              viewMoreRedirect: !projectId,
              viewKey: 'social_fund',
              view: 'member',
              memberInfo,
              date: todaysDate(year),
              amount: `${currencyFormat(socialFundsTotal)}`,
              data: socialFunds,
              background: blueColor,
              dataKey: 'total',
              color: '#fff',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Cumulative loans',
              viewMoreRedirect: !projectId,
              viewKey: 'loan',
              view: 'member',
              memberInfo,
              date: todaysDate(year),
              amount: `${currencyFormat(cumulativeLoansCount)}`,
              data: cumulativeLoans,
              background: orangeColor,
              dataKey: 'total',
              color: '#fff',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Outstanding loans',
              viewMoreButton: true,
              viewKey: 'current_loans',
              view: 'member',
              memberInfo,
              interestRate,
              date: todaysDate(year),
              amount: `${currencyFormat(outstandingLoansCount)}`,
              data: outstandingLoans,
              background: redColor,
              dataKey: 'total',
              color: '#fff',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default LineCharts;
