import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart } from 'recharts';

const TinyBarChart = ({ width, height, data, dataKey }) => {
  return (
    <>
      <div
        style={{
          margin: 5,
          padding: 10,
          backgroundColor: 'rgba(72, 78, 115, 0.05)',
        }}
      >
        <BarChart width={width} height={height} data={data}>
          <Bar dataKey={dataKey} fill="#8884d8" />
        </BarChart>
      </div>
    </>
  );
};

TinyBarChart.defaultProps = {
  width: 100,
  height: 100,
  data: [],
};

TinyBarChart.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.array]),
  dataKey: PropTypes.string.isRequired,
};
export default TinyBarChart;
