import {
  AGENT_ACTIVITIES_ERROR,
  AGENT_ACTIVITIES_START,
  AGENT_ACTIVITIES_SUCCESS,
} from 'constants/action-types/organizations/agentActivities';

import apiAction from 'helpers/apiAction';

export default (organizationId, agentId, queries) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/agents/${agentId}/activities`,
      method: 'GET',
      queries,
      API_REQUEST_START: AGENT_ACTIVITIES_START,
      API_REQUEST_SUCCESS: AGENT_ACTIVITIES_SUCCESS,
      API_REQUEST_FAILURE: AGENT_ACTIVITIES_ERROR,
      onEnd: () => false,
    }),
  );
