import {
  GET_CURRENT_USER_FAILURE,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
} from 'constants/action-types/user/currentUser';
import { UPDATE_ACCOUNT_SUCCESS } from 'constants/action-types/user/updateUser';
import { isSupportAdmin } from 'helpers/checkRoles';

export const isSSA = roles =>
  roles.find(({ name }) => name === 'ssa');

export default (state, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_USER_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: true,
          error: null,
        },
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          details: {
            ...payload.data,
            isSSA: isSSA(payload.data.roles),
            isSupportAdmin: isSupportAdmin(payload.data.roles),
          },
          loading: false,
        },
      };
    case GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: false,
        },
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          details: {
            ...payload,
            isSSA: isSSA(payload.roles),
            isSupportAdmin: isSupportAdmin(payload.roles),
          },
        },
      };
    default:
      return null;
  }
};
