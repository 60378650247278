import {
  ORG_USER,
  ORG_ADMIN,
  SSA,
  SA,
  SUPPORT_ADMIN,
  FINANCE_ADMIN,
  TECHNICAL_LEAD,
  RWANDA_ADMIN,
  COMMUNICATIONS_ADMIN,
} from 'constants/roles/adminRoles';

export const shouldLogin = () => true;

export const belongsToOrg = roles =>
  roles.some(({ name }) => name === ORG_ADMIN || name === ORG_USER);

export const isSuperAdmin = roles =>
  roles && roles.some(({ name }) => name === SSA || name === SA);

export const isOrgAdmin = roles =>
  roles && roles.some(({ name }) => name === ORG_ADMIN);

export const isSSA = roles =>
  roles && roles.some(({ name }) => name === SSA);

export const isSA = roles =>
  roles && roles.some(({ name }) => name === SA);

export const isAdmin = roles =>
  roles &&
  roles.some(
    ({ name }) => name === SSA || name === SA || name === ORG_ADMIN,
  );

export const isOrganizationAdmin = ({
  orgUsers = [],
  userType,
  currentOrgId,
}) => {
  if (userType) return ['admin', 'super_admin'].includes(userType);
  return orgUsers.some(
    ({ organization_id: id, user_type: type }) =>
      currentOrgId === id && ['admin', 'super_admin'].includes(type),
  );
};

export const isRwandaAdmin = ({
  roles,
  orgUsers = [],
  currentOrgId,
}) => {
  return (
    (isSA(roles) &&
      isOrganizationAdmin({ currentOrgId, orgUsers })) ||
    roles?.some(({ name }) => name === RWANDA_ADMIN)
  );
};

export const isSupportAdmin = roles =>
  roles?.some(({ name }) => name === SUPPORT_ADMIN);

// should be deprecated (use instead isFinanceRwandaAdmin);
export const isFinanceAdmin = roles =>
  roles?.some(({ name }) => name === FINANCE_ADMIN);

export const isFinanceRwandaAdmin = roles =>
  roles?.some(({ name }) => name === FINANCE_ADMIN);

export const isCommunicationsAdmin = roles =>
  roles?.some(({ name }) => name === COMMUNICATIONS_ADMIN);

export const isTechnicalLead = roles =>
  roles?.some(({ name }) => name === TECHNICAL_LEAD);

export default {
  shouldLogin,
};
