import {
  GET_MEMBER_TRANSACTION_ERROR,
  GET_MEMBER_TRANSACTION_START,
  GET_MEMBER_TRANSACTION_SUCCESS,
} from 'constants/action-types/groups/getMemberTransaction';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_MEMBER_TRANSACTION_START:
      return {
        ...state,
        singleMember: {
          ...state.singleMember,
          MemberTransactions: {
            loading: true,
            error: null,
            data: [],
            meta: {},
          },
        },
      };
    case GET_MEMBER_TRANSACTION_ERROR:
      return {
        ...state,
        singleMember: {
          ...state.singleMember,
          MemberTransactions: {
            ...state.singleMember?.MemberTransactions,
            error: payload,
            loading: false,
          },
        },
      };
    case GET_MEMBER_TRANSACTION_SUCCESS:
      return {
        ...state,
        singleMember: {
          ...state.singleMember,
          MemberTransactions: {
            ...state.singleMember?.MemberTransactions,
            loading: false,
            meta: payload.meta,
            data: payload.data,
          },
        },
      };
    default:
      return null;
  }
};
