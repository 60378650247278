import moment from 'moment';

export default (date, format) => {
  let formattedDate = '';

  if (format) {
    formattedDate = moment(date).format(format);
  } else {
    formattedDate = new Date(date)
      .toDateString()
      .split(' ')
      .splice(1)
      .join(' ');
  }

  return formattedDate;
};
