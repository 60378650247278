/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import createProject from 'redux/actions/projects/createProject';
import getProject from 'redux/actions/projects/getProject';
import updateProject from 'redux/actions/projects/updateProject';
import ProjectForm from 'components/Projects/projectForm';

import './index.scss';
import formatedAreas from 'helpers/formatedAreas';
import validateInterventionAreas from 'helpers/validators/validateInterventionAreas';
import validateProjectDates from 'helpers/validators/validateProjectDates';
import { isSuperAdmin } from 'helpers/checkRoles';
import getCurrentOrg from 'helpers/getCurrentOrg';

const CreateProject = ({
  history: { push },
  noToolbar,
  settings,
  match: {
    params: { organizationId, projectId },
  },
}) => {
  const currentOrganization = getCurrentOrg();
  const [form, setForm] = useState({});
  const [areas, setAreas] = useState();
  const [
    interventionAreaError,
    setInterventionAreaError,
  ] = useState();

  const { roles = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const {
    details: { organizations = [] },
    projects: {
      createProject: { loading, error: createError },
      currentProject: { loading: loadingProject, data: projectData },
      updateProject: { loading: loadingUpdate, error: updateError },
    },
  } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
      projects,
    }) => ({ details, projects }),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setForm({});
    if (settings) {
      getProject(organizationId, projectId)(dispatch);
    }
  }, [dispatch, organizationId, projectId, settings]);

  const handleChange = (e, data) => {
    if (data.name === 'intervention_areas') {
      setAreas({
        sectors: data.sectors,
        districts: data.districts,
      });
    }
    setForm({ ...form, [data.name]: data.value });
  };

  const handleSubmit = () => {
    const isNotValidProjectDates = validateProjectDates(form);
    if (isNotValidProjectDates) {
      return;
    }

    const IsValidInterventionAreas = validateInterventionAreas(form);
    if (IsValidInterventionAreas && !settings) {
      return setInterventionAreaError(IsValidInterventionAreas);
    }
    setInterventionAreaError();

    const data = {
      ...form,
      intervention_areas: formatedAreas(form, areas),
    };

    if (!isSuperAdmin(roles)) {
      data.organization = currentOrganization.id;
    }

    if (settings) {
      return updateProject(organizationId, projectId, data)(dispatch);
    }

    return createProject(currentOrganization.id, data)(dispatch)(
      push,
    );
  };

  const organizationsOptions = organizations.map(({ name, id }) => ({
    text: name,
    key: id,
    value: id,
  }));

  const formError = () => {
    if (interventionAreaError) {
      return interventionAreaError;
    }

    if (updateError) {
      return updateError;
    }
    if (createError) {
      if (createError.errors) {
        return createError.errors[0];
      }
      return createError;
    }

    return false;
  };

  return (
    <ProjectForm
      {...{
        formError,
        organizationsOptions,
        handleSubmit,
        handleChange,
        loading,
        loadingProject,
        loadingUpdate,
        projectData: settings ? projectData : form,
        noToolbar,
        settings,
        form,
      }}
    />
  );
};

CreateProject.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  noToolbar: PropTypes.bool.isRequired,
  settings: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf.isRequired,
  }).isRequired,
};

export default CreateProject;
