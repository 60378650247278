import React from 'react';
import { Icon, Modal, Table } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import currencyFormat from 'utils/currencyFormat';
import './index.scss';
import Empty from 'components/common/Empty';

const MemberModal = ({
  memberModal,
  setMemberModal,
  memberInfo,
  interestRate,
}) => {
  return (
    <Modal
      className="member-modal"
      size="mini"
      open={memberModal}
      onClose={() => setMemberModal(false)}
    >
      <Modal.Header>
        Outstanding Loans ({currencyFormat(memberInfo?.loans_balance)}
        )
        <Icon
          name="close"
          className="pointer"
          onClick={() => setMemberModal(false)}
        />
      </Modal.Header>
      <Modal.Content>
        {memberInfo?.loans_balance ? (
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell>Loan requested :</Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.loans_principle
                    ? `${currencyFormat(memberInfo?.loans_principle)}`
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Repayment Days :</Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.loans_repayment_days
                    ? memberInfo?.loans_repayment_days
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Interest Rate :</Table.HeaderCell>
                <Table.Cell>
                  {interestRate
                    ? `${currencyFormat(interestRate)} %`
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Interest Amount :</Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.loans_interest
                    ? `${currencyFormat(memberInfo?.loans_interest)}`
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Due Date :</Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.loans_due_date
                    ? moment(memberInfo?.loans_due_date).format('LL')
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Payed amount :</Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.last_loan_paid_amount
                    ? `${currencyFormat(
                        memberInfo?.last_loan_paid_amount,
                      )}`
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Disbursed Date :</Table.HeaderCell>

                <Table.Cell>
                  {memberInfo?.last_loan_disbursed_date
                    ? moment(
                        memberInfo?.last_loan_disbursed_date,
                      ).format('LL')
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <br />
              <Table.Row>
                <Table.HeaderCell>
                  Interest on overdue loan :
                </Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.overdue_loans_interests
                    ? `${currencyFormat(
                        memberInfo?.overdue_loans_interests,
                      )}`
                    : '-'}
                </Table.Cell>
              </Table.Row>
              <br />
              <Table.Row>
                <Table.HeaderCell>
                  Outstanding Balance :
                </Table.HeaderCell>
                <Table.Cell>
                  {memberInfo?.loans_balance
                    ? `${currencyFormat(memberInfo?.loans_balance)}`
                    : '-'}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Empty message="No Outstanding Loans Found" />
        )}
      </Modal.Content>
    </Modal>
  );
};

MemberModal.propTypes = {
  memberModal: PropTypes.bool,
  setMemberModal: PropTypes.func.isRequired,
  memberInfo: PropTypes.objectOf(Object),
  interestRate: PropTypes.number,
};

MemberModal.defaultProps = {
  memberModal: false,
  memberInfo: {},
  interestRate: 0,
};

export default MemberModal;
