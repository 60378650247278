import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './index.scss';
import { Link } from 'react-router-dom';
import capitalize from 'utils/capitalize';

const ExpandableRow = ({ data = [], redirectUrl = '' }) => {
  const [isOpen, setOpen] = useState(false);

  const rowItem = (icon, label, content) => (
    <>
      <Icon name={icon} />
      <b>{capitalize(label)}</b>
      {content}
    </>
  );
  return (
    <div className="expandable-row">
      <span
        tabIndex="-1"
        onKeyDown={null}
        role="button"
        className={`state ${isOpen ? 'open' : ''}`}
        onClick={() => setOpen(!isOpen)}
      >
        {' '}
      </span>
      <div className="items">
        {data?.map(
          ({ icon, label, content, color, visible = true }) => (
            <>
              {visible && (
                <>
                  {redirectUrl ? (
                    <Link
                      className="item"
                      style={{ color }}
                      to={redirectUrl}
                    >
                      {rowItem(icon, label, content)}
                    </Link>
                  ) : (
                    <div className="item" style={{ color }}>
                      {rowItem(icon, label, content)}
                    </div>
                  )}
                </>
              )}
            </>
          ),
        )}
      </div>
    </div>
  );
};

ExpandableRow.defaultProps = {
  redirectUrl: '',
};

ExpandableRow.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    color: PropTypes.string,
    visible: PropTypes.bool.isRequired,
  }).isRequired,
  redirectUrl: PropTypes.string,
};

export default ExpandableRow;
