export default details => {
  return ['save_country', 'save_global'].includes(details?.save_type);
};

export const isSaveUserGlobalOrganization = details => {
  return details?.organization?.save_type === 'save_global';
};

export const isSaveUserCountryOrganization = details => {
  return details?.organization?.save_type === 'save_country';
};

export const isSaveAdminGlobalOrganization = details => {
  return details?.save_type === 'save_global';
};

export const isSaveAdminCountryOrganization = details => {
  return details?.save_type === 'save_country';
};
