import SingleOrganization from 'containers/Organizations/SingleOrganization';

export default {
  exact: true,
  name: 'Organization',
  protected: true,
  path:
    '/organizations/:organizationId/:tab?/:tabOption?/:option1?/:option2?/:option3?',
  component: SingleOrganization,
  permissions: ['all'],
};
