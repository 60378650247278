import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const ReasonPopup = ({
  description,
  agentFirstName,
  agentLastName,
}) => {
  return (
    <div>
      <p className="bold-title">Loan Reason </p>
      <p>{description}</p>
      <p className="bold-title">Approved by</p>
      <p>{`${agentFirstName} ${agentLastName}`}</p>
    </div>
  );
};

ReasonPopup.defaultProps = {
  description: '',
  agentFirstName: '',
  agentLastName: '',
};

ReasonPopup.propTypes = {
  description: PropTypes.string,
  agentFirstName: PropTypes.string,
  agentLastName: PropTypes.string,
};

export default ReasonPopup;
