import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Divider, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './RecentActivity.scss';
import { isEmpty } from 'lodash';
import Empty from 'components/common/Empty';
import getBulkSmsList from 'redux/actions/organizations/bulkSmsList';
import PageLoader from 'components/common/PageLoader';
import isLocation from 'helpers/isLocation';
import getAllPushNotifications from 'redux/actions/organizations/getAllPushNotifications';
import splitParams from 'helpers/splitParams';
import getAllEmails from 'redux/actions/communication/emails/getAllEmails';
import ActivityItem from './ActivityItem';

const RecentActivity = ({ type, title, params, viewAll = false }) => {
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (type) {
      case 'bulk-sms':
        getBulkSmsList(dispatch);
        break;
      case 'push-notifications':
        getAllPushNotifications()(dispatch);
        break;
      case 'emails':
        getAllEmails()(dispatch);
        break;
      default:
        break;
    }
  }, [type]);

  const {
    data: notificationList,
    loading: notificationLoading,
  } = useSelector(
    ({ organizations: { pushNotificationsList } }) =>
      pushNotificationsList,
  );

  const { data: emailsList, loading: emailsLoading } = useSelector(
    ({ advertisement: { emailsList } }) => emailsList,
  );

  const { data: smsList, loading: smsLodaing } = useSelector(
    ({ organizations: { bulkSmsList } }) => bulkSmsList,
  );

  useEffect(() => {
    switch (type) {
      case 'bulk-sms':
        if (!isEmpty(smsList)) return setItems(smsList);
        break;
      case 'push-notifications':
        if (!isEmpty(notificationList))
          return setItems(notificationList);
        break;
      case 'emails':
        if (!isEmpty(emailsList)) return setItems(emailsList);
        break;
      default:
        break;
    }
  }, [dispatch, notificationList, smsList, emailsList]);

  const viewAllButton = () => (
    <>
      <div className="recent-sms-header">
        <h3>{title}</h3>
        <Button
          onClick={() => {
            history.push({ pathname: `${params}/all` });
          }}
        >
          View all
        </Button>
      </div>
      <Divider className="mb-4" />
    </>
  );

  return (
    <div className="recent-sms">
      {params === 'communications'
        ? isLocation({ name: params }) && viewAllButton()
        : isLocation({
            name: params,
          }) &&
          !splitParams(pathname).includes('all') &&
          viewAllButton()}
      <div className="recent-sms-body">
        {notificationLoading || smsLodaing || emailsLoading ? (
          <PageLoader />
        ) : items.length > 0 ? (
          isLocation({
            name: params,
          }) ? (
            items.slice(0, 10).map(item => (
              <ActivityItem
                key={item.id}
                data={{
                  ...item,
                  content:
                    item?.message ||
                    item?.content ||
                    item?.content_en,
                  sender: item?.sender || item?.initiator,
                }}
                type={type}
                viewAll={viewAll}
              />
            ))
          ) : (
            items.map(item => (
              <ActivityItem
                key={item.id}
                data={{
                  ...item,
                  content:
                    item?.message ||
                    item?.content ||
                    item?.content_en,
                  sender: item?.sender || item?.initiator,
                }}
                viewAll={viewAll}
              />
            ))
          )
        ) : (
          <Empty message="No content found" />
        )}
      </div>
    </div>
  );
};

RecentActivity.defaultProps = {
  viewAll: false,
};

RecentActivity.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  params: PropTypes.string.isRequired,
  viewAll: PropTypes.bool,
};

export default RecentActivity;
