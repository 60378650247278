import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { canAccess } from 'helpers/permissions';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';
import splitParams from 'helpers/splitParams';
import SendForm from 'components/Communications/Activity/SendForm';
import './index.scss';
import AllActivity from 'components/Communications/Activity/AllActivity';
import RecentActivity from 'components/Communications/Activity/RecentActivity';
import permissions from 'constants/permissions';

const BulkSms = () => {
  const { currentOrgId, orgUsers, roles } = getUserRoles();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const isCurrentOrgParent = isParentOrganization();

  useEffect(() => {
    if (
      !isEmpty(roles) &&
      currentOrgId &&
      !canAccess(permissions.canAccess.communications, roles, {
        currentOrgId,
        orgUsers,
        additionalCondition: isCurrentOrgParent,
      })
    ) {
      push('/');
    }
  }, [orgUsers, currentOrgId]);
  return (
    <section className="bulk-sms">
      {splitParams(pathname).includes('all') ? (
        <AllActivity
          type="bulk-sms"
          title="Recent SMS"
          subTitle="All SMS"
          params="bulk-sms"
        />
      ) : (
        <div className="bulk-sms-container">
          <SendForm type="bulk-sms" title="Send SMS to" />
          <RecentActivity
            type="bulk-sms"
            title="Recent SMS"
            params={pathname ? 'bulk-sms' : 'communications'}
          />
        </div>
      )}
    </section>
  );
};

export default BulkSms;
