import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import {
  EDIT_PROFILE_FAILED,
  EDIT_PROFILE_START,
  EDIT_PROFILE_SUCCESS,
} from 'constants/action-types/profiles/editProfile';

export default ({ orgId, profileId, data }) => (
  dispatch,
  push,
  clearForm,
) =>
  dispatch(
    apiAction({
      url: `/organizations/${orgId}/profiles/${profileId}`,
      method: 'PUT',
      data,
      API_REQUEST_START: EDIT_PROFILE_START,
      API_REQUEST_SUCCESS: EDIT_PROFILE_SUCCESS,
      API_REQUEST_FAILURE: EDIT_PROFILE_FAILED,
      callbackOnSuccess: ({ message }) => {
        push(`/`);
        clearForm({});
        notification({ message });
      },
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
