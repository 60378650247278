import React from 'react';
import PropTypes from 'prop-types';
import './selectedItems.scss';
import { Label, Icon } from 'semantic-ui-react';

const SelectedItems = ({ items = [], removeItem }) => {
  return (
    <div className="selectedItems">
      <div className="selectedItems__item">
        {items.length > 0 && (
          <a
            href
            onClick={() => removeItem({ clearAll: true })}
            className="selectedItems__item__clear"
          >
            <Icon name="delete" />
          </a>
        )}
        {items.map(({ id, name }) => (
          <Label key={id} color={name === 'All' && 'grey'}>
            <span>{id}</span>
            <Label.Detail>{name}</Label.Detail>
            <Label.Detail as="a">
              <a href onClick={() => removeItem({ id })}>
                <Icon
                  name="delete"
                  color={name === 'All' && 'teal'}
                />
              </a>
            </Label.Detail>
          </Label>
        ))}
      </div>
    </div>
  );
};

SelectedItems.defaultProps = {
  items: [],
};

SelectedItems.propTypes = {
  items: PropTypes.array,
  removeItem: PropTypes.func.isRequired,
};

export default SelectedItems;
