export default {
  pendingLoanRequests: {
    items: [],
    meta: {},
    loading: false,
    error: null,
  },
  approveLoanRequest: {
    loading: false,
    error: null,
    data: null,
  },
  amendLoanRequest: {
    loading: false,
    error: null,
    data: null,
  },
  singleLoanRequest: {
    loading: false,
    error: null,
    data: {},
  },
  loanInterest: {
    loading: false,
    error: null,
    data: {},
  },
};
