import React, { useEffect, useState } from 'react';
import { Table, Message, Popup, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import './index.scss';
import { useParams, Link } from 'react-router-dom';
import numeral from 'numeral';
import LoanDetails from 'components/Organizations/SingleOrganization/Requests/LoanDetails';
import ReasonPopup from 'components/Organizations/SingleOrganization/Requests/ReasonPopup';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import StatusTag from 'components/common/StatusTag';
import { getOrgGroupRequests } from 'redux/actions/wallets/getOrgRequests';
import SimpleModal from 'components/common/modals/SimpleModal';
import AmendRequestForm from 'components/common/OrgLoanRequestModals/OrgLoanRequestAmendForm';
import DeclineRequestForm from 'components/common/OrgLoanRequestModals/OrgLoanRequestDeclineForm';
import ApproveRequestForm from 'components/common/OrgLoanRequestModals/OrgRequestApprovalForm';
import { capitalizeEachWords } from 'utils/capitalize';
import { orgGroupRequestsFormReset } from 'redux/actions/wallets/approvalOrgRequests';
import OrgLoanPopup from 'components/Organizations/SingleOrganization/Requests/OrgLoanPopup';

const GroupOrgTransactions = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const [action, setAction] = useState('');
  const [currentRequest, setCurrentRequest] = useState({});
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    orgGroupRequestsFormReset()(dispatch);
  }, [action]);

  const { loading, items, error, meta } = useSelector(
    ({ wallets: { orgGroupRequests } }) => orgGroupRequests,
  );

  const {
    loading: loadingGroupRequest,
    error: errorOrgGroupRequest,
  } = useSelector(
    ({ wallets: { approveOrgGroupRequests } }) =>
      approveOrgGroupRequests,
  );

  useEffect(() => {
    getOrgGroupRequests(organizationId)(dispatch);
  }, [dispatch, organizationId]);

  const getTitle = action => {
    return action === 'accept'
      ? 'Confirm Loan Approval'
      : action === 'amend'
      ? `${capitalizeEachWords(groupName)} Loan Request`
      : 'Confirm Loan Decline';
  };

  return (
    <>
      <div className="group-transactions__lists">
        <Toolbar
          header={{
            title: 'Requests',
            navigation: true,
            rightComponent: (
              <ToolbarFooter
                exportCSVData={items}
                meta={meta}
                exportCSVFileName="external-transactions"
              />
            ),
          }}
        />
        <div className="groups-transactions-table">
          {loading ? (
            <Loader />
          ) : error ? (
            <Message negative>{error.message}</Message>
          ) : items?.length === 0 ? (
            <Empty message="No requests found" />
          ) : (
            <Table basic="very" size="large">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <span>Groups</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Requested Loan (RWF)
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Disbursed Loan (RWF)
                  </Table.HeaderCell>
                  <Table.HeaderCell>Loan Reason</Table.HeaderCell>
                  <Table.HeaderCell>Repayment Days</Table.HeaderCell>
                  <Table.HeaderCell>
                    Interest on Loans(RWF)
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Repaid Loans(RWF)
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Outstanding Loans(RWF)
                  </Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {items?.map(
                  ({
                    id,
                    amount,
                    request_id: requestId,
                    provider_name,
                    description,
                    group,
                    loans_paid: loansPaid,
                    loans_balance: loansBalance,
                    group_request,
                    status,
                    modified_loans_interest,
                    modified_repayment_days,
                    is_modified: modified,
                    loan_disbursed_amount,
                  }) => (
                    <Table.Row key={id}>
                      <Table.Cell>
                        <Link
                          to={{
                            pathname: `requests/${group?.id}`,
                            state: { loansBalance },
                          }}
                          className={`group-name ${status ===
                            'approved' && 'rejected'}`}
                        >
                          <Thumbnail
                            avatar={null}
                            name={group?.name}
                          />
                          <span>
                            {capitalizeEachWords(group?.name)}
                          </span>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(amount).format('0.00')}
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(loan_disbursed_amount).format(
                          '0.00',
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Popup
                          trigger={
                            <div className="view-reason">View</div>
                          }
                          position="top left"
                          key={requestId}
                          hoverable
                          content={
                            <ReasonPopup
                              description={description}
                              agentFirstName={
                                group?.created_by?.first_name
                              }
                              agentLastName={
                                group?.created_by?.last_name
                              }
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {modified
                          ? modified_repayment_days
                          : group_request?.repayment_days}
                      </Table.Cell>
                      <Table.Cell>
                        {modified
                          ? numeral(modified_loans_interest).format(
                              '0.00',
                            )
                          : numeral(
                              group_request?.loans_interest,
                            ).format('0.00')}
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(loansPaid).format('0.00')}
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(loansBalance).format('0.00')}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="request-status">
                          <StatusTag status={status} />
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Popup
                          key={requestId}
                          hoverable
                          basic
                          pinned
                          on="click"
                          content={() =>
                            status === 'pending' ? (
                              <OrgLoanPopup
                                setAction={setAction}
                                request={{ id, provider_name }}
                                setRequest={setCurrentRequest}
                                context="approval"
                                data={group}
                                setGroupName={setGroupName}
                              />
                            ) : (
                              <OrgLoanPopup
                                setAction={setAction}
                                request={group_request}
                                setRequest={setCurrentRequest}
                                context="details"
                                data={group}
                                setGroupName={setGroupName}
                              />
                            )
                          }
                          trigger={
                            <div className="action-icon">
                              <Icon name="ellipsis vertical" />
                            </div>
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  ),
                )}
              </Table.Body>
            </Table>
          )}
        </div>
      </div>
      <SimpleModal
        title={getTitle(action)}
        open={['accept', 'reject', 'amend'].includes(action)}
        onClose={() => setAction('')}
        action={action}
      >
        {action === 'amend' && (
          <AmendRequestForm
            action={action}
            organizationId={organizationId}
            loadingGroupRequest={loadingGroupRequest}
            errorOrgGroupRequest={errorOrgGroupRequest}
            request={currentRequest}
            requestMeta={meta}
            setAction={setAction}
          />
        )}
        {action === 'accept' && (
          <ApproveRequestForm
            action={action}
            organizationId={organizationId}
            loadingGroupRequest={loadingGroupRequest}
            errorOrgGroupRequest={errorOrgGroupRequest}
            request={currentRequest}
            requestMeta={meta}
            setAction={setAction}
          />
        )}
        {action === 'reject' && (
          <DeclineRequestForm
            action={action}
            organizationId={organizationId}
            loadingGroupRequest={loadingGroupRequest}
            errorOrgGroupRequest={errorOrgGroupRequest}
            request={currentRequest}
            setAction={setAction}
          />
        )}
      </SimpleModal>
      <SimpleModal
        title={`${capitalizeEachWords(groupName)} Loan Details`}
        open={action === 'details'}
        onClose={() => setAction('')}
      >
        <LoanDetails data={{ request: currentRequest }} />
      </SimpleModal>
      ,
    </>
  );
};

GroupOrgTransactions.defaultProps = {};

GroupOrgTransactions.propTypes = {};

export default GroupOrgTransactions;
