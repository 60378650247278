/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from 'semantic-ui-react';

import './BarChartWrapper.scss';
import BarChartComponent from 'components/common/charts/BarChart';
import todaysDate from 'utils/todaysDate';
import SynchronizedChart from '../../AreaChart/Synchronized';

const BarChartWrapper = ({
  title,
  className,
  date,
  dataKey1,
  dataKey2,
  dataKey3,
  dataKeyXaxis,
  color1,
  color2,
  color3,
  data,
  width,
  height,
  style,
  stack,
  showSynchronized,
  ...restProps
}) => {
  const barChartContainerRef = useRef();
  const [barChartWidth, setBarChartWidth] = useState(0);
  const totalSizePaddings = 0;

  useEffect(() => {
    if (barChartContainerRef.current)
      setBarChartWidth(
        barChartContainerRef.current.clientWidth - totalSizePaddings,
      );

    window.addEventListener('resize', () => {
      if (barChartContainerRef.current) {
        setBarChartWidth(
          barChartContainerRef.current.clientWidth -
            totalSizePaddings,
        );
      }
    });
  }, [barChartContainerRef]);

  return (
    <Card
      className={`bar-chart-wrapper ${className || ''}`}
      style={style}
    >
      <div className="title">{title}</div>
      <div className="date">{date}</div>
      <Divider />
      <div className="bar-chart-container" ref={barChartContainerRef}>
        {!showSynchronized && (
          <BarChartComponent
            dataKey1={dataKey1}
            dataKey2={dataKey2}
            dataKey3={dataKey3}
            data={data}
            dataKeyXaxis={dataKeyXaxis}
            color1={color1}
            color2={color2}
            color3={color3}
            width={barChartWidth || 0}
            height={height}
            stack={stack}
            {...restProps}
          />
        )}

        {showSynchronized && (
          <SynchronizedChart
            dataKey1={dataKey1}
            dataKey2={dataKey2}
            data={data}
          />
        )}
      </div>
    </Card>
  );
};

BarChartWrapper.defaultProps = {
  title: '',
  className: '',
  date: todaysDate(),
  width: 600,
  height: 300,
  dataKey1: 'uv',
  dataKey2: 'pv',
  dataKeyXaxis: 'name',
  color1: '#8884d8',
  showSynchronized: false,
  color2: '#82ca9d',
  color3: '#ffc658',
  dataKey3: '',
  style: {},
  data: [],
  stack: false,
  data: [],
};

BarChartWrapper.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  dataKey1: PropTypes.string,
  dataKey2: PropTypes.string,
  dataKey3: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  showSynchronized: PropTypes.bool,
  dataKeyXaxis: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  data: PropTypes.array,
  stack: PropTypes.bool,
};

export default BarChartWrapper;
