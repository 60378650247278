import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'semantic-ui-react';
import InputNumberFormat from 'components/common/InputNumberFormat';
import AttachmentInput from 'components/common/attachmentInput';
import { toDropDownOptions } from 'utils/dropdownOptions';
import telcoOptions from 'constants/options/telcoOptions';
import PINInput from 'components/common/PINInput';

const TopupNGOForm = ({
  onChange,
  setFile,
  file,
  wallets,
  setWalletId,
}) => {
  const handleDeleteAttach = () => {
    setFile({});
  };

  const handleChangeAttach = e => {
    e.preventDefault();
    setFile(e.target?.files[0]);
  };

  const handleChange = (e, { name, value }) => {
    if (name === 'channel') {
      const selectedWalletId = wallets?.items?.find(wallet => {
        return wallet?.provider_name
          .toLowerCase()
          .includes(value.split(' ')?.[0]?.toLowerCase());
      })?.id;

      setWalletId(selectedWalletId);
    }
    onChange(name, value);
  };
  return (
    <div>
      <InputNumberFormat
        required
        name="amount"
        control={Input}
        label="Amount"
        type="number"
        placeholder="Amount..."
        onChange={handleChange}
      />

      <Form.Field
        required
        name="channel"
        control={Select}
        fluid
        options={toDropDownOptions(telcoOptions, true)}
        label="Channel"
        placeholder="Channel"
        onChange={handleChange}
      />

      <Form.TextArea
        required
        label="Description"
        placeholder="Description"
        name="description"
        className="textarea"
        onChange={handleChange}
      />

      <AttachmentInput
        label="Attachment"
        required
        name="topupattachment"
        onAddAttach={handleChangeAttach}
        filename={file?.name}
        onDeleteAttach={handleDeleteAttach}
      />
      <Form.Field>
        <label>Provide PIN to confirm</label>
        <PINInput
          type="password"
          name="password"
          required
          maxLength={5}
          onInputChange={handleChange}
        />
      </Form.Field>
    </div>
  );
};

TopupNGOForm.defaultProps = {
  file: {},
};

TopupNGOForm.propTypes = {
  wallets: PropTypes.instanceOf(Array).isRequired,
  setWalletId: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  file: PropTypes.instanceOf(Object),
};

export default TopupNGOForm;
