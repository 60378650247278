import {
  GET_ALL_EMAILS_ERROR,
  GET_ALL_EMAILS_START,
  GET_ALL_EMAILS_SUCCESS,
} from 'constants/action-types/communication/getAllEmails';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ALL_EMAILS_START:
      return {
        ...state,
        emailsList: {
          ...state.emailsList,
          loading: true,
          error: null,
        },
      };
    case GET_ALL_EMAILS_ERROR:
      return {
        ...state,
        emailsList: {
          ...state.emailsList,
          error: payload,
          loading: false,
        },
      };
    case GET_ALL_EMAILS_SUCCESS:
      return {
        ...state,
        emailsList: {
          ...state.emailsList,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    default:
      return null;
  }
};
