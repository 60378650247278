import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './index.scss';

const OrgLoanPopup = ({
  setAction,
  request,
  setRequest,
  context,
  data,
  setGroupName,
}) => {
  return (
    <>
      <div className="popup-content transaction-popup">
        {context === 'approval' && (
          <>
            <Button
              className="popup-content__link approve-action-link"
              onClick={() => {
                setAction('accept');
                setRequest(request);
                setGroupName(data?.name);
              }}
            >
              Approve
            </Button>
            <Button
              className="popup-content__link decline-action-link"
              onClick={() => {
                setAction('reject');
                setRequest(request);
                setGroupName(data?.name);
              }}
            >
              Decline
            </Button>
            <Button
              className="popup-content__link amend-action-link"
              onClick={() => {
                setAction('amend');
                setRequest(request);
                setGroupName(data?.name);
              }}
            >
              Amend
            </Button>
          </>
        )}
        {context === 'details' && (
          <Button
            className="popup-content__link modifications-link"
            onClick={() => {
              setAction('details');
              setRequest(request);
              setGroupName(data?.name);
            }}
          >
            View Loan Details
          </Button>
        )}
      </div>
    </>
  );
};

OrgLoanPopup.propTypes = {
  setAction: PropTypes.func.isRequired,
  request: PropTypes.instanceOf(Object).isRequired,
  setRequest: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setGroupName: PropTypes.func.isRequired,
};

export default OrgLoanPopup;
