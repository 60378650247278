/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import validateCredentials from 'helpers/validators/validateCredentials';
import login from 'redux/actions/user/login';
import PhoneInput from 'components/common/PhoneInput';
import './index.scss';
import PINInput from 'components/common/PINInput';
import whiteLogo from '../../assets/images/white_logo.png';

const Login = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    login: { loading, error },
    isAuthenticated,
  } = useSelector(
    ({
      user: {
        login,
        currentUser: { isAuthenticated },
      },
    }) => ({
      login,
      isAuthenticated,
    }),
  );
  const [credentials, setCredentials] = useState({
    phone: 0,
    password: 0,
  });

  const onInputChange = ({ target: { name, value } }) =>
    setCredentials({
      ...credentials,
      [name]: value,
    });

  const handleLogin = () => login(credentials)(dispatch);

  useEffect(() => {
    if (isAuthenticated) {
      const previousUrl = localStorage.getItem('PREVIOUS_URL');

      push(previousUrl || '/');
    }
  }, []);

  return (
    <section className="login">
      <div className="container">
        <img src={whiteLogo} alt="" />
        <Form>
          <h1>{t('Welcome')}</h1>
          <Helmet defaultTitle="Save." titleTemplate="%s - Save.">
            <title>{t('Welcome')}</title>
          </Helmet>
          <PhoneInput
            id="name"
            className="login-phone-input"
            name="phone"
            onChange={e => onInputChange(e)}
          />
          <Form.Field>
            <label>{t('PIN')}</label>
            <div className="ui left icon input">
              <PINInput
                onInputChange={onInputChange}
                name="password"
              />
            </div>
            {error && error.message && (
              <Message negative>
                <span className="error-message">{error.message}</span>
              </Message>
            )}
          </Form.Field>
          <Button.Group floated="right">
            {loading ? (
              <Button loading primary>
                Loading
              </Button>
            ) : (
              <Button
                icon
                labelPosition="right"
                color="blue"
                disabled={!validateCredentials(credentials, 12)}
                onClick={handleLogin}
              >
                <span>{t('Sign in')}</span>
                <Icon name="lock" />
              </Button>
            )}
          </Button.Group>
        </Form>
        <div className="forgot">
          <Link to="/reset-password">{t('forgotPin')}</Link>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
