import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from 'components/common/Toolbar';
import changePin, {
  clearSuccessMessage,
} from 'redux/actions/user/changePin';
import './index.scss';
import ChangePinForm from './ChangePinForm';

const ChangePin = () => {
  const [form, setForm] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({
    oldPin: '',
    newPin: '',
    confirmPin: '',
  });

  const { loading, message, error } = useSelector(
    ({ user: { changePin: updatePin } }) => updatePin,
  );

  const dispatch = useDispatch();
  const handleChange = (e, { name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    if (message) {
      setForm({});
      setSuccessMessage(message);
      clearSuccessMessage()(dispatch);
    }
  }, [message]);

  useEffect(() => {
    if (error) {
      setErrors({
        ...errors,
        oldPin: error,
      });
    }
  }, [error]);

  const handleSubmit = () => {
    if (Object.keys(form).length) {
      if (error) {
        setErrors({
          ...errors,
          oldPin: error,
        });
      }

      if (form.newPin !== form.confirmPin) {
        setErrors({
          ...errors,
          newPin: 'PINs must match',
          confirmPin: 'PINs must match',
          oldPin: '',
        });
        setSuccessMessage('');
      } else {
        setErrors({
          ...errors,
          oldPin: '',
          newPin: '',
          confirmPin: '',
        });
        setSuccessMessage('');
        changePin(form)(dispatch);
      }
    }
  };
  return (
    <div className="changepin__container">
      <Toolbar
        header={{
          navigation: false,
          title: 'Change PIN',
        }}
      />
      <ChangePinForm
        form={form}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        error={errors}
        loading={loading}
        successMessage={successMessage}
        message={message}
      />
    </div>
  );
};

export default ChangePin;
