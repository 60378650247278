import Communications from 'containers/Communications';

export default {
  exact: true,
  name: 'Communications',
  protected: true,
  path: '/communications/:tab?/:option?',
  component: Communications,
  permissions: ['all'],
};
