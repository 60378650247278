import {
  LIST_PROJECTS_ERROR,
  LIST_PROJECTS_START,
  LIST_PROJECTS_SUCCESS,
} from 'constants/action-types/projects/listProjects';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (id, queries) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${id}/projects`,
      method: 'GET',
      queries,
      API_REQUEST_START: LIST_PROJECTS_START,
      API_REQUEST_SUCCESS: LIST_PROJECTS_SUCCESS,
      API_REQUEST_FAILURE: LIST_PROJECTS_ERROR,
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
