/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './index.scss';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import formatNumber from 'helpers/formatNumber';
import capitalize from 'utils/capitalize';
import Thumbnail from 'components/common/Thumbnail';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';
import getWallets from 'redux/actions/wallets/getWallets';
import LoanApprovalModal from './ApprovalModal';
import LoanAmendModal from './AmendModal';

const options = [
  {
    key: 'approve',
    text: 'Approve',
    color: '#15AE70DE',
    value: 'approve',
  },
  { key: 'amend', text: 'Amend', color: '#0F83F2', value: 'amend' },
];

const LoanRequestTable = ({ items }) => {
  const [open, setOpen] = useState(null);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const isCurrentOrgParent = isParentOrganization();
  const { currentOrgId } = getUserRoles();

  const params = useParams();

  const { items: walletItems } = useSelector(
    ({ wallets: { wallets } }) => wallets,
  );

  const onOptionClicked = (option, request) => {
    setOpen(option);
    setSelectedRequest(request);
  };

  useEffect(() => {
    if (isCurrentOrgParent) {
      getWallets()(dispatch);
    } else {
      getWallets(currentOrgId)(dispatch);
    }
  }, [isCurrentOrgParent]);

  return (
    <>
      <LoanApprovalModal
        open={open === 'approve'}
        setOpen={setOpen}
        request={selectedRequest}
        wallets={walletItems}
      />
      <LoanAmendModal
        open={open === 'amend'}
        setOpen={setOpen}
        request={selectedRequest}
        wallets={walletItems}
      />
      <div className="list-loan-requests__items__table">
        <Table basic="very" size="small" textAlign="left">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="">Group</Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Micro-loan type
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Requested amount{' '}
                  <span className="table-data__currency">(RWF)</span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Repayment Days
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Interest on Loan{' '}
                  <span className="table-data__currency">(RWF)</span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Outstanding Loan{' '}
                  <span className="table-data__currency">(RWF)</span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Loan reason
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Expiration Date
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data" width="1" />
            </Table.Row>
          </Table.Header>

          <Table.Body
            className={`list-loan-requests__items__table__tbody wallet__transactions--table-body ${true &&
              'lg-tbody'}`}
          >
            {items.map(request => {
              return (
                <Table.Row
                  key={request.id}
                  className="table-row cursor"
                >
                  <Table.Cell
                    className="wallet__transactions--originator"
                    onClick={() => {
                      push(
                        `/organizations/${params?.organizationId}/requests/${request?.id}`,
                      );
                    }}
                  >
                    <Thumbnail name={request?.group?.name} />
                    <div>
                      <span>{request?.group?.name}</span>
                      <div style={{ fontSize: 10 }}>
                        <b>Profile: </b>
                        <span>
                          {request?.group?.loan_profile_monthly?.[0]
                            ?.loan_profile?.name || 'No profile'}
                        </span>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {capitalize(request?.type?.replace(/_/g, ' '))}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.amount)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.repayment_days)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loans_interest)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loans_balance)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {capitalize(request.purpose)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {request?.expiration_date
                      ? moment(request.expiration_date).format(
                          'MMM DD, YYYY',
                        )
                      : '-'}
                  </Table.Cell>
                  <Table.Cell className="table-data" width="1">
                    <div className="flex flex-between flex-center-vertical">
                      <Dropdown
                        icon={null}
                        direction="left"
                        options={options}
                        trigger={
                          <div className="action-icon">
                            <Icon name="ellipsis vertical" />
                          </div>
                        }
                      >
                        <Dropdown.Menu>
                          <>
                            {request?.status === 'approved' ||
                              options.map(option => (
                                <Dropdown.Item
                                  style={{ color: option.color }}
                                  key={option.key}
                                  onClick={() => {
                                    onOptionClicked(
                                      option.value,
                                      request,
                                    );
                                  }}
                                >
                                  {option.text}
                                </Dropdown.Item>
                              ))}

                            <Dropdown.Item
                              onClick={() => {
                                push(
                                  `/organizations/${params?.organizationId}/requests/${request?.id}`,
                                );
                              }}
                            >
                              View
                            </Dropdown.Item>
                          </>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

LoanRequestTable.defaultProps = {
  items: [],
};

LoanRequestTable.propTypes = {
  items: PropTypes.array,
};

export default LoanRequestTable;
