import {
  GET_USERS_START,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
} from 'constants/action-types/user/getUsers';
import apiAction from 'helpers/apiAction';

const onSuccess = ({ data, meta }) => dispatch => {
  dispatch({
    type: GET_USERS_SUCCESS,
    payload: {
      data,
      meta,
    },
  });
};

export const getOrganizationUsers = (
  organizationId,
  page,
  queries,
) => dispatch => {
  const url = `organizations/${organizationId}/users${
    page ? `?page=${page}` : ''
  }`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_USERS_START,
      API_REQUEST_SUCCESS: GET_USERS_SUCCESS,
      API_REQUEST_FAILURE: GET_USERS_ERROR,
      onSuccess: data => onSuccess(data)(dispatch),
      onEnd: () => false,
    }),
  );
};

export default (organization, roles, queries, all) => dispatch => {
  const url = () => {
    if (all) {
      return '/users';
    }
    return organization && `organizations/${organization.id}/users`;
  };

  dispatch(
    apiAction({
      url: url(),
      method: 'GET',
      queries,
      API_REQUEST_START: GET_USERS_START,
      API_REQUEST_SUCCESS: GET_USERS_SUCCESS,
      API_REQUEST_FAILURE: GET_USERS_ERROR,
      onSuccess: data => onSuccess(data)(dispatch),
      onEnd: () => false,
    }),
  );
};
