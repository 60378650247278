export default {
  wallets: {
    items: [],
    meta: {},
    loading: false,
    error: null,
  },
  creditAgent: {
    message: null,
    loading: false,
    error: null,
  },
  transactions: {
    data: [],
    loading: false,
    error: null,
    meta: null,
  },
  topUpWallet: {
    message: null,
    loading: false,
    error: null,
  },
  topupOrgRequests: {
    items: [],
    message: null,
    loading: false,
    error: null,
  },
  approveOrgRequest: {
    message: null,
    loading: false,
    error: null,
  },
  orgGroupRequests: {
    items: [],
    meta: null,
    loading: false,
    error: null,
  },
  approveOrgGroupRequests: {
    message: null,
    loading: false,
    error: null,
  },
  orgGroupLoanHistory: {
    items: {},
    meta: {},
    loading: false,
    error: null,
  },
  withdrawalWallet: {
    data: {},
    message: null,
    loading: false,
    error: null,
  },
  processWithdrawal: {
    message: null,
    loading: false,
    error: null,
  },
};
