import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  bulkSmsDropdown as organizationOption,
  toDropDownOptions,
} from 'utils/dropdownOptions';
import PageLoader from 'components/common/PageLoader';
import './SendForm.scss';
import onListOrganizations from 'redux/actions/organizations/listOrganizations';
import getProjectGroups from 'redux/actions/organizations/organizationGroups';
import sendBulkSms from 'redux/actions/organizations/bulkSms';
import { isArrayEmpty } from 'utils';
import axios from 'helpers/axiosHelper';
import removeEmptyProperties from 'helpers/removeEmptyProperties';
import capitalize from 'utils/capitalize';
import SelectedItems from 'components/common/SelectedItems';
import pushNotifications from 'redux/actions/organizations/pushNotifications';
import sendEmail from 'redux/actions/communication/emails/sendEmail';
import communicationRoles from 'constants/roles/communicationRoles';

const SendForm = ({ type, title }) => {
  const [projectLoading, setProjectLoading] = useState(false);
  const [form, setForm] = useState({});
  const [disabledInput, setDisabledInput] = useState(false);
  const [independentGroup, setIndependentGroup] = useState(false);
  const [allOrganization, setAllOrganization] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [savingGroupLabel, setSavingGroupLabel] = useState(
    'Saving Group',
  );
  const [
    userTypeOptionDisabled,
    setUserTypeOptionDisabled,
  ] = useState(false);
  const [smsDescriptionCount, setSmsDescriptionCount] = useState({
    count: 160,
    page: 1,
  });
  const [organizationProjects, setOrganizationProjects] = useState(
    [],
  );
  const dispatch = useDispatch();

  const [userTypes, setuserTypes] = useState(
    communicationRoles[type],
  );

  useEffect(() => {
    if ('project_id' in form)
      setuserTypes(
        communicationRoles[type].filter(
          type => !['admin'].includes(type),
        ),
      );
    if (form.role === 'all') {
      setDisabledInput(true);
    } else {
      setDisabledInput(false);
    }
  }, [form]);

  useEffect(() => {
    onListOrganizations()(dispatch);
  }, []);

  const organizations = useSelector(
    ({
      organizations: {
        list: { items = [] },
      },
    }) => items,
  );

  const { loading: smsLoading } = useSelector(
    ({ organizations: { bulkSms } }) => bulkSms,
  );

  const { items: groups, loading: groupLoading } = useSelector(
    ({ organizations: { organizationGroups } }) => organizationGroups,
  );

  const loading = type === 'bulk-sms' ? smsLoading : false;

  const showForm = type !== 'emails';

  const handleSubmit = () => {
    const selectedGroupIds = selectedGroups?.map(({ id }) => id);
    const formattedGroups = selectedGroupIds?.includes('-')
      ? groups
          ?.map(({ id }) => id)
          ?.filter(id => !['', '-'].includes(id))
      : selectedGroupIds;
    const formattedForm = {
      ...removeEmptyProperties(form),
      groups: formattedGroups,
    };
    switch (type) {
      case 'bulk-sms':
        return sendBulkSms(formattedForm)(dispatch);
      case 'push-notifications':
        return pushNotifications(formattedForm)(dispatch);
      case 'emails':
        return sendEmail(form)(dispatch);
      default:
        break;
    }
  };

  const handleChange = async (e, { name, value: currentValue }) => {
    let value = currentValue;
    if (name === 'organization_id') {
      if (!value || value === '-') {
        value = 0;
        setAllOrganization(true);
      } else {
        setProjectLoading(true);
        const { data: projects } = await axios().request({
          method: 'get',
          url: `organizations/${value}/projects`,
        });
        setAllOrganization(false);
        setOrganizationProjects(await projects.data);
        setProjectLoading(false);
      }
    }

    if (name === 'project_id') {
      if (
        organizationProjects.length > 0 &&
        organizationProjects.some(
          ({ id, group_type: groupType = '' }) =>
            id === value && groupType === 'independent',
        )
      ) {
        setIndependentGroup(true);
      } else if (value === '-') value = 0;
      else {
        setIndependentGroup(false);
      }

      getProjectGroups(form.organization_id, value)(dispatch);
    }

    if (name === 'role') {
      switch (value) {
        case 'all':
          value = '';

          if (!form?.project_id) {
            setSavingGroupLabel('User Name');
            return setUserTypeOptionDisabled(true);
          }
          setSavingGroupLabel('Group Name');
          getProjectGroups(
            form.organization_id,
            form.project_id,
          )(dispatch);
          setUserTypeOptionDisabled(false);
          break;
        case 'admin':
        case 'agent':
          setSavingGroupLabel(capitalize(`${value} Name`));
          setUserTypeOptionDisabled(true);
          break;
        case 'member':
        case 'committee':
          if (!form.organization_id || form.organization_id === '-') {
            setUserTypeOptionDisabled(true);
          } else {
            getProjectGroups(
              form.organization_id,
              form.project_id,
            )(dispatch);
            setUserTypeOptionDisabled(false);
          }
          setSavingGroupLabel('Saving Group');
          break;
        default:
          break;
      }
    }

    if (name === 'message') {
      const remainingCharacters =
        smsDescriptionCount.page * 160 - value.length;
      const descriptionCount =
        remainingCharacters < 1
          ? { count: 160, page: smsDescriptionCount.page + 1 }
          : {
              ...smsDescriptionCount,
              count: remainingCharacters,
            };
      setSmsDescriptionCount(descriptionCount);
    }

    if (name === 'groups') {
      const item = groups?.find(group => group.id === value);

      if (!item) return;

      if (item?.id === '-') return setSelectedGroups([item]);

      if (!selectedGroups?.some(group => group.id === value)) {
        const newSelectedGroups = [
          ...selectedGroups.filter(
            selectedGroup => selectedGroup?.id !== '-',
          ),
          item,
        ];
        return setSelectedGroups(newSelectedGroups);
      }
    }

    return setForm({
      ...form,
      [name]: value,
    });
  };

  // reset selected groups if org or project changed
  useEffect(() => {
    setSelectedGroups([]);
  }, [form.project_id, form.organization_id]);

  const handleSearchChange = (e, { searchQuery }) => {
    const querie = { search: searchQuery };
    getProjectGroups(
      form.organization_id,
      form.project_id,
      querie,
    )(dispatch);
  };

  const removeSelectedGroup = ({ id, clearAll = false }) => {
    if (clearAll) return setSelectedGroups([]);
    return setSelectedGroups(
      selectedGroups?.filter(group => group?.id !== id),
    );
  };

  return (
    <div className="send-bulk-sms">
      {isArrayEmpty(organizations) ? (
        <PageLoader />
      ) : (
        <>
          <h3>{title}</h3>
          <Form onSubmit={handleSubmit} loading={loading}>
            <Form.Group
              widths={showForm && 'equal'}
              className={!showForm ? 'fill' : ''}
            >
              <Form.Field
                control={Select}
                label="Organization"
                placeholder="All"
                name="organization_id"
                options={organizationOption(organizations)}
                search
                clearable
                disabled={disabledInput}
                searchInput={{ id: 'organization' }}
                onChange={handleChange}
              />
              {showForm && (
                <Form.Field
                  control={Select}
                  label="Projects"
                  placeholder="All"
                  name="project_id"
                  options={organizationOption(organizationProjects)}
                  search
                  clearable
                  disabled={disabledInput || allOrganization}
                  loading={projectLoading}
                  searchInput={{ id: 'projects' }}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
            <Form.Dropdown
              placeholder="All"
              fluid
              label="User Type"
              selection
              clearable
              options={toDropDownOptions(userTypes)}
              name="role"
              disabled={independentGroup}
              value={independentGroup ? 'member' : undefined}
              onChange={handleChange}
            />
            {showForm && (
              <>
                <label htmlFor="groups">{savingGroupLabel}</label>
                <SelectedItems
                  items={selectedGroups}
                  removeItem={removeSelectedGroup}
                />
                <Form.Dropdown
                  options={organizationOption(groups, true)}
                  className="dropdown-withID"
                  placeholder="Select groups"
                  name="groups"
                  search
                  fluid
                  selection
                  closeOnChange={false}
                  onSearchChange={handleSearchChange}
                  onChange={handleChange}
                  loading={groupLoading}
                  disabled={disabledInput || userTypeOptionDisabled}
                />
              </>
            )}
            <Form.Input
              onChange={handleChange}
              required
              label="Subject"
              name="subject"
              placeholder="Subject"
            />
            <Form.TextArea
              onChange={handleChange}
              required
              rows={6}
              label="Message"
              name="message"
              placeholder="Message"
              className="sms-description"
            />
            <span className="description-count">{`${smsDescriptionCount.count}/${smsDescriptionCount.page}`}</span>
            <div className="send-btn-wrapper">
              <Button type="submit" primary>
                Send
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

SendForm.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
};

SendForm.defaultProps = {
  type: '',
  title: '',
};

export default SendForm;
