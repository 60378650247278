import {
  REMOVE_AGENT_ERROR,
  REMOVE_AGENT_START,
  REMOVE_AGENT_SUCCESS,
} from 'constants/action-types/projects/removeAgent';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default (data, organizationId, agentId) => (
  dispatch,
  onClose,
  redirect,
) =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/agents/${agentId}/remove`,
      method: 'PUT',
      data,
      API_REQUEST_START: REMOVE_AGENT_START,
      API_REQUEST_SUCCESS: REMOVE_AGENT_SUCCESS,
      API_REQUEST_FAILURE: REMOVE_AGENT_ERROR,
      callbackOnSuccess: ({ message }) => {
        onClose(false);
        notification({ message });
        redirect();
      },
      callbackOnFailure: ({ message }) => {
        if (message !== 'Wrong PIN.') {
          onClose(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
