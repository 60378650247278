import {
  GET_GROUP_MEMBER_ERROR,
  GET_GROUP_MEMBER_START,
  GET_GROUP_MEMBER_SUCCESS,
} from 'constants/action-types/organizations/getGroupMember';

import apiAction from 'helpers/apiAction';

export default (groupId, memberId, queries) => dispatch =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}/members/${memberId}/overview`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_GROUP_MEMBER_START,
      API_REQUEST_SUCCESS: GET_GROUP_MEMBER_SUCCESS,
      API_REQUEST_FAILURE: GET_GROUP_MEMBER_ERROR,
      onEnd: () => false,
    }),
  );
