import {
  GET_MEMBER_OVERDUE_LOANS_START,
  GET_MEMBER_OVERDUE_LOANS_ERROR,
  GET_MEMBER_OVERDUE_LOANS_SUCCESS,
} from 'constants/action-types/groups/getMemberOverdueLoans';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_MEMBER_OVERDUE_LOANS_START:
      return {
        ...state,
        singleMember: {
          ...state.singleMember,
          MemberOverdueLoans: {
            loading: true,
            error: null,
            data: [],
            meta: {},
          },
        },
      };
    case GET_MEMBER_OVERDUE_LOANS_ERROR:
      return {
        ...state,
        singleMember: {
          ...state.singleMember,
          MemberOverdueLoans: {
            ...state.singleMember?.memberOverdueLoans,
            loading: false,
            error: payload,
          },
        },
      };
    case GET_MEMBER_OVERDUE_LOANS_SUCCESS:
      return {
        ...state,
        singleMember: {
          ...state.singleMember,
          MemberOverdueLoans: {
            ...state.singleMember?.memberOverdueLoans,
            loading: false,
            data: payload.data,
            meta: payload.meta,
          },
        },
      };
    default:
      return null;
  }
};
