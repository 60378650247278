import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { capitalizeEachWords } from 'utils/capitalize';

const Toggle = ({ title, status, disabled, handleChange }) => {
  return (
    <div className="toggle">
      <div className="left">{capitalizeEachWords(title)}</div>
      <div className="right">
        <Checkbox
          toggle
          checked={status}
          disabled={disabled}
          onChange={(_, { checked }) => handleChange(checked)}
        />
      </div>
    </div>
  );
};

Toggle.defaultProps = {
  disabled: false,
};

Toggle.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

export default Toggle;
