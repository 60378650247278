/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, Select } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import listProjects from 'redux/actions/projects/listProjects';
import getOrganizationAgents from 'redux/actions/organizations/organizationAgents';
import removeAgent from 'redux/actions/projects/removeAgent';
import PINInput from 'components/common/PINInput';
import { organizationsDropDownOptions as projectOptions } from 'utils/dropdownOptions';
import SimpleModal from 'components/common/modals/SimpleModal';

const RemoveAgent = ({ open, onClose, organizationId, agentId }) => {
  const [form, setForm] = useState({});

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { items: projects, loading: projectsLoading } = useSelector(
    ({ projects: { list } }) => list,
  );

  const { error, loading } = useSelector(
    ({ projects: { removeAgent } }) => removeAgent,
  );

  const handleSubmit = () => {
    removeAgent(form, organizationId, agentId)(
      dispatch,
      onClose,
      () => push(`/organizations/${organizationId}/agents`),
    );
  };

  useEffect(() => {
    listProjects(organizationId, { agent_id: agentId })(dispatch);
  }, [agentId]);

  const handleChange = (_, { value, name }) => {
    if (name === 'project_id') {
      getOrganizationAgents(organizationId, value)(dispatch);
    }
    setForm({ ...form, [name]: value });
  };

  return (
    <SimpleModal
      title="Remove Agent"
      open={open}
      onClose={() => onClose(false)}
    >
      <Form className="remove-agent" onSubmit={handleSubmit}>
        <Form.Field
          name="project_id"
          label="Select the project to be removed from"
          placeholder="Select"
          control={Select}
          options={projectOptions(projects)}
          search
          loading={projectsLoading}
          onChange={handleChange}
        />
        <label>Provide your PIN</label>
        <PINInput
          className="input"
          fluid
          size="tiny"
          name="password"
          onInputChange={handleChange}
          error={error}
        />
        <Button
          className="remove__button"
          loading={loading}
          disabled={!form?.project_id || !form?.password}
          type="submit"
          color="red"
          size="medium"
          content="Remove"
        />
      </Form>
    </SimpleModal>
  );
};

RemoveAgent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  agentId: PropTypes.string.isRequired,
};

export default RemoveAgent;
