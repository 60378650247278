import {
  DELETE_GROUP_ERROR,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
} from 'constants/action-types/organizations/deleteGroup';

import apiAction from 'helpers/apiAction';

export default (groupId, data) => dispatch => (
  showConfirmationModel,
  notify,
  push,
  organizationId,
) =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}`,
      method: 'DELETE',
      data,
      API_REQUEST_START: DELETE_GROUP_START,
      API_REQUEST_SUCCESS: DELETE_GROUP_SUCCESS,
      API_REQUEST_FAILURE: DELETE_GROUP_ERROR,
      onSuccess: payload => {
        showConfirmationModel(false);
        notify({
          message: 'Group successfully deleted',
        });
        push(`/organizations/${organizationId}/groups`);
        dispatch({
          type: DELETE_GROUP_SUCCESS,
          payload,
        });
      },
      onEnd: () => false,
    }),
  );
