import {
  DEACTIVATE_ADVERTISEMENT_ERROR,
  DEACTIVATE_ADVERTISEMENT_START,
  DEACTIVATE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/deactivateAdvertisement';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import getAllAdvertisement from './getAllAdvertisement';

export default id => dispatch => {
  dispatch(
    apiAction({
      url: `advertisements/${id}/deactivate`,
      method: 'PUT',
      API_REQUEST_START: DEACTIVATE_ADVERTISEMENT_START,
      API_REQUEST_SUCCESS: DEACTIVATE_ADVERTISEMENT_SUCCESS,
      API_REQUEST_FAILURE: DEACTIVATE_ADVERTISEMENT_ERROR,
      callbackOnSuccess: ({ message }) => {
        notificationHelper({ message });
        getAllAdvertisement()(dispatch);
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};
