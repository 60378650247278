import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_END,
} from 'constants/action-types/user/resetPassword';
import apiAction from 'helpers/apiAction';
import formatPhone from 'helpers/formatPhone';
import { HTTP_NOT_FOUND } from 'constants/httpStatusCodes';
import {
  PASSWORD_RESET_SUCCESSFULLY,
  VERIFICATION_CODE_SENT,
  PHONE_VERIFICATION_ERROR,
} from 'constants/response-messages/resetPassword';
import { UNEXPECTED_ERROR } from 'constants/response-messages/httpRequests';

export const verifyAccount = phone => dispatch =>
  dispatch(
    apiAction({
      url: '/auth/reset_password/token',
      method: 'POST',
      data: {
        phone_number: formatPhone(phone),
      },
      API_REQUEST_START: RESET_PASSWORD_START,
      onSuccess: () => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: {
            message: VERIFICATION_CODE_SENT,
          },
        });
      },
      API_REQUEST_END: RESET_PASSWORD_END,
      onFailure: error => {
        if (error.status === HTTP_NOT_FOUND) {
          return dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: PHONE_VERIFICATION_ERROR,
          });
        }
        return dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: UNEXPECTED_ERROR,
        });
      },
    }),
  );

export const resetPassword = ({
  phone,
  code,
  password,
}) => dispatch =>
  dispatch(
    apiAction({
      url: '/auth/reset_password/',
      method: 'POST',
      data: {
        phone_number: formatPhone(phone),
        new_password: password,
        code,
      },
      API_REQUEST_START: RESET_PASSWORD_START,
      API_REQUEST_END: RESET_PASSWORD_END,
      onSuccess: () => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: {
            isPasswordReset: true,
            message: PASSWORD_RESET_SUCCESSFULLY,
          },
        });
      },
      onFailure: error => {
        if (error.status === HTTP_NOT_FOUND) {
          return dispatch({
            type: RESET_PASSWORD_FAILURE,
            payload: PHONE_VERIFICATION_ERROR,
          });
        }
        return dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: UNEXPECTED_ERROR,
        });
      },
    }),
  );
