import {
  INVITE_PARTNER_ERROR,
  INVITE_PARTNER_START,
  INVITE_PARTNER_SUCCESS,
} from 'constants/action-types/projects/invitePartner';

import apiAction from 'helpers/apiAction';

export default (
  organizationId,
  projectId,
  form,
  organizationExist,
) => dispatch => push => {
  const data = organizationExist
    ? {
        organization_id: form.org_name,
      }
    : {
        ...form,
      };

  const invite = !organizationExist ? '/invite' : '';

  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}/partners${invite}`,
      method: 'POST',
      data,
      API_REQUEST_START: INVITE_PARTNER_START,
      API_REQUEST_SUCCESS: INVITE_PARTNER_SUCCESS,
      API_REQUEST_FAILURE: INVITE_PARTNER_ERROR,
      onSuccess: () => {
        dispatch({
          type: INVITE_PARTNER_SUCCESS,
        });
        push(
          `/projects/${projectId}/organizations/${organizationId}/partners`,
        );
      },
      onEnd: () => false,
    }),
  );
};
