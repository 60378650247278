import {
  LOGOUT_USER_START,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_END,
} from 'constants/action-types/user/logout';

export default (state, { type, payload }) => {
  switch (type) {
    case LOGOUT_USER_START:
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: true,
          error: null,
        },
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAuth: false,
        },
        logout: {
          ...state.logout,
          error: null,
        },
      };
    case LOGOUT_USER_FAILURE:
      return {
        ...state,
        logout: {
          ...state.logout,
          error: payload,
        },
      };
    case LOGOUT_USER_END:
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: false,
        },
      };
    default:
      return null;
  }
};
