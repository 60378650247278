import {
  PROCESS_WITHDRAWAL_ERROR,
  PROCESS_WITHDRAWAL_START,
  PROCESS_WITHDRAWAL_SUCCESS,
} from 'constants/action-types/wallets/processWithdrawal';
import { ERROR } from 'constants/notificationStatus';

import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import getTransactions from './getTransactions';

export default ({ organizationId, walletId, requestId, data }) => (
  dispatch,
  setShowReceipt,
) =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/wallets/${walletId}/requests/${requestId}/withdraw/process`,
      method: 'POST',
      data,
      API_REQUEST_START: PROCESS_WITHDRAWAL_START,
      API_REQUEST_SUCCESS: PROCESS_WITHDRAWAL_SUCCESS,
      API_REQUEST_FAILURE: PROCESS_WITHDRAWAL_ERROR,
      callbackOnSuccess: ({ message }) => {
        notificationHelper({ message });
        getTransactions()(dispatch);
        setShowReceipt(false);
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: ERROR });
      },
      onEnd: () => false,
    }),
  );
