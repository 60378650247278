/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { pick, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Segment,
  Message,
  Dropdown,
  Form,
  TextArea,
  Header,
  Modal,
} from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import PageLoader from 'components/common/PageLoader';
import updateUser from 'redux/actions/user/updateUser';
import './SingleUser.scss';
import ConfirmAction from 'containers/confirmAction';
import {
  genderOptions,
  maritalStatusOptions,
  levelOfEducationOptions,
  idTypeOptions,
} from 'utils/dropdownOptions';
import SingleUserForm from 'components/Users/SingleUser/SingleUserForm';
import resetUserPassword from 'redux/actions/user/resetUserPassword';
import deleteUser from 'redux/actions/user/deleteUser';
import ConfirmModel from 'components/Users/SingleUser/Models/ConfirmModel';
import ChangePinModel from 'components/Users/SingleUser/Models/ChangePinModel';
import ActionConfirmationModel from 'components/common/modals/SimpleModal';
import {
  isOrganizationAdmin,
  isSuperAdmin,
} from 'helpers/checkRoles';
import capitalize from 'utils/capitalize';
import PINInput from 'components/common/PINInput';
import createReferralAlias from 'redux/actions/user/createReferralAlias';
import updateUserPhone from 'redux/actions/user/updateUserPhone';
import unblockUser from 'redux/actions/user/unblockUser';
import { canAccess, canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions/';
import getSingleUser from 'redux/actions/user/getSingleUser';
import invitation from 'assets/images/invitation.svg';
import getUserRoles from 'helpers/getUserRoles';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import UserTransactions from './UserTransactions/index';
import UserRoles from './UserRoles';

const SingleUser = ({
  user,
  getUserLoading,
  getUserError,
  account,
  history,
  isSaveUser,
}) => {
  const { search } = useLocation();

  const page = new URLSearchParams(search).get('page') || 'profile';

  const [form, setForm] = useState({});
  const [formData, setFormData] = useState({});
  const [
    isInfluencerModelOpened,
    setIsInfluencerModelOpened,
  ] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionForm, setActionForm] = useState({});
  const [confirmActionModel, setConfirmActionModel] = useState({
    action: '',
    header: '',
    body: '',
    context: '',
    model: false,
    modelForm: false,
  });
  const [showProfile, setShowProfile] = useState(page === 'profile');

  const { loading, error, message } = useSelector(
    ({ user: { updateUser } }) => updateUser,
  );
  const { currentOrgId, orgUsers, roles } = getUserRoles();

  const {
    loading: updatePhoneLoading,
    error: updatePhoneError,
  } = useSelector(({ user: { updateUserPhone } }) => updateUserPhone);

  const {
    loading: createReferralAliasLoading,
    error: createReferralAliasError,
  } = useSelector(
    ({ user: { createReferalAlias } }) => createReferalAlias,
  );

  const userDetails = useSelector(
    ({
      user: {
        getSingleUser: {
          userDetails: { organizations = [], referral_code },
        },
      },
    }) => ({
      organizations,
      referral_code,
    }),
  );

  const currentOrg = useSelector(
    ({
      organizations: {
        currentOrganization: { details },
      },
    }) => details,
  );

  const { loading: loadingDeleteUser } = useSelector(
    ({ user: { deleteUser } }) => deleteUser,
  );

  const [userType, setUserType] = useState();
  useEffect(() => {
    if (!isEmpty(userDetails?.orgUser)) {
      const { user: { user_type: userType } = {} } =
        userDetails.orgUser?.find(org => {
          return currentOrg && org.id === currentOrg.id;
        }) || {};

      setUserType(userType);
    }
  }, [userDetails.orgUser]);

  const { organizations } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [
    enterPasswordDialogOpen,
    setEnterPasswordDialogOpen,
  ] = useState(false);

  const userInactive = user.status === 'inactive';

  const activateDeactivateUser = () => {
    setEnterPasswordDialogOpen(true);
    setConfirmOpen(false);
  };

  const dispatch = useDispatch();

  const { push } = useHistory();

  useEffect(() => {
    if (
      !isEmpty(roles) &&
      !canAccess(permissions.canAccess.singleUser, roles)
    )
      push('/');
  }, [roles]);

  const handleChange = (e, { name, value }) => {
    if (
      [
        'phone_number',
        'password',
        'referral_user_type',
        'referral_alias',
      ].includes(name)
    ) {
      return setFormData({ ...formData, [name]: value });
    }

    return setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    setForm({
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      id_number: user.id_number,
      birth_date: user.birth_date,
      gender: user.gender,
      occupation: user.occupation,
      marital_status: user.marital_status,
      education_level: user.education_level,
      email: user.email,
      work_email: user.work_email,
      position: user.position,
    });
    setFormData({
      phone_number: user.phone_number,
      referral_user_type: user.referral_user_type,
      referral_alias: user.referral_alias,
    });
  }, [user]);

  const openPopup = () => {
    setConfirmActionModel({
      header: 'Update Phone Number',
      body: `Are you sure you want to update ${user.first_name} ${user.last_name}'s phone number?`,
    });
    setOpenModal(true);
  };

  const openInfluencerModal = () => {
    if (formData.referral_user_type === 'influencer') {
      setConfirmActionModel({
        header: 'Deactivate Influencer ',
        body: `Are you sure you want to deactivate ${user.first_name} ${user.last_name} as an influencer?`,
      });
    } else {
      setConfirmActionModel({
        header: 'Activate Influencer ',
        body: `Are you sure you want to activate ${user.first_name} ${user.last_name} as an influencer?`,
      });
    }

    setIsInfluencerModelOpened(true);
  };

  const handleSubmit = () => {
    if (form) {
      updateUser(form, user.id, account)(dispatch);
    }
    return null;
  };

  const goToChangePin = () => {
    if (account) {
      return history.push('/change-pin');
    }

    return resetUserPassword(
      roles,
      organizations[0].id,
      user.phone_number,
    )(dispatch, setModalActive);
  };

  const onCloseConfirmModal = () => {
    setConfirmActionModel({
      ...confirmActionModel,
      model: false,
      modelForm: false,
    });
  };

  const handleActionFormChange = (e, { name, value }) =>
    setActionForm({
      ...actionForm,
      [name]: value,
    });
  const disableAliasButton =
    formData.referral_alias === '' || formData.password === '';

  const handleReferralActions = () => {
    const referralFormKeys = [
      'password',
      'referral_user_type',
      'referral_alias',
    ];
    createReferralAlias(user.id, {
      ...pick(formData, referralFormKeys),
      referral_user_type:
        formData.referral_user_type === 'member'
          ? 'influencer'
          : 'member',
    })(dispatch, () => {
      setIsInfluencerModelOpened(false);
      getSingleUser(user.id, account)(dispatch);
    });
  };

  const handleSubmitMemberActions = () => {
    switch (confirmActionModel.context) {
      case 'delete-user':
        deleteUser(user.id, actionForm)(
          dispatch,
          onCloseConfirmModal,
          push,
        );
        break;
      case 'unblock-user':
        unblockUser(user.id, actionForm)(
          dispatch,
          onCloseConfirmModal,
        );
        break;
      default:
        break;
    }
  };

  const getButtonType = context => {
    switch (context) {
      case 'delete-user':
        return 'Delete';
      case 'unblock-user':
        return 'Unblock';
      default:
    }
  };

  const updateTab = () => {
    if (showProfile) {
      push(formatSearchParameter({ page: 'transactions' }));
    } else push(formatSearchParameter({ page: 'profile' }));

    setShowProfile(!showProfile);
  };

  const rightComponent = (
    <div>
      <Button
        icon
        labelPosition="right"
        onClick={() => setModalActive(true)}
      >
        <span>{account ? 'Change Pin' : 'Reset Pin'}</span>
        <Icon name="lock" />
      </Button>

      {!account && userType && !isOrganizationAdmin({ userType }) && (
        <Button
          icon
          labelPosition="right"
          data-cy="actionButton"
          onClick={() => setConfirmOpen(true)}
          color={userInactive ? 'green' : 'red'}
        >
          <span>{userInactive ? 'Activate' : 'Deactivate'}</span>
          <Icon name={userInactive ? 'check' : 'ban'} />
        </Button>
      )}
      <Button onClick={() => updateTab()} primary>
        <span>{showProfile ? 'Transactions' : 'User Profile'}</span>
      </Button>
      {isSaveUser && isSuperAdmin(roles) && (
        <Dropdown
          className="setting-dropdown"
          icon
          floating
          labeled
          text={
            <Button icon primary labelPosition="right">
              <span>
                <Icon name="setting" />
              </span>
              Settings
              <Icon name="triangle down" />
            </Button>
          }
        >
          <Dropdown.Menu className="right">
            <Dropdown.Item
              onClick={() => {
                setConfirmActionModel({
                  action: 'delete',
                  header: 'Delete User',
                  body: `Are you sure you want to delete ${capitalize(
                    user.first_name,
                  )} ${user.last_name} ?`,
                  context: 'delete-user',
                  modelForm: true,
                });
              }}
            >
              <Header as="h4" color="red">
                Delete
              </Header>
            </Dropdown.Item>
            {user.is_blocked &&
              canPerformAction(
                permissions.canPerformAction.canUnblockFeature,
                roles,
                {
                  currentOrgId,
                  orgUsers,
                },
              ) && (
                <Dropdown.Item
                  onClick={() => {
                    setConfirmActionModel({
                      action: 'unblock',
                      header: 'Unblock User',
                      body: `Are you sure you want to unblock ${capitalize(
                        user.first_name,
                      )} ${user.last_name} ?`,
                      context: 'unblock-user',
                      modelForm: true,
                    });
                  }}
                >
                  <Header as="h4" color="green">
                    Unblock
                  </Header>
                </Dropdown.Item>
              )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );

  const errorMessage = getUserError && (
    <div className="text-center">
      <Segment vertical>
        <Message size="huge" compact warning attached="bottom">
          <Icon name="warning" /> {getUserError}
        </Message>
      </Segment>
    </div>
  );

  const title = () => {
    if (account) {
      return 'Account';
    }
    if (isSaveUser) {
      return 'User';
    }

    return 'Admin';
  };

  const toolbarTitle = `${title()}: ${
    !getUserLoading && !errorMessage
      ? `${user.first_name || ''} ${user.last_name || ''}`.trim()
      : ''
  }`;

  return (
    <>
      <ChangePinModel
        {...{ modalActive, setModalActive, goToChangePin }}
      />
      <ConfirmModel
        {...{
          ConfirmAction,
          enterPasswordDialogOpen,
          setEnterPasswordDialogOpen,
          userInactive,
          user,
          confirmOpen,
          setConfirmOpen,
          activateDeactivateUser,
        }}
      />
      <Modal
        size="mini"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>
          {confirmActionModel.header}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setOpenModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          {confirmActionModel.body}
          <Form className="phone-form">
            <Form.Input
              required
              label="New Phone Number"
              name="phone_number"
              placeholder="New Phone Number"
              defaultValue={formData.phone_number}
              onChange={handleChange}
            />
            <Form.Input label="Provide PIN to confirm" required>
              <PINInput
                className="input"
                fluid
                size="tiny"
                name="password"
                onChange={handleChange}
                onInputChange={handleChange}
              />
            </Form.Input>
            {updatePhoneError && (
              <p color="red">
                <Message negative>{updatePhoneError}</Message>
              </p>
            )}
            <Button
              color="blue"
              content="Save"
              type="submit"
              loading={updatePhoneLoading}
              onClick={() =>
                updateUserPhone(user.id, {
                  phone_number: formData.phone_number,
                  password: formData.password,
                })(dispatch, setOpenModal)
              }
              fluid
            />
          </Form>
        </Modal.Content>
      </Modal>

      <Modal
        size="mini"
        open={isInfluencerModelOpened}
        onClose={() => setIsInfluencerModelOpened(false)}
      >
        <Modal.Header>
          {confirmActionModel.header}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setIsInfluencerModelOpened(false)}
          />
        </Modal.Header>
        <Modal.Content>
          {confirmActionModel.body}
          <Form className="referral-form" autoComplete="off">
            {formData.referral_user_type === 'member' &&
              !createReferralAliasLoading && (
                <Form.Input
                  autoComplete="off"
                  required
                  label="Alias name(optional)"
                  name="referral_alias"
                  defaultValue={formData.referral_alias || ''}
                  placeholder="Alias name"
                  onChange={handleChange}
                />
              )}

            <Form.Input label="Provide PIN to confirm" required>
              <PINInput
                className="input"
                fluid
                required
                size="tiny"
                name="password"
                onChange={handleChange}
                onInputChange={handleChange}
              />
            </Form.Input>
            {createReferralAliasError && (
              <p color="red">
                <Message negative>
                  {createReferralAliasError.message}
                </Message>
              </p>
            )}
            <Button
              color="blue"
              content="Confirm"
              type="submit"
              disabled={disableAliasButton}
              loading={createReferralAliasLoading}
              onClick={handleReferralActions}
              fluid
            />
          </Form>
        </Modal.Content>
      </Modal>
      <Toolbar
        header={{
          title: toolbarTitle,
          rightComponent:
            !getUserLoading && !errorMessage && rightComponent,
        }}
      />
      <ActionConfirmationModel
        title={confirmActionModel.header}
        content={confirmActionModel.body}
        open={confirmActionModel.modelForm}
        onClose={() => onCloseConfirmModal()}
      >
        <Form
          className="delete-form"
          onSubmit={handleSubmitMemberActions}
          loading={loadingDeleteUser}
        >
          {confirmActionModel.context === 'delete-user' && (
            <TextArea
              required
              placeholder="Provide a reason for the deletion"
              className="delete-form__reason"
              name="comment"
              onChange={handleActionFormChange}
            />
          )}

          <br />
          {confirmActionModel.context === 'unblock-user' && (
            <Form.Input label="Provide PIN to confirm" required>
              <PINInput
                className="input"
                fluid
                size="tiny"
                name="password"
                onChange={handleActionFormChange}
                onInputChange={handleActionFormChange}
              />
            </Form.Input>
          )}

          <Button
            floated="right"
            type="submit"
            content={getButtonType(confirmActionModel.context)}
            color={
              confirmActionModel.context === 'unblock-user'
                ? 'green'
                : 'red'
            }
          />
        </Form>
      </ActionConfirmationModel>

      {getUserLoading ||
      createReferralAliasLoading ||
      updatePhoneLoading ? (
        <PageLoader />
      ) : showProfile ? (
        <div className="SingleUser scroll-wrapper">
          <h3> Personal Details </h3>
          <div className="SingleUser__body">
            <SingleUserForm
              {...{
                form,
                formData,
                user,
                genderOptions,
                idTypeOptions,
                maritalStatusOptions,
                levelOfEducationOptions,
                loading,
                error,
                handleChange,
                handleSubmit,
                message,
                isSaveUser,
                openPopup,
                openInfluencerModal,
              }}
            />
            <div className="SingleUser__body__right">
              <div className="SingleUser__body__right__header">
                <h2>User Roles</h2>
                <div className="SingleUser__body__right__header__invite_code">
                  <img src={invitation} alt="Invitation Icon" />
                  <p>{userDetails.referral_code}</p>
                </div>
              </div>
              {!_.isEmpty(user) && <UserRoles />}
            </div>
          </div>
        </div>
      ) : (
        <UserTransactions user={user} />
      )}
      {errorMessage}
    </>
  );
};

SingleUser.defaultProps = {
  user: {},
  getUserLoading: false,
  getUserError: '',
  isSaveUser: false,
};

SingleUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    id_number: PropTypes.string,
    birth_date: PropTypes.string,
    gender: PropTypes.string,
    occupation: PropTypes.string,
    marital_status: PropTypes.string,
    phone_number: PropTypes.string,
    referral_user_type: PropTypes.string,
    referral_alias: PropTypes.string,
    education_level: PropTypes.string,
    email: PropTypes.string,
    work_email: PropTypes.string,
    position: PropTypes.string,
    status: PropTypes.string,
    my_groups: PropTypes.array,
    roles: PropTypes.array,
    created_groups: PropTypes.array,
    organizations_users: PropTypes.array,
    is_blocked: PropTypes.bool,
  }),
  getUserLoading: PropTypes.bool,
  getUserError: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  account: PropTypes.bool.isRequired,
  isSaveUser: PropTypes.bool,
};

export default SingleUser;
