/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Popup, Icon, Button } from 'semantic-ui-react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import Message from 'components/common/Message';
import Thumbnail from 'components/common/Thumbnail';
import './transactionList.scss';
import formatNumber from 'helpers/formatNumber';
import calendarImage from 'assets/images/calendar.svg';
import moneyBagImage from 'assets/images/money-bag.svg';
import userImage from 'assets/images/user.svg';
import creditImg from 'assets/images/creditArrow.svg';
import debitImg from 'assets/images/debitArrow.svg';
import ellipses from 'helpers/ellipses';
import { capitalizeEachWords } from 'utils/capitalize';
import currencyFormat from 'utils/currencyFormat';
import FormatedDisplay from './formatedDisplay';

const GroupTransactionList = ({
  handleGetGroups,
  loading,
  error,
  message,
  groupTransactions,
  context,
  refreshTransactionStatus,
  transactionStatusUpdateLoading,
  transactionData,
}) => {
  return (
    <section className="group-list scroll-wrapper">
      {loading && <Loader active inline="centered" />}
      {!loading && error && (
        <Message
          message={error.message}
          color="red"
          action={{
            onClick: () => handleGetGroups(),
          }}
        />
      )}
      {!loading && message && (
        <Message
          message={message}
          icon="info circle"
          action={{
            onClick: () => handleGetGroups(),
          }}
        />
      )}
      {groupTransactions &&
        groupTransactions.map(
          ({
            id: transactionId,
            purpose,
            group,
            user,
            amount,
            provider_name,
            created_at: createdAt,
            status,
            comment,
            type,
            sender_or_receiver_phone_number: senderOrReceiverNumber,
            service_provider_name: serviceProviderName,
            response_message: responseMessage,
            provider_reference_id: referenceId,
            bank_ref_id: bankRefId,
            user_id: userId,
            group_id: groupId,
            charge,
          }) => {
            const title = capitalizeEachWords(
              purpose?.replace(/_/g, ' '),
            );
            const provider = provider_name?.split('_')[0];
            return (
              <div
                className="group-item"
                key={transactionId}
                role="button"
                tabIndex="-1"
                onKeyDown={() => null}
              >
                <Thumbnail name={title} />

                <div className="details">
                  <div className="info">
                    <div className="name">{title}</div>
                    <div className="contact">
                      <FormatedDisplay
                        purpose={purpose}
                        group={group}
                        type={type}
                        senderOrReceiverNumber={
                          senderOrReceiverNumber
                        }
                        serviceProviderName={serviceProviderName}
                      />
                      {context === 'group' && (
                        <span className="contact-item">
                          <img
                            src={userImage}
                            className="group-item-icon"
                            alt="user"
                          />
                          <b>Done By: </b>
                          {ellipses(
                            capitalizeEachWords(
                              `${user?.first_name} ${user?.last_name}`,
                            ),
                            10,
                          )}
                        </span>
                      )}
                      <span className="contact-item">
                        <img
                          src={moneyBagImage}
                          className="group-item-icon"
                          alt="savings"
                        />
                        <b>Amount: </b>
                        {formatNumber(amount)} RWF
                      </span>

                      <span className="contact-item time">
                        <img
                          src={calendarImage}
                          className="group-item-icon"
                          alt="calendar"
                        />
                        <b>Time: </b>
                        {createdAt &&
                          moment(createdAt).format(
                            'DD/MM/YYYY, hh:mm A',
                          )}
                      </span>

                      <span className="contact-item">
                        <img
                          src={
                            type === 'credit' ? creditImg : debitImg
                          }
                          className="group-item-icon"
                          alt={type}
                        />
                        <b>Status: </b>
                        {status === 'success' ? (
                          <span className="status-success">
                            Successful
                          </span>
                        ) : status === 'pending' ? (
                          <span className="status-pending">
                            Pending
                          </span>
                        ) : (
                          <Popup
                            trigger={
                              <span className="status-failed">
                                Failed
                              </span>
                            }
                            content={
                              <span className="status-popup">
                                <span>
                                  <Icon name="warning" />
                                  Error Message
                                </span>
                                <p className="status-message">
                                  {comment}
                                </p>
                                <Button
                                  basic
                                  color="green"
                                  size="mini"
                                  content="Redisburse"
                                />
                              </span>
                            }
                            on="hover"
                            hoverable
                            position="left center"
                          />
                        )}
                      </span>
                      <span className="more-info">
                        <Icon
                          name="info circle"
                          className="info-icon"
                        />
                        <Popup
                          trigger={<b>info</b>}
                          content={
                            <span
                              className={
                                status === 'success'
                                  ? 'status-popup info-message  info-popup'
                                  : 'status-popup info-message'
                              }
                            >
                              <span>
                                <Icon name="warning" />
                                Message
                              </span>
                              <span className="transaction-info warning detail">
                                <img
                                  src={
                                    type === 'credit'
                                      ? creditImg
                                      : debitImg
                                  }
                                  alt={type}
                                  className="warning"
                                />
                                <span
                                  className={
                                    type === 'credit'
                                      ? 'transaction-info__credit'
                                      : 'transaction-info__debit'
                                  }
                                >
                                  {type}
                                </span>
                              </span>
                              <p className="status-message">
                                {responseMessage}
                              </p>
                              <div className="group-details">
                                <div className="details">
                                  {!isEmpty(String(userId || '')) && (
                                    <div className="detail">
                                      <div>User ID:</div>
                                      <div>{userId}</div>
                                    </div>
                                  )}
                                  {!isEmpty(
                                    String(groupId || ''),
                                  ) && (
                                    <div className="detail">
                                      <div>Group ID:</div>{' '}
                                      <div>{groupId}</div>
                                    </div>
                                  )}
                                  {!isEmpty(
                                    String(referenceId || ''),
                                  ) && (
                                    <div className="detail">
                                      <div>Ref ID:</div>
                                      <div>{referenceId}</div>
                                    </div>
                                  )}
                                  {!isEmpty(
                                    String(bankRefId || ''),
                                  ) && (
                                    <div className="detail">
                                      <div>Bank Ref ID:</div>{' '}
                                      <div>{bankRefId}</div>
                                    </div>
                                  )}
                                  {transactionData?.status && (
                                    <div className="detail status-message">
                                      Access Bank status is{' '}
                                      {transactionData?.status}
                                    </div>
                                  )}
                                  {(purpose === 'saving' ||
                                    purpose === 'social_fund') && (
                                    <div className="detail">
                                      <div>Fee:</div>{' '}
                                      <div>
                                        {currencyFormat(charge)}
                                      </div>
                                    </div>
                                  )}
                                  <br />
                                </div>
                              </div>
                              <Button
                                basic
                                color="blue"
                                size="fluid"
                                content={
                                  <p>
                                    Refresh transaction status {` `}
                                    <Loader
                                      active={
                                        transactionStatusUpdateLoading
                                      }
                                      inline
                                      size="tiny"
                                      className="loader"
                                    />
                                  </p>
                                }
                                className="refresh-status-buttton"
                                onClick={() =>
                                  refreshTransactionStatus(
                                    transactionId,
                                  )
                                }
                              />
                            </span>
                          }
                          on="hover"
                          hoverable
                          position="left center"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="right-info">
                    <div
                      className={`status ${provider?.toLowerCase()}`}
                    >
                      {provider?.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        )}
    </section>
  );
};

GroupTransactionList.defaultProps = {
  message: null,
  loading: false,
  error: false,
  meta: {
    page: 0,
    pages: 0,
  },
  handleGetGroups: null,
  groupTransactions: [],
  context: 'group',
  refreshTransactionStatus: null,
  transactionStatusUpdateLoading: false,
  transactionData: null,
};

GroupTransactionList.propTypes = {
  meta: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
  }),
  handleGetGroups: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string]),
  groupTransactions: PropTypes.array,
  context: PropTypes.string,
  refreshTransactionStatus: PropTypes.func,
  transactionStatusUpdateLoading: PropTypes.bool,
  transactionData: PropTypes.object,
};

export default GroupTransactionList;
