import {
  CREATE_PROJECT_FAILED,
  CREATE_PROJECT_START,
  CREATE_PROJECT_SUCCESS,
} from 'constants/action-types/projects/createProject';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_PROJECT_START:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          loading: true,
          error: null,
        },
      };
    case CREATE_PROJECT_FAILED:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          error: payload,
          loading: false,
        },
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          loading: false,
        },
      };
    default:
      return null;
  }
};
