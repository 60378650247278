/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Icon, Label, Modal } from 'semantic-ui-react';
import { isEmpty, debounce } from 'lodash';
import './index.scss';
import getAllGroups from 'redux/actions/groups/getAllGroups';
import addTestFeature from 'redux/actions/settings/addTestFeature';

const SEARCH_INTERVAL = 1;

const GroupTestModal = props => {
  const { open, setOpen, type, feature } = props;
  const dispatch = useDispatch();

  const [groups, setGroups] = useState([]);

  const { items = [], loading } = useSelector(
    ({ groups: { allGroups } }) => allGroups,
  );
  const { loading: addTestFeatureLoading } = useSelector(
    ({ settings: { addTestFeature } }) => addTestFeature,
  );

  const handleGetGroups = queries => {
    return getAllGroups({ ...(queries || {}), limit: 10 })(dispatch);
  };

  useEffect(() => {
    if (isEmpty(items)) {
      handleGetGroups();
    }
  }, []);

  const handleSearchChange = debounce((_, { searchQuery }) => {
    handleGetGroups({ search: searchQuery });
  }, SEARCH_INTERVAL);

  const handleChange = (_, val) => {
    return setGroups([...groups, ...val.value]);
  };

  const handleSubmit = () => {
    const groupIds = groups.map(group =>
      Number(group?.split(' ')?.[0]),
    );

    addTestFeature(feature?.id, 'groups', { groups: groupIds })(
      dispatch,
      () => {
        setOpen(false);
      },
    );
  };

  const onRemoveGroup = group => {
    setGroups(groups.filter(groupItem => groupItem !== group));
  };

  const options = useMemo(() => {
    return items.map((item, index) => {
      return {
        key: index,
        text: `${item.id} (${item.name})`,
        value: `${item.id} (${item.name})`,
      };
    });
  }, [items]);

  return (
    <>
      <Modal size="mini" open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          {`Add ${type} groups`}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setOpen(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <label>Group Id</label>
            <div>
              {groups.map(group => (
                <Label key={group}>
                  {group}
                  <Icon
                    name="delete"
                    onClick={() => onRemoveGroup(group)}
                  />
                </Label>
              ))}
            </div>
            <Form.Dropdown
              className="input"
              name="ids"
              id="ids"
              label=""
              size="tiny"
              loading={loading}
              value={[]}
              fluid
              multiple
              search
              selection
              options={options}
              onChange={handleChange}
              onSearchChange={handleSearchChange}
            />

            <Button
              className="confirm"
              type="submit"
              color="blue"
              loading={addTestFeatureLoading}
              fluid
            >
              Add Groups
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

GroupTestModal.defaultProps = {
  open: false,
  type: 'test',
};

GroupTestModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string,
  feature: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default GroupTestModal;
