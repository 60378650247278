import {
  UPDATE_GROUP_START,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_END,
  UPDATE_GROUP_CLEAR,
} from 'constants/action-types/organizations/updateGroup';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import { getSingleGroup } from './organizationGroups';

export const clearUpdateOrganization = () => dispatch =>
  dispatch({
    type: UPDATE_GROUP_CLEAR,
  });

export default (groupId, data) => dispatch => {
  dispatch(
    apiAction({
      method: 'PUT',
      url: `/admin/groups/${groupId}`,
      data,
      API_REQUEST_START: UPDATE_GROUP_START,
      API_REQUEST_END: UPDATE_GROUP_END,
      onSuccess: () => {
        getSingleGroup(groupId)(dispatch);
        dispatch({
          type: UPDATE_GROUP_SUCCESS,
        });
        notificationHelper({ message: 'Group successfully updated' });
      },
      onFailure: error => {
        return dispatch({
          type: UPDATE_GROUP_FAILURE,
          payload: error,
        });
      },
    }),
  );
};
