import AllGroupsTab from 'containers/Groups/AllGroups';

export default {
  exact: true,
  name: 'All Saving Groups',
  protected: true,
  path: '/all-groups/:tab?',
  component: AllGroupsTab,
  permissions: ['all'],
};
