import {
  DEACTIVATE_USER_LOADING,
  DEACTIVATE_USER_FAILED,
  DEACTIVATE_USER_SUCCESS,
  CLEAR_DEACTIVATE_USER,
} from 'constants/action-types/user/deactivateUser';
import apiAction from 'helpers/apiAction';

export const deactivateUser = payload => dispatch => {
  dispatch(
    apiAction({
      url: `users/${payload.id}/${payload.do}`,
      method: 'PUT',
      data: { password: payload.password },
      API_REQUEST_START: DEACTIVATE_USER_LOADING,
      API_REQUEST_SUCCESS: DEACTIVATE_USER_SUCCESS,
      API_REQUEST_FAILURE: DEACTIVATE_USER_FAILED,
      onEnd: () => false,
    }),
  );
};
export const clearUserDeactivated = () => dispatch => {
  dispatch({
    type: CLEAR_DEACTIVATE_USER,
  });
};
