export default (form, areas) => {
  if (!form.intervention_areas) {
    return undefined;
  }
  const {
    provinces = [],
    districts = [],
    sectors = [],
  } = form.intervention_areas;
  if (!provinces) return undefined;
  return provinces.map(province => ({
    province,
    districts: areas.districts
      .filter(({ value, province: areaProvince }) => {
        if (areaProvince === province && districts.includes(value)) {
          return true;
        }
        return null;
      })
      .map(({ value }) => value),
    sectors: areas.sectors
      .filter(({ value, province: areaProvince }) => {
        if (areaProvince === province && sectors.includes(value)) {
          return true;
        }
        return null;
      })
      .map(({ value }) => value),
  }));
};
