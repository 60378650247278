import {
  APPROVAL_ORG_WALLET_REQUESTS_ERROR,
  APPROVAL_ORG_WALLET_REQUESTS_SUCCESS,
  APPROVAL_ORG_WALLET_REQUESTS_START,
  APPROVAL_ORG_GROUP_REQUESTS_ERROR,
  APPROVAL_ORG_GROUP_REQUESTS_START,
  APPROVAL_ORG_GROUP_REQUESTS_SUCCESS,
  ORG_GROUP_REQUESTS_FORM_RESET,
} from 'constants/action-types/wallets/approvalOrgRequests';

import { SUCCESS } from 'constants/notificationStatus';

import apiAction from 'helpers/apiAction';

import getOrgGroupLoanHistory from './getOrgGroupLoanHistory';
import { getOrgGroupRequests } from './getOrgRequests';
import getTransactions from './getTransactions';
import getWallets from './getWallets';

export const approveTopupRequests = (
  organizationId,
  walletId,
  requestId,
  action,
  notify,
  setShowReceipt,
) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/wallets/${walletId}/requests/${requestId}/${action}`,
      method: 'POST',
      API_REQUEST_START: APPROVAL_ORG_WALLET_REQUESTS_START,
      API_REQUEST_SUCCESS: APPROVAL_ORG_WALLET_REQUESTS_SUCCESS,
      API_REQUEST_FAILURE: APPROVAL_ORG_WALLET_REQUESTS_ERROR,
      onSuccess: payload => {
        setShowReceipt(false);
        dispatch({
          type: APPROVAL_ORG_WALLET_REQUESTS_SUCCESS,
          payload,
        });
        notify({
          message: `Successfully ${action}ed the request`,
        });
        getTransactions()(dispatch);
        getWallets()(dispatch);
      },
      onEnd: () => false,
    }),
  );
};

export const approveOrgGroupRequests = ({
  organizationId,
  requestId,
  action,
  data,
  groupId,
  context,
}) => dispatch => (setAction, notify) =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/requests/${requestId}/${action}`,
      method: 'POST',
      data,
      API_REQUEST_START: APPROVAL_ORG_GROUP_REQUESTS_START,
      API_REQUEST_SUCCESS: APPROVAL_ORG_GROUP_REQUESTS_SUCCESS,
      API_REQUEST_FAILURE: APPROVAL_ORG_GROUP_REQUESTS_ERROR,
      callbackOnSuccess: () => {
        setAction('');
        notify({
          message: `Successfully ${action}ed the request`,
          status: SUCCESS,
        });

        if (context === 'history') {
          getOrgGroupLoanHistory(organizationId, groupId)(dispatch);
        }
        getOrgGroupRequests(organizationId)(dispatch);
      },
      onEnd: () => false,
    }),
  );

export const orgGroupRequestsFormReset = () => dispatch =>
  dispatch({
    type: ORG_GROUP_REQUESTS_FORM_RESET,
  });
