import {
  PENDING_LOAN_REQUESTS_ERROR,
  PENDING_LOAN_REQUESTS_START,
  PENDING_LOAN_REQUESTS_SUCCESS,
} from 'constants/action-types/loanRequests/pendingLoanRequests';

export default (state, { type, payload }) => {
  switch (type) {
    case PENDING_LOAN_REQUESTS_START:
      return {
        ...state,
        pendingLoanRequests: {
          ...state.pendingLoanRequests,
          loading: true,
          error: null,
        },
      };
    case PENDING_LOAN_REQUESTS_ERROR:
      return {
        ...state,
        pendingLoanRequests: {
          ...state.pendingLoanRequests,
          error: payload,
          loading: false,
        },
      };
    case PENDING_LOAN_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingLoanRequests: {
          ...state.pendingLoanRequests,
          items: payload.data,
          meta: payload.meta,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
