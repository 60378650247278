/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon } from 'semantic-ui-react';
import './index.scss';
import currencyFormat from 'utils/currencyFormat';

const CumulativeMicroLoansModal = ({ open, setOpen, data }) => {
  return (
    <Modal
      size="tiny"
      open={open}
      className="cumulative-micro-loans-modal"
      onClose={setOpen}
    >
      <Modal.Header className="simple-modal__header">
        <span>Cumulative Micro-Loans</span>
        <Icon
          name="close"
          fitted
          className="pointer"
          onClick={setOpen}
        />
      </Modal.Header>

      <Modal.Content>
        <div className="cumulative-micro-loans-modal__container">
          <div className="cumulative-micro-loans-modal__container__row">
            <span>Total disbursed loans</span>
            <span>{currencyFormat(data.cumulative_micro_loans)}</span>
          </div>
          <div className="cumulative-micro-loans-modal__container__row">
            <span>Total paid loans</span>
            <span>{currencyFormat(data.total_paid_micro_loans)}</span>
          </div>
          <div className="cumulative-micro-loans-modal__container__row">
            <span>Due loans</span>
            <span>{currencyFormat(data.total_due_micro_loans)}</span>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

CumulativeMicroLoansModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    cumulative_micro_loans: PropTypes.number.isRequired,
    total_paid_micro_loans: PropTypes.number.isRequired,
    total_due_micro_loans: PropTypes.number.isRequired,
  }).isRequired,
};

export default CumulativeMicroLoansModal;
