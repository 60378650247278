import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';
import ProfileInfo from 'components/Profile/ProfileForm/ProfileInfo';
import ProfileInterest from 'components/Profile/ProfileForm/ProfileInterest';
import createProfile from 'redux/actions/profiles/createProfile';
import countProfileGroups from 'redux/actions/profiles/countProfileGroups';
import getCurrentOrg from 'helpers/getCurrentOrg';
import profileFormErrors from 'constants/response-messages/profiles/profileFormErrors';
import validateProfileForm from 'helpers/validators/validateProfileForm';

const CreateProfile = ({ history }) => {
  const [form, setForm] = useState({});
  const [disableBtn, setDisableBtn] = useState(true);
  const [page, setPage] = useState(0);
  const [formError, setFormError] = useState(profileFormErrors);

  const dispatch = useDispatch();
  const currentOrganization = getCurrentOrg();

  const {
    createProfile: { loading },
    profileList: { items: profiles },
  } = useSelector(({ profiles: { createProfile, profileList } }) => ({
    createProfile,
    profileList,
  }));

  const validateForm = ({ name, value, type }) => {
    if (type === 'number') value = Number(value);

    validateProfileForm({
      name,
      value,
      form,
      formError,
      profiles,
      setFormError,
      setDisableBtn,
    });

    setForm({ ...form, [name]: value });
  };

  const onFieldChange = ({ name, value }) => {
    let data = { ...form, [name]: value };

    if (isEmpty(value)) data = { ...omit(data, name) };

    countProfileGroups({
      id: currentOrganization?.id,
      data,
    })(dispatch, setPage);

    setForm(data);
  };

  const handlechange = (e, { name, value, type }) => {
    if (type === 'number') value = Number(value);

    setForm({ ...form, [name]: value });
  };

  const disableForm =
    disableBtn ||
    !form?.name ||
    !form?.cycles_count ||
    !form?.min_monthly_savings ||
    !form?.min_monthly_loans;

  const handleNext = () => {
    countProfileGroups({ id: currentOrganization?.id, data: form })(
      dispatch,
      setPage,
    );
  };

  const handleSubmit = () => {
    createProfile({
      organizationId: currentOrganization?.id,
      data: form,
    })(dispatch, data => {
      history?.push(
        `/organizations/${currentOrganization?.id}/profiles/${data?.id}/overview`,
      );
      setForm({});
    });
  };

  return (
    <>
      {page === 0 && (
        <ProfileInfo
          form={form}
          handlechange={handlechange}
          validateForm={validateForm}
          handleNext={handleNext}
          disableBtn={disableForm}
          formError={formError}
          profiles={profiles}
        />
      )}
      {page === 1 && (
        <ProfileInterest
          form={form}
          loading={loading}
          disableBtn={disableForm}
          setPage={setPage}
          handlechange={handlechange}
          handleSubmit={handleSubmit}
          submitBtnText="Create Profile"
          onFieldChange={onFieldChange}
          onIconChange={() => setPage(0)}
          customizedTitle
        />
      )}
    </>
  );
};

CreateProfile.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
};

export default CreateProfile;
