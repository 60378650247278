/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import changePin from 'redux/actions/user/changePin';
import PINInput from 'components/common/PINInput';
import whiteLogo from '../../../assets/images/white_logo.png';
import './index.scss';

const SetPin = () => {
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState();
  const dispatch = useDispatch();
  const { token, verification_code: oldPin } = queryString.parse(
    useLocation().search,
  );
  const { push } = useHistory();
  const { loading, message, error } = useSelector(
    ({ user: { changePin: updatePin } }) => updatePin,
  );

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };
  const handleLogin = () => {
    if (form.newPin !== form.confirmPin) {
      return setFormError('PINs do not match!');
    }

    setFormError();

    changePin(
      {
        oldPin,
        newPin: form.newPin,
      },
      token,
      push,
    )(dispatch);
  };

  return (
    <section className="SetPassword">
      <div className="container">
        <img src={whiteLogo} alt="logo" />
        <Form loading={loading}>
          <h1>Set PIN</h1>
          <Form.Field>
            <label>New PIN</label>
            <PINInput
              icon="lock"
              iconPosition="left"
              label="New PIN"
              type="password"
              name="newPin"
              maxLength={5}
              required
              onInputChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Confirm PIN</label>
            <PINInput
              icon="lock"
              iconPosition="left"
              label="Confirm PIN"
              type="password"
              name="confirmPin"
              required
              maxLength={5}
              onInputChange={handleChange}
            />
          </Form.Field>
          {formError && <Message negative>{formError}</Message>}
          {error && <Message negative>{error}</Message>}
          {message && <Message positive>{message}</Message>}
          <Button
            icon
            labelPosition="right"
            color="blue"
            onClick={handleLogin}
            floated="right"
            disabled={!form.newPin || !form.confirmPin}
          >
            <span>Set Pin</span>
            <Icon name="lock" />
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default SetPin;
