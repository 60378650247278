import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';
import moment from 'moment';
import formatNumber from 'helpers/formatNumber';
import Thumbnail from 'components/common/Thumbnail';
import calendarImage from 'assets/images/calendar.svg';
import moneyBagImage from 'assets/images/money-bag.svg';

const LoansList = ({ item }) => {
  const { push } = useHistory();
  return (
    <>
      <div
        className="loan-item"
        key={item.id}
        role="button"
        tabIndex="-1"
        onKeyDown={() => null}
        onClick={() =>
          push(
            `/organizations/${item.group?.organization_id}/groups/${item.group?.id}/members/${item.user_id}`,
          )
        }
      >
        <Thumbnail
          name={item.user?.first_name}
          secondName={item.user?.last_name}
        />

        <div className="details">
          <div className="info">
            <div className="name">
              {moment(item.created_at).format('Do MMMM YYYY')}
            </div>
            <div className="member">
              <span className="member-item">
                <img
                  src={moneyBagImage}
                  className="loan-item-icon"
                  alt="interest"
                />
                <b>Loan Interest:</b>
                {item.overdue_loans_interests} RWF
              </span>

              <span className="member-item">
                <img
                  src={moneyBagImage}
                  className="loan-item-icon"
                  alt="interest"
                />
                <b>Interest Rate: </b>
                {item.request?.interest_rate}%
              </span>

              <span className="member-item">
                <img
                  src={moneyBagImage}
                  className="loan-item-icon"
                  alt="interest"
                />
                <b>Principle Loan: </b>
                {item.member_loans?.loans_principle} RWF
              </span>

              <span className="member-item">
                <img
                  src={moneyBagImage}
                  className="loan-item-icon"
                  alt="amount"
                />
                <b>Amount: </b>
                {formatNumber(item.loans_balance)} RWF
              </span>

              <span className="member-item time">
                <img
                  src={calendarImage}
                  className="loan-item-icon"
                  alt="calendar"
                />
                <b>Time: </b>
                {moment(item.created_at).format(
                  'DD/MM/YYYY, hh:mm A',
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoansList;
