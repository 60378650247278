import React from 'react';
import PropTypes from 'prop-types';

import DetailCards from 'components/common/charts/LineChart/Container';
import todaysDate from 'utils/todaysDate';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import currencyFormat from 'utils/currencyFormat';
import './index.scss';

const LoanOverviewModal = ({ group }) => {
  return (
    <>
      <div className="internal-loan-overview">
        <div className="internal-loan-overview__upper">
          <DetailCards
            title="Cumulative Loans"
            background={greenColor}
            date={todaysDate()}
            amount={`${currencyFormat(group.cumulative_loans_total)}`}
            noLineCharts
          />
          <DetailCards
            title="Outstanding loans"
            background={redColor}
            date={todaysDate()}
            amount={`${currencyFormat(group.current_loans_total)}`}
            noLineCharts
          />
        </div>
        <div className="internal-loan-overview__lower">
          <DetailCards
            title="Loan Write offs"
            background={blueColor}
            date={todaysDate()}
            amount={`${currencyFormat(
              group.group?.loan_write_off_total,
            )}`}
            noLineCharts
          />
          <DetailCards
            title="Overdue loans"
            background={orangeColor}
            date={todaysDate()}
            amount={`${currencyFormat(
              group.group?.overdue_loans_total,
            )}`}
            noLineCharts
          />
        </div>
      </div>
    </>
  );
};

LoanOverviewModal.propTypes = {
  group: PropTypes.object.isRequired,
};

export default LoanOverviewModal;
