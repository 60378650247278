import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import {
  GET_BULK_PUSH_NOTIFICATION_ERROR,
  GET_BULK_PUSH_NOTIFICATION_START,
  GET_BULK_PUSH_NOTIFICATION_SUCCESS,
} from 'constants/action-types/organizations/getAllPushNotifications';

export default () => dispatch =>
  dispatch(
    apiAction({
      url: '/users/notifications/push_notifications',
      method: 'GET',
      API_REQUEST_START: GET_BULK_PUSH_NOTIFICATION_START,
      API_REQUEST_SUCCESS: GET_BULK_PUSH_NOTIFICATION_SUCCESS,
      API_REQUEST_FAILURE: GET_BULK_PUSH_NOTIFICATION_ERROR,
      onSuccess: payload =>
        dispatch({
          type: GET_BULK_PUSH_NOTIFICATION_SUCCESS,
          payload,
        }),
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
