import {
  CREDIT_AGENT_START,
  CREDIT_AGENT_ERROR,
  CREDIT_AGENT_SUCCESS,
} from 'constants/action-types/wallets/creditAgent';

export default (state, { type, payload }) => {
  switch (type) {
    case CREDIT_AGENT_START:
      return {
        ...state,
        creditAgent: {
          ...state.creditAgent,
          loading: true,
          error: null,
          message: null,
        },
      };
    case CREDIT_AGENT_ERROR:
      return {
        ...state,
        creditAgent: {
          ...state.creditAgent,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case CREDIT_AGENT_SUCCESS:
      return {
        ...state,
        creditAgent: {
          ...state.creditAgent,
          loading: false,
          error: null,
          message: payload.message,
        },
      };
    default:
      return null;
  }
};
