import {
  GET_COMMISSIONS_ERROR,
  GET_COMMISSIONS_START,
  GET_COMMISSIONS_SUCCESS,
} from 'constants/action-types/settings/getCommissions';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_COMMISSIONS_START:
      return {
        ...state,
        commissions: {
          ...state?.commissions,
          loading: true,
          error: null,
          data: {},
        },
      };
    case GET_COMMISSIONS_ERROR:
      return {
        ...state,
        commissions: {
          ...state?.commissions,
          loading: false,
          error: payload?.message,
        },
      };
    case GET_COMMISSIONS_SUCCESS:
      return {
        ...state,
        commissions: {
          ...state?.commissions,
          loading: false,
          data: payload?.data,
        },
      };
    default:
      return null;
  }
};
