import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Popup,
  Modal,
  Icon,
  Button,
  Form,
  TextArea,
  Message,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './detailsPopUp.scss';
import cancelMemberRequest from 'redux/actions/groups/cancelMemberRequest';
import reApproveMemberRequest from 'redux/actions/groups/reApproveMemberRequest';
import PINInput from '../PINInput';

const DetailsPopUp = ({
  groupId,
  requestId,
  openPopup,
  setOpenPopup,
  requestModal,
  setRequestModal,
  content,
}) => {
  const dispatch = useDispatch();
  const [actionForm, setActionForm] = useState({});
  const [pinModal, setPinModal] = useState(false);

  const {
    loading: loadingCancelRequest,
    error: errCancelRequest,
  } = useSelector(
    ({ activities: { groupActivities } }) =>
      groupActivities.cancelMemberRequest,
  );

  const {
    loading: loadingReApproveRequest,
    error: errReApproveRequest,
  } = useSelector(
    ({ activities: { groupActivities } }) =>
      groupActivities.reApproveMemberRequest,
  );

  const handleActionForm = (e, { name, value }) => {
    setActionForm({
      ...actionForm,
      [name]: value,
    });
  };

  const openPinModal = () => {
    if (actionForm.comment) {
      setRequestModal({
        ...requestModal,
        model: false,
      });
      setPinModal(true);
    }
  };

  const handleMemberRequest = () => {
    switch (requestModal.context) {
      case 'cancel-request':
        cancelMemberRequest(
          groupId,
          requestId,
          actionForm,
        )(dispatch, setPinModal);
        break;
      case 're-approve-request':
      case 're-trigger-request':
        reApproveMemberRequest(
          groupId,
          requestId,
          actionForm,
        )(dispatch, setPinModal);
        break;
      default:
        break;
    }
  };
  return (
    <div className="detailsPopup">
      <Popup
        trigger={
          <div className="status-icon">
            <span>i</span>
          </div>
        }
        on="click"
        open={openPopup}
        onOpen={() => setOpenPopup(true)}
        onClose={() => setOpenPopup(false)}
        content={content}
        position="left center"
        style={{ minWidth: 320 }}
      />
      <Modal
        size="mini"
        open={requestModal.model}
        onClose={() =>
          setRequestModal({
            ...requestModal,
            model: false,
          })
        }
      >
        <Modal.Header className="request-modal header">
          {requestModal.header}
          <Icon
            name="close"
            className="pointer"
            onClick={() =>
              setRequestModal({
                ...requestModal,
                model: false,
              })
            }
          />
        </Modal.Header>
        <Modal.Content>
          {requestModal.body}
          <Form>
            <TextArea
              required
              placeholder={`Provide a reason for the ${
                requestModal.context === 'cancel-request'
                  ? 'cancellation'
                  : 're-approval'
              }`}
              className="request-form"
              name="comment"
              onChange={handleActionForm}
            />
            <Button
              className="request-form__button"
              negative
              content="Confirm"
              type="submit"
              onClick={() => openPinModal()}
            />
          </Form>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={pinModal}
        onClose={() => setPinModal(false)}
      >
        <Modal.Header className="request-modal header">
          Confirm{' '}
          {requestModal.context === 'cancel-request'
            ? 'Cancellation'
            : 'Re-approval'}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={handleMemberRequest}
            loading={loadingCancelRequest || loadingReApproveRequest}
          >
            <PINInput
              className="input"
              fluid
              size="tiny"
              label="Enter Pin"
              name="password"
              onInputChange={handleActionForm}
            />

            {[errCancelRequest, errReApproveRequest].map(
              error =>
                error === 'Wrong PIN.' && (
                  <p color="red">
                    <Message negative>{error}</Message>
                  </p>
                ),
            )}

            <br />
            <Button type="submit" color="blue" fluid size="tiny">
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
};

DetailsPopUp.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  requestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  requestModal: PropTypes.object.isRequired,
  setRequestModal: PropTypes.func.isRequired,
  content: PropTypes.element.isRequired,
};

export default DetailsPopUp;
