export const APPROVAL_ORG_WALLET_REQUESTS_START =
  'APPROVAL_ORG_WALLET_REQUESTS_START';
export const APPROVAL_ORG_WALLET_REQUESTS_ERROR =
  'APPROVAL_ORG_WALLET_REQUESTS_ERROR';
export const APPROVAL_ORG_WALLET_REQUESTS_SUCCESS =
  'APPROVAL_ORG_WALLET_REQUESTS_SUCCESS';

export const APPROVAL_ORG_GROUP_REQUESTS_START =
  'APPROVAL_ORG_GROUP_REQUESTS_START';
export const APPROVAL_ORG_GROUP_REQUESTS_ERROR =
  'APPROVAL_ORG_GROUP_REQUESTS_ERROR';
export const APPROVAL_ORG_GROUP_REQUESTS_SUCCESS =
  'APPROVAL_ORG_GROUP_REQUESTS_SUCCESS';
export const ORG_GROUP_REQUESTS_FORM_RESET =
  'ORG_GROUP_REQUESTS_FORM_RESET';
