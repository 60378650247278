import {
  SEARCH_USER_ERROR,
  SEARCH_USER_START,
  SEARCH_USER_SUCCESS,
} from 'constants/action-types/user/searchUser';

export default (state, { type, payload }) => {
  switch (type) {
    case SEARCH_USER_START:
      return {
        ...state,
        searchUser: {
          ...state.searchUser,
          loading: true,
          error: null,
        },
      };
    case SEARCH_USER_ERROR:
      return {
        ...state,
        searchUser: {
          ...state.searchUser,
          error: payload,
          loading: false,
        },
      };
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        searchUser: {
          ...state.searchUser,
          meta: payload.meta,
          loading: false,
          items: payload.data,
        },
      };
    default:
      return null;
  }
};
