import {
  LIST_PROFILES_ERROR,
  LIST_PROFILES_START,
  LIST_PROFILES_SUCCESS,
} from 'constants/action-types/profiles/listProfiles';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (id, queries) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${id}/profiles`,
      method: 'GET',
      queries,
      API_REQUEST_START: LIST_PROFILES_START,
      API_REQUEST_SUCCESS: LIST_PROFILES_SUCCESS,
      API_REQUEST_FAILURE: LIST_PROFILES_ERROR,
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
