/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TabRender } from 'helpers/tabRouting';
import routes from './routes';

const AgentsTab = () => {
  const { pathname } = useLocation();

  return <TabRender pathname={pathname} routes={routes} />;
};

export default AgentsTab;
