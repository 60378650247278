import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import moment from 'moment';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions';
import getUserRoles from 'helpers/getUserRoles';
import capitalize, { capitalizeEachWords } from 'utils/capitalize';
import currencyFormat from 'utils/currencyFormat';
import statusColors from 'constants/statusColors';
import BillingPopup from './BillingPopup';

const BillingList = ({ bills }) => {
  const { currentOrgId, roles, orgUsers } = getUserRoles();
  return (
    <>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Wallet type</Table.HeaderCell>
            <Table.HeaderCell>Transaction type</Table.HeaderCell>
            <Table.HeaderCell>Range (Rwf)</Table.HeaderCell>
            <Table.HeaderCell>Fees</Table.HeaderCell>
            <Table.HeaderCell>Frequency</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {bills.map(
            ({
              id,
              name,
              status,
              charge,
              description,
              charge_from_wallet: walletCharge,
              groups_types: groupsType = [],
              groups_purposes: groupsPurpose = [],
              wallet_provider: walletProvider,
              transaction_type: transactionType,
              transaction_purposes: transactionPurpose = [],
              amount_minimum: minimumAmount,
              amount_maximum: maximumAmount,
              charge_type: chargeType,
              charge_frequency: frequency,
              effective_date: effectiveDate,
            }) => (
              <Table.Row>
                <Table.Cell>
                  {canPerformAction(
                    permissions.canPerformAction.canEditBill,
                    roles,
                    { orgUsers, currentOrgId },
                  ) ? (
                    <Link to={`/settings/billing/${id}/edit`}>
                      {name}
                    </Link>
                  ) : (
                    name
                  )}
                </Table.Cell>
                <Table.Cell>
                  {capitalize(startCase(walletCharge))}
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    trigger={
                      <span>
                        {transactionPurpose[0] &&
                          capitalizeEachWords(
                            startCase(transactionPurpose[0]),
                          )}
                        {transactionPurpose.length > 1 && '..'}
                      </span>
                    }
                    content={transactionPurpose.map(purpose => (
                      <span>
                        {capitalizeEachWords(startCase(purpose))},{' '}
                      </span>
                    ))}
                  />
                </Table.Cell>
                <Table.Cell>
                  {currencyFormat(minimumAmount, '')} -{' '}
                  {maximumAmount
                    ? currencyFormat(maximumAmount, '')
                    : 'Above'}
                </Table.Cell>
                <Table.Cell>
                  {chargeType === 'fixed'
                    ? currencyFormat(charge)
                    : `${charge}%`}
                </Table.Cell>
                <Table.Cell>{capitalize(frequency)}</Table.Cell>
                <Table.Cell>
                  <div
                    className="billings--status"
                    style={{
                      backgroundColor:
                        statusColors[
                          status === 'active' &&
                          effectiveDate > moment().format()
                            ? 'pending'
                            : status === 'active'
                            ? 'approved'
                            : 'declined'
                        ],
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <BillingPopup
                    name={name}
                    walletCharge={walletCharge}
                    groupsPurpose={groupsPurpose}
                    transactionType={transactionType}
                    minimumAmount={minimumAmount}
                    maximumAmount={maximumAmount}
                    chargeType={chargeType}
                    charge={charge}
                    effectiveDate={effectiveDate}
                    groupsType={groupsType}
                    walletProvider={walletProvider}
                    transactionPurpose={transactionPurpose}
                    frequency={frequency}
                    description={description}
                  />
                </Table.Cell>
              </Table.Row>
            ),
          )}
        </Table.Body>
      </Table>
    </>
  );
};

BillingList.propTypes = {
  bills: PropTypes.bool.isRequired,
};

export default BillingList;
