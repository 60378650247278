const sheetSchemas = {
  'All VAs': {
    ID: {
      prop: 'id',
      type: Number,
      required: true,
    },
    'Saving groups': {
      prop: 'saving_group',
      type: String,
      required: true,
    },
    'First name': {
      prop: 'first_name',
      type: String,
      required: true,
    },
    'Middle name': {
      prop: 'middle_name',
      type: String,
    },
    'Last name': {
      prop: 'last_name',
      type: String,
    },
    'National ID.': {
      prop: 'id_number',
      type: String,
    },
    'Phone number': {
      prop: 'phone_number',
      type: String,
      required: true,
    },
    'owns smart phones': {
      prop: 'owns_smart_phone',
      type: String,
    },
    'Education level': {
      prop: 'education_level',
      type: String,
      required: true,
    },
  },
  'All SGs': {
    ID: {
      prop: 'id',
      type: Number,
      required: true,
    },
    Names: {
      prop: 'name',
      type: String,
      required: true,
    },
    'Formation date': {
      prop: 'start_date',
      type: Date,
      required: true,
    },
    Province: {
      prop: 'province',
      type: String,
      required: true,
    },
    District: {
      prop: 'district',
      type: String,
    },
    Sector: {
      prop: 'sector',
      type: String,
    },
    Cell: {
      prop: 'cell',
      type: String,
    },
    Village: {
      prop: 'village',
      type: String,
      required: true,
    },
    'Project name': {
      prop: 'project',
      type: String,
      required: true,
    },
    'Intenrational NGO': {
      prop: 'partner',
      type: String,
    },
    'Local NGO': {
      prop: 'local_ngo',
      type: String,
    },
    'Group status': {
      prop: 'type',
      type: String,
    },
    'Min. share': {
      prop: 'share_value_min',
      type: String,
      required: true,
    },
    'Interest rate': {
      prop: 'interest_rate',
      type: String,
      required: true,
    },
    'Max. share': {
      prop: 'share_value_max',
      type: String,
      required: true,
    },
    'Social Fund contribution': {
      prop: 'social_fund',
      type: String,
      required: true,
    },
    'Frequency of contribution': {
      prop: 'saving_frequency',
      type: String,
      required: true,
    },
    'Day of saving': {
      prop: 'saving_time',
      type: String,
      required: true,
    },
    'Cycle length': {
      prop: 'cycle',
      type: String,
      required: true,
    },
    'Saving delay Fine': {
      prop: 'saving_delay_fine',
      type: String,
    },
    'Social Fund delay': {
      prop: 'social_fund_delay_fine',
      type: String,
    },
    'Attendance delay': {
      prop: 'attendance_delay_fine',
      type: String,
    },
    'Meeting absence': {
      prop: 'meeting_absence_fine',
      type: String,
    },
    'Type of Financial Institution': {
      prop: 'fi_type',
      type: String,
    },
    'Name of Financial Institution': {
      prop: 'fi_name',
      type: String,
    },
    'Account number': {
      prop: 'fi_account_number',
      type: String,
    },
  },
  'All Members': {
    ID: {
      prop: 'id',
      type: String,
      required: true,
    },
    'First name': {
      prop: 'first_name',
      type: String,
      required: true,
    },
    'Middle name': {
      prop: 'middle_name',
      type: String,
    },
    'Last name': {
      prop: 'last_name',
      type: String,
    },
    'Phone number': {
      prop: 'phone_number',
      type: String,
      required: true,
    },
    'National ID number': {
      prop: 'id_number',
      type: String,
    },
    'Date of birth': {
      prop: 'birth_date',
      type: Date,
    },
    'Gender (F, M)': {
      prop: 'gender',
      type: String,
      oneOf: ['Male', 'Female'],
    },
    Occupation: {
      prop: 'occupation',
      type: String,
    },
    'Marital status': {
      prop: 'marital_status',
      type: String,
    },
    'Education level': {
      prop: 'education_level',
      type: String,
    },
    'Next of keen / Names': {
      prop: 'next_kin_names',
      type: String,
      required: true,
    },
    'Next of keen / Relationship': {
      prop: 'next_kin_relationship',
      type: String,
      required: true,
    },
    'Next of keen / Phone number': {
      prop: 'next_kin_phone_number',
      type: String,
    },
    'Financial institution': {
      prop: 'fi_name',
      type: String,
    },
    'Account number': {
      prop: 'fi_account_number',
      type: String,
    },
    'Committee member (member or committee)': {
      prop: 'committee_member',
      type: String,
      required: true,
      oneOf: ['member', 'committee'],
    },
  },
};

export default sheetSchemas;
