import {
  INVITE_AGENT_ERROR,
  INVITE_AGENT_START,
  INVITE_AGENT_SUCCESS,
} from 'constants/action-types/organizations/inviteAgent';

import apiAction from 'helpers/apiAction';

export default (
  organizationId,
  projectId,
  data,
  receivedProjectId,
) => dispatch => push =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}/agents`,
      method: 'POST',
      data,
      API_REQUEST_START: INVITE_AGENT_START,
      API_REQUEST_SUCCESS: INVITE_AGENT_SUCCESS,
      API_REQUEST_FAILURE: INVITE_AGENT_ERROR,
      onSuccess: () => {
        dispatch({
          type: INVITE_AGENT_SUCCESS,
        });
        push(
          !receivedProjectId
            ? `/projects/${projectId}/organizations/${organizationId}/agents`
            : `/organizations/${organizationId}/agents`,
        );
      },
      onEnd: () => false,
    }),
  );
