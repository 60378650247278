import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Table } from 'semantic-ui-react';
import { startCase } from 'lodash';
import capitalize, { capitalizeEachWords } from 'utils/capitalize';
import formatNumber from 'helpers/formatNumber';
import updateCommissions from 'redux/actions/settings/updateCommissions';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import CommissionPopup from './CommissionPopup';

const CommissionList = ({ commissions }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(
    commissions.status === 'active',
  );
  const [openModal, setOpenModal] = useState(false);
  const [userPin, setUserPin] = useState(false);

  const { loading, error } = useSelector(
    ({ settings: { updateCommission } }) => updateCommission,
  );

  const handleSubmit = () => {
    updateCommissions({
      ...commissions,
      eligible_ngos: commissions?.eligible_ngos?.map(ngo => ngo?.id),
      status: !status ? 'active' : 'inactive',
      password: userPin,
    })(dispatch, () => {
      setStatus(!status);
      setOpenModal(false);
    });
  };

  return (
    <>
      <ConfirmPinModal
        content="Do you want to confirm the edits made to the commissions?"
        openModal={openModal}
        setOpenModal={setOpenModal}
        userPin={userPin}
        setUserPin={setUserPin}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
        loading={loading}
        error={{ message: error }}
      />
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User role</Table.HeaderCell>
            <Table.HeaderCell>Cash-out Medium</Table.HeaderCell>
            <Table.HeaderCell>
              Commission per active user (Rwf)
            </Table.HeaderCell>
            <Table.HeaderCell>
              Eligible cashout (Rwf)
            </Table.HeaderCell>
            <Table.HeaderCell>Cash-Out frequency</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Agents</Table.Cell>
            <Table.Cell>
              {capitalizeEachWords(
                startCase(commissions.cashout_medium),
              )}
            </Table.Cell>
            <Table.Cell>
              {formatNumber(commissions.commission_per_active_user)}
            </Table.Cell>
            <Table.Cell>
              {formatNumber(commissions.future_cashout_amount)}
            </Table.Cell>
            <Table.Cell>
              {capitalize(commissions.cashout_frequency)}
            </Table.Cell>

            <Table.Cell>
              <Checkbox
                toggle
                checked={status}
                defaultChecked={commissions.status === 'active'}
                onChange={() => {
                  setOpenModal(true);
                }}
                disabled={false}
              />
            </Table.Cell>
            <Table.Cell>
              <CommissionPopup commissions={commissions} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

CommissionList.propTypes = {
  commissions: PropTypes.object.isRequired,
};

export default CommissionList;
