import {
  RESET_PASSWORD_USER_START,
  RESET_PASSWORD_USER_SUCCESS,
  RESET_PASSWORD_USER_ERROR,
} from 'constants/action-types/user/resetUserPassword';

export default (state, { type, payload }) => {
  switch (type) {
    case RESET_PASSWORD_USER_START:
      return {
        ...state,
        resetUserPassword: {
          ...state.resetUserPassword,
          loading: true,
          error: null,
        },
      };
    case RESET_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        resetUserPassword: {
          ...state.resetUserPassword,
          loading: false,
          data: payload,
          error: null,
        },
      };
    case RESET_PASSWORD_USER_ERROR:
      return {
        ...state,
        resetUserPassword: {
          ...state.resetUserPassword,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
