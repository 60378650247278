import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';

import './Empty.scss';

const Empty = ({ icon, title, message, action, smallMargin }) => {
  return (
    <div
      className="empty-view"
      style={{ marginTop: smallMargin ? '25px' : undefined }}
    >
      <Segment>
        <Header icon disabled textAlign="center">
          {icon && <Icon name={icon} />}
          {title}
          {message && <Header.Subheader>{message}</Header.Subheader>}
        </Header>
        {action && action.onClick && (
          <Button primary onClick={action.onClick}>
            {action.title}
          </Button>
        )}
      </Segment>
    </div>
  );
};

Empty.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  smallMargin: PropTypes.bool,
  action: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

Empty.defaultProps = {
  icon: 'info circle',
  title: null,
  message: null,
  smallMargin: false,
  action: {
    title: null,
    onClick: null,
  },
};

export default Empty;
