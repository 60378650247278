import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_END,
} from 'constants/action-types/user/resetPassword';

export default (state, { type, payload }) => {
  switch (type) {
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
          message: null,
          error: null,
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isAccountVerified: true,
          isPasswordReset: payload.isPasswordReset,
          message: payload.message,
          error: null,
        },
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error: payload,
        },
      };
    case RESET_PASSWORD_END:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
        },
      };
    default:
      return null;
  }
};
