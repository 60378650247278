import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Sidebar,
  Icon,
  Form,
  Segment,
} from 'semantic-ui-react';
import phoneUtils from '@exuus/rwanda-phone-utils';
import { pick, keys } from 'lodash';
import topUpWallet from 'redux/actions/wallets/topUpWallet';
import './index.scss';
import notify from 'helpers/notificationHelper';
import { WARN } from 'constants/notificationStatus';
import {
  isFinanceRwandaAdmin,
  isRwandaAdmin,
} from 'helpers/checkRoles';
import { getOrgTransactions } from 'redux/actions/wallets/getOrgRequests';
import TopupSaveRwandaForm from 'components/Wallet/Topup/TopupSaveRwandaForm';
import TopupNGOForm from 'components/Wallet/Topup/TopupNGOForm';
import getWalletTransactions from 'redux/actions/wallets/getTransactions';
import isParentOrganization from 'helpers/isParentOrganization';

const TopUpSidebar = ({
  filterOn,
  setActionType,
  roles,
  currentOrgId,
  wallets,
  orgUsers,
}) => {
  const [form, setForm] = useState({});
  const [file, setFile] = useState({});
  const [walletId, setWalletId] = useState(0);
  const [, updateState] = React.useState();
  const isCurrentOrgParent = isParentOrganization();

  const dispatch = useDispatch();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { loading, message } = useSelector(
    ({ wallets: { topUpWallet } }) => topUpWallet,
  );

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleClear = () => {
    setFile({});
  };

  const handleSubmit = () => {
    const allowedKeys = {
      phone_number: [
        'source',
        'phone_number',
        'amount',
        'description',
      ],
      save_pay: ['source', 'channel', 'amount', 'description'],
    };
    const formattedForm =
      form.source === 'phone_number'
        ? pick(
            {
              ...form,
              phone_number: phoneUtils(form.phone_number).format(
                'unformatted',
              ),
            },
            allowedKeys.phone_number,
          )
        : pick(form, allowedKeys.save_pay);

    if (
      isFinanceRwandaAdmin(roles) ||
      isRwandaAdmin({ roles, orgUsers, currentOrgId })
    ) {
      topUpWallet({
        data: formattedForm,
        isSaveRwanda: true,
        callBack: payload => {
          notify({ message: payload?.message });
          getWalletTransactions()(dispatch);
          forceUpdate();
        },
      })(dispatch);
    } else {
      const formData = new FormData();

      const isLessThan5MBs = file.size / 1024 / 1024 > 5;

      if (isLessThan5MBs) {
        return notify({
          message: 'File should be less than 5 MBs',
          status: WARN,
        });
      }
      keys(form).map(key => formData.append(key, form[key]));
      formData.append('topupattachment', file);

      topUpWallet({
        organizationId: currentOrgId,
        walletId,
        data: formData,
        callBack: () => {
          notify({ message });
          getOrgTransactions({ organization_id: currentOrgId })(
            dispatch,
          );
        },
      })(dispatch);
    }

    handleClear();
  };

  return (
    <Sidebar
      className="sidebar scrollable"
      as={Segment}
      direction="right"
      animation="overlay"
      visible={filterOn}
      width="wide"
    >
      <div className="padding">
        <div className="header">
          <div
            tabIndex="-1"
            role="button"
            onKeyDown={() => null}
            className="back"
            onClick={() => {
              setActionType(null);
            }}
          >
            <Icon name="arrow left" fitted size="large" />
          </div>
          <div className="title titleText">
            <h2>Top-Up</h2>
          </div>
        </div>
      </div>
      <div className="padding">
        <Form onSubmit={handleSubmit} loading={loading}>
          {isCurrentOrgParent ? (
            <TopupSaveRwandaForm
              onChange={handleChange}
              form={form}
            />
          ) : (
            <TopupNGOForm
              onChange={handleChange}
              setFile={setFile}
              file={file}
              wallets={wallets}
              setWalletId={setWalletId}
            />
          )}
          <Button
            primary
            className="sidebar__submit-btn"
            floated="right"
            size="medium"
            content="Initiate"
            type="submit"
          />
        </Form>
      </div>
    </Sidebar>
  );
};

TopUpSidebar.propTypes = {
  filterOn: PropTypes.bool.isRequired,
  setActionType: PropTypes.func.isRequired,
  roles: PropTypes.array,
  currentOrgId: PropTypes.number,
  wallets: PropTypes.oneOfType([PropTypes.object]),
  orgUsers: PropTypes.instanceOf(Array),
};

TopUpSidebar.defaultProps = {
  roles: [],
  currentOrgId: 0,
  wallets: [],
  orgUsers: [],
};

export default TopUpSidebar;
