import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import SendForm from 'components/Communications/Activity/SendForm';
import isParentOrganization from 'helpers/isParentOrganization';
import { canAccess } from 'helpers/permissions';
import splitParams from 'helpers/splitParams';
import getUserRoles from 'helpers/getUserRoles';
import './index.scss';
import AllActivity from 'components/Communications/Activity/AllActivity';
import RecentActivity from 'components/Communications/Activity/RecentActivity';
import permissions from 'constants/permissions';

const Emails = () => {
  const { currentOrgId, orgUsers, roles } = getUserRoles();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const isCurrentOrgParent = isParentOrganization();

  useEffect(() => {
    if (
      !isEmpty(roles) &&
      currentOrgId &&
      !canAccess(permissions.canAccess.communications, roles, {
        currentOrgId,
        orgUsers,
        additionalCondition: isCurrentOrgParent,
      })
    ) {
      push('/');
    }
  }, [orgUsers, currentOrgId]);

  return (
    <section className="emails">
      {splitParams(pathname).includes('all') ? (
        <AllActivity
          type="emails"
          title="All Emails"
          subTitle="All Emails"
          params="emails"
        />
      ) : (
        <div className="emails-container">
          <SendForm type="emails" title="Send Emails to" />
          <RecentActivity
            type="emails"
            title="Recent Emails"
            params="emails"
          />
        </div>
      )}
    </section>
  );
};

export default Emails;
