import OrganizationSettings from 'containers/Settings';

export default {
  exact: true,
  name: 'Settings',
  protected: true,
  path: '/settings/:tab?/:option?/:action?',
  component: OrganizationSettings,
  permissions: ['all'],
};
