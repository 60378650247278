import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import startWithVowel from 'helpers/startWithVowel';
import isToday from 'helpers/isToday';
import ResourceLink from './ResourceLink';

const ActivityMessage = ({ item }) => {
  return (
    <div>
      <span>
        <Link to={`/users/${item.user?.id}`}>
          {item?.user?.first_name}
        </Link>{' '}
        <span>
          {item.actual_action ||
            `${item.action} ${startWithVowel(item.resource[0])} ${
              item.resource
            }
        `}
        </span>{' '}
        <ResourceLink
          activity={item}
          resource={item[item.resource]}
          resourceName={item.resource}
        />
        {`, ${
          isToday(new Date(item.created_at))
            ? moment(item.created_at).fromNow()
            : moment(item.created_at).format('LLLL')
        }`}
      </span>
    </div>
  );
};

ActivityMessage.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default ActivityMessage;
