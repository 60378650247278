import {
  TRANSFER_GROUPS_ERROR,
  TRANSFER_GROUPS_START,
  TRANSFER_GROUPS_SUCCESS,
} from 'constants/action-types/groups/transferGroups';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';
import getAgent from '../organizations/getAgent';

export default (data, organizationId, agentId) => (
  dispatch,
  onClose,
) =>
  dispatch(
    apiAction({
      url: '/groups/transfer',
      method: 'POST',
      data,
      API_REQUEST_START: TRANSFER_GROUPS_START,
      API_REQUEST_SUCCESS: TRANSFER_GROUPS_SUCCESS,
      API_REQUEST_FAILURE: TRANSFER_GROUPS_ERROR,
      callbackOnSuccess: ({ message }) => {
        onClose(false);
        notification({ message });
        getAgent(organizationId, '', agentId)(dispatch);
      },
      callbackOnFailure: ({ message }) => {
        if (message !== 'Wrong PIN.') {
          onClose(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
