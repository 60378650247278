import {
  AGENT_ACTIVITIES_ERROR,
  AGENT_ACTIVITIES_START,
  AGENT_ACTIVITIES_SUCCESS,
} from 'constants/action-types/organizations/agentActivities';

export default (state, { type, payload }) => {
  switch (type) {
    case AGENT_ACTIVITIES_START:
      return {
        ...state,
        agentActivities: {
          ...state.agentActivities,
          loading: true,
          error: null,
        },
      };
    case AGENT_ACTIVITIES_ERROR:
      return {
        ...state,
        agentActivities: {
          ...state.agentActivities,
          error: payload,
          loading: false,
        },
      };
    case AGENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        agentActivities: {
          ...state.agentActivities,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    default:
      return null;
  }
};
