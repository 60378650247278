import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import Proptype from 'prop-types';
import Message from 'components/common/Message';
import getMicroloansSettingData from 'redux/actions/settings/getMicroloansSettingData';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import labelFormatter from 'helpers/labelFormatter';

const RequestAmount = ({ type }) => {
  const [form, setForm] = useState({});
  const [openPinModal, setOpenPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');

  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettingsData } }) =>
      microloanSettingsData,
  );
  const { loading: updateLoading, error: updateError } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    getMicroloansSettingData({
      section: 'Request Amount',
      type,
    })(dispatch);
  }, []);

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: userPin,
      type,
      section: 'Request Amount',
      data: [
        form.round_1 || data?.[0],
        form.round_2 || data?.[1],
        form.round_3 || data?.[2],
        form.round_4 || data?.[3],
        form.round_5 || data?.[4],
      ],
    })(dispatch, () => setOpenPinModal(false));
  };

  return (
    <>
      <ConfirmPinModal
        content={`Are you sure you want to update the settings for ${labelFormatter(
          type,
        )}`}
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        userPin={userPin}
        setUserPin={setUserPin}
        error={{ message: updateError }}
        loading={updateLoading}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
      />
      <Form
        onSubmit={() => setOpenPinModal(!openPinModal)}
        loading={loading}
      >
        <h5>Maximum request amount at each round</h5>
        {!loading && error && (
          <Message message={error.message} color="red" />
        )}

        {data && (
          <>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="1st round (Rwf)"
                placeholder="19"
                onChange={handleChange}
                name="round_1"
                defaultValue={data?.[0]}
              />

              <Form.Field
                control={Input}
                type="number"
                label="2nd round (Rwf)"
                placeholder="19"
                onChange={handleChange}
                name="round_2"
                defaultValue={data?.[1]}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="3rd round (Rwf)"
                placeholder="19"
                onChange={handleChange}
                name="round_3"
                defaultValue={data?.[2]}
              />

              <Form.Field
                control={Input}
                type="number"
                label="4th round (Rwf)"
                placeholder="19"
                onChange={handleChange}
                name="round_4"
                defaultValue={data?.[3]}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                type="number"
                label="5th round and upwards (Rwf)"
                placeholder="19"
                onChange={handleChange}
                name="round_5"
                defaultValue={data?.[4]}
              />
            </Form.Group>
          </>
        )}

        <div className="action">
          <Button
            type="submit"
            primary
            loading={updateLoading}
            disabled={updateLoading || Object.keys(form).length === 0}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
RequestAmount.propTypes = {
  type: Proptype.string.isRequired,
};

export default RequestAmount;
