import {
  LIST_ORGANIZATIONS_ERROR,
  LIST_ORGANIZATIONS_START,
  LIST_ORGANIZATIONS_SUCCESS,
} from 'constants/action-types/organizations/listOrganizations';

import apiAction from 'helpers/apiAction';

export default queries => dispatch =>
  dispatch(
    apiAction({
      url: '/organizations',
      queries,
      method: 'GET',
      API_REQUEST_START: LIST_ORGANIZATIONS_START,
      API_REQUEST_SUCCESS: LIST_ORGANIZATIONS_SUCCESS,
      API_REQUEST_FAILURE: LIST_ORGANIZATIONS_ERROR,
      onEnd: () => false,
    }),
  );
