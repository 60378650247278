import React, { useEffect } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Empty from 'components/common/Empty';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions';
import getCommissions from 'redux/actions/settings/getCommissions';
import getUserRoles from 'helpers/getUserRoles';
import CommissionList from 'components/Settings/Commission/CommissionList';
import './index.scss';

const Commission = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { loading, error, data: commissions } = useSelector(
    ({ settings: { commissions } }) => commissions,
  );

  useEffect(() => {
    getCommissions()(dispatch);
  }, []);

  const { currentOrgId, roles, orgUsers } = getUserRoles();

  const rightComponent = (
    <>
      {canPerformAction(
        permissions.canPerformAction.canEditCommission,
        roles,
        { orgUsers, currentOrgId },
      ) && (
        <Button
          primary
          onClick={() => push('/settings/commission/edit')}
        >
          Edit
        </Button>
      )}
    </>
  );
  return (
    <>
      <Toolbar
        header={{
          title: 'Agent’s commission',
          navigation: false,
          rightComponent,
        }}
        style={{
          marginLeft: '0px',
        }}
      />
      <div className="commissions">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : Object.keys(commissions).length !== 0 ? (
          <CommissionList commissions={commissions} />
        ) : (
          <Empty message="No commission setting" />
        )}
      </div>
    </>
  );
};

export default Commission;
