/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import LoanRequestTable from 'containers/MicroLoanRequests/LoanRequestTable';
import Empty from 'components/common/Empty';
import './index.scss';
import getLoanRequests from 'redux/actions/loanRequests/getLoanRequests';
import SidebarFilter from 'components/common/SidebarFilter';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import getProfileOverview from 'redux/actions/organizations/profiles/profileOverview';
import getCurrentOrg from 'helpers/getCurrentOrg';
import SingleLoanRequest from './SingleRequest';

const ProfileRequestList = () => {
  const params = useParams();
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const urlParams = new URLSearchParams(search);
  const queryParams = Object.fromEntries(urlParams);
  const [filterOn, setFilterOn] = useState(false);
  const [filterForm, setFilterForm] = useState({});
  const {
    organizationId,
    profileId: theProfileId,
    tabOption,
  } = params;
  const profileId = theProfileId || tabOption;

  const { items, meta, loading, error } = useSelector(
    ({ loanRequests: { pendingLoanRequests } }) =>
      pendingLoanRequests,
  );
  const {
    data: { profile },
  } = useSelector(
    ({ profiles: { profileOverview } }) => profileOverview,
  );

  const dispatch = useDispatch();
  const currentUserOrganization = getCurrentOrg();

  useEffect(() => {
    getLoanRequests({
      organization_profile_id: profileId,
      ...queryParams,
    })(dispatch);
  }, [profileId, filterForm]);

  useEffect(() => {
    getProfileOverview(
      organizationId || currentUserOrganization?.id,
      profileId,
    )(dispatch);
  }, [organizationId, currentUserOrganization, profileId]);

  const getRequests = (
    profileId,
    queries = queryString.parse(search),
  ) => {
    return getLoanRequests({
      organization_profile_id: profileId,
      ...queries,
    })(dispatch);
  };

  const onSearch = queries => {
    getRequests(profileId, queries);
  };

  const onPaginate = queries => {
    getRequests(profileId, queries);
  };

  const defaultValue = {
    group_type: filterForm?.group_type,
    type: filterForm?.type,
    status: filterForm?.status,
    purpose: filterForm?.purpose,
  };

  const handleChange = (e, { value, name }) => {
    if (name === 'type')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'type'),
          [name]: value,
        }),
      );
    if (name === 'status')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'status'),
          [name]: value,
        }),
      );
    if (name === 'group_type')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'group_type'),
          [name]: value,
        }),
      );
    if (name === 'purpose')
      push(
        formatSearchParameter({
          ...omit(defaultValue, 'purpose'),
          [name]: value,
        }),
      );
    setFilterForm({ ...filterForm, [name]: value });
  };

  if (
    params.organizationId &&
    params.tab === 'requests' &&
    params.tabOption
  ) {
    return <SingleLoanRequest />;
  }

  return (
    <>
      <div className="profile-requests__lists">
        <Toolbar
          header={{
            title:
              profile && pathname.includes('/profile')
                ? `${profile.name} > Requests`
                : 'Requests',

            navigation: true,
            rightComponent: (
              <ToolbarFooter
                onSearch={onSearch}
                onPaginate={onPaginate}
                paginationMeta={meta}
                filter
                filterClicked={() => setFilterOn(true)}
                exportCSVData={items}
                exportCSVFileName="microloan-requests"
              />
            ),
          }}
        />

        <div className="list-profiles__items scroll-wrapper">
          {loading ? (
            <Loader />
          ) : isEmpty(items) ? (
            <Empty message="No pending request" />
          ) : error ? (
            <Message negative>{error?.message || error}</Message>
          ) : (
            <LoanRequestTable items={items} />
          )}
        </div>
      </div>
      <SidebarFilter
        filterOn={filterOn}
        setFilterOn={setFilterOn}
        handleChange={handleChange}
        form={filterForm}
        setForm={setFilterForm}
        activity="microloanRequests"
      />
    </>
  );
};

export default ProfileRequestList;
