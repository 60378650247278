import {
  UPDATE_FEATURE_ERROR,
  UPDATE_FEATURE_START,
  UPDATE_FEATURE_SUCCESS,
} from 'constants/action-types/settings/updateFeature';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default (id, data) => (dispatch, setPinModal) => {
  dispatch(
    apiAction({
      url: `/settings/${id}`,
      method: 'PUT',
      data,
      API_REQUEST_START: UPDATE_FEATURE_START,
      API_REQUEST_FAILURE: UPDATE_FEATURE_ERROR,
      API_REQUEST_SUCCESS: UPDATE_FEATURE_SUCCESS,
      onSuccess: payload => {
        dispatch({
          type: UPDATE_FEATURE_SUCCESS,
          payload,
        });
        setPinModal(false);
        notification({
          message: 'Setting feature successfully updated',
        });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: UPDATE_FEATURE_ERROR,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          setPinModal(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
};
