import initialState from 'redux/initial-states/user';
import updateTransactionStatus from './updateTransactionStatus';
import allGroups from './allGroups';
import groupTransactions from './groupTransactions';
import memberOverdueLoans from './memberOverdueLoans';
import memberTransactions from './memberTransactions';
import resetGroupShares from './resetGroupShares';
import recomputeGroupBalance from './recomputeGroupBalance';
import getGroupAccessBankBalance from './getGroupAccessBankBalance';
import uploadBulkTransaction from './uploadBulkTransaction';

export default (state = initialState, action = {}) => ({
  ...state,
  ...allGroups(state, action),
  ...memberTransactions(state, action),
  ...memberOverdueLoans(state, action),
  ...groupTransactions(state, action),
  ...resetGroupShares(state, action),
  ...recomputeGroupBalance(state, action),
  ...getGroupAccessBankBalance(state, action),
  ...updateTransactionStatus(state, action),
  ...uploadBulkTransaction(state, action),
});
