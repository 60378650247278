import {
  DELETE_ADVERTISEMENT_ERROR,
  DELETE_ADVERTISEMENT_START,
  DELETE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/deleteAdvertisement';

export default (state, { type, payload }) => {
  switch (type) {
    case DELETE_ADVERTISEMENT_START:
      return {
        ...state,
        deleteAdvertisement: {
          loading: true,
          error: null,
        },
      };
    case DELETE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        deleteAdvertisement: {
          ...state.deleteAdvertisement,
          error: payload,
          loading: false,
        },
      };
    case DELETE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        deleteAdvertisement: {
          ...state.deleteAdvertisement,
          message: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
