import {
  GET_SINGLE_ADVERTISEMENT_ERROR,
  GET_SINGLE_ADVERTISEMENT_START,
  GET_SINGLE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/getSingleAdvertisement';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';

export default id => dispatch =>
  dispatch(
    apiAction({
      url: `/advertisements/${id}`,
      method: 'GET',
      id,
      API_REQUEST_START: GET_SINGLE_ADVERTISEMENT_START,
      API_REQUEST_SUCCESS: GET_SINGLE_ADVERTISEMENT_SUCCESS,
      API_REQUEST_FAILURE: GET_SINGLE_ADVERTISEMENT_ERROR,

      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
