import Wallet from 'containers/Wallet';

export default {
  exact: true,
  name: 'users',
  protected: true,
  path: '/wallets',
  component: Wallet,
  permissions: ['all'],
};
