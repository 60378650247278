import apiAction from 'helpers/apiAction';
import {
  CHANGE_PIN_START,
  CHANGE_PIN_SUCCESS,
  CHANGE_PIN_FAIL,
  CHANGE_PIN_CLEAR,
} from 'constants/action-types/user/changePin';

export default (pins, token, push) => dispatch => {
  if (token) {
    localStorage.setItem('token', `${token}`);
  }

  return dispatch(
    apiAction({
      url: '/auth/change_password',
      method: 'POST',
      data: {
        old_password: pins.oldPin,
        new_password: pins.newPin,
      },
      API_REQUEST_START: CHANGE_PIN_START,
      API_REQUEST_SUCCESS: CHANGE_PIN_SUCCESS,
      API_REQUEST_FAILURE: CHANGE_PIN_FAIL,
      onSuccess: data => {
        dispatch({
          type: CHANGE_PIN_SUCCESS,
          payload: data.message,
        });

        if (token) {
          setTimeout(() => {
            push('/');
          }, 3000);
        }
      },
      onFailure: error => {
        const message = error.errors ? 'Invalid Pin' : error.message;
        dispatch({
          type: CHANGE_PIN_FAIL,
          payload: message,
        });
      },
    }),
  );
};

export const clearSuccessMessage = () => dispatch => {
  return dispatch({
    type: CHANGE_PIN_CLEAR,
  });
};
