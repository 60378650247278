import {
  GET_PARTNER_FAILED,
  GET_PARTNER_START,
  GET_PARTNER_SUCCESS,
} from 'constants/action-types/projects/getPartner';

import apiAction from 'helpers/apiAction';

export default (organizationId, projectId, partnerId) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}/partners/${partnerId}/overview`,
      method: 'GET',
      API_REQUEST_START: GET_PARTNER_START,
      API_REQUEST_SUCCESS: GET_PARTNER_SUCCESS,
      API_REQUEST_FAILURE: GET_PARTNER_FAILED,
      onEnd: () => false,
    }),
  );
};
