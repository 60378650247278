/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import LineChartWrapper from 'components/common/charts/LineChart/LineChartWrapper/LineChartWrapper';
import todaysDate from 'utils/todaysDate';

const StatCharts = ({
  data: {
    actives_vs_inactives_members: activeVsInactiveMembers = [],
    cumulative_savings_vs_cumulative_loans = [],
  },
  year,
}) => {
  const shortMonthActiveVsInactiveMembers = activeVsInactiveMembers.map(
    item => ({
      ...item,
      name: item.month.substring(0, 3),
    }),
  );

  const cleanSavingsVsLoans = cumulative_savings_vs_cumulative_loans.map(
    item => {
      return {
        name: item.month.substring(0, 3),
        Savings: item.savings,
        Loans: item.loans,
      };
    },
  );

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChartWrapper
            title="Active Vs Inactive Members"
            dataKey1="actives"
            dataKey2="inactives"
            width={440}
            height={290}
            data={shortMonthActiveVsInactiveMembers}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <BarChart
            {...{
              title: 'Cumulative Savings Vs Cumulative Loans',
              date: todaysDate(year),
              data: cleanSavingsVsLoans,
              dataKey1: 'Savings',
              dataKey2: 'Loans',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

StatCharts.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default StatCharts;
