import {
  REAPPROVE_MEMBER_REQUEST_ERROR,
  REAPPROVE_MEMBER_REQUEST_START,
  REAPPROVE_MEMBER_REQUEST_SUCCESS,
} from 'constants/action-types/groups/reApproveMemberRequest';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import getGroupActivities from '../organizations/getGroupActivities';

export default (groupId, requestId, data) => (
  dispatch,
  setPinModal,
) =>
  dispatch(
    apiAction({
      url: `/groups/${groupId}/requests/${requestId}/reapprove`,
      method: 'POST',
      data,
      API_REQUEST_START: REAPPROVE_MEMBER_REQUEST_START,
      API_REQUEST_SUCCESS: REAPPROVE_MEMBER_REQUEST_SUCCESS,
      API_REQUEST_FAILURE: REAPPROVE_MEMBER_REQUEST_ERROR,
      onSuccess: payload => {
        dispatch({
          type: REAPPROVE_MEMBER_REQUEST_SUCCESS,
          payload,
        });
        setPinModal(false);
        notification({ message: 'Request successfully re-approved' });
        getGroupActivities(groupId, { category: 'no_transactions' })(
          dispatch,
        );
      },
      onFailure: ({ message }) => {
        dispatch({
          type: REAPPROVE_MEMBER_REQUEST_ERROR,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          setPinModal(false);
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
