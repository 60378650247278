import ReactGA from 'react-ga';

const trackingCode = () => {
  if (process.env.NODE_ENV !== 'production') {
    return process.env
      .REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE_STAGING;
  }

  return process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE_PROD;
};

ReactGA.initialize(trackingCode());
ReactGA.pageview('/');
