/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Form,
  Message,
  Image,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './CreateOrganization.scss';
import { isEmpty, keys } from 'lodash';
import phoneUtils from '@exuus/rwanda-phone-utils';
import Toolbar from 'components/common/Toolbar';
import PhoneInput from 'components/common/PhoneInput';
import createOrganization from 'redux/actions/organizations/createOrganization';
import UserSearch from 'containers/Users/UserSearch';
import Toggle from 'components/common/Toggle';
import BlankImage from 'assets/images/blank_image.png';
import DeleteUpload from 'assets/images/delete_image.svg';
import UploadImage from 'assets/images/upload_image.svg';
import { toAreaDropDownOptions } from 'utils/dropdownOptions';
import organizationTypes, {
  organizationCategory,
} from 'constants/options/organizationTypes';

const CreateOrganization = ({ history: { push } }) => {
  const { items } = useSelector(
    ({ user: { searchUser } }) => searchUser,
  );

  const [form, setForm] = useState({});
  const [userExist, setUserExist] = useState(false);
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const imageRef = useRef(null);
  const formData = new FormData();

  const { loading, error } = useSelector(
    ({ organizations: { create } }) => create,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onImageChange = e => {
    const images = e.target.files;
    if (images?.[0]) {
      setImage(images?.[0]);
      setImageURL(URL.createObjectURL(images?.[0]));
    }
  };

  const openImageSelection = () => {
    if (isEmpty(imageURL)) return imageRef.current.click();
    setImage(null);
    setImageURL(null);
  };

  const handleChange = (e, { name, value }) => {
    if (name === 'phone_number' || name === 'admin_phone_number')
      value = phoneUtils(value).format('unformatted');

    setForm({ ...form, [name]: value });
    if ((items || []).some(item => item.phone_number === value)) {
      return setUserExist(true);
    }
    setUserExist(false);
  };

  const handleSubmit = () => {
    keys(form).map(key => formData.append(key, form[key]));
    formData.append('logo', image);

    return createOrganization(formData)(dispatch, push);
  };

  return (
    <>
      <Toolbar header={{ title: 'New Organization' }} />
      <section className="create-organization scroll-wrapper">
        <Form
          className="create-organization__left"
          onSubmit={handleSubmit}
          loading={loading}
        >
          <Form.Field
            required
            type="text"
            label="Name"
            control={Input}
            name="name"
            placeholder="Name"
            onChange={handleChange}
          />
          <Form.Group widths="2">
            <Form.Field
              control={Input}
              required
              label="Email"
              type="email"
              name="email"
              placeholder="email"
              onChange={handleChange}
            />
            <PhoneInput
              required
              id="phone"
              name="phone_number"
              organization
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Dropdown
            required
            type="text"
            label="Type"
            search
            selection
            name="type"
            placeholder="Type"
            options={toAreaDropDownOptions(organizationTypes)}
            onChange={handleChange}
          />

          <Form.Dropdown
            required
            type="text"
            label="Category"
            search
            selection
            name="category"
            options={toAreaDropDownOptions(
              organizationCategory?.[form?.type],
            )}
            placeholder="Category"
            onChange={handleChange}
          />

          <Form.Field
            required
            control={Input}
            type="text"
            label="Address"
            name="address"
            placeholder="Address"
            onChange={handleChange}
          />

          <div className="create-organization__title padded">
            <div>Admin&#39;s Details</div>
          </div>

          <UserSearch
            name="admin_phone_number"
            handleChange={handleChange}
          />

          {!userExist && (
            <>
              <Form.Group widths="2">
                <Form.Field
                  required
                  type="text"
                  label="First name"
                  control={Input}
                  name="admin_first_name"
                  placeholder="First name"
                  onChange={handleChange}
                />
                <Form.Field
                  required
                  type="text"
                  label="Last name"
                  control={Input}
                  name="admin_last_name"
                  placeholder="Last name"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group widths="2">
                <Form.Field
                  control={Input}
                  required
                  label="Email"
                  type="email"
                  name="admin_email"
                  placeholder="Email"
                  onChange={handleChange}
                />
                <Form.Field
                  control={Input}
                  required
                  label="Position"
                  type="text"
                  name="admin_position"
                  placeholder="Position"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Field
                required
                control={Input}
                type="text"
                label="ID Number"
                name="admin_id_number"
                placeholder="ID Number"
                onChange={handleChange}
              />
            </>
          )}
          {error && (
            <Message
              negative
              visible={false}
              content={
                error && error.errors
                  ? error.errors[0].message.replace(/"/g, '')
                  : error && error.message
              }
            />
          )}

          <div className="create-organization__title padded">
            <div>Features</div>
          </div>

          <Toggle
            title="wallet"
            status={form?.hasWallet}
            handleChange={checked =>
              handleChange(null, {
                name: 'has_wallet',
                value: checked,
              })
            }
          />
          <Toggle
            title="member loans"
            status={form?.memberOrgLoans}
            handleChange={checked =>
              handleChange(null, {
                name: 'has_member_org_loan',
                value: checked,
              })
            }
          />
          <Toggle
            title="group loans"
            status={form?.groupOrgLoans}
            handleChange={checked =>
              handleChange(null, {
                name: 'has_group_org_loan',
                value: checked,
              })
            }
          />
          <Button
            type="submit"
            floated="right"
            color="primary"
            disabled={loading}
          >
            {t('Create')}
          </Button>
        </Form>
        <div className="create-organization__right">
          Organization Logo
          <div
            className="create-organization__image"
            style={{
              backgroundImage: `url(${imageURL || BlankImage})`,
            }}
          >
            <Image
              className="create-organization__image--select"
              src={imageURL ? DeleteUpload : UploadImage}
              onClick={openImageSelection}
            />
            <input
              style={{ display: 'none' }}
              ref={imageRef}
              type="file"
              accept="image/*"
              onChange={onImageChange}
            />
          </div>
        </div>
      </section>
    </>
  );
};

CreateOrganization.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CreateOrganization;
