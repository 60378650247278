import {
  UNBLOCK_USER_START,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
} from 'constants/action-types/user/unblockUser';
import userState from '../../initial-states/user';

export default (state = userState, { type, payload }) => {
  switch (type) {
    case UNBLOCK_USER_START:
      return {
        ...state,
        unblockUser: {
          ...state.unblockUser,
          loading: true,
        },
      };
    case UNBLOCK_USER_ERROR:
      return {
        ...state,
        unblockUser: {
          ...state.unblockUser,
          error: payload,
          loading: false,
        },
      };

    case UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        unblockUser: {
          ...state.unblockUser,
          message: payload,
          error: {},
          loading: false,
        },
      };
    default:
      return null;
  }
};
