import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import './index.scss';
import formatNumber from 'helpers/formatNumber';

const Cycle = ({ name, date, count, countLabel }) => {
  return (
    <Card className="cycle">
      <Card.Content>
        <div className="cycle__header">
          <div className="cycle__header--name">{name}</div>
          <div className="cycle__header--date">
            {new Date(date).toDateString()}
          </div>
        </div>
        <div className="cycle__footer">
          <span className="cycle__footer--count">
            {formatNumber(count)}
          </span>
          <span className="cycle__footer--count_label">
            {countLabel}
          </span>
        </div>
      </Card.Content>
    </Card>
  );
};

Cycle.defaultProps = {
  count: 0,
};

Cycle.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  countLabel: PropTypes.string.isRequired,
};

export default Cycle;
