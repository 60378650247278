export const GET_MICRO_LOANS_SETTINGS_DATA_START =
  'GET_MICRO_LOANS_SETTINGS_DATA_START';
export const GET_MICRO_LOANS_SETTINGS_DATA_SUCCESS =
  'GET_MICRO_LOANS_SETTINGS_DATA_SUCCESS';
export const GET_MICRO_LOANS_SETTINGS_DATA_ERROR =
  'GET_MICRO_LOANS_SETTINGS_DATA_ERROR';

export const GET_MICRO_LOANS_SETTINGS_START =
  'GET_MICRO_LOANS_SETTINGS_START';
export const GET_MICRO_LOANS_SETTINGS_SUCCESS =
  'GET_MICRO_LOANS_SETTINGS_SUCCESS';
export const GET_MICRO_LOANS_SETTINGS_ERROR =
  'GET_MICRO_LOANS_SETTINGS_ERROR';
