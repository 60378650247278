import colors from '../assets/styles/colors.scss';

export default {
  approved: colors?.approvedColor,
  declined: colors?.declinedColor,
  pending: colors?.pendingColor,
  success: colors?.successColor,
  cleared: colors?.clearedColor,
  amended: colors?.amendedColor,
};
