import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';
import DetailsPopUp from 'components/common/DetailsPopUp';
import './ActivityItem.scss';
import capitalize from 'utils/capitalize';
import randomColor from 'utils/randomColor';
import abName from 'utils/abName';
import ExportCSV from 'components/common/ExportCSV';
import ActivityMessage from './ActivityMessage';
import ApprovalDetails from './ApprovalDetails';
import LoanInfoPopup from './LoanInfoPopup';

const ActivityItem = ({
  title,
  user,
  groupId,
  description,
  detailsPopup,
  hyperLink = true,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [requestModal, setRequestModal] = useState({
    header: '',
    body: '',
    context: '',
    model: false,
  });

  const modalContent = {
    'cancel-request': 'Cancel',
    're-approve-request': 'Re-Approve',
    're-trigger-request': 'Re-Trigger',
  };

  const openRequestModal = context => {
    setRequestModal({
      header: `${modalContent[context]} Request`,
      body: `Are you sure you want to ${
        modalContent[context]
      } ${capitalize(
        `${description?.user?.first_name} ${description?.user?.last_name}'s`,
      )} request?`,
      context,
      model: true,
    });
    setOpenPopup(false);
  };

  return (
    <>
      <div className="activity__item">
        <div
          className="activity__thumbnail"
          style={{ backgroundColor: randomColor() }}
        >
          <span>{abName(user?.first_name, user?.last_name)}</span>
        </div>
        <div className="activity__item--details">
          <div className="activity__item--details__body">
            <div className="activity__item--details____body__content">
              <span className="activity__item--title">{title}</span>
              <span className="activity__item--description">
                <ActivityMessage
                  item={description}
                  hyperLink={hyperLink}
                />
              </span>
            </div>
            <div className="activity__item--details__body__approvalStatus">
              {detailsPopup && (
                <>
                  <div
                    className={`activity__item--details__body__statusDot ${description?.request?.status}`}
                  />
                  <DetailsPopUp
                    groupId={groupId}
                    requestId={description?.request?.id}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    requestModal={requestModal}
                    setRequestModal={setRequestModal}
                    content={
                      <ApprovalDetails
                        data={description?.request}
                        status={description?.request?.status}
                        openRequestModal={openRequestModal}
                      />
                    }
                  />
                  {['success', 'approved'].includes(
                    description?.request?.status,
                  ) &&
                    ['share_out', 'bulk_share_out'].includes(
                      description?.request?.type,
                    ) && (
                      <ExportCSV
                        data={description?.request?.share_out_reports}
                        fileName="Share Out"
                        customExport
                      />
                    )}
                </>
              )}
              {description?.type === 'debit' &&
                description?.purpose === 'loan' && (
                  <LoanInfoPopup description={description} />
                )}
            </div>
          </div>
          <Divider />
        </div>
      </div>
    </>
  );
};

ActivityItem.defaultProps = {
  hyperLink: true,
};

ActivityItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  detailsPopup: PropTypes.bool.isRequired,
  groupId: PropTypes.number.isRequired,
  hyperLink: PropTypes.bool,
};

export default ActivityItem;
