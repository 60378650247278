export default (path, query) => {
  const queryParameter = Object.keys(query)
    .map(key => `${query[key] ? `${key}=${query[key]}` : ''}`)
    .filter(Boolean)
    .join('&');
  return { pathname: path, search: `?${queryParameter}` };
};

export const formatSearchParameter = parameter => {
  const query = Object.keys(parameter)
    .map(key => `${parameter[key] ? `${key}=${parameter[key]}` : ''}`)
    .filter(Boolean)
    .join('&');
  return { search: `?${query}` };
};
