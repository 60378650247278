import {
  APPROVAL_ORG_WALLET_REQUESTS_ERROR,
  APPROVAL_ORG_WALLET_REQUESTS_SUCCESS,
  APPROVAL_ORG_WALLET_REQUESTS_START,
} from 'constants/action-types/wallets/approvalOrgRequests';

export default (state, { type, payload }) => {
  switch (type) {
    case APPROVAL_ORG_WALLET_REQUESTS_START:
      return {
        ...state,
        approveOrgRequest: {
          ...state.approveOrgRequest,
          message: null,
          loading: true,
          error: null,
        },
      };
    case APPROVAL_ORG_WALLET_REQUESTS_ERROR:
      return {
        ...state,
        approveOrgRequest: {
          ...state.approveOrgRequest,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case APPROVAL_ORG_WALLET_REQUESTS_SUCCESS:
      return {
        ...state,
        approveOrgRequest: {
          ...state.approveOrgRequest,
          error: null,
          message: payload.message,
          loading: false,
        },
      };
    default:
      return null;
  }
};
