import {
  CREATE_REFERRAL_ALIAS_START,
  CREATE_REFERRAL_ALIAS_SUCCESS,
  CREATE_REFERRAL_ALIAS_ERROR,
} from 'constants/action-types/user/createReferralAlias';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_REFERRAL_ALIAS_START:
      return {
        ...state,
        createReferalAlias: {
          loading: true,
          error: null,
          message: null,
        },
      };
    case CREATE_REFERRAL_ALIAS_ERROR:
      return {
        ...state,
        createReferalAlias: {
          ...state.createReferalAlias,
          error: payload,
          loading: false,
        },
      };
    case CREATE_REFERRAL_ALIAS_SUCCESS:
      return {
        ...state,
        createReferalAlias: {
          ...state.createReferalAlias,
          message: payload.message,

          loading: false,
        },
      };

    default:
      return null;
  }
};
