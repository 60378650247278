export const frequencyTypes = ['daily', 'weekly', 'monthly'];

export const groupTypes = ['supervised', 'graduated'];

export const groupsPurpose = [
  'savings',
  'savings_and_loans',
  'merry_go_round',
];

export const providerTypes = [
  'mobile_money',
  'individual_wallet',
  'sg_cashin',
];

export const commissionRequiredFields = [
  'group_type',
  'group_purpose',
  'cashout_medium',
  'commission_per_active_user',
  'min_trx_daily_savings',
  'min_trx_weekly_savings',
  'min_trx_monthly_savings',
  'cashout_frequency',
  'initial_cashout_amount',
  'future_cashout_amount',
  'eligible_ngos',
  'description',
];

export const commissionNameTypes = {
  group_type: 'group_type',
  group_purpose: 'group_purpose',
  cashout_medium: 'cashout_medium',
  commission_per_active_user: 'commission_per_active_user',
  min_trx_daily_savings: 'min_trx_daily_savings',
  min_trx_weekly_savings: 'min_trx_weekly_savings',
  min_trx_monthly_savings: 'min_trx_monthly_savings',
  cashout_frequency: 'cashout_frequency',
  cashout_frequency_date: 'cashout_frequency_date',
  cashout_frequency_time: 'cashout_frequency_time',
  initial_cashout_amount: 'initial_cashout_amount',
  future_cashout_amount: 'future_cashout_amount',
  eligible_ngos: 'eligible_ngos',
  description: 'description',
  status: 'status',
};
