/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import Toolbar from 'components/common/Toolbar';
import ProjectSettings from 'containers/Projects/CreateProject';
import Partners from 'containers/Projects/Partners';
import ProjectOverview from 'containers/Organizations/SingleOrganization/Projects/SingleProject';
import getProject from 'redux/actions/projects/getProject';
import ProjectAgents from 'containers/Organizations/SingleOrganization/Agents';
import OrganizationGroups from 'containers/Organizations/SingleOrganization/Groups';
import GroupOrgRequests from 'containers/Organizations/SingleOrganization/Requests';

const PaneWrapper = ({ children }) => <>{children}</>;

const panes = props => {
  const {
    match: {
      params: { projectId, organizationId },
    },
  } = props;

  return [
    {
      menuItem: 'Overview',
      render: () => (
        <PaneWrapper>
          <ProjectOverview
            projectId={projectId}
            organizationId={organizationId}
            option1="overview"
          />
        </PaneWrapper>
      ),
    },
    {
      menuItem: 'Partners',
      render: () => (
        <PaneWrapper>
          <Partners {...props} />
        </PaneWrapper>
      ),
    },
    {
      menuItem: 'Agents',
      render: () => (
        <PaneWrapper>
          <ProjectAgents {...props} />
        </PaneWrapper>
      ),
    },
    {
      menuItem: 'Saving Groups',
      render: () => (
        <PaneWrapper>
          <OrganizationGroups {...props} />
        </PaneWrapper>
      ),
    },
    {
      menuItem: 'Requests',
      render: () => (
        <PaneWrapper>
          <GroupOrgRequests {...props} />
        </PaneWrapper>
      ),
    },
    {
      menuItem: 'Settings',
      render: () => (
        <PaneWrapper>
          <Toolbar
            header={{ title: 'Project Settings', navigation: true }}
          />
          <ProjectSettings {...props} noToolbar settings />
        </PaneWrapper>
      ),
    },
  ];
};

const ProjectTab = ({ location, history, match }) => {
  const {
    params: { organizationId, projectId, tab },
  } = match;

  const dispatch = useDispatch();

  useEffect(() => {
    getProject(organizationId, projectId)(dispatch);
  }, [projectId]);

  const tabs = {
    overview: { index: 0, name: 'overview' },
    partners: { index: 1, name: 'partners' },
    agents: { index: 2, name: 'agents' },
    groups: { index: 3, name: 'groups' },
    requests: { index: 4, name: 'requests' },
    settings: { index: 5, name: 'settings' },
  };

  const { push } = history;

  const activeIndex = !tabs[tab] ? 0 : tabs[tab].index;

  const getPath = tabName =>
    `/projects/${projectId}/organizations/${organizationId}/${tabName}`;

  const handleTabChange = (e, { activeIndex }) => {
    const key = Object.keys(tabs).find(
      key => tabs[key].index === activeIndex,
    );
    push(getPath(key));
  };

  return (
    <Tab
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      panes={panes({ location, history, match })}
      onTabChange={handleTabChange}
    />
  );
};

PaneWrapper.propTypes = {
  children: PropTypes.func.isRequired,
};

ProjectTab.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
};

export default ProjectTab;
