import {
  GET_DASHBOARD_CURRENT_ORGANIZATION_START,
  GET_DASHBOARD_CURRENT_ORGANIZATION_SUCCESS,
  GET_DASHBOARD_CURRENT_ORGANIZATION_FAILURE,
} from 'constants/action-types/dashboard/currentOrganization';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD_CURRENT_ORGANIZATION_START:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          loading: true,
          error: null,
        },
      };
    case GET_DASHBOARD_CURRENT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          details: {
            ...payload,
          },
          loading: false,
        },
      };
    case GET_DASHBOARD_CURRENT_ORGANIZATION_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentOrganization,
          loading: false,
          error: null,
        },
      };
    default:
      return null;
  }
};
