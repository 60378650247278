import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import AppSideBar from 'containers/AppSideBar';
import AppHeader from 'components/common/AppHeader';
import AppNavPanel from 'components/common/AppNavPanel';
import getCurrentUser from 'redux/actions/user/currentUser';
import './index.scss';
import PageLoader from 'components/common/PageLoader';

const DashBoard = ({ children, location: { pathname } }) => {
  const dispatch = useDispatch();
  const { details: user } = useSelector(
    ({ user: { currentUser = {} } = {} }) => currentUser,
  );

  useEffect(() => {
    getCurrentUser()(dispatch);
  }, [dispatch]);

  const showOrHideNavPanel = pathname => {
    let show = true;
    const hideNavPanelRoutes = [
      '/create-project',
      '/organizations',
      '/projects',
      '/organizations/users',
      '/projects',
      '/requests',
      '/projects/invite',
      '/profiles',
    ];

    hideNavPanelRoutes.forEach(item => {
      if (pathname.includes(item)) {
        show = false;
      }
    });

    return show;
  };

  return (
    <div id="app">
      <AppSideBar />
      <div id="content">
        <AppHeader />
        <div id="pages">
          <div className="pannels-row">
            {showOrHideNavPanel(pathname) && <AppNavPanel />}
            {isEmpty(user) ? (
              <PageLoader center />
            ) : (
              <div className="pannels-column">{children}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DashBoard.defaultProps = {
  children: '',
};

DashBoard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(DashBoard);
