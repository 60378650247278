import {
  GET_ORG_WALLET_ERROR,
  GET_ORG_WALLET_START,
  GET_ORG_WALLET_SUCCESS,
} from 'constants/action-types/organizations/getOrgWithWallet';

import apiAction from 'helpers/apiAction';

export default () => dispatch =>
  dispatch(
    apiAction({
      url: '/organizations',
      queries: { has_wallet: 'true' },
      method: 'GET',
      API_REQUEST_START: GET_ORG_WALLET_START,
      API_REQUEST_SUCCESS: GET_ORG_WALLET_SUCCESS,
      API_REQUEST_FAILURE: GET_ORG_WALLET_ERROR,
      onEnd: () => false,
    }),
  );
