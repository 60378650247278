import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Input, Icon } from 'semantic-ui-react';
import Proptype from 'prop-types';
import { isArray } from 'lodash';
import Message from 'components/common/Message';
import './repayment.scss';
import getMicroloansSettingData from 'redux/actions/settings/getMicroloansSettingData';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import labelFormatter from 'helpers/labelFormatter';

const Repayment = ({ type }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([
    {
      id: 1,
      range_from: '',
      range_to: '',
      minimum_installments: '',
    },
  ]);
  const [openPinModal, setOpenPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');

  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettingsData } }) =>
      microloanSettingsData,
  );
  const { loading: updateLoading, error: updateError } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  useEffect(() => {
    getMicroloansSettingData({
      section: 'Repayment',
      type,
    })(dispatch);
  }, []);

  useEffect(() => {
    if (data && isArray(data)) {
      setForm(
        data.map((data, index) => ({
          id: index + 1,
          ...data,
        })),
      );
    }
  }, [data]);

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: userPin,
      section: 'Repayment',
      type,
      data: form.map(range => ({
        ...range,
        id: undefined,
      })),
    })(dispatch, () => setOpenPinModal(false));
  };

  const addFormItem = () => {
    setForm([
      ...form,
      {
        id: form.length + 1,
        range_from: '',
        range_to: '',
        minimum_installments: '',
      },
    ]);
  };

  const removeFormItem = id => {
    setForm(form.filter(item => item.id !== id));
  };

  return (
    <>
      <ConfirmPinModal
        content={`Are you sure you want to update the settings for ${labelFormatter(
          type,
        )}`}
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        userPin={userPin}
        setUserPin={setUserPin}
        error={{ message: updateError }}
        loading={updateLoading}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
      />
      <Form
        onSubmit={() => setOpenPinModal(!openPinModal)}
        className="microloans-repayment-settings"
        loading={loading}
      >
        {!loading && error && (
          <Message message={error.message} color="red" />
        )}

        {data &&
          form.map((item, index) => (
            <div
              widths="equal"
              className="microloans-repayment-settings__item"
            >
              <div>
                <span className="microloans-repayment-settings__item__range-label">
                  Range (Days)
                </span>
                <Form.Group
                  widths="equal"
                  className="microloans-repayment-settings__item__range-input"
                >
                  <Input
                    control={Input}
                    type="number"
                    placeholder="19"
                    name="form.range_from"
                    onChange={(_, { value }) => {
                      setForm(
                        form.map(formItem => {
                          if (formItem.id === item.id) {
                            return {
                              ...formItem,
                              range_from: value,
                            };
                          }
                          return formItem;
                        }),
                      );
                    }}
                    defaultValue={item.range_from}
                  />

                  <span className="input-separator" />

                  <Input
                    control={Input}
                    type="number"
                    placeholder="19"
                    onChange={(_, { value }) => {
                      setForm(
                        form.map(formItem => {
                          if (formItem.id === item.id) {
                            return {
                              ...formItem,
                              range_to: value,
                            };
                          }
                          return formItem;
                        }),
                      );
                    }}
                    name="form.range_to"
                    defaultValue={item.range_to}
                  />
                </Form.Group>
              </div>
              <div className="microloans-repayment-settings__item__minimum-installments">
                <Form.Field
                  control={Input}
                  type="number"
                  label="Minimum repayment instalments"
                  placeholder="19"
                  onChange={(_, { value }) => {
                    setForm(
                      form.map(formItem => {
                        if (formItem.id === item.id) {
                          return {
                            ...formItem,
                            minimum_installments: value,
                          };
                        }
                        return formItem;
                      }),
                    );
                  }}
                  name="form.minimum_installments"
                  defaultValue={item.minimum_installments}
                  className="microloans-repayment-settings__item__minimum-installments__input"
                />
                {index === 0 || (
                  <Icon
                    name="minus"
                    className="cursor"
                    onClick={() => removeFormItem(item.id)}
                  />
                )}
                {index !== 0 || (
                  <Icon
                    name="plus"
                    className="cursor"
                    onClick={() => addFormItem()}
                  />
                )}
              </div>
            </div>
          ))}

        <div className="action">
          <Button
            type="submit"
            primary
            loading={updateLoading}
            disabled={updateLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
Repayment.propTypes = {
  type: Proptype.string.isRequired,
};

export default Repayment;
