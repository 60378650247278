import {
  UPDATE_GROUP_TRANSACTION_STATUS_ERROR,
  UPDATE_GROUP_TRANSACTION_STATUS_START,
  UPDATE_GROUP_TRANSACTION_STATUS_SUCCESS,
} from 'constants/action-types/groups/updateTransactionStatus';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_GROUP_TRANSACTION_STATUS_START:
      return {
        ...state,
        transactionStatusUpdate: {
          ...state.transactionStatusUpdate,
          loading: true,
          error: null,
        },
      };
    case UPDATE_GROUP_TRANSACTION_STATUS_ERROR:
      return {
        ...state,
        transactionStatusUpdate: {
          ...state.transactionStatusUpdate,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_GROUP_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        transactionStatusUpdate: {
          ...state.transactionStatusUpdate,
          loading: false,
          error: null,
          data: payload,
        },
      };
    default:
      return null;
  }
};
