import {
  GET_GROUP_ACCESS_BANK_BALANCE_START,
  GET_GROUP_ACCESS_BANK_BALANCE_SUCCESS,
  GET_GROUP_ACCESS_BANK_BALANCE_FAILED,
} from 'constants/action-types/groups/getGroupAccessBankBalance';
import notification from 'helpers/notificationHelper';
import { ERROR } from 'constants/notificationStatus';
import apiAction from 'helpers/apiAction';

export default (groupId, data) => (dispatch, setOpenModal) => {
  dispatch(
    apiAction({
      url: `/groups/${groupId}/access_bank_balance`,
      data,
      method: 'GET',
      API_REQUEST_START: GET_GROUP_ACCESS_BANK_BALANCE_START,
      API_REQUEST_SUCCESS: GET_GROUP_ACCESS_BANK_BALANCE_SUCCESS,
      API_REQUEST_FAILURE: GET_GROUP_ACCESS_BANK_BALANCE_FAILED,
      callbackOnFailure: ({ message }) => {
        dispatch({
          type: GET_GROUP_ACCESS_BANK_BALANCE_FAILED,
          payload: message,
        });
        notification({ message, status: ERROR });
        setOpenModal(false);
      },
      onEnd: () => false,
    }),
  );
};
