/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Image, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import './index.scss';
import Loader from 'components/common/PageLoader';
import getCurrentUser from 'redux/actions/user/currentUser';
import { GET_DASHBOARD_CURRENT_ORGANIZATION_SUCCESS } from 'constants/action-types/dashboard/currentOrganization';
import { GET_CURRENT_ORGANIZATION_SUCCESS } from 'constants/action-types/organizations/currentOrganization';
import logout from 'redux/actions/user/logout';
import notificationHelper from 'helpers/notificationHelper';
import whiteLogo from '../../assets/images/white_logo.png';
import orgIcon from '../../assets/images/orglogo.png';

const Login = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const previousUrl = localStorage.getItem('PREVIOUS_URL');
  const location = useLocation();

  const { details: user, loading, isAuthenticated } = useSelector(
    ({ user: { currentUser } }) => currentUser,
  );

  const setCurrentOrganization = organization => {
    localStorage.setItem(
      'CURRENT_ORGANIZATION',
      JSON.stringify(organization),
    );
    dispatch({
      type: GET_DASHBOARD_CURRENT_ORGANIZATION_SUCCESS,
      payload: organization,
    });

    dispatch({
      type: GET_CURRENT_ORGANIZATION_SUCCESS,
      payload: organization,
    });
    getCurrentUser()(dispatch);
    push(
      !!previousUrl && previousUrl !== location.pathname
        ? previousUrl
        : '/',
    );
  };

  useEffect(() => {
    getCurrentUser(organizations => {
      if (isAuthenticated && organizations?.length === 1) {
        setCurrentOrganization(user?.organizations?.[0]);
      }
    })(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      push('/login');
    }
  }, []);

  const handleSelection = id => {
    const currentOrganization = user?.organizations?.find(
      organization => organization?.id === id,
    );
    if (currentOrganization)
      setCurrentOrganization(currentOrganization);
    else
      notificationHelper(
        'Something went wrong when selecting',
        'error',
      );
  };

  const isUserActive = orgId =>
    user?.organizations_users?.every(organizationUser => {
      if (orgId === organizationUser?.organization_id)
        if (['user', 'admin']?.includes(organizationUser?.user_type))
          return organizationUser?.status === 'active';

      return true;
    });

  return (
    <section className="login select__org">
      <div className="container">
        <img src={whiteLogo} alt="" />
        <Form>
          <h3 className="header__text">Select Organization</h3>
          <Helmet defaultTitle="Save." titleTemplate="%s - Save.">
            <title>Select Organization</title>
          </Helmet>
          <div className="organization__list">
            {loading ? (
              <Loader />
            ) : (
              user?.organizations?.map(organization => (
                <Segment attached key={organization?.id}>
                  <div
                    onKeyDown={() => null}
                    onClick={() =>
                      isUserActive(organization?.id) &&
                      handleSelection(organization?.id)
                    }
                    role="button"
                    tabIndex="-1"
                    className={`${
                      !isUserActive(organization?.id)
                        ? 'action-prevented'
                        : ''
                    } pointer organization__list__item`}
                  >
                    <Image avatar src={orgIcon} />
                    <span>{organization.name}</span>
                    <span
                      className={`org__status ${
                        isUserActive(organization?.id)
                          ? `active`
                          : `inactive`
                      }`}
                    />
                  </div>
                </Segment>
              ))
            )}
          </div>
        </Form>
        <div className="forgot">
          <Link
            to="/login"
            onClick={() => {
              logout()(dispatch);
            }}
          >
            {t('Sign in')}
          </Link>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
