import {
  TOP_UP_WALLET_ERROR,
  TOP_UP_WALLET_START,
  TOP_UP_WALLET_SUCCESS,
} from 'constants/action-types/wallets/topUpWallet';

export default (state, { type, payload }) => {
  switch (type) {
    case TOP_UP_WALLET_START:
      return {
        ...state,
        topUpWallet: {
          ...state.topUpWallet,
          message: null,
          loading: true,
          error: null,
        },
      };
    case TOP_UP_WALLET_ERROR:
      return {
        ...state,
        topUpWallet: {
          ...state.topUpWallet,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case TOP_UP_WALLET_SUCCESS:
      return {
        ...state,
        topUpWallet: {
          ...state.topUpWallet,
          error: null,
          message: payload.message,
          loading: false,
        },
      };
    default:
      return null;
  }
};
