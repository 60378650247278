import {
  UPDATE_FEATURE_ERROR,
  UPDATE_FEATURE_START,
  UPDATE_FEATURE_SUCCESS,
} from 'constants/action-types/settings/updateFeature';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_FEATURE_START:
      return {
        ...state,
        updateFeature: {
          loading: true,
          message: null,
          data: [],
        },
      };
    case UPDATE_FEATURE_ERROR:
      return {
        ...state,
        updateFeature: {
          ...state.updateFeature,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_FEATURE_SUCCESS:
      return {
        ...state,
        updateFeature: {
          ...state.updateFeature,
          data: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
