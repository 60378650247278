import * as apiActionTypes from 'constants/apiActions';

export default ({
  url = '',
  method = 'GET',
  data = null,
  queries = null,
  resType = 'json',
  API_REQUEST_START = apiActionTypes.API_REQUEST_START,
  API_REQUEST_SUCCESS = apiActionTypes.API_REQUEST_SUCCESS,
  API_REQUEST_FAILURE = apiActionTypes.API_REQUEST_FAILURE,
  API_REQUEST_END = apiActionTypes.API_REQUEST_END,
  onStart,
  onSuccess,
  callbackOnSuccess,
  onFailure,
  callbackOnFailure,
  onEnd,
}) => {
  if (queries) {
    Object.keys(queries).forEach(key => {
      // Avoids sending tab and urlQueries to the backend
      if (['tab', 'urlQueries'].includes(key)) {
        return delete queries[key];
      }
      queries[key] =
        typeof queries[key] === 'string'
          ? queries[key].trim()
          : queries[key];
      return queries[key] || delete queries[key];
    });
  }

  return {
    type: apiActionTypes.API_REQUEST,
    payload: {
      url,
      method,
      resType,
      data,
      queries,
      API_REQUEST_START,
      API_REQUEST_SUCCESS,
      API_REQUEST_FAILURE,
      API_REQUEST_END,
      onStart,
      onSuccess,
      callbackOnSuccess,
      onFailure,
      callbackOnFailure,
      onEnd,
    },
  };
};
