import moment from 'moment';

const agents = data => {
  return [...data[0].data.rows];
};

const groups = data => {
  return [...data[1].data.rows];
};

const members = data => {
  return [...data[2].data.rows];
};

export default data => {
  return members(data).map(member => {
    if (
      !groups(data).some(({ id }) => Number(id) === Number(member.id))
    ) {
      throw new Error(
        `A member with the name ${member.first_name} is referencing a group with ID ${member.id} which was not found among the uploaded groups`,
      );
    }

    const group = groups(data).find(
      ({ id }) => Number(id) === Number(member.id),
    );
    const agent = agents(data).find(
      ({ id }) => Number(id) === Number(member.id),
    );

    return {
      ...member,
      id: undefined,
      id_number: String(member.id_number),
      fi_account_number: String(member.fi_account_number),
      phone_number: String(member.phone_number),
      birth_date: moment(String(member.birth_date)).toISOString(),
      group: {
        ...group,
        id: undefined,
        project: undefined,
        interest_rate: group.interest_rate,
        fi_account_number: String(group.fi_account_number),
      },
      agent: {
        ...agent,
        id: undefined,
        owns_smart_phone: undefined,
        saving_group: undefined,
        id_number: String(agent ? agent.id_number : null),
        phone_number: String(agent ? agent.phone_number : null),
      },
    };
  });
};
