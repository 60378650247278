import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import { getOrganizationByName } from 'redux/actions/organizations/getSingleOrganization';

const PartnerSearch = ({ handleChange, label, placeholder }) => {
  const { items, loading } = useSelector(
    ({ organizations: { organizationSearch } }) => organizationSearch,
  );
  const [value, setValue] = useState('');
  const [typing, setTyping] = useState(false);
  const [addedOptions, setAdddedOptions] = useState([]);

  const dispatch = useDispatch();

  const handleSearchChange = async (e, { searchQuery }) => {
    setValue(searchQuery);
    setTyping(true);

    await setTimeout(() => setTyping(false), 2000);

    if (!typing) {
      getOrganizationByName(searchQuery)(dispatch);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      getOrganizationByName(value)(dispatch);
    }
  };

  const itemOptions = items.map(({ id, name }) => ({
    text: name,
    value: id,
    key: id,
  }));

  const options = [...itemOptions, ...addedOptions];

  const handleAddition = (e, { value }) => {
    setAdddedOptions([{ text: value, value }]);
  };

  return (
    <Form.Field
      required
      control={Dropdown}
      search
      selection
      loading={loading}
      onSearchChange={handleSearchChange}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onAddItem={handleAddition}
      options={options}
      type="text"
      allowAdditions
      label={label || 'Name'}
      name="org_name"
      placeholder={placeholder || 'Name'}
    />
  );
};

PartnerSearch.defaultProps = {
  label: '',
  placeholder: '',
};

PartnerSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default PartnerSearch;
