import {
  RESET_PASSWORD_USER_START,
  RESET_PASSWORD_USER_SUCCESS,
  RESET_PASSWORD_USER_ERROR,
} from 'constants/action-types/user/resetUserPassword';
import * as notificationStatus from 'constants/notificationStatus';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';

export default (roles, organizationId, userPhoneNumber) => (
  dispatch,
  setModalActive,
) => {
  dispatch(
    apiAction({
      url: `/user/sa/reset_password`,
      method: 'POST',
      data: {
        phone_number: userPhoneNumber,
        organization_id: organizationId,
      },
      API_REQUEST_START: RESET_PASSWORD_USER_START,
      API_REQUEST_SUCCESS: RESET_PASSWORD_USER_SUCCESS,
      API_REQUEST_FAILURE: RESET_PASSWORD_USER_ERROR,
      onSuccess: data => {
        dispatch({
          type: RESET_PASSWORD_USER_SUCCESS,
          payload: data,
        });
        notificationHelper({
          message: data.message,
        });
        setTimeout(
          () =>
            dispatch({
              type: RESET_PASSWORD_USER_SUCCESS,
              payload: null,
            }),
          3000,
        );
        setModalActive(false);
      },
      onError: data => {
        dispatch({
          type: RESET_PASSWORD_USER_ERROR,
          payload: data,
        });
        notificationHelper({
          message: data.message,
          status: notificationStatus.ERROR,
        });
        setTimeout(
          () =>
            dispatch({
              type: RESET_PASSWORD_USER_ERROR,
              payload: null,
            }),
          3000,
        );
      },
      onEnd: () => false,
    }),
  );
};
