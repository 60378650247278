/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import phoneUtils from '@exuus/rwanda-phone-utils';
import PropTypes from 'prop-types';
import validator from 'validator';
import './PhoneInput.scss';

const PhoneInput = ({
  className,
  label,
  id,
  ext,
  name,
  placeholder,
  onChange,
  required,
  organization,
  value,
  ...rest
}) => {
  const phone = phoneUtils(value);

  const [localValue, setLocalValue] = useState(
    phone.isValid ? phone.short : value,
  );

  return (
    <Form.Field
      required={required}
      error={localValue.length > 0 && !phoneUtils(localValue).isValid}
      className={`phone-input ${className}`}
      {...rest}
    >
      <label htmlFor={id}>{label}</label>
      <Input
        id={id}
        label={ext}
        type="number"
        pattern="\d*"
        maxLength="9"
        name={name}
        placeholder={placeholder}
        onChange={(e, v) => {
          const p = phoneUtils(v.value);
          if (validator.isNumeric(v.value || '') || v.value === '') {
            if (v.value.length <= 9) {
              setLocalValue(v.value);
            } else if (phoneUtils(v.value).isValid)
              setLocalValue(phoneUtils(v.value).short);
            const cValue = p.isValid ? p.short : v.value;
            onChange(
              { ...e, target: { ...v, value: cValue } },
              { ...v, value: cValue },
            );
          }
        }}
        required={required}
        value={localValue}
        error={
          localValue.length > 0 && !phoneUtils(localValue).isValid
        }
        {...rest}
      />
    </Form.Field>
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  ext: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  organization: PropTypes.bool,
  className: PropTypes.string,
};

PhoneInput.defaultProps = {
  value: '',
  required: false,
  organization: false,
  onChange: () => {},
  placeholder: '780-000-000',
  name: 'phone',
  ext: '+250',
  id: `phone-${Math.random()}`,
  label: 'Phone',
  className: '',
};

export default PhoneInput;
