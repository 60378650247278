/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import { cleanCumulative } from 'helpers/toCumulativeDates';
import todaysDate from 'utils/todaysDate';
import formatNumber from 'helpers/formatNumber';
import currencyFormat from 'utils/currencyFormat';
import CumulativeMicroLoansModal from './CumulativeMicroLoansModal';

const ProfileOverviewCharts = ({ data, year }) => {
  const [open, setOpen] = useState(false);
  const {
    groups_count: groupsCount,
    groups = [],
    cumulative_profile_loans = [],
    total_paid_micro_loans = 0,
    current_savings = [],
    current_savings_total = 0,
    current_loans = [],
    cumulative_micro_loans = 0,
    total_due_micro_loans = 0,
  } = data;

  return (
    <>
      <CumulativeMicroLoansModal
        open={open}
        setOpen={() => setOpen(!open)}
        data={{
          cumulative_micro_loans,
          total_paid_micro_loans,
          total_due_micro_loans,
        }}
      />

      <Grid>
        <Grid.Row columns="2">
          <Grid.Column width="8">
            <LineChart
              {...{
                title: 'Saving Groups',
                viewMoreRedirect: true,
                viewKey1: 'groups',
                view: 'profiles',
                date: todaysDate(year),
                amount: formatNumber(groupsCount),
                data: groups,
                background: greenColor,
                dataKey: 'uv',
              }}
            />
          </Grid.Column>
          <Grid.Column width="8">
            <LineChart
              {...{
                title: `Current Savings`,
                viewMoreRedirect: true,
                viewKey1: 'groups',
                viewKey: 'loans',
                view: 'profiles',
                date: todaysDate(year),
                amount: `${currencyFormat(current_savings_total)}`,
                data: cleanCumulative(current_savings),
                background: blueColor,
                dataKey: 'count',
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column width="8">
            <LineChart
              {...{
                title: 'Cumulative Micro Loans',
                viewMoreRedirect: false,
                viewKey1: 'cumulative-loans',
                view: 'profiles',
                onViewMoreClicked: () => setOpen(true),
                date: todaysDate(year),
                amount: `${currencyFormat(cumulative_micro_loans)}`,
                data: cleanCumulative(cumulative_profile_loans),
                background: orangeColor,
                dataKey: 'count',
              }}
            />
          </Grid.Column>
          <Grid.Column width="8">
            <LineChart
              {...{
                title: 'Outstanding Micro Loans',
                viewMoreRedirect: false,
                viewKey1: 'outstanding-loans',
                view: 'general',
                date: todaysDate(year),
                amount: `${currencyFormat(total_due_micro_loans)}`,
                data: current_loans,
                background: redColor,
                dataKey: 'count',
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

ProfileOverviewCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  year: PropTypes.string.isRequired,
};

export default ProfileOverviewCharts;
