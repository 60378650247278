/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import RequestList from './RequestList';
import './index.scss';

const Requests = props => {
  return <RequestList {...props} />;
};

Requests.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
};

export default Requests;
