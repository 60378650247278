export default {
  addAdvertisement: {
    loading: false,
    data: [],
    error: null,
  },
  allAdvertisements: {
    loading: false,
    data: [],
    error: null,
  },
  singleAdvertisement: {
    loading: false,
    data: null,
    error: null,
  },
  activateAdvertisement: {
    loading: false,
    message: null,
    error: null,
  },
  deactivateAdvertisement: {
    loading: false,
    message: null,
    error: null,
  },
  deleteAdvertisement: {
    loading: false,
    message: null,
    error: null,
  },
  emails: {
    message: null,
    loading: false,
    error: null,
  },
  emailsList: {
    data: [],
    meta: {},
    loading: false,
  },
  updateAdvertisement: {
    loading: false,
    data: [],
    error: null,
  },
};
