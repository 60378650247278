/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import './index.scss';
import { Message } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import getLoanRequests from 'redux/actions/loanRequests/getLoanRequests';
import LoanRequestTable from './LoanRequestTable';

const ListLoanRequest = ({ items, meta, loading, error }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const queryParams = Object.fromEntries(urlParams);

  const dispatch = useDispatch();

  useEffect(() => {
    getLoanRequests({ status: 'submitted', ...queryParams })(
      dispatch,
    );
  }, [dispatch]);

  const onQuery = queries => getLoanRequests(queries)(dispatch);

  return (
    <section className="list-loan-requests not-all">
      <Toolbar
        header={{
          title: 'Requests',
          rightComponent: (
            <ToolbarFooter
              onSearch={onQuery}
              onPaginate={onQuery}
              paginationMeta={meta}
              exportCSVData={items}
              exportCSVFileName="Loan requests"
            />
          ),
        }}
        ignoreHelmet={false}
      />
      <div className="list-loan-requests__items scroll-wrapper">
        {loading ? (
          <Loader />
        ) : isEmpty(items) ? (
          <Empty message="No pending request" />
        ) : error ? (
          <Message negative>{error?.message}</Message>
        ) : (
          <LoanRequestTable items={items} />
        )}
      </div>
    </section>
  );
};

ListLoanRequest.defaultProps = {
  meta: {},
  items: [],
  error: null,
};

ListLoanRequest.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.objectOf(Object),
  error: PropTypes.objectOf(Object),
};

export default ListLoanRequest;
