/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import { cleanCumulative } from 'helpers/toCumulativeDates';
import todaysDate from 'utils/todaysDate';
import toMonthStat from 'helpers/toMonthStat';
import formatNumber from 'helpers/formatNumber';

const LineCharts = ({ data = {} }) => {
  const {
    groups_count: groupsCount,
    groups = [],
    agents_count: agentsCount,
    agents: cumulativeAgents = [],
    partners_count: partnersCount,
    partners = [],
    projects_count: projectsCount = [],
    projects = [],
  } = data;

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Partners',
              date: todaysDate(),
              amount: formatNumber(partnersCount),
              data: toMonthStat(partners),
              background: greenColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Projects',
              date: todaysDate(),
              amount: formatNumber(projectsCount),
              data: projects,
              background: blueColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Saving Groups',
              date: todaysDate(),
              amount: groupsCount,
              data: groups,
              background: orangeColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Agents',
              date: todaysDate(),
              amount: agentsCount,
              data: cleanCumulative(cumulativeAgents),
              background: redColor,
              dataKey: 'uv',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default LineCharts;
