import users from 'containers/Users';

export default {
  exact: true,
  name: 'Org Users',
  protected: true,
  path: '/users',
  component: users,
  permissions: ['all'],
};
