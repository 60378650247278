/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import phoneUtils from '@exuus/rwanda-phone-utils';
import Thumbnail from 'components/common/Thumbnail';
import { isSuperAdmin } from 'helpers/checkRoles';

const GroupDetails = ({
  data: {
    member_info: {
      user: {
        id_number: idNumber,
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        province,
        district,
        sector,
        cell,
        village,
        roles,
        created_at: createdAt,
      } = {},
    } = {},
  },
}) => {
  return (
    <div className="group-info">
      <div className="info-wrapper">
        <div className="header">
          <Thumbnail
            name={firstName}
            secondName={lastName}
            style={{ width: '50px', height: '50px' }}
          />
          <div className="name">{`${firstName} ${lastName}`}</div>
        </div>

        <div className="group-details">
          <div className="details">
            <div className="detail">Phone: </div>
            <div className="detail">ID Number: </div>
            <div className="detail">Email : </div>
            <div className="detail">Location : </div>
          </div>
          <div className="details ">
            <div className="detail right-align">
              {phoneUtils(phoneNumber).format('space')}
            </div>
            <div className="detail right-align">{idNumber}</div>
            <div className="detail right-align">{email}</div>
            <div className="detail right-align">
              <div className="locations">
                <div className="location">{province}</div>
                <div className="location">{district}</div>
                <div className="location">{sector}</div>
                <div className="location">{cell}</div>
                <div className="location">{village}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        {roles && isSuperAdmin(roles) ? (
          <div className="label">Admin</div>
        ) : null}
        <div className="content">
          Joined on {new Date(createdAt).toDateString()}
        </div>
      </div>
    </div>
  );
};

GroupDetails.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default GroupDetails;
