import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ListLoanRequests from 'containers/MicroLoanRequests/ListLoanRequest';

const MicroLoanRequests = ({ history, openSingleLoanRequest }) => {
  const { items, meta, loading, error } = useSelector(
    ({ loanRequests: { pendingLoanRequests } }) =>
      pendingLoanRequests,
  );

  const goToSingleLoanRequests = requestId => {
    history.push(`/requests/${requestId}`);
  };

  return (
    <ListLoanRequests
      history={history}
      items={items}
      loading={loading}
      meta={meta}
      error={error}
      openSingleLoanRequest={
        openSingleLoanRequest || goToSingleLoanRequests
      }
      all
    />
  );
};

MicroLoanRequests.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  openSingleLoanRequest: PropTypes.func,
};

MicroLoanRequests.defaultProps = {
  openSingleLoanRequest: null,
};

export default MicroLoanRequests;
