import {
  UPLOAD_BULK_TRANSACTION_ERROR,
  UPLOAD_BULK_TRANSACTION_START,
  UPLOAD_BULK_TRANSACTION_SUCCESS,
} from 'constants/action-types/groups/uploadBulkTransaction';

import apiAction from 'helpers/apiAction';

export default (data, callback) => dispatch =>
  dispatch(
    apiAction({
      url: `/admin/transactions/bulk/check`,
      method: 'PUT',
      data,
      timeout: 1200000,
      API_REQUEST_START: UPLOAD_BULK_TRANSACTION_START,
      API_REQUEST_SUCCESS: UPLOAD_BULK_TRANSACTION_SUCCESS,
      API_REQUEST_FAILURE: UPLOAD_BULK_TRANSACTION_ERROR,
      onSuccess: payload => {
        callback();
        dispatch({
          type: UPLOAD_BULK_TRANSACTION_SUCCESS,
          payload,
        });
      },
      onEnd: () => false,
    }),
  );
