import AllOrganizations from 'containers/Organizations/AllOrganizations';

export default {
  exact: true,
  name: 'Organizations',
  protected: true,
  path: '/organizations',
  component: AllOrganizations,
  permissions: ['all'],
};
