import {
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
} from 'constants/action-types/projects/updateProject';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_PROJECT_START:
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          loading: true,
          error: null,
        },
      };
    case UPDATE_PROJECT_FAILED:
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          loading: false,
        },
      };
    default:
      return null;
  }
};
