import {
  GET_PROJECT_FAILED,
  GET_PROJECT_START,
  GET_PROJECT_SUCCESS,
} from 'constants/action-types/projects/getProject';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_PROJECT_START:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          loading: true,
          overview: {},
          error: null,
        },
      };
    case GET_PROJECT_FAILED:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          error: payload,
          loading: false,
          overview: {},
        },
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          data: payload.data.project || payload.data,
          overview: payload.data.project ? payload.data : {},
          loading: false,
        },
      };
    default:
      return null;
  }
};
