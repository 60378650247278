/**
 * @param {int|string} num
 * @returns {string} a formatted number with grouped thousands separated by commas
 */
// TODO: will be depricated use numeraljs instead
function formatNumber(num) {
  let number = Number(num);
  if (num === undefined || num === null) return 0;
  number = Number.isNaN(number) ? 0 : number;
  number = number
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return number;
}

export default formatNumber;
