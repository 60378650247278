import {
  GET_GROUP_ACTIVITIES_ERROR,
  GET_GROUP_ACTIVITIES_START,
  GET_GROUP_ACTIVITIES_SUCCESS,
} from 'constants/action-types/organizations/groupActivities';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_GROUP_ACTIVITIES_START:
      return {
        ...state,
        groupActivities: {
          ...state.groupActivities,
          getGroupActivities: {
            ...state.groupActivities?.getGroupActivities,
            loading: true,
            error: null,
          },
        },
      };
    case GET_GROUP_ACTIVITIES_ERROR:
      return {
        ...state,
        groupActivities: {
          ...state.groupActivities,
          getGroupActivities: {
            ...state.groupActivities?.getGroupActivities,
            error: payload,
            loading: false,
          },
        },
      };
    case GET_GROUP_ACTIVITIES_SUCCESS:
      return {
        ...state,
        groupActivities: {
          ...state.groupActivities,
          getGroupActivities: {
            ...state.groupActivities?.getGroupActivities,
            items: payload.data,
            meta: payload.meta,
            loading: false,
          },
        },
      };
    default:
      return null;
  }
};
