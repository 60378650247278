import {
  DELETE_MULTIPLE_MEMBER_ERROR,
  DELETE_MULTIPLE_MEMBER_START,
  DELETE_MULTIPLE_MEMBER_SUCCESS,
} from 'constants/action-types/organizations/deleteGroupMember';

export default (state, { type, payload }) => {
  switch (type) {
    case DELETE_MULTIPLE_MEMBER_START:
      return {
        ...state,
        deleteMultipleMembers: {
          ...state.deleteMultipleMembers,
          loading: true,
          error: null,
          data: [],
        },
      };
    case DELETE_MULTIPLE_MEMBER_SUCCESS:
      return {
        ...state,
        deleteMultipleMembers: {
          ...state.deleteMultipleMembers,
          loading: false,
          error: null,
          data: payload,
        },
      };
    case DELETE_MULTIPLE_MEMBER_ERROR:
      return {
        ...state,
        deleteMultipleMembers: {
          ...state.deleteMultipleMembers,
          loading: false,
          error: payload,
        },
      };
    default:
      return null;
  }
};
