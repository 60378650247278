import {
  ADD_USER_LOADING,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  CLEAR_ADD_USER,
} from 'constants/action-types/user/addUser';
import userState from '../../initial-states/user';

export default (state = userState, { type, payload }) => {
  switch (type) {
    case ADD_USER_LOADING:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          user: {},
          isCreating: true,
          error: {},
        },
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          user: {},
          error: payload,
          isCreating: false,
        },
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          user: payload.data || payload.foundUser,
          error: {},
          isCreating: false,
        },
      };
    case CLEAR_ADD_USER:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          user: {},
          error: {},
          isCreating: false,
        },
      };
    default:
      return null;
  }
};
