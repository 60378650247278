import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import PropTypes from 'prop-types';

const SynchronizedChart = ({ data, dataKey2, dataKey1 }) => {
  return (
    <div>
      <LineChart
        width={650}
        height={150}
        data={data}
        syncId="anyId"
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey={dataKey1}
          stroke="#8884d8"
          fill="#8884d8"
        />

        <Brush />
      </LineChart>
      <LineChart
        width={650}
        height={150}
        data={data}
        syncId="anyId"
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey={dataKey2}
          stroke="#82ca9d"
          fill="#82ca9d"
        />
      </LineChart>
    </div>
  );
};
SynchronizedChart.defaultProps = {
  dataKey1: 'uv',
  dataKey2: 'pv',
};
SynchronizedChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey2: PropTypes.string,
  dataKey1: PropTypes.string,
};
export default SynchronizedChart;
