/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import Empty from 'components/common/Empty';
import Toolbar from 'components/common/Toolbar';
import './index.scss';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import SidebarFilter from 'components/common/SidebarFilter';
import getAgentActivities from 'redux/actions/organizations/agentActivities';
import Loader from 'components/common/PageLoader';
import ActivityItem from 'containers/Activity/ActivityItem';
import { agentActivitiesTypes } from 'constants/activityTypes';
import getAgent from 'redux/actions/organizations/getAgent';
import TYPE_NAMES from 'constants/typeNames';

const AgentActivities = () => {
  const [filter, setFilter] = useState();
  const [form, setForm] = useState({});
  const {
    organizationId,
    projectId,
    tabOption,
    option,
  } = useParams();

  const agentId = tabOption || option;

  const { loading, data: activities = [], meta = {} } = useSelector(
    ({ organizations: { agentActivities } }) => agentActivities,
  );
  const { data: agentData } = useSelector(
    ({ organizations: { getAgent } }) => getAgent,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getAgentActivities(organizationId, agentId)(dispatch);
    if (isEmpty(agentData))
      getAgent(organizationId, projectId, agentId)(dispatch);
  }, []);

  const handleFilter = () => {
    if (!form.search && !form.filter) return;
    getAgentActivities(organizationId, agentId, {
      search: form.search,
      type: form.filter,
    })(dispatch);
  };

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
    handleFilter();
  };

  useEffect(() => {
    handleFilter();
  }, [form]);

  const onQuery = queries => {
    getAgentActivities(organizationId, agentId, queries)(dispatch);
  };

  const title = item =>
    item[item.resource] && item[item.resource].name;

  const agent = activities.length > 0 ? activities[0] : {};

  return (
    <>
      <Toolbar
        header={{
          breadcrumbParent: 'Recent activities',
          breadcrumbChildren: `${`${agentData?.agent?.first_name ||
            ''} ${agentData?.agent?.last_name || ''}`.trim()}`,
          breadcrumbChildrenLink: `/organizations/${organizationId}/agents/${agentId}`,
          navigation: true,
          rightComponent: (
            <ToolbarFooter
              onPaginate={onQuery}
              paginationMeta={meta}
              onSearch={onQuery}
              filter
              filterClicked={() => setFilter(true)}
            />
          ),
        }}
      />

      <SidebarFilter
        {...{
          filterOn: filter,
          setFilterOn: setFilter,
          form,
          setForm,
          filterTransactions: null,
          handleChange,
          types: agentActivitiesTypes,
          typeName: TYPE_NAMES.TYPE_OF_ACTIVITY,
          searchName: 'Group name',
          activity: 'agent',
          resourceId: organizationId,
        }}
      />

      <div className="agent-recent-activities">
        {loading && <Loader />}
        {!loading &&
          activities &&
          activities.map(item => (
            <ActivityItem title={title(item)} description={item} />
          ))}
        {!loading && activities.length === 0 && (
          <Empty message="No activities" />
        )}
      </div>
    </>
  );
};

export default AgentActivities;
