import Login from 'containers/Login';

export default {
  exact: true,
  name: 'Login',
  protected: false,
  path: '/login',
  component: Login,
  permissions: ['all'],
};
