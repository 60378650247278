/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Icon, Label, Modal } from 'semantic-ui-react';
import { isEmpty, debounce } from 'lodash';
import './index.scss';
import getUsers from 'redux/actions/user/getUsers';
import addTestFeature from 'redux/actions/settings/addTestFeature';

const SEARCH_INTERVAL = 1;

const UserTestModal = props => {
  const { open, setOpen, type, feature } = props;
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { items = [], loading } = useSelector(
    ({ user: { usersList } }) => usersList,
  );
  const { loading: addTestFeatureLoading } = useSelector(
    ({ settings: { addTestFeature } }) => addTestFeature,
  );

  const handleGetUsers = queries => {
    return getUsers(
      undefined,
      undefined,
      { ...(queries || {}), limit: 10 },
      true,
    )(dispatch);
  };

  useEffect(() => {
    if (isEmpty(items)) {
      handleGetUsers();
    }
  }, []);

  const handleSearchChange = debounce((_, { searchQuery }) => {
    setSearchQuery(searchQuery);
    handleGetUsers({ search: searchQuery });
  }, SEARCH_INTERVAL);

  const handleChange = (_, val) => {
    return setUsers([...users, ...val.value]);
  };
  const handleSubmit = () => {
    const userIds = users.map(user => Number(user?.split(' ')?.[0]));

    addTestFeature(feature?.id, 'users', { users: userIds })(
      dispatch,
      () => {
        setOpen(false);
      },
    );
  };

  const onRemoveUser = user => {
    setUsers(users.filter(userItem => userItem !== user));
  };

  const options = useMemo(() => {
    return items.map((item, index) => {
      return {
        key: index,
        text: `${item.id} (${item.first_name} ${item.last_name})`,
        value: `${item.id} (${item.first_name} ${item.last_name})`,
      };
    });
  }, [items, searchQuery]);

  return (
    <>
      <Modal size="mini" open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          {`Add ${type} users`}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setOpen(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <label>User Id</label>
            <div>
              {users.map(user => (
                <Label key={user}>
                  {user}{' '}
                  <Icon
                    name="delete"
                    onClick={() => onRemoveUser(user)}
                  />
                </Label>
              ))}
            </div>
            <Form.Dropdown
              className="input"
              name="ids"
              id="ids"
              label=""
              size="tiny"
              loading={loading}
              value={[]}
              fluid
              multiple
              search
              selection
              options={options}
              onChange={handleChange}
              onSearchChange={handleSearchChange}
            />

            <Button
              className="confirm"
              type="submit"
              color="blue"
              loading={addTestFeatureLoading}
              fluid
            >
              Add Users
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

UserTestModal.defaultProps = {
  open: false,
  type: 'test',
};

UserTestModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string,
  feature: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserTestModal;
