import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Sidebar,
  Icon,
  Form,
  Input,
  Segment,
  Select,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  toDropDownOptions,
  walletDropDownOptions,
} from 'utils/dropdownOptions';
import financialDetails from 'constants/financialDetails';
import withdrawalWallet from 'redux/actions/wallets/withdrawalWallet';
import InputNumberFormat from '../InputNumberFormat';
import PINInput from '../PINInput';

const WithdrawSidebar = ({
  filterOn,
  setActionType,
  currentOrgId,
}) => {
  const [form, setForm] = useState({});
  const [hasError, setHasError] = useState(false);
  const [formError, setFormError] = useState('');
  const [selectedWallet, setSelectedWallet] = useState({});
  const dispatch = useDispatch();

  const {
    wallets: { meta },
    withdrawalWallet: { loading },
  } = useSelector(({ wallets: { wallets, withdrawalWallet } }) => ({
    wallets,
    withdrawalWallet,
  }));

  const bankNames = toDropDownOptions(financialDetails);
  const floatOptions =
    !isEmpty(meta?.wallets) && walletDropDownOptions(meta?.wallets);

  const handleFormChange = (e, { name, value }) => {
    if (name === 'amount') {
      if (value > selectedWallet?.float_balance) {
        setHasError(true);
        setFormError('Insufficient fund');
      }
      if (value <= selectedWallet?.float_balance) setHasError(false);
    }
    if (name === 'provider_name') {
      const selectedWallet = meta?.wallets?.find(wallet => {
        return wallet?.provider_name
          .toLowerCase()
          .includes(value?.split(' ')?.[0]?.toLowerCase());
      });
      setSelectedWallet(selectedWallet);
    }

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () =>
    withdrawalWallet({
      data: form,
      organizationId: currentOrgId,
      walletId: selectedWallet?.id,
    })(dispatch, setActionType);
  return (
    <Sidebar
      className="sidebar scrollable"
      as={Segment}
      direction="right"
      animation="overlay"
      visible={filterOn}
      width="wide"
    >
      <div className="padding">
        <div className="header">
          <div
            tabIndex="-1"
            role="button"
            onKeyDown={() => null}
            className="back"
            onClick={() => {
              setActionType(null);
            }}
          >
            <Icon name="arrow left" fitted size="large" />
          </div>
          <div className="title titleText">
            <h2>Withdrawal</h2>
          </div>
        </div>
      </div>
      <div className="padding">
        <Form onSubmit={handleSubmit} loading={loading}>
          <Form.Field
            required
            name="provider_name"
            control={Select}
            fluid
            options={floatOptions}
            label="Select float"
            placeholder="Select float"
            onChange={handleFormChange}
          />
          <InputNumberFormat
            required
            name="amount"
            control={Input}
            label="Amount"
            type="number"
            placeholder="Amount..."
            onChange={handleFormChange}
            error={hasError && formError}
          />

          <Form.Field
            required
            name="recipient_bank"
            control={Select}
            fluid
            options={bankNames}
            label="Recipeint Bank"
            placeholder="Recipeint Bank"
            onChange={handleFormChange}
          />

          <Form.Field
            required
            name="recipient_bank_account"
            control={Input}
            label="Recipeint Bank Account"
            type="number"
            placeholder="Recipeint Bank Account"
            onChange={handleFormChange}
          />

          <Form.TextArea
            required
            name="comment"
            label="Description"
            placeholder="Description"
            className="textarea"
            onChange={handleFormChange}
          />
          <Form.Field>
            <label>Provide PIN to confirm</label>
            <PINInput
              name="password"
              required
              maxLength={5}
              onInputChange={handleFormChange}
            />
          </Form.Field>
          <Button
            disabled={!form.recipient_bank_account}
            primary
            floated="right"
            size="medium"
            type="submit"
            content="Request"
            loading={loading}
          />
        </Form>
      </div>
    </Sidebar>
  );
};

WithdrawSidebar.propTypes = {
  filterOn: PropTypes.bool.isRequired,
  setActionType: PropTypes.func.isRequired,
  currentOrgId: PropTypes.number.isRequired,
};

export default WithdrawSidebar;
