import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import SendForm from 'components/Communications/Activity/SendForm';
import isParentOrganization from 'helpers/isParentOrganization';
import { canAccess } from 'helpers/permissions';
import splitParams from 'helpers/splitParams';
import getUserRoles from 'helpers/getUserRoles';
import './index.scss';
import AllActivity from 'components/Communications/Activity/AllActivity';
import RecentActivity from 'components/Communications/Activity/RecentActivity';
import permissions from 'constants/permissions';

const PushNotifications = () => {
  const { currentOrgId, orgUsers, roles } = getUserRoles();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const isCurrentOrgParent = isParentOrganization();

  useEffect(() => {
    if (
      !isEmpty(roles) &&
      currentOrgId &&
      !canAccess(permissions.canAccess.communications, roles, {
        currentOrgId,
        orgUsers,
        additionalCondition: isCurrentOrgParent,
      })
    ) {
      push('/');
    }
  }, [orgUsers, currentOrgId]);

  return (
    <section className="push-notifications">
      {splitParams(pathname).includes('all') ? (
        <AllActivity
          type="push-notifications"
          title="Recent pushed notifications"
          subTitle="All Push Notifications"
          params="push-notifications"
        />
      ) : (
        <div className="push-notifications-container">
          <SendForm
            type="push-notifications"
            title="Push notification to"
          />
          <RecentActivity
            type="push-notifications"
            title="Recent pushed notifications"
            params="push-notifications"
          />
        </div>
      )}
    </section>
  );
};

export default PushNotifications;
