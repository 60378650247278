const ACTIVITIES = {
  BILLINGS: 'billings',
  GROUP: 'group',
  AGENT: 'agent',
  MEMBER: 'member',
  GROUP_TRANSACTIONS: 'groupTransactions',
  ALL_SAVING_GROUPS: 'allSavingGroups',
  SETTING_FEATURES: 'settingFeatures',
  MICROLOAN_REQUESTS: 'microloanRequests',
};

export default ACTIVITIES;
