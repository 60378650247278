/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import currencyFormat from 'utils/currencyFormat';
import todaysDate from 'utils/todaysDate';
import formatNumber from 'helpers/formatNumber';

const LineCharts = ({ data, year }) => {
  const {
    cumulative_savings: cumulativeSavings,
    savings_balance_total: savingsBalanceTotalCount,
    groups,
    groups_count: groupsCount,
    cumulative_loans: cumulativeLoans,
    cumulative_loans_total: cumulativeLoansCount,
    current_loans: outstandingLoans,
    current_loans_total: outstandingLoansCount,
  } = data;

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Current savings',
              date: todaysDate(year),
              amount: `${currencyFormat(savingsBalanceTotalCount)}`,
              data: cumulativeSavings,
              background: greenColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'agent',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Saving Groups',
              date: todaysDate(year),
              amount: formatNumber(groupsCount),
              data: groups,
              background: blueColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'agent',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Cumulative loans',
              date: todaysDate(year),
              amount: `${currencyFormat(cumulativeLoansCount)}`,
              data: cumulativeLoans,
              background: orangeColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'agent',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Outstanding loans',
              date: todaysDate(year),
              amount: `${currencyFormat(outstandingLoansCount)}`,
              data: outstandingLoans,
              background: redColor,
              dataKey: 'count',
              color: '#fff',
              viewMoreRedirect: true,
              view: 'agent',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default LineCharts;
