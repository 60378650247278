import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Image, Button, Checkbox } from 'semantic-ui-react';
import './CreateAd.scss';
import PropTypes from 'prop-types';
import addAdvertisement from 'redux/actions/communication/advertisement/addAdvertisement';
import getSingleAdvertisement from 'redux/actions/communication/advertisement/getSingleAdvertisement';
import updateAdvertisement from 'redux/actions/communication/advertisement/updateAdvertisement';
import notify from 'helpers/notificationHelper';
import { WARN } from 'constants/notificationStatus';
import MyDropzone from 'components/common/DragAndDrop';
import PageLoader from 'components/common/PageLoader';
import isURL from 'helpers/isURL';
import { compactObject } from 'helpers/removeEmptyProperties';

const options = [
  { key: 'tap_and_win', text: 'Tap & Win', value: 'tap_and_win' },
  {
    key: 'external_link',
    text: 'External link',
    value: 'external_link',
  },
];

const CreateAd = ({ editingAd, setEditingAd }) => {
  const [lightFile, setLightFile] = useState(null);
  const [darkFile, setDarkFile] = useState(null);
  const [urlLightFile, setUrlLightFile] = useState(null);
  const [urlDarkFile, setUrlDarkFile] = useState(null);
  const [form, setForm] = useState({});
  const lightFileInputRef = useRef(null);
  const darkFileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [header, setHeader] = useState('New Advertisement');

  const { loading } = useSelector(
    ({ advertisement: { addAdvertisement } }) => addAdvertisement,
  );
  const { loading: updateLoading } = useSelector(
    ({ advertisement: { updateAdvertisement } }) =>
      updateAdvertisement,
  );
  const { data: singleAd, loading: singleAdLoading } = useSelector(
    ({ advertisement: { singleAdvertisement } }) =>
      singleAdvertisement,
  );

  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const handleLightClear = () => {
    setLightFile(null);
    setForm({ title: '', has_action: false });
    setUrlLightFile(null);
    setEditingAd(null);
  };
  const handleDarkClear = () => {
    setDarkFile(null);
    setForm({ title: '', has_action: false });
    setUrlLightFile(null);
    setEditingAd(null);
  };

  const clickLightFileAttchement = () => {
    lightFileInputRef.current.click();
  };

  const clickDarkFileAttchement = () => {
    darkFileInputRef.current.click();
  };

  const handleChangeAttach = e => {
    e.preventDefault();

    const objectUrl = URL.createObjectURL(e.target?.files[0]);

    if (e.target.name === 'lightFile') {
      setLightFile(e.target?.files[0]);
      setUrlLightFile(objectUrl);
    } else if (e.target.name === 'darkFile') {
      setDarkFile(e.target?.files[0]);
      setUrlDarkFile(objectUrl);
    }

    return () => URL.revokeObjectURL(objectUrl);
  };

  const handleUpdate = () => {
    const formData = new FormData();

    if (lightFile) {
      const isLessThan3MBs = lightFile.size / 1024 / 1024 > 3;

      if (isLessThan3MBs) {
        return notify({
          message: 'Light file should not exceed 3 MBs',
          status: WARN,
        });
      }
      formData.append('image', lightFile);
    }

    if (darkFile) {
      const isLessThan3MBs = darkFile.size / 1024 / 1024 > 3;

      if (isLessThan3MBs) {
        return notify({
          message: 'Dark file should not exceed 3 MBs',
          status: WARN,
        });
      }
      formData.append('dark_image', darkFile);
    }

    if (!form.has_action) {
      form.action_content = undefined;
      form.action_label = undefined;
      form.action_type = undefined;
    }

    if (form.action_type === 'tap_and_win') {
      form.action_content = undefined;
    }

    const compactForm = compactObject(form);

    Object.keys(compactForm).forEach(key => {
      formData.append(key, compactForm[key]);
    });

    updateAdvertisement(editingAd, formData)(dispatch, () => {
      handleLightClear();
      handleDarkClear();
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();

    const isLightImageLessThan3MBs = lightFile.size / 1024 / 1024 > 3;
    const isDarkImageLessThan3MBs = darkFile.size / 1024 / 1024 > 3;

    if (isLightImageLessThan3MBs) {
      return notify({
        message: 'Light file should not exceed 3 MBs',
        status: WARN,
      });
    }
    if (isDarkImageLessThan3MBs) {
      return notify({
        message: 'Dark file should not exceed 3 MBs',
        status: WARN,
      });
    }
    if (form.status === 'active') {
      formData.append('status', form.status);
    }

    if (!form.has_action) {
      form.action_content = undefined;
      form.action_label = undefined;
      form.action_type = undefined;
    }

    if (form.action_type === 'tap_and_win') {
      form.action_content = undefined;
    }

    const compactForm = compactObject(form);

    Object.keys(compactForm).forEach(key => {
      if (key !== 'status') {
        formData.append(key, compactForm[key]);
      }
    });

    formData.append('image', lightFile);
    formData.append('dark_image', darkFile);

    addAdvertisement(formData)(dispatch, () => {
      handleLightClear();
      handleDarkClear();
    });
  };

  useEffect(() => {
    if (editingAd) {
      getSingleAdvertisement(editingAd)(dispatch);

      setHeader('Editing Advertisement');
    }
    setForm({});
  }, [editingAd]);

  const isButtonDisabled = () => {
    if (editingAd) {
      return (
        !form?.title ||
        form?.title === '' ||
        updateLoading ||
        (form.has_action === true &&
          (!form.action_type ||
            form.action_type === '' ||
            !form.action_label ||
            (form.action_type === 'external_link' &&
              (!form.action_content || !isURL(form.action_content)))))
      );
    }
    return (
      !lightFile ||
      !darkFile ||
      !form?.title ||
      form?.title === '' ||
      loading ||
      (form.has_action === true &&
        (!form.action_type ||
          form.action_type === '' ||
          !form.action_label ||
          (form.action_type === 'external_link' &&
            (!form.action_content || !isURL(form.action_content)))))
    );
  };

  useEffect(() => {
    if (singleAd) {
      setForm({
        ...form,
        action_content: singleAd.action_content,
        action_label: singleAd.action_label,
        action_type: singleAd.action_type,
        has_action: singleAd.has_action,
        title: singleAd.title,
        status: singleAd.status,
      });
    }
  }, [singleAd]);

  return (
    <div className="create-new-ad">
      <>
        {!!form?.title || lightFile ? (
          <div className="header">
            <h3>{header}</h3>
            <Button
              content="Clear"
              className="header__clear"
              onClick={() => {
                handleLightClear();
                handleDarkClear();
                setHeader('New Advertisement');
              }}
            />
          </div>
        ) : (
          <h3>{header}</h3>
        )}
        {editingAd ? (
          singleAdLoading ? (
            <PageLoader />
          ) : (
            <Form>
              <span>Light mode image</span>
              <div className="editing_image">
                <Image
                  file
                  name="image"
                  src={lightFile ? urlLightFile : singleAd?.image}
                  alt=""
                  className="editing_imaged__file"
                />
                <input
                  className="attachment"
                  ref={lightFileInputRef}
                  type="file"
                  name="lightFile"
                  required
                  accept="image/*"
                  onChange={handleChangeAttach}
                />

                <button
                  className="editing_image__button"
                  type="button"
                  onClick={clickLightFileAttchement}
                >
                  Change Image
                </button>
              </div>

              <span>Dark mode image</span>
              <div className="editing_image">
                <Image
                  file
                  name="darkFile"
                  src={darkFile ? urlDarkFile : singleAd?.dark_image}
                  alt=""
                  className="editing_imaged__file"
                />
                <input
                  className="attachment"
                  name="darkFile"
                  ref={darkFileInputRef}
                  type="file"
                  required
                  accept="image/*"
                  onChange={handleChangeAttach}
                />

                <button
                  className="editing_image__button"
                  type="button"
                  onClick={clickDarkFileAttchement}
                >
                  Change Image
                </button>
              </div>

              <Form.Input
                onChange={handleChange}
                required
                className="input_title"
                defaultValue={singleAd?.title}
                label="Subject"
                name="title"
                placeholder="Subject"
              />

              <div className="action-group">
                <span>Does this add has action ?</span>
                <div className="action-group__checkbox">
                  <Checkbox
                    toggle
                    className="publish-checkbox"
                    checked={form.has_action}
                    onChange={(_, { checked }) =>
                      setForm({
                        ...form,
                        has_action: checked,
                      })
                    }
                  />
                </div>
              </div>

              {form.has_action && (
                <>
                  <Form.Select
                    fluid
                    required
                    onChange={(_, { value }) => {
                      setForm({
                        ...form,
                        action_type: value,
                      });
                    }}
                    defaultValue={singleAd?.action_type}
                    options={options}
                    label="Action type"
                    name="action_type"
                    placeholder="Action type"
                  />
                  {form.action_type === 'external_link' && (
                    <Form.Input
                      fluid
                      required
                      onChange={handleChange}
                      defaultValue={singleAd?.action_content}
                      label="Link"
                      name="action_content"
                      placeholder="External link"
                    />
                  )}
                  <Form.Input
                    fluid
                    required
                    onChange={handleChange}
                    defaultValue={singleAd?.action_label}
                    label="Button label"
                    name="action_label"
                    placeholder="Button label"
                  />
                </>
              )}

              <div className="send-btn-wrapper">
                <Form.Button
                  primary
                  className="create"
                  disabled={isButtonDisabled()}
                  loading={updateLoading}
                  onClick={handleUpdate}
                >
                  Save
                </Form.Button>
              </div>
            </Form>
          )
        ) : (
          <Form onSubmit={handleSubmit}>
            <MyDropzone
              label="Light mode image"
              setFile={setLightFile}
              file={lightFile}
              name="image"
              handleChangeAttach={handleChangeAttach}
              clickFileAttchement={clickLightFileAttchement}
              fileInputRef={lightFileInputRef}
            />
            <MyDropzone
              label="Dark mode image"
              setFile={setDarkFile}
              file={darkFile}
              name="image"
              handleChangeAttach={handleChangeAttach}
              clickFileAttchement={clickDarkFileAttchement}
              fileInputRef={darkFileInputRef}
            />
            <Form.Input
              onChange={handleChange}
              required
              value={form.title}
              label="Subject"
              name="title"
              placeholder="Subject"
            />
            <div className="action-group">
              <span>Does this add has action ?</span>
              <div className="action-group__checkbox">
                <Checkbox
                  toggle
                  className="publish-checkbox"
                  checked={form.has_action}
                  onChange={(_, { checked }) =>
                    setForm({
                      ...form,
                      has_action: checked,
                    })
                  }
                />
              </div>
            </div>

            {form.has_action && (
              <>
                <Form.Select
                  fluid
                  required
                  onChange={(_, { value }) => {
                    setForm({
                      ...form,
                      action_type: value,
                    });
                  }}
                  value={form.action_type}
                  options={options}
                  label="Action type"
                  name="action_type"
                  placeholder="Action type"
                />
                {form.action_type === 'external_link' && (
                  <Form.Input
                    fluid
                    required
                    onChange={handleChange}
                    value={form.link}
                    label="Link"
                    name="action_content"
                    placeholder="External link"
                  />
                )}
                <Form.Input
                  fluid
                  required
                  onChange={handleChange}
                  value={form.action_label}
                  label="Button label"
                  name="action_label"
                  placeholder="Button label"
                />
              </>
            )}

            <div className="send-btn-wrapper">
              <Checkbox
                toggle
                label="publish"
                className="publish-checkbox"
                checked={form.status === 'active'}
                onChange={(_, { checked }) =>
                  setForm({
                    ...form,
                    status: checked ? 'active' : 'inactive',
                  })
                }
              />
              <Form.Button
                primary
                loading={loading}
                type="submit"
                className="create"
                disabled={isButtonDisabled()}
              >
                Create Ad
              </Form.Button>
            </div>
          </Form>
        )}
      </>
    </div>
  );
};

CreateAd.defaultProps = {};

CreateAd.propTypes = {
  editingAd: PropTypes.number.isRequired,
  setEditingAd: PropTypes.func.isRequired,
};
export default CreateAd;
