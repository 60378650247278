export default {
  currentOrganization: {
    loading: false,
    error: null,
  },
  create: {
    loading: false,
    error: null,
  },
  getSingleOrganization: {
    error: null,
    loading: false,
  },
  updateSingleOrganization: {
    error: null,
    loading: false,
    message: '',
  },
  list: {
    items: [],
    loading: false,
    error: null,
  },
  organizationGroups: {
    items: [],
    loading: false,
    error: null,
  },
  singleGroup: {
    item: {},
    loading: false,
    error: null,
  },
  updateGroup: {
    item: {},
    loading: false,
    error: null,
  },
  organizationSearch: {
    items: [],
    loading: false,
    error: null,
  },
  organizationMembers: {
    items: [],
    loading: false,
    error: null,
  },
  organizationsOverview: {
    data: {},
    loading: false,
    error: null,
  },
  singleOrganizationOverview: {
    data: {},
    loading: false,
    error: null,
  },
  fspOrganizationOverview: {
    data: {},
    loading: false,
    error: null,
  },
  organizationAgents: {
    items: [],
    loading: false,
    error: null,
  },
  groupMember: {
    data: {},
    loading: false,
    error: null,
  },
  inviteAgent: {
    data: {},
    loading: false,
    error: null,
  },
  getAgent: {
    data: {},
    loading: false,
    error: null,
  },
  bulkCreate: {
    data: {},
    loading: false,
    error: null,
  },
  bulkSms: {
    data: {},
    loading: false,
    error: null,
  },
  bulkSmsList: {
    data: [],
    loading: false,
    error: null,
  },
  deleteGroup: {
    message: null,
    loading: false,
    error: null,
  },
  deleteGroupMember: {
    message: null,
    loading: false,
    error: null,
  },
  deleteMultipleMembers: {
    data: [],
    loading: false,
    error: null,
  },
  addToCommittee: {
    message: null,
    loading: false,
    error: null,
  },
  removeFromCommittee: {
    message: null,
    loading: false,
    error: null,
  },
  changeGroupStatus: {
    message: null,
    loading: false,
    error: null,
  },
  searchGroup: {
    items: [],
    loading: false,
    error: null,
  },
  agentActivities: {
    data: [],
    loading: false,
    error: null,
    meta: null,
  },
  hasWallet: {
    data: [],
    loading: false,
    error: null,
    meta: null,
  },
  pushNotifications: {
    message: null,
    loading: false,
    error: null,
  },
  pushNotificationsList: {
    data: [],
    meta: {},
    loading: false,
    error: null,
  },
};
