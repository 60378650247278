import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Message, Select, Input } from 'semantic-ui-react';
import './index.scss';
import {
  genderOptions,
  organizationsDropDownOptions as dropdownOption,
  idTypeOptions,
} from 'utils/dropdownOptions';
import Toolbar from 'components/common/Toolbar';
import inviteAgent from 'redux/actions/organizations/inviteAgent';
import getProjectPartners from 'redux/actions/projects/getPartners';
import isValidId from 'helpers/validators/isValidId';
import nidaVerification from 'helpers/nidaVerification';
import shouldUseNIDA from 'helpers/shouldUseNIDA';
import UserSearch from '../../../../Users/UserSearch';

const InviteAgent = ({ tabOption: receivedProjectId }) => {
  const dispatch = useDispatch();
  const [userExist, setUserExist] = useState(false);
  const [phoneError, setPhoneError] = useState(null);

  const [nidaData, setNidaData] = useState({});
  const [fetchNidaLoading, setFetchNidaLoading] = useState(false);
  const [nidaVerificationData, setNidaVerificationData] = useState(
    {},
  );
  const [verifyNidaLoading, setVerifyNidaLoading] = useState(false);

  const { organizationId, projectId: routeProjectId } = useParams();
  const projectId = receivedProjectId || routeProjectId;
  useEffect(() => {
    getProjectPartners(organizationId, projectId)(dispatch);
  }, []);
  const {
    items: partners = [],
    loading: partnerLoading,
  } = useSelector(
    ({ projects: { projectPartners = {} } }) => projectPartners,
  );

  const projectPartner =
    partners.length > 0 ? partners.map(({ partner }) => partner) : [];
  const { push } = useHistory();

  const { items } = useSelector(
    ({ user: { searchUser } }) => searchUser,
  );

  const { loading, error } = useSelector(
    ({ organizations: { inviteAgent } }) => inviteAgent,
  );

  const { name: projectName } = useSelector(
    ({ projects: { currentProject: { data = {} } } = {} }) => data,
  );

  const [form, setForm] = useState({});

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });

    if ((items || []).some(item => item.phone_number === value)) {
      return setUserExist(true);
    }
    setUserExist(false);
  };

  const formatPhone = phone => {
    if (Number(phone[0]) === 0) {
      return `250${phone.slice(1)}`;
    }

    if (Number(phone[0]) !== 0 && Number(phone.slice(0, 3)) !== 250) {
      return `250${phone}`;
    }

    return phone;
  };

  const handleSubmit = () => {
    if (!form.phone_number) {
      return setPhoneError('Phone number is required');
    }

    setPhoneError(null);
    const data = userExist
      ? { phone_number: form.phone_number }
      : { ...form, phone_number: formatPhone(form.phone_number) };

    return inviteAgent(
      organizationId,
      projectId,
      data,
      receivedProjectId,
    )(dispatch)(push);
  };

  const fetchIdNumber = async nationalId => {
    setFetchNidaLoading(true);

    const res = await nidaVerification('fetch', nationalId);
    setFetchNidaLoading(false);
    setNidaData(res);
  };

  const verifyIdNumber = async (nationalId, data) => {
    setVerifyNidaLoading(true);

    const res = await nidaVerification('verify', nationalId, data);
    setVerifyNidaLoading(false);
    setNidaVerificationData(res);
  };

  useEffect(() => {
    if (isValidId(form.id_number) && shouldUseNIDA(form.id_type)) {
      fetchIdNumber(form.id_number);
    }
  }, [form.id_number]);

  useEffect(() => {
    if (
      isValidId(form.id_number) &&
      !nidaData.fail &&
      form.gender &&
      shouldUseNIDA(form.id_type)
    ) {
      verifyIdNumber(form.id_number, {
        gender: form.gender === 'male' ? 'M' : 'F',
      });
    }
  }, [form.gender]);

  return (
    <>
      <Toolbar header={{ title: 'Invite Agent' }} />
      <div className="invite-agent">
        <div className="invite-agent__container">
          <div className="invite-agent__container--title">
            Agent's Details
          </div>
          <Form onSubmit={handleSubmit} loading={loading}>
            <UserSearch
              onChange={handleChange}
              handleChange={handleChange}
              error={phoneError}
            />

            <Form.Field
              value={projectName}
              control={Input}
              label="Project"
              disabled
            />

            {!userExist && (
              <>
                <Form.Field
                  control={Select}
                  label="Partner"
                  placeholder="All"
                  name="partner_id"
                  options={dropdownOption(projectPartner)}
                  loading={partnerLoading}
                  search
                  searchInput={{ id: 'partners' }}
                  onChange={handleChange}
                />

                <Form.Group widths="equal">
                  <Form.Dropdown
                    placeholder="Select"
                    fluid
                    required
                    label="ID Type"
                    selection
                    name="id_type"
                    value={form.id_type}
                    options={idTypeOptions}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input"
                    control={Input}
                    required
                    label="ID Number"
                    placeholder="ID Number..."
                    name="id_number"
                    loading={fetchNidaLoading}
                    error={
                      form.id_number && !isValidId(form.id_number)
                        ? { content: 'Wrong ID Number' }
                        : nidaData.fail
                        ? { content: 'Invalid national ID' }
                        : false
                    }
                    icon={
                      form.id_number && isValidId(form.id_number)
                        ? 'checkmark'
                        : ''
                    }
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Input
                  onChange={handleChange}
                  required
                  label="Names"
                  name="first_name"
                  placeholder="Names"
                  value={
                    !nidaData.fail
                      ? `${nidaData.foreName ||
                          ''}  ${nidaData.surnames || ''}`
                      : ''
                  }
                  defaultValue={
                    !nidaData.fail
                      ? `${nidaData.foreName ||
                          ''}  ${nidaData.surnames || ''}`
                      : ''
                  }
                  disabled
                />

                <Form.Group widths="equal">
                  <Form.Dropdown
                    selection
                    label="Gender"
                    name="gender"
                    type="text"
                    placeholder="Gender"
                    options={genderOptions}
                    loading={form?.gender && verifyNidaLoading}
                    required
                    onChange={handleChange}
                    error={
                      form.gender &&
                      !nidaVerificationData?.gender?.isValid
                        ? { content: 'Wrong gender' }
                        : false
                    }
                  />
                  <Form.Input
                    fluid
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="Email"
                  />
                </Form.Group>
              </>
            )}

            {error && error.message && (
              <Message negative>{error.message}</Message>
            )}

            <Form.Button
              floated="right"
              type="submit"
              primary
              disabled={
                (form.id_number && !isValidId(form.id_number)) ||
                !form.id_type ||
                !nidaVerificationData?.gender?.isValid ||
                nidaData.fail
              }
            >
              Invite Agent
            </Form.Button>
          </Form>
        </div>
      </div>
    </>
  );
};

InviteAgent.defaultProps = {
  tabOption: null,
};

InviteAgent.propTypes = {
  tabOption: PropTypes.string,
};

export default InviteAgent;
