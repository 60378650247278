import SingleUser from 'containers/Users/SingleUser';

export default {
  exact: true,
  name: 'Single User',
  protected: true,
  path: '/users/:userId',
  component: SingleUser,
  permissions: ['all'],
};
