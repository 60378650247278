import {
  GET_ORG_WALLET_REQUESTS_START,
  GET_ORG_WALLET_REQUESTS_SUCCESS,
  GET_ORG_WALLET_REQUESTS_ERROR,
  GET_ORG_GROUPS_REQUESTS_ERROR,
  GET_ORG_GROUPS_REQUESTS_START,
  GET_ORG_GROUPS_REQUESTS_SUCCESS,
} from 'constants/action-types/wallets/getOrgRequests';

import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';

export const getOrgTransactions = queries => async dispatch => {
  dispatch(
    apiAction({
      url: '/admin/transactions',
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORG_WALLET_REQUESTS_START,
      API_REQUEST_SUCCESS: GET_ORG_WALLET_REQUESTS_SUCCESS,
      API_REQUEST_FAILURE: GET_ORG_WALLET_REQUESTS_ERROR,
      onEnd: () => false,
      callbackOnFailure: error => {
        notificationHelper({
          message: error.message,
          status: 'error',
        });
      },
    }),
  );
};

export const getOrgGroupRequests = orgId => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${orgId}/groups/requests`,
      method: 'GET',
      API_REQUEST_START: GET_ORG_GROUPS_REQUESTS_START,
      API_REQUEST_SUCCESS: GET_ORG_GROUPS_REQUESTS_SUCCESS,
      API_REQUEST_FAILURE: GET_ORG_GROUPS_REQUESTS_ERROR,
      onEnd: () => false,
    }),
  );
};
