import AddUser from 'containers/Users/AddUser';

export default {
  exact: true,
  name: 'Create Organization User',
  protected: true,
  path: '/organizations/:organizationId/create-user',
  component: AddUser,
  permissions: ['all'],
};
