import {
  GET_SINGLE_ORGANIZATION_OVERVIEW_ERROR,
  GET_SINGLE_ORGANIZATION_OVERVIEW_START,
  GET_SINGLE_ORGANIZATION_OVERVIEW_SUCCESS,
} from 'constants/action-types/organizations/singleOrganizationOverview';
import apiAction from 'helpers/apiAction';

export default (organizationId, queries) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/overview`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_SINGLE_ORGANIZATION_OVERVIEW_START,
      API_REQUEST_SUCCESS: GET_SINGLE_ORGANIZATION_OVERVIEW_SUCCESS,
      API_REQUEST_FAILURE: GET_SINGLE_ORGANIZATION_OVERVIEW_ERROR,
      onEnd: () => false,
    }),
  );
};

export const getNGOOverview = (
  organizationId,
  queries,
) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_SINGLE_ORGANIZATION_OVERVIEW_START,
      API_REQUEST_SUCCESS: GET_SINGLE_ORGANIZATION_OVERVIEW_SUCCESS,
      API_REQUEST_FAILURE: GET_SINGLE_ORGANIZATION_OVERVIEW_ERROR,
      onEnd: () => false,
    }),
  );
};
