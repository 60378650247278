import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'assets/styles/index.scss';
import setTitle from 'helpers/setTitle';
import DashBoard from 'containers/DashBoard';
import routes from './routes';

const protectedRoutes = (routes || []).filter(
  route => route.protected,
);
const unprotectedRoutes = (routes || []).filter(
  route => !route.protected,
);

const App = () => {
  const { isAuthenticated } = useSelector(
    ({ user: { currentUser } }) => currentUser,
  );
  const { details: currentOrg } = useSelector(
    ({ dashboard }) => dashboard?.currentOrganization,
  );

  return (
    <div className="App">
      <Router>
        <Switch>
          {unprotectedRoutes.map(route => (
            <Route
              key={route.name}
              exact
              path={route.path}
              render={props => {
                setTitle(props.location, route);
                return (
                  <route.component
                    location={props.location}
                    history={props.history}
                    match={props.match}
                  />
                );
              }}
            />
          ))}

          {protectedRoutes.map(route => (
            <Route
              key={route.name}
              exact
              path={route.path}
              render={props => {
                setTitle(props.location, route);
                if (!isAuthenticated) {
                  localStorage.setItem(
                    'PREVIOUS_URL',
                    props.location.pathname,
                  );
                  return <Redirect to="/login" />;
                }
                if (isAuthenticated && isEmpty(currentOrg))
                  return <Redirect to="/select-organization" />;

                return (
                  <DashBoard>
                    <route.component
                      location={props.location}
                      history={props.history}
                      match={props.match}
                    />
                  </DashBoard>
                );
              }}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
};

App.defaultProps = {
  location: {},
  history: {},
  match: {},
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({}),
};

export default App;
