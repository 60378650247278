import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Checkbox, Icon } from 'semantic-ui-react';
import './accordionToggle.scss';
import PropTypes from 'prop-types';
import { capitalizeEachWords } from 'utils/capitalize';

const AccordionToggle = ({
  data,
  handleChange,
  disabled,
  linkTo,
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <>
      <div className="accordion-toggle">
        <Accordion>
          <div className="accordion-toggle__item">
            <Accordion.Title
              active={isActive}
              onClick={() => setActive(!isActive)}
            >
              <div className="accordion-toggle__main">
                <div className="left">
                  <Icon name="dropdown" />
                  {capitalizeEachWords(data?.title)}
                </div>
                <div className="right">
                  <Checkbox
                    toggle
                    checked={data?.status}
                    disabled={
                      disabled ||
                      data?.subItems?.length === 0 ||
                      data?.subItems === false
                    }
                    onChange={(_, { checked }) =>
                      handleChange(
                        checked,
                        data?.context,
                        data?.subItems,
                        'toggleBulk',
                        data?.title,
                      )
                    }
                  />
                </div>
              </div>
            </Accordion.Title>
            <Accordion.Content active={isActive}>
              {data?.subItems?.length > 0 ? (
                data?.subItems?.map(item => (
                  <div className="accordion-toggle__content">
                    {linkTo ? (
                      <Link
                        to={
                          linkTo === 'organization'
                            ? `/organizations/${item?.organization_id}`
                            : linkTo === 'project'
                            ? `/organizations/${item?.organization_id}/projects/${item?.id}/overview`
                            : `/organizations/${item?.organization_id}/groups/${item?.id}`
                        }
                      >
                        <div className="left">
                          {capitalizeEachWords(item?.name)}
                        </div>
                      </Link>
                    ) : (
                      <div className="left">
                        {capitalizeEachWords(item?.name)}
                      </div>
                    )}

                    <div className="right">
                      <Checkbox
                        toggle
                        disabled={disabled}
                        checked={[
                          true,
                          'accepted',
                          'active',
                        ].includes(item?.status)}
                        onChange={(_, { checked }) =>
                          handleChange(
                            checked,
                            data?.context,
                            item,
                            'toggleRole',
                            data?.title,
                          )
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="accordion-toggle__content">
                  <div className="left">N/A</div>
                </div>
              )}
            </Accordion.Content>
          </div>
        </Accordion>
      </div>
    </>
  );
};

AccordionToggle.defaultProps = {
  disabled: false,
  linkTo: '',
};

AccordionToggle.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    subItems: PropTypes.array.isRequired,
    context: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  linkTo: PropTypes.string,
};

export default AccordionToggle;
