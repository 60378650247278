import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import './index.scss';
import { isSuperAdmin } from 'helpers/checkRoles';
import ListOrganizations from 'containers/Organizations/ListOrganizations';
import getOrganizations from 'redux/actions/organizations/listOrganizations';

const AllOrganizations = ({ history, location }) => {
  const dispatch = useDispatch();
  const [paginationMeta, setPaginationMeta] = useState({});

  const { items = [], loading, meta } = useSelector(
    ({ organizations: { list } }) => list,
  );
  const { roles } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const handleGetOrganizations = (
    queries = queryString.parse(location.search),
  ) => {
    return (
      roles &&
      isSuperAdmin(roles) &&
      getOrganizations(queries)(dispatch)
    );
  };

  useEffect(() => {
    if (meta) {
      setPaginationMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    handleGetOrganizations();
  }, [dispatch, roles]);

  const onSearch = ({ search }) => {
    handleGetOrganizations({ search });
  };

  const onPaginate = queries => {
    handleGetOrganizations(queries);
  };

  return (
    <ListOrganizations
      history={history}
      items={items}
      loading={loading}
      all
      onSearch={onSearch}
      onPaginate={onPaginate}
      meta={paginationMeta}
    />
  );
};

AllOrganizations.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default AllOrganizations;
