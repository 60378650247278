export default [
  'individual_wallet_top_up',
  'individual_wallet_withdraw',
  'individual_wallet_transfer',
  'pay_electricity',
  'pay_water',
  'pay_airtime',
  'pay_tv',
  'pay_4g',
];
