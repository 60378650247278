import AllUsers from 'containers/Users/AllUsers';

export default {
  exact: true,
  name: 'All users',
  protected: true,
  path: '/all-users',
  component: AllUsers,
  permissions: ['all'],
};
