import ProjectDashboard from 'containers/Projects/ProjectDashboard';

export default {
  exact: true,
  name: 'Project',
  protected: true,
  path:
    '/projects/:projectId/organizations/:organizationId/:tab?/:option?/:option1?/:option2?',
  component: ProjectDashboard,
  permissions: ['all'],
};
