/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Image,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react';
import Loader from 'components/common/PageLoader';
import './index.scss';
import Empty from 'components/common/Empty';
import editIcon from 'assets/images/editIcon.svg';
import Toolbar from 'components/common/Toolbar';
import { capitalizeEachWords } from 'utils/capitalize';
import PINInput from 'components/common/PINInput';
import { canPerformAction } from 'helpers/permissions';
import getMicroloansSettings from 'redux/actions/settings/getMicroloansSettings';
import labelFormatter from 'helpers/labelFormatter';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import BulkEditModal from './SettingModals';
import BasicDetails from './SettingModals/BasicDetails';
import EligibilityCheck from './SettingModals/EligibilityCheck';
import Repayment from './SettingModals/Repayment';
import RequestAmount from './SettingModals/RequestAmount';
import TermsAndCondtions from './SettingModals/TermsAndCondtions';
import InterestDetails from './SettingModals/InterestDetails';

const MicroLoans = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [pinModal, setPinModal] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState({});
  const [isBulkEditModalOpened, showBulkEditModal] = useState(false);

  const { roles = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );
  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettings } }) => microloanSettings,
  );

  const { loading: updateLoading } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  const actionLoading = false;

  const actionError = null;

  const panes = [
    {
      menuItem: 'Basic Details',
      render: data => <BasicDetails data={data} />,
    },
    {
      menuItem: 'Eligibility Checks',
      render: data => <EligibilityCheck type={data.type} />,
    },
    {
      menuItem: 'Repayment',
      render: data => <Repayment type={data.type} />,
    },
    {
      menuItem: 'Request Amount',
      render: data => <RequestAmount type={data.type} />,
    },
    {
      menuItem: 'Terms & Conditions',
      render: data => <TermsAndCondtions type={data.type} />,
    },
    {
      menuItem: 'Interest Details',
      render: data => <InterestDetails type={data.type} />,
    },
  ];

  useEffect(() => {
    getMicroloansSettings({})(dispatch);
  }, []);

  const changeStatus = (status, type) => {
    setForm({ ...form, status, type });
    setPinModal(true);
  };

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: form.password,
      type: form.type,
      section: 'Basic',
      data: {
        status: form.status,
        disbursement: data[form.type].disbursement,
        eligible_groups: data[form.type].eligible_groups,
      },
    })(dispatch, () => {
      setPinModal(false);
      getMicroloansSettings({})(dispatch);
    });
  };

  return (
    <>
      <Modal
        size="mini"
        open={pinModal}
        onClose={() => setPinModal(false)}
      >
        <Modal.Header>
          {form?.status ? 'Activate' : 'Deactivate'} {form?.title}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to{' '}
            {form?.status ? 'activate' : 'deactivate'} {form?.title}{' '}
            Feature?
          </p>
          <Form onSubmit={handleSubmit} loading={actionLoading}>
            <PINInput
              className="input"
              fluid
              size="tiny"
              label="Enter Pin"
              name="password"
              onInputChange={handleChange}
            />

            {actionError && actionError === 'Wrong PIN.' && (
              <p color="red">
                <Message negative>{actionError}</Message>
              </p>
            )}

            <Button
              className="confirm"
              type="submit"
              color="blue"
              fluid
              loading={updateLoading}
              disabled={updateLoading}
            >
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
      <BulkEditModal
        title={`Edit ${selectedSetting.name}`}
        panes={panes}
        data={selectedSetting}
        states={{ isOpened: isBulkEditModalOpened }}
        showBulkEditModal={showBulkEditModal}
        size="small"
      />
      <Toolbar
        header={{
          title: 'Micro loans',
          navigation: false,
        }}
        style={{
          marginLeft: '0px',
        }}
      />
      <div className="setting__microloans">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : Object.keys(data).length === 0 ? (
          <Empty message="No features found" />
        ) : (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>
                  Disbursment Wallet
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Eligible Group types{' '}
                </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Object.keys(data).map(microloanType => (
                <Table.Row key={microloanType}>
                  <Table.Cell>
                    {labelFormatter(microloanType)}
                  </Table.Cell>
                  <Table.Cell>
                    {labelFormatter(data[microloanType].disbursement)}
                  </Table.Cell>
                  <Table.Cell>
                    {capitalizeEachWords(
                      data[microloanType]?.eligible_groups?.join(
                        ', ',
                      ) || '',
                    )}
                  </Table.Cell>
                  <Table.Cell className="status-field sg-status">
                    <Checkbox
                      toggle
                      checked={data[microloanType].status}
                      onChange={(_, { checked }) =>
                        changeStatus(checked, microloanType)
                      }
                      disabled={
                        !canPerformAction(
                          'canDeactivateFeature',
                          roles,
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell className="flex flex-start">
                    <Image
                      src={editIcon}
                      className="cursor"
                      onClick={() => {
                        setSelectedSetting({
                          ...data[microloanType],
                          type: microloanType,
                        });
                        showBulkEditModal(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  );
};

export default MicroLoans;
