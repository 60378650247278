export const GET_ORG_WALLET_REQUESTS_START =
  'GET_ORG_WALLET_REQUESTS_START';
export const GET_ORG_WALLET_REQUESTS_ERROR =
  'GET_ORG_WALLET_REQUESTS_ERROR';
export const GET_ORG_WALLET_REQUESTS_SUCCESS =
  'GET_ORG_WALLET_REQUESTS_SUCCESS';

export const GET_ORG_GROUPS_REQUESTS_START =
  'GET_ORG_GROUPS_REQUESTS_START';
export const GET_ORG_GROUPS_REQUESTS_ERROR =
  'GET_ORG_GROUPS_REQUESTS_ERROR';
export const GET_ORG_GROUPS_REQUESTS_SUCCESS =
  'GET_ORG_GROUPS_REQUESTS_SUCCESS';
