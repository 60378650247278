import SetPin from 'containers/Users/SetPin';

export default {
  exact: true,
  name: 'Set Pin',
  protected: false,
  path: '/set_new_password',
  component: SetPin,
  permissions: ['all'],
};
