import React from 'react';
import { capitalize } from 'lodash';
import { capitalizeEachWords } from 'utils/capitalize';
import groupImage from 'assets/images/group-chat.svg';
import individualWalletTransactions from 'constants/individualWalletTransactions';

const formatedDisplay = ({
  purpose,
  group,
  type,
  senderOrReceiverNumber,
  serviceProviderName,
}) => {
  if (individualWalletTransactions.includes(purpose)) {
    if (
      ['pay_canal', 'pay_startimes', 'pay_dstv'].includes(
        serviceProviderName,
      )
    ) {
      serviceProviderName = capitalize(
        serviceProviderName?.split('_')[1],
      );
    } else serviceProviderName = serviceProviderName?.toUpperCase();

    return (
      <span className="contact-item">
        <img
          src={groupImage}
          className="group-item-icon"
          alt="users"
        />
        <b>
          {purpose === 'individual_wallet_transfer'
            ? type === 'debit'
              ? 'To'
              : 'From'
            : 'For'}
          :
        </b>
        {['individual_wallet_transfer', 'pay_airtime'].includes(
          purpose,
        )
          ? senderOrReceiverNumber
          : [
              'individual_wallet_top_up',
              'individual_wallet_withdraw',
            ].includes(purpose)
          ? 'Wallet'
          : serviceProviderName}
      </span>
    );
  }

  return (
    <>
      <span className="contact-item">
        <img
          src={groupImage}
          className="group-item-icon"
          alt="users"
        />
        <b>Group:</b>
        {capitalizeEachWords(group?.name || '')}
      </span>
    </>
  );
};

export default formatedDisplay;
