import React from 'react';
import PropTypes from 'prop-types';
import { Label, Popup } from 'semantic-ui-react';
import { capitalizeEachWords } from 'utils/capitalize';

import './index.scss';

const InfoCard = ({
  cardTitle,
  amount,
  isCurrency,
  displayDate,
  loanStatus,
  date,
}) => {
  return (
    <div className="info__card">
      <Popup
        trigger={
          <span className="info__card__content">
            {capitalizeEachWords(cardTitle)}:{' '}
            <strong>
              {displayDate && `${date} | `}
              {amount} {isCurrency && 'RWF'}
            </strong>
          </span>
        }
        position="top left"
        hoverable
        on="hover"
        content={
          <span className="info__card__content">
            {capitalizeEachWords(cardTitle)}:{' '}
            <strong>
              {displayDate && `${date} | `}
              {amount} {isCurrency && 'RWF'}
            </strong>
          </span>
        }
      />
      {!!loanStatus && (
        <div className="badge-div">
          <Label
            color={
              loanStatus === 'overdue'
                ? 'red'
                : loanStatus === 'due'
                ? 'blue'
                : 'green'
            }
          >
            {capitalizeEachWords(loanStatus)}
          </Label>
        </div>
      )}
    </div>
  );
};

InfoCard.propTypes = {
  cardTitle: PropTypes.string,
  amount: PropTypes.string,
  isCurrency: PropTypes.bool,
  displayDate: PropTypes.bool,
  loanStatus: PropTypes.string,
  date: PropTypes.string,
};

InfoCard.defaultProps = {
  cardTitle: '',
  amount: '',
  isCurrency: false,
  displayDate: false,
  loanStatus: '',
  date: '',
};

export default InfoCard;
