export default {
  createProject: {
    loading: false,
    error: null,
  },
  list: {
    items: [],
    loading: false,
    error: null,
  },
  currentProject: {
    data: {},
    loading: false,
    error: null,
    overview: {},
  },
  updateProject: {
    loading: false,
    error: null,
    data: {},
  },
  invitePartner: {
    loading: false,
    error: null,
  },
  projectPartners: {
    items: [],
    loading: false,
    error: null,
  },
  projectPartner: {
    data: {},
    loading: false,
    error: null,
  },
  removeAgent: {
    message: null,
    loading: false,
    error: null,
  },
};
