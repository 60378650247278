import MicroLoanRequests from 'containers/MicroLoanRequests';

export default {
  exact: true,
  name: 'Pending requests',
  protected: true,
  path: '/requests',
  component: MicroLoanRequests,
  permissions: ['all'],
};
