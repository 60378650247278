import React from 'react';
import PropTypes from 'prop-types';

const TransactionPopupContent = ({ items, data }) => {
  return (
    <div className="popup-content transaction-popup">
      {items?.items?.map(popupItem => (
        <div
          role="link"
          tabIndex="-1"
          onKeyDown={() => null}
          className="popup-content__link"
          onClick={() => popupItem?.onClick(data)}
        >
          {popupItem?.label}
        </div>
      ))}
    </div>
  );
};

TransactionPopupContent.defaultProps = {
  data: {},
};

TransactionPopupContent.propTypes = {
  items: PropTypes.array.isRequired,
  data: PropTypes.any,
};

export default TransactionPopupContent;
