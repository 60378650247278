import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import PINInput from 'components/common/PINInput';
import { approveOrgGroupRequests } from 'redux/actions/wallets/approvalOrgRequests';
import notify from 'helpers/notificationHelper';
import { walletDropDownOptions } from 'utils/dropdownOptions';
import isOrgRequestFormValid from 'helpers/validators/validateOrgRequestForm';

const ApproveRequestForm = ({
  organizationId,
  requestMeta,
  loadingGroupRequest,
  action,
  setAction,
  errorOrgGroupRequest,
  request,
  groupId,
  context,
}) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({});

  const handleChange = (_, { name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleApproval = () => {
    approveOrgGroupRequests({
      organizationId,
      requestId: request.id,
      action,
      data: form,
      groupId,
      context,
    })(dispatch)(setAction, notify);
  };

  return (
    <Form onSubmit={handleApproval} loading={loadingGroupRequest}>
      <Form.Select
        required
        name="provider_name"
        fluid
        label="Select Channel"
        options={walletDropDownOptions(requestMeta?.wallets)}
        placeholder="Channel"
        onChange={handleChange}
      />

      <PINInput
        label="PIN"
        required
        fluid
        placeholder="PIN"
        name="password"
        className="confirm-transaction-input"
        value={form.password}
        onChange={handleChange}
      />

      {errorOrgGroupRequest && (
        <p color="red">
          <Message negative>{errorOrgGroupRequest?.message}</Message>
        </p>
      )}
      <Button
        type="submit"
        content={
          action === 'amend'
            ? 'Save & notify group committee'
            : 'Submit'
        }
        color="blue"
        fluid
        disabled={!isOrgRequestFormValid(action, form)}
      />
    </Form>
  );
};

ApproveRequestForm.propTypes = {
  organizationId: PropTypes.number,
  loadingGroupRequest: PropTypes.bool,
  action: PropTypes.string,
  setAction: PropTypes.func,
  request: PropTypes.object,
  context: PropTypes.string,
  requestMeta: PropTypes.instanceOf(Array),
  errorOrgGroupRequest: PropTypes.instanceOf(Object),
  groupId: PropTypes.number,
};

ApproveRequestForm.defaultProps = {
  setAction: () => null,
  loadingGroupRequest: false,
  action: '',
  request: {},
  organizationId: '',
  requestMeta: [],
  context: '',
  errorOrgGroupRequest: {},
  groupId: 0,
};

export default ApproveRequestForm;
