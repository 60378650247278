/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import OrganizationLineCharts from 'components/Organizations/SingleOrganization/Overview/LineCharts';
import OrganizationDetails from 'components/Organizations/SingleOrganization/Overview/Details';
import OrganizationGenders from 'components/Organizations/SingleOrganization/Overview/Genders';
import getProjectPartner from 'redux/actions/projects/getProjectPartner';
import Message from 'components/common/Message';
import StatCharts from 'components/Organizations/SingleOrganization/Overview/StatCharts';

const SingleOrganization = ({
  match: {
    params: { organizationId, projectId, option: partnerId },
  },
}) => {
  const { data, loading, error } = useSelector(
    ({ projects: { projectPartner } }) => projectPartner,
  );

  const dispatch = useDispatch();

  const {
    organization: {
      name = '',
      type,
      phone_number: phoneNumber,
      email,
      address,
    } = {},
  } = data;

  useEffect(() => {
    getProjectPartner(organizationId, projectId, partnerId)(dispatch);
  }, [dispatch]);

  return (
    <div className="group-overview scroll-wrapper">
      <Toolbar
        header={{
          breadcrumbParent: 'Partner',
          breadcrumbParentLink: `/projects/${projectId}/organizations/${organizationId}/partners`,
          breadcrumbActive: `${name}`,
        }}
      />

      {loading ? (
        <Loader />
      ) : error ? (
        <Message color="red" message={error.message} />
      ) : (
        data && (
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column width="8">
                <OrganizationLineCharts data={data} />
              </Grid.Column>
              <Grid.Column width="5">
                <OrganizationDetails
                  data={data}
                  name={name}
                  type={type}
                  phoneNumber={phoneNumber}
                  email={email}
                  address={address}
                />
              </Grid.Column>
              <Grid.Column width="3">
                <OrganizationGenders data={data} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={data} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      )}
    </div>
  );
};

SingleOrganization.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
};

export default SingleOrganization;
