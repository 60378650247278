import {
  BULK_CREATE_ERROR,
  BULK_CREATE_START,
  BULK_CREATE_SUCCESS,
} from 'constants/action-types/organizations/bulkCreate';

export default (state, { type, payload }) => {
  switch (type) {
    case BULK_CREATE_START:
      return {
        ...state,
        bulkCreate: {
          ...state.bulkCreate,
          loading: true,
          error: null,
        },
      };
    case BULK_CREATE_ERROR:
      return {
        ...state,
        bulkCreate: {
          ...state.bulkCreate,
          error: payload,
          loading: false,
        },
      };
    case BULK_CREATE_SUCCESS:
      return {
        ...state,
        bulkCreate: {
          ...state.bulkCreate,
          data: payload.data,
          loading: false,
        },
      };
    default:
      return null;
  }
};
