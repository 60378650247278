import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import notify from 'helpers/notificationHelper';
import * as notificationStatus from 'constants/notificationStatus';
import ConfirmActionUI from 'components/Users/SingleUser/ConfirmActionUI';
import {
  deactivateUser,
  clearUserDeactivated,
} from '../../redux/actions/user/deactivateActivateUser';

const ConfirmAction = ({
  match,
  setEnterPasswordDialogOpen,
  action,
}) => {
  const {
    params: { userId },
  } = match;

  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const { error, user, isDeactivating } = useSelector(
    state => state.user.deactivateUser,
  );
  const onSubmit = () => {
    if (password !== '') {
      deactivateUser({
        id: userId,
        password,
        do: action,
      })(dispatch);
    }
  };
  const statusHasChanged = user.message
    ? user.message.split(' ')[0].replace('d', '') !== action
    : false;

  useEffect(() => {
    if (statusHasChanged) {
      notify({
        message: user.message,
        status: notificationStatus.SUCCESS,
      });
      setEnterPasswordDialogOpen(false);
      clearUserDeactivated()(dispatch);
    }
  }, [statusHasChanged, dispatch, user, setEnterPasswordDialogOpen]);

  return (
    <ConfirmActionUI
      error={error}
      isDeactivating={isDeactivating}
      onSubmit={onSubmit}
      setPassword={setPassword}
      action={action}
    />
  );
};

ConfirmAction.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
  setEnterPasswordDialogOpen: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
};

export default ConfirmAction;
