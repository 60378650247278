/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from 'semantic-ui-react';
import './PieChartWrapper.scss';
import PieChart from 'components/common/charts/PieChart';
import todaysDate from 'utils/todaysDate';

const PieChartWrapper = ({
  title,
  className,
  date,
  width,
  height,
  data,
  style,
  filled,
  ...restProps
}) => {
  return (
    <Card
      className={`pie-chart-wrapper ${className || ''}`}
      style={style}
    >
      <div className="title">{title}</div>
      <div className="date">{date}</div>
      <Divider />
      <div className="pie-chart-container">
        <PieChart
          filled={filled}
          width={width}
          height={height}
          {...restProps}
          data={data}
        />
      </div>
    </Card>
  );
};

PieChartWrapper.defaultProps = {
  title: '',
  className: '',
  date: todaysDate(),
  width: 600,
  height: 300,
  style: {},
  filled: false,
  data: [],
};

PieChartWrapper.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.instanceOf(Object),
  filled: PropTypes.bool,
};

export default PieChartWrapper;
