import {
  GET_PROFILE_OVERVIEW_ERROR,
  GET_PROFILE_OVERVIEW_START,
  GET_PROFILE_OVERVIEW_SUCCESS,
} from 'constants/action-types/organizations/profiles/getProfileOverview';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_OVERVIEW_START:
      return {
        ...state,
        profileOverview: {
          ...state.profileOverview,
          loading: true,
          error: null,
        },
      };
    case GET_PROFILE_OVERVIEW_SUCCESS:
      return {
        ...state,
        profileOverview: {
          ...state.profileOverview,
          loading: false,
          data: payload.data,
        },
      };
    case GET_PROFILE_OVERVIEW_ERROR:
      return {
        ...state,
        profileOverview: {
          ...state.profileOverview,
          loading: false,
          error: payload,
        },
      };
    default:
      return null;
  }
};
