import React from 'react';
import './index.scss';

const UploadProgress = () => (
  <div className="uploading-box">
    <div className="uploading-box__top">
      <div className="uploading-box__top__action">Uploading...</div>
    </div>
    <div className="uploading-box__bar">
      <div className="uploading-box__bar__value" />
    </div>
  </div>
);

export default UploadProgress;
