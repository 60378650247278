import {
  DEACTIVATE_ADVERTISEMENT_ERROR,
  DEACTIVATE_ADVERTISEMENT_START,
  DEACTIVATE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/deactivateAdvertisement';

export default (state, { type, payload }) => {
  switch (type) {
    case DEACTIVATE_ADVERTISEMENT_START:
      return {
        ...state,
        deactivateAdvertisement: {
          ...state.deactivateAdvertisement,
          loading: true,
          error: null,
          message: null,
        },
      };
    case DEACTIVATE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        deactivateAdvertisement: {
          ...state.deactivateAdvertisement,
          error: payload,
          loading: false,
        },
      };
    case DEACTIVATE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        deactivateAdvertisement: {
          ...state.deactivateAdvertisement,
          error: {},
          loading: false,
          message: payload,
        },
      };
    default:
      return null;
  }
};
