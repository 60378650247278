/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import PhoneInput from 'components/common/PhoneInput';
import PINInput from 'components/common/PINInput';
import './index.scss';
import whiteLogo from '../../assets/images/white_logo.png';

const ResetPassword = ({
  error,
  message,
  loading,
  formData,
  handleChange,
  handleSubmit,
  isPasswordReset,
  isAccountVerified,
}) => {
  return (
    <section className="ResetPassword">
      <div className="container">
        <img src={whiteLogo} alt="logo" />
        <Form>
          <h1>Reset PIN</h1>
          <Helmet defaultTitle="Save." titleTemplate="%s - Save.">
            <title>Reset PIN</title>
          </Helmet>
          {!isAccountVerified && !isPasswordReset ? (
            <PhoneInput
              required
              name="phone"
              className="reset-phone-input"
              value={formData.phone || ''}
              onChange={handleChange}
            />
          ) : (
            <>
              <Form.Field>
                <Form.Input
                  label="Code"
                  type="number"
                  name="code"
                  placeholder="123456"
                  value={formData.code || ''}
                  required
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>New PIN</label>
                <PINInput
                  name="password"
                  value={formData.password || ''}
                  onInputChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>PIN Again</label>
                <PINInput
                  name="passwordAgain"
                  value={formData.passwordAgain || ''}
                  onInputChange={handleChange}
                />
              </Form.Field>
            </>
          )}
          {message && (
            <Message positive>
              <span className="success-message">{message}</span>
            </Message>
          )}
          {error && (
            <Message negative>
              <span className="error-message">{error}</span>
            </Message>
          )}
          <Button.Group floated="right">
            {loading ? (
              <Button loading primary>
                Loading
              </Button>
            ) : (
              <Button
                icon
                primary
                type="submit"
                labelPosition="right"
                name="submit-btn"
                onClick={handleSubmit}
              >
                {isPasswordReset ? 'Login' : 'Submit'}
                <Icon name="check" />
              </Button>
            )}
          </Button.Group>
        </Form>
        <div className="login">
          <Link to="/login">Sign in instead?</Link>
        </div>
      </div>
    </section>
  );
};

ResetPassword.defaultProps = {
  formData: {},
  loading: false,
  message: '',
  error: '',
  isPasswordReset: false,
  isAccountVerified: false,
};

ResetPassword.propTypes = {
  formData: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isPasswordReset: PropTypes.bool,
  isAccountVerified: PropTypes.bool,
};

export default ResetPassword;
