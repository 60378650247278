import {
  UPDATE_USER_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_END,
  UPDATE_USER_END,
} from 'constants/action-types/user/updateUser';
import apiAction from 'helpers/apiAction';
import notify from 'helpers/notificationHelper';
import { SUCCESSFULLY_UPDATED } from 'constants/response-messages/users/updateUser';

const onSuccess = response => dispatch => {
  notify({
    message: SUCCESSFULLY_UPDATED,
  });

  dispatch({
    type: UPDATE_USER_SUCCESS,
    payload: response,
  });
  dispatch({
    type: UPDATE_USER_END,
    payload: response.data,
  });
};

const onAccountUpdated = response => dispatch => {
  notify({
    message: SUCCESSFULLY_UPDATED,
  });
  dispatch({
    type: UPDATE_USER_SUCCESS,
    payload: response.data,
  });
  dispatch({
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: response.data,
  });
  dispatch({
    type: UPDATE_ACCOUNT_END,
  });
};

export default (formData, id, account) => dispatch => {
  const url = account ? '/profiles' : `/users/${id}`;

  const data = {};
  Object.keys(formData).map(key => {
    if (formData[key]) {
      data[key] = formData[key];
    }
    return null;
  });

  if (account && data.phone_number) delete data.phone_number;

  dispatch(
    apiAction({
      url,
      method: 'PUT',
      data: {
        ...data,
        gender: data.gender ? data.gender.toLowerCase() : undefined,
        birth_date: data.birth_date
          ? new Date(data.birth_date).toISOString()
          : undefined,
      },
      API_REQUEST_START: UPDATE_USER_START,
      API_REQUEST_SUCCESS: UPDATE_USER_SUCCESS,
      API_REQUEST_FAILURE: UPDATE_USER_ERROR,
      onSuccess: data => {
        if (Object.keys(data).includes('message')) {
          return onAccountUpdated(data)(dispatch);
        }
        return onSuccess(data)(dispatch);
      },
      onEnd: () => false,
    }),
  );
};
