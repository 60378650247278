/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Dropdown,
  Button,
  Message,
} from 'semantic-ui-react';
import Toolbar from 'components/common/Toolbar';
import { genderOptions } from 'utils/dropdownOptions';
import { categories } from 'constants/categories/organization';
import {
  SELECT_CATEGORY,
  SELECT_GENDER,
} from 'constants/response-messages/organizations';
import './index.scss';
import PartnerSearch from 'components/Projects/Partners/PartnerSearch';
import invitePartner from 'redux/actions/projects/invitePartner';
import InterventionArea from 'components/common/InterventionArea';
import PhoneInput from 'components/common/PhoneInput';
import formatedAreas from 'helpers/formatedAreas';
import InputNumberFormat from 'components/common/InputNumberFormat';

const InvitePartners = ({
  match: {
    params: { organizationId, projectId },
  },
  history: { push },
}) => {
  const { items } = useSelector(
    ({ organizations: { organizationSearch } }) => organizationSearch,
  );

  const [form, setForm] = useState({ org_type: 'NGO' });
  const [organizationExist, setOrganizationExist] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [areas, setAreas] = useState();

  const handleChange = (e, data) => {
    if (data.name === 'intervention_areas') {
      setAreas({
        sectors: data.sectors,
        districts: data.districts,
      });
    }

    setForm({ ...form, [data.name]: data.value });

    if (items.some(item => item.id === data.value)) {
      return setOrganizationExist(true);
    }
    setOrganizationExist(false);
  };

  const { loading } = useSelector(
    ({ organizations: { create } }) => create,
  );

  const { name: projectName } = useSelector(
    ({ projects: { currentProject: { data = {} } } = {} }) => data,
  );

  const { loading: inviteLoading, error: inviteError } = useSelector(
    ({ projects: { invitePartner } }) => invitePartner,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleInvitePartner = () => {
    const data = {
      ...form,
      intervention_areas: formatedAreas(form, areas),
    };

    invitePartner(
      organizationId,
      projectId,
      {
        ...data,
        user_gender: String(form.user_gender).toLowerCase(),
      },
      organizationExist,
    )(dispatch)(push);
  };

  const handleSubmit = () => {
    if (organizationExist) {
      return handleInvitePartner();
    }

    if (!form.org_category) {
      return setCategoryError(SELECT_CATEGORY);
    }

    if (!form.user_gender) {
      return setGenderError(SELECT_GENDER);
    }

    setCategoryError(null);
    setGenderError(null);
    return handleInvitePartner();
  };

  const selectedOrganization =
    items.find(({ id }) => id === form.name) || {};

  return (
    <section className="invite-partner scroll-wrapper">
      <Toolbar
        header={{ title: 'Invite Partner', navigation: true }}
      />
      <div className="container">
        <div className="wrapper">
          <div className="title">
            <div>Partner's Details</div>
          </div>
          <Form
            onSubmit={handleSubmit}
            loading={loading || inviteLoading}
          >
            <PartnerSearch
              onChange={handleChange}
              handleChange={handleChange}
            />

            <Form.Group widths="2">
              <Form.Field
                control={Input}
                required
                label="Email"
                type="email"
                name="org_email"
                disabled={organizationExist}
                placeholder={'Email' || selectedOrganization.email}
                onChange={handleChange}
              />

              <PhoneInput
                required
                name="org_phone_number"
                disabled={organizationExist}
                value={selectedOrganization.phone_number}
                organization
                onChange={handleChange}
              />
            </Form.Group>

            {!organizationExist && (
              <Form.Group widths="2">
                <Form.Field
                  disabled
                  control={Input}
                  type="text"
                  label="Type"
                  name="org_type"
                  placeholder="Type"
                  value="NGO"
                  onChange={handleChange}
                />

                <Form.Field
                  required
                  control={Input}
                  type="text"
                  label="Address"
                  name="org_address"
                  placeholder="Address"
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            {!organizationExist && (
              <Form.Field
                required
                selection
                control={Dropdown}
                error={categoryError}
                label="Category"
                name="org_category"
                placeholder="Category"
                options={categories.NGO}
                onChange={handleChange}
              />
            )}

            <Form.Field
              value={projectName}
              control={Input}
              label="Project"
              disabled
            />

            {!organizationExist && (
              <>
                <div className="title padded">
                  <div>Partner's Contact Person</div>
                </div>
                <Form.Group widths="2">
                  <Form.Field
                    required
                    type="text"
                    label="First name"
                    control={Input}
                    name="user_first_name"
                    placeholder="First name"
                    onChange={handleChange}
                  />
                  <Form.Field
                    required
                    type="text"
                    label="Last name"
                    control={Input}
                    name="user_last_name"
                    placeholder="Last name"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group widths="2">
                  <Form.Field
                    control={Input}
                    required
                    label="Email"
                    type="email"
                    name="user_email"
                    placeholder="email"
                    onChange={handleChange}
                  />
                  <PhoneInput
                    required
                    name="user_phone_number"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group widths="2">
                  <Form.Field
                    required
                    control={Input}
                    type="text"
                    label="ID Number"
                    name="user_id_number"
                    placeholder="ID Number"
                    onChange={handleChange}
                  />

                  <Form.Field
                    required
                    selection
                    control={Dropdown}
                    error={genderError}
                    label="Gender"
                    name="user_gender"
                    placeholder="Gender"
                    options={genderOptions}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            )}

            {!organizationExist && (
              <InterventionArea
                keys={{
                  province: 'org_province',
                  district: 'org_district',
                  sector: 'org_sector',
                  cell: 'org_cell',
                  village: 'org_village',
                }}
                name="Partner's Intervention Area"
                data={selectedOrganization}
                onChange={handleChange}
              />
            )}
            {inviteError && (
              <Message
                negative
                visible={false}
                content={
                  inviteError && inviteError.errors
                    ? inviteError.errors[0].message.replace(/"/g, '')
                    : inviteError && inviteError.message
                }
              />
            )}

            <Button
              type="submit"
              floated="right"
              color="primary"
              disabled={loading}
            >
              {t('Invite')}
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
};

InvitePartners.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
};

export default InvitePartners;
