/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Cycle from 'components/common/cycle';
import todaysDate from 'utils/todaysDate';

const GroupGenders = ({
  data: {
    cycle_length: cycleLength,
    sg_cycles_count: sgCyclesCount,
    member_cycles_count: memberCyclesCount,
  },
  year,
}) => {
  const currentDate = todaysDate(year);

  return (
    <div className="group-genders cycles">
      <Grid>
        <Grid.Row columns="1">
          <Grid.Column width="16">
            <Cycle
              name="Cycle Length"
              count={cycleLength}
              date={currentDate}
              countLabel="months"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column width="16">
            <Cycle
              name="SG Cycle"
              count={sgCyclesCount}
              date={currentDate}
              countLabel="st"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column width="16">
            <Cycle
              name="Member Cycle"
              count={memberCyclesCount}
              date={currentDate}
              countLabel="st"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GroupGenders.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default GroupGenders;
