import {
  GET_PROJECT_FAILED,
  GET_PROJECT_START,
  GET_PROJECT_SUCCESS,
} from 'constants/action-types/projects/getProject';

import apiAction from 'helpers/apiAction';

export default (
  organizationId,
  projectId,
  _,
  queries,
) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}/overview`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_PROJECT_START,
      API_REQUEST_SUCCESS: GET_PROJECT_SUCCESS,
      API_REQUEST_FAILURE: GET_PROJECT_FAILED,
      onEnd: () => false,
    }),
  );
};
