const generateFilterableYears = () => {
  const filterableYears = ['all'];

  let currentYear = new Date().getFullYear();

  while (currentYear >= 2017) {
    filterableYears.push(String(currentYear));
    currentYear -= 1;
  }

  return filterableYears;
};

const filterableYears = generateFilterableYears();

export default filterableYears;
