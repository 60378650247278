import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import { isArray } from 'lodash';
import Proptype from 'prop-types';
import Message from 'components/common/Message';
import getMicroloansSettingData from 'redux/actions/settings/getMicroloansSettingData';
import updateMicroloansSettings from 'redux/actions/settings/updateMicroloansSettings';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import labelFormatter from 'helpers/labelFormatter';
import isFieldRequired from 'helpers/validators/isFieldRequired';

const TermsAndCondtions = ({ type }) => {
  const [form, setForm] = useState({});
  const [openPinModal, setOpenPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');
  const [fieldError, setFieldError] = useState({});

  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(
    ({ settings: { microloanSettingsData } }) =>
      microloanSettingsData,
  );
  const { loading: updateLoading, error: updateError } = useSelector(
    ({ settings: { updateMicroloanSettings } }) =>
      updateMicroloanSettings,
  );

  useEffect(() => {
    getMicroloansSettingData({
      section: 'Terms and Conditions',
      type,
    })(dispatch);
  }, []);

  const validateFields = () => {
    const formData = {
      ...data,
      ...form,
      micro_loan_insurance:
        form.micro_loan_insurance || data.micro_loan_insurance,
      independent: {
        ...data.independent,
        ...form.independent,
      },
      graduated: {
        ...data.graduated,
        ...form.graduated,
      },
    };

    const microLoanInsuranceError =
      formData.micro_loan_insurance > 0
        ? false
        : 'Micro loan insurance is required and should be greater than 0';

    const independentMemberSavingsError =
      formData.independent?.member_savings_guaranteed > 0
        ? false
        : 'Percentage guaranteed by member savings is required and should be greater than 0';

    const independentGroupSavingsError =
      formData.independent?.groups_savings_guaranteed > 0
        ? false
        : 'Percentage guaranteed by group savings is required and should be greater than 0';

    const graduatedMemberSavingsError =
      formData.graduated?.member_savings_guaranteed > 0
        ? false
        : 'Percentage guaranteed by member savings is required and should be greater than 0';

    const graduatedGroupSavingsError =
      formData.graduated?.groups_savings_guaranteed > 0
        ? false
        : 'Percentage guaranteed by group savings is required and should be greater than 0';

    setFieldError({
      ...fieldError,
      microLoanInsuranceError,
      independentMemberSavingsError,
      independentGroupSavingsError,
      graduatedMemberSavingsError,
      graduatedGroupSavingsError,
    });

    return (
      !microLoanInsuranceError &&
      !independentMemberSavingsError &&
      !independentGroupSavingsError &&
      !graduatedMemberSavingsError &&
      !graduatedGroupSavingsError
    );
  };

  const handleSubmit = () => {
    updateMicroloansSettings({
      password: userPin,
      type,
      section: 'Terms and Conditions',
      data: {
        ...data,
        ...form,
        micro_loan_insurance:
          form.micro_loan_insurance || data.micro_loan_insurance,
        independent: {
          ...data.independent,
          ...form.independent,
        },
        graduated: {
          ...data.graduated,
          ...form.graduated,
        },
      },
    })(dispatch, () => setOpenPinModal(false));
  };

  return (
    <>
      <ConfirmPinModal
        content={`Are you sure you want to update the settings for ${labelFormatter(
          type,
        )}`}
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        userPin={userPin}
        setUserPin={setUserPin}
        error={{ message: updateError }}
        loading={updateLoading}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
      />
      <Form
        onSubmit={() => {
          if (validateFields()) {
            setOpenPinModal(!openPinModal);
          }
        }}
        loading={loading}
      >
        {!loading && error && (
          <Message message={error.message} color="red" />
        )}
        <h5>Independent Groups</h5>
        {data && (
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              label="Percentage guaranteed by member savings (%)"
              placeholder="19"
              onChange={(e, { value }) => {
                setFieldError({
                  ...fieldError,
                  independentMemberSavingsError: false,
                });
                return setForm({
                  ...form,
                  independent: {
                    ...form.independent,
                    member_savings_guaranteed: Number(value),
                  },
                });
              }}
              name="independent.member_savings_guaranteed"
              defaultValue={
                data.independent?.member_savings_guaranteed
              }
              error={fieldError?.independentMemberSavingsError}
            />

            <Form.Field
              control={Input}
              type="number"
              label="Percentage guaranteed by group savings (%)"
              placeholder="19"
              onChange={(e, { value }) => {
                setFieldError({
                  ...fieldError,
                  independentGroupSavingsError: false,
                });
                return setForm({
                  ...form,
                  independent: {
                    ...form.independent,
                    groups_savings_guaranteed: Number(value),
                  },
                });
              }}
              name="independent.groups_savings_guaranteed"
              defaultValue={
                data.independent?.groups_savings_guaranteed
              }
              error={fieldError?.independentGroupSavingsError}
            />
          </Form.Group>
        )}

        <h5>Graduated Groups</h5>
        {data && (
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              label="Percentage guaranteed by member savings (%)"
              placeholder="19"
              onChange={(e, { value }) => {
                setFieldError({
                  ...fieldError,
                  graduatedMemberSavingsError: false,
                });
                return setForm({
                  ...form,
                  graduated: {
                    ...form.graduated,
                    member_savings_guaranteed: Number(value),
                  },
                });
              }}
              name="graduated.member_savings_guaranteed"
              defaultValue={data.graduated?.member_savings_guaranteed}
              error={fieldError?.graduatedMemberSavingsError}
            />

            <Form.Field
              control={Input}
              type="number"
              label="Percentage guaranteed by group savings (%)"
              placeholder="19"
              onChange={(e, { value }) => {
                setFieldError({
                  ...fieldError,
                  graduatedGroupSavingsError: false,
                });
                return setForm({
                  ...form,
                  graduated: {
                    ...form.graduated,
                    groups_savings_guaranteed: Number(value),
                  },
                });
              }}
              name="graduated.groups_savings_guaranteed"
              defaultValue={data.graduated?.groups_savings_guaranteed}
              error={fieldError?.graduatedGroupSavingsError}
            />
          </Form.Group>
        )}

        <h5>Micro Insurance (%)</h5>
        {data && (
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              placeholder="19"
              onChange={(e, { value }) => {
                setFieldError({
                  ...fieldError,
                  microLoanInsuranceError: false,
                });
                return setForm({
                  ...form,
                  micro_loan_insurance: Number(value),
                });
              }}
              name="micro_loan_insurance"
              defaultValue={data.micro_loan_insurance}
              error={fieldError?.microLoanInsuranceError}
            />
          </Form.Group>
        )}

        <div className="action">
          <Button
            type="submit"
            primary
            loading={updateLoading}
            disabled={updateLoading || Object.keys(form).length === 0}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
TermsAndCondtions.propTypes = {
  type: Proptype.string.isRequired,
};

export default TermsAndCondtions;
