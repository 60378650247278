/* eslint-disable no-restricted-globals */
import React, { useState, useMemo } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import './index.scss';
import PropTypes from 'prop-types';
import { uniqWith } from 'lodash';
import { toDropDownOptions } from 'utils/dropdownOptions';
import getOrdinalNumber from 'helpers/getOrdinalNumber';

const cycleFilterOptions = ['all', 'current'];

const DropdownCycleFilter = ({
  handleChange,
  params = {},
  name,
  label,
  group,
  disabled,
}) => {
  const [cycle, setCycle] = useState();

  const handleCycleFilter = (e, { value }) => {
    setCycle(value);
    if (value === 'all') handleChange(params, {});
    else if (value === 'current')
      handleChange(params, { cycle_id: group?.cycle_id });
    else handleChange(params, { cycle_id: value });
  };

  let options = useMemo(
    () =>
      toDropDownOptions([
        ...cycleFilterOptions,
        ...(group?.cycles
          ?.map(cycle => `${cycle.id}`)
          .sort((a, b) => b - a) || []),
      ]),
    [group],
  );

  options = useMemo(
    () =>
      options?.map((cycle, index, arr) => {
        if (!isNaN(cycle.key)) {
          return {
            ...cycle,
            text: getOrdinalNumber(arr.length - index),
          };
        }
        if (cycle.value === 'current') {
          setCycle(group?.cycle_id);
          return {
            ...cycle,
            value: group?.cycle_id,
          };
        }
        return cycle;
      }) || [],
    [options],
  );

  options = uniqWith(
    options,
    (a, b) => `${a.value}` === `${b.value}`,
  );

  return (
    <div
      className={`cycle_filter_options ${
        !label
          ? 'cycle_filter_options__margin flex flex-center-vertical'
          : 'cycle_filter_options__no-margin'
      }`}
    >
      {!label && <p className="cycle_filter_options__title">Cycle</p>}
      <Form.Field
        control={Dropdown}
        selection
        clearable
        data-testid="dropdown"
        value={cycle}
        options={options}
        onChange={handleCycleFilter}
        type="text"
        label={label}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};

DropdownCycleFilter.defaultProps = {
  params: {},
  name: '',
  label: null,
  disabled: false,
};

DropdownCycleFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  params: PropTypes.object,
  group: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DropdownCycleFilter;
