import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from 'semantic-ui-react';
import getActivities from 'redux/actions/organizations/getActivities';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import { isSuperAdmin } from 'helpers/checkRoles';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import ActivityItem from './ActivityItem';
import './RecentActivities.scss';

const RecentActivities = ({
  activities,
  loading,
  fetched,
  activitiesMeta,
}) => {
  const {
    organizations: userOrganizations = [],
    roles,
  } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (userOrganizations[0]) {
      getActivities(userOrganizations[0].id)(dispatch);
    }
  }, [dispatch, userOrganizations]);

  const noContent = fetched && !activities.length;

  const title = item => {
    if (isSuperAdmin(roles) && item.organization) {
      return item.organization.name;
    }

    if (!isSuperAdmin(roles) && item.project) {
      return item.project.name;
    }

    if (item.group) {
      return item.group.name;
    }

    return '';
  };

  const onQuery = queries => {
    if (userOrganizations[0]) {
      getActivities(userOrganizations[0].id, queries)(dispatch);
    }
  };

  return (
    <>
      <div className="recent-activities">
        <Toolbar
          header={{
            title: 'Recent Activities',
            rightComponent: (
              <ToolbarFooter
                onSearch={onQuery}
                onPaginate={onQuery}
                paginationMeta={activitiesMeta}
              />
            ),
          }}
        />
        {loading && <Loader />}
        <div className="activities">
          {activities &&
            activities.map(item => (
              <ActivityItem
                key={item.id}
                title={title(item)}
                description={item}
              />
            ))}
          {noContent && (
            <Message>
              <span>No Activities</span>
            </Message>
          )}
        </div>
      </div>
    </>
  );
};

RecentActivities.defaultProps = {
  activitiesMeta: {},
};

RecentActivities.propTypes = {
  activities: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  activitiesMeta: PropTypes.instanceOf(Object),
};

export default RecentActivities;
