import {
  CHANGE_PIN_START,
  CHANGE_PIN_SUCCESS,
  CHANGE_PIN_FAIL,
  CHANGE_PIN_CLEAR,
} from 'constants/action-types/user/changePin';

export default (state, { type, payload }) => {
  switch (type) {
    case CHANGE_PIN_START:
      return {
        ...state,
        changePin: {
          ...state.changePin,
          loading: true,
          error: null,
          message: null,
        },
      };
    case CHANGE_PIN_SUCCESS:
      return {
        ...state,
        changePin: {
          ...state.changePin,
          loading: false,
          message: payload,
        },
      };
    case CHANGE_PIN_FAIL:
      return {
        ...state,
        changePin: {
          ...state.changePin,
          error: payload,
          loading: false,
          message: null,
        },
      };
    case CHANGE_PIN_CLEAR:
      return {
        ...state,
        changePin: {
          ...state.changePin,
          error: null,
          message: null,
        },
      };
    default:
      return null;
  }
};
