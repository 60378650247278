import {
  DEACTIVATE_FEATURE_ERROR,
  DEACTIVATE_FEATURE_START,
  DEACTIVATE_FEATURE_SUCCESS,
} from 'constants/action-types/settings/deactivateFeature';

export default (state, { type, payload }) => {
  switch (type) {
    case DEACTIVATE_FEATURE_START:
      return {
        ...state,
        deactivateFeature: {
          ...state.deactivateFeature,
          loading: true,
          error: null,
          message: null,
        },
      };
    case DEACTIVATE_FEATURE_ERROR:
      return {
        ...state,
        deactivateFeature: {
          ...state.deactivateFeature,
          error: payload,
          loading: false,
        },
      };
    case DEACTIVATE_FEATURE_SUCCESS:
      return {
        ...state,
        deactivateFeature: {
          ...state.deactivateFeature,
          error: {},
          loading: false,
          message: payload,
        },
      };
    default:
      return null;
  }
};
