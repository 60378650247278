/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Table, Message } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import duration from 'utils/duration';
import Toolbar from 'components/common/Toolbar';
import getOrganizationProjects from 'redux/actions/projects/listProjects';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import './AllProjects.scss';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import formatNumber from 'helpers/formatNumber';

const OrganizationProjects = ({ organizationId }) => {
  const { items, loading, error, meta } = useSelector(
    ({ projects: { list } }) => list,
  );
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { t } = useTranslation();

  const onPaginate = queries => {
    getOrganizationProjects(organizationId, queries)(dispatch);
  };

  const onSearch = queries => {
    getOrganizationProjects(organizationId, queries)(dispatch);
  };

  useEffect(() => {
    getOrganizationProjects(organizationId)(dispatch);
  }, [dispatch, organizationId]);
  return (
    <div className="projects-table">
      <Toolbar
        header={{
          title: 'Projects',
          navigation: true,
          rightComponent: (
            <ToolbarFooter
              onPaginate={onPaginate}
              paginationMeta={meta}
              onSearch={onSearch}
              exportCSVData={items}
              exportCSVFileName="Projects"
            />
          ),
        }}
      />
      {loading ? (
        <Loader size="small" />
      ) : error ? (
        <Message visible negative content={error.message} />
      ) : !Array.isArray(items) || items.length === 0 ? (
        <Empty message="No projects found" />
      ) : (
        <div className="projectlist">
          <Table basic="very" size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('Project')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Partner')}</Table.HeaderCell>
                <Table.HeaderCell>
                  {t('Saving Groups')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('Budget')} (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>{t('Duration')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {items.map(
                ({
                  organization_id,
                  id,
                  avatar,
                  name,
                  partners = [],
                  groups = [],
                  partners_count: partnersCount,
                  groups_count: groupsCount,
                  budget,
                  start_date: startDate,
                  end_date: endDate,
                }) => (
                  <Table.Row
                    onClick={() =>
                      push(
                        `/organizations/${organization_id}/projects/${id}/overview`,
                      )
                    }
                  >
                    <Table.Cell>
                      <div className="project-name">
                        <Thumbnail
                          key={id}
                          avatar={avatar}
                          name={name}
                        />
                        <span>{name}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {partnersCount || partners.length}
                    </Table.Cell>
                    <Table.Cell>
                      {groupsCount || groups.length}
                    </Table.Cell>
                    <Table.Cell>{formatNumber(budget)}</Table.Cell>
                    <Table.Cell>
                      {duration(startDate, endDate)}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

OrganizationProjects.propTypes = {
  organizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default OrganizationProjects;
