export default (
  form,
  getChargeFrom,
  providerTypes,
  frequencyTypes,
  userPin,
) => {
  const data = {
    ...form,
    charge_frequency:
      form?.charge_from_wallet === 'individual_wallet' ||
      form?.charge_per_category === 'group_member'
        ? frequencyTypes?.[0]
        : form?.charge_frequency,
    get_charge_from:
      form?.charge_frequency === 'instant'
        ? form?.get_charge_from
        : getChargeFrom?.[0],
    charge_per_category:
      form?.charge_from_wallet === 'individual_wallet'
        ? undefined
        : form?.charge_per_category,
    wallet_provider:
      form?.charge_from_wallet === 'individual_wallet'
        ? providerTypes?.[0]
        : form?.wallet_provider,
    password: userPin,
    status:
      form?.charge_from_wallet === 'individual_wallet'
        ? 'inactive'
        : form.status || undefined,
  };

  return data;
};
