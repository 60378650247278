import organizations from 'redux/reducers/organizations';
import user from 'redux/reducers/user';
import projects from 'redux/reducers/projects';
import profiles from 'redux/reducers/profiles';
import activities from 'redux/reducers/activities';
import wallets from 'redux/reducers/wallets';
import dashboard from 'redux/reducers/dashboard';
import support from 'redux/reducers/support';
import groups from 'redux/reducers/groups';
import settings from 'redux/reducers/settings';
import exportData from 'redux/reducers/export';
import advertisement from 'redux/reducers/communication/advertisement';
import loanRequests from 'redux/reducers/loanRequests';

export default {
  user,
  organizations,
  projects,
  profiles,
  activities,
  wallets,
  dashboard,
  support,
  groups,
  settings,
  exportData,
  advertisement,
  loanRequests,
};
