import React, { useEffect, useState } from 'react';
import { Table, Message, Popup, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import './index.scss';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import Empty from 'components/common/Empty';
import AmountInfoCard from 'components/common/AmountInfoCard';
import capitalize, { capitalizeEachWords } from 'utils/capitalize';
import { getSingleGroup } from 'redux/actions/organizations/organizationGroups';
import AmendRequestForm from 'components/common/OrgLoanRequestModals/OrgLoanRequestAmendForm';
import DeclineRequestForm from 'components/common/OrgLoanRequestModals/OrgLoanRequestDeclineForm';
import ApproveRequestForm from 'components/common/OrgLoanRequestModals/OrgRequestApprovalForm';
import SimpleModal from 'components/common/modals/SimpleModal';
import LoanOverviewModal from 'components/common/LoansOverviewModal';
import getOrgGroupLoanHistory from 'redux/actions/wallets/getOrgGroupLoanHistory';
import { orgGroupRequestsFormReset } from 'redux/actions/wallets/approvalOrgRequests';
import LoanDetails from 'components/Organizations/SingleOrganization/Requests/LoanDetails';
import OrgLoanPopup from 'components/Organizations/SingleOrganization/Requests/OrgLoanPopup';
import statusColors from 'constants/statusColors';

const GroupTransactionsHistory = () => {
  const dispatch = useDispatch();

  const { projectId, organizationId, option: groupId } = useParams();
  const [showInternalLoans, setShowInternalLoans] = useState(false);
  const [action, setAction] = useState('');
  const [currentRequest, setCurrentRequest] = useState({});
  const [groupName, setGroupName] = useState('');
  const [selectedRow, setSelectedRow] = useState('');

  useEffect(() => {
    orgGroupRequestsFormReset()(dispatch);
  }, [action]);

  useEffect(() => {
    getSingleGroup(groupId)(dispatch);
    getOrgGroupLoanHistory(organizationId, groupId)(dispatch);
  }, [dispatch, organizationId]);

  const { items, loading, error, meta } = useSelector(
    ({ wallets: { orgGroupLoanHistory } }) => orgGroupLoanHistory,
  );

  const {
    sortedItems: transactions,
    unSortedItems,
    pendingRequest,
    installment,
  } = items;

  const {
    loading: loadingGroupRequest,
    error: errorOrgGroupRequest,
  } = useSelector(
    ({ wallets: { approveOrgGroupRequests } }) =>
      approveOrgGroupRequests,
  );

  const { item } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup,
  );

  const getTitle = action => {
    return action === 'accept'
      ? 'Confirm Loan Approval'
      : action === 'amend'
      ? `${capitalizeEachWords(item?.group?.name)} Loan Request`
      : 'Confirm Loan Decline';
  };

  const todayDate = moment().format('YYYY-MM-DD');

  const nextPayment = () => {
    if (
      installment?.loans_balance > 0 &&
      item?.group?.cumulative_org_loans_total > 0
    ) {
      if (todayDate > installment?.next_payment_date) {
        return {
          cardTitle: 'next payment',
          loanStatus: 'overdue',
          date: installment?.next_payment_date,
        };
      }
      return {
        cardTitle: 'next payment',
        loanStatus: 'due',
        date: installment?.next_payment_date,
      };
    }
    if (installment?.loans_balance === 0)
      return {
        cardTitle: 'last payment',
        loanStatus: 'cleared',
        date: installment?.next_payment_date,
      };
    return {};
  };

  const nextPaymentValue = nextPayment();

  const onExpandRow = id => {
    setSelectedRow(selectedRow === id ? '' : id);
  };

  const groupTransactions = id => {
    return transactions?.filter(
      transaction => transaction?.request_id === id.toString(),
    );
  };

  return (
    <>
      <div className="group-transactions__lists">
        <Toolbar
          header={{
            breadcrumbParent: 'Loan History',
            breadcrumbParentLink: `/projects/${projectId}/organizations/${organizationId}/requests`,
            breadcrumbActive: ` ${capitalizeEachWords(
              item?.group?.name,
            )}`,
            navigation: true,
            rightComponent: (
              <>
                {pendingRequest && (
                  <>
                    <Button
                      inverted
                      size="medium"
                      color="green"
                      onClick={() => {
                        setAction('accept');
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      inverted
                      size="medium"
                      color="red"
                      onClick={() => {
                        setAction('reject');
                      }}
                    >
                      Decline
                    </Button>
                    <Button
                      inverted
                      size="medium"
                      color="blue"
                      onClick={() => {
                        setAction('amend');
                      }}
                    >
                      Amend
                    </Button>
                  </>
                )}
                <Button
                  color="blue"
                  onClick={() => {
                    setShowInternalLoans(true);
                  }}
                >
                  Internal Loans Overview
                </Button>
              </>
            ),
          }}
        />
        {pendingRequest ? (
          <div className="loan-info-cards">
            <div className="loan-info-cards-items">
              <div className="loan-info-cards-item">
                <AmountInfoCard
                  cardTitle="requested amount"
                  amount={numeral(pendingRequest?.amount).format(
                    '0.00',
                  )}
                  isCurrency
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="repayment days"
                  amount={
                    pendingRequest?.group_request?.repayment_days
                  }
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="interest on loan"
                  amount={numeral(
                    pendingRequest?.group_request?.loans_interest,
                  ).format('0.00')}
                  isCurrency
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="installments"
                  amount={pendingRequest?.repayment_installments}
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="payment/installment"
                  amount={numeral(
                    pendingRequest?.next_payment_amount,
                  ).format('0.00')}
                  isCurrency
                  loading={loading}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="loan-info-cards">
            <div className="loan-info-cards-items">
              <div className="loan-info-cards-item">
                <AmountInfoCard
                  cardTitle="cumulative loan"
                  amount={numeral(
                    item.group?.cumulative_org_loans_total,
                  ).format('0.00')}
                  isCurrency
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="cumulative interest"
                  amount={numeral(
                    item.group?.cumulative_org_loans_interest_total,
                  ).format('0.00')}
                  isCurrency
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="outstanding balance"
                  amount={numeral(installment?.loans_balance).format(
                    '0.00',
                  )}
                  isCurrency
                  loading={loading}
                />
                <AmountInfoCard
                  cardTitle="late fees"
                  amount={numeral(
                    installment?.groups_late_fees,
                  ).format('0.00')}
                  isCurrency
                  loading={loading}
                />
                {!isEmpty(nextPaymentValue) && (
                  <AmountInfoCard
                    cardTitle={nextPaymentValue.cardTitle}
                    amount={
                      installment?.group_request?.is_modified
                        ? numeral(
                            installment?.group_request
                              ?.modified_installment_amount,
                          ).format('0.00')
                        : numeral(
                            installment?.group_request
                              ?.repayment_installment_amount,
                          ).format('0.00')
                    }
                    isCurrency
                    displayDate
                    loanStatus={nextPaymentValue.loanStatus}
                    date={
                      moment(nextPaymentValue?.date).isValid()
                        ? moment(nextPaymentValue?.date).format('LL')
                        : '-'
                    }
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="groups-requests-table">
          {loading ? (
            <Loader />
          ) : error ? (
            <Message negative>{error.message}</Message>
          ) : transactions?.length === 0 ? (
            <Empty message="No transactions found" />
          ) : (
            <Table basic="very" size="large">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Activity Type</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Approved By</Table.HeaderCell>
                  <Table.HeaderCell>Due Amount</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {unSortedItems?.map(
                  ({
                    id,
                    group,
                    status,
                    amount,
                    type,
                    created_at,
                    is_modified,
                    group_request,
                    modified_amount,
                    loans_balance,
                    org_loan_balance,
                  }) => (
                    <>
                      <Table.Row
                        key={id}
                        className={
                          selectedRow === id ? 'isActive' : ''
                        }
                      >
                        <Table.Cell>
                          {['cleared', 'approved'].includes(
                            status,
                          ) && (
                            <Button
                              onClick={() => onExpandRow(id)}
                              className={`state ${
                                selectedRow === id ? 'open' : ''
                              }`}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <div
                            className={`group-name ${status ===
                              'approved' && 'rejected'}`}
                          >
                            {type === 'loan' && is_modified ? (
                              <span>Loan Amendment</span>
                            ) : type === 'loan' && !is_modified ? (
                              <span>Loan Request</span>
                            ) : (
                              <span>Loan Repayment</span>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {is_modified
                            ? numeral(modified_amount).format('0.00')
                            : numeral(amount).format('0.00')}
                        </Table.Cell>
                        <Table.Cell>
                          {type === 'loan'
                            ? `${group?.created_by?.first_name} ${group?.created_by?.last_name}`
                            : 'N/A'}
                        </Table.Cell>
                        <Table.Cell>
                          {type === 'loan'
                            ? numeral(loans_balance).format('0.00')
                            : numeral(org_loan_balance).format(
                                '0.00',
                              )}
                        </Table.Cell>
                        <Table.Cell className="wallet__transactions--status">
                          <div
                            className="wallet__transactions--status--buble"
                            style={{
                              backgroundColor: statusColors[status],
                            }}
                          />
                          <div>{capitalize(status)}</div>
                        </Table.Cell>
                        <Table.Cell>
                          {moment(created_at).format('LL')}
                        </Table.Cell>

                        <Table.Cell>
                          {type === 'loan' && (
                            <Popup
                              trigger={
                                <div className="status-icon">
                                  <span>i</span>
                                </div>
                              }
                              position="top right"
                              key={id}
                              hoverable
                              on="click"
                              content={
                                <OrgLoanPopup
                                  setAction={setAction}
                                  request={group_request}
                                  setRequest={setCurrentRequest}
                                  context="details"
                                  data={group}
                                  setGroupName={setGroupName}
                                />
                              }
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                      {!isEmpty(groupTransactions(id)) &&
                        groupTransactions(id)?.map(transaction => (
                          <Table.Row
                            key={transaction?.id}
                            className={
                              selectedRow.toString() ===
                              transaction?.request_id
                                ? 'view'
                                : 'fold'
                            }
                          >
                            <Table.Cell />
                            <Table.Cell>Loan Repayment</Table.Cell>
                            <Table.Cell>
                              {numeral(transaction?.amount).format(
                                '0.00',
                              )}
                            </Table.Cell>
                            <Table.Cell>N/A</Table.Cell>
                            <Table.Cell>
                              {numeral(
                                transaction?.org_loan_balance,
                              ).format('0.00')}
                            </Table.Cell>
                            <Table.Cell className="wallet__transactions--status">
                              <div
                                className="wallet__transactions--status--buble"
                                style={{
                                  backgroundColor:
                                    statusColors[transaction?.status],
                                }}
                              />
                              <div>
                                {capitalize(transaction?.status)}
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              {moment(transaction?.created_at).format(
                                'LL',
                              )}
                            </Table.Cell>
                            <Table.Cell />
                          </Table.Row>
                        ))}
                    </>
                  ),
                )}
              </Table.Body>
            </Table>
          )}
        </div>
      </div>
      <SimpleModal
        title={getTitle(action)}
        open={['accept', 'reject', 'amend'].includes(action)}
        onClose={() => setAction('')}
        action={action}
      >
        {action === 'amend' && (
          <AmendRequestForm
            action={action}
            organizationId={organizationId}
            loadingGroupRequest={loadingGroupRequest}
            errorOrgGroupRequest={errorOrgGroupRequest}
            request={pendingRequest}
            requestMeta={meta}
            setAction={setAction}
            groupId={groupId}
            context="history"
          />
        )}
        {action === 'accept' && (
          <ApproveRequestForm
            action={action}
            organizationId={organizationId}
            loadingGroupRequest={loadingGroupRequest}
            errorOrgGroupRequest={errorOrgGroupRequest}
            request={pendingRequest}
            requestMeta={meta}
            setAction={setAction}
            groupId={groupId}
            context="history"
          />
        )}
        {action === 'reject' && (
          <DeclineRequestForm
            action={action}
            organizationId={organizationId}
            loadingGroupRequest={loadingGroupRequest}
            errorOrgGroupRequest={errorOrgGroupRequest}
            request={pendingRequest}
            setAction={setAction}
            groupId={groupId}
            context="history"
          />
        )}
      </SimpleModal>

      <SimpleModal
        title={`${capitalizeEachWords(groupName)} Loan Details`}
        open={action === 'details'}
        onClose={() => setAction('')}
      >
        <LoanDetails
          data={{
            request: currentRequest,
          }}
        />
      </SimpleModal>

      <SimpleModal
        title="Internal Loans Overview"
        open={showInternalLoans}
        onClose={() => setShowInternalLoans(false)}
      >
        <LoanOverviewModal group={item} />
      </SimpleModal>
    </>
  );
};

GroupTransactionsHistory.defaultProps = {};

GroupTransactionsHistory.propTypes = {};

export default GroupTransactionsHistory;
