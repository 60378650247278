import {
  GET_ORG_LOAN_HISTORY_ERROR,
  GET_ORG_LOAN_HISTORY_START,
  GET_ORG_LOAN_HISTORY_SUCCESS,
} from 'constants/action-types/wallets/getOrgLoanHistory';

import axiosHelper from 'helpers/axiosHelper';
import notificationHelper from 'helpers/notificationHelper';

export default (orgId, groupId) => async dispatch => {
  const axios = axiosHelper();
  dispatch({
    type: GET_ORG_LOAN_HISTORY_START,
  });
  Promise.all([
    axios.request({
      method: 'GET',
      url: `/organizations/${orgId}/groups/${groupId}/requests`,
    }),
    axios.request({
      url: `/groups/${groupId}/loans/statement`,
      method: 'GET',
    }),
  ])
    .then(([{ data: requests }, { data: allRepayments }]) => {
      const { data = { repayments: [] } } = allRepayments;

      const sortedItems = [
        ...requests?.data,
        ...data?.repayments,
      ].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      return dispatch({
        type: GET_ORG_LOAN_HISTORY_SUCCESS,
        payload: {
          records: {
            sortedItems,
            unSortedItems: requests?.data,
            pendingRequest: requests.pendingRecord,
            installment: data,
          },
          meta: requests.meta,
        },
      });
    })
    .catch(error => {
      notificationHelper({
        message: error.message,
        status: 'error',
      });
      return dispatch({
        type: GET_ORG_LOAN_HISTORY_ERROR,
        payload: error,
      });
    });
};
