import { omit } from 'lodash';
import {
  GET_GROUP_ACTIVITIES_ERROR,
  GET_GROUP_ACTIVITIES_START,
  GET_GROUP_ACTIVITIES_SUCCESS,
} from 'constants/action-types/organizations/groupActivities';

import apiAction from 'helpers/apiAction';

export default (groupId, queries) => dispatch => {
  const url =
    queries?.category === 'transactions'
      ? `/groups/${groupId}/transactions`
      : `/groups/${groupId}/activities`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries:
        queries?.category === 'transactions'
          ? omit(queries, 'category')
          : omit(queries, 'cycle_id'),
      API_REQUEST_START: GET_GROUP_ACTIVITIES_START,
      API_REQUEST_SUCCESS: GET_GROUP_ACTIVITIES_SUCCESS,
      API_REQUEST_FAILURE: GET_GROUP_ACTIVITIES_ERROR,
      onSuccess: ({ data, meta }) => {
        dispatch({
          type: GET_GROUP_ACTIVITIES_SUCCESS,
          payload: { data, meta },
        });
      },
      onEnd: () => false,
    }),
  );
};
