/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Message,
  Grid,
  Dropdown,
  Icon,
  Button,
  Header,
  Modal,
  Form,
  TextArea,
  Popup,
} from 'semantic-ui-react';
import PINInput from 'components/common/PINInput';
import getGroupMember from 'redux/actions/organizations/getGroupMember';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import MemberLineCharts from 'components/Organizations/SingleOrganization/Groups/Member/LineCharts';
import MemberDetails from 'components/Organizations/SingleOrganization/Groups/Member/MemberDetails';
import MemberCycles from 'components/Organizations/SingleOrganization/Groups/Member/MemberCycles';
import StatCharts from 'containers/Organizations/SingleOrganization/Groups/Member/StatCharts';
import DeleteConfirmationModel from 'components/common/modals/SimpleModal';
import { isSuperAdmin } from 'helpers/checkRoles';
import deleteGroupMember from 'redux/actions/organizations/deleteGroupMember';
import capitalize from 'utils/capitalize';
import isMemberBalanceEmpty from 'helpers/isMemberBalanceEmpty';
import './index.scss';
import DropdownFilterYear from 'components/common/DropdownFilterYear';

const GroupMember = ({
  match: {
    params: {
      tabOption,
      option2: memberId,
      option,
      organizationId,
      projectId,
    },
  },
}) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [year, setYear] = useState();
  const [confirmGroupModel, setConfirmGroupModel] = useState({
    action: '',
    header: '',
    body: '',
    context: '',
    model: false,
    modelForm: false,
  });
  const [form, setForm] = useState({});
  const [pinModal, setPinModal] = useState(false);
  const { data, loading, error } = useSelector(
    ({ organizations: { groupMember } }) => groupMember,
  );
  const {
    loading: loadingDeleteMember,
    error: errorRemoveMember,
  } = useSelector(
    ({ organizations: { deleteGroupMember } }) => deleteGroupMember,
  );

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } = {} }) =>
      details,
  );

  const groupId = tabOption || option;

  const dispatch = useDispatch();

  const {
    member_info: {
      user: {
        first_name: firstName = '',
        last_name: lastName = '',
      } = {},
    } = {},
    group: { name: groupName = '' } = {},
    savings_balance: savingsBalance = 0,
    social_funds_balance: socialFundBalance = 0,
  } = data;

  useEffect(() => {
    getGroupMember(
      groupId,
      memberId,
      year === 'all' ? {} : { year },
    )(dispatch);
  }, [dispatch, year]);

  const onCloseConfirmModal = () => {
    setConfirmGroupModel({
      ...confirmGroupModel,
      model: false,
      modelForm: false,
    });
  };

  const handleSumbitMemberActions = () => {
    switch (confirmGroupModel.context) {
      case 'remove-member':
        deleteGroupMember(organizationId, groupId, memberId, form)(
          dispatch,
          setPinModal,
          push,
          onCloseConfirmModal,
        );
        break;
      default:
        break;
    }
  };

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const isActionApplicable = action => {
    switch (action) {
      case 'remove':
      case 'delete':
        return isMemberBalanceEmpty(
          savingsBalance,
          socialFundBalance,
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal size="mini" open={pinModal}>
        <Modal.Header>
          Confirm Remove Member
          <Icon
            name="close"
            fitted
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={handleSumbitMemberActions}
            loading={loadingDeleteMember}
          >
            <PINInput
              className="input"
              fluid
              size="mini"
              label="Enter Pin"
              name="password"
              onInputChange={handleChange}
            />
            <p fluid size="tiny">
              {errorRemoveMember && (
                <Message negative>{errorRemoveMember}</Message>
              )}
            </p>
            <Button type="submit" color="blue" fluid size="tiny">
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>

      <Toolbar
        header={{
          breadcrumbTitle: 'breadcrumb-title',
          breadcrumbParent: `${groupName ? `${groupName}` : ''}`,
          breadcrumbParentLink: projectId
            ? `/projects/${projectId}/organizations/${organizationId}/groups/${groupId}`
            : `/organizations/${organizationId}/groups/${groupId}`,
          breadcrumbActive: `${firstName} ${lastName}`,
          rightComponent: isSuperAdmin(roles) && !loading && (
            <>
              <DropdownFilterYear
                setFilterYear={setYear}
                requestHandledBySelf
              />
              <Button
                primary
                content="Transactions"
                onClick={() =>
                  push(`${(location.search, pathname)}/transactions`)
                }
              />
              <Dropdown
                className="setting-dropdown"
                icon={false}
                floating
                labeled
                trigger={
                  <Button icon primary labelPosition="right">
                    <span>
                      <Icon name="setting" />
                    </span>
                    Settings
                    <Icon name="triangle down" />
                  </Button>
                }
              >
                <Dropdown.Menu className="right">
                  <Popup
                    content="Sorry, You can delete only a member with 0 Rwf saving Balance"
                    disabled={isActionApplicable('delete')}
                    trigger={
                      <Dropdown.Item
                        className={
                          !isActionApplicable('delete')
                            ? 'action-prevented'
                            : ''
                        }
                        onClick={() =>
                          isActionApplicable('delete') &&
                          setConfirmGroupModel({
                            action: 'deactivate',
                            header: 'Remove Member',
                            body: `Are you sure you want to remove ${capitalize(
                              `${firstName} ${lastName}`,
                            )} from SG ${capitalize(groupName)} ?`,
                            context: 'remove-member',
                            modelForm: true,
                          })
                        }
                      >
                        <Header as="h4" color="red">
                          Remove Member
                        </Header>
                      </Dropdown.Item>
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            </>
          ),
        }}
      />
      <Modal
        size="mini"
        className="group__lists__confirmation__modal"
        open={confirmGroupModel.model}
        onClose={() => onCloseConfirmModal()}
      >
        <Modal.Header>{confirmGroupModel.header}</Modal.Header>
        <Modal.Content>{confirmGroupModel.body}</Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onCloseConfirmModal()}>
            Cancel
          </Button>
          <Button
            negative
            content="Confirm"
            onClick={handleSumbitMemberActions}
          />
        </Modal.Actions>
      </Modal>
      <DeleteConfirmationModel
        title={confirmGroupModel.header}
        content={confirmGroupModel.body}
        open={confirmGroupModel.modelForm}
        onClose={() => onCloseConfirmModal()}
      >
        <Form
          className="delete-form"
          onSubmit={() => setPinModal(true)}
        >
          <TextArea
            required
            placeholder="Provide a reason for the deletion"
            className="delete-form__reason"
            name="comment"
            onChange={handleChange}
          />
          <Button
            floated="right"
            type="submit"
            content="Delete"
            color="red"
          />
        </Form>
      </DeleteConfirmationModel>
      {loading && <Loader />}
      {!loading && data && !error && (
        <div className="group-overview member-overview scroll-wrapper">
          {error ? (
            <Message negative>{error.message}</Message>
          ) : (
            <Grid>
              <Grid.Row columns="3">
                <Grid.Column width="8">
                  <MemberLineCharts data={data} year={year} />
                </Grid.Column>
                <Grid.Column width="5">
                  <MemberDetails data={data} year={year} />
                </Grid.Column>
                <Grid.Column width="3">
                  <MemberCycles data={data} year={year} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="1">
                <Grid.Column width="16">
                  <StatCharts data={data} year={year} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

GroupMember.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
};

export default GroupMember;
