import {
  CHANGE_GROUP_STATUS_START,
  CHANGE_GROUP_STATUS_SUCCESS,
  CHANGE_GROUP_STATUS_ERROR,
} from 'constants/action-types/organizations/changeGroupStatus';

export default (state, { type, payload }) => {
  switch (type) {
    case CHANGE_GROUP_STATUS_START:
      return {
        ...state,
        changeGroupStatus: {
          ...state.changeGroupStatus,
          loading: true,
        },
      };
    case CHANGE_GROUP_STATUS_ERROR:
      return {
        ...state,
        changeGroupStatus: {
          ...state.changeGroupStatus,
          error: payload,
          loading: false,
        },
      };

    case CHANGE_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        changeGroupStatus: {
          ...state.changeGroupStatus,
          message: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
