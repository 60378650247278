import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Message } from 'semantic-ui-react';

const ChangePinForm = ({
  form,
  handleChange,
  handleSubmit,
  loading,
  error,
  successMessage,
  message,
}) => {
  return (
    <div className="changepin__form">
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Form.Input
            value={message ? '' : form.oldPin}
            onChange={handleChange}
            name="oldPin"
            label="Old PIN"
            placeholder="Old PIN"
            type="password"
            error={error.oldPin !== ''}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={message ? '' : form.newPin}
            onChange={handleChange}
            name="newPin"
            label="New PIN"
            placeholder="New PIN"
            type="password"
            error={error.newPin !== ''}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            value={message ? '' : form.confirmPin}
            onChange={handleChange}
            name="confirmPin"
            label="Confirm PIN"
            placeholder="Confirm PIN"
            type="password"
            error={error.confirmPin !== ''}
          />
        </Form.Field>
        {error.confirmPin && (
          <Message negative>{error.confirmPin}</Message>
        )}
        {error.oldPin && <Message negative>{error.oldPin}</Message>}
        {successMessage && (
          <Message className="success__message" positive>
            {successMessage}
          </Message>
        )}
        <div className="changepin__submit">
          <Button type="submit" primary loading={loading}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

ChangePinForm.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.objectOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ChangePinForm;
