import {
  CREDIT_AGENT_START,
  CREDIT_AGENT_ERROR,
  CREDIT_AGENT_SUCCESS,
} from 'constants/action-types/wallets/creditAgent';

import apiAction from 'helpers/apiAction';

export const creditAgent = (data, agentId) => dispatch =>
  dispatch(
    apiAction({
      url: `/wallets/agents/${agentId}`,
      method: 'POST',
      data: {
        amount: data.amount,
        purpose: data.description,
      },
      API_REQUEST_START: CREDIT_AGENT_START,
      API_REQUEST_SUCCESS: CREDIT_AGENT_SUCCESS,
      API_REQUEST_FAILURE: CREDIT_AGENT_ERROR,
      onEnd: () => false,
    }),
  );

export const creditGroup = (data, groupId) => dispatch =>
  dispatch(
    apiAction({
      url: `/wallets/groups/${groupId}`,
      method: 'POST',
      data: {
        amount: data.amount,
        purpose: data.description,
        source: data.source,
      },
      API_REQUEST_START: CREDIT_AGENT_START,
      API_REQUEST_SUCCESS: CREDIT_AGENT_SUCCESS,
      API_REQUEST_FAILURE: CREDIT_AGENT_ERROR,
      onEnd: () => false,
    }),
  );
