import {
  GET_PARTNERS_FAILED,
  GET_PARTNERS_START,
  GET_PARTNERS_SUCCESS,
} from 'constants/action-types/projects/getPartners';

import apiAction from 'helpers/apiAction';

export default (organizationId, projectId, queries) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}/partners`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_PARTNERS_START,
      API_REQUEST_SUCCESS: GET_PARTNERS_SUCCESS,
      API_REQUEST_FAILURE: GET_PARTNERS_FAILED,
      onEnd: () => false,
    }),
  );
};
