import {
  REAPPROVE_MEMBER_REQUEST_ERROR,
  REAPPROVE_MEMBER_REQUEST_START,
  REAPPROVE_MEMBER_REQUEST_SUCCESS,
} from 'constants/action-types/groups/reApproveMemberRequest';

export default (state, { type, payload }) => {
  switch (type) {
    case REAPPROVE_MEMBER_REQUEST_START:
      return {
        ...state,
        groupActivities: {
          ...state.groupActivities,
          reApproveMemberRequest: {
            loading: true,
            error: null,
            message: null,
          },
        },
      };
    case REAPPROVE_MEMBER_REQUEST_ERROR:
      return {
        ...state,
        groupActivities: {
          ...state.groupActivities,
          reApproveMemberRequest: {
            ...state.groupActivities?.reApproveMemberRequest,
            error: payload,
            loading: false,
          },
        },
      };
    case REAPPROVE_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        groupActivities: {
          ...state.groupActivities,
          reApproveMemberRequest: {
            ...state.groupActivities?.reApproveMemberRequest,
            loading: false,
            message: payload,
          },
        },
      };
    default:
      return null;
  }
};
