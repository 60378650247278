import {
  SEARCH_AGENT_ERROR,
  SEARCH_AGENT_START,
  SEARCH_AGENT_SUCCESS,
} from 'constants/action-types/user/searchAgent';

import apiAction from 'helpers/apiAction';

export default (organizationId, query) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/agents?search=${query}`,
      method: 'GET',
      API_REQUEST_START: SEARCH_AGENT_START,
      API_REQUEST_SUCCESS: SEARCH_AGENT_SUCCESS,
      API_REQUEST_FAILURE: SEARCH_AGENT_ERROR,
      onEnd: () => false,
    }),
  );
