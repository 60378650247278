import {
  GET_ALL_BILLS_ERROR,
  GET_ALL_BILLS_START,
  GET_ALL_BILLS_SUCCESS,
} from 'constants/action-types/settings/getAllBills';
import apiAction from 'helpers/apiAction';

export default queries => dispatch =>
  dispatch(
    apiAction({
      url: '/admin/billing/transactions_charges_settings',
      method: 'GET',
      queries: {
        ...queries,
        order_option: 'DESC',
        order_by: 'updated_at',
      },
      API_REQUEST_START: GET_ALL_BILLS_START,
      API_REQUEST_SUCCESS: GET_ALL_BILLS_SUCCESS,
      API_REQUEST_FAILURE: GET_ALL_BILLS_ERROR,
      onEnd: () => false,
    }),
  );
