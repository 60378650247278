import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Header } from 'semantic-ui-react';
import Message from 'components/common/Message';
import isCycleExpired from 'helpers/isCycleExpired';

import listOrganizations from 'redux/actions/organizations/listOrganizations';
import updateGroup from 'redux/actions/organizations/updateGroup';
import getPartners from 'redux/actions/projects/getPartners';
import listProjects from 'redux/actions/projects/listProjects';
import {
  organizationsDropDownOptions,
  toDropDownOptions,
} from 'utils/dropdownOptions';

const AffiliationDetails = () => {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const handleChange = (_, data) => {
    setForm({ ...form, [data.name]: data.value });
  };

  const { group } = useSelector(
    ({ organizations: { singleGroup } }) => singleGroup?.item,
  );

  const organizations = useSelector(
    ({ organizations: { list } }) => list?.items,
  );

  const { projects, partners } = useSelector(
    ({ projects: { list, projectPartners } }) => ({
      projects: list?.items,
      partners: projectPartners?.items,
    }),
  );

  const handleSubmit = () => {
    updateGroup(group?.id, form)(dispatch);
  };

  const { loading, error } = useSelector(
    ({ organizations: { updateGroup } }) => updateGroup,
  );

  useEffect(() => {
    listProjects(group?.organization_id)(dispatch);
    listOrganizations()(dispatch);
    getPartners(group?.organization_id, group?.project_id)(dispatch);
  }, []);

  const isDisabled = () => {
    const cycleLength = group?.cycles?.length;
    return (
      group?.cycles[cycleLength - 1]?.status === 'active' ||
      group?.status === 'active' ||
      isCycleExpired(group?.cycle_end_date)
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      {!loading && error && (
        <Message message={error.message} color="red" />
      )}
      {isCycleExpired(group?.cycle_end_date) && (
        <Header as="h5" color="red">
          Update Cycle details First.
        </Header>
      )}
      <Form.Select
        fluid
        label="Organization"
        placeholder="Organization"
        name="organization_id"
        options={organizationsDropDownOptions(organizations)}
        defaultValue={group?.organization_id}
        disabled
      />
      <Form.Select
        fluid
        label="Project"
        placeholder="Project"
        name="project_id"
        options={organizationsDropDownOptions(projects)}
        defaultValue={group?.project_id}
        disabled
      />
      <Form.Select
        fluid
        label="Partner"
        placeholder="Partner"
        name="partner_id"
        options={organizationsDropDownOptions(partners)}
        defaultValue={group?.partner_id}
        disabled
      />
      <Form.Select
        fluid
        label="Group Type"
        placeholder="Group Type"
        options={toDropDownOptions(['supervised', 'graduated'])}
        onChange={handleChange}
        name="type"
        defaultValue={group?.type}
        disabled={isDisabled()}
      />
      <div className="action">
        <Button type="submit" primary loading={loading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default AffiliationDetails;
