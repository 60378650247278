import {
  GET_USER_ROLES_START,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
} from 'constants/action-types/user/getUserRoles';
import {
  HTTP_ACCESS_DENIED,
  HTTP_NOT_FOUND,
} from 'constants/httpStatusCodes';
import { VIEW_USER_DENIED } from 'constants/response-messages/user/getSingleUser';
import apiAction from 'helpers/apiAction';

export default userId => dispatch =>
  dispatch(
    apiAction({
      url: `admin/users/${userId}/roles`,
      API_REQUEST_START: GET_USER_ROLES_START,
      onSuccess: ({ data }) => {
        return dispatch({
          type: GET_USER_ROLES_SUCCESS,
          payload: data,
        });
      },
      onFailure: error => {
        if (
          error.status === HTTP_NOT_FOUND ||
          Number.isNaN(Number(userId))
        ) {
          return dispatch({
            type: GET_USER_ROLES_FAILURE,
            payload: `User with id "${userId}" does not exist`,
          });
        }
        if (error.status === HTTP_ACCESS_DENIED) {
          return dispatch({
            type: GET_USER_ROLES_FAILURE,
            payload: VIEW_USER_DENIED,
          });
        }
        return dispatch({
          type: GET_USER_ROLES_FAILURE,
          payload: error?.message || error,
        });
      },
    }),
  );
