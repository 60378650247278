/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import Message from 'components/common/Message';
import Thumbnail from 'components/common/Thumbnail';
import './index.scss';
import ExpandableRow from 'components/common/ExpandableRow';
import shapeFormatter from 'helpers/shapeFormatter/allSavingGroups';
import capitalize from 'utils/capitalize';

const GroupList = ({
  handleGetGroups,
  loading,
  error,
  message,
  groups,
}) => {
  const { push } = useHistory();

  return (
    <section className="group-list scroll-wrapper">
      {loading && <Loader active inline="centered" />}
      {!loading && error && (
        <Message
          message={error.message}
          color="red"
          action={{
            onClick: () => handleGetGroups(),
          }}
        />
      )}
      {!loading && message && (
        <Message
          message={message}
          icon="info circle"
          action={{
            onClick: () => handleGetGroups(),
          }}
        />
      )}
      {groups &&
        groups.map(group => (
          <div className="group-item" key={group.id}>
            <Thumbnail name={group.name} />

            <div className="details">
              <div className="info">
                <div
                  className="name"
                  tabIndex="-1"
                  onKeyDown={null}
                  role="button"
                  onClick={() =>
                    push(
                      `/organizations/${group.organization_id}/groups/${group.id}`,
                    )
                  }
                >
                  {capitalize(group.name)}
                </div>
                <ExpandableRow
                  data={shapeFormatter(group)}
                  redirectUrl={`/organizations/${group.organization_id}/groups/${group.id}`}
                />
              </div>
              <div className="right-info">
                <div className={`status ${group.status}`}>
                  {group.status}
                </div>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
};

GroupList.defaultProps = {
  message: null,
  meta: { page: 0, pages: 0 },
  error: false,
  groups: [],
};

GroupList.propTypes = {
  meta: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
  }),
  handleGetGroups: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string]),
  groups: PropTypes.array,
};

export default GroupList;
