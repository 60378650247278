/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Divider,
  Icon,
  Container,
  Button,
  Dropdown,
  Header,
  Loader as Spinner,
  Modal,
} from 'semantic-ui-react';

import './SingleProject.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toolbar from 'components/common/Toolbar';
import currencyFormat from 'utils/currencyFormat';
import Loader from 'components/common/PageLoader';
import Message from 'components/common/Message';
import Thumbnail from 'components/common/Thumbnail';
import LineChartWrapper from 'components/common/charts/LineChart/LineChartWrapper';
import StatCharts from 'components/Projects/SingleProject/StatCharts';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import TinyBarChart from 'components/common/charts/BarChart/TinyBarChart';
import todaysDate from 'utils/todaysDate';
import BulkCreate from 'containers/Organizations/SingleOrganization/Groups/BulkCreate';
import { isSA, isSuperAdmin } from 'helpers/checkRoles';
import {
  changeAllGroupStatus,
  changeAllGroupPilotStatus,
} from 'redux/actions/organizations/organizationGroups';
import getProject from 'redux/actions/projects/getProject';
import capitalize from 'utils/capitalize';
import formatNumber from 'helpers/formatNumber';
import toMonthStat from 'helpers/toMonthStat';
import ISOToDateString from 'helpers/ISOToDateString';
import DropdownFilterYear from 'components/common/DropdownFilterYear';
import filterOptions from 'constants/filterableYears';
import verifiedFilterYear from 'helpers/verifiedFilterYear';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';

const SingleProject = ({ option1 }) => {
  const dispatch = useDispatch();

  const [year, setYear] = useState(
    verifiedFilterYear(filterOptions, null),
  );

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } = {} }) =>
      details,
  );

  const { data, overview, loading, error } = useSelector(
    ({ projects: { currentProject } }) => currentProject,
  );

  const [upload, setUpload] = useState(false);
  const { push } = useHistory();
  const {
    organizationId,
    tabOption: projectId,
    projectId: ngoProjectId,
  } = useParams();

  const [confirmGroupModel, setConfirmGroupModel] = useState({
    action: '',
    header: '',
    body: '',
    context: '',
    model: false,
  });

  const { loading: changeGroupStatusLoading } = useSelector(
    ({ organizations: { changeGroupStatus } }) => changeGroupStatus,
  );

  const onCloseConfirmModal = () => {
    setConfirmGroupModel({
      ...confirmGroupModel,
      model: false,
    });
  };

  const handleSumbitGroupStatus = () => {
    onCloseConfirmModal();
    switch (confirmGroupModel.context) {
      case 'activate/deactivate-groups':
        changeAllGroupStatus(
          organizationId,
          projectId,
          confirmGroupModel.action,
        )(dispatch);
        break;
      case 'set/unset-pilot':
        changeAllGroupPilotStatus(
          organizationId,
          projectId,
          confirmGroupModel.action,
        )(dispatch);
        break;

      default:
        break;
    }
  };

  const getSingleProject = () => {
    getProject(
      organizationId,
      projectId || ngoProjectId,
      option1 === 'overview',
      year === 'all' ? {} : { year },
    )(dispatch);
  };

  useEffect(() => {
    getSingleProject();
  }, [dispatch, year]);

  return (
    <>
      {!loading && (
        <Toolbar
          header={{
            breadcrumbParent: 'Project',
            breadcrumbParentLink: `/organizations/${organizationId}/projects/`,
            breadcrumbActive: `${data.name || ''}`,
            rightComponent: (
              <div className="single_project_overview_header">
                <Spinner
                  active={changeGroupStatusLoading}
                  inline
                  size="small"
                />

                <DropdownFilterYear
                  setFilterYear={setYear}
                  requestHandledBySelf
                  value={year}
                />

                {isSA(roles) && (
                  <>
                    <Button
                      basic
                      primary
                      content="Upload SGs Data"
                      onClick={() => {
                        setUpload(!upload);
                      }}
                    />

                    <Button
                      primary
                      onClick={() =>
                        push(
                          `/organizations/${organizationId}/projects/${data.id}/invite-agent`,
                        )
                      }
                    >
                      Invite Agent
                    </Button>
                  </>
                )}
                {isSuperAdmin(roles) && (
                  <Dropdown
                    className="setting-dropdown"
                    icon={false}
                    floating
                    labeled
                    trigger={
                      <Button icon primary labelPosition="right">
                        <span>
                          <Icon name="setting" />
                        </span>
                        Settings
                        <Icon name="triangle down" />
                      </Button>
                    }
                  >
                    <Dropdown.Menu className="right">
                      <Dropdown.Item
                        onClick={() => {
                          setConfirmGroupModel({
                            action: 'activate',
                            header: 'Activate All Saving Groups',
                            body: `Are you sure you want to activate All groups under ${capitalize(
                              data.name,
                            )} ?`,
                            context: 'activate/deactivate-groups',
                            model: true,
                          });
                        }}
                      >
                        <Header as="h4">Activate All SGs</Header>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setConfirmGroupModel({
                            action: 'set',
                            header: 'Add Groups to Pilot',
                            body: `Are you sure you want to add All groups under ${capitalize(
                              data.name,
                            )} to pilot ? \n
                             During the pilot phase, Cash-Out fees are waived for members and are covered by SAVE.`,
                            context: 'set/unset-pilot',
                            model: true,
                          });
                        }}
                      >
                        <Header as="h4">Add To Pilot</Header>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setConfirmGroupModel({
                            action: 'deactivate',
                            header: 'Deactivate All Saving Groups',
                            body: `Are you sure you want to deactivate All groups under ${capitalize(
                              data.name,
                            )} ?`,
                            context: 'activate/deactivate-groups',
                            model: true,
                          });
                        }}
                      >
                        <Header as="h4" color="red">
                          Deactivate All SGs
                        </Header>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setConfirmGroupModel({
                            action: 'unset',
                            header: 'Remove Groups From Pilot',
                            body: `Are you sure you want to Remove All groups under ${capitalize(
                              data.name,
                            )} from pilot ?
                            Outside the pilot phase, Cash-Out fees are covered by members.`,
                            context: 'set/unset-pilot',
                            model: true,
                          });
                        }}
                      >
                        <Header as="h4" color="red">
                          Remove From Pilot
                        </Header>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <ToolbarFooter
                  url={`/organizations/${organizationId}/projects/${data.id}/stats/`}
                  fetchExportData
                  exportCSVFileName="project-overview"
                />
              </div>
            ),
          }}
        />
      )}
      <Modal
        size="mini"
        className="group__lists__confirmation__modal"
        open={confirmGroupModel.model}
        onClose={() => onCloseConfirmModal()}
      >
        <Modal.Header>{confirmGroupModel.header}</Modal.Header>
        <Modal.Content>{confirmGroupModel.body}</Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onCloseConfirmModal()}>
            Cancel
          </Button>
          <Button
            negative
            content="Confirm"
            onClick={handleSumbitGroupStatus}
          />
        </Modal.Actions>
      </Modal>
      <div className="SingleProject scroll-wrapper">
        {upload && <BulkCreate />}
        {loading && <Loader size="small" />}
        {!loading && error && (
          <Container>
            <Divider />
            <Message
              message={error.message}
              color="red"
              action={{
                onClick: () => getSingleProject(),
              }}
            />
          </Container>
        )}
        {loading || error ? null : (
          <div className="single-project-container">
            <Grid>
              {/* left */}
              <Grid.Column width={8}>
                <Grid>
                  {/* SAVING GROUPS */}
                  <Grid.Column width={8}>
                    <LineChartWrapper
                      title="SAVING GROUPS"
                      count={overview.groups_count}
                      dataKey="total"
                      data={
                        overview && Array.isArray(overview.groups)
                          ? toMonthStat(overview.groups)
                          : []
                      }
                      style={{ backgroundColor: greenColor }}
                      view={`${ngoProjectId ? 'ngo' : 'project'}`}
                      viewMoreRedirect
                      viewKey="groups"
                      date={todaysDate(year)}
                    />
                  </Grid.Column>
                  {/* AGENTS */}
                  <Grid.Column width={8}>
                    <LineChartWrapper
                      title="AGENTS"
                      count={overview.agents_count}
                      dataKey="total"
                      data={
                        overview && Array.isArray(overview.agents)
                          ? toMonthStat(overview.agents)
                          : []
                      }
                      style={{ backgroundColor: blueColor }}
                      view={`${ngoProjectId ? 'ngo' : 'project'}`}
                      viewMoreRedirect
                      viewKey="agents"
                      date={todaysDate(year)}
                    />
                  </Grid.Column>
                  {/* SAVINGS */}
                  <Grid.Column width={8}>
                    <LineChartWrapper
                      title="CURRENT SAVINGS"
                      count={overview.current_savings_total}
                      currency={data.currency}
                      dataKey="total"
                      data={
                        (overview && overview.current_savings) || []
                      }
                      style={{ backgroundColor: orangeColor }}
                      view={`${ngoProjectId ? 'ngo' : 'project'}`}
                      viewMoreRedirect
                      viewKey="groups"
                      date={todaysDate(year)}
                    />
                  </Grid.Column>
                  {/* OUTSTANDING LOANS */}
                  <Grid.Column width={8}>
                    <LineChartWrapper
                      title="OUTSTANDING LOANS"
                      count={overview.current_loans_total}
                      currency={data.currency}
                      data={
                        (overview && overview.current_loans) || []
                      }
                      style={{ backgroundColor: redColor }}
                      view={`${ngoProjectId ? 'ngo' : 'project'}`}
                      viewMoreRedirect
                      viewKey="groups"
                      date={todaysDate(year)}
                    />
                  </Grid.Column>

                  {/* SGs Distribution Per Agent */}
                  {/* <Grid.Column width={16}>
                  <PieChartWrapper
                    title="SGs Distribution Per Agent"
                    data={project.distribution_per_agent}
                  />
                </Grid.Column> */}
                </Grid>
              </Grid.Column>
              {/* right */}
              <Grid.Column width={8}>
                <Grid>
                  {/* project details */}
                  <Grid.Column width={10}>
                    <Card className="project-card">
                      <div>
                        <span className="project-icon">
                          <Thumbnail name={data.name} />
                        </span>
                        <span className="project-name">
                          {data.name}
                        </span>
                      </div>
                      <Divider />
                      <div>
                        <div className="left-text">
                          <p className="title">Start Date</p>
                          <span>
                            {moment(
                              ISOToDateString(data.start_date),
                            ).format('LL')}
                          </span>
                        </div>
                        <div className="right-text">
                          <p className="title">End Date</p>
                          <span>
                            {moment(
                              ISOToDateString(data.end_date),
                            ).format('LL')}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="left-text">
                          <p className="title">Budget</p>
                          <span>
                            {currencyFormat(
                              data.budget,
                              (data.currency || '').toUpperCase(),
                            )}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="left-text">
                          <p className="title">Partner</p>
                          <span>{overview.partners_count}</span>
                        </div>
                        <div className="right-text">
                          <p className="title">Donor</p>
                          <span>{data.donor}</span>
                        </div>
                      </div>
                      {data.piloted && (
                        <p className="pilot-status-badge">
                          In Pilot Phase
                        </p>
                      )}
                      <div className="footer">
                        <Divider />
                        <p>
                          Created on{' '}
                          {moment(
                            ISOToDateString(data.created_at),
                          ).format('LL')}
                        </p>
                      </div>
                    </Card>
                  </Grid.Column>
                  {/* members */}
                  <Grid.Column width={6}>
                    <Card className="Total__members">
                      <Card.Content>
                        <div className="Total__members__top">
                          <div className="Total__members__top__header">
                            <h1 className="Total__members__title">
                              Total Members
                            </h1>
                            <Card.Meta>{todaysDate(year)}</Card.Meta>
                          </div>
                          <div className="Total__members__top__icon">
                            <Icon name="female" size="big" />
                            <Icon name="male" size="big" />
                          </div>
                        </div>
                        <Card.Description className="Total__members__description">
                          {formatNumber(
                            overview.groups_members_count,
                          )}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card className="drop-outs-card">
                      <div className="drop-outs-card-header">
                        <div className="drop-outs-card-header-left">
                          <div className="drop-outs-card-header-title">
                            Female Members
                          </div>
                          <div className="drop-outs-card-header-date">
                            {todaysDate(year)}
                          </div>
                        </div>
                        <div className="drop-outs-card-header-right">
                          <span className="person-icon">
                            <Icon name="female" />
                          </span>
                        </div>
                      </div>
                      <div className="count">
                        {formatNumber(overview.females_count) || 0}
                      </div>
                      {overview && (
                        <TinyBarChart
                          data={overview.females}
                          dataKey="total"
                          height={90}
                        />
                      )}
                    </Card>
                    <Card className="members-card">
                      <div className="members-card-header">
                        <div className="members-card-header-left">
                          <div className="members-card-header-title">
                            Male Members
                          </div>
                          <div className="members-card-header-date">
                            {todaysDate(year)}
                          </div>
                        </div>
                        <div className="members-card-header-right">
                          <span className="person-icon">
                            <Icon name="male" />
                          </span>
                        </div>
                      </div>
                      <div className="count">
                        {formatNumber(overview.males_count) || 0}
                      </div>
                      {overview && (
                        <TinyBarChart
                          data={overview.males}
                          dataKey="total"
                          height={90}
                        />
                      )}
                    </Card>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>

            {overview && !loading && (
              <StatCharts overview={overview} year={year} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

SingleProject.defaultProps = {
  option1: 0,
};

SingleProject.propTypes = {
  option1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SingleProject;
