/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';
import moment from 'moment';
import activityTypes from 'constants/activityTypes';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import GroupTransactionList from 'components/Groups/TransactionsList';
import SidebarFilter from 'components/common/SidebarFilter';
import getGroupsTransaction from 'redux/actions/groups/getGroupsTransaction';
import Empty from 'components/common/Empty';
import TYPE_NAMES from 'constants/typeNames';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import updateTransactionStatus from 'redux/actions/groups/updateTransactionStatus';
import BulkUpload from './BulkUpload';

const GroupsTransactions = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const [filterOn, setFilterOn] = useState(false);
  const [filterForm, setFilterForm] = useState({
    dateFilter: moment([2017, 0, 1]).format('DD MMM YYYY'),
  });
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const {
    loading: transactionStatusUpdateLoading,
    error: transactionStatusUpdateError,
    data: { data },
  } = useSelector(
    ({ groups: { transactionStatusUpdate } }) =>
      transactionStatusUpdate,
  );

  useEffect(() => {
    if (search) {
      setFilterForm(params);
    } else {
      getGroupsTransaction()(dispatch);
    }
  }, []);

  const {
    data: transactions = [],
    meta = {},
    loading,
    error,
  } = useSelector(
    ({ groups: { groupTransactions } }) => groupTransactions,
  );

  const filteredTransactions = () => {
    const {
      type,
      status,
      category,
      purpose,
      provider_name,
      search,
      created_at,
    } = filterForm;
    getGroupsTransaction({
      type,
      status,
      category,
      purpose,
      provider_name,
      search,
      created_at,
    })(dispatch);
  };

  useEffect(() => {
    if (!isEmpty(filterForm)) filteredTransactions();
  }, [filterForm]);

  const defaultParams = {
    purpose: filterForm?.purpose,
    provider_name: filterForm?.provider_name,
    status: filterForm?.status,
    type: filterForm?.type,
    category: filterForm?.category,
    search: filterForm?.search,
  };

  const handleChange = (e, { name, value }) => {
    if (name === 'type') {
      push(
        formatSearchParameter({
          ...omit(defaultParams, 'type'),
          [name]: value,
        }),
      );
    }
    if (name === 'status') {
      push(
        formatSearchParameter({
          ...omit(defaultParams, 'status'),
          [name]: value,
        }),
      );
    }
    if (name === 'category') {
      push(
        formatSearchParameter({
          ...omit(defaultParams, 'category'),
          [name]: value,
        }),
      );
    }
    if (name === 'purpose') {
      push(
        formatSearchParameter({
          ...omit(defaultParams, 'purpose'),
          [name]: value,
        }),
      );
    }
    if (name === 'provider_name') {
      push(
        formatSearchParameter({
          ...omit(defaultParams, 'provider_name'),
          [name]: value,
        }),
      );
    }

    if (name === 'created_at') {
      setFilterForm({
        ...filterForm,
        dateFilter: moment(value).format('YYYY-MM-DD'),
      });
      push(
        formatSearchParameter({
          ...omit(defaultParams, 'created_at'),
          [name]: moment(value),
        }),
      );
    }

    setFilterForm({ ...filterForm, [name]: value });
  };

  const types = activityTypes('transactions');

  const onQuery = queries => {
    getGroupsTransaction({
      ...queries,
    })(dispatch);
  };

  const refreshTransactionStatus = id =>
    updateTransactionStatus(id)(dispatch);

  return (
    <>
      <Toolbar
        header={{
          title: 'All Transactions',
          rightComponent: (
            <ToolbarFooter
              onPaginate={onQuery}
              paginationMeta={meta}
              exportCSVData={transactions}
              exportCSVFileName="Groups"
              filterClicked={() => {
                setFilterOn(true);
              }}
              uploadClicked={() => {
                setOpenBulkUpload(true);
              }}
              filter
              upload
              onSearch={onQuery}
              hasSearchBy
            />
          ),
        }}
      />
      <BulkUpload open={openBulkUpload} setOpen={setOpenBulkUpload} />
      {isEmpty(transactions) && !loading ? (
        <Empty message="No Transactions" />
      ) : (
        <GroupTransactionList
          loading={loading}
          transactionStatusUpdateLoading={
            transactionStatusUpdateLoading
          }
          transactionData={data}
          transactionStatusUpdateError={transactionStatusUpdateError}
          error={error}
          groupTransactions={transactions}
          refreshTransactionStatus={refreshTransactionStatus}
        />
      )}
      <SidebarFilter
        {...{
          filterOn,
          setFilterOn,
          form: filterForm,
          setForm: setFilterForm,
          filterTransactions: filteredTransactions,
          handleChange,
          types,
          typeName: TYPE_NAMES.TRANSACTION_PURPOSE,
          searchName: 'Group name',
          activity: 'groupTransactions',
          resourceId: 6,
        }}
      />
    </>
  );
};
GroupsTransactions.defaultProps = {
  filter: '',
};

GroupsTransactions.propTypes = {
  filter: PropTypes.string,
};

export default GroupsTransactions;
