import {
  GET_ALL_ADVERTISEMENTS_ERROR,
  GET_ALL_ADVERTISEMENTS_START,
  GET_ALL_ADVERTISEMENTS_SUCCESS,
} from 'constants/action-types/communication/getAllAdvertisment';
import notificationHelper from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default data => dispatch => {
  return dispatch(
    apiAction({
      url: '/advertisements',
      method: 'GET',
      data,
      API_REQUEST_START: GET_ALL_ADVERTISEMENTS_START,
      API_REQUEST_SUCCESS: GET_ALL_ADVERTISEMENTS_SUCCESS,
      API_REQUEST_FAILURE: GET_ALL_ADVERTISEMENTS_ERROR,

      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};
