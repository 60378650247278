import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Form, Dropdown } from 'semantic-ui-react';
import {
  typeOptions,
  categories,
  defaultValues,
} from 'constants/categories/organization';
import * as checkRoles from '../../../../helpers/checkRoles';
import { isUpdating } from '../../../../helpers/organization';

const TypeCategory = ({
  handleChange,
  categoryError,
  typeError,
  form,
  required,
  setTypeCategories,
}) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const { roles } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );
  const currentUserRole = ['isSA', 'isSSA'].find(role =>
    checkRoles[role](roles),
  );

  let { type, category } = defaultValues(currentUserRole);

  const organization = useSelector(
    ({ organizations: { currentOrganization } }) =>
      currentOrganization,
  );
  const { pathname } = useLocation();
  if (isUpdating(pathname)) {
    type = organization.type;
    category = organization.category;
  }

  const handleTypeChange = (e, { name, value }) => {
    if (
      Object.keys(categories).some((category, index) => index > 2)
    ) {
      setCategoryOptions(null);
    } else {
      setCategoryOptions(categories[value]);
    }

    handleChange(e, { name, value });
  };

  if (categoryOptions.length === 0) {
    if (checkRoles.isSSA(roles)) {
      setTypeCategories({ type, category });
      return setCategoryOptions(categories['Business ORG']);
    }
    if (checkRoles.isSA(roles)) {
      setTypeCategories({ type });
      return setCategoryOptions(categories.NGO);
    }
  }

  return (
    <>
      {!!type && (
        <Form.Field
          search
          selection
          control={Dropdown}
          disabled
          type="text"
          label="Type"
          name="type"
          error={typeError}
          placeholder={form.type || 'Type'}
          options={typeOptions}
          onChange={handleTypeChange}
          value={type}
        />
      )}

      {!!category && categoryOptions ? (
        <Form.Field
          required={required}
          search
          selection
          control={Dropdown}
          error={categoryError}
          label="Category"
          disabled={isUpdating(pathname) || checkRoles.isSSA(roles)}
          name="category"
          placeholder={form.category || 'Category'}
          options={categoryOptions}
          onChange={handleChange}
          defaultValue={checkRoles.isSSA(roles) ? category : null}
        />
      ) : (
        !!category && (
          <Form.Field
            required={required}
            control={Input}
            error={categoryError}
            label="Category"
            name="category"
            placeholder="Category"
            onChange={handleChange}
            defaultValue={category || null}
          />
        )
      )}
    </>
  );
};

TypeCategory.defaultProps = {
  form: {},
  required: true,
};

TypeCategory.propTypes = {
  handleChange: PropTypes.func.isRequired,
  categoryError: PropTypes.string.isRequired,
  typeError: PropTypes.string.isRequired,
  form: PropTypes.objectOf(Object),
  required: PropTypes.bool,
  setTypeCategories: PropTypes.func.isRequired,
};

export default TypeCategory;
