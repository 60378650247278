import {
  ACTIVATE_ADVERTISEMENT_ERROR,
  ACTIVATE_ADVERTISEMENT_START,
  ACTIVATE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/activateAdvertisement';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import getAllAdvertisement from './getAllAdvertisement';

export default id => dispatch => {
  dispatch(
    apiAction({
      url: `advertisements/${id}/activate`,
      method: 'PUT',
      API_REQUEST_START: ACTIVATE_ADVERTISEMENT_START,
      API_REQUEST_SUCCESS: ACTIVATE_ADVERTISEMENT_SUCCESS,
      API_REQUEST_FAILURE: ACTIVATE_ADVERTISEMENT_ERROR,
      callbackOnSuccess: ({ message }) => {
        notificationHelper({ message });
        getAllAdvertisement()(dispatch);
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};
