import {
  UPDATE_USER_PHONE_ERROR,
  UPDATE_USER_PHONE_START,
  UPDATE_USER_PHONE_SUCCESS,
} from 'constants/action-types/user/updateUserPhone';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default (id, data) => (dispatch, setOpenModal) =>
  dispatch(
    apiAction({
      url: `/users/${id}/change_phone_number`,
      method: 'PUT',
      data,
      API_REQUEST_START: UPDATE_USER_PHONE_START,
      API_REQUEST_SUCCESS: UPDATE_USER_PHONE_SUCCESS,
      API_REQUEST_FAILURE: UPDATE_USER_PHONE_ERROR,
      onSuccess: data => {
        dispatch({
          type: UPDATE_USER_PHONE_SUCCESS,
          data,
        });
        setOpenModal(false);
        notification({
          message: 'Phone number updated successfully',
        });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: UPDATE_USER_PHONE_ERROR,
          payload: message,
        });
      },
      onEnd: () => false,
    }),
  );
