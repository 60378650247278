import {
  GET_PROFILE_OVERVIEW_ERROR,
  GET_PROFILE_OVERVIEW_START,
  GET_PROFILE_OVERVIEW_SUCCESS,
} from 'constants/action-types/organizations/profiles/getProfileOverview';

import apiAction from 'helpers/apiAction';

const getProfileOverview = (
  organizationId,
  profileId,
  queries,
) => dispatch => {
  const url = `/organizations/${organizationId}/profiles/${profileId}/overview`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_PROFILE_OVERVIEW_START,
      API_REQUEST_SUCCESS: GET_PROFILE_OVERVIEW_SUCCESS,
      API_REQUEST_FAILURE: GET_PROFILE_OVERVIEW_ERROR,
      onEnd: () => false,
    }),
  );
};

export default getProfileOverview;
