import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'helpers/setupGoogleAnalytics';
import 'utils/translations';
import App from './App';
import store from './redux/store';
import './sentry';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
