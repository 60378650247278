import { months } from 'moment';
import getMonthName from './getMonthName';

const toMonth = date => getMonthName(new Date(date).getMonth());

export const cleanCumulative = array => {
  if (array instanceof Array) {
    const data = [];

    months().map(month => {
      const itemIndex = array.findIndex(item =>
        month
          .toLowerCase()
          .includes((item.month || '').toLowerCase()),
      );
      if (itemIndex > -1) {
        const item = array[itemIndex];
        const name = item.month ? item.month.slice(0, 3) : item.month;
        const month = item.month
          ? item.month.slice(0, 3)
          : item.month;

        data.push({
          ...item,
          name,
          month,
          uv: parseInt(item.count, 10),
          count: parseInt(item.count, 10),
        });
      } else {
        data.push({
          month,
          uv: 0,
          count: 0,
        });
      }

      return null;
    });
    return data;
  }

  return array;
};

export default array => {
  if (array instanceof Array) {
    const data = [];

    months().map(month => {
      if (!data.some(item => item.month === month)) {
        data[data.length] = {
          name: month,
          uv: 0,
        };
      }

      const currentIndex = data.findIndex(
        item => item.month === month,
      );
      array.map(item => {
        if (item && toMonth(item.created_at) === month) {
          if (data[currentIndex]) {
            data[currentIndex].uv += 1;
          }
        }
        return null;
      });
      return null;
    });
    return data;
  }

  return array;
};
