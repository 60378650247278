import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import './index.scss';
import { approveTopupRequests } from 'redux/actions/wallets/approvalOrgRequests';
import currencyFormat from 'utils/currencyFormat';
import notify from 'helpers/notificationHelper';
import { isFinanceRwandaAdmin } from 'helpers/checkRoles';
import { ERROR } from 'constants/notificationStatus';

const Request = ({
  originator,
  recipient,
  amount,
  comment,
  date,
  initiator,
  transactionId: requestId,
  organizationId,
  org_wallet_id,
  roles,
  attachment,
  setShowReceipt,
  description,
}) => {
  const formatedDate = moment(date).format('DD-MM-YYYY');

  const dispatch = useDispatch();

  const handleSubmit = action => {
    approveTopupRequests(
      organizationId,
      org_wallet_id,
      requestId,
      action,
      notify,
      setShowReceipt,
    )(dispatch);
  };

  const { error } = useSelector(
    ({ wallets: { approveOrgRequest } }) => approveOrgRequest,
  );

  useEffect(() => {
    if (error) {
      if (!error.errors) {
        notify({ message: error.message, status: ERROR });
      }
      if (error.errors) {
        notify({
          message: error.errors[0].message,
          status: ERROR,
        });
      }
    }
  }, [error]);

  return (
    <>
      <div className="request" id="org-request">
        <div className="request__left">
          <div className="request__left__item">
            Originator/Creditor: {originator}{' '}
          </div>
          <div className="request__left__item">
            Recipient: {recipient?.name}
          </div>
          <div className="request__left__item">
            Amount: {currencyFormat(amount)}{' '}
          </div>
          <div className="request__left__item">
            Description: {comment || description}
          </div>
          <div className="request__left__item">
            Requested Date: {formatedDate}{' '}
          </div>
          <div className="request__left__item">
            Initiated By:{' '}
            {initiator
              ? `${initiator.first_name} ${initiator.last_name}`
              : `${recipient}`}
          </div>
          {isFinanceRwandaAdmin(roles) && (
            <div className="request__left--btns">
              <Button
                color="blue"
                onClick={() => handleSubmit('accept')}
              >
                Approve
              </Button>
              <Button
                color="red"
                onClick={() => handleSubmit('reject')}
              >
                Decline
              </Button>
            </div>
          )}
        </div>
        <div className="request__right">
          <div className="request__right__title">
            Transaction Slip
          </div>
          <img
            className="request__right__image"
            src={attachment}
            alt="attachment"
          />
          <div className="request__right--items">
            <div>
              <Icon name="expand" /> View full size
            </div>
            <div>
              <Icon name="download" /> Download File
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Request.propTypes = {
  originator: PropTypes.string,
  recipient: PropTypes.string,
  amount: PropTypes.number,
  date: PropTypes.string,
  initiator: PropTypes.string,
  transactionId: PropTypes.number,
  organizationId: PropTypes.number,
  org_wallet_id: PropTypes.number,
  roles: PropTypes.instanceOf(Array),
  attachment: PropTypes.string,
  setShowReceipt: PropTypes.func.isRequired,
  comment: PropTypes.string,
  description: PropTypes.string,
};

Request.defaultProps = {
  originator: '',
  recipient: '',
  amount: '',
  date: '',
  initiator: '',
  transactionId: '',
  organizationId: '',
  org_wallet_id: '',
  roles: [],
  attachment: '',
  comment: '',
  description: '',
};

export default Request;
