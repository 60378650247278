import React, { useEffect, useState } from 'react';
import { Form, Button, Message, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';
import { isEmpty, startCase } from 'lodash';
import PINInput from 'components/common/PINInput';
import { approveOrgGroupRequests } from 'redux/actions/wallets/approvalOrgRequests';
import getCurrentOrganization from 'helpers/getCurrentOrg';
import './index.scss';
import notify from 'helpers/notificationHelper';
import { walletDropDownOptions } from 'utils/dropdownOptions';
import isOrgRequestFormValid from 'helpers/validators/validateOrgRequestForm';
import { compactObject } from 'helpers/removeEmptyProperties';
import InputNumberFormat from '../../InputNumberFormat';

const AmendRequestForm = ({
  organizationId,
  requestMeta,
  loadingGroupRequest,
  action,
  setAction,
  errorOrgGroupRequest,
  request,
  groupId,
  context,
}) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({});
  const [walletBalance, setWalletBalance] = useState(0);
  const [formError, setFormError] = useState({});

  const currentOrganization = getCurrentOrganization();

  const setError = (context, values) => {
    let errorMessage = '';
    switch (context) {
      case 'amount':
        errorMessage = Object.keys(values).reduce(
          (accumulator, key) => {
            return accumulator.replace(`${key}`, values[key]);
          },
          `amount is greater than wallet balance`,
        );
        break;
      case 'comment':
        errorMessage =
          'Description must be between 10 and 120 characters';
        break;

      default:
        break;
    }
    setFormError({ ...formError, [context]: errorMessage });
  };

  const resetError = context =>
    setFormError({ ...formError, [context]: null });

  const get_loans_interest = () => {
    if (form.amount && form.repayment_days)
      return Number(
        form.amount *
          ((form.repayment_days / 365) *
            (currentOrganization?.loans_interest_rate / 100)),
      ).toFixed(2);
    return null;
  };

  const get_installment_amount = () => {
    if (form.amount && form.repayment_installments)
      return Number(
        (form.amount +
          form.amount *
            ((form.repayment_days / 365) *
              (currentOrganization?.loans_interest_rate / 100))) /
          form.repayment_installments,
      ).toFixed(2);

    return null;
  };

  useEffect(() => {
    setForm({
      ...form,
      loans_interest: get_loans_interest(),
      installment_amount: get_installment_amount(),
    });
  }, [form.amount, form.repayment_days, form.repayment_installments]);

  const handleChange = (_, { name, value }) => {
    if (name === 'provider_name') {
      const selectedWallet = requestMeta?.wallets?.find(
        wallet => wallet.provider_name === value,
      );

      setWalletBalance(selectedWallet.float_balance);

      if (form.amount > selectedWallet.float_balance)
        setError('amount', {
          amount: numeral(form.amount).format('0.00'),
          wallet: startCase(value),
        });
      else resetError('amount');
    }

    if (name === 'amount') {
      if (walletBalance && value > walletBalance)
        setError('amount', {
          amount: numeral(value).format('0.00'),
          wallet: startCase(form.provider_name),
        });
      else resetError('amount');
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = ({ currentTarget: { name, value } }) => {
    if (name === 'comment') {
      if (value.length < 10 || value.length > 120)
        return setError('comment');
      return resetError('comment');
    }
  };

  const handleApproval = () => {
    approveOrgGroupRequests({
      organizationId,
      requestId: request.id,
      action,
      data: form,
      groupId,
      context,
    })(dispatch)(setAction, notify);
  };

  return (
    <Form
      onSubmit={handleApproval}
      loading={loadingGroupRequest}
      className="org-request-approval-form"
    >
      <InputNumberFormat
        required
        name="amount"
        control={Input}
        label="Loan Amount (rwf)"
        type="number"
        placeholder="Amount..."
        onChange={handleChange}
      />

      <Form.Field
        required
        name="repayment_days"
        control={Input}
        label="Repayment Days"
        type="number"
        placeholder="Repayment Days..."
        onChange={handleChange}
      />

      <Form.Field
        required
        name="repayment_installments"
        control={Input}
        label="Repayment Installments"
        type="number"
        placeholder="Repayment Installments..."
        onChange={handleChange}
      />
      {form.amount && form.repayment_days ? (
        <div className="org-requests-amend-inputs">
          <h4>Interest On Loan</h4>{' '}
          {numeral(form.loans_interest).format('0.00')} Rwf
        </div>
      ) : null}
      {form.amount &&
      form.repayment_days &&
      form.repayment_installments ? (
        <div className="org-requests-amend-inputs">
          <h4>Payment/Installment</h4>{' '}
          {numeral(form.installment_amount).format('0.00')} Rwf
        </div>
      ) : null}

      <Form.TextArea
        required
        placeholder="Provide a reason for amending the loan request..."
        className="textarea"
        name="comment"
        value={form.comment}
        onChange={handleChange}
        label="Reason for Loan Amendment"
        min="10"
        max="120"
        error={formError.comment}
        onBlur={handleBlur}
      />

      <Form.Select
        required
        name="provider_name"
        fluid
        label="Select Channel"
        options={walletDropDownOptions(requestMeta?.wallets)}
        placeholder="Channel"
        onChange={handleChange}
        error={formError.amount}
      />

      <PINInput
        label="PIN"
        required
        fluid
        placeholder="PIN"
        name="password"
        className="confirm-transaction-input"
        value={form.password}
        onChange={handleChange}
      />

      {errorOrgGroupRequest && (
        <p color="red">
          <Message negative>{errorOrgGroupRequest?.message}</Message>
        </p>
      )}
      <Button
        type="submit"
        content={
          action === 'amend'
            ? 'Save & notify group committee'
            : 'Submit'
        }
        color="blue"
        fluid
        disabled={
          !isOrgRequestFormValid(action, form) ||
          !isEmpty(compactObject(formError))
        }
      />
    </Form>
  );
};

AmendRequestForm.propTypes = {
  organizationId: PropTypes.number,
  loadingGroupRequest: PropTypes.bool,
  action: PropTypes.string,
  setAction: PropTypes.func,
  request: PropTypes.object,
  context: PropTypes.string,
  requestMeta: PropTypes.instanceOf(Array),
  errorOrgGroupRequest: PropTypes.instanceOf(Object),
  groupId: PropTypes.number,
};

AmendRequestForm.defaultProps = {
  setAction: () => null,
  loadingGroupRequest: false,
  action: '',
  request: {},
  organizationId: '',
  requestMeta: [],
  context: '',
  errorOrgGroupRequest: {},
  groupId: 0,
};

export default AmendRequestForm;
