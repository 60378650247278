import {
  UPDATE_USER_PHONE_START,
  UPDATE_USER_PHONE_ERROR,
  UPDATE_USER_PHONE_SUCCESS,
  UPDATE_USER_PHONE_END,
} from 'constants/action-types/user/updateUserPhone';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_PHONE_START:
      return {
        ...state,
        updateUserPhone: {
          loading: true,
          error: null,
          message: null,
        },
      };
    case UPDATE_USER_PHONE_ERROR:
      return {
        ...state,
        updateUserPhone: {
          ...state.updateUserPhone,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_USER_PHONE_SUCCESS:
      return {
        ...state,
        updateUserPhone: {
          ...state.updateUserPhone,
          message: payload,
          loading: false,
        },
      };
    case UPDATE_USER_PHONE_END:
      return {
        ...state,
        updateUserPhone: {
          ...state.updateUserPhone,
          message: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
