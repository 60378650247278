/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Message, Radio } from 'semantic-ui-react';
import { isArray } from 'lodash';
import Toolbar from 'components/common/Toolbar';
import { toDropDownOptions } from 'utils/dropdownOptions';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import validateFields from 'helpers/validators/validateFields';
import isFieldRequired from 'helpers/validators/isFieldRequired';
import {
  commissionNameTypes,
  commissionRequiredFields,
  frequencyTypes,
  groupTypes,
  groupsPurpose,
  providerTypes,
} from 'constants/commissionTypes';
import updateCommissions from 'redux/actions/settings/updateCommissions';
import getCommissions from 'redux/actions/settings/getCommissions';
import { weekDaysPicker } from 'constants/timePicker';
import capitalize from 'utils/capitalize';

const EditCommission = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [form, setForm] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [displayFieldError, setFieldError] = useState(false);
  const [userPin, setUserPin] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [isMultiSelect, setIsMultiSelect] = useState(true);

  const { data: commissions } = useSelector(
    ({ settings: { commissions } }) => commissions,
  );
  const { loading, error } = useSelector(
    ({ settings: { updateCommission } }) => updateCommission,
  );

  const {
    organizationsList: { items: organizationsItems = [] },
  } = useSelector(
    ({ organizations: { list: organizationsList } }) => ({
      organizationsList,
    }),
  );

  useEffect(() => {
    if (Object.keys(commissions).length === 0) {
      getCommissions()(dispatch);
    }
  }, []);

  const handleChange = (e, { name, value, type }) => {
    if (type === 'number') value = Number(value);

    if (name === commissionNameTypes.eligible_ngos) {
      if (isArray(value) && value.includes('all')) {
        setIsMultiSelect(false);
        return setForm({ ...form, [name]: value });
      }
      if (!isArray(value) && value !== 'all') {
        setIsMultiSelect(true);
        return setForm({ ...form, [name]: [value] });
      }
    }
    setForm({ ...form, [name]: value });
  };

  const checkFields = () => {
    if (
      validateFields(commissionRequiredFields, {
        ...commissions,
        ...form,
      })
    ) {
      setFieldError(false);
      return setOpenModal(true);
    }
    return setFieldError(true);
  };

  const handleSubmit = () => {
    let effectiveStatus = status ? 'active' : 'inactive';

    if (status === undefined) {
      effectiveStatus = commissions.status;
    }

    const eligible_ngos = form?.eligible_ngos
      ? form?.eligible_ngos
      : commissions?.eligible_ngos?.map(ngo => ngo?.id);

    updateCommissions({
      ...commissions,
      ...form,
      eligible_ngos:
        eligible_ngos === 'all'
          ? organizationsItems.map(({ id }) => id)
          : eligible_ngos,
      status: effectiveStatus,
      password: userPin,
    })(dispatch, () => {
      push('/settings/commission');
      setOpenModal(false);
    });
  };

  const timeOption = useMemo(() => {
    const hours = new Array(24).fill(0).map((_, idx) => idx);
    const minutes = new Array(4).fill(0).map((_, idx) => idx * 15);

    return hours.map(hour =>
      minutes
        .map(
          minute =>
            `${`0${hour}`.slice(-2)}:${`0${minute}`.slice(-2)}`,
        )
        .map(value => {
          return {
            key: value,
            text: value,
            value,
          };
        }),
    );
  }, []);

  return (
    <div className="billings__edit">
      <Toolbar
        header={{
          title: 'Edit agent’s commission',
          navigation: true,
        }}
      />
      {Object.keys(commissions)?.length ? (
        <Form loading={loading}>
          <Form.Dropdown
            fluid
            selection
            multiple
            label="Eligible group type"
            name={commissionNameTypes.group_type}
            defaultValue={commissions[commissionNameTypes.group_type]}
            placeholder="-- Select --"
            options={toDropDownOptions(groupTypes)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.group_type,
              'Eligible group type',
            )}
          />
          <Form.Dropdown
            fluid
            selection
            multiple
            label="Eligible group purpose"
            name={commissionNameTypes.group_purpose}
            defaultValue={
              commissions[commissionNameTypes.group_purpose]
            }
            placeholder="-- Select --"
            options={toDropDownOptions(groupsPurpose)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.group_purpose,
              'Eligible group purpose',
            )}
          />
          <Form.Dropdown
            fluid
            selection
            label="Cash-Out medium"
            name={commissionNameTypes.cashout_medium}
            defaultValue={
              commissions[commissionNameTypes.cashout_medium]
            }
            placeholder="-- Select --"
            options={toDropDownOptions(providerTypes)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.cashout_medium,
              'Cash-Out medium',
            )}
          />
          <Form.Input
            fluid
            type="number"
            label="Commission per active user (Rwf)"
            name={commissionNameTypes.commission_per_active_user}
            defaultValue={
              commissions[
                commissionNameTypes.commission_per_active_user
              ]
            }
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.commission_per_active_user,
              'Commission per active user',
            )}
          />
          <Form.Input
            fluid
            type="number"
            label="Minimum transactions for daily saving frequency for an active user"
            name={commissionNameTypes.min_trx_daily_savings}
            defaultValue={
              commissions[commissionNameTypes.min_trx_daily_savings]
            }
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.min_trx_daily_savings,
              'Minimum transactions for daily saving frequency',
            )}
          />
          <Form.Input
            fluid
            type="number"
            label="Minimum transactions for weekly saving frequency for an active user"
            name={commissionNameTypes.min_trx_weekly_savings}
            defaultValue={
              commissions[commissionNameTypes.min_trx_weekly_savings]
            }
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.min_trx_weekly_savings,
              'Minimum transactions for weekly saving frequency',
            )}
          />
          <Form.Input
            fluid
            type="number"
            label="Minimum transactions for monthly saving frequency for an active user"
            name={commissionNameTypes.min_trx_monthly_savings}
            defaultValue={
              commissions[commissionNameTypes.min_trx_monthly_savings]
            }
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.min_trx_monthly_savings,
              'Minimum transactions for monthly saving frequency',
            )}
          />
          <Form.Group>
            <Form.Dropdown
              fluid
              selection
              label="Cash-Out Frequency"
              name={commissionNameTypes.cashout_frequency}
              defaultValue={
                commissions[commissionNameTypes.cashout_frequency]
              }
              placeholder="-- Select --"
              options={toDropDownOptions(frequencyTypes)}
              onChange={handleChange}
              error={isFieldRequired(
                displayFieldError,
                !form?.cashout_frequency,
                'Cash-Out Frequency',
              )}
            />
            {(form?.cashout_frequency ??
              commissions?.cashout_frequency) === 'daily' || (
              <Form.Dropdown
                fluid
                selection
                search
                label="Date"
                name={commissionNameTypes.cashout_frequency_date}
                defaultValue={
                  commissions[
                    commissionNameTypes.cashout_frequency_date
                  ]
                }
                placeholder="Select"
                options={
                  (form?.cashout_frequency ??
                    commissions?.cashout_frequency) === 'weekly'
                    ? weekDaysPicker.map((item, idx) => ({
                        key: item,
                        text: capitalize(item),
                        value: idx + 1,
                      }))
                    : toDropDownOptions([
                        ...Array(30)
                          .fill(1)
                          .map((_, idx) =>
                            `0${String(idx + 1)}`.slice(-2),
                          ),
                      ])
                }
                onChange={handleChange}
                error={isFieldRequired(
                  displayFieldError &&
                    commissionRequiredFields.includes(
                      commissionNameTypes.cashout_frequency_date,
                    ),
                  !form?.cashout_frequency_date,
                  'Date',
                )}
              />
            )}
            <Form.Dropdown
              fluid
              selection
              search
              label="Time"
              name={commissionNameTypes.cashout_frequency_time}
              defaultValue={
                commissions[
                  commissionNameTypes.cashout_frequency_time
                ]
              }
              placeholder="Select"
              options={timeOption.flat()}
              onChange={handleChange}
              error={isFieldRequired(
                displayFieldError &&
                  commissionRequiredFields.includes(
                    commissionNameTypes.cashout_frequency_time,
                  ),
                !form?.cashout_frequency_time,
                'Time',
              )}
            />
          </Form.Group>
          <Form.Input
            fluid
            type="number"
            label="Initial Cash-out amount (Rwf)"
            name={commissionNameTypes.initial_cashout_amount}
            defaultValue={
              commissions[commissionNameTypes.initial_cashout_amount]
            }
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.initial_cashout_amount,
              'Initial Cash-out amount',
            )}
          />
          <Form.Input
            fluid
            type="number"
            label="Future Cash-out amount (Rwf)"
            name={commissionNameTypes.future_cashout_amount}
            defaultValue={
              commissions[commissionNameTypes.future_cashout_amount]
            }
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.future_cashout_amount,
              'Future Cash-out amount',
            )}
          />
          <Form.Dropdown
            fluid
            selection
            multiple={isMultiSelect}
            search
            label="Eligible NGOs"
            name={commissionNameTypes.eligible_ngos}
            value={
              isMultiSelect
                ? form[commissionNameTypes.eligible_ngos]
                : undefined
            }
            defaultValue={commissions[
              commissionNameTypes.eligible_ngos
            ]?.map(ngo => ngo?.id)}
            placeholder="-- Select --"
            options={[
              {
                text: 'All',
                key: 'all',
                value: 'all',
              },
              ...organizationsItems.map(({ name, id }) => ({
                text: name,
                key: name,
                value: id,
              })),
            ]}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.eligible_ngos,
              'Eligible NGOs',
            )}
          />

          <Form.TextArea
            fluid
            label="Description"
            placeholder="Description"
            name={commissionNameTypes.description}
            defaultValue={commissions?.description}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.description,
              'Description',
            )}
          />

          <Form.Field>
            <label>Status</label>
            <Radio
              toggle
              name={commissionNameTypes.status}
              value={status}
              defaultChecked={commissions?.status === 'active'}
              onChange={(e, { checked }) => setStatus(checked)}
            />
          </Form.Field>

          <Form.Button
            primary
            name="addButton"
            floated="right"
            content="Save"
            onClick={checkFields}
          />
        </Form>
      ) : (
        <Message negative>Could not load commission data</Message>
      )}
      <ConfirmPinModal
        content="Do you want to confirm the edits made to the commissions?"
        openModal={openModal}
        setOpenModal={setOpenModal}
        userPin={userPin}
        setUserPin={setUserPin}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
        loading={loading}
        error={{ message: error }}
      />
    </div>
  );
};

export default EditCommission;
