import {
  CREATE_REFERRAL_ALIAS_START,
  CREATE_REFERRAL_ALIAS_SUCCESS,
  CREATE_REFERRAL_ALIAS_ERROR,
} from 'constants/action-types/user/createReferralAlias';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';

export default (id, data) => (dispatch, callBack) =>
  dispatch(
    apiAction({
      url: `/users/${id}/referrals`,
      method: 'PUT',
      data,
      API_REQUEST_START: CREATE_REFERRAL_ALIAS_START,
      API_REQUEST_SUCCESS: CREATE_REFERRAL_ALIAS_SUCCESS,
      API_REQUEST_FAILURE: CREATE_REFERRAL_ALIAS_ERROR,

      callbackOnSuccess: ({ message }) => {
        notificationHelper({ message });
        callBack?.();
      },
      callbackOnFailure: ({ message }) => {
        if (message !== 'Wrong PIN.') {
          notificationHelper({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
