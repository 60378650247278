import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import './index.scss';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { toDropDownOptions } from 'utils/dropdownOptions';
import filterOptions from 'constants/filterableYears';
import formatUrlParameter from 'helpers/formatUrlParameter';
import verifiedFilterYear from 'helpers/verifiedFilterYear';

const DropdownFilterYear = ({
  handleChange,
  params = {},
  setFilterYear,
  requestHandledBySelf,
  triggerChange,
}) => {
  const { push } = useHistory();
  const { search, pathname } = useLocation();

  const [year, setYear] = useState(
    verifiedFilterYear(filterOptions, search),
  );

  useEffect(() => {
    push(formatUrlParameter(pathname, { year }));
    if (!requestHandledBySelf)
      handleChange(params, year === 'all' ? {} : { year });
  }, [year, triggerChange]);

  const handleFilterYear = (e, { value }) => {
    setYear(value);
    requestHandledBySelf && setFilterYear(value);
  };

  return (
    <div className="filter_options">
      <p className="filter_options__title">Year</p>
      <Dropdown
        selection
        clearable
        value={year}
        options={toDropDownOptions(filterOptions)}
        onChange={handleFilterYear}
      />
    </div>
  );
};

DropdownFilterYear.defaultProps = {};

DropdownFilterYear.propTypes = {
  handleChange: PropTypes.func.isRequired,
  params: PropTypes.object,
  setFilterYear: PropTypes.func,
  requestHandledBySelf: PropTypes.bool,
  triggerChange: PropTypes.number,
};

export default DropdownFilterYear;
