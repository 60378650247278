import moment from 'moment';

const validateProjectDates = ({
  start_date: startDate,
  end_date: endDate,
} = {}) => {
  const currentDate = moment();

  if (startDate && endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    if (startDate.isAfter(endDate)) {
      return 'The end date should be greater than the start date';
    }
    if (startDate.isSame(endDate)) {
      return 'The end date should not be equal to the start date';
    }
    if (endDate.isBefore(currentDate)) {
      return 'Both start and end dates should not be in the past';
    }
  }

  return false;
};

export default validateProjectDates;
