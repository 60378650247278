/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Details from 'containers/Organizations/SingleOrganization/Settings/Details';
import AppNavPanel from 'components/common/AppNavPanel';
import Users from 'containers/Organizations/SingleOrganization/Settings/Users';

import './index.scss';

const OrganizationSettings = props => {
  const {
    match: {
      params: { organizationId, tabOption },
    },
  } = props;

  const routes = [
    {
      name: 'Details',
      path: `/organizations/${organizationId}/settings`,
    },
    {
      name: 'Users',
      path: `/organizations/${organizationId}/settings/users`,
    },
  ];

  return (
    <section className="organization-settings-wrapper">
      <AppNavPanel routes={routes} settings />
      {tabOption === 'details' || !tabOption ? (
        <Details {...props} noToolbar />
      ) : (
        <Users {...props} />
      )}
    </section>
  );
};

OrganizationSettings.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
};

export default OrganizationSettings;
