import React from 'react';
import { Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import './index.scss';

const LoanDetails = ({ data }) => {
  return (
    <div className="loan-modification-modal">
      <div className="bold-title">Requested Loan</div>
      <Divider />
      <div className="loan-modification-modal__item">
        Loan amount{' '}
        <strong>{`${numeral(data?.request?.amount).format(
          '0.00',
        )} Rwf`}</strong>
      </div>
      <div className="loan-modification-modal__item">
        Repayment days{' '}
        <strong>{data?.request?.repayment_days}</strong>
      </div>
      <div className="loan-modification-modal__item">
        Repayment installments
        <strong>{data?.request?.repayment_installments}</strong>
      </div>
      <div className="loan-modification-modal__item">
        Interest on loan{' '}
        <strong>
          {`${numeral(data?.request?.loans_interest).format(
            '0.00',
          )} Rwf`}
        </strong>
      </div>
      <div className="loan-modification-modal__item">
        Payment/Installment
        <strong>
          {`${numeral(
            data?.request?.repayment_installment_amount,
          ).format('0.00')} Rwf`}
        </strong>
      </div>
      {!data?.modified && (
        <div className="loan-modification-modal__item">
          Approved By
          <strong>{`${data?.request?.group?.created_by?.last_name} ${data?.request?.group?.created_by?.first_name}`}</strong>
        </div>
      )}
      {data?.request?.is_modified && (
        <>
          <Divider />
          <div className="bold-title">Loan Amendments</div>
          <Divider />
          <div className="loan-modification-modal__item">
            Loan amount{' '}
            <strong>
              {`${numeral(data?.request?.modified_amount).format(
                '0.00',
              )} Rwf`}
            </strong>
          </div>
          <div className="loan-modification-modal__item">
            Repayment days{' '}
            <strong>{data?.request?.modified_repayment_days}</strong>
          </div>
          <div className="loan-modification-modal__item">
            Repayment installments{' '}
            <strong>{data?.request?.modified_installments}</strong>
          </div>
          <div className="loan-modification-modal__item">
            Interest on loan{' '}
            <strong>
              {`${numeral(
                data?.request?.modified_loans_interest,
              ).format('0.00')} Rwf`}
            </strong>
          </div>
          <div className="loan-modification-modal__item">
            Payment/Installment{' '}
            <strong>
              {`${numeral(
                data?.request?.modified_installment_amount,
              ).format('0.00')} Rwf`}
            </strong>
          </div>
        </>
      )}

      <div className="loan-modification-modal__item">
        <strong>
          {data?.request?.is_modified
            ? 'Reason for Loan Amendment'
            : 'Reason for Loan Request'}
        </strong>
      </div>
      <p>
        {data?.request?.is_modified
          ? data?.request?.modified_reason
          : data?.request?.comment}
      </p>
    </div>
  );
};

LoanDetails.defaultProps = {
  data: {},
};
LoanDetails.propTypes = {
  data: PropTypes.shape({
    request: PropTypes.object,
    group: PropTypes.object,
    description: PropTypes.string,
    modified: PropTypes.bool,
  }),
};

export default LoanDetails;
