import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message, Grid } from 'semantic-ui-react';
import LineCharts from 'containers/Organizations/Overviews/LineCharts';
import StatCharts from 'containers/Organizations/Overviews/StatCharts';
import OrganizationsGenders from 'containers/Organizations/Overviews/OrganizationsGenders';
import getOrganizationsOverview from 'redux/actions/organizations/organizationsOverview';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import './index.scss';
import DropdownFilterYear from 'components/common/DropdownFilterYear';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';

const GeneralOverview = () => {
  const currentYear = useRef();
  const { data, loading, error } = useSelector(
    ({ organizations: { organizationsOverview: overviews } }) =>
      overviews,
  );
  const dispatch = useDispatch();

  const getOrganizationsOverviewWithYear = (_, yearQuery) => {
    currentYear.current = yearQuery.year;
    getOrganizationsOverview(yearQuery)(dispatch);
  };

  return (
    <>
      <Toolbar
        header={{
          title: 'Overview',
          rightComponent: (
            <>
              <DropdownFilterYear
                handleChange={getOrganizationsOverviewWithYear}
              />
              <ToolbarFooter
                url="/stats/"
                fetchExportData
                exportCSVFileName="general-overview"
              />
            </>
          ),
        }}
      />

      <div className="organizations-overview scroll-wrapper">
        {loading && <Loader />}

        {error && <Message negative>{error.message}</Message>}

        {!loading && data && (
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column width="12">
                <LineCharts data={data} year={currentYear.current} />
              </Grid.Column>
              <Grid.Column width="4">
                <OrganizationsGenders
                  data={data}
                  year={currentYear.current}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={data} year={currentYear.current} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </>
  );
};

export default GeneralOverview;
