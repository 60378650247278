import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from 'components/common/Toolbar';
import './AllActivity.scss';
import RecentActivity from './RecentActivity';

const AllActivity = ({ type, title, subTitle, params }) => {
  return (
    <section className="bulk-sms bulk-sms-all">
      <Toolbar header={{ title: subTitle, navigation: true }} />
      <div className="bulk-sms-container">
        <RecentActivity
          type={type}
          title={title}
          params={params}
          viewAll
        />
      </div>
    </section>
  );
};

AllActivity.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  params: PropTypes.string.isRequired,
};

export default AllActivity;
