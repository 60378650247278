import { omitBy } from 'lodash';

const removeEmptyProperties = form => {
  return omitBy(form, property => property === '');
};

export const compactObject = object => {
  return omitBy(object, property =>
    ['', null, undefined].includes(property),
  );
};

export default removeEmptyProperties;
