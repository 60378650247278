/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import { cleanCumulative } from 'helpers/toCumulativeDates';
import todaysDate from 'utils/todaysDate';
import toMonthStat from 'helpers/toMonthStat';
import formatNumber from 'helpers/formatNumber';

const LineCharts = ({ data = {} }) => {
  const {
    profiles_count: profilesCount,
    org_loan_profiles: profiles = [],
    groups_count: groupsCount,
    groups_members_count: groupsMembersCount,
    groups = [],
    cumulative_savings_total: cumulativeSavingsTotal,
    cumulative_savings: cumulativeSavings,
    cumulative_loans_total: cumulativeLoansTotal,
    cumulative_loans: cumulativeLoans,
    cumulative_savings_vs_cumulative_loans: cumulativeSavingsVsCumulativeLoans,
    current_savings_vs_current_loans: currentSavingsVsCurrentLoans,
    members_age_range: membersAgeRange,
    actives_vs_inactives_groups: activesVsInactivesGroups,
  } = data;

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Profiles',
              date: todaysDate(),
              viewMoreRedirect: true,
              amount: formatNumber(profilesCount),
              data: toMonthStat(profiles),
              background: greenColor,
              dataKey: 'count',
              viewKey1: 'profiles',
              view: 'general',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Saving groups',
              date: todaysDate(),
              amount: formatNumber(groupsCount),
              data: groups,
              background: blueColor,
              dataKey: 'count',
              viewKey1: 'profiles',
              view: 'general',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Cumulative savings',
              date: todaysDate(),
              amount: formatNumber(cumulativeSavingsTotal),
              data: cumulativeSavings,
              background: orangeColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Cumulative loans',
              date: todaysDate(),
              amount: formatNumber(cumulativeLoansTotal),
              data: cleanCumulative(cumulativeLoans),
              background: redColor,
              dataKey: 'uv',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default LineCharts;
