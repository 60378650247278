import {
  RESET_GROUP_SHARE_START,
  RESET_GROUP_SHARE_SUCCESS,
  RESET_GROUP_SHARE_FAILED,
} from 'constants/action-types/groups/resetGroupShares.js';

export default (state, { type, payload }) => {
  switch (type) {
    case RESET_GROUP_SHARE_START:
      return {
        ...state,
        resetGroupShares: {
          ...state.resetGroupShares,
          loading: true,
          error: null,
          data: null,
        },
      };
    case RESET_GROUP_SHARE_SUCCESS:
      return {
        ...state,
        resetGroupShares: {
          ...state.resetGroupShares,
          data: payload,
          error: null,
          loading: false,
        },
      };
    case RESET_GROUP_SHARE_FAILED:
      return {
        ...state,
        resetGroupShares: {
          ...state.resetGroupShares,
          loading: false,
          error: payload,
          data: null,
        },
      };
    default:
      return null;
  }
};
