import moment from 'moment';
import phoneUtils from '@exuus/rwanda-phone-utils';
import numeral from 'numeral';
import { greenColor, redColor } from 'utils/chartColors';

export default agent => {
  return [
    {
      icon: 'phone',
      label: 'Phone Number:',
      content: phoneUtils(agent?.phone_number).format('space'),
    },
    {
      icon: 'money',
      label: 'cashed out commission:',
      content: `${numeral(agent?.cumulative_cashout_amount).format(
        '0,0',
      )} RWF`,
      color: greenColor,
      visible: agent?.cumulative_cashout_amount !== undefined,
    },
    {
      icon: 'clock outline',
      label: 'current month:',
      content: moment(agent?.updated_at).format('MMMM YYYY'),
    },
    {
      icon: 'users',
      label: 'groups:',
      content: agent?.agent_groups_total,
      visible: agent?.agent_groups_total !== undefined,
    },
    {
      icon: 'money',
      label: 'commission balance:',
      content: `${numeral(agent?.current_commission).format(
        '0,0',
      )} RWF`,
      color: redColor,
      visible: agent?.current_commission !== undefined,
    },
    {
      icon: 'users',
      label: 'active members:',
      content: agent?.agents_members_supervised_active_count,
      visible:
        agent?.agents_members_supervised_active_count !== undefined,
    },
    {
      icon: 'users',
      label: 'total members:',
      content: agent?.agent_groups_members_total,
      visible: agent?.agent_groups_members_total !== undefined,
    },
    {
      icon: 'money',
      label: 'previous balance:',
      content: `${numeral(agent?.previous_commission_balance).format(
        '0,0',
      )} RWF`,
      visible: agent?.previous_commission_balance !== undefined,
    },
    {
      icon: 'money',
      label: 'monthly commission:',
      content: `${numeral(agent?.current_month_commission).format(
        '0,0',
      )} RWF`,
      visible: agent?.current_month_commission !== undefined,
    },
  ];
};
