import {
  GET_MICRO_LOANS_SETTINGS_DATA_ERROR,
  GET_MICRO_LOANS_SETTINGS_DATA_START,
  GET_MICRO_LOANS_SETTINGS_DATA_SUCCESS,
} from 'constants/action-types/settings/getMicroLoansSettingData';
import apiAction from 'helpers/apiAction';

export default queries => dispatch =>
  dispatch(
    apiAction({
      url: '/groups/microloans/settings',
      method: 'GET',
      queries,
      API_REQUEST_START: GET_MICRO_LOANS_SETTINGS_DATA_START,
      API_REQUEST_SUCCESS: GET_MICRO_LOANS_SETTINGS_DATA_SUCCESS,
      API_REQUEST_FAILURE: GET_MICRO_LOANS_SETTINGS_DATA_ERROR,
      onSuccess: payload => {
        dispatch({
          type: GET_MICRO_LOANS_SETTINGS_DATA_SUCCESS,
          payload: payload?.data,
        });
      },
      onEnd: () => false,
    }),
  );
