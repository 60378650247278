import AccountSettings from 'containers/Users/AccountSettings';

export default {
  exact: true,
  name: 'Account',
  protected: true,
  path: '/account',
  component: AccountSettings,
  permissions: ['all'],
};
