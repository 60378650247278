import {
  WITHDRAWAL_WALLET_ERROR,
  WITHDRAWAL_WALLET_START,
  WITHDRAWAL_WALLET_SUCCESS,
} from 'constants/action-types/wallets/withdrawalWallet';

export default (state, { type, payload }) => {
  switch (type) {
    case WITHDRAWAL_WALLET_START:
      return {
        ...state,
        withdrawalWallet: {
          ...state.withdrawalWallet,
          data: {},
          message: null,
          loading: true,
          error: null,
        },
      };
    case WITHDRAWAL_WALLET_SUCCESS:
      return {
        ...state,
        withdrawalWallet: {
          ...state.withdrawalWallet,
          data: payload.data,
          message: payload.message,
          error: null,
          loading: false,
        },
      };
    case WITHDRAWAL_WALLET_ERROR:
      return {
        ...state,
        withdrawalWallet: {
          ...state.withdrawalWallet,
          error: payload,
          loading: false,
          message: null,
          data: {},
        },
      };
    default:
      return null;
  }
};
