import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import {
  COUNT_PROFILE_GROUPS_FAILED,
  COUNT_PROFILE_GROUPS_START,
  COUNT_PROFILE_GROUPS_SUCCESS,
} from 'constants/action-types/profiles/countProfileGroups';

export default ({ id, data }) => (dispatch, setPage) =>
  dispatch(
    apiAction({
      url: `/organizations/${id}/profiles/count_groups_per_profile`,
      method: 'POST',
      data,
      API_REQUEST_START: COUNT_PROFILE_GROUPS_START,
      API_REQUEST_SUCCESS: COUNT_PROFILE_GROUPS_SUCCESS,
      API_REQUEST_FAILURE: COUNT_PROFILE_GROUPS_FAILED,
      callbackOnSuccess: () => setPage(1),
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );
