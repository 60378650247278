const toOptions = options =>
  options.map(option => ({
    text: option,
    value: option,
    key: option,
  }));

const toCountryOptions = countries =>
  countries.map(({ name, alpha }) => ({
    text: name,
    value: alpha,
    key: alpha,
  }));

// ⛔⚠️ do not change the order of the elements in the array
const types = [
  'Business ORG',
  'NGO',
  'Cooperative Federation',
  'Federation',
  'Union',
  'Cooperative',
];

// ⛔⚠️ do not change the order of the elements in the array
export const categories = {
  'Business ORG': toOptions(['SSA', 'SA', 'FSP']),
  NGO: toOptions(['International', 'Local']),
  'Cooperative Federation': toCountryOptions([
    {
      name: 'Rwanda',
      alpha: 'rw',
    },
  ]),
};

export const typeOptions = toOptions(types);

export const defaultValues = role => {
  switch (role) {
    case 'isSSA':
      return {
        type: typeOptions[0].value,
        category: categories['Business ORG'][1].value,
      };
    case 'isSA':
      return {
        type: typeOptions[1].value,
        category: [],
      };
    default:
      break;
  }
  return {};
};
