export default [
  {
    name: 'Overview',
    path: '/',
    permissions: ['org_user', 'sa', 'ssa'],
  },
  {
    name: 'Activities',
    path: '/activities',
    permissions: ['org_user', 'sa', 'ssa'],
  },
  {
    name: 'All Users',
    path: '/all-users',
    permissions: ['sa', 'ssa'],
  },
  {
    name: 'All Saving Groups',
    path: '/all-groups',
    permissions: ['sa', 'ssa'],
  },
  {
    name: 'Organization Users',
    path: '/users',
    permissions: ['org_user', 'sa', 'ssa'],
  },
  {
    name: 'Wallet',
    path: '/wallets',
    permissions: [
      'ssa',
      'save_rwanda_admin',
      'sa_support_admin',
      'sa_finance_admin',
      'wallet_org_admin',
    ],
  },
  {
    name: 'Communications',
    path: '/communications/bulk-sms',
    permissions: ['save_rwanda_admin', 'sa_communications_admin'],
  },
  {
    name: 'Settings',
    path: '/settings',
    permissions: ['org_user', 'sa', 'ssa'],
  },
  {
    name: 'Support',
    path: '/support',
    permissions: ['org_user', 'sa', 'ssa'],
  },
];
