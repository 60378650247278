/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import LineChartWrapper from 'components/common/charts/LineChart/LineChartWrapper/LineChartWrapper';
import todaysDate from 'utils/todaysDate';
import PieChart from 'components/common/charts/PieChart/PieChartWrapper';

const StatCharts = ({
  data: {
    cumulative_savings_vs_cumulative_loans = [],
    current_savings_vs_current_loans = [],
    members_age_range,
    actives_vs_inactives_groups: activeVsInactiveGroups = [],
  },
}) => {
  const cleanSavingsVsLoans =
    cumulative_savings_vs_cumulative_loans &&
    cumulative_savings_vs_cumulative_loans.map(item => {
      return {
        ...item,
        name: item.month.substring(0, 3),
      };
    });

  const new_cumulative_vs_current_loans =
    current_savings_vs_current_loans &&
    current_savings_vs_current_loans.map(item => {
      return {
        ...item,
        name: item.month.substring(0, 3),
      };
    });

  const shortMonthActiveVsInactiveGroups = activeVsInactiveGroups.map(
    item => ({
      ...item,
      name: item.month.substring(0, 3),
    }),
  );

  const colors = ['#8884d8', '#83a6ed', '#D0ED57'];
  const member_ages =
    members_age_range &&
    members_age_range.map((item, i) => {
      item.fill = colors[i];
      item.name = item.age;
      return item;
    });
  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Cumulative Savings Vs Cumulative Loans',
              date: todaysDate(),
              data: cleanSavingsVsLoans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Monthly Savings Vs Monthly Loans',
              date: todaysDate(),
              data: new_cumulative_vs_current_loans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <PieChart
            filled
            title="Members Age Range"
            width={400}
            height={330}
            innerRadius={20}
            outerRadius={140}
            barSize={10}
            dataKey="total"
            nameKey="age"
            data={member_ages}
            date={todaysDate()}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <LineChartWrapper
            title="Active Vs Inactive Groups"
            dataKey1="actives"
            dataKey2="inactives"
            width={440}
            height={300}
            data={shortMonthActiveVsInactiveGroups}
            date={todaysDate()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

StatCharts.propTypes = {
  data: PropTypes.shape({
    cumulative_savings_vs_cumulative_loans: PropTypes.array,
    current_savings_vs_current_loans: PropTypes.array,
    members_age_range: PropTypes.array,
    actives_vs_inactives_groups: PropTypes.array,
  }).isRequired,
};

export default StatCharts;
