import {
  REPORT_A_PROBLEM_FAILED,
  REPORT_A_PROBLEM_START,
  REPORT_A_PROBLEM_SUCCESS,
} from 'constants/action-types/support';

import apiAction from 'helpers/apiAction';
import notify from 'helpers/notificationHelper';
import { version } from '../../../../package.json';

export default ({ topic, message, role }) => dispatch => {
  const onSuccess = ({ message }) => {
    notify({
      message,
    });

    dispatch({
      type: REPORT_A_PROBLEM_SUCCESS,
    });
  };

  const { appVersion, platform } = window.navigator;

  const data = {
    topic,
    message,
    role,
    os: platform,
    osVersion: appVersion.match(/\(.*\)/)[0],
    app: 'Save Dashboard',
    appVersion: version,
  };

  dispatch(
    apiAction({
      url: `/problems`,
      method: 'POST',
      data,
      API_REQUEST_START: REPORT_A_PROBLEM_START,
      API_REQUEST_SUCCESS: REPORT_A_PROBLEM_SUCCESS,
      API_REQUEST_FAILURE: REPORT_A_PROBLEM_FAILED,
      onSuccess,
      onEnd: () => false,
    }),
  );
};
