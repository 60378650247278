import {
  SEND_BULK_PUSH_NOTIFICATION_ERROR,
  SEND_BULK_PUSH_NOTIFICATION_START,
  SEND_BULK_PUSH_NOTIFICATION_SUCCESS,
} from 'constants/action-types/organizations/pushNotifications';

export default (state, { type, payload }) => {
  switch (type) {
    case SEND_BULK_PUSH_NOTIFICATION_START:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          loading: true,
          error: null,
        },
      };
    case SEND_BULK_PUSH_NOTIFICATION_ERROR:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          error: payload,
          loading: false,
        },
      };
    case SEND_BULK_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pushNotifications: {
          ...state.pushNotifications,
          message: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};
