/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NGOOverviews from 'components/Organizations/SingleOrganization/Overview/NGOOverviews';
import FspOverviews from 'components/Organizations/SingleOrganization/Overview/FspOverviews';
import isParentOrganization from 'helpers/isParentOrganization';
import { isBusinessOrganization } from 'helpers/organizationType';
import GeneralOverview from './GeneralOverview';

const Overview = props => {
  const isCurrentOrgParent = isParentOrganization();
  const isCurrentOrgBusiness = isBusinessOrganization();

  return (
    <>
      {isCurrentOrgParent ? (
        <GeneralOverview />
      ) : isCurrentOrgBusiness ? (
        <FspOverviews {...props} />
      ) : (
        <NGOOverviews {...props} />
      )}
    </>
  );
};

export default Overview;
