import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SingleOrganization from 'containers/Settings/OrganizationProfile';
import getSingleOrganization from 'redux/actions/organizations/getSingleOrganization';
import updateSingleOrganization from 'redux/actions/organizations/updateSingleOrganization';

const SingleOrganizationContainer = ({ match }) => {
  const { organizationId } = match.params;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const {
    currentOrganization: {
      details: { organization: currentOrganization } = {},
    } = {},
    getSingleOrganization: {
      loading: getOrganizationLoading,
      error: getOrganizationError,
    },
    updateSingleOrganization: {
      loading: updateOrganizationLoading,
      error: updateOrganizationError,
      message: updateOrganizationMessage,
    },
  } = useSelector(({ organizations }) => organizations);

  const updateOrganization = () => {
    updateSingleOrganization(organizationId, formData)(dispatch);
  };

  useEffect(() => {
    getSingleOrganization(organizationId)(dispatch);
  }, [organizationId, dispatch]);

  const handleChange = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const setTypeCategories = args => {
    setFormData({ ...formData, ...args });
  };

  useEffect(() => {
    setFormData(currentOrganization);
  }, [currentOrganization]);

  return (
    <SingleOrganization
      noToolbar
      formData={formData}
      getOrganizationLoading={getOrganizationLoading}
      getOrganizationError={getOrganizationError}
      updateOrganizationLoading={updateOrganizationLoading}
      updateOrganizationError={updateOrganizationError}
      updateOrganizationMessage={updateOrganizationMessage}
      handleSubmit={updateOrganization}
      handleChange={handleChange}
      typeError={null}
      categoryError={null}
      setTypeCategories={setTypeCategories}
    />
  );
};

SingleOrganizationContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default SingleOrganizationContainer;
