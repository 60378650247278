import {
  ADD_TEST_FEATURE_START,
  ADD_TEST_FEATURE_ERROR,
  ADD_TEST_FEATURE_SUCCESS,
} from 'constants/action-types/settings/addTestFeature';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_TEST_FEATURE_START:
      return {
        ...state,
        addTestFeature: {
          ...state.addTestFeature,
          loading: true,
          error: null,
          message: null,
        },
      };
    case ADD_TEST_FEATURE_ERROR:
      return {
        ...state,
        addTestFeature: {
          ...state.addTestFeature,
          error: payload,
          loading: false,
        },
      };
    case ADD_TEST_FEATURE_SUCCESS:
      return {
        ...state,
        addTestFeature: {
          ...state.addTestFeature,
          error: {},
          loading: false,
          message: payload,
        },
      };
    default:
      return null;
  }
};
