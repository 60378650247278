import {
  GET_ORG_WALLET_ERROR,
  GET_ORG_WALLET_START,
  GET_ORG_WALLET_SUCCESS,
} from 'constants/action-types/organizations/getOrgWithWallet';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ORG_WALLET_START:
      return {
        ...state,
        hasWallet: {
          ...state.hasWallet,
          loading: true,
          error: null,
        },
      };
    case GET_ORG_WALLET_ERROR:
      return {
        ...state,
        hasWallet: {
          ...state.hasWallet,
          error: payload,
          loading: false,
        },
      };
    case GET_ORG_WALLET_SUCCESS:
      return {
        ...state,
        hasWallet: {
          ...state.hasWallet,
          items: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    default:
      return null;
  }
};
