import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from 'components/common/Toolbar';
import { Loader } from 'semantic-ui-react';
import Empty from 'components/common/Empty';
import { isEmpty } from 'lodash';
import './index.scss';
import getMemberOverdueLoans from 'redux/actions/groups/getMemberOverdueLoans';
import LoansList from './LoansList';
import getGroupMember from 'redux/actions/organizations/getGroupMember';

const MemberOverdueLoans = props => {
  const {
    match: {
      params: {
        organizationId,
        tabOption,
        option2: memberId,
        option,
      },
    },
  } = props;
  const groupId = tabOption || option;
  const dispatch = useDispatch();

  useEffect(() => {
    getGroupMember(groupId, memberId)(dispatch);
    getMemberOverdueLoans(groupId, memberId)(dispatch);
  }, []);

  const { data, loading } = useSelector(
    ({
      groups: {
        singleMember: { MemberOverdueLoans },
      },
    }) => MemberOverdueLoans,
  );

  const {
    member_info: { user: { first_name: firstName = '' } = {} } = {},
    group: { name: groupName = '' } = {},
  } = useSelector(
    ({ organizations: { groupMember } }) => groupMember?.data,
  );

  return (
    <div className="member__loans">
      <Toolbar
        header={{
          breadcrumbTitle: 'breadcrumb-title',
          breadcrumbParent: `${groupName}`,
          breadcrumbParentLink: `/organizations/${organizationId}/groups/${groupId}`,
          breadcrumbChildren: 'Members',
          breadcrumbChildrenLink: `/organizations/${organizationId}/groups/${groupId}/members`,
          breadcrumbChildrenTwo: `${firstName}`,
          breadcrumbChildrenLinkTwo: `/organizations/${organizationId}/groups/${groupId}/members/${memberId}`,
          breadcrumbActive: 'Overdue Loan Interest',
        }}
      />
      {loading ? (
        <Loader active />
      ) : isEmpty(data) ? (
        <Empty message="No overdue loans found" />
      ) : (
        <div className="scroll-wrapper">
          {data.map(loan => (
            <LoansList item={loan} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MemberOverdueLoans;
