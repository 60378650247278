import {
  GET_PROFILE_GROUPS_ERROR,
  GET_PROFILE_GROUPS_START,
  GET_PROFILE_GROUPS_SUCCESS,
} from 'constants/action-types/organizations/profiles/getProfileGroups';

import apiAction from 'helpers/apiAction';

const getProfileGroups = (
  organizationId,
  profileId,
  queries,
) => dispatch => {
  const url = `/organizations/${organizationId}/profiles/${profileId}/groups`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_PROFILE_GROUPS_START,
      API_REQUEST_SUCCESS: GET_PROFILE_GROUPS_SUCCESS,
      API_REQUEST_FAILURE: GET_PROFILE_GROUPS_ERROR,
      onEnd: () => false,
    }),
  );
};

export default getProfileGroups;
