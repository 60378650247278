export const DELETE_GROUP_MEMBER_START = 'DELETE_GROUP_MEMBER_START';
export const DELETE_GROUP_MEMBER_SUCCESS =
  'DELETE_GROUP_MEMBER_SUCCESS';
export const DELETE_GROUP_MEMBER_ERROR = 'DELETE_GROUP_MEMBER_ERROR';
export const DELETE_MULTIPLE_MEMBER_START =
  'DELETE_MULTIPLE_MEMBER_START';
export const DELETE_MULTIPLE_MEMBER_SUCCESS =
  'DELETE_MULTIPLE_MEMBER_SUCCESS';
export const DELETE_MULTIPLE_MEMBER_ERROR =
  'DELETE_MULTIPLE_MEMBER_ERROR';
