/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Icon,
  Message,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { omit, isEmpty } from 'lodash';
import Loader from 'components/common/PageLoader';
import './index.scss';
import Empty from 'components/common/Empty';
import getAllFeatures from 'redux/actions/settings/getAllFeatures';
import Toolbar from 'components/common/Toolbar';
import SidebarFilter from 'components/common/SidebarFilter';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import capitalize, { capitalizeEachWords } from 'utils/capitalize';
import PINInput from 'components/common/PINInput';
import deactivateFeature from 'redux/actions/settings/deactivateFeature';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import { canPerformAction } from 'helpers/permissions';
import UserTestModal from './UserTestModal';
import GroupTestModal from './GroupTestModal';

const FeatureSettings = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { search } = useLocation();
  const [form, setForm] = useState({});
  const [filterOn, setFilterOn] = useState(false);
  const [filterForm, setFilterForm] = useState({});
  const [pinModal, setPinModal] = useState(false);
  const [userTestModal, setUserTestModal] = useState(false);
  const [groupTestModal, setGroupTestModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalFeature, setModalFeature] = useState('');
  const urlParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlParams);

  const { roles = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  const { data: features = [], loading, error } = useSelector(
    ({ settings: { allFeatures } }) => allFeatures,
  );

  const { loading: actionLoading, error: actionError } = useSelector(
    ({ settings: { deactivateFeature } }) => deactivateFeature,
  );

  useEffect(() => {
    getAllFeatures({ ...params })(dispatch);
  }, []);

  const filteredFeatures = () => {
    getAllFeatures({ ...params, ...filterForm })(dispatch);
  };
  useEffect(() => {
    if (!isEmpty(filterForm)) filteredFeatures();
  }, [filterForm]);

  const changeStatus = (status, id, title, platform) => {
    setForm({ ...form, status, id, title, platform });
    setPinModal(true);
  };

  const defaultParams = {
    platform: filterForm?.platform,
    type: filterForm?.type,
    frequency: filterForm?.frequency,
    status: filterForm?.status,
    search: params?.search,
  };
  const handleFilter = (e, { name, value }) => {
    push(
      formatSearchParameter({
        ...omit(defaultParams, 'platform'),
        [name]: value,
      }),
    );
    setFilterForm({ ...filterForm, [name]: value });
  };

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    const action = form?.status ? 'activate' : 'deactivate';
    deactivateFeature(
      form?.id,
      action,
      form?.password,
      form?.platform,
    )(dispatch, setPinModal);
  };

  const onQuery = queries => {
    getAllFeatures({ ...params, ...queries })(dispatch);
  };

  const rightComponent = (
    <>
      <ToolbarFooter
        filterClicked={() => {
          setFilterOn(true);
        }}
        filter
        onSearch={onQuery}
      />
      {canPerformAction('canAddEditFeature', roles) && (
        <Button primary onClick={() => push('/features/add')}>
          Add feature
        </Button>
      )}
    </>
  );

  return (
    <>
      <Modal
        size="mini"
        open={pinModal}
        onClose={() => setPinModal(false)}
      >
        <Modal.Header>
          {form?.status ? 'Activate' : 'Deactivate'} {form?.title}
          <Icon
            name="close"
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to{' '}
            {form?.status ? 'activate' : 'deactivate'} {form?.title}{' '}
            Feature?
          </p>
          <Form onSubmit={handleSubmit} loading={actionLoading}>
            <PINInput
              className="input"
              fluid
              size="tiny"
              label="Enter Pin"
              name="password"
              onInputChange={handleChange}
            />

            {actionError && actionError === 'Wrong PIN.' && (
              <p color="red">
                <Message negative>{actionError}</Message>
              </p>
            )}

            <Button
              className="confirm"
              type="submit"
              color="blue"
              fluid
            >
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
      <UserTestModal
        open={userTestModal}
        setOpen={setUserTestModal}
        type={modalType}
        feature={modalFeature}
      />
      <GroupTestModal
        open={groupTestModal}
        setOpen={setGroupTestModal}
        type={modalType}
        feature={modalFeature}
      />
      <Toolbar
        header={{
          title: 'Feature Settings',
          navigation: false,
          rightComponent,
        }}
        style={{
          marginLeft: '0px',
        }}
      />
      <div className="setting__features">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message}</Message>
        ) : features.length === 0 ? (
          <Empty message="No features found" />
        ) : (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Feature Name</Table.HeaderCell>
                <Table.HeaderCell>Platform(s)</Table.HeaderCell>
                <Table.HeaderCell>Frequency</Table.HeaderCell>
                <Table.HeaderCell>
                  Accessibility Level
                </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {features.map(
                ({
                  id,
                  feature_name: featureName,
                  platforms = [],
                  active_frequency: frequency,
                  status,
                  users = [],
                  groups = [],
                  active_time,
                  feature_type,
                }) => (
                  <Table.Row key={id}>
                    <Table.Cell>
                      {canPerformAction(
                        'canAddEditFeature',
                        roles,
                      ) ? (
                        <Link to={`/features/${id}/edit`}>
                          {featureName}
                        </Link>
                      ) : (
                        featureName
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        trigger={
                          <span>
                            {platforms[0] &&
                              capitalizeEachWords(
                                platforms[0]?.platform_name?.replace(
                                  /_/g,
                                  ' ',
                                ),
                              )}
                            {platforms.length > 1 && '..'}
                          </span>
                        }
                        content={platforms.map(
                          ({ platform_name }) => (
                            <span>
                              {capitalizeEachWords(
                                platform_name?.replace(/_/g, ' '),
                              )}
                              ,{' '}
                            </span>
                          ),
                        )}
                      />
                    </Table.Cell>
                    <Table.Cell>{frequency}</Table.Cell>
                    <Table.Cell>
                      {capitalize(feature_type)}
                    </Table.Cell>
                    <Table.Cell className="status-field sg-status">
                      <Checkbox
                        toggle
                        checked={status === 'active'}
                        onChange={(_, { checked }) =>
                          changeStatus(
                            checked,
                            id,
                            featureName,
                            platforms[0]?.platform_name,
                          )
                        }
                        disabled={
                          !canPerformAction(
                            'canDeactivateFeature',
                            roles,
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        trigger={
                          <Icon name="info circle" color="blue" />
                        }
                        content={
                          <div className="setting__features-info">
                            <div className="setting__features-info__title">
                              {featureName}
                            </div>
                            <Divider />
                            <div className="setting__features-info__details">
                              <div className="setting__features-info__details--left">
                                <h5>Platform(s)</h5>
                                <span>
                                  {platforms.map(
                                    ({ platform_name }) => (
                                      <span>
                                        {capitalizeEachWords(
                                          platform_name?.replace(
                                            /_/g,
                                            ' ',
                                          ),
                                        )}
                                        ,{' '}
                                      </span>
                                    ),
                                  )}
                                </span>

                                <h5>Frequency</h5>
                                <span>{frequency}</span>
                              </div>
                              <div className="setting__features-info__details--right">
                                <h5>Status</h5>
                                <span>{capitalize(status)}</span>

                                {active_time && (
                                  <>
                                    <h5>Active time</h5>
                                    <span>
                                      {capitalize(active_time)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            {feature_type !== 'live' &&
                              users?.length > 0 && (
                                <div className="setting__features-info__description">
                                  <h5>{`${capitalize(
                                    feature_type,
                                  )} users`}</h5>
                                  <p>
                                    {users.map(
                                      ({
                                        id,
                                        first_name,
                                        last_name,
                                      }) => (
                                        <span>
                                          {`${id}(${first_name} ${last_name})`}
                                          ,{' '}
                                        </span>
                                      ),
                                    )}
                                  </p>
                                </div>
                              )}
                            {feature_type !== 'live' &&
                              groups?.length > 0 && (
                                <div className="setting__features-info__description">
                                  <h5>{`${capitalize(
                                    feature_type,
                                  )} groups`}</h5>
                                  <p>
                                    {groups.map(({ id, name }) => (
                                      <span>
                                        {`${id}(${capitalizeEachWords(
                                          name,
                                        )})`}
                                        ,{' '}
                                      </span>
                                    ))}
                                  </p>
                                </div>
                              )}
                          </div>
                        }
                        on="click"
                        hoverable
                        position="left center"
                      />
                      {feature_type !== 'live' && (
                        <Dropdown
                          icon={null}
                          direction="left"
                          trigger={
                            <div className="ellipsis-dropdown-options">
                              <Icon name="ellipsis vertical" />
                            </div>
                          }
                        >
                          <Dropdown.Menu>
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setModalType(feature_type);
                                  setModalFeature({
                                    id,
                                    name: featureName,
                                  });
                                  return setUserTestModal(true);
                                }}
                              >
                                {feature_type === 'experimental'
                                  ? 'Add experimental users'
                                  : feature_type === 'test' &&
                                    'Add test users'}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setModalType(feature_type);
                                  setModalFeature({
                                    id,
                                    name: featureName,
                                  });
                                  return setGroupTestModal(true);
                                }}
                              >
                                {feature_type === 'experimental'
                                  ? 'Add experimental groups'
                                  : feature_type === 'test' &&
                                    'Add test groups'}
                              </Dropdown.Item>
                            </>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        )}
      </div>
      <SidebarFilter
        {...{
          filterOn,
          setFilterOn,
          form: filterForm,
          setForm: setFilterForm,
          handleChange: handleFilter,
          activity: 'settingFeatures',
        }}
      />
    </>
  );
};

export default FeatureSettings;
