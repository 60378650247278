import {
  GET_GROUP_MEMBER_ERROR,
  GET_GROUP_MEMBER_START,
  GET_GROUP_MEMBER_SUCCESS,
} from 'constants/action-types/organizations/getGroupMember';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_GROUP_MEMBER_START:
      return {
        ...state,
        groupMember: {
          ...state.groupMember,
          loading: true,
          error: null,
        },
      };
    case GET_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        groupMember: {
          ...state.groupMember,
          loading: false,
          data: payload.data,
        },
      };
    case GET_GROUP_MEMBER_ERROR:
      return {
        ...state,
        groupMember: {
          ...state.groupMember,
          loading: false,
          error: payload,
        },
      };
    default:
      return null;
  }
};
