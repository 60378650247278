import React from 'react';
import PropTypes from 'prop-types';
import { LineChart as Chart, Line } from 'recharts';

const LineChart = ({ data, color, type, width, height }) => {
  return (
    <Chart width={width} height={height} data={data}>
      <Line
        type={type}
        dataKey="total"
        stroke={color}
        strokeWidth={2}
      />
    </Chart>
  );
};

LineChart.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

LineChart.defaultProps = {
  type: 'monotone',
  width: 200,
  height: 50,
  color: '#fff',
};

export default LineChart;
