import {
  GET_ALL_GROUPS_ERROR,
  GET_ALL_GROUPS_START,
  GET_ALL_GROUPS_SUCCESS,
} from 'constants/action-types/groups/getAllGroups';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_ALL_GROUPS_START:
      return {
        ...state,
        allGroups: {
          ...state.allGroups,
          loading: true,
          error: null,
          items: [],
          meta: {},
        },
      };
    case GET_ALL_GROUPS_ERROR:
      return {
        ...state,
        allGroups: {
          ...state.allGroups,
          error: payload,
          loading: false,
        },
      };
    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: {
          ...state.allGroups,
          loading: false,
          items: payload.data,
          meta: payload.meta,
        },
      };
    default:
      return null;
  }
};
