import {
  UNBLOCK_USER_ERROR,
  UNBLOCK_USER_START,
  UNBLOCK_USER_SUCCESS,
} from 'constants/action-types/user/unblockUser';
import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';

export default (userId, data) => (dispatch, onCloseConfirmModal) =>
  dispatch(
    apiAction({
      url: `/admin/users/${userId}/unblock`,
      method: 'PUT',
      data,
      API_REQUEST_START: UNBLOCK_USER_START,
      API_REQUEST_SUCCESS: UNBLOCK_USER_SUCCESS,
      API_REQUEST_FAILURE: UNBLOCK_USER_ERROR,
      onSuccess: payload => {
        onCloseConfirmModal();
        dispatch({
          type: UNBLOCK_USER_SUCCESS,
          payload,
        });
        notification({ message: 'User successfully unblocked' });
      },
      onFailure: ({ message }) => {
        onCloseConfirmModal();
        dispatch({
          type: UNBLOCK_USER_ERROR,
          payload: message,
        });
        notification({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
