import {
  UPDATE_GROUP_TRANSACTION_STATUS_ERROR,
  UPDATE_GROUP_TRANSACTION_STATUS_SUCCESS,
  UPDATE_GROUP_TRANSACTION_STATUS_START,
} from 'constants/action-types/groups/updateTransactionStatus';
import apiAction from 'helpers/apiAction';

export default id => dispatch => {
  dispatch(
    apiAction({
      url: `/admin/transactions/${id}`,
      method: 'PUT',
      API_REQUEST_START: UPDATE_GROUP_TRANSACTION_STATUS_START,
      API_REQUEST_SUCCESS: UPDATE_GROUP_TRANSACTION_STATUS_SUCCESS,
      API_REQUEST_FAILURE: UPDATE_GROUP_TRANSACTION_STATUS_ERROR,
      onEnd: () => false,
    }),
  );
};
