/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import UsersList from 'components/Users/UsersList';
import getUsers from 'redux/actions/user/getUsers';
import './index.scss';
import formatNumber from 'helpers/formatNumber';

const OrganizationUsers = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { items = [], meta = {}, loading, error } = useSelector(
    ({ user: { usersList } }) => usersList,
  );

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } }) => details,
  );

  const users = () => {
    if (items?.[0]?.user) {
      return items.map(
        ({ user, user_type: userType, ...details }) => ({
          ...user,
          userType,
          details,
        }),
      );
    }
    return items;
  };

  useEffect(() => {
    getUsers(
      null,
      null,
      {
        user_type: 'org_user',
      },
      true,
    )(dispatch);
  }, []);

  const handleGetUsers = page => {
    if (page) {
      getUsers(null, page, {
        user_type: 'org_user',
      })(dispatch);
      return;
    }
    getUsers(
      null,
      null,
      {
        user_type: 'org_user',
      },
      true,
    )(dispatch);
  };

  const onQuery = queries => {
    getUsers(
      null,
      null,
      {
        ...queries,
        user_type: 'org_user',
      },
      true,
    )(dispatch);
  };

  return (
    <>
      <Toolbar
        header={{
          title: `Organization users (${formatNumber(
            meta.total || '0',
          )})`,
          rightComponent: (
            <ToolbarFooter
              onSearch={onQuery}
              onPaginate={onQuery}
              paginationMeta={meta}
              exportCSVData={items}
              exportCSVFileName="Projects"
            />
          ),
        }}
      />
      <UsersList
        {...{
          noToolbar: true,
          push,
          meta,
          handleGetUsers,
          dispatch,
          loading,
          error,
          users,
          roles,
          allUsers: false,
        }}
      />
    </>
  );
};

OrganizationUsers.propTypes = {};

OrganizationUsers.defaultProps = {};

export default OrganizationUsers;
