export default {
  group_micro_loan: 'Group micro loan disbursement',
  individual_micro_loan: 'Individual micro loan disbursement',
  micro_loan_insurance: 'Micro loan Insurance',
  group_micro_loan_insurance: 'Group micro loan Insurance',
  individual_micro_loan_insurance: 'Individual micro loan Insurance',
  individual_micro_loan_repayment: 'Individual micro loan repayment',
  group_micro_loan_repayment: 'Group micro loan repayment',
  group_micro_loan_guarantee:
    'Group micro loan Guaranteed amount (10%)',
  individual_micro_loan_guarantee:
    'Individual micro loan Guaranteed amount (20%)',
};
