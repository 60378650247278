export default str =>
  str && typeof str === 'string'
    ? `${str[0].toUpperCase()}${str.substr(1)}`
    : '';

export const capitalizeEachWords = sentence =>
  sentence
    ?.toLowerCase()
    ?.split(' ')
    ?.map(word => word.charAt(0).toUpperCase() + word.substring(1))
    ?.join(' ');
