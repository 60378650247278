/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Icon } from 'semantic-ui-react';
import BarChart from 'components/common/charts/BarChart/Container';
import todaysDate from 'utils/todaysDate';
import './genders.scss';
import formatNumber from 'helpers/formatNumber';

const ProfileGenders = ({
  data: {
    males_count: malesCount,
    females_count: femalesCount,
    males,
    females,
    groups_members_count: groupMembersCount,
  },
  year,
}) => {
  return (
    <Grid className="genders">
      <Grid.Row columns={1}>
        <Grid.Column>
          <Card className="Total__members">
            <Card.Content>
              <div className="Total__members__top">
                <div className="Total__members__top__header">
                  <h1 className="Total__members__title">
                    Total Members
                  </h1>
                  <Card.Meta>{todaysDate(year)}</Card.Meta>
                </div>
                <div className="Total__members__top__icon">
                  <Icon name="female" size="big" />
                  <Icon name="male" size="big" />
                </div>
              </div>
              <Card.Description className="Total__members__description">
                {formatNumber(groupMembersCount)}
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BarChart
            {...{
              title: 'Female members',
              date: todaysDate(year),
              data: females,
              count: femalesCount,
              dataKey: 'total',
              height: 68,
              icon: 'female',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <BarChart
            {...{
              title: 'Male members',
              date: todaysDate(year),
              data: males,
              count: malesCount,
              dataKey: 'total',
              height: 68,
              icon: 'male',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProfileGenders.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

export default ProfileGenders;
