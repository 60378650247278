import moment from 'moment';

const todaysDate = incomingYear => {
  if (incomingYear) {
    if (
      !Number.isNaN(+Number(incomingYear)) &&
      Number(incomingYear) !== new Date().getFullYear()
    ) {
      // If incomingYear is not currentYear, set the date to 31st Dec
      return moment([incomingYear, 11, 31]).format('MMM DD, YYYY');
    }
  }
  // If incomingYear is null or is the same as currentYear,
  // set the date to current Date and Month
  return moment().format('MMM DD, YYYY');
};

export default todaysDate;
