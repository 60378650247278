import {
  GET_USER_TRANSACTION_ERROR,
  GET_USER_TRANSACTION_START,
  GET_USER_TRANSACTION_SUCCESS,
} from 'constants/action-types/user/getUserTransactions';
import apiAction from 'helpers/apiAction';

export default (user_id, queries) => dispatch => {
  dispatch(
    apiAction({
      url: `/users/${user_id}/transactions`,
      queries,
      method: 'GET',
      API_REQUEST_START: GET_USER_TRANSACTION_START,
      API_REQUEST_SUCCESS: GET_USER_TRANSACTION_SUCCESS,
      API_REQUEST_FAILURE: GET_USER_TRANSACTION_ERROR,
      onSuccess: ({ data, meta }) => {
        dispatch({
          type: GET_USER_TRANSACTION_SUCCESS,
          payload: {
            data,
            meta,
          },
        });
      },
      onEnd: () => false,
    }),
  );
};
