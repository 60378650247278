import React from 'react';
import PropTypes from 'prop-types';
import abName from 'utils/abName';
import randomColor from 'utils/randomColor';
import './index.scss';

const Thumbnail = ({ avatar, name, secondName, style }) => {
  return (
    <>
      {avatar ? (
        <img src={avatar} alt="" className="thumbnail" />
      ) : (
        <div
          className="thumbnail"
          style={{ ...style, backgroundColor: randomColor() }}
        >
          <span>
            {!secondName ? abName(name) : abName(name, secondName)}
          </span>
        </div>
      )}
    </>
  );
};

Thumbnail.defaultProps = {
  secondName: null,
  style: {},
  avatar: '',
  name: '',
};

Thumbnail.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  secondName: PropTypes.string,
  style: PropTypes.objectOf(Object),
};

export default Thumbnail;
