/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import jpegIcon from 'assets/images/jpegIcon.svg';
import folderImage from 'assets/images/file.svg';
import pngIcon from 'assets/images/pngIcon.svg';
import gifIcon from 'assets/images/gifIcon.svg';

import './index.scss';

const MyDropzone = ({
  setFile,
  file,
  label,
  showPreview = true,
  accept = 'image/*',
}) => {
  const [urlFile, setUrlFile] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  const removeFile = () => setFile(null);

  useEffect(() => {
    if (!file) {
      setUrlFile(null);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setUrlFile(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return (
    <>
      <Form.Field>
        {label && <span className="dropzone-label">{label}</span>}
        {file ? (
          <>
            {showPreview && (
              <div className="uploaded_field">
                <div
                  {...getRootProps()}
                  className="uploaded_field__file"
                >
                  <img
                    src={urlFile}
                    alt=""
                    className="uploaded_field__file--image"
                  />
                  <input
                    className="uploaded_field__file--attachment"
                    {...getInputProps()}
                  />
                </div>
              </div>
            )}
            <span className="uploaded_field__file--name">
              {file?.name}
              <Icon
                name="times circle outline"
                onClick={removeFile}
              />
            </span>
          </>
        ) : (
          <div className="input_field" {...getRootProps()}>
            <img src={folderImage} alt="" className="file" />
            <input {...getInputProps()} required />
            <p>Drag and drop your file here or browse </p>
            <div className="format">
              <span>Supported format:</span>
              <div className="file_icons">
                <img src={gifIcon} alt="" />
                <img src={pngIcon} alt="" />
                <img src={jpegIcon} alt="" />
              </div>
            </div>
          </div>
        )}
      </Form.Field>
    </>
  );
};

MyDropzone.propTypes = {
  setFile: PropTypes.func.isRequired,
  file: PropTypes.instanceOf(Object),
  accept: PropTypes.string,
  label: PropTypes.string,
  showPreview: PropTypes.bool,
};

MyDropzone.defaultProps = {
  file: {},
  accept: '',
  label: '',
  showPreview: true,
};

export default MyDropzone;
