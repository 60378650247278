import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Sidebar,
  Icon,
  Form,
  Input,
  Segment,
  Select,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import OrganizationSearch from 'components/Projects/Partners/PartnerSearch';
import AgentSearch from 'containers/Users/AgentSearch';
import {
  creditAgent,
  creditGroup,
} from 'redux/actions/wallets/credit';
import searchAgent from 'redux/actions/user/searchAgent';
import notify from 'helpers/notificationHelper';
import { ERROR } from 'constants/notificationStatus';
import { toDropDownOptions } from 'utils/dropdownOptions';
import GroupSearch from 'components/Organizations/SingleOrganization/Groups/GroupSearch';
import creditSourceOptions from 'constants/creditSourceOptions';
import accessSourceOption from 'constants/accessCreditSourceOptions';
import InputNumberFormat from '../InputNumberFormat';

const CreditSidebar = ({ filterOn, setActionType }) => {
  const types = toDropDownOptions(['agent', 'group']);
  const [sourceError, setSourceError] = useState();
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  const [
    showAccessBankGroupSource,
    setAccessBankGroupSource,
  ] = useState(false);

  const foundGroups = useSelector(
    state => state?.organizations?.searchGroup?.items,
  );

  const { loading, error, message } = useSelector(
    ({ wallets: { creditAgent } }) => creditAgent,
  );

  const handleFormChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });

    if (name === 'group') {
      const group = foundGroups.find(element => element.id === value);
      setAccessBankGroupSource(group?.access_bank);
    }
  };

  const handleSubmit = () => {
    if (!form.source && showAccessBankGroupSource) {
      return setSourceError('The source is required');
    }

    setSourceError();

    if (form.filter === 'agent') {
      return creditAgent(form, form.agent)(dispatch);
    }

    if (form.filter === 'group') {
      return creditGroup(form, form.group)(dispatch);
    }

    return null;
  };

  useEffect(() => {
    if (form.org_name) searchAgent(form.org_name, '')(dispatch);
  }, [form.org_name]);

  useEffect(() => {
    if (message && !error) {
      notify({ message });
    }
  }, [message]);

  useEffect(() => {
    if (error) {
      if (!error.errors) {
        notify({ message: error.message, status: ERROR });
      }
      if (error.errors) {
        notify({
          message: error.errors[0].message,
          status: ERROR,
        });
      }
    }
  }, [error]);

  const sourceDropdownValues = () => {
    const sourceDropdown = toDropDownOptions(
      showAccessBankGroupSource
        ? accessSourceOption
        : creditSourceOptions,
      true,
    );
    sourceDropdown.forEach(item => {
      if (item.text === 'Top up') item.text = 'Access Bank External';
    });
    return sourceDropdown;
  };

  return (
    <Sidebar
      className="sidebar scrollable"
      as={Segment}
      direction="right"
      animation="overlay"
      visible={filterOn}
      width="wide"
    >
      <div className="padding">
        <div className="header">
          <div
            tabIndex="-1"
            role="button"
            onKeyDown={() => null}
            className="back"
            onClick={() => {
              setActionType(null);
            }}
          >
            <Icon name="arrow left" fitted size="large" />
          </div>
          <div className="title titleText">
            <h2>Credit</h2>
          </div>
        </div>
      </div>
      <div className="padding">
        <Form onSubmit={handleSubmit} loading={loading}>
          <OrganizationSearch
            label="Organization"
            placeholder="Organization"
            handleChange={handleFormChange}
          />

          <Form.Field
            required
            name="filter"
            control={Select}
            fluid
            options={types}
            label="Beneficiary"
            placeholder="Beneficiary"
            onChange={handleFormChange}
          />

          {form.filter === 'agent' && (
            <AgentSearch
              name="agent"
              handleChange={handleFormChange}
              disabled={!form.org_name}
              form={form}
            />
          )}

          {form.filter === 'group' && (
            <GroupSearch
              name="group"
              handleChange={handleFormChange}
              disabled={!form.org_name}
              form={form}
            />
          )}

          <InputNumberFormat
            required
            name="amount"
            control={Input}
            label="Amount"
            type="number"
            placeholder="Amount..."
            onChange={handleFormChange}
          />

          {form.filter === 'group' && (
            <Form.Field
              required
              name="source"
              control={Select}
              fluid
              options={sourceDropdownValues()}
              label="Source"
              placeholder="Source"
              onChange={handleFormChange}
              error={sourceError}
            />
          )}

          <Form.TextArea
            required
            label="Description"
            placeholder="Description"
            className="textarea"
            name="description"
            onChange={handleFormChange}
          />

          {form.filter === 'agent' && (
            <Button
              disabled={!form.agent}
              primary
              floated="right"
              size="medium"
              type="submit"
              content="Credit Agent"
            />
          )}

          {form.filter === 'group' && (
            <Button
              disabled={!form.group}
              primary
              floated="right"
              size="medium"
              type="submit"
              content="Credit Group"
            />
          )}
        </Form>
      </div>
    </Sidebar>
  );
};

CreditSidebar.propTypes = {
  filterOn: PropTypes.bool.isRequired,
  setActionType: PropTypes.func.isRequired,
};

export default CreditSidebar;
