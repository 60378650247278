import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import './PageLoader.scss';

const PageLoader = ({
  loaderSize,
  loaderContent,
  paddingTop,
  center,
}) => (
  <div
    style={{ paddingTop }}
    className={`page__loader ${center ? 'center' : ''}`}
  >
    <Segment vertical>
      <Dimmer active inverted>
        <Loader size={loaderSize} inverted content={loaderContent} />
      </Dimmer>
    </Segment>
  </div>
);

PageLoader.defaultProps = {
  loaderSize: 'medium',
  loaderContent: '',
  paddingTop: '10%',
  center: false,
};

PageLoader.propTypes = {
  loaderSize: PropTypes.string,
  loaderContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any,
  ]),
  paddingTop: PropTypes.string,
  center: PropTypes.bool,
};

export default PageLoader;
