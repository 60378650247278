export default (
  hasError,
  value,
  fieldName = 'Field',
  length = 10,
) => {
  if (hasError && typeof value === 'string' && value.length < length)
    return `"${fieldName}" length must be at least ${length} characters long`;
  return null;
};
