import OrganizationsOverview from 'containers/Organizations/Overviews';

export default {
  exact: true,
  name: 'Overview',
  protected: true,
  path: '/',
  component: OrganizationsOverview,
  permissions: ['all'],
};
