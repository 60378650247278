import React from 'react';
import { Icon, Breadcrumb, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './index.scss';
import { withRouter, Link } from 'react-router-dom';

const Toolbar = ({
  header,
  ignoreHelmet,
  backUrl,
  body,
  footer,
  history,
  style,
}) => {
  return (
    <section className="save-dash-toolbar" style={style}>
      <div className="save-dash-toolbar-header">
        {header.navigation && (
          <button
            type="button"
            className="back-button"
            onClick={() =>
              backUrl ? history.push(backUrl) : history.goBack()
            }
          >
            <Icon size="large" name="arrow left" />
          </button>
        )}
        <div
          className={`title ${
            header.navigation ? 'back-showing' : ''
          }`}
        >
          {header.breadcrumbParent ? (
            <Breadcrumb className={header.tag ? 'tag' : ''}>
              <Breadcrumb.Section>
                <Link
                  className={header.breadcrumbTitle}
                  to={header.breadcrumbParentLink || ''}
                >
                  {header.breadcrumbParent}
                </Link>
              </Breadcrumb.Section>
              {header.breadcrumbChildren ? (
                <>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section>
                    <Link
                      className="breadcrumb-title"
                      to={header.breadcrumbChildrenLink}
                    >
                      {header.breadcrumbChildren}
                    </Link>
                  </Breadcrumb.Section>
                  {header.breadcrumbChildrenTwo ? (
                    <>
                      <Breadcrumb.Divider icon="right chevron" />
                      <Breadcrumb.Section>
                        <Link
                          className="breadcrumb-title"
                          to={header.breadcrumbChildrenLinkTwo}
                        >
                          {header.breadcrumbChildrenTwo}
                        </Link>
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon="right chevron" />
                      <Breadcrumb.Section active>
                        {header.breadcrumbActive}
                      </Breadcrumb.Section>
                    </>
                  ) : header.breadcrumbActive ? (
                    <>
                      <Breadcrumb.Divider icon="right chevron" />
                      <Breadcrumb.Section active>
                        {header.breadcrumbActive}
                      </Breadcrumb.Section>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section active>
                    {header.breadcrumbActive}
                  </Breadcrumb.Section>
                </>
              )}
            </Breadcrumb>
          ) : (
            <h2>{header.title}</h2>
          )}

          <p>{header.meta}</p>

          {header.walletSelection?.options && (
            <span className="dropdown">
              <h2 className="dropdown__title">
                {header.walletSelection?.title}
              </h2>
              <Dropdown
                className="dropdown__menu"
                inline
                scrolling
                options={header.walletSelection?.options}
                placeholder={
                  header.walletSelection.defaultValue?.text ||
                  'Select Organization'
                }
                onChange={header.walletSelection?.onChange}
              />
            </span>
          )}
        </div>
        {!ignoreHelmet && (
          <Helmet defaultTitle="Save." titleTemplate="%s - Save.">
            <title>{header.title}</title>
          </Helmet>
        )}
        <div className="left-component">{header.leftComponent}</div>
        <div className="right-component">{header.rightComponent}</div>
      </div>
      {body && <div className="body">{body}</div>}
      {footer && <div className="footer">{footer}</div>}
    </section>
  );
};

Toolbar.defaultProps = {
  ignoreHelmet: false,
  body: null,
  footer: null,
  style: null,
  title: '',
  backUrl: '',
  walletSelection: {},
  breadcrumbParentLink: '',
  breadcrumbActiveLink: '',
  breadcrumbChildrenLink: '',
  breadcrumbTitle: '',
  breadcrumbActive: '',
  navigation: '',
};

Toolbar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    backUrl: PropTypes.string,
    walletSelection: PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.array,
      onChange: PropTypes.func,
      defaultValue: PropTypes.string,
    }),
    navigation: PropTypes.string,
    meta: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    breadcrumbTitle: PropTypes.string,
    breadcrumbParent: PropTypes.string,
    breadcrumbParentLink: PropTypes.string,
    breadcrumbChildren: PropTypes.string,
    breadcrumbChildrenLink: PropTypes.string,
    breadcrumbActive: PropTypes.string,
    breadcrumbActiveLink: PropTypes.string,
    rightComponent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.bool,
    ]),
    leftComponent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.bool,
    ]),
  }).isRequired,
  ignoreHelmet: PropTypes.bool,
  body: PropTypes.element,
  footer: PropTypes.element,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  style: PropTypes.objectOf(Object),
};

export default withRouter(Toolbar);
