import User from 'components/Users/User';

export default {
  exact: true,
  name: 'User',
  protected: true,
  path: '/user/:userId',
  component: User,
  permissions: ['all'],
};
