import {
  GET_SINGLE_FEATURE_ERROR,
  GET_SINGLE_FEATURE_START,
  GET_SINGLE_FEATURE_SUCCESS,
} from 'constants/action-types/settings/singleFeature';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_SINGLE_FEATURE_START:
      return {
        ...state,
        singleFeature: {
          ...state?.singleFeature,
          loading: true,
          error: null,
          data: [],
        },
      };
    case GET_SINGLE_FEATURE_ERROR:
      return {
        ...state,
        singleFeature: {
          ...state?.singleFeature,
          loading: false,
          error: payload?.message,
        },
      };
    case GET_SINGLE_FEATURE_SUCCESS:
      return {
        ...state,
        singleFeature: {
          ...state?.singleFeature,
          loading: false,
          data: payload?.data,
        },
      };
    default:
      return null;
  }
};
