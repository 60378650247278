import React from 'react';
import { Tab } from 'semantic-ui-react';
import './index.scss';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import AllUsers from './AllUsers';
import OrganizationUsers from './OrganizationUsers';
import AllAgents from './AllAgents';
import AllMembers from './AllMembers';

const panes = [
  {
    alias: 'all-users',
    menuItem: 'All users',
    render: () => (
      <Tab.Pane attached={false}>
        <AllUsers />
      </Tab.Pane>
    ),
  },
  {
    alias: 'organization-users',
    menuItem: 'Organization users',
    render: () => (
      <Tab.Pane attached={false}>
        <OrganizationUsers />
      </Tab.Pane>
    ),
  },
  {
    alias: 'agents',
    menuItem: 'Agents',
    render: () => (
      <Tab.Pane attached={false}>
        <AllAgents />
      </Tab.Pane>
    ),
  },
  {
    alias: 'group-members',
    menuItem: 'Group members',
    render: () => (
      <Tab.Pane attached={false}>
        <AllMembers />
      </Tab.Pane>
    ),
  },
];

const aliasIndex = tab =>
  panes.findIndex(({ alias }) => alias === tab);

const AllUsersTab = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const activeIndex = aliasIndex(queryString.parse(search)?.tab);

  const handleTabChange = (_, { activeIndex }) => {
    const activeTab = panes.find((_, index) => index === activeIndex);
    if (activeTab.alias !== 'all-users') {
      push({ search: `?tab=${activeTab?.alias}` });
    } else push({});
  };

  return (
    <div className="all-users-tab">
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        defaultActiveIndex={activeIndex !== -1 ? activeIndex : 0}
        onTabChange={handleTabChange}
      />
    </div>
  );
};

export default AllUsersTab;
