import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Confirm, Modal } from 'semantic-ui-react';

const ConfirmModel = ({
  ConfirmAction,
  enterPasswordDialogOpen,
  setEnterPasswordDialogOpen,
  userInactive,
  user,
  confirmOpen,
  setConfirmOpen,
  activateDeactivateUser,
}) => (
  <>
    <Modal
      size="mini"
      open={enterPasswordDialogOpen}
      onClose={() => {
        setEnterPasswordDialogOpen(false);
      }}
    >
      <Modal.Header>
        {userInactive ? 'Confirm Activation' : 'Confirm Deactivation'}
      </Modal.Header>
      <Modal.Content>
        <ConfirmAction
          match={{ params: { userId: user.id } }}
          action={userInactive ? 'activate' : 'deactivate'}
          setEnterPasswordDialogOpen={setEnterPasswordDialogOpen}
        />
      </Modal.Content>
    </Modal>
    <Confirm
      data-cy="confirmationBox"
      header={`${userInactive ? 'Activate' : 'Deactivate'} User`}
      open={confirmOpen}
      onCancel={() => {
        setConfirmOpen(false);
      }}
      content={`Are you sure you want to ${
        userInactive ? 'activate' : 'deactivate'
      } ${user.first_name} ${user.last_name}`}
      onConfirm={activateDeactivateUser}
      cancelButton={<Button color="red">No</Button>}
      confirmButton={
        <Button color="green" data-cy="confirmButton">
          <Icon name="checkmark" /> Yes
        </Button>
      }
      size="tiny"
    />
  </>
);

ConfirmModel.propTypes = {
  ConfirmAction: PropTypes.func.isRequired,
  enterPasswordDialogOpen: PropTypes.bool.isRequired,
  setEnterPasswordDialogOpen: PropTypes.func.isRequired,
  userInactive: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(Object).isRequired,
  confirmOpen: PropTypes.bool.isRequired,
  setConfirmOpen: PropTypes.func.isRequired,
  activateDeactivateUser: PropTypes.func.isRequired,
};

export default ConfirmModel;
