import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isSuperAdmin } from 'helpers/checkRoles';

const ResourceLink = ({ activity, resource, resourceName }) => {
  const roles = useSelector(
    ({ user: { currentUser: { details: { roles } } = {} } = {} }) =>
      roles,
  );

  if (!resource) {
    return null;
  }

  let link = `/organizations/${resource.id}`;

  const URLPrefix = !isSuperAdmin(roles)
    ? `/projects/${activity.project_id}/organizations/${activity.organization_id}`
    : `/organizations/${activity.organization_id}/projects/${activity.project_id}`;

  switch (resourceName) {
    case 'project':
      link = URLPrefix;
      break;
    case 'group':
      link = `${
        !isSuperAdmin(roles)
          ? URLPrefix
          : `/organizations/${activity.organization_id}`
      }/groups/${resource.id}`;
      break;
    case 'user':
      link = `/users/${resource.id}`;
      break;
    default:
      link = `/organizations/${resource.id}`;
  }

  return <Link to={link}>{resource.name}</Link>;
};

ResourceLink.defaultProps = {
  resource: {},
};

ResourceLink.propTypes = {
  activity: PropTypes.objectOf(Object).isRequired,
  resourceName: PropTypes.string.isRequired,
  resource: PropTypes.instanceOf(Object),
};

export default ResourceLink;
