import {
  ADD_TEST_FEATURE_START,
  ADD_TEST_FEATURE_ERROR,
  ADD_TEST_FEATURE_SUCCESS,
} from 'constants/action-types/settings/addTestFeature';
import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';

export default (featureId, type, data) => (dispatch, onSuccess) => {
  dispatch(
    apiAction({
      url: `/settings/${featureId}/${type}`,
      method: 'POST',
      data,
      API_REQUEST_START: ADD_TEST_FEATURE_START,
      API_REQUEST_FAILURE: ADD_TEST_FEATURE_ERROR,
      API_REQUEST_SUCCESS: ADD_TEST_FEATURE_SUCCESS,
      onSuccess: payload => {
        dispatch({
          type: ADD_TEST_FEATURE_SUCCESS,
          payload,
        });

        onSuccess();

        notification({
          message: `${type} added successfully`,
        });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: ADD_TEST_FEATURE_ERROR,
          payload: message,
        });
        if (message !== 'Wrong PIN.') {
          notification({ message, status: 'error' });
        }
      },
      onEnd: () => false,
    }),
  );
};
