import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './walletCard.scss';
import formatNumber from 'helpers/formatNumber';
import SimpleModal from 'components/common/modals/SimpleModal';
import SkeletonCard from 'components/common/Skeletons/Card';
import { capitalizeEachWords } from 'utils/capitalize';
import SimpleAccordion from 'components/common/AccordionToggle/SimpleAccordion';

const WalletCard = ({
  label,
  balance,
  data,
  logo,
  loading,
  organizationName,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className="wallet-card">
      {loading ? (
        <SkeletonCard />
      ) : (
        <>
          <div className="wallet-card__logo">
            <img src={logo} alt="" />
          </div>
          <div className="wallet-card__body">
            <h3>{capitalizeEachWords(label)}</h3>
            <p className="wallet-card__body__balance">
              {formatNumber(balance)}
            </p>
            <p className="wallet-card__body__date">
              {moment().format('Do MMMM YYYY')}
            </p>
          </div>
          <div
            tabIndex="-1"
            role="button"
            onKeyDown={() => null}
            className="wallet-card__more"
            onClick={() => setIsModalVisible(true)}
          >
            View more
          </div>
          <SimpleModal
            title={`${capitalizeEachWords(label)} balance`}
            open={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            contentStyle={{ padding: '0px' }}
          >
            <div className="wallet-card__modal">
              <div className="wallet-card__modal__balance">
                <h2 className="">{formatNumber(balance)}</h2>
                <h2 className="wallet-card__modal__balance__meta">
                  RWF
                </h2>
              </div>
              <div className="wallet-card__modal__details">
                {data?.map((wallet, index) => (
                  <>
                    {!wallet?.hasSub && (
                      <div
                        className={`wallet-card__modal__details__item ${
                          wallet?.label === 'Due Commissions'
                            ? 'wallet-card__modal__details__due'
                            : ''
                        }`}
                        key={wallet?.label}
                      >
                        <span className="wallet-card__modal__details__label">
                          {wallet?.label === 'Float'
                            ? `${organizationName} ${wallet?.label}`
                            : wallet?.label}
                          :
                        </span>
                        <span className="wallet-card__modal__details__value">
                          {formatNumber(wallet?.value)} RWF
                        </span>
                      </div>
                    )}
                    {wallet?.hasSub && (
                      <SimpleAccordion data={wallet} index={index} />
                    )}
                  </>
                ))}
              </div>
            </div>
          </SimpleModal>
        </>
      )}
    </div>
  );
};

export default WalletCard;

WalletCard.propTypes = {
  label: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  logo: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  organizationName: PropTypes.string.isRequired,
};
