import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const ChangePinModel = ({
  modalActive,
  setModalActive,
  goToChangePin,
}) => {
  const { loading: resetUserPasswordLoading } = useSelector(
    ({ user: { resetUserPassword } }) => resetUserPassword,
  );

  return (
    modalActive && (
      <Modal
        open={modalActive}
        size="mini"
        onClose={() => setModalActive(false)}
      >
        <Modal.Header>Change PIN</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to change the PIN?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalActive(false)}>
            No
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={goToChangePin}
            loading={resetUserPasswordLoading}
          />
        </Modal.Actions>
      </Modal>
    )
  );
};

export default ChangePinModel;
