import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ListOrganizations from 'containers/Organizations/ListOrganizations';
import ListProjects from 'containers/Projects/ListProjects';
import './index.scss';
import { isSuperAdmin } from 'helpers/checkRoles';
import RecentActivities from './RecentActivities';

const Activity = ({ history }) => {
  const {
    projectItems = [],
    projectMeta,
    organizationItems = [],
    organizationMeta,
    loadingProjects,
    loadingOrganizations,
    user: {
      currentUser: {
        details: { roles = [] },
      },
    },
    organizationActivities = [],
    loadingActivities,
    fetchedActivities,
    activitiesMeta,
  } = useSelector(
    ({
      projects: {
        list: {
          items: projectItems,
          loading: loadingProjects,
          meta: projectMeta,
        },
      },
      organizations: {
        list: {
          items: organizationItems,
          meta: organizationMeta,
          loading: loadingOrganizations,
        },
      },
      user,
      activities: {
        organizationActivities: {
          items: organizationActivities,
          meta: activitiesMeta,
          loading: loadingActivities,
          fetched: fetchedActivities,
        },
      },
    }) => ({
      projectItems,
      projectMeta,
      organizationItems,
      organizationMeta,
      user,
      loadingProjects,
      loadingOrganizations,
      organizationActivities,
      loadingActivities,
      fetchedActivities,
      activitiesMeta,
    }),
  );
  return (
    <section className="activity">
      <RecentActivities
        className="activities"
        activities={organizationActivities}
        loading={loadingActivities}
        fetched={fetchedActivities}
        activitiesMeta={activitiesMeta}
      />

      {isSuperAdmin(roles) && (
        <ListOrganizations
          history={history}
          items={organizationItems}
          loading={loadingOrganizations}
          meta={organizationMeta}
        />
      )}

      {!isSuperAdmin(roles) && (
        <ListProjects
          className="activities"
          history={history}
          items={projectItems}
          loading={loadingProjects}
          meta={projectMeta}
        />
      )}
    </section>
  );
};

Activity.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
};

export default Activity;
