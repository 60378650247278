import React, { useState } from 'react';
import CreateAd from 'components/Communications/Advertisements/CreateAd';
import RecentAds from 'components/Communications/Advertisements/RecentAds';

import './index.scss';

const Advertisements = () => {
  const [editingAd, setEditingAd] = useState(null);

  return (
    <section className="bulk-sms">
      <div className="bulk-sms-container">
        <CreateAd editingAd={editingAd} setEditingAd={setEditingAd} />
        <div className="bulk-sms-container__recent_ads">
          <RecentAds
            editingAd={editingAd}
            setEditingAd={setEditingAd}
          />
        </div>
      </div>
    </section>
  );
};

export default Advertisements;
