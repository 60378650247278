import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Divider,
  Dropdown,
  Message,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import getExportData from 'redux/actions/export/getExportData';
import { toDropDownOptions } from 'utils/dropdownOptions';

const FetchExportData = ({ url }) => {
  const { data: fetchedData } = useSelector(
    ({ exportData }) => exportData,
  );

  const dispatch = useDispatch();

  const [dateFilter, setDateFilter] = useState({
    startDate: moment([2017, 0, 1]).format('DD MMM YYYY'),
    endDate: moment().format('DD MMM YYYY'),
  });

  const [frequency, setFrequency] = useState('daily');

  const handleChange = ({ target: { name, value } }) => {
    setDateFilter({
      ...dateFilter,
      [name]: moment(value).format('DD MMM YYYY'),
    });
  };

  const handleClick = () => {
    const queries = {
      from: moment(dateFilter.startDate).format('YYYY-MM-DD'),
      to: moment(dateFilter.endDate).format('YYYY-MM-DD'),
    };

    if (frequency) {
      queries.type = frequency;
    }

    getExportData(url, queries)(dispatch);
  };

  const handleFrequencyChange = (e, { value }) => {
    setFrequency(value);
  };

  return (
    <div className="fetch_export_data_container">
      {fetchedData?.message && (
        <Message warning>{fetchedData.message}</Message>
      )}
      <div className="fetch_export_data_container__forms_input_container">
        <div className="fetch_export_data_container__forms_input_container__export_date_input">
          <Form.Input
            fluid
            type="date"
            placeholder="From"
            label="From"
            name="startDate"
            onChange={handleChange}
            defaultValue={dateFilter.startDate}
          />
        </div>
        <div className="fetch_export_data_container__forms_input_container__export_date_input">
          <Form.Input
            fluid
            type="date"
            placeholder="To"
            label="To"
            name="endDate"
            onChange={handleChange}
            defaultValue={dateFilter.endDate}
          />
        </div>
      </div>

      <Dropdown
        className="fetch_export_data_container__dropdown"
        options={toDropDownOptions(['daily', 'monthly', 'yearly'])}
        placeholder="Frequency"
        value={frequency}
        onChange={handleFrequencyChange}
      />

      <Divider />

      <Button
        primary
        className="fetch_export_data_container__get_data_button"
        onClick={handleClick}
      >
        Get Data
      </Button>
    </div>
  );
};

FetchExportData.propTypes = {
  url: PropTypes.string.isRequired,
};

export default FetchExportData;
