import React from 'react';
import PropTypes from 'prop-types';
import { Form, Breadcrumb } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './index.scss';
import Toolbar from 'components/common/Toolbar';
import formatNumber from 'helpers/formatNumber';
import InterventionArea from 'components/common/InterventionArea';

const ProfileInterest = ({
  form,
  profile,
  loading,
  backToProfileOverview,
  disableBtn,
  handleSubmit,
  submitBtnText,
  onIconChange,
  onFieldChange,
  customizedTitle = false,
}) => {
  const { pathname } = useLocation();
  const { data } = useSelector(
    ({ profiles: { countProfileGroups } }) => countProfileGroups,
  );

  return (
    <div className="profile-interest">
      {!customizedTitle ? (
        <Toolbar header={{ title: 'New profile' }} />
      ) : (
        <Toolbar
          header={{
            title:
              !pathname.includes('/organizations/') &&
              'Profile settings',
            navigation: false,
            leftComponent: pathname.includes('/organizations/') && (
              <Breadcrumb className="customized-title" size="massive">
                <Breadcrumb.Section active>
                  Profile
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section
                  active
                  className="cursor"
                  onClick={() => backToProfileOverview(profile?.id)}
                >
                  {profile?.name}
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section active>
                  Profile settings
                </Breadcrumb.Section>
              </Breadcrumb>
            ),
          }}
        />
      )}

      <div className="container">
        <div className="wrapper">
          <Form>
            <InterventionArea
              data={form}
              name="Area of interest"
              profile={profile}
              onFieldChange={onFieldChange}
              onIconChange={onIconChange}
              goBack
              isCaseSensitive
              hasVillage={false}
            />
            <div className="cards">
              <div className="card-primary">
                <div className="card-title">Saving groups count</div>
                <div className="card-text">
                  {formatNumber(data?.groups_count || 0)}
                </div>
              </div>
              <div className="card-secondary">
                <div className="card-title">Group members count</div>
                <div className="card-text">
                  {formatNumber(data?.groups_members_count || 0)}
                </div>
              </div>
            </div>
            <Form.Button
              floated="right"
              type="submit"
              primary
              onClick={handleSubmit}
              loading={loading}
              disabled={disableBtn}
            >
              {submitBtnText}
            </Form.Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

ProfileInterest.defaultProps = {
  form: {},
  profile: {},
  loading: false,
  disableBtn: false,
  submitBtnText: '',
  customizedTitle: false,
};

ProfileInterest.propTypes = {
  form: PropTypes.object,
  profile: PropTypes.object,
  loading: PropTypes.bool,
  disableBtn: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onIconChange: PropTypes.func.isRequired,
  backToProfileOverview: PropTypes.func.isRequired,
  submitBtnText: PropTypes.string,
  customizedTitle: PropTypes.bool,
};

export default ProfileInterest;
