import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Dropdown, Divider, Label, Icon } from 'semantic-ui-react';
import queryString from 'query-string';

import './FilterTransactions.scss';

const FilterTransactions = ({
  organizations,
  onFilterTransactions,
}) => {
  let urlQueries = '';
  const { push } = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const [filteredOrganization, setFilteredOrganization] = useState();

  const updateUrl = value => {
    Object.keys(queries || {}).forEach(key => {
      if (key && queries[key] && key !== 'organization') {
        urlQueries += `&${key}=${queries[key]}`;
      }
    });
    push(
      value ? `?organization=${value}${urlQueries}` : { urlQueries },
    );
  };

  const onFilterSelect = (orgName, orgId) => {
    updateUrl(orgName);
    setFilteredOrganization(orgName);
    onFilterTransactions({ organization_id: orgId });
  };

  const onClearFilter = () => {
    updateUrl();
    setFilteredOrganization(null);
    onFilterTransactions({});
  };

  return (
    <>
      {filteredOrganization && (
        <Label className="filter_transactions__tag">
          {filteredOrganization}
          <Icon name="delete" onClick={onClearFilter} />
        </Label>
      )}
      <Dropdown
        floating
        closeOnBlur
        icon="filter"
        pointing="top left"
        className="button icon filter_transactions"
      >
        <Dropdown.Menu>
          <Dropdown.Menu scrolling>
            {organizations.map(option => (
              <Dropdown.Item
                key={option.id}
                text={option.name}
                value={option.id}
                onClick={() => onFilterSelect(option.name, option.id)}
              />
            ))}
            <Divider />
            <Dropdown.Item
              text="Reset"
              className="reset"
              onClick={onClearFilter}
            />
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

FilterTransactions.defaultProps = {
  onFilterTransactions: null,
  organizations: [],
};

FilterTransactions.propTypes = {
  onFilterTransactions: PropTypes.func,
  organizations: PropTypes.array,
};
export default FilterTransactions;
