import {
  DELETE_ADVERTISEMENT_ERROR,
  DELETE_ADVERTISEMENT_START,
  DELETE_ADVERTISEMENT_SUCCESS,
} from 'constants/action-types/communication/deleteAdvertisement';
import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import getAllAdvertisement from './getAllAdvertisement';

export default id => dispatch =>
  dispatch(
    apiAction({
      url: `advertisements/${id}`,
      method: 'DELETE',
      API_REQUEST_START: DELETE_ADVERTISEMENT_START,
      API_REQUEST_SUCCESS: DELETE_ADVERTISEMENT_SUCCESS,
      API_REQUEST_FAILURE: DELETE_ADVERTISEMENT_ERROR,

      callbackOnSuccess: () => {
        notificationHelper({
          message: 'Advertisment successfully deleted',
        });
        getAllAdvertisement()(dispatch);
      },
      callbackOnFailure: ({ message }) => {
        notificationHelper({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
