import {
  BULK_CREATE_ERROR,
  BULK_CREATE_START,
  BULK_CREATE_SUCCESS,
} from 'constants/action-types/organizations/bulkCreate';

import apiAction from 'helpers/apiAction';

export default (
  organizationId,
  projectId,
  data,
  setState,
) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}/groups/bulk`,
      method: 'POST',
      data,
      timeout: 1200000,
      API_REQUEST_START: BULK_CREATE_START,
      API_REQUEST_SUCCESS: BULK_CREATE_SUCCESS,
      API_REQUEST_FAILURE: BULK_CREATE_ERROR,
      onSuccess: payload => {
        setState('success');
        dispatch({
          type: BULK_CREATE_SUCCESS,
          payload,
        });
      },
      onEnd: () => false,
    }),
  );
