import { useSelector } from 'react-redux';

export default () => {
  const {
    id: currentOrgId,
    name: currentOrgName,
    save_type: saveType,
    roles = [],
    orgUsers = [],
    user: { user_type: currentUserType } = {},
  } = useSelector(
    ({
      dashboard: {
        currentOrganization: { details = { user: {} } },
      },
      user: {
        currentUser: {
          details: { roles, organizations_users: orgUsers },
        },
      },
    }) => ({ ...details, roles, orgUsers }),
  );
  return {
    currentOrgId,
    currentOrgName,
    saveType,
    currentUserType,
    roles,
    orgUsers,
  };
};
