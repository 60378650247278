/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import PieChart from 'components/common/charts/PieChart/PieChartWrapper';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import LineChartWrapper from 'components/common/charts/LineChart/LineChartWrapper/LineChartWrapper';
import todaysDate from 'utils/todaysDate';
import capitalize from 'utils/capitalize';

const StatCharts = ({
  data: {
    distribution_per_agent = [],
    MNO_per_province: MNODistributionPerProvince,
    enrolment_per_gender: enrolmentPerGender,
    agent_education_levels: agentEducationLevel = [],
    members_age_range: membersAgeRange,
    cumulative_savings_vs_cumulative_loans: savingsVsLoans,
  },
  year,
}) => {
  const agentsPerProvince = distribution_per_agent.map(item => ({
    ...item,
    name: item.province,
  }));

  const cleanSavingsVsLoans =
    savingsVsLoans &&
    savingsVsLoans.map(item => {
      return {
        ...item,
        name: (item.month || '').substring(0, 3),
      };
    });

  const genders =
    enrolmentPerGender &&
    enrolmentPerGender.map(item => {
      return {
        name: (item.name || item.month).substring(0, 3),
        female: item.females || item.female,
        male: item.males || item.male,
      };
    });

  const cleanAgentEducationLevel =
    agentEducationLevel &&
    agentEducationLevel.map(item => ({
      Agents: item.education_level,
      name: capitalize(item.education_level),
      total: Number(item.total),
    }));

  const colors = ['#8884d8', '#83a6ed', '#8dd1e1'];
  const membersAgeRangeData =
    membersAgeRange &&
    membersAgeRange.map((item, i) => {
      item.name = item.age;
      item.fill = colors[i];
      item.name = item.age;
      return item;
    });

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <BarChart
            stack
            title="Agent Distribution Per Province"
            data={agentsPerProvince}
            dataKey1="male"
            dataKey2="female"
            dataKey3="groups"
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'MNO Distribution Per Province',
              date: todaysDate(year),
              data: MNODistributionPerProvince,
              dataKey1: 'Airtel',
              dataKey2: 'MTN',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <LineChartWrapper
            title="SGs Enrolment Per Gender"
            dataKey1="male"
            dataKey2="female"
            width={440}
            height={300}
            data={genders}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Column width={8}>
            <PieChart
              title="Agent Level Of Education"
              dataKey="total"
              nameKey="Agents"
              width={300}
              height={330}
              data={cleanAgentEducationLevel}
              date={todaysDate(year)}
            />
          </Grid.Column>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <PieChart
            filled
            title="Members Age Range"
            width={400}
            height={320}
            innerRadius={20}
            outerRadius={140}
            barSize={10}
            dataKey="total"
            nameKey="age"
            data={membersAgeRangeData}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Cumulative Savings Vs Cumulative Loans',
              date: todaysDate(year),
              data: cleanSavingsVsLoans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

StatCharts.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default StatCharts;
