const validateInterventionAreas = form => {
  if (!form.intervention_areas) {
    return {
      message: 'Intervention areas are required',
    };
  }
  if (!form.intervention_areas.provinces) {
    return {
      message:
        'The provinces field is required for intervention areas',
    };
  }
  if (!form.intervention_areas.districts) {
    return {
      message:
        'The districts field is required for intervention areas',
    };
  }
  if (!form.intervention_areas.sectors) {
    return {
      message: 'The sectors field is required for intervention areas',
    };
  }
  return false;
};

export default validateInterventionAreas;
