import {
  GET_WALLETS_START,
  GET_WALLETS_ERROR,
  GET_WALLETS_SUCCESS,
} from 'constants/action-types/wallets/getWallets';

import apiAction from 'helpers/apiAction';

export default organizationId => dispatch => {
  const url = organizationId
    ? `/organizations/${organizationId}/wallets`
    : '/wallets';

  dispatch(
    apiAction({
      url,
      method: 'GET',
      API_REQUEST_START: GET_WALLETS_START,
      API_REQUEST_SUCCESS: GET_WALLETS_SUCCESS,
      API_REQUEST_FAILURE: GET_WALLETS_ERROR,
      onEnd: () => false,
    }),
  );
};
