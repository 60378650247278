import {
  RESET_GROUP_SHARE_START,
  RESET_GROUP_SHARE_SUCCESS,
  RESET_GROUP_SHARE_FAILED,
} from 'constants/action-types/groups/resetGroupShares.js';

import apiAction from 'helpers/apiAction';

export default (groupId, data) => (
  dispatch,
  setOpenModal,
  setUserPin,
) => {
  dispatch(
    apiAction({
      url: `/groups/${groupId}/shares/reset`,
      data,
      method: 'PUT',
      API_REQUEST_START: RESET_GROUP_SHARE_START,
      API_REQUEST_SUCCESS: RESET_GROUP_SHARE_SUCCESS,
      API_REQUEST_FAILURE: RESET_GROUP_SHARE_FAILED,
      callbackOnSuccess: () => {
        setOpenModal(false);
        setUserPin('');
      },
      onEnd: () => false,
    }),
  );
};
