import {
  LOGOUT_USER_START,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_END,
} from 'constants/action-types/user/logout';
import apiAction from 'helpers/apiAction';

export default () => dispatch => {
  localStorage.clear();
  window.location.replace('/login');
  dispatch({
    type: LOGOUT_USER_SUCCESS,
  });
  dispatch(
    apiAction({
      method: 'post',
      url: '/auth/logout',
      API_REQUEST_START: LOGOUT_USER_START,
      API_REQUEST_END: LOGOUT_USER_END,
      onSuccess: () => {
        dispatch({
          type: LOGOUT_USER_SUCCESS,
        });
        return true;
      },
      onFailure: () => {
        dispatch({
          type: LOGOUT_USER_FAILURE,
        });
        return false;
      },
    }),
  );
};
