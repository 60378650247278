import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import Toolbar from 'components/common/Toolbar';
import InterventionArea from 'components/common/InterventionArea';
import ISOToDateString from 'helpers/ISOToDateString';
import validateProjectDates from 'helpers/validators/validateProjectDates';
import { isSuperAdmin } from 'helpers/checkRoles';
import InputNumberFormat from 'components/common/InputNumberFormat';
import getCurrentOrg from 'helpers/getCurrentOrg';

const ProjectForm = ({
  formError,
  organizationsOptions,
  handleSubmit,
  handleChange,
  loading,
  loadingProject,
  loadingUpdate,
  projectData,
  noToolbar,
  settings,
  form,
  message,
}) => {
  const currentOrganization = getCurrentOrg();
  const { roles = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  return (
    <section className="create-project scroll-wrapper">
      {!noToolbar && <Toolbar header={{ title: 'New project' }} />}
      <div className="container">
        <div className="wrapper">
          <div className="title">
            <div>Project information</div>
          </div>
          <Form
            onSubmit={handleSubmit}
            loading={loading || loadingProject || loadingUpdate}
          >
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Name"
                name="name"
                type="text"
                defaultValue={projectData.name}
                placeholder="Name"
                required={!settings}
                onChange={handleChange}
              />
              <Form.Dropdown
                placeholder={
                  !isSuperAdmin(roles)
                    ? currentOrganization?.name
                    : `Organization`
                }
                fluid
                label="Organization"
                selection
                disabled={!isSuperAdmin(roles)}
                options={organizationsOptions}
                name="organization"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Start"
                name="start_date"
                type="date"
                value={
                  form.start_date ||
                  ISOToDateString(projectData.start_date)
                }
                placeholder="Start"
                required={!settings}
                onChange={handleChange}
              />
              <Form.Input
                fluid
                label="End"
                name="end_date"
                min={new Date().toLocaleDateString()}
                type="date"
                value={
                  form.end_date ||
                  ISOToDateString(projectData.end_date)
                }
                placeholder="End"
                required={!settings}
                onChange={handleChange}
                error={validateProjectDates(form)}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <InputNumberFormat
                fluid
                label="Budget"
                name="budget"
                type="number"
                defaultValue={projectData.budget}
                placeholder="RWF"
                required={!settings}
                onChange={handleChange}
              />
              <Form.Input
                fluid
                required={!settings}
                label="Donor"
                name="donor"
                type="text"
                defaultValue={projectData.donor}
                placeholder="Donor"
                onChange={handleChange}
              />
            </Form.Group>

            <InterventionArea
              data={projectData}
              onChange={handleChange}
            />

            {formError() && (
              <Message negative>{formError().message}</Message>
            )}
            <Form.Button floated="right" type="submit" primary>
              {settings ? 'Save' : 'Create'}
            </Form.Button>
          </Form>
        </div>
      </div>
    </section>
  );
};

ProjectForm.defaultProps = {
  message: null,
};

ProjectForm.propTypes = {
  formError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  organizationsOptions: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingProject: PropTypes.bool.isRequired,
  loadingUpdate: PropTypes.bool.isRequired,
  projectData: PropTypes.objectOf(Object).isRequired,
  noToolbar: PropTypes.bool.isRequired,
  settings: PropTypes.bool.isRequired,
  form: PropTypes.objectOf(Object).isRequired,
  message: PropTypes.string,
};

export default ProjectForm;
