import {
  UPDATE_SINGLE_ORGANIZATION_START,
  UPDATE_SINGLE_ORGANIZATION_SUCCESS,
  UPDATE_SINGLE_ORGANIZATION_FAILURE,
  UPDATE_SINGLE_ORGANIZATION_END,
  UPDATE_SINGLE_ORGANIZATION_CLEAR,
} from 'constants/action-types/organizations/updateSingleOrganization';
import apiAction from 'helpers/apiAction';
import formatPhone from 'helpers/formatPhone';
import {
  HTTP_BAD_REQUEST,
  HTTP_ACCESS_DENIED,
  HTTP_UNAUTHORIZED,
} from 'constants/httpStatusCodes';
import { UNEXPECTED_ERROR } from 'constants/response-messages/httpRequests';
import {
  UPDATE_SUCCESS,
  UPDATE_DENIED,
} from 'constants/response-messages/organizations/updateOrganization';

const getOrganizationDetails = data => ({
  id: data.id || '',
  userId: data.user_id || '',
  name: data.name || '',
  email: data.email || '',
  address: data.address || '',
  activities: data.activities || '',
  type: data.type || '',
  category: data.category || '',
  country: data.country || '',
  emailVerified: data.email_verified,
  phone: (data.phone_number || '').replace('250', ''),
  phoneVerified: data.phone_verified,
  status: data.status || '',
});

const getErrors = ({ errors }) => {
  let message = '';
  (errors || []).forEach(error => {
    message += `*${error.message}.* , `;
  });
  return message || UNEXPECTED_ERROR;
};

export const clearUpdateOrganization = () => dispatch =>
  dispatch({
    type: UPDATE_SINGLE_ORGANIZATION_CLEAR,
  });

export default (organizationId, data) => dispatch =>
  dispatch(
    apiAction({
      method: 'PUT',
      url: `/organizations/${organizationId}`,
      data: {
        name: data.name || undefined,
        category: data.category || undefined,
        type: data.type || undefined,
        phone_number: formatPhone(data.phone),
        email: data.email || undefined,
        country: data.country || undefined,
        address: data.address || undefined,
      },
      API_REQUEST_START: UPDATE_SINGLE_ORGANIZATION_START,
      API_REQUEST_END: UPDATE_SINGLE_ORGANIZATION_END,
      onSuccess: ({ data }) => {
        return dispatch({
          type: UPDATE_SINGLE_ORGANIZATION_SUCCESS,
          payload: {
            message: UPDATE_SUCCESS,
            organization: getOrganizationDetails(data),
          },
        });
      },
      onFailure: error => {
        if (error.status === HTTP_ACCESS_DENIED) {
          return dispatch({
            type: UPDATE_SINGLE_ORGANIZATION_FAILURE,
            payload: UPDATE_DENIED,
          });
        }
        if (error.status === HTTP_BAD_REQUEST) {
          return dispatch({
            type: UPDATE_SINGLE_ORGANIZATION_FAILURE,
            payload: getErrors(error),
          });
        }
        if (error.status === HTTP_UNAUTHORIZED) {
          return dispatch({
            type: UPDATE_SINGLE_ORGANIZATION_FAILURE,
            payload: error.message,
          });
        }
        return dispatch({
          type: UPDATE_SINGLE_ORGANIZATION_FAILURE,
          payload: UNEXPECTED_ERROR,
        });
      },
    }),
  );
