import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from 'components/ResetPassword';
import {
  verifyAccount,
  resetPassword,
} from 'redux/actions/user/resetPassword';
import { MIN_PASSWORD_LENGTH } from 'constants/password';

const ResetPasswordContainer = ({ history }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    phone: '',
    code: '',
    password: '',
    passwordAgain: '',
  });
  const [formError, setFormError] = useState('');
  const {
    isAccountVerified,
    isPasswordReset,
    loading,
    message,
    error,
  } = useSelector(({ user: { resetPassword } }) => resetPassword);

  const handleSubmit = () => {
    if (isAccountVerified && !isPasswordReset) {
      if (formData.password !== formData.passwordAgain) {
        return setFormError('PINs do not match');
      }
      if (formData.password.length < MIN_PASSWORD_LENGTH) {
        return setFormError(`Wrong PIN`);
      }
      setFormError('');
      resetPassword(formData)(dispatch);
    } else if (isPasswordReset) {
      return history.push('/login');
    }
    setFormError('');
    return (
      !isAccountVerified && verifyAccount(formData.phone)(dispatch)
    );
  };

  const handleChange = ({ target }) => {
    setFormError('');
    setFormData({ ...formData, [target.name]: target.value });
  };

  useEffect(() => {
    if (message) {
      setFormData({
        ...formData,
        code: '',
        password: '',
        passwordAgain: '',
      });
    }
  }, [message]);

  return (
    <ResetPassword
      loading={loading}
      message={message}
      error={formError || error}
      formData={formData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isPasswordReset={isPasswordReset}
      isAccountVerified={isAccountVerified}
    />
  );
};

ResetPasswordContainer.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default ResetPasswordContainer;
