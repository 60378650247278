import moment from 'moment';

export default (startDate, endDate) => {
  const M = moment(endDate).diff(moment(startDate), 'months');
  const D = moment(endDate).diff(moment(startDate), 'days');

  if (Number(M) === 0) {
    return `${D} Days`;
  }

  return `${M} Months`;
};
