import {
  GET_ACTIVITY_START,
  GET_ACTIVITY_END,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITIES,
} from 'constants/action-types/organizations/activities';
import apiAction from 'helpers/apiAction';
import { HTTP_ACCESS_DENIED } from 'constants/httpStatusCodes';
import { UNEXPECTED_ERROR } from 'constants/response-messages/httpRequests';

export default (organizationId, queries) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/activities`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ACTIVITY_START,
      API_REQUEST_END: GET_ACTIVITY_END,
      API_REQUEST_SUCCESS: GET_ACTIVITY_SUCCESS,
      API_REQUEST_FAILURE: GET_ACTIVITY_FAILURE,
      onSuccess: res => {
        if (typeof res === 'string') {
          dispatch({
            type: GET_ACTIVITIES,
            payload: {},
          });
        } else {
          dispatch({
            type: GET_ACTIVITIES,
            payload: { data: res.data, meta: res.meta },
          });
        }
      },
      onEnd: () => false,
      onFailure: error => {
        if (error.status === HTTP_ACCESS_DENIED) {
          return dispatch({
            type: GET_ACTIVITY_FAILURE,
            payload: 'Access Denied',
          });
        }
        return dispatch({
          type: GET_ACTIVITY_FAILURE,
          payload: UNEXPECTED_ERROR,
        });
      },
    }),
  );
};
