import {
  AMEND_LOAN_REQUESTS_ERROR,
  AMEND_LOAN_REQUESTS_START,
  AMEND_LOAN_REQUESTS_SUCCESS,
} from 'constants/action-types/loanRequests/amendLoanRequests';

export default (state, { type, payload }) => {
  switch (type) {
    case AMEND_LOAN_REQUESTS_START:
      return {
        ...state,
        amendLoanRequest: {
          ...state.amendLoanRequest,
          loading: true,
          error: null,
        },
      };
    case AMEND_LOAN_REQUESTS_ERROR:
      return {
        ...state,
        amendLoanRequest: {
          ...state.amendLoanRequest,
          error: payload,
          loading: false,
        },
      };
    case AMEND_LOAN_REQUESTS_SUCCESS:
      return {
        ...state,
        amendLoanRequest: {
          ...state.amendLoanRequest,
          data: payload.data,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
