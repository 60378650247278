import {
  GET_ORGANIZATION_AGENT_ERROR,
  GET_ORGANIZATION_AGENT_START,
  GET_ORGANIZATION_AGENT_SUCCESS,
} from 'constants/action-types/organizations/getAgent';

import apiAction from 'helpers/apiAction';

export default (
  organizationId,
  projectId,
  agentId,
  queries,
) => dispatch => {
  const url = `/organizations/${organizationId}/agents/${agentId}/overview${
    projectId ? `?project_id=${projectId}` : ''
  }`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_AGENT_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_AGENT_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_AGENT_ERROR,
      onEnd: () => false,
    }),
  );
};
