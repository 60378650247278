/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import todaysDate from 'utils/todaysDate';

const StatCharts = ({
  data: {
    synchronized_savings_vs_shares: synchronisedSavingsVsShares = [],
    cumulative_savings_vs_cumulative_loans: cumulativeSavingsVsCumulativeLoans = [],
  },
  year,
}) => {
  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Cumulative Savings Vs Cumulative Loans',
              date: todaysDate(year),
              data: cumulativeSavingsVsCumulativeLoans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Synchronized Savings and Shares',
              date: todaysDate(year),
              data: synchronisedSavingsVsShares,
              dataKey1: 'savings',
              dataKey2: 'shares',
              showSynchronized: true,
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
StatCharts.defaultProps = {
  synchronized_savings_vs_shares: [],
  cumulative_savings_vs_cumulative_loans: [],
};

StatCharts.propTypes = {
  synchronized_savings_vs_shares: PropTypes.array,
  cumulative_savings_vs_cumulative_loans: PropTypes.array,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default StatCharts;
