import initialState from 'redux/initial-states/profiles';
import countProfileGroups from './countProfileGroups';
import createProfile from './createProfile';
import listProfiles from './listProfiles';
import listProfileGroups from './listProfileGroups';
import profileOverview from './profileOverview';
import editProfile from './editProfile';
import singleProfile from './singleProfile';

export default (state = initialState, action = {}) => ({
  ...state,
  ...createProfile(state, action),
  ...countProfileGroups(state, action),
  ...editProfile(state, action),
  ...listProfiles(state, action),
  ...listProfileGroups(state, action),
  ...profileOverview(state, action),
  ...singleProfile(state, action),
});
