/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Message, Button, Table } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import './index.scss';
import formatNumber from 'helpers/formatNumber';
import capitalize from 'utils/capitalize';
import getSingleLoanRequest from 'redux/actions/loanRequests/getSingleLoanRequest';
import LoanAmendModal from 'containers/MicroLoanRequests/AmendModal';
import LoanApprovalModal from 'containers/MicroLoanRequests/ApprovalModal';
import Empty from 'components/common/Empty';
import getUserRoles from 'helpers/getUserRoles';
import getWallets from 'redux/actions/wallets/getWallets';
import isParentOrganization from 'helpers/isParentOrganization';
import InternalLoanOverview from './InternalLoanOverview';
import RequestPopupContent from './RequestPopupContent';

const SingleLoanRequest = () => {
  const params = useParams();
  const { organizationId, tabOption: requestId } = params;
  const isCurrentOrgParent = isParentOrganization();

  const [open, setOpen] = useState(null);

  const { currentOrgId } = getUserRoles();

  const { loading, error, data } = useSelector(
    ({ loanRequests: { singleLoanRequest } }) => singleLoanRequest,
  );
  const { items: walletItems } = useSelector(
    ({ wallets: { wallets } }) => wallets,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getSingleLoanRequest(requestId)(dispatch);
  }, [requestId]);

  const onAmendOrApprovalSuccess = () => {
    getSingleLoanRequest(requestId)(dispatch);
  };

  useEffect(() => {
    if (isCurrentOrgParent) {
      getWallets()(dispatch);
    } else {
      getWallets(currentOrgId)(dispatch);
    }
  }, [isCurrentOrgParent]);

  return (
    <div className="single-loan-request">
      <LoanAmendModal
        open={open === 'amend'}
        setOpen={setOpen}
        request={data}
        onSuccess={onAmendOrApprovalSuccess}
        wallets={walletItems}
      />
      <LoanApprovalModal
        open={open === 'approve'}
        setOpen={setOpen}
        request={data}
        onSuccess={onAmendOrApprovalSuccess}
        wallets={walletItems}
      />
      <InternalLoanOverview
        open={open === 'overview-info'}
        setOpen={setOpen}
        request={data}
      />
      <Toolbar
        header={{
          title:
            data?.group?.name && `Micro loan: ${data?.group?.name}`,
          navigation: true,
          backUrl: `/organizations/${organizationId}/requests`,
          rightComponent: (
            <>
              {data.status === 'approved' ||
                data.status === 'rejected' || (
                  <>
                    <Button
                      basic
                      color="green"
                      content="Approve"
                      onClick={() => {
                        setOpen('approve');
                      }}
                    />
                    <Button
                      basic
                      color="blue"
                      content="Amend"
                      onClick={() => {
                        setOpen('amend');
                      }}
                    />
                  </>
                )}
              <Button
                primary
                content="Internal loans overview"
                onClick={() => {
                  setOpen('overview-info');
                }}
              />
            </>
          ),
        }}
      />

      <div
        className={`single-loan-request__content ${loading &&
          'single-loan-request__content__loading'} scroll-wrapper`}
      >
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error?.message}</Message>
        ) : (
          <>
            <div className="single-loan-request__content__left">
              <RequestPopupContent request={data} />
            </div>
            <div className="single-loan-request__content__table">
              {data.installments?.length === 0 ? (
                <div
                  className="flex flex-1 flex-center"
                  style={{ width: '100%' }}
                >
                  <Empty title="No activities" />
                </div>
              ) : (
                <Table basic="very" size="small" textAlign="left">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        Activity Type
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Paid amount (RWF)
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Due amount (RWF)
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Late fee (RWF)
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Late fee date
                      </Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body
                    className={`single-loan-request__content__table__tbody wallet__transactions--table-body ${true &&
                      'lg-tbody'}`}
                  >
                    {data.installments?.map(installment => {
                      return (
                        <Table.Row
                          key={installment.id}
                          className="table-row cursor"
                        >
                          <Table.Cell>Loan Repayment</Table.Cell>
                          <Table.Cell>
                            {formatNumber(
                              installment.loans_repayment_amount,
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {formatNumber(installment.loans_balance)}
                          </Table.Cell>
                          <Table.Cell>
                            {formatNumber(installment.late_fees)}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(installment.late_fee_date).format(
                              'MMM DD, YYYY',
                            )}
                          </Table.Cell>
                          <Table.Cell className="flex flex-center-vertical">
                            <div
                              className={
                                installment.status === 'overdue'
                                  ? 'red-dot'
                                  : installment.status === 'due'
                                  ? 'bleu-dot'
                                  : 'green-dot'
                              }
                            />
                            {capitalize(installment.status)}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(
                              installment.repayment_date,
                            ).format('MMM DD, YYYY')}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleLoanRequest;
