import React, { useEffect, useState } from 'react';
import {
  Card,
  Icon,
  Loader,
  Dimmer,
  Image,
  Segment,
  Modal,
  Form,
  Message,
  Button,
} from 'semantic-ui-react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccordionToggle from 'components/common/AccordionToggle';
import getUserRolesAction from 'redux/actions/user/getUserRoles';
import NestedAccordionToggle from 'components/common/AccordionToggle/NestedAccordionToggle';
import getRoleType from 'helpers/getRoleType';
import toggleUserRoles from 'redux/actions/user/toggleUserRoles';
import dimmer from 'assets/images/short-paragraph.png';
import toggleRolesInBulk from 'redux/actions/user/toggleRolesInBulk';
import PINInput from 'components/common/PINInput';
import userRolesFormatter from 'helpers/shapeFormatter/userRoles';
import { canPerformAction } from 'helpers/permissions';
import permissions from 'constants/permissions';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';
import {
  isSaveUserCountryOrganization,
  isSaveAdminCountryOrganization,
} from 'helpers/checkOrgType';

const UserRoles = () => {
  const [actionForm, setActionForm] = useState({});
  const [pinModal, setPinModal] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const isCurrentOrgParent = isParentOrganization();

  const { data, loading } = useSelector(
    ({ user: { singleUserRoles } }) => singleUserRoles,
  );

  const { loading: loadingBulk, error: errorBulk } = useSelector(
    ({ user: { toggleRolesInBulk } }) => toggleRolesInBulk,
  );

  const { loading: loadingToggle, error: errorToggle } = useSelector(
    ({ user: { toggleUserRoles } }) => toggleUserRoles,
  );

  const { currentOrgId, roles, orgUsers } = getUserRoles();

  useEffect(() => {
    getUserRolesAction(params?.userId)(dispatch);
  }, [params?.userId]);

  const handleChange = (status, role, items, context, title) => {
    setPinModal(true);
    const roleType = getRoleType(role);
    setActionForm({
      status,
      items,
      context,
      roleType,
      title,
    });
  };

  const handlePassword = (e, { name, value }) => {
    setActionForm({
      ...actionForm,
      [name]: value,
    });
  };

  const toggleRoles = () => {
    const action = actionForm?.status ? 'activate' : 'deactivate';
    switch (actionForm.context) {
      case 'toggleBulk':
        return toggleRolesInBulk(params?.userId, action, {
          type: actionForm?.roleType?.type,
          password: actionForm?.password,
        })(dispatch, setPinModal);

      case 'toggleRole':
        return toggleUserRoles(params?.userId, action, {
          type: actionForm?.roleType?.type,
          password: actionForm?.password,
          [actionForm?.roleType?.dependency1]: actionForm?.items?.id,
        })(dispatch, setPinModal);

      case 'nestedToggle':
        return actionForm?.roles?.map(role =>
          toggleRolesInBulk(params?.userId, action, {
            type: role,
            password: actionForm?.password,
          })(dispatch, setPinModal),
        );

      default:
        return null;
    }
  };

  const toggleBulk = (status, mainItems, subItems, context) => {
    setPinModal(true);
    return mainItems?.map(item =>
      setActionForm({
        status,
        context,
        items: item?.subItems,
        roles: item?.roles,
        title: 'an Admin',
      }),
    );
  };

  const canEditUserRoles = () =>
    canPerformAction(
      permissions.canPerformAction.editUserRoles,
      roles,
      {
        currentOrgId,
        orgUsers,
      },
    );

  return (
    <>
      <Modal
        size="mini"
        open={pinModal}
        onClose={() => setPinModal(false)}
      >
        <Modal.Header>
          {actionForm?.status ? 'Activate' : 'Deactivate'} user role
          <Icon
            name="close"
            className="pointer"
            onClick={() => setPinModal(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to{' '}
            {actionForm?.status ? 'activate' : 'deactivate'}{' '}
            {data?.user?.first_name} as{' '}
            {actionForm?.title ===
            'Organization User (Organization name)'
              ? 'an Organization User'
              : actionForm?.title === 'Agent (Project name)'
              ? 'an Agent'
              : actionForm?.title === 'Member (Saving group name)'
              ? 'a Member'
              : `${actionForm?.title}`}{' '}
            of{' '}
            {actionForm?.items?.length > 0 ? (
              actionForm?.items?.map(item => <b>{item?.name}, </b>)
            ) : (
              <b>{actionForm?.items?.name}</b>
            )}
          </p>
          <Form
            onSubmit={toggleRoles}
            loading={loadingBulk || loadingToggle}
          >
            <PINInput
              className="input"
              fluid
              size="tiny"
              label="Enter Pin"
              name="password"
              onInputChange={handlePassword}
            />

            {(errorBulk || errorToggle) &&
              (errorBulk || errorToggle) === 'Wrong PIN.' && (
                <p color="red">
                  <Message negative>
                    {errorBulk || errorToggle}
                  </Message>
                </p>
              )}

            <Button
              className="confirm"
              type="submit"
              color="blue"
              fluid
            >
              Confirm
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
      <div className="SingleUser__body__details">
        {loading ? (
          <Card>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted />
              </Dimmer>
              <Image src={dimmer} />
            </Segment>
          </Card>
        ) : (
          <Card>
            <Card.Content>
              <NestedAccordionToggle
                data={userRolesFormatter({
                  data,
                  params,
                  context: 'org_admin_roles',
                  title: 'Admin',
                  subitemKey: 'organization',
                  admin: true,
                  isCurrentOrgParent,
                  isSaveUserCountryOrganization,
                  isSaveAdminCountryOrganization,
                })}
                toggleBulk={toggleBulk}
                handleChange={handleChange}
                disabled={
                  !canPerformAction(
                    permissions.canPerformAction.editAdminUserRoles,
                    roles,
                    {
                      currentOrgId,
                      orgUsers,
                    },
                  )
                }
                linkTo="organization"
              />
              <AccordionToggle
                data={userRolesFormatter({
                  data,
                  params,
                  context: 'org_user_roles',
                  title: 'Organization User (Organization name)',
                  subitemKey: 'organization',
                  admin: false,
                  isCurrentOrgParent,
                  isSaveUserCountryOrganization,
                  isSaveAdminCountryOrganization,
                })}
                handleChange={handleChange}
                disabled={!canEditUserRoles()}
                linkTo="organization"
              />
              <AccordionToggle
                data={userRolesFormatter({
                  data,
                  params,
                  context: 'agent_roles',
                  title: 'Agent (Project name)',
                  subitemKey: 'project',
                  admin: false,
                  isCurrentOrgParent,
                  isSaveUserCountryOrganization,
                  isSaveAdminCountryOrganization,
                })}
                handleChange={handleChange}
                disabled={!canEditUserRoles()}
                linkTo="project"
              />
              <AccordionToggle
                data={userRolesFormatter({
                  data,
                  params,
                  context: 'group_roles',
                  title: 'Member (Saving group name)',
                  subitemKey: 'group',
                  admin: false,
                  isCurrentOrgParent,
                  isSaveUserCountryOrganization,
                  isSaveAdminCountryOrganization,
                })}
                handleChange={handleChange}
                disabled={!canEditUserRoles()}
                linkTo="group"
              />
            </Card.Content>
          </Card>
        )}
      </div>
    </>
  );
};

export default UserRoles;
