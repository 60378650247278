export default {
  allGroups: {
    items: [],
    meta: {},
    loading: false,
    error: null,
  },
  singleMember: {
    MemberTransactions: {
      data: [],
      meta: {},
      loading: false,
      error: null,
    },
    MemberOverdueLoans: {
      loading: false,
      error: null,
      data: [],
      meta: {},
    },
  },
  groupTransactions: {
    data: [],
    meta: {},
    loading: false,
    error: null,
  },
  resetGroupShares: {
    data: {},
    loading: false,
    error: null,
  },
  recomputeGroupBalance: {
    message: null,
    loading: false,
    error: null,
  },
  getGroupAccessBankBalance: {
    data: {},
    loading: false,
    error: null,
  },
  transactionStatusUpdate: {
    data: {},
    loading: false,
    error: null,
  },
  bulkTransactionUpload: {
    data: {},
    loading: false,
    error: null,
  },
};
