import {
  GET_ORGANIZATION_GROUPS_ERROR,
  GET_ORGANIZATION_GROUPS_START,
  GET_ORGANIZATION_GROUPS_SUCCESS,
} from 'constants/action-types/organizations/getGroups';
import {
  CHANGE_GROUP_STATUS_START,
  CHANGE_GROUP_STATUS_SUCCESS,
  CHANGE_GROUP_STATUS_ERROR,
} from 'constants/action-types/organizations/changeGroupStatus';
import {
  GET_ORGANIZATION_GROUP_ERROR,
  GET_ORGANIZATION_GROUP_START,
  GET_ORGANIZATION_GROUP_SUCCESS,
} from 'constants/action-types/organizations/getSingleGroup';

import apiAction from 'helpers/apiAction';
import notification from 'helpers/notificationHelper';
import getProject from 'redux/actions/projects/getProject';

const getOrganizationGroups = (
  organizationId,
  projectId,
  queries,
) => dispatch => {
  const url = !projectId
    ? `/organizations/${organizationId}/groups`
    : `/organizations/${organizationId}/projects/${projectId}/groups`;

  dispatch(
    apiAction({
      url,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_GROUPS_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_GROUPS_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_GROUPS_ERROR,
      onEnd: () => false,
    }),
  );
};

export const getSingleGroup = (id, queries) => dispatch =>
  dispatch(
    apiAction({
      url: `/groups/${id}`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_GROUP_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_GROUP_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_GROUP_ERROR,
      onEnd: () => false,
    }),
  );

export const getAgentGroups = (
  organizationId,
  agentId,
  queries,
) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/agents/${agentId}/groups`,
      method: 'GET',
      queries,
      API_REQUEST_START: GET_ORGANIZATION_GROUPS_START,
      API_REQUEST_SUCCESS: GET_ORGANIZATION_GROUPS_SUCCESS,
      API_REQUEST_FAILURE: GET_ORGANIZATION_GROUPS_ERROR,
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );

export const changeGroupStatus = (id, action) => dispatch => {
  dispatch(
    apiAction({
      url: `/groups/${id}/${action}`,
      method: 'PUT',
      API_REQUEST_START: CHANGE_GROUP_STATUS_START,
      onSuccess: ({ message }) => {
        dispatch({
          type: CHANGE_GROUP_STATUS_SUCCESS,
          payload: message,
        });
        getSingleGroup(id)(dispatch);
        notification({ message });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: CHANGE_GROUP_STATUS_ERROR,
          payload: message,
        });
        notification({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};

export const changeAllGroupStatus = (
  orgId,
  projectId,
  action,
) => dispatch => {
  dispatch(
    apiAction({
      url: `organizations/${orgId}/projects/${projectId}/groups/${action}`,
      method: 'PUT',
      API_REQUEST_START: CHANGE_GROUP_STATUS_START,
      onSuccess: ({ message }) => {
        dispatch({
          type: CHANGE_GROUP_STATUS_SUCCESS,
          payload: message,
        });
        getOrganizationGroups(orgId)(dispatch);
        notification({ message });
      },
      onFailure: ({ message }) => {
        dispatch({
          type: CHANGE_GROUP_STATUS_ERROR,
          payload: message,
        });
        notification({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};

export const changeAllGroupPilotStatus = (
  orgId,
  projectId,
  action,
) => dispatch => {
  const method =
    action === 'set' ? 'POST' : action === 'unset' ? 'DELETE' : '';
  dispatch(
    apiAction({
      url: `organizations/${orgId}/projects/${projectId}/groups/piloted`,
      method,
      API_REQUEST_START: CHANGE_GROUP_STATUS_START,
      onSuccess: ({ message }) => {
        dispatch({
          type: CHANGE_GROUP_STATUS_SUCCESS,
          payload: message,
        });
        getOrganizationGroups(orgId)(dispatch);
        notification({ message });
        getProject(orgId, projectId, true)(dispatch);
      },
      onFailure: ({ message }) => {
        dispatch({
          type: CHANGE_GROUP_STATUS_ERROR,
          payload: message,
        });
        notification({ message, status: 'error' });
      },
      onEnd: () => false,
    }),
  );
};

export default getOrganizationGroups;
