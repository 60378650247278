import {
  LOGIN_START,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
} from 'constants/action-types/user/login';
import apiAction from 'helpers/apiAction';
import formatPhone from 'helpers/formatPhone';
import { HTTP_UNAUTHORIZED } from 'constants/httpStatusCodes';
import { UNAUTHORIZED_ACCESS } from 'constants/response-messages/login/index';
import { shouldLogin } from 'helpers/checkRoles';

const onSuccess = ({ data: { roles, token } }) => dispatch => {
  if (shouldLogin(roles)) {
    localStorage.setItem('token', token);

    dispatch({
      type: LOGIN_SUCCESS,
    });
    return window.location.replace('/select-organization');
  }

  dispatch({
    type: LOGIN_ERROR,
    payload: {
      status: HTTP_UNAUTHORIZED,
      message: UNAUTHORIZED_ACCESS,
    },
  });
};

export default ({ phone, password }) => dispatch =>
  dispatch(
    apiAction({
      url: `/auth/login?light=true`,
      method: 'POST',
      data: {
        phone_number: formatPhone(phone),
        password,
      },
      API_REQUEST_START: LOGIN_START,
      API_REQUEST_SUCCESS: LOGIN_SUCCESS,
      API_REQUEST_FAILURE: LOGIN_ERROR,
      onSuccess: data => onSuccess(data)(dispatch),
      onEnd: () => false,
    }),
  );
