/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';

import phoneUtils from '@exuus/rwanda-phone-utils';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import OrganizationLineCharts from 'components/Organizations/SingleOrganization/Overview/LineCharts';
import OrganizationDetails from 'components/Organizations/SingleOrganization/Overview/Details';
import OrganizationGenders from 'components/Organizations/SingleOrganization/Overview/Genders';
import getSingleOrganizationOverview from 'redux/actions/organizations/singleOrganizationOverview';
import Message from 'components/common/Message';
import StatCharts from 'components/Organizations/SingleOrganization/Overview/StatCharts';
import DropdownFilterYear from 'components/common/DropdownFilterYear';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';

const SingleOrganization = ({
  match: {
    params: { organizationId },
  },
  history: { push },
}) => {
  const currentYear = useRef();
  const { data, loading, error } = useSelector(
    ({ organizations: { singleOrganizationOverview } }) =>
      singleOrganizationOverview,
  );

  const dispatch = useDispatch();
  const {
    organization: {
      name = '',
      type,
      phone_number: phoneNumber,
      email,
      address,
      projects_count: projectCounts,
    } = {},
  } = data;

  const getSingleOrganizationOverviewWithYear = (
    { organizationId },
    yearQuery,
  ) => {
    currentYear.current = yearQuery.year;
    getSingleOrganizationOverview(
      organizationId,
      yearQuery,
    )(dispatch);
  };

  return (
    <>
      <div className="single_organization_overview_header">
        <Toolbar
          header={{
            title: `Organization: ${name}`,
            rightComponent: (
              <div className="header_right_component">
                <DropdownFilterYear
                  handleChange={getSingleOrganizationOverviewWithYear}
                  params={{ organizationId }}
                  triggerChange={organizationId}
                />

                <Button
                  label={projectCounts}
                  primary
                  content="View projects"
                  onClick={() =>
                    push(`/organizations/${organizationId}/projects`)
                  }
                />

                <ToolbarFooter
                  url={`/organizations/${organizationId}/stats`}
                  fetchExportData
                  exportCSVFileName="organization-details"
                />
              </div>
            ),
          }}
        />
      </div>
      <div className="group-overview scroll-wrapper">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message color="red" message={error.message} />
        ) : (
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column width="8">
                <OrganizationLineCharts
                  data={data}
                  year={currentYear.current}
                />
              </Grid.Column>
              <Grid.Column width="5">
                <OrganizationDetails
                  data={data}
                  name={name}
                  type={type}
                  phoneNumber={phoneUtils(phoneNumber).format(
                    'space',
                  )}
                  email={email}
                  address={address}
                />
              </Grid.Column>
              <Grid.Column width="3">
                <OrganizationGenders
                  data={data}
                  year={currentYear.current}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={data} year={currentYear.current} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </>
  );
};

SingleOrganization.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(Object).isRequired,
  }).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
};

export default SingleOrganization;
