import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Icon, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { startCase } from 'lodash';
import capitalize, { capitalizeEachWords } from 'utils/capitalize';
import currencyFormat from 'utils/currencyFormat';

const BillingPopup = ({
  name,
  walletCharge,
  groupsPurpose,
  transactionType,
  minimumAmount,
  maximumAmount,
  chargeType,
  charge,
  effectiveDate,
  groupsType,
  walletProvider,
  transactionPurpose,
  frequency,
  description,
}) => {
  return (
    <Popup
      trigger={<Icon name="info circle" color="blue" />}
      content={
        <div className="billings-info">
          <div className="billings-info__title">{name}</div>
          <Divider />
          <div className="billings-info__details">
            <div className="billings-info__details--left">
              <h5>Wallet type</h5>
              <span>{capitalize(startCase(walletCharge))}</span>

              {walletCharge === 'group_wallet' && (
                <>
                  <h5>Group purpose</h5>
                  {groupsPurpose.map(type => (
                    <span>
                      {capitalizeEachWords(startCase(type))}
                    </span>
                  ))}
                </>
              )}

              <h5>Transaction type</h5>
              <span>{capitalize(transactionType)}</span>

              <h5>Range (Rwf)</h5>
              <span>
                {currencyFormat(minimumAmount, '')} -{' '}
                {maximumAmount
                  ? currencyFormat(maximumAmount, '')
                  : 'Above'}
              </span>

              <h5>Fees amount</h5>
              <span>
                {chargeType === 'fixed'
                  ? currencyFormat(charge)
                  : `${charge}%`}
              </span>
              <h5>Effective Date</h5>
              <span>
                {effectiveDate
                  ? moment(effectiveDate).format('DD-MM-YYYY')
                  : '-'}
              </span>
            </div>
            <div className="billings-info__details--right">
              {walletCharge === 'group_wallet' && (
                <>
                  <h5>Group type</h5>
                  {groupsType.map(type => (
                    <span>
                      {capitalizeEachWords(startCase(type))},{' '}
                    </span>
                  ))}
                </>
              )}

              <h5>Group Wallet type</h5>
              <span>{capitalize(startCase(walletProvider))}</span>
              <h5>Transaction categories</h5>
              {transactionPurpose.map(type => (
                <span>{capitalizeEachWords(startCase(type))}, </span>
              ))}

              <h5>Fees type</h5>
              <span>{capitalize(chargeType)}</span>

              <h5>Frequency</h5>
              <span>{capitalize(frequency)}</span>
              <h5>Description</h5>
              <span>{capitalize(description)}</span>
            </div>
          </div>
        </div>
      }
      on="hover"
      hoverable
      position="left center"
    />
  );
};

BillingPopup.defaultProps = {
  groupsPurpose: [],
  groupsType: [],
};

BillingPopup.propTypes = {
  name: PropTypes.string.isRequired,
  walletCharge: PropTypes.string.isRequired,
  groupsPurpose: PropTypes.array,
  transactionType: PropTypes.string.isRequired,
  minimumAmount: PropTypes.number.isRequired,
  maximumAmount: PropTypes.number.isRequired,
  chargeType: PropTypes.string.isRequired,
  charge: PropTypes.number.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  groupsType: PropTypes.array,
  walletProvider: PropTypes.string.isRequired,
  transactionPurpose: PropTypes.array.isRequired,
  frequency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BillingPopup;
