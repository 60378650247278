import {
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
} from 'constants/action-types/projects/updateProject';

import apiAction from 'helpers/apiAction';
import notificationHelper from 'helpers/notificationHelper';
import { SUCCESS } from 'constants/notificationStatus';
import getProject from './getProject';

export default (organizationId, projectId, form) => dispatch => {
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/projects/${projectId}`,
      method: 'PUT',
      data: {
        ...form,
        organization: undefined,
      },
      API_REQUEST_START: UPDATE_PROJECT_START,
      API_REQUEST_SUCCESS: UPDATE_PROJECT_SUCCESS,
      API_REQUEST_FAILURE: UPDATE_PROJECT_FAILED,
      onSuccess: ({ message }) => {
        dispatch({
          type: UPDATE_PROJECT_SUCCESS,
          payload: form,
        });
        notificationHelper({
          message,
          status: SUCCESS,
        });
        getProject(organizationId, projectId)(dispatch);
      },
      onEnd: () => false,
    }),
  );
};
