import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import WalletCard from 'components/Wallet/Card';
import walletBalance from 'helpers/shapeFormatter/walletBalance';

const WalletBalance = ({ data: walletData, currentOrg }) => {
  const data = walletBalance(walletData, currentOrg?.type);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });

  return (
    <div className="wallet__balance">
      {data.map(
        wallet =>
          wallet && (
            <WalletCard
              key={wallet?.label}
              label={wallet?.label}
              logo={wallet?.logo}
              data={wallet?.data}
              balance={wallet?.balance}
              loading={loading}
              organizationName={currentOrg?.name}
            />
          ),
      )}
    </div>
  );
};

WalletBalance.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  currentOrg: PropTypes.object.isRequired,
};

export default WalletBalance;
