import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import Toolbar from 'components/common/Toolbar';
import './index.scss';
import reportAProblem from 'redux/actions/support/reportAProblem';
import getUserRoles from 'helpers/getUserRoles';

const Support = () => {
  const [form, setForm] = useState({});

  const { roles: currentUserRoles } = getUserRoles();

  const { reported, loading, error } = useSelector(
    ({ support: { reportAProblem } }) => reportAProblem,
  );

  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) =>
    setForm({ ...form, [name]: value });

  const handleSubmit = () => {
    if (form.topic && form.message && currentUserRoles) {
      reportAProblem({ ...form, role: 'org_user' })(dispatch);
    }
  };

  useEffect(() => {
    setForm({ topic: '', message: '' });
  }, [reported]);

  return (
    <>
      <Toolbar header={{ title: 'Support' }} />
      <div className="support">
        <h1>Report a problem</h1>

        <div className="support__form">
          <Form onSubmit={handleSubmit} loading={loading}>
            <Form.Input
              fluid
              label="Subject"
              name="topic"
              placeholder=""
              value={form.topic}
              onChange={handleChange}
              required
            />
            <Form.TextArea
              label="Message"
              name="message"
              placeholder=""
              value={form.message}
              onChange={handleChange}
              required
            />
            {error && <Message negative>{error.message}</Message>}
            <Form.Button primary floated="right">
              Send
            </Form.Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Support;
