import {
  GET_GROUP_TRANSACTION_ERROR,
  GET_GROUP_TRANSACTION_START,
  GET_GROUP_TRANSACTION_SUCCESS,
} from 'constants/action-types/groups/getGroupTransactions';
import apiAction from 'helpers/apiAction';

export default queries => dispatch => {
  dispatch(
    apiAction({
      url: `/admin/transactions`,
      queries,
      method: 'GET',
      API_REQUEST_START: GET_GROUP_TRANSACTION_START,
      API_REQUEST_SUCCESS: GET_GROUP_TRANSACTION_SUCCESS,
      API_REQUEST_FAILURE: GET_GROUP_TRANSACTION_ERROR,
      onSuccess: ({ data, meta }) => {
        dispatch({
          type: GET_GROUP_TRANSACTION_SUCCESS,
          payload: {
            data,
            meta,
          },
        });
      },
      onEnd: () => false,
    }),
  );
};
