import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './index.scss';
import ListProjects from 'containers/Projects/ListProjects';

const AllOrganizations = ({ history }) => {
  const { items, meta, loading } = useSelector(
    ({ projects: { list } }) => list,
  );

  return (
    <ListProjects
      history={history}
      items={items}
      loading={loading}
      meta={meta}
      all
    />
  );
};

AllOrganizations.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
};

export default AllOrganizations;
