import {
  SEND_BULK_SMS_ERROR,
  SEND_BULK_SMS_START,
  SEND_BULK_SMS_SUCCESS,
} from 'constants/action-types/organizations/bulkSms';
import notification from 'helpers/notificationHelper';
import axios from 'helpers/axiosHelper';
import getSmsList from 'redux/actions/organizations/bulkSmsList';

export default body => async dispatch => {
  try {
    dispatch({
      type: SEND_BULK_SMS_START,
    });
    const { data: payload, status } = await axios().request({
      method: 'post',
      url: 'sms',
      data: body,
    });
    if (status === 204) {
      notification({
        message: 'No recipients found',
        status: 'warn',
      });
      return dispatch({
        type: SEND_BULK_SMS_SUCCESS,
      });
    }
    notification({ message: payload.message });
    dispatch({
      type: SEND_BULK_SMS_SUCCESS,
      payload,
    });
    return getSmsList(dispatch);
  } catch (error) {
    if (error.response) {
      const { message: payload } = error.response.data;
      notification({
        message: payload,
        status: 'error',
      });
      return dispatch({
        type: SEND_BULK_SMS_ERROR,
        payload,
      });
    }
    return notification({
      message: 'Something went wrong',
      status: 'error',
    });
  }
};
