import {
  SEARCH_GROUP_ERROR,
  SEARCH_GROUP_START,
  SEARCH_GROUP_SUCCESS,
} from 'constants/action-types/organizations/searchGroup';

import apiAction from 'helpers/apiAction';

export default (organizationId, query) => dispatch =>
  dispatch(
    apiAction({
      url: `/organizations/${organizationId}/groups?search=${query}`,
      method: 'GET',
      API_REQUEST_START: SEARCH_GROUP_START,
      API_REQUEST_SUCCESS: SEARCH_GROUP_SUCCESS,
      API_REQUEST_FAILURE: SEARCH_GROUP_ERROR,
      onEnd: () => false,
    }),
  );
