/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Tab, Dropdown } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isSuperAdmin } from 'helpers/checkRoles';
import GroupTransactions from '../Transactions';
import AllGroups from './AllGroups';
import './index.scss';

const panes = [
  {
    menuItem: 'All Saving Groups',
    render: ({ filter }) => (
      <Tab.Pane attached={false}>
        <AllGroups filter={filter} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Supervised',
    alias: 'supervised',
    render: ({ filter }) => (
      <Tab.Pane attached={false}>
        <AllGroups filter={filter} type="supervised" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Graduated',
    alias: 'graduated',
    render: ({ filter }) => (
      <Tab.Pane attached={false}>
        <AllGroups filter={filter} type="graduated" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Independent',
    alias: 'independent',
    render: ({ filter }) => (
      <Tab.Pane attached={false}>
        <AllGroups filter={filter} type="independent" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'All Transactions',
    alias: 'allTransactions',
    render: ({ filter }) => (
      <Tab.Pane attached={false}>
        <GroupTransactions filter={filter} />
      </Tab.Pane>
    ),
  },
];

const aliasIndex = tab =>
  panes.findIndex(({ alias }) => alias === tab);

const AllGroupsTab = () => {
  const { tab } = useParams();
  const activeIndex = aliasIndex(tab);

  const { push } = useHistory();

  const { roles = [] } = useSelector(
    ({
      user: {
        currentUser: { details },
      },
    }) => details,
  );

  useEffect(() => {
    if (roles.length > 0 && !isSuperAdmin(roles)) {
      push('/');
    }
  }, [roles]);

  const handleTabChange = (e, { activeIndex }) => {
    const { alias: currentAlias } = panes.find(
      (pane, index) => index === activeIndex,
    );

    if (currentAlias) {
      push(`/all-groups/${currentAlias}`);
    } else {
      push('/all-groups');
    }
  };

  const [filter, setFilter] = useState();

  const filterOptions = [
    { text: 'Alphabetically A to Z', key: 1, value: 'name-ASC' },
    { text: 'Alphabetically Z to A', key: 2, value: 'name-DESC' },
    { text: 'Active Groups', key: 3, value: 'status-ASC' },
    { text: 'Date Created (Old)', key: 5, value: 'created_at-ASC' },
    { text: 'Date Created (New)', key: 6, value: 'created_at-DESC' },
    {
      text: 'Current Savings (Low)',
      key: 7,
      value: 'savings_balance-ASC',
    },
    {
      text: 'Current Savings (High)',
      key: 8,
      value: 'savings_balance-DESC',
    },
  ];

  const transactionOptions = [
    { text: 'Recent to Old', key: 1, value: 'created_at-DESC' },
    { text: 'Old to Recent', key: 2, value: 'created_at-ASC' },
  ];

  const handleSort = (e, { value }) => setFilter(value);

  return (
    <div className="all-groups-tab">
      <div className="group-sort">
        <Dropdown
          selection
          clearable
          placeholder="Sort By"
          options={
            panes[activeIndex].alias === 'allTransactions'
              ? transactionOptions
              : filterOptions
          }
          onChange={handleSort}
        />
      </div>

      <Tab
        className="groups-tab-items"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        filter={filter}
        defaultActiveIndex={activeIndex !== -1 ? activeIndex : 0}
        onTabChange={handleTabChange}
      />
    </div>
  );
};

export default AllGroupsTab;
