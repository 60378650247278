import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import SidebarFilter from 'components/common/SidebarFilter';
import { IndividualUserTransactions } from 'constants/activityTypes';
import getUserTransactions from 'redux/actions/user/getUserTransactions';
import GroupTransactionList from 'components/Groups/TransactionsList';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import Empty from 'components/common/Empty';

const UserTransactions = ({ user }) => {
  const { search } = useLocation();

  const page = new URLSearchParams(search).get('page');
  const purpose = new URLSearchParams(search).get('purpose');
  const status = new URLSearchParams(search).get('status');
  const category = new URLSearchParams(search).get('category');
  const provider_name = new URLSearchParams(search).get(
    'provider_name',
  );
  const type = new URLSearchParams(search).get('type');

  const [filterOn, setFilterOn] = useState(false);
  const [filterForm, setFilterForm] = useState({
    purpose,
    status,
    category,
    provider_name,
    type,
  });

  const { push } = useHistory();

  const dispatch = useDispatch();

  const {
    loading: userTransactionsLoading,
    error: userTransactionsError,
    data: userTransactionsData,
    meta = {},
  } = useSelector(({ user: { getTransactions } }) => getTransactions);

  const onQuery = queries =>
    getUserTransactions(user.id, {
      ...queries,
    })(dispatch);

  const filteredTransactions = () => {
    getUserTransactions(user.id, filterForm)(dispatch);
  };

  useEffect(() => {
    filteredTransactions();
  }, [dispatch, filterForm]);

  const handleChangeFilter = (e, { name, value }) => {
    setFilterForm({ ...filterForm, [name]: value });
    if (
      [
        'type',
        'status',
        'category',
        'purpose',
        'provider_name',
      ].includes(name)
    ) {
      push(
        formatSearchParameter({
          ...filterForm,
          [name]: value,
          page,
        }),
      );
    }
  };

  const types = IndividualUserTransactions();

  return (
    <div className="user_transaction_container">
      <div className="user_transaction_container__toolbar">
        <Toolbar
          header={{
            title: 'All User Transactions',
            rightComponent: (
              <ToolbarFooter
                onPaginate={onQuery}
                paginationMeta={meta}
                exportCSVData={userTransactionsData}
                exportCSVFileName="User Transactions"
                filterClicked={() => {
                  setFilterOn(true);
                }}
                filter
                transactionSearch
              />
            ),
          }}
        />
      </div>
      {isEmpty(userTransactionsData) && !userTransactionsLoading ? (
        <Empty message="No Transactions" />
      ) : (
        <div className="user_transaction_container__transactions">
          <GroupTransactionList
            loading={userTransactionsLoading}
            error={userTransactionsError}
            groupTransactions={userTransactionsData}
            context="user"
          />
        </div>
      )}

      <SidebarFilter
        filterOn={filterOn}
        setFilterOn
        form={filterForm}
        setForm={setFilterForm}
        filterTransactions={filteredTransactions}
        handleChange={handleChangeFilter}
        types={types}
        activity="groupTransactions"
      />
    </div>
  );
};

UserTransactions.defaultProps = {
  user: {},
};

UserTransactions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    id_number: PropTypes.string,
    birth_date: PropTypes.string,
    gender: PropTypes.string,
    occupation: PropTypes.string,
    marital_status: PropTypes.string,
    phone_number: PropTypes.string,
    referral_user_type: PropTypes.string,
    referral_alias: PropTypes.string,
    education_level: PropTypes.string,
    email: PropTypes.string,
    work_email: PropTypes.string,
    position: PropTypes.string,
    status: PropTypes.string,
    my_groups: PropTypes.array,
    roles: PropTypes.array,
    created_groups: PropTypes.array,
    organizations_users: PropTypes.array,
    is_blocked: PropTypes.bool,
  }),
};

export default UserTransactions;
