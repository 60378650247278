import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Tab } from 'semantic-ui-react';
import { canAccess } from 'helpers/permissions';
import getUserRoles from 'helpers/getUserRoles';
import './index.scss';
import Advertisements from 'containers/Communications/Advertisements';
import BulkSms from 'containers/Communications/BulkSms';
import permissions from 'constants/permissions';
import PushNotifications from './PushNotifications';
import Emails from './Emails';

const panes = [
  {
    alias: 'bulk-sms',
    menuItem: 'Bulk SMS',
    render: () => (
      <Tab.Pane attached={false}>
        <BulkSms />
      </Tab.Pane>
    ),
  },
  {
    alias: 'push-notifications',
    menuItem: 'Push Notifications',
    render: () => (
      <Tab.Pane attached={false}>
        <PushNotifications />
      </Tab.Pane>
    ),
  },
  {
    alias: 'emails',
    menuItem: 'Emails',
    render: () => (
      <Tab.Pane attached={false}>
        <Emails />
      </Tab.Pane>
    ),
  },
  {
    alias: 'advertisement',
    menuItem: 'Advertisement',
    render: () => (
      <Tab.Pane attached={false}>
        <Advertisements />
      </Tab.Pane>
    ),
  },
];

const aliasIndex = tab =>
  panes.findIndex(({ alias }) => alias === tab);

const Communications = () => {
  const { push } = useHistory();
  const { tab } = useParams();
  const activeIndex = aliasIndex(tab);
  const { currentOrgId, orgUsers, roles } = getUserRoles();

  const handleTabChange = (e, { activeIndex }) => {
    const { alias: currentAlias } = panes.find(
      (pane, index) => index === activeIndex,
    );
    push(`/communications/${currentAlias}`);
  };

  useEffect(() => {
    if (
      !isEmpty(roles) &&
      currentOrgId &&
      !canAccess(permissions.canAccess.communications, roles, {
        currentOrgId,
        orgUsers,
      })
    ) {
      push('/');
    }
  }, [orgUsers, currentOrgId]);
  return (
    <div className="communication-tab">
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        defaultActiveIndex={activeIndex !== -1 ? activeIndex : 0}
        onTabChange={handleTabChange}
      />
    </div>
  );
};

export default Communications;
