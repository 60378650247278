import {
  ADD_BILL_ERROR,
  ADD_BILL_START,
  ADD_BILL_SUCCESS,
} from 'constants/action-types/settings/addBill';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_BILL_START:
      return {
        ...state,
        addBill: {
          loading: true,
          error: null,
          data: [],
        },
      };
    case ADD_BILL_ERROR:
      return {
        ...state,
        addBill: {
          ...state.addBill,
          error: payload,
          loading: false,
        },
      };
    case ADD_BILL_SUCCESS:
      return {
        ...state,
        addBill: {
          ...state.addBill,
          data: payload.data,
          loading: false,
        },
      };
    default:
      return null;
  }
};
